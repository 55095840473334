import { createElement } from "../elements";
import Card from "./card";
import owner from "../../owner";
import 'chartjs-gauge'
import { SocketList, TagSocket } from "./tagsocket__classic";
import NodeManager from "../nodemanager";
import 'chartjs-adapter-moment';
import {RadialGauge} from 'canvas-gauges'
import Colors from '../colors'
import { UnitsMap } from "../widgets/lib/tagunits";


export default class GaugeCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width:      250,    // default width (pixels)
                height:     150,    // default height (pixels)
                minWidth:   200,    // minimum width (pixels)
                minHeight:  100,    // minimum height (pixels)
                type:       'gauge',
            }
        };
        super(parent, editor, props);
        this.element.wrapper = createElement('div', 'card__widget-wrapper', this.element);
        this.element.tagMap = new Map();
        this.element.nodeManager = new NodeManager(this.element); // this widget needs a node manager to track the level

        this.element.update = (tag) => {
            let chart = this.element.tagMap.get(tag);
            chart.value = tag.getFormattedTextFromValue(tag.getValue());
        }

        this.element.socketList = new SocketList([
            new TagSocket('Gauge Tags', 'Clever description here', ['name', 'ranges'], undefined, undefined, true),
        ], this);

        if (this.tags) {
            this.element.socketList.populate(this.tags);
        }

        else {
            this.tags = [];
            this.element.socketList.modify();
        }

    }

    resizeCallback() {
        this.rebuild();
    }

    rebuild() {
        this.element.wrapper.removeChildren();
        this.element.nodeManager.destroy();
        this.element.nodeManager = new NodeManager(this.element); // this widget needs a node manager to track the level
        for (let i=0;i<this.element.socketList.sockets.get('Gauge Tags').tags.length;i++) {
            let serializedTag = this.element.socketList.sockets.get('Gauge Tags').tags[i];
            createElement('div', 'card__widget-wrapper__name', this.element.wrapper, serializedTag.name)
            let tag = this.resolveTag(serializedTag);
            if (!tag) return;
            this.element.nodeManager.addNode(tag);
            this.minValue = Math.min(...serializedTag.ranges[0]);
            let maxValue = Math.max(...serializedTag.ranges[0]);
            let rangeZones = []
            let ranges = [];
            for (let i=1;i<serializedTag.ranges[0].length;++i) {
                let value = serializedTag.ranges[0][i];
                rangeZones.push({
                    color: Colors.hex2rgba(serializedTag.ranges[1][i-1]),
                    from: serializedTag.ranges[0][i-1],
                    to: value
                })
            }
            let canvas = createElement('canvas', '', this.element.wrapper, null, {'width':this.element.clientWidth * 0.75, 'height':this.element.clientWidth * 0.75});
            let gauge = new RadialGauge({
                renderTo: canvas,
                width: this.element.clientWidth * 0.75,
                height: this.element.clientWidth * 0.75,
                units: UnitsMap.get(tag.units).abbrev,
                title: false,
                value: 0,
                minValue: this.minValue,
                maxValue: maxValue,
                exactTicks: true,
                majorTicks: serializedTag.ranges[0],
                minorTicks: Math.round((maxValue - this.minValue) / 50),
                strokeTicks: false,
                highlights: rangeZones,
                //colorPlate: '#222',
                colorMajorTicks: '#f5f5f5',
                colorMinorTicks: '#ddd',
                colorTitle: '#fff',
                colorNeedle: 'rgba(240, 128, 128, 1)',
                needleShadow: false,
                valueBox: true,
                animationRule: 'bounce',
                animationDuration: 500,
                borders: false,
                colorUnits:owner.colors.hex('--color-onSurface'),
                colorNumbers: owner.colors.hex('--color-onSurface'),
                colorValueBoxRect: 'rgba(0,0,0,0)',
                colorValueBoxRectEnd: 'rgba(0,0,0,0)',
                colorValueBoxBackground: 'rgba(0,0,0,0)',
                colorValueBoxShadow: 'rgba(0,0,0,0)',
            });
            gauge.draw();
            this.element.tagMap.set(tag, gauge);
        }

        this.element.nodeManager.subscribe();
    }

    onSettingsClicked() {
        this.element.socketList.modify();
    }

    destroy() {
        this.element.nodeManager.destroy();
        super.destroy()
    }
}