import { Alarm, AlarmConditionDescription, AlarmConditions, AlarmTypeNames, AlarmTypeText, AlarmTypes } from "../alarm";
import { createElement, formatTimeSpan, createUniqueId } from "../elements";
import { Widget } from "../widget";
import Localization from "../localization";
import View from "./view";
import './alarmlogview.css';
import assert from "../debug";
import owner from "../../owner";
import ViewModal from "../viewmodal";
import { ExportView } from "./chartview";
import Loader from "../loader";
import ArrowIcon from '../images/icons/arrow_right_filled.svg';
// Make a table of all the active alarms and any other data we have about them. One tab manages
// all the alarms (instead of creating an object to manage each row) so we can do our queries on
// mass together easily.
export default class AlarmLogView extends View {
    constructor(device, ldc) {
        super(parent)
        this.ldc            	= ldc;
        this.device         	= device;
        this.tree				= this.device.tree;					// Get a pointer to the node tree
        this.alarmSet			= this.device.alarms;				// Get our set of alarms
        this.dateFormat			= '%yy.%MM.%dd %HH:%mm:%ss';	// Build the string on how we want to display dates once
        this.displayCount		= 20;							// How many alarms to display
		this.alarmsToExport 	= [];
		this.exportStartDate 	= 0;
		this.filterEvents 		= true;
		this.filterAlarms 		= true;
    };

    initialize(parent) {
		super.initialize(parent);
		// Create a controls division and an acknowledge button within that division
        this.wrapper = createElement('div', 'alarm-log__wrapper', this.parent);

		this.tableWrapper			= createElement('div', 'alarm-log__table-wrapper', this.wrapper);

        // Create all of our header cells for the active alarms table
        this.alarmTable             = createElement('div', 'alarm-log__table alarm-log__alarms-table', this.tableWrapper);
        var controlsDiv				= createElement('div', 'alarm-log__controls', this.alarmTable);

		// Create another div within the controls division to hold our radio buttons. These radio buttons
		// will be used to selectively show alarms
		var radioDiv			 	= createElement('div', 'alarm-log__radios', controlsDiv);
        let ackWrapper              = createElement('div', 'alarm-log__ack-wrapper', controlsDiv)
        this.acknowledge            = createElement('input', 'se-button alarm-log__ack-button', ackWrapper, null, {'value':'Acknowledge'})
        this.acknowledge.onclick    = () => this.acknowledgeAlarms();
		var radioID					= createUniqueId();
		this.allButton				= this.createRadioButton(radioDiv, 'All', radioID);
		this.allButton.checked		= true;	// Start off with the all button checked
		this.activeButton			= this.createRadioButton(radioDiv, 'Active', radioID);
		this.unackButton			= this.createRadioButton(radioDiv, 'Unacknowledged', radioID);
		this.allButton.onchange = this.activeButton.onchange = this.unackButton.onchange = () => this.onRadioButtonChanged();
        let header          = createElement('div', 'alarm-log__row alarm-log__row__alarms alarm-log__row__header', this.alarmTable);
        let checkAll        = createElement('div', 'alarm-log__cell alarm-log__header', header)
        this.headerCheck	= createElement('input', null, checkAll);	// Create a checkbox in the first column
        this.headerCheck.setAttribute('type','checkbox');
        this.headerCheck.onchange = this.selectAll.bind(this);		// Bind it to the selectAll method
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Activated');	// Activated time
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Duration');		// Duration (so far) -- updated each second
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Tag');			// Node this is on
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Condition');	// AlarmEvent type
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Severity');		// Severity of alarm
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Category');		// Category of alarm
        createElement('div', 'alarm-log__cell alarm-log__header',	header, 'Acknowledged');	// user who acknowledged the alarm
		this.contentWrapper = createElement('div', 'alarm-log__table__content', this.alarmTable)
        this.alarmContent = createElement('div', 'alarm-log__table__content__alarms', this.contentWrapper);

		// Create all of our header cells for the active alarms table
        this.historical         = createElement('div', 'alarm-log__historical-table', this.tableWrapper);

        let controlsWrapper	        = createElement('div', 'alarm-log__controls', this.historical);
        let historicalControls      = createElement('div', 'alarm-log__radios', controlsWrapper);
		let filterChecks 			= createElement('div', 'alarm-log__checks hide', controlsWrapper)

		let exportButton 			= createElement('button', 'se-button', controlsWrapper, 'Export History');
		exportButton.onclick = () => {
			let endDate 	= new Date();
			let startDate 	= new Date(new Date().setDate(endDate.getDate() - 1));
			new ViewModal(new ExportView(startDate, endDate, false, (sDate, eDate) => this.requestHistory(sDate, eDate)), {
				maxWidth: 				'400px',
				maxHeight: 				'300px',
				title:					'Export Data',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)',
			});
		}
		this.back					= createElement('button', 'se-button alarm-log__time-controls', historicalControls, null, {'type':'button'});
		this.dateInput				= createElement('input', 'alarm-log__date-time', historicalControls);
		this.dateInput.type			= 'datetime-local';	// Date time local gives a date and time, but subtracts out from UTC, unfortunately
		this.dateInput.step			= 60;				// They aren't allowed to set below a minute
		this.dateInput.required		= true;				// No 'X' on the input that allows it to be cleared
		this.dateInput.onchange		= this.onDateChange.bind(this);	// Callback when the user adjusts the input
		this.forward			    = createElement('button', 'se-button alarm-log__time-controls', historicalControls, null, {'type':'button','disabled':true});
		createElement('img', '', this.forward, '', {'src': ArrowIcon});
		createElement('img', '', this.back, '', {'src': ArrowIcon}).style.rotate = '180deg';
        this.back.onclick           = () => this.getHistorical(true);
        this.forward.onclick        = () => this.getHistorical(false);

        let histHeader          = createElement('div', 'alarm-log__row alarm-log__row__historical alarm-log__row__header', this.historical);
        createElement('div', 'alarm-log__cell alarm-log__header',	histHeader, 'Time');	// Activated time
		createElement('div', 'alarm-log__cell alarm-log__header',	histHeader, 'Event');		// Duration (so far) -- updated each second
		this.histContentWrapper = createElement('div', 'alarm-log__table__content', this.historical)

        this.historicalContent  = createElement('div', 'alarm-log__table__content', this.histContentWrapper);

		let createCheck = (name) => {
			let id 						= createUniqueId();
			let checkWrapper            = createElement('div', 'se-checkbox', filterChecks);
			let checkbox            	= createElement('input', '', checkWrapper, '', {'type':'checkbox', 'id':id});
			checkbox.checked 			= true;
			createElement('label', 'explorer__filter-name', checkWrapper, name, { 'htmlFor': id });
			return checkbox;
		}

		let eventCheck = createCheck('Events');
		eventCheck.onchange = () => {
			this.historicalContent.removeChildren();
			this.filterEvents = eventCheck.checked;
			this.alarmSet.requestHistorical(new Date().getTime()*1000, true, this.displayCount, eventCheck.checked, alarmCheck.checked)
		}
		let alarmCheck = createCheck('Alarms');
		alarmCheck.onchange = () => {
			this.historicalContent.removeChildren();
			this.filterAlarms = alarmCheck.checked;
			this.alarmSet.requestHistorical(new Date().getTime()*1000, true, this.displayCount, eventCheck.checked, alarmCheck.checked)
		}

		this.alarmSet.registerCallback(this);						// Get a callback on new alarms and events nost that we've set up everything

		// Start the one-second update timer:
		assert(this.timer === undefined);
		this.timer = setInterval (this.onTimer.bind(this), 1000);
		this.alarmSet.requestHistorical(new Date().getTime()*1000, true, this.displayCount);
        this.fInitialized = true;
		return this;
	}

	resize() {									// Called with the window resizes

	}

	destroy() {
		if (this.table) {	// If no table, we weren't initialized
			clearInterval (this.timer);
			this.alarmSet.removeCallback(this);	// Remove the callback we created
			this.wrapper.destroyWidgets(true);	// Make sure we destroy any widgets
			this.wrapper.removeChildren();		// Delete any DOM elements left over
		}
	}

	onTimer() {
		for (var i = 0, alarm; alarm = this.alarmSet.alarms[i]; ++i)	// Update the timer durations for active alarms
			if (alarm.isAlarm() && alarm.isActive())
				this.setDuration(alarm);
	}

	setDuration(alarm) {				// Update duration for active alarm
		assert (alarm.isAlarm(), 'Events have no duration.');
		var startTime = alarm.tsActivated / 1000;	// Convert active time to milliseconds
		var endTime;

		if (alarm.isActive())					// Alarm is still active
			endTime = (new Date()).getTime();	// now
		else { // alarm is not active:
			if (alarm.tsDeactivated === undefined)	// We don't yet have the deactivated metadata:
				return;	// can't update the duration yet...
			endTime = alarm.tsDeactivated / 1000;	// Us the deactivated timestamp
		}
		alarm._tableRow.children[2].textContent = formatTimeSpan (endTime - startTime, false, true, true, true);	// Update duration
	}

	setCondition(alarm) {
		var element = alarm._tableRow.children[4];	// condition element
		if (alarm.fAlarm) {
			if (!alarm.isAnalog())
				element.textContent = alarm.message;
			else { // Analog alarm:
				var text = Alarm.conditionText[alarm.condition];
				var limit = alarm.node ? alarm.limit.toFixed(alarm.node.digits) : alarm.limit;
				if (alarm.condition < AlarmConditions.EQUAL) {
					text += ' (';
					if (alarm.isActive() && alarm.node)	// If alarm is active, then print the current value next:
						text += alarm.node.getFormattedText();
					text += ((alarm.condition <= AlarmConditions.LOW) ? ' < ' : ' > ') + limit + '): ';// Looks like a frowny emoticon ):
				} else {
					text += ' ' + limit + ': ';
				}
				element.innerText = text;
				var safeAlarmMessageDisplay = createElement('span', '', element);	// Use this span to safely add alarm messages as textContent
				safeAlarmMessageDisplay.textContent = alarm.message;
			}
		} else { // Simply an event:
			assert ((alarm.type == AlarmTypes.EVENT) || (alarm.type == AlarmTypes.STARTUP), 'should be startup or event');
			element.textContent = ((alarm.type == AlarmTypes.EVENT) ? 'Event: ' : 'Startup Event: ') + alarm.message;
		}
	}

	refresh() {
		for (var i = 0; i < this.alarmSet.alarms.length; ++i) {	// Check all alarms
			var alarm = this.alarmSet.alarms[i];
			 alarm._tableRow.children[1].textContent = (new Date(alarm.tsActivated/1000)).format(this.dateFormat);
		}

		for (var i = 0; i < this.historical.tbody.children.length; ++i) {
			var row = this.historical.tbody.children[i];
			row.children[0].textContent = new Date(row.event.time/1000).format(this.dateFormat);
		}
//		var utc = owner.timeZone.toUTC(new Date(this.dateInput.valueAsNumber));
		this.dateInput.value = new Date(this.latest).format('%yyyy-%MM-%ddT%HH:%mm');
	}

	createRow(alarm, fBlock) {
        let row          = createElement('div', 'alarm-log__row alarm-log__row__alarms', this.alarmContent);
        createElement('div', 'alarm-log__cell alarm-log__centered',	row);	// Check box for acknowledgement
		createElement('div', 'alarm-log__cell alarm-log__centered',	row);	// Activated time
		createElement('div', 'alarm-log__cell alarm-log__centered',	row);	// Duration
		createElement('div', 'alarm-log__cell', row);
		createElement('div', 'alarm-log__cell',	row);
		createElement('div', 'alarm-log__cell alarm-log__centered',	row);
		createElement('div', 'alarm-log__cell',	row);
		createElement('div', 'alarm-log__cell',	row);	// User who acknowledged the alarm
		row.alarm = alarm;
        let children = Array.from(this.alarmContent.children);
		for (var i = 0; i < children.length; ++i) {
            if (children[i].alarm.tsActivated < alarm.tsActivated) {
                this.alarmContent.insertBefore(row, children[i]);
				break;
			}
        }

		alarm._checkBox = createElement('input', null, row.children[0]);	// Create a checkbox in the first column
		alarm._checkBox.setAttribute('type','checkbox');
		alarm._checkBox.onchange = this.onChange.bind(this);	// Bind it to the onChange method

		alarm._tableRow = row;					// Give it a short circuit back to the row for the alarm
	}

	createButton(parent, label, fDisabled, callback, callbackArg) {
		var button		= createElement('input', 'alarmTabAcknowledge', parent);
		button.value	= Localization.toLocal(label);
		button.type		= 'button';
		button.disabled	= fDisabled;
		button.onclick	= callback.bind(this, callbackArg);
		return button;
	}

	createRadioButton(parent, text, radioID) {
		var uniqueID	= createUniqueId();	// Generate a unique ID for each radio button to get it's label attached to it

		var input		= createElement('input', 'radio-buttons__input', parent);	// Create the element
		input.type		= 'radio';		// Make it a radio button
		input.name		= radioID;		// Give it a name that will match it with other radio buttons
		input.id		= uniqueID;		// Give it the same ID we will give the label we create in a second
		input.onchange	= this.onRadioButtonChanged.bind(this);	// Tell the button what to call when the button is pressed

		var label		= createElement('label', 'writeable radioButtonLabel', parent, text);	// Create the label for the radio button
		label.setAttribute('for', uniqueID);

		return input;	// Return the button we just created
	}

	onRadioButtonChanged() {		// A radio button just changed value
		if (this.allButton.checked)			// Display all alarms
			this.alarmContent.removeAttribute('onlyshow');
		else if (this.activeButton.checked)	// Display only active alarms
			this.alarmContent.setAttribute('onlyshow', 'active');
		else {								// Display only unacknowledged alarms
			assert(this.unackButton.checked, "Do we have another radio button?");
			this.alarmContent.setAttribute('onlyshow', 'unack');
		}
	}

	onAckInput(e) {
		for (var i = 0; i < this.alarmSet.alarms.length; ++i) 	// Check the alarm box of the row we are changing only
			this.alarmSet.alarms[i]._checkBox.checked = this.alarmSet.alarms[i]._ackBox === e.target;
		this.headerCheck.checked	= false;		// The header check box shouldn't be checked anymore
		this.acknowledge.disabled	= false;		// No box was checked. Disable the acknowledge button
		if (e.keyCode == 13 || e.keyCode == 9) {	// The enter key or the tab key
			this.acknowledgeAlarms();	// Acknowledge this and only this larm
			e.preventDefault();			// Prevent the enter key from giving us the onInput call back
			e.target.value = '';		// Clear out any text they had
			e.target.blur();			// Lose focus after submitting the value
		}
	}

	onAlarmAdded(alarm) {
		this.createRow(alarm, true);	// When a row is added, create a new alarm row
	}

	onAlarmChanged(alarm) {	// An alarm has changed a value. Sync up our table
		var row		= alarm._tableRow;	// The row this alarm is attached to

		row.setAttribute('active', alarm.isAlarm() && alarm.isActive());		// Set attributes tied to CSS rules
		row.setAttribute('unack', alarm.isUnacknowledged());

		if (alarm.tag !== undefined) {	// We have the set of 'activated' metadata:
			assert(alarm.tsActivated!= undefined);	// If we have the tag name, we should have this
			assert(alarm.condition	!= undefined);	// If we have the tag name, we should have this
			assert(alarm.category	!= undefined);	// If we have the tag name, we should have this
			assert(alarm.message	!= undefined);	// If we have the tag name, we should have this
			assert(alarm.severity	!= undefined);	// If we have the tag name, we should have this

			if (!row.fInit) {		// Only fill the primary cells once:
				// Column 1: Event activation MM.DD HH:MM:SS:
				row.children[1].textContent = (new Date(alarm.tsActivated/1000)).format(this.dateFormat);

				// Column 2: Duration (set below).

				// Column 3: Tag name:
				row.children[3].textContent = alarm.tag;
				// Two other options for column 3 display:
//				row.children[3].textContent = alarm._node.name;
//				row.children[3].innerHTML = alarm.tag.replace(/(?!^)\//g, '<wbr>/');	// Allow browser to break at slashes (replace all but first slach with '/<wbr>'

				// Column 4: Alarm/Event condition:
				alarm.node = this.tree.findNode(alarm.tag);
				if (alarm.node)	// Create a widget so we can have dynamic text for analog alarms:
					new AlarmCondition(this, alarm, row.children[4]).initialize();

				// Column 5: Severity:
				row.children[5].textContent = alarm.severity;
				if (alarm.severity < 100) {				// Can't acknowledge low severity alarms and events:
					alarm._checkBox.disabled = true;	// Checkbox is disabled if the alarm was acknowledged
					alarm._checkBox.checked = false;	// We don't want checked, disabled checkboxes
					if (this.alarmSet.fAlarmsUpdated && false) {
						alarm._ackBox.disabled = true;
					}
				}

				// Column 6: Category:
				row.children[6].textContent = alarm.category;

				row.fInit = true;
			}
		}

		// Set alarm duration in column 2:
		if (alarm.isAlarm())
			this.setDuration(alarm);

		// Update alarm condition in column 4:
		if (alarm.condition !== undefined) {
			if (!alarm.node || (alarm.tsDeactivated !== undefined))	// Only update if not being updated by node.update(), or if alarm is deactivated
				this.setCondition(alarm, row.children[4]);
		}

		if (alarm.userNames.length > 0)  {	// We know the user who acknowledged the alarm
			var output = alarm.userNames.back();
			if (alarm.userNames.length > 1)
				output += " (" + alarm.userNames.length + " total)"
			row.children[7].textContent = output;

			if (this.alarmSet.fAlarmsUpdated && false) {
				alarm._ackBox.value = alarm.messages.back();
			} else {
				alarm._checkBox.disabled = true;	// Checkbox is disabled if the alarm was acknowledged
				alarm._checkBox.checked = false;	// We don't want checked, disabled checkboxes
			}
		}
	}

	onAlarmRemoved(alarm) {
		alarm._tableRow.destroyWidgets(true);	// Destroy any widgets in the table row
		alarm._tableRow.parent && alarm._tableRow.parent.removeChild(alarm._tableRow)
	}

	endAlarmCommand() {		// End of the event command. Good time to query more data
		this.onChange();	// sync up the 'Acknowledge' button -- might disable it if a bunch of 'acknowledge' metadata just arrived.
	}

	onChange() {	// A checkbox value has just changed
		for (var i = 0; i < this.alarmSet.alarms.length; ++i) {	// Check all alarms
			if (this.alarmSet.alarms[i]._checkBox.checked) {	// If any of the boxes is checked
				this.acknowledge.disabled = false;				// Enable the acknowledge button
				return;											// We've done what we came to do
			}
		}
		this.headerCheck.checked	= false;					// The header check box shouldn't be checked anymore
		this.acknowledge.disabled	= true;						// No box was checked. Disable the acknowledge button
	}

	selectAll() {	// Select all enabled checkboxes in response to the select all cell in the header
		for (var i = 0; i < this.alarmSet.alarms.length; ++i) {	// Check all alarms
			var checkBox = this.alarmSet.alarms[i]._checkBox;	// Convenience reference
			checkBox.checked = this.headerCheck.checked && !checkBox.disabled;	// If the box isn't disabled, check the box
		}
		this.onChange();	// Call our on change event to make sure we update the acknowledge button
	}

	acknowledgeAlarms() {
		var toAcknowledge = [];	// An array to hold the alarms we want to acknowledge
		for (var i = 0; i < this.alarmSet.alarms.length; ++i) {			// Check all the alarms
			var alarm = this.alarmSet.alarms[i];						// Convenience reference
			if (alarm._checkBox.checked && !alarm._checkBox.disabled){	// If the box is checked and the box isn't disabled
				if (this.alarmSet.fAlarmsUpdated && false)
					alarm.ackMessage = alarm._ackBox.value;				// Save any alarm message they have
				toAcknowledge.push(alarm);								// Add it the acknowledge array
			}
		}
		if (toAcknowledge.length > 0)					// If we found any alarms
			this.alarmSet.acknowledge(toAcknowledge);	// Try to acknowledge them

		this.onChange();	// Call our on change event to make sure we update the acknowledge button
	}

	requestHistory(start, end) {
		this.loaderContainer = createElement('div', 'chart-view__export__loader__container', document.body);
		new Loader(this.loaderContainer);
		this.fPendingExport = true;
		this.exportStartDate = start;
		this.alarmSet.requestHistorical(end.getTime()*1000, true, 100);
	}

	onHistorical(ordered, latestTime, fCurrent, fBefore, fUnprompted) {	// Got historical alarms back
		if (this.fPendingExport && !fUnprompted)
			this.exportHistorical(ordered, latestTime, fCurrent, fBefore, fUnprompted);
		else
			this.createHistoricalList(ordered, latestTime, fCurrent, fBefore, fUnprompted);
	}

	exportHistorical(ordered, latestTime, fCurrent, fBefore, fUnprompted) {
		this.alarmsToExport.push(...ordered);
		if (ordered.back().time > (this.exportStartDate.getTime() * 1000))
			this.alarmSet.requestHistorical(ordered.back().time, true, 100);
		else {
			this.createCSV(this.alarmsToExport);
			this.fPendingExport = false;
			this.alarmsToExport = [];
		}
	}

	createCSV(events) {
		console.log(events.length);
		let csvText = `Time,Type,Alarm Condition,Alarm Tag Name,Alarm Message\n`;
		for (let i = 0; i < events.length; ++i) {	// Check all of the events we just got
			let event = events[i];					// Convenience indirection
			if (event.time < this.exportStartDate.getTime() * 1000)
				break;
			console.log(event);
			csvText += `${new Date(event.time / 1000).format('%yyyy/%MM/%dd %HH:%mm:%ss')},${AlarmTypeNames.get(event.type)},${AlarmTypeText.get(event.condition)},${event.tag ?? event.tag.name},${this.buildEventText(event)}\n`
		}

		let downloadLink 		= document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
		downloadLink.download 	= `${this.device.siteName}_Event-History_${new Date().toLocaleDateString()}.csv`;		// File name to download as
		downloadLink.href 		= URL.createObjectURL(new Blob([csvText], {type:'text/plain'}));	// Make a blob text file URL for the CSV
		downloadLink.click();							// Simulate clicking on the hyperlink
		document.body.removeChild(this.loaderContainer);
	}

	createHistoricalList(ordered, latestTime, fCurrent, fBefore, fUnprompted) {
		if (fUnprompted && this.forward.disabled === false)	// If these are unpropted and we aren't current
			return;											// Don't show these alarms (the user is looking at older alarms)

		while (ordered.length + Array.from(this.historicalContent.children).length > this.displayCount)	// While we have too many elements
			this.historicalContent.removeChild(fBefore ? this.historicalContent.firstChild : this.historicalContent.lastChild);	// Remove elements

		for (var i = 0; i < ordered.length; ++i) {	// Check all of the events we just got
			var event = ordered[i];					// Convenience indirection
            let row          = createElement('div', 'alarm-log__row alarm-log__row__historical', this.historicalContent);
			row.event = event;						// Save a reference to the event on each row
            createElement('div', 'alarm-log__cell alarm-log__centered',	row, (new Date(event.time/1000)).format(this.dateFormat));	// Time cell in the first column
			createElement('div', 'alarm-log__cell', row, this.buildEventText(event));		// Create a cell and give it the text we just created
			this.historicalContent.insertBefore(row, fBefore ? Array.from(this.historicalContent.children)[this.displayCount] : this.historicalContent.firstChild);	// Add it to the front or back of the table
		}

		this.forward.disabled = fCurrent;	// Disable the forward button if we are current
		if (this.fDateChange !== true && latestTime > 0) {	// If the user didn't specifically change the data
			this.latest = latestTime / 1000;
			this.dateInput.value = new Date(this.latest).format('%yyyy-%MM-%ddT%HH:%mm');	// Update the current date shown
		}
		this.fDateChange = false;			// Date change is now false regardless
	}

	buildEventText(event) {
		var what;	// Build up a human-readable string on what happened at this time
		if (event.type == AlarmTypes.ACTIVATED) {	// If this was an activated alarm
			what = `${AlarmTypeText.get(event.condition)} on ${event.tag}.`;	// Say what type of alarm activated
			if (Alarm.isAnalogCondition(event.condition)) {
				what += ` Value ${AlarmConditionDescription.get(event.condition)}`;
				if (event.condition <= AlarmConditions.VERY_HIGH || event.condition >= AlarmConditions.EQUAL)
					what += ` ${event.setting}`;
			}
			if (event.message)
				what += ` (Message: '${event.message}')`;
		} else if (event.type == AlarmTypes.DEACTIVATED)	// Alarm deactivated
			what =  `Alarm on ${event.tag} deactivated after ${formatTimeSpan((event.time - event.activated)/1000, false, true, true, true)}`;
		else if (event.type == AlarmTypes.ACKNOWLEDGED)		// Alarm was acknowledged
			what = event.userName + ' acknowledged ' + event.tag + ' ' + Alarm.conditionText[event.condition] + ' alarm after ' + formatTimeSpan((event.time - event.activated)/1000, false, true, true, true)
			+ ": " + event.message;
		else											// Discrete alarm/event
			what = event.message;						// List the message as the payload
		return what;
	}

	getHistorical(fBefore) {		// The forward or back button has been clicked
		var row = fBefore ? this.historicalContent.lastChild : this.historicalContent.firstChild;	// Get the extreme timestamp we care about (first or last)
		if (row)
			this.alarmSet.requestHistorical(row.event.time, fBefore, this.displayCount, this.filterEvents, this.filterAlarms);			// Request more events in the correct direction
		else
			this.onDateChange();
	}

	onDateChange() {				// The date selector has been modified
		this.fDateChange = true;			// Set the date change flag so when the result comes back we don't update the date selector under them
		this.latest = owner.timeZone.toUTC(new Date(this.dateInput.valueAsNumber))*1000;
		this.alarmSet.requestHistorical(this.latest*1000, true, this.displayCount, this.filterEvents, this.filterAlarms);	// Request more events
	}
};

// Alarm Condition Widget:
// This widget is simply here to subscribe to an alarm node and keep the alarm message updated while the
// alarm is active. It unsubscribes once the alarm is deactivated.
class AlarmCondition extends Widget {
    constructor(alarmsTab, alarm, element) {
        super();
        this.alarmsTab	= alarmsTab;
        this.alarm		= alarm;
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element	= element;
        this.fInitialized = false;
        this.registerAsWidget(element);			// register this element as a widget
    };

    initialize() {
        this.fInitialized = true;
        this.alarm.node.subscribe(this, this.element, 0xFFFFFFFF);	// Don't use the red x after all. It looks bad.
    };

    update(node) {	// node value/quality changed:
        assert(node == this.alarm.node);
        assert (this.fInitialized);

        if (this.alarm.isActive() || !this.fCondition) {	// Only update the condition if active (or first time):
            this.alarmsTab.setCondition(this.alarm);		// Just feed the change back to the alarmsTab
            this.fCondition = true;
        }
    };

    destroy = function () {
        assert(this.fInitialized);
		if (this.alarm.node)
        	this.alarm.node.unsubscribe(this);
        this.unregisterAsWidget();
    };
};
