import { createElement } from "../../elements";
import NodeManager from "../../nodemanager";
import EditorPage from "../../pages/editorpage";
import { PumpBank } from "../../pumpbank";
import { RoleFilter, SettingsFilter, VTypeFilter } from "../../tagfilter";
import { StyleCategories } from "../../views/dashboardstyleview";
import TagSocket from "../tagsocket";
import { LiveDataGizmo } from "./gizmo";
import owner from '../../../owner';
import { Role } from "../../role";

export class PumpBankGizmo extends LiveDataGizmo {
    systemSocket: TagSocket;
    dpoSocket: TagSocket;
    nodeManager: NodeManager = new NodeManager(this);
    pumpBank: PumpBank;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.BORDER, StyleCategories.BACKGROUND, StyleCategories.TYPOGRAPHY]
        this.systemSocket = new TagSocket(owner.ldc, 'Pump System', this, [new RoleFilter([Role.ROLE_PUMP_BANK, Role.ROLE_ALTERNATOR_FOLDER], true)], [], false, []);
        this.dpoSocket = new TagSocket(owner.ldc, 'DPO Pump System', this, [new RoleFilter([Role.ROLE_DPO_FOLDER], true)], [], false, []);
        this.sockets.set(this.systemSocket.name, this.systemSocket);
        this.sockets.set(this.dpoSocket.name, this.dpoSocket);
        this.wrapper = createElement('div', 'full__width full__height', this);
        this.systemSocket.refreshTags();
        this.dpoSocket.refreshTags();
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '100%');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '180px');
        }
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
    }

    onResize(): void {
        if (this.pumpBank)
            this.pumpBank.resize();
    }

    rebuild() {
        if (this.pumpBank)
            this.pumpBank.destroy();
        this.wrapper.removeChildren();
        this.nodeManager.destroy();
        let [systemTag] = this.systemSocket.tags;
        if (!systemTag)
            return;
        else
            this.showWarning();
        this.hideWarning();
        let [dpoTag] = this.dpoSocket.tags;
        this.pumpBank = new PumpBank(systemTag, this.wrapper, dpoTag);
    }
}