import {createElement} from '../elements';
import { NodeQuality} from '../node'
import Localization from '../localization';
import View from './view';
import owner from '../../owner';
import assert from '../debug';
import Color from '../colors';
import './resultsview.css';
import LiveData from '../livedata';
import { StaticGraph } from '../graph';
import { TagUnit, TagUnitQuantity, UnitsMap, convert } from '../widgets/lib/tagunits';
import { Role } from '../role';

export default class ResultsView extends View {
    constructor(device, ldc) {
        super();
        this.ldc			= ldc;			// The LiveDataClient we need to query data
        this.device			= device;		// The device that is currently in focus
    };

	initialize(parent) {
		super.initialize(parent);
		const rootNode      = this.device.tree.nodes[0];
        const pumpSystem	= rootNode.findChildByRole(Role.ROLE_PUMP_BANK);;	// The pump system folder node
		this.savingsFolder	= pumpSystem.findChildByRole(Role.ROLE_SAVINGS_FOLDER);	// The Savings Folder node
		const dpoFolder     = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);

        var totalFlow		= pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);	// Find the total flow node
		this.flowDigits 	= totalFlow.digits;
        this.flowConversion = convert(1, TagUnit.TU_GPM, totalFlow.units);	// So we can remember what units the flow are in
        this.flowUnits		= totalFlow.getUnitsText();
        this.id				= this.ldc.registerGraph(this);						// ID so we can register for live data commands
        this.pumps		    = pumpSystem.findByRole(Role.ROLE_PUMP);	// Find the count of pumps once

        // The trial data response method down below is hard coded to expect this data:
        this.trialFlags		= 	LiveData.TRIAL_DATA_VOLUME +				// We want the volume on a trial basis
                                LiveData.TRIAL_DATA_ENERGY + 			// We want the energy on a trial basis
                                LiveData.TRIAL_DATA_WATER_ENERGY + 		// We want the water energy on a trial basis
                                LiveData.TRIAL_DATA_POTENTIAL_ENERGY + 	// We want the potential energy on a trial basis
                                LiveData.TRIAL_DATA_DPO_STATE;			// We want the time optimizing on a trial basis

        assert(pumpSystem.findChildByRole(Role.ROLE_SEC), "The SEC node is not in the device tree.");
        var secNode			= pumpSystem.findChildByRole(Role.ROLE_SEC);
        this.secMax			= secNode.engMax;								// We need this so we dont plot something from the trials graph with an absurdly high SEC
        this.energyCost		= pumpSystem.findChildByRole(Role.ROLE_ENERGY_COST);	// Cost in $ / kW
        this.currency		= UnitsMap.get((this.energyCost.units - TagUnitQuantity.TUQ_ENERGYCOST) + TagUnitQuantity.TUQ_CURRENCY).abbrev;
        this.dpoInControl	= dpoFolder.findChildByRole(Role.ROLE_DPO_IN_CONTROL);
        this.advisoryMode	= dpoFolder.findChild('AdvisoryMode');

        this.volumeNode	= this.savingsFolder.findChildByRole(Role.ROLE_VOLUME_TODAY);	// Volume today (GMT)
        this.energyNode	= this.savingsFolder.findChildByRole(Role.ROLE_ENERGY_TODAY);	// Energy today (GMT)
        this.savingNode	= this.savingsFolder.findChildByRole(Role.ROLE_SAVINGS_TODAY);	// Savings today (GMT)
        this.volConversion	= convert(1, TagUnit.TU_MG, this.volumeNode.units);				// Hang on to the volumetric conversion
        this.volUnits		= this.volumeNode.getUnitsText();				// Hang on to the volumetric units text
        this.secConversion	= convert(1, TagUnit.TU_KW_HR_PER_MG, secNode.units);	// Hang on to the SEC conversion
        this.secUnits		= secNode.getUnitsText();										// Hang on to the SEC units text

		this.wrapper = createElement('div', 'resultsMainWrapper', this.parent)
		var daySummary = createElement('div', null, this.wrapper);	// Overall wrapper
		createElement('div', 'savingsTitle', daySummary, 'Today');	// Create a title for the division

		var gaugeWrapper = createElement('div', 'gaugeWrapper', daySummary);	// Wrapper to hold the last 24 hour gauges
		this.volumeDiv	= this.createGauge(gaugeWrapper, 'Volume (' + this.volUnits + ')');	// Create guages to hold statistics about the day so far
		this.energyDiv	= this.createGauge(gaugeWrapper, 'Energy (' + this.energyNode.getUnitsText() + ')');
		this.secDiv		= this.createGauge(gaugeWrapper, 'Specific Energy (' + this.secUnits + ')');
		this.savingDiv	= this.createGauge(gaugeWrapper, 'Projected Reduction (%)');

		var historical = createElement('div', null, this.wrapper);						// Create a couple graphs that summarize of the last xx days of operation
		var title = createElement('div', 'savingsTitle', historical, 'From');			// Create a title for the division
		this.startInput	= createElement('input', 'savingsInput', title);					// Input element that defines the start of the interval
		createElement('label', null, title, 'to');
		this.endInput	= createElement('input', 'savingsInput', title);					// Input element that defines the end of the interval
		this.inputValidText = createElement('div', 'inputValidText', title);
		this.inputValidText.setAttribute('id', 'inputValidText');
		this.startInput.type = this.endInput.type = 'date';									// Both are a date, meaning no time selection is possible
		this.startInput.required = this.endInput.required = true;							// Both are required to get rid of HTML's weird x's

		var date = new Date();
		this.endInput.value = date.format('%yyyy-%MM-%dd');									// Set the end of the interval to midnight

		// Request trial data for the day so far in the browser's time zone. One day, make this the box's time zone. We do this so we can
		// compute the totals for the day so far in this time zone. The nodes that are available to us are in GMT and we need to do the math
		// to correct that. To keep the running total update, we will track changes in the GMT nodes
		var endUTC = owner.timeZone.toUTC(new Date(this.endInput.value));
		this.ldc.getTrialData(this.id, this.device.id, endUTC, Math.floor(new Date().getTime()/1000), true, this.trialFlags, 0);

		date.setDate(date.getDate()-30);
		this.startInput.value = date.format('%yyyy-%MM-%dd');							// Default the start to 30 days back
		this.startInput.onkeydown = this.endInput.onkeydown = this.onInputKey.bind(this);
		this.startInput.oninput = this.endInput.oninput = this.onInputChange.bind(this);	// We will request data when the inputs change

		var dailySummary	= createElement('div', 'savingsSummarySection', historical);
		var dailyWrapper	= createElement('div', 'savingsGraphDiv', historical);	// Wrapper for the daily summaries graph and legend
		this.summaryVol		= this.createSummaryEntry(dailySummary, 'Volume:');			// Add a bunch of summary displays
		this.summaryUsed	= this.createSummaryEntry(dailySummary, 'Energy Used:');
		this.summarySaved	= this.createSummaryEntry(dailySummary, 'Energy Reduction:');
		this.summaryPercent	= this.createSummaryEntry(dailySummary, 'Percent Reduction:');
		this.summarySavings	= this.createSummaryEntry(dailySummary, 'Cost Reduction:');
		this.summaryEnabled	= this.createSummaryEntry(dailySummary, 'DPO Mode:');

		this.volumeGraphDiv	= createElement('div', null, dailyWrapper);					// Division for the daily summaries graph
		this.dailyGraph		= createElement('div', null, dailyWrapper);					// Division for the daily summaries graph

		var trialWrapper = createElement('div', 'savingsGraphDiv', historical);		// Wrapper for the operation point graph and legend
		this.trialGraph = createElement('div', 'trialGraphDiv', trialWrapper);					// Division for the operation point graph
		this.trialLegend = createElement('div', 'savingsLegend', trialWrapper);		// Legend for the operation point graph
		var trialOptions = createElement('div', 'savingsLegend', trialWrapper);		// Add checkboxes for showing/hiding Optimizing or non-Optimizing data
		createElement('label', null, trialOptions, 'Optimizing:');
		this.fOptBox	= createElement('input', 'savingsCheckbox', trialOptions);
		createElement('label', null, trialOptions, 'Not Optimizing:');
		this.fNoptBox	= createElement('input', 'savingsCheckbox', trialOptions);
		this.fOptBox.type = this.fNoptBox.type = 'checkbox';
		this.fOptBox.checked = this.fNoptBox.checked = true;
		this.fOptBox.onchange = this.fNoptBox.onchange = this.onCheckboxChange.bind(this);

		this.onInputChange(); // Query trial data for both graphs
		this.volumeNode.subscribe(this);	// Go ahead and subscribe to these nodes so they are good when we need them
        this.energyNode.subscribe(this);
        this.savingNode.subscribe(this);
        this.dpoInControl.subscribe(this);
        this.advisoryMode.subscribe(this);
        this.energyCost.subscribe(this);

		this.fInitialized = true;
		return this;
	}

	update(node) {	// Called when a Node changes
		if (!this.fShown) return;
		if (node == this.energyCost && this.summarySavings) {
			if (node.quality == NodeQuality.NQ_GOOD && !isNaN(this.totalSavingsFromBuildGraph))
				this.summarySavings.innerHTML = (this.totalSavingsFromBuildGraph*this.energyCost.getValue()).toCurrency(this.currency);
			else
				this.summarySavings.innerHTML = "";
			return;
		}
		if (this.dayVolume === undefined)	// If we don't have the trials for the day so far, ignore these messages
			return;
		// The totals from the day are calculated as the integral of the trials from the day plus the changes in the GMT nodes since
		// we got trial data

		if (isNaN(this.startVolume) && (this.volumeNode.quality == NodeQuality.NQ_GOOD))
			this.startVolume = this.volumeNode.getValue();								// Check to confirm quality of nodes
		if (isNaN(this.startEnergy) && (this.energyNode.quality == NodeQuality.NQ_GOOD))
			this.startEnergy = this.energyNode.getValue();								// Check to confirm quality of nodes
		if (isNaN(this.startSaving) && (this.savingNode.quality == NodeQuality.NQ_GOOD))
			this.startSaving = this.savingNode.getValue();

		if ((this.energyNode.quality == NodeQuality.NQ_GOOD) &&
			(this.volumeNode.quality == NodeQuality.NQ_GOOD)) {

			// Confirm that the startEnergy and startVolume are good
			assert(!isNaN(this.startEnergy), "NaN must have propogated!");
			assert(!isNaN(this.startVolume), "NaN must have propogated!");

            // Numbers should all be good, procede with calculations

			var volume = (this.volumeNode.getValue() - this.startVolume) + this.dayVolume*this.volConversion;	// New daily volume
			var energy = (this.energyNode.getValue() - this.startEnergy) + this.dayEnergy;						// New daily energy
			var saving = (this.calculateSavingsFromNodes() - this.startSaving) + this.daySaving;				// New daily savings

			this.volumeDiv.textContent	= volume.toFixed(2);	// Update our gauges with the new values
			this.energyDiv.textContent	= energy.toFixed(2);
			this.secDiv.textContent		= volume > 0 ? (energy/(volume/this.volConversion)*this.secConversion).toFixed(1) : '-';
			this.savingDiv.textContent	= energy > 0 && (this.dpoInControl.getValue() || this.advisoryMode.getValue()) ? (100*saving/(energy+saving)).toFixed(1) : '-';
		}
		else
		{
			// Don't have reliable numbers, set to nothing... should we red X them?
			this.volumeDiv.textContent = "";
			this.energyDiv.textContent = ""
			this.secDiv.textContent = "";
			this.savingDiv.textContent = "";
		}
	}

	createSummaryEntry(parent, label) {
		var wrapper = createElement('div', 'savingsSummaryWrapper', parent);		// Create the wrapper for the whole shebang
		var background = createElement('div', 'savingsBackground', wrapper);
		createElement('div', 'savingsSummaryLabel', background, Localization.toLocal(label));				// Label for the summary
		return createElement('div', 'savingsSummaryValue', background);				// Return the value display for the summary
	}

	createLegendEntry(parent, text, color, runningCount) {		// Create a legend entry
		var wrapper	= createElement('div', 'legendindicatorwrapper');	// Wrapper for the whole entry
		var title	= createElement('div', 'legendindicator', wrapper, text);	// Colored text for the entry
		title.style.backgroundColor = color;
		wrapper.classList.add('savingsClickable');								// Make it have a pointer when hovered
		wrapper.runningCount = runningCount;									// Store running count on each wrapper so we can sort them
		wrapper.fHidden = false;												// All are visible at the start
		title.onclick = this.onLegendClick.bind(this, wrapper);					// Onclick, hide the corresponding data in the graph
		for (var i = 0; i < parent.children.length; ++i)						// Check where the wrapper should be in order
			if (wrapper.runningCount < parent.children[i].runningCount) {		// If this wrapper has less running pumps than the next wrapper
				parent.insertChildAt(wrapper, i);								// Insert this node before the one we just checked
				break;															// Found a home for the wrapper, leave
			}
		if (wrapper.parentNode === null)
			parent.appendChild(wrapper);
		wrapper.index = parent.children.length - 1;								// Remember this guy's index cause its difficult to look up later
	}

	onLegendClick(wrapper) {				// A legend entry has been clicked
		wrapper.fHidden = !wrapper.fHidden;			// If shown, hide the line or visa versa
		wrapper.classList.toggle('savingsGray');	// Make the text gray when the line is hidden
		this.secGraph.dygraph.setVisibility(2*wrapper.index, this.fNoptBox.checked && !wrapper.fHidden);	// Update the lines' visibility for this regime
		this.secGraph.dygraph.setVisibility(2*wrapper.index+1, this.fOptBox.checked && !wrapper.fHidden);
	}

	onCheckboxChange() {	// One of the checkboxes has been clicked
		var fNoptVis = this.fNoptBox.checked, fOptVis = this.fOptBox.checked;	// Get visibility statuses
		var fVisible = [];			// We will pass this array to dygraph
		for (var i = 0; i < this.trialLegend.children.length; ++i) {	// For each regime
			var wrapper = this.trialLegend.children[i];					// Get the wrapper
			fVisible.push(fNoptVis && !wrapper.fHidden, fOptVis && !wrapper.fHidden);	// Show/hide each line as appropriate
		}
		this.secGraph.dygraph.updateOptions(null, {visibility: fVisible},  false);		// Update dygraph
	}

	onInputKey(e) {
		if ((e.keyCode == 38 || e.keyCode == 40) && e.currentTarget.value) {						// Up or down arrow key
			e.currentTarget.previousDate	= (new Date(e.currentTarget.value)).getTime();			// Remember the number before the change
			e.currentTarget.fUpArrow		= e.keyCode == 38;										// Remember which arrow was pressed
		}
	}

	onInputChange() {		// One of the spinners that define our interval has changed
		if (this.startInput.previousDate)	// If this is an arrow key operation
			this.fixDate(this.startInput);	// Check the output
		if (this.endInput.previousDate)		// If this is an arrow key operation
			this.fixDate(this.endInput);	// Check the output
		if (this.timerID !== undefined)	// If the is a timer running
			clearTimeout(this.timerID);	// Clear it
		this.timerID = setTimeout(this.onInputHitch.bind(this), 300);	// Delay requesting data for a 0.3 seconds incase the button is rapidly chaning
	}

	onInputHitch() {	// It has been 0.3 seconds since the user changed the input
		delete this.timerID;	// Delete the old timerID (which has fired) and request the new data
		var startUTC = owner.timeZone.toUTC(new Date(this.startInput.value));
		var endUTC = owner.timeZone.toUTC(new Date(this.endInput.value));
		if(startUTC <= endUTC) {
			this.ldc.getTrialData(this.id, this.device.id, startUTC, endUTC, false, this.trialFlags, 0);
			this.inputValidText.innerHTML = "";
		} else { 				// tell user their dates need fixing
			this.inputValidText.innerHTML = "Invalid date entry.";
		}
	}

	fixDate(dateInput) {
		var date = new Date(dateInput.previousDate);									// Create a date representing the starting time
		var inputAsTimeStamp = (new Date(dateInput.value)).getTime();
		var step = inputAsTimeStamp - dateInput.previousDate;					// How many milliseconds of changed happened
		var abs = Math.abs(step);
		if ((step < 0|| isNaN(inputAsTimeStamp)) && dateInput.fUpArrow)			// Up arrow was pressed and date didn't increase
			dateInput.value = (new Date(step > 31.1*86400*1000 ? date.setMonth(date.getMonth() + 1) : date.setDate(date.getDate() + 1))).format('%yyyy-%MM-%dd');	// Manually crank it up
		else if ((step > 0 || isNaN(inputAsTimeStamp)) && !dateInput.fUpArrow)	// Down arrow was pressed and date didn't decrease
			dateInput.value = (new Date(step > 31.1*86400*1000 ? date.setMonth(date.getMonth() - 1) : date.setDate(date.getDate() - 1))).format('%yyyy-%MM-%dd');	// Manually crank it down
		delete dateInput.previousDate;													// Remove the reminder to check the day
	}

	destroy() {
		if (this.fInitialized) {
			this.volumeNode.unsubscribe(this);	// Unsubscribe to the nodes
			this.energyNode.unsubscribe(this);
			this.savingNode.unsubscribe(this);
			this.dpoInControl.unsubscribe(this);
			this.advisoryMode.unsubscribe(this);
			this.energyCost.unsubscribe(this);
		}
		if (this.timerID !== undefined)		// Clear all timers that might exist
			clearTimeout(this.timerID);
		if (this.midnightID !== undefined)
			clearTimeout(this.midnightID);
		if (this.midnightUtcID !== undefined)
			clearTimeout(this.midnightUtcID);
		if (this.id)
			this.ldc.unregisterGraph(this.id);	// Remove the graph ID we created
		this.parent.destroyWidgets(true);	// Don't need to destroy our graphs. That will happen here automagically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}

	createGauge(parent, label) {	// Convenience method to avoid copy/pasting code
		var wrapper = createElement('div', 'savingsGauge digital-gauge', parent);	// Wrapper for the gauge
		createElement('div', 'digital-label', wrapper).innerHTML = label;						// Label for the gauge
		return createElement('div', 'savingsValue', wrapper);						// Return the value display for the guage
	}

	array (length) {	// Quick method to generate an array of a certain size with all memebers initialized to zero
		var array = [];
		while (length--)array[length]=0;
		return array;
	}

	// Instead of making this method a little more generic, I've gone ahead and done everything
	// related to building up my savings graphs here. That way, I don't have to iterate through
	// the data twice or make two queries. I go through once and build up both data sets I need
	// before creating graphs that summarize the recent operation points and plots summary data.
	onTrialDataResponse(fp) {	// Just got trial data. Fill out our two graphs
		if(!this.ldc.isLoggedIn()) 		// A user should be logged in
			return;

		this.start			= fp.pop_u32();	// Start of interval
		this.end				= fp.pop_u32();	// End of interval
		var startInputUTC	= owner.timeZone.toUTC(new Date(this.startInput.value));
		var endInputUTC		= owner.timeZone.toUTC(new Date(this.endInput.value));
		if (this.dayVolume !== undefined && (this.start != startInputUTC || this.end != endInputUTC)) {
			fp.skip(fp.size());
			return;
		}

		var fBaseline		= fp.pop_u8();	// If the baseline data is appended
		var trialFlags		= fp.pop_u8();	// The trial table data that is appended
		var savingsFlags	= fp.pop_u8();	// The savings table data that is appended

		assert(trialFlags == this.trialFlags, "Trial flags don't match on trial data response!");
		assert(savingsFlags == 0, "Saving flags don't match on trial data response!");
		assert(this.pumps.length > 0, "Didn't find any pumps...");

		if (fBaseline) {							// If the baseline has come across as well
			this.baseline = [];						// Create an array to store the baseline
			this.baseline.type = fp.pop_u8();		// Valid baseline type is either 0 or 1 (system efficiency or wire to water efficiency)
			this.baseline.time = fp.pop_u32();
			if (this.baseline.type === 0) {			// If the type is daily system efficiency
				this.baseline[0] = fp.pop_f64();	// a
				this.baseline[1] = fp.pop_f64();	// b
				this.baseline[2] = fp.pop_f64();	// c
			} else {								// We are not system efficiency. Either wire to water or baseline invalid
				this.baseline.qMin	=	fp.pop_f32();
				this.baseline.qMax	=	fp.pop_f32();
				this.baseline.qN	=	fp.pop_u16();
				this.baseline.hMin	=	fp.pop_f32();
				this.baseline.hMax	=	fp.pop_f32();
				this.baseline.hN	=	fp.pop_u16();
				var count = this.baseline.qN * this.baseline.hN;
				for (var i = 0; i < count; ++i)
					this.baseline[i] = [fp.pop_f32()];	// Pop each point's efficiency
			}
		}

		this.points = [];			// To hold all the trials
		var count	= fp.pop_s32();	// How many trials were passed back
		assert(count > 0, "No trial data came back. Weird!");
		for (var i = 0; i < count; ++i) {			// For each point
		var point = {timestamp: fp.pop_u32()};	// Start of trial
			this.points.push(point);
			point.duration	= fp.pop_u32();			// Duration
			point.config	= fp.pop_u64();			// Mask of running pumps
			point.volume	= fp.pop_f32();			// Volume pumped in MG
			point.energy	= fp.pop_f32();			// Energy used in kW-h
			point.waterEn	= fp.pop_f32();			// Water energy in ft-MG
			point.potEn		= fp.pop_f32();			// Potential energy in ft-MG
			point.state		= fp.pop_f32();			// Optimizer state
		}
		if (this.dayVolume === undefined)				// If we haven't got the stuff the day yet
			this.calculateDaySoFar(this.points, this.start,this. end);	// That's what this query is
		else	{										// Else
			this.buildGraphs(this.points, this.start, this.end);		// Build the interval summary graphs
		}
	}

	resize() {
		if (this.parent.clientHeight == 0)
			return;

		this.buildGraphs(this.points, this.start, this.end)
	}

	calculateSavings(baseEff, energyUsed, energyAdded) {	// Calculate energy saved from a baseline efficiency (0-1), energy used (kW-h), and energy added (ft-MG)
		if (energyAdded === 0 || baseEff === 0)
			return 0;
		var actEff = energyUsed > 0 ? energyAdded * 3.136492403 / energyUsed : 0;	// Calcuate the actual efficiency
		return 3.136492403*energyAdded*(1/baseEff - 1/actEff);						// Calculate the savings
	}

	valToIndex(val, min, max, n) {
		var index = Math.floor((val - min) / (max - min) * n);	// Based on the min, max, and count for this axis, interpolate index
		return Math.max(0, Math.min(index, n-1));				// Clamp everything to sane limits
	}

	getWireToWaterSavings (duration, volume, energy, waterEnergy) {
		var	q	= duration > 0 ? volume * 1E6 / (duration / 60.0) : 0;	// Compute average flow in GPM
		var	h	= (volume > 0) ? (waterEnergy / volume) : 0;			// Compute average head in feet
		var i	= this.valToIndex(q, this.baseline.qMin, this.baseline.qMax, this.baseline.qN);	// Find the x index we need
		var j	= this.valToIndex(h, this.baseline.hMin, this.baseline.hMax, this.baseline.hN);	// Find the y index we need
		return this.calculateSavings(this.baseline[i + this.baseline.qN * j], energy, waterEnergy);	// Return the savings for this point
	}

	getSystemEfficiencySavings (volume, energy, potentialEnergy) {
		return this.calculateSavings(this.baseline[0]*volume*volume + this.baseline[1]*volume + this.baseline[2], energy, potentialEnergy);
	}

	calculateSavingsFromNodes() {
		var value = this.savingNode.getValue() / 100;	// Get the value of the savings node and scale it 0-1
		return this.energyNode.getValue() * value / (1-value);	// Return the energy saved based on the energy used and the savings value
	}

	calculateDaySoFar(points, start, end) {	// We just got trial data from midnight to now
		this.dayVolume = 0;	// Create members to hold the day's volume, energy used, and energy saved in the trials
		this.dayEnergy = 0;
		this.daySaving = 0;
		var dayPotEn = 0;	// And a local to hold total potential energy added
		for (var i = 0; i < points.length; ++i) {	// For each point
			var p = points[i];				// Convenience reference
			this.dayVolume += p.volume;		// Accrue volume pumped in MG
			this.dayEnergy += p.energy;		// Accrue energy used in kW-h
			dayPotEn += p.potEn;			// Accrue potential energy added in ft-MG
			if (this.baseline.type === 1 && start >= this.baseline.time && p.state > 1 && p.energy > 0)	// If this is a wire-to-water baseline
				this.daySaving += this.getWireToWaterSavings(p.duration, p.volume, p.energy, p.waterEn);	// Accruse savings based on water energy
		}

		// Please note the only thing we are prorating is the volume here. We are not prorating the energy used nor the potential energy. This is because
		// we aren't prorating the energy saved when we do the percent savings calculation. Since we have prorated none of the energies, the math falls out.
		// However, this means you shouldn't display the energy saved value directly. It must be prorated first.
		if (this.baseline.type === 0 && start >= this.baseline.time)				// If this is a system efficiency baseline, calculate energy saved
			this.daySaving = this.getSystemEfficiencySavings(this.dayVolume * 86400 / (end - start), this.dayEnergy, dayPotEn);

		if ((this.volumeNode.quality == NodeQuality.NQ_GOOD) && (this.energyNode.quality == NodeQuality.NQ_GOOD)) {
			this.startVolume = this.volumeNode.getValue();	// Record the current state of the nodes so we can add in the node changes as the day progresses
			this.startEnergy = this.energyNode.getValue();
			this.startSaving = this.calculateSavingsFromNodes();
		}
		this.update();									// Force an update to set all the display values
		this.refresh();
	}

	refresh() {
		if (this.midnightID) {					// If we already have timeouts, clear them (TimeZone changed)
			clearTimeout(this.midnightID);
			clearTimeout(this.midnightUtcID);
		}

		var now = new Date();															// Current time
		var midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0, 0);	// Midnight tomorrow (local time)
		var difference = (midnight.getTime() - now.getTime());							// How long until midnight tomorrow (local time)
		var utcDifference = owner.timeZone.toUTC(midnight)*1000 - now.getTime();		// How long until midnight UTC
		this.midnightID		= setTimeout(this.onMidnight.bind(this), difference);		// Set a timer to go off a midnight
		this.midnightUtcID	= setTimeout(this.onMidnightUTC.bind(this), utcDifference);	// Set a timer to go off a midnight UTC
	}

	onMidnight() {	// It's midnight here
		if ((this.volumeNode.quality == NodeQuality.NQ_GOOD) && (this.energyNode.quality == NodeQuality.NQ_GOOD)) {
			this.startVolume = this.volumeNode.getValue();			// We want to accrue values from this node value going forward
			this.startEnergy = this.energyNode.getValue();
			this.startSaving = this.calculateSavingsFromNodes();
		}
		this.dayVolume = this.dayEnergy = this.daySaving = 0;	// Clear out any saved data
		this.midnightID = setTimeout(this.onMidnight.bind(this), 86400*1000);
	}

	onMidnightUTC() {	// It's midnight GMT
		if ((this.volumeNode.quality == NodeQuality.NQ_GOOD) && (this.energyNode.quality == NodeQuality.NQ_GOOD)) {
			this.dayVolume += (this.volumeNode.getValue() - this.startVolume) / this.volConversion;	// Add in any savings from the nodes before they get lost
			this.dayEnergy += (this.energyNode.getValue() - this.startEnergy);
			this.daySaving += (this.calculateSavingsFromNodes() - this.startSaving);
		}
		this.startVolume = this.startEnergy = this.startSaving = 0;								// Clear out the node starting points, since they are about to reset
		this.midnightUtcID = setTimeout(this.onMidnightUTC.bind(this), 86400*1000);
	}

	buildGraphs(points, start, end) {	// Build graphs summerizing the interval
		if(this.savingsGraph)
			this.savingsGraph.destroy();
		if(this.secGraph)
			this.secGraph.destroy();
		if(this.volumeGraph)
			this.volumeGraph.destroy();		// removed because the volume and savings graph are technically one widget
		var secDigits = this.secConversion == 1 ? 0 : 3;
		var days	= (end - start) / 86400;						// Count of days we got
		var configData = [];										// Temporary array to store our config data
		var minFlow = Number.MAX_VALUE, minSEC = Number.MAX_VALUE;	// Min flow and SEC we see in any trial
		var maxFlow = 0, maxSEC = 0;								// Max flow and SEC we see in any trial
		var totalV = 0, totalE = 0, totalT = 0, totalS = 0;			// Aggregates for the entire interval
		var dayV = this.array(days), dayE = this.array(days), dayT = this.array(days);	// Aggregate volume, energy, time in DPO mode for each day
		var dayP = this.array(days), dayS = this.array(days);							// Aggregate potential energy, energy saved for each day
		for (var i = 0; i < points.length; ++i) {					// For each point
			var p = points[i];										// Convenience reference
			var index = Math.floor((p.timestamp - start) / 86400);	// Find the index of the day this belongs to
			if (p.state > 1) {										// If we were in optimizer mode
				dayT[index]	+= p.duration;							// Add the duration of the trial to the appropriate day
				totalT		+= p.duration;							// Add the duration of the trial to the total time
			}
			dayE[index]	+= p.energy;
			totalE		+= p.energy;

			if(p.volume === 0 || p.config === 0)	// If no volume or no pumps on, no point in doing the rest of the math for the point
				continue;

			// Add this record to the trial maps
			var flow		= p.volume*60*1000000/p.duration*this.flowConversion;	// Average flow rate
			var sec 		= p.energy/p.volume*this.secConversion; 				// SEC for the trial
			if (p.energy > 0 && p.duration > 600 && sec > 1 && sec<=this.secMax) {	// Non zero, non infinite SEC with a good duration on it
				var data = configData[p.config];	// Get the arrays for this configuration
				if (!data)							// Haven't created this configuration yet
					data = configData[p.config] = {optFlows: [], optSECs: [], noptFlows:[], noptSECs:[]};

				var f = p.state > 1 ? data.optFlows : data.noptFlows;	// Grab the optimizing or non-optimizing arrays
				var s = p.state > 1 ? data.optSECs : data.noptSECs;
				for (var j = 0; j < p.duration; j += 600) {	// Every ten minutes gets another point
					f.push(flow);				// X-axis is the flow in GPM
					s.push(sec);				// Y-axis is the SEC in kW-h/MG
				}

				minFlow = Math.min(minFlow, flow);	// Find the limits as we go
				maxFlow = Math.max(maxFlow, flow);
				minSEC = Math.min(minSEC, sec);
				maxSEC = Math.max(maxSEC, sec);
			}

			dayV[index] += p.volume*this.volConversion;	// Accrue volume, energy, and potential energy for the appropriate day
			dayP[index] += p.potEn;

			totalV += p.volume*this.volConversion;		// Accrue total volume and energy for the interval

			if (this.baseline.type === 1 && p.timestamp >= this.baseline.time && p.state > 1 && p.energy > 0)	// If this is a wire-to-water baseline
				dayS[index] += this.getWireToWaterSavings(p.duration, p.volume, p.energy, p.waterEn);	// Accrue savings for the appropriate day
		}

		if (maxFlow == 0) {
			maxFlow = maxSEC = 10;
			minFlow = minSEC = 0;
		}
		var deltaFlow = Math.max(10*this.flowConversion, (maxFlow - minFlow) / 20);	// 5% of the flow difference
		var deltaSEC = Math.max(10*this.secConversion, (maxSEC - minSEC) / 20);	// 5% of the SEC difference
		var counts = [];		// Parallel array to hold the count of pumps on in this configuration so we can sort them by running count in the legend
		var trialData 	= [[]];	// Start off with an empty array for line names in our trial data
		var fVisible = [];		// Array of visibility for dygraph
		var commonChars = 0;	// Figure out how many common characters exist between pumps
		if (this.pumps.length > 1) {	// If there's more than one pump
			var name1 = this.pumps[0].getDisplayName();	// Get the display name for each pump
			var name2 = this.pumps[1].getDisplayName();
			for (var i = 0; i < name1.length; ++i) {	// Check each string character by character
				if (name1.charAt(i) == name2.charAt(i))	// If the names mach
					++commonChars;						// Increment common characters
				else									// Characters don't match
					break;								// Leave the loop
			}
		}
		for (var i = 0; i < configData.length; ++i) {	// Iterate through our configs
			var data = configData[i];					// Convenience reference
			if (!data)			// If we didn't create this configuration
				continue;		// Skip ahead
			var name = '';		// Build up the name we want to display this configuration as
			var count = 0;
			for (var j = 0; j < this.pumps.length; ++j)// For each pump
				if (i & (1 << j)) {					// If it was on in the mask
					var toAdd = this.pumps[j].getDisplayName().substring(commonChars);	// Get the substring exclusive to this pump
					name += name.length > 0 ? ', ' + toAdd : ' ' + toAdd;	// Add the number to the string (with a comma if appropriate)
					++count;						// Add up the count of total pumps running
				}
			trialData[0].push(this.pumps[0].getDisplayName().substring(0, commonChars) + name);	// Create the non-optimized full name and add it to the SE Data array
			trialData[0].push(trialData[0].back() + 'Opt');		// Create the optimized full name
			counts.push(count);									// Add the count
			trialData.push(data.noptFlows, null, data.noptSECs, null);	// Add the non-optimized data itself to the data to plot
			trialData.push(data.optFlows, null, data.optSECs, null);	// Add the optimizeddata itself to the data to plot
			fVisible.push(this.fNoptBox.checked, this.fOptBox.checked);	// Keep track of visibilities
		}

		this.trialLegend.removeChildElements();			// Remove any previous legend entries
		var colors = [];								// Define an array to hold each line's color
		for (var i = 0; i < trialData[0].length; i += 2) {	// For each configuration we ended up with
			colors.push(Color.hslToRgb(i / trialData[0].length,1,.3));	// Add colors that are evenly separated around the wheel
			colors.push(colors.back());						// Add the color again for the sister line
			this.createLegendEntry(this.trialLegend, trialData[0][i], colors.back(), counts[i/2]);		// Create a legend entry for each configuration
		}

		this.secGraph = new StaticGraph(this.ldc, this.trialGraph, this.trialGraph.clientWidth, 300, trialData, {	// Create a graph to plot the trial data
			colors: colors,											// Colors for the pump counts
			connectSeparatedPoints: false,							// No lines between points
			drawPoints: true,										// Put a circle at the point
			drawY2Line: true,
			visibility:	fVisible,									// Individual line visibilities
			pointSize: 2,											// How big to make the circle
			alpha: 0.15,											// The points should be pretty transparent
			xMarksTheSpot: true,									// Make the point's circle and X
			xAxisAsNumber: true, 									// Interpret the x-axis as numbers, not times
			axes: {													// This is how Dygraph delimits per-axis options
				x: { 												// Add options for the x-axis
					axisLabelFormatter: (e) => e.toFixed(this.flowDigits)	// Define a function to kill x-axis labels
				}
			},
			digits: secDigits,
			digitsAfterDecimal: secDigits,
			dateWindow: [minFlow - deltaFlow, maxFlow + deltaFlow],	// X-axis gets 5% of the flow range on either side
			valueRange: [minSEC - deltaSEC, maxSEC + deltaSEC],		// Y-axis gets 5% of the SEC range on either side
			xlabel: "Flow [" + this.flowUnits + "]",				// X-axis title
			ylabel: "Specific Energy [" + this.secUnits + "]"		// Y-axis title
		});

		minSEC = Number.MAX_VALUE, maxSEC = 0;	// Reset the min and max SEC numbers so we can find the range across all days
		var maxSavings = 0.001;					// Max savings starts out positive so we don't show losses
		var timestamps = [];					// Array to hold the timestamp for each day
		for (var i = 0; i < days; ++i) {				// For each day in the interval
			timestamps[i] = (start + 86400*i)*1000;		// Calculate the timestamp
			if (this.baseline.type === 0 && timestamps[i]/1000 >= this.baseline.time && dayE[i] > 0) 	// If the baseline is a system efficiency baseline
				dayS[i] = this.getSystemEfficiencySavings(dayV[i]/this.volConversion, dayE[i], dayP[i]);	// Calculate total savings for the day now that all the accruing is complete

			if (dayT[i] === 0)	// If the time in DPO mode for the day is zero
				 dayS[i] = 0;	// The savings is 0
			totalS += dayS[i];	// Accrue total savings from the daily savings now that we know savings is calculated
			dayS[i] = dayE[i] > 0 ? 100 * dayS[i] / (dayS[i] + dayE[i]) : 0;	// Recalculate savings to be percent savings instead of energy saved
			if (dayS[i] > maxSavings)	// If this is the biggest savings we have seen
				maxSavings = dayS[i];	// Keep track of that
			dayT[i] *= 100 / 86400;		// Convert time in DPO mode to percent of time instead of seconds in DPO mode

			if(dayV[i] > 0) {							// If the day has a positive volume
				dayE[i] *= this.secConversion / dayV[i] * this.volConversion;	// Turn this from an energy to an SEC
				minSEC = Math.min(minSEC, dayE[i]);		// Keep track of min and max SEC as we go
				maxSEC = Math.max(maxSEC, dayE[i]);
			}
		}
		deltaSEC = Math.max(10*this.secConversion, (maxSEC - minSEC) / 20);	// 5% of the SEC difference	on each side of the graph

		// Set up the daily summary arrays
		var dailyData	= [['Volume', 'SEC'],				// Row labels
		             	   timestamps, null, dayV, null,	// Volume time stamps, minimums, averages, and maximums
		             	   timestamps, null, dayE, null];	// SEC time stamps, minimums, averages, and maximums
		var savingsData	= [['Savings', 'PercentDPO'], 		// More labels
		               	   timestamps, null, dayS, null,	// Percent savings time stamps, minimums, averages, and maximums
		               	   timestamps, null, dayT, null];	// Percent of time in DPO mode time stamps, minimums, averages, and maximums

		// Create a couple of graphs to plot the daily data
		// var bargraphSize = 900 / ((end - start) / 86400) * 0.85;	// Size the bargraph based on how many days we got. width = 900px, 15% of graph is whitespace between bars
		var bargraphSize = this.volumeGraphDiv.clientWidth / (days * 1.5);	// Size the bargraph based on how many days we got. width = 900px, 15% of graph is whitespace between bars
		var fBargraph = bargraphSize > 2;							// If the bars get too thin, abandon the bar graph style
		this.volumeGraph = new StaticGraph(this.ldc, this.volumeGraphDiv, this.volumeGraphDiv.clientWidth, 211, dailyData, {
			strokeWidth: 2,											// Make lines this thick
			colors: [owner.colors.hex('--color-brand'), owner.colors.hex('--color-secondary')],								// Colors for the volume and SEC
			customAxis: [false, true],								// Put volume on the left axis
			ylabelcolor: owner.colors.hex('--color-brand'),									// Left y-axis title color
			y2labelcolor: owner.colors.hex('--color-secondary'),									// Right y-axis title color
			yAxisLineColor: owner.colors.hex('--color-brand'),									// Color to make the left axis labels and line
			y2AxisLineColor: owner.colors.hex('--color-secondary'),								// Color to make the right axis labels and line
			drawPoints: true,										// Put circles at the points
			pointSize: 2,											// How big to make the circles at points
			dateWindow: [(start-43200)*1000, (end-43200)*1000],		// Put 12 hours on either side of the graph (and make timestamps milliseconds)
			secondAxisRange: [minSEC-deltaSEC, maxSEC+deltaSEC],	// Use our calculated SEC range (5% of range gap on each side)
			digitsAfterDecimal: 0,									// Display everything as an integer
			legend: 'true',											// We want a legend
			xAxisLegend: false,
			yAxisLabelWidth: 40,
			legendCallback:	this.volumeLegend.bind(this),			// And call this method to create the legend
			traditionalHover: true,									// Use Dygraph's old hover style, which jumps to points instead of interpolating
			ylabel: 'Volume [' + this.volUnits + ']',				// Left y-axis title
			y2label: "Specific Energy [" + this.secUnits + "]",		// Right y-axis title
			bargraphWidth: bargraphSize,							// Make any bar graphs this many pixels wide
			Volume: {												// Create an option set for a single line (a line with the name 'Volume')
				bargraph: fBargraph, 								// Display this line as a bar graph
				connectSeparatedPoints: !fBargraph,					// Do not connect lines on the bar graph
				digits: 1,
				units: this.volUnits
			},
			SEC: {digits:secDigits, digitsAfterDecimal: secDigits, units: this.secUnits, seriesRange: [minSEC-deltaSEC, maxSEC+deltaSEC]}
		},  true);	// This true means to allow hover events on the graph
		this.savingsGraph = new StaticGraph(this.ldc, this.dailyGraph, this.dailyGraph.clientWidth, 149, savingsData, {
			colors: [owner.colors.hex('--color-brand'), owner.colors.hex('--color-secondary')],							// Colors for the Savings and the DPO Operation percentage
			customAxis: [false, true],								// Savings on the left, enabled on the right
			ylabelcolor: owner.colors.hex('--color-brand'),									// Left y-axis title color
			yAxisLineColor: owner.colors.hex('--color-brand'),								// Color to make the left axis labels and line
			y2AxisLineColor: owner.colors.hex('--color-secondary'),								// Color to make the right axis labels and line
			dateWindow: [(start-43200)*1000, (end-43200)*1000],		// Put 12 hours on either side of the graph (and convert times to milliseconds)
			digitsAfterDecimal: 0,									// Display everything as an integer
			legend: true,											// We want a legend
			yAxisLabelWidth: 40,
			minorXLines:			0,					// 0 horizontal minor lines between major lines
			legendCallback:	this.savingsLegend.bind(this),			// And call this method to create the legend
			ylabel: "Energy Reduction [%]",							// Left y-axis title
			y2label: "DPO Mode [%]",								// Right y-axis title
			dateString: "%DDDD, %MM/%dd/%yyyy",
			xAxisHeight: 2,											// How tall to make the x-axis division
			drawPoints: true,										// Draw points on the graph
			pointSize: 2,											// How big to make the circles at points
			axes: {													// This is how Dygraph delimits per-axis options
				x: { 												// Add options for the x-axis
					axisLabelFormatter() { return '';}	// Define a function to kill x-axis labels
				},
				y: {												// Add options for the y-axis
					ticker: this.createSparseTicks					// Define a function to supply y-axis tick marks
				}
			},
			pixelsPerLabel: 15,										// Pixels per label (sets what ticks to make)
			valueRange: [0, maxSavings*1.1],						// Y-axis range
			secondAxisRange: [0, 101],								// X-axis range is easy, as it is a percentage
			traditionalHover: true,									// Use Dygraph's old hover style, which jumps to points instead of interpolating
			bargraphWidth: bargraphSize,							// Make any bar graphs this many pixels wide
			Savings: {												// Create an option set for a single line (a line with the name 'Savings')
				bargraph: fBargraph, 								// Display this line as a bar graph
				connectSeparatedPoints: !fBargraph,					// Do not connect lines on the bar graph
				digits: 1,
				units: "%"
			},
			PercentDPO: {
				digits: 1,
				units: "%", seriesRange: [0, 101]
			},
		},  true);	// This true means to allow hover events on the graph

		this.summaryVol.innerHTML = totalV.toFixed(1) + ' ' + this.volUnits;	// Fix up the interval totals
		this.summaryUsed.textContent = totalE.toFixed(1) + ' kWh';
		this.summaryEnabled.textContent = (100*totalT/(end-start)).toFixed(1) + '%';
		this.summarySaved.textContent = totalS.toFixed(1) + ' kWh';
		this.summaryPercent.textContent = (100*totalS/(totalS+totalE)).toFixed(1) + '%';
		this.totalSavingsFromBuildGraph = totalS;
		if (this.energyCost.quality == NodeQuality.NQ_GOOD)					// Check if this node has a sensible value
			this.summarySavings.innerHTML = (totalS*this.energyCost.getValue()).toCurrency(this.currency);
		else															// Don't use the value since it is bad
			this.summarySavings.innerHTML = "       ";
	}

	savingsLegend (timestamp, points) {		// There was a hover event on the savings graph
		this.callGraph(this.volumeGraph, timestamp);	// Make sure the other graph is up to date
	}

	volumeLegend (timestamp, points) {		// There was a hover event on the volume graph
		this.callGraph(this.savingsGraph, timestamp);	// Make sure the other graph is up to date
	}

	callGraph (graph, timestamp) {	// Attempt to call the other graph to make it update it's hover location
		if (!this.locked && graph) {			// If we aren't locked and the other graph exists (one of them doesn't on first call)
			this.locked = true;					// Set the lock
			graph.dygraph.hover(timestamp);		// Call the other graph to update the hover location
			this.locked = false;				// Reset the lock
		}
	}

	// This method will return what Dygraph wants for tick marks. Dygraph wants an array with two members returned. The first member should
	// be the array of tick marks. The second member should be the pixels between tick marks.  The array of tick marks should be an array of
	// objects that have two members: 'v', which holds the tick value, and 'label', which has the text for the tick mark.
	createSparseTicks(min, max, pixels, opts, dygraph, vals) {
		var roundedMax = Math.floor(max *.95);	// Make the max a round number
		if (roundedMax % 2 != 0)	// If roundedMax isn't even
			--roundedMax;			// Make it so

		// Create our ticks
		var ticks = [{v: 0},  {v: roundedMax/2},  {v: roundedMax}];	// Three ticks, 0, halfway to the max, and the max
		for (var i = 0; i < ticks.length; i++) 		// Add labels to the ticks.
			ticks[i].label = ticks[i].v.toString();	// Just make it the number.toString value. They should already be rounded enough

		return [ticks, roundedMax/2/(max-min)*pixels];	// Return an array with the ticks and the spacing between the ticks
	}
};

