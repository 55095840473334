import Icon from '../../../images/icons/name.svg';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import DeviceIcon from '../../../images/icons/device.svg';
import DisconnectedIcon from '../../../images/icons/disconnected.svg';
import { type Device } from '../../../device';

const ImageID = 'status-image';

const template = /*html*/`
<style>
    :host{
        display: flex;
        height: 32px;
        width: 32px;
    }
    img {
        width: 100%;
        height: 100%;
    }
</style>
<img id=${ImageID}></div>`

@RegisterWidget({tag: 'device-status', displayName: 'Device Status', template: template, icon: Icon, section: 'Tag Information'})
export class DeviceStatus extends Widget {
    @Attribute({displayName: 'Connected Image'}) connectedIcon: string;
    @Attribute({displayName: 'Disconnected Image'}) disconnectedIcon: string;

    @TagAttribute({displayName: 'Tag', supportedTypes: ['root']}) rootNode: TagDefinition;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let device = this.rootNode.tag.device;
        let image = this.shadowRoot?.getElementById(ImageID) as HTMLImageElement;
        this.onConnectionStatusChanged(device, image);
    }

    onConnectionStatusChanged(device: Device, image: HTMLImageElement) {
        if (device.connected) {
            image.src = this.connectedIcon ?? DeviceIcon;
            device.onConnect.delete(this)
            device.onDisconnect.set(this, () => this.onConnectionStatusChanged(device, image));
        }
        else {
            image.src = this.disconnectedIcon ?? DisconnectedIcon;
            device.onDisconnect.delete(this)
            device.onConnect.set(this, () => this.onConnectionStatusChanged(device, image));
        }
    }

    protected onDisconnect(): void {
        let device = this.rootNode?.tag?.device;
        if (device) {
            device.onDisconnect.delete(this);
            device.onConnect.delete(this);
        }
    }
}