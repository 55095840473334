/**
 * ExtraTabView provides links to all the views
 * that we can't put in the tab bar
 */
import {createElement} from '../elements';
import owner from '../../owner';
import ArrowIcon from '../images/icons/arrow_forward.svg';
import './extratabview.css';
import View from './view';
import { getHash, getRouteAndProperties } from '../router/router';

export default class ExtraTabView extends View {
    constructor () {
        super();
    }
    initialize(parent) {
        super.initialize(parent);
        this.wrapper    = createElement('div', 'extra-tab-view__page-wrapper', this.parent)
        var tabList     = createElement('ul', 'extra-tab-view__list', this.wrapper)
        this.tabs = owner.currentPage.tabBar.tabList;
        var tabCount = 0;
        for (let [key, value] of Object.entries(this.tabs)) {
            if (tabCount>3) {
                var tabListItem = createElement('div', 'extra-tab-view__list__item', tabList);
                createElement('img', 'extra-tab-view__list__item__icon', tabListItem, null, {'src':value.icon});
                createElement('div', 'extra-tab-view__list__item__title', tabListItem, value.name);
                createElement('img', 'extra-tab-view__list__item__icon-arrow', tabListItem, null, {'src':ArrowIcon});
                tabListItem.onclick = () => location.hash = getHash(...getRouteAndProperties(location.hash, {'tab':value.name}));
            }
            tabCount++;
        }
        this.fInitialized = true;
        return this;
    }

    addItem(item) {
        this.content.appendChild(item);
    }
};
