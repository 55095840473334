import { Attribute } from "../lib/attributes";
import { TagAttribute } from "../lib/tag";
import { RegisterWidget, Widget } from "../lib/widget";
import type { Tag, TagDefinition } from "../lib/tag";
import { TagUnit, convert } from '../lib/tagunits';

const template = /*html*/`
<style>
    :host {
        display: inline-block;
        min-width: 96px;
        height: 24px;
    }
    #input {
        font-family: 'Roboto Mono', monospace;
        width: 100%;
        height: 100%;
        border: 1px solid var(--color-primaryFixedDim);
        background-color: var(--color-surface);
        color: var(--color-onSurface);
        font-size: 1em;
        text-align: right;
        border-radius: 4px;
        padding: 0;
    }
    #wrapper {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: flex;
    }
    #input:focus {
        outline: none;
    }
    #input:disabled {
        cursor: not-allowed;
        background-color: var(--color-surfaceContainerHigh);
        opacity: 0.7;
    }
    #units {
        font-size: 0.75em;
        display: flex;
        align-items: center;
        height: 100%;
        opacity: 0.7;
    }
    #units::before {
        white-space: pre;
        content: " ";
    }
</style>
<div id="wrapper">
    <input type="time" id="input" disabled>
    <div id="units"></div>
</div>
`.trim();

@RegisterWidget({tag:'date-time-setpoint', roles:['StartSecondOfDay', 'EndSecondOfDay'], displayName: 'Numeric Setpoint'})
export class DateTimeSetpoint extends Widget {
    valueElement: HTMLInputElement;
    @Attribute({displayName: 'Step'}) step: number;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;

    @TagAttribute({
        displayName: 'Setpoint Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['numeric']
    }) setpoint: TagDefinition;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void
    {
        this.valueElement = this.shadowRoot?.getElementById('input')! as HTMLInputElement;
        this.valueElement.disabled = !this.setpoint.tag.isWriteable;
        this.valueElement.step = this.step ? this.step.toString() : this.setpoint.tag.resolution.toString();
        if (this.showUnits)
        {
            let unitsElement = this.shadowRoot!.getElementById('units')!;
            unitsElement.textContent = this.setpoint.tag.unitsText ?? '';
        }
        let tag = this.setpoint.tag;
        this.valueElement.onchange = (e) => tag.setPendingValue(convert(this.valueElement.valueAsNumber, TagUnit.TU_MSECONDS, tag.units), this);
    }

    update(tag: Tag): void {
        let mSeconds = tag.convertValue(TagUnit.TU_MSECONDS, this);
        this.valueElement.valueAsNumber = mSeconds;
    }
}