import View from "./view";
import { createElement, createUniqueId } from '../elements';
import Localization from '../localization';
import owner, { Routes } from '../../owner';
import assert from '../debug'
import Loader from "../loader";
import RefreshIcon from '../images/icons/refresh.svg';
import DeviceImage from '../images/Atlas_Pump.jpg';
import PLCIcon from '../images/icons/plc.svg';
import UploadIcon from '../images/icons/upload.svg';
import AddCircleIcon from '../images/icons/add_circle.svg';
import InfoIcon from '../images/icons/info.svg';
import RestoreIcon from '../images/icons/restore.svg';
import DownloadIcon from '../images/icons/download.svg';
import Dialog, { WritesEnabler } from "../dialog";
import ViewModal from "../viewmodal";
import LiveData from "../livedata";
import TextInput from "../components/textinput";
import SiteAccessView from "./siteaccessview";
import ConfigFormElement from "../configform";
import { PDSType } from "../device";
import { Node, NodeFlags, typeMap } from '../node';
import FrameParser from "../frameparser";
import { Device, Driver } from "../device";
import { GroupInfo } from '../livedataclient';
import './devicesettingsview.css'
import ConfigTabView from './configtabview';
import ConfigTabViewClassic from "./configtabview_classic";
import LiveDataClient from '../livedataclient';
import { Role } from "../role";
import { getHash } from "../router/router";

export class DeviceConfigurationView extends View {
	key: string | undefined;
	tag: string | undefined;
	unassignedSites: any[]; //TODO: Type?
	companyKey: string | undefined;
	driverName: string;
	id: number;
	status: HTMLElement;
	settings: HTMLElement;
	devImage: HTMLElement;
	sortedKeys: string[];
	sortedTags: string[];
	sortedStatus: number[];
	device: Device;
	driverOptions: HTMLElement;
	groupOptions: HTMLElement;
	root: ConfigFormElement;
	groupsButton: HTMLElement;
	siteSettings: HTMLElement;
	ldc: LiveDataClient;
	columns: string[] = ['Driver','Source','Location','Tag Path','Tag Type','Write Flag','Log Flag','Resolution','Raw Min','Raw Max','Scaled Min','Scaled Max']
	constructor(ldc: LiveDataClient, key: string, tag?: string, companyKey?: string) {
		super();
		this.ldc 			= ldc;
		this.key 			= key;
		this.tag 			= tag;
		this.unassignedSites= [];
		this.companyKey 	= companyKey ?? this.ldc.user.companyKey;
		this.id 			= this.ldc.registerGraph(this);
	}

	initialize(parent: HTMLElement) {
		super.initialize(parent);
		this.wrapper 		= createElement('div', 'dev-config-view__wrapper', this.parent);
		this.status 		= createElement('div', 'dev-config-view__status', this.wrapper);
		let settingsWrapper = createElement('div', 'dev-config-view__settings-wrapper', this.wrapper);
		createElement('div', 'dev-config-view__settings-wrapper__fader', settingsWrapper)
		this.settings = createElement('div', 'dev-config-view__settings', settingsWrapper);
		//this.devImage		= createElement('img', 'dev-config-view__status__image', this.status, undefined, {'src':DeviceImage});

		this.fInitialized = true
		owner.ldc.getServiceTags(this.id, owner.ldc.user.companyKey);
		return this;
	}

	onServiceTagsResponse(tags: string[], keys: string[], status: number[]) {
		this.unassignedSites = [];
		for (let j = 0; j < owner.sortedDevices.length; ++j) {	// For each site
			let device = owner.sortedDevices[j];				// Convenience reference

			if (keys.indexOf(device.key) != -1)	// If this site is currently assigned
				continue;								// Keep going
			let option = createElement('option', 'UserSiteRow', undefined, device.siteName);		// Create an option
			option.value = device.key;
			this.unassignedSites.push(option);
		}

		this.sortedKeys = [keys[0]];					// List of all service tags sorted alphabetically
		this.sortedTags = [tags[0]];
		this.sortedStatus = [status[0]];

		for (let i = 1; i < keys.length; ++i) {				// For each device
			for (let j = 0; j <= this.sortedKeys.length; ++j) {
				if (j == this.sortedKeys.length) {
					this.sortedKeys.push(keys[i]);
					this.sortedTags.push(tags[i]);
					this.sortedStatus.push(status[i])
					break;
				}
				else if (keys[i] < this.sortedKeys[j]) {
					this.sortedKeys.splice(j, 0, keys[i]);
					this.sortedTags.splice(j, 0, tags[i]);
					this.sortedStatus.splice(j, 0, status[i])
					break;
				}
			}
		}

		this.buildGuts();
	}

	buildGuts() {
		this.settings.removeChildren();
		// Device Settings
		let deviceSettings = createElement('div', 'dev-config-view__settings__sub', this.settings);
		createElement('div', 'dev-config-view__settings__title', deviceSettings, 'Tagger Settings');

		// Device Information
		let settingsOptions = createElement('div', 'dev-config-view__settings__sub__options', deviceSettings);
		/*
		let information 	= createElement('div', 'dev-config-view__settings__sub__option', settingsOptions);
		createElement('img', 'dev-config-view__settings__sub__option__icon', information, undefined, {'src':InfoIcon})
		createElement('div', 'dev-config-view__settings__sub__option__title', information, 'Device Information');
		*/
		this.key = this.key ? this.key : (this.tag && this.sortedKeys[this.sortedTags.indexOf(this.tag)] !== undefined) ? this.sortedKeys[this.sortedTags.indexOf(this.tag)] : undefined;
		if (this.key) {
			this.tag = this.sortedTags[this.sortedKeys.indexOf(this.key)];
			this.device = owner.ldc.devices.getByKey(this.key)!;

			assert(this.device);
			if (!this.device._connected) {
				this.settings.removeChildren();
				this.device.onConnect.set(this, this.buildGuts)
				let loadingContainer = createElement('div', 'dev-config-view__settings__loading', this.settings);
				new Loader(loadingContainer);
				createElement('div', 'dev-config-view__settings__loading__text', loadingContainer, 'Connecting to ' + this.device.siteName);
				return;
			}

			let siteName = this.device.siteName;

			// Site Settings
			let siteSettings = createElement('div', 'dev-config-view__settings__sub')
			createElement('div', 'dev-config-view__settings__title', siteSettings, siteName + ' Settings');
			this.settings.prepend(siteSettings);

			// Driver Settings
			if (this.device.tagger) {
				createElement('div', 'dev-config-view__settings__subtitle', siteSettings, 'Drivers');
				this.driverOptions = createElement('div', 'dev-config-view__settings__sub__options', siteSettings);
				this.device.requestDrivers(this.onGetDriversResponse.bind(this));
			}

			// Tag Tree Settings
			createElement('div', 'dev-config-view__settings__subtitle', siteSettings, 'Tag Tree');
			let tagTreeOptions = createElement('div', 'dev-config-view__settings__sub__options', siteSettings);
			this.device.requestNodeTree(() => this.onNodeTreeComplete(tagTreeOptions, this.device));
			/* //TODO: Get License info and show it here.
			// License Settings
			createElement('div', 'dev-config-view__settings__subtitle', siteSettings, 'License');
			let licenseOptions	= createElement('div', 'dev-config-view__settings__sub__options', siteSettings);
			let license			= createElement('div', 'dev-config-view__settings__sub__option', licenseOptions);
			createElement('div', 'dev-config-view__settings__sub__option__title', license, 'Tagger Basic');
			createElement('div', 'dev-config-view__settings__sub__option__units', license, '02/24/2021 - 02/24/2022');
			*/

			// Group Settings
			createElement('div', 'dev-config-view__settings__subtitle', siteSettings, 'Groups');
			this.groupOptions = createElement('div', 'dev-config-view__settings__sub__options', siteSettings);
			owner.ldc.getGroups(this.id, this.companyKey ?? owner.ldc.user.companyKey);

			// Device Reset
			let reset = createElement('div', 'dev-config-view__settings__sub__option', settingsOptions);
			createElement('img', 'dev-config-view__settings__sub__option__icon', reset, undefined, { 'src': RefreshIcon })
			createElement('div', 'dev-config-view__settings__sub__option__title', reset, 'Reset Device');
			let resetOptions = {
				title: 'Reset Device',
				titleBackground: 'var(--color-error)',
				titleColor: 'var(--color-inverseOnSurface)',
				fButtons: true,
				body: 'WARNING: You are about to reset this device (Service Tag: ' + this.tag + ') to factory settings. We will save a backup of ' + siteName + ' that can be restored in the future.',
				callback: () => {
					new WritesEnabler(() => { owner.ldc.assignSiteKey(this.id, this.tag!, '') });
				}
			}
			reset.onclick = () => new Dialog(document.body, resetOptions);
		}
		else if (this.tag/* && this.sortedTags.indexOf(this.tag) != -1*/) {
			let restore = createElement('div', 'dev-config-view__settings__sub__option', settingsOptions);
			createElement('img', 'dev-config-view__settings__sub__option__icon', restore, undefined, { 'src': RestoreIcon })
			createElement('div', 'dev-config-view__settings__sub__option__title', restore, 'Restore from Backup');
			let selectOptions = {
				fSelect: true,
				title: 'Restore from Backup',
				titleBackground: 'var(--color-primary)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'Select backup to assign to this device.',
				options: this.unassignedSites,
				callback: (option: any) => new WritesEnabler(()=>{ owner.ldc.assignSiteKey(this.id, this.tag!, option.value) })
			}
			restore.onclick = () => {
				new Dialog(document.body, selectOptions);
			}

			let addSite = createElement('div', 'dev-config-view__settings__sub__option', settingsOptions);
			createElement('img', 'dev-config-view__settings__sub__option__icon', addSite, undefined, { 'src': AddCircleIcon })
			createElement('div', 'dev-config-view__settings__sub__option__title', addSite, 'Add New Site');
			let dialogOptions = {
				fText: true,
				title: 'Create New Site',
				titleBackground: 'var(--color-primary)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'Please enter a name for your new site.',
				callback: (name: string) => { owner.ldc.createDeviceKey(this.id, this.tag!, name, owner.timeZone.localZone) }
			}
			addSite.onclick = () => new Dialog(document.body, dialogOptions);
		}
		else
			assert(false, 'DeviceConfigurationView must be passed either a valid service tag or a device key')
	}

	onCreateDeviceKey(fp: FrameParser) {
		let kosher = fp.pop_u8();
		if (kosher) {
			let deviceKey = fp.pop_string();
			owner.ldc.getDeviceInfo(deviceKey);
			this.modal?.destroy();
		}
		else
			new Dialog(document.body, {
				title: 'Error',
				body: 'The new site could not be created at this time. Please try again.',
				titleBackground: 'var(--color-error)',
				titleColor: 'var(--color-inverseOnSurface)'
			});
	}

	onNodeTreeComplete(parent: HTMLElement, device: Device) {
		let fTagger = device.tree.nodes[0]!.findChildByRole(Role.ROLE_VERSION);

		/*
		let tagTree	= createElement('div', 'dev-config-view__settings__sub__option', parent);
		createElement('div', 'dev-config-view__settings__sub__option__stat', tagTree, `${device.tree.getLoggedCount()}`);
		createElement('div', 'dev-config-view__settings__sub__option__units', tagTree, 'Logged Tags');
		*/

		let EditTags = createElement('div', 'dev-config-view__settings__sub__option', parent);
		createElement('img', 'dev-config-view__settings__sub__option__icon', EditTags, undefined, { 'src': DownloadIcon })
		createElement('div', 'dev-config-view__settings__sub__option__title', EditTags, 'Bulk Tag Configuration');
		let configView = fTagger ? new ConfigTabView(this.device, this.ldc, { deviceKey: device.key }) : new ConfigTabViewClassic(this.device)
		EditTags.onclick = () => new ViewModal(configView, {
			title: 'Tag Configuration',
			titleBackgroundColor: 'var(--color-primary)',
			titleTextColor: 'var(--color-inverseOnSurface)',
			parent: this.modal ? this.modal.wrapper : this.wrapper,
			closeCallback: () => {
				owner.ldc.getGroups(this.id, this.companyKey!);
				return true;
			}
		});
		/*
		let uploadTags	= createElement('div', 'dev-config-view__settings__sub__option', parent);
		createElement('img', 'dev-config-view__settings__sub__option__icon', uploadTags, undefined, {'src':UploadIcon})
		createElement('div', 'dev-config-view__settings__sub__option__title', uploadTags, 'Upload New Tag Map');
		uploadTags.onclick = () => {
			let fileInput = createElement('input', undefined, undefined, undefined, {'type':'file', 'accept':'text/csv'});
			fileInput.onchange = () => {
				let reader = new FileReader();			// Create file reader
				if (fileInput.files && fileInput.files[0]) {
					reader.onload = () => this.onFileLoad(reader.result as string);
					reader.readAsText(fileInput.files[0])
				}
			}
			fileInput.click();
		}
		*/
	}

	onFileLoad(data: string) {
		let rowArray = data.split(/\r?\n/);

		for (let i = 0; i < rowArray.length; ++i) {
			let cellArray = rowArray[i].split(',');
		}
		console.log(data);
	}

	generateCSV(drivers: Driver[]) {
		//let csvText = '';

		let csvText = `Driver,Source,Location,Tag Path,Tag Type,Write Flag,Log Flag,Resolution,Raw Min,Raw Max,Scaled Min,Scaled Max`;
		this.device.tree.nodes.forEach(node => {
			if (!node || !(node.flags & NodeFlags.NF_USER))
				return
			let driver: Driver = drivers.find((driver: Driver) => driver.name == node.driver)!;
			if (!driver)
				return;
			csvText += `\r\n${node.driver},${node.dataType},${node.registerName},${node.getDeviceRelativePath()},${typeMap.get(node.vtype)},${(node.flags & NodeFlags.NF_WRITE) == 0 ? 1 : 0},${(node.flags & NodeFlags.NF_LOG) == 0 ? 1 : 0},${node.resolution},${node.rawMin ?? ''},${node.rawMax ?? ''},${node.engMin ?? ''},${node.engMax ?? ''}`
		});
		var encodedUri = encodeURIComponent(csvText);
		var link = document.createElement("a");
		link.setAttribute("href", 'data:attachment/csv,' + encodedUri);
		link.target = '_blank';
		link.setAttribute("download", `${this.device.siteName}_TAG_MAP.csv`);
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file
		console.log(csvText);
	}

	onGetGroupsResponse(groups: GroupInfo[]) {
		this.groupOptions.removeChildren();
		let deviceGroups = groups.filter((group) => {
			return group.devices.some((key: string) => key == this.key)
		})
		let addGroup = createElement('div', 'dev-config-view__settings__sub__option', this.groupOptions);
		createElement('img', 'dev-config-view__settings__sub__option__icon', addGroup, undefined, { 'src': AddCircleIcon })
		createElement('div', 'dev-config-view__settings__sub__option__title', addGroup, 'Add to Group');
		addGroup.onclick = () => new ViewModal(new SiteAccessView(this.ldc, undefined, this.companyKey, this.key, this.id), {
			maxWidth: '400px',
			maxHeight: '500px',
			title: 'Device Groups',
			titleBackgroundColor: 'var(--color-primary)',
			titleTextColor: 'var(--color-inverseOnSurface)',
			parent: this.modal ? this.modal.wrapper : this.wrapper,
			closeCallback: () => {
				owner.ldc.getGroups(this.id, this.companyKey!);
				return true;
			}
		});

		for (let i = 0; i < deviceGroups.length; i++) {
			let group = createElement('div', 'dev-config-view__settings__sub__option', this.groupOptions);
			createElement('img', 'dev-config-view__settings__sub__option__icon', group, undefined, { 'src': InfoIcon })
			createElement('div', 'dev-config-view__settings__sub__option__title', group, groups[i].name == '' ? 'All' : groups[i].name);
		}

		if (!this.groupsButton) {
			this.groupsButton = createElement('button', 'se-button', this.siteSettings, 'Edit Groups')
			this.groupsButton.onclick = () => new ViewModal(new SiteAccessView(this.ldc, this.companyKey, this.key, this.id), {
				maxWidth: '400px',
				maxHeight: '500px',
				title: 'Device Groups',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)'
			});
			if (owner.ldc.isPowerUser()) {
				let seGroupsButton = createElement('button', 'se-button', this.siteSettings, 'Edit SE Groups')
				seGroupsButton.onclick = () => new ViewModal(new SiteAccessView(this.ldc, '', undefined, this.key, this.id), {
					maxWidth: '400px',
					maxHeight: '500px',
					title: 'Device Groups',
					titleBackgroundColor: 'var(--color-primary)',
					titleTextColor: 'var(--color-inverseOnSurface)'
				});
			}
		};
	};

	onDriverFormsReceived(fp: FrameParser) {
		new ViewModal(new DriverFormView('', fp, this.device.id), {
			maxWidth: 				this.modal ? this.modal.props.maxWidth : '600px',
			maxHeight:				'600px',
			title:					'Add Driver',
			titleTextColor: 		'var(--color-inverseOnSurface)',
			titleBackgroundColor:	'var(--color-blue-8)',
			parent: 				this.modal ? this.modal.wrapper : this.wrapper,
			closeCallback: 			() => {this.device.requestDrivers(this.onGetDriversResponse.bind(this)); return true},
		});
	}

	onGetDriversResponse(drivers: Driver[]) {
		if (!this.driverOptions)
			return;
		this.driverOptions.removeChildren();

		let addDriver = createElement('div', 'dev-config-view__settings__sub__option', this.driverOptions);
		createElement('img', 'dev-config-view__settings__sub__option__icon', addDriver, undefined, { 'src': AddCircleIcon })
		createElement('div', 'dev-config-view__settings__sub__option__title', addDriver, 'Add Driver')
		addDriver.onclick = () => owner.ldc.getDriverForms(this.id, this.device.id);
		drivers.forEach(driver => {
			if (driver.name == "Other Tagger" || driver.name == "Alias") //FIXME: Gotta be a better way to check for this
				return;
			let driverElement = createElement('div', 'dev-config-view__settings__sub__option', this.driverOptions);
			createElement('img', 'dev-config-view__settings__sub__option__icon', driverElement, undefined, { 'src': PLCIcon })
			createElement('div', 'dev-config-view__settings__sub__option__title', driverElement, driver.name)
			driverElement.onclick = () => {
				this.driverName = driver.name;
				owner.ldc.fm.buildFrame(LiveData.LDC_GET_DRIVER_ATTRIBUTES, this.device.id, this.id);
				owner.ldc.fm.push_string(driver.name);
				owner.ldc.send();
			}
		})
	}

	onDriverAttributesReceived(fp: FrameParser) {
		new ViewModal(new DriverFormView(this.driverName, fp, this.device.id), {
			maxWidth: 				this.modal ? this.modal.props.maxWidth : '600px',
			maxHeight:				'400px',
			title:					this.driverName,
			titleTextColor: 		'var(--color-inverseOnSurface)',
			titleBackgroundColor:	'var(--color-blue-8)',
			parent: 				this.modal ? this.modal.wrapper : this.wrapper,
			fAnimated: 				typeof this.modal !== 'undefined',
			closeCallback: 			() => {this.device.requestDrivers(this.onGetDriversResponse.bind(this)); return true;}
		});
	}

	onSubmitDriverAttributesResponse(fp: FrameParser) {
		this.root.popAttributes(fp);
	}

	onKeyAssigned(result: number) {	// Feedback from one of our device management changes
		if (result)	{
			new ViewModal(new DeviceConfigurationView(this.ldc, this.key ?? '', this.tag, this.ldc.user.companyKey), { //TODO: make this smart about grabbing other company keys somehow
				maxWidth: '600px',
				title: 'Tagger',
				titleTextColor: 'var(--color-inverseOnSurface)',
				titleBackgroundColor: 'var(--color-primary)',
				buttons: [
					{
						title: 'Finish',
						borderColor: owner.colors.hex('--color-primary'),
						callback: () => {
							window.location.hash = getHash(Routes.Finder);
							return true;
						},
					}]
			});
		}
		else
			new Dialog(document.body, {
				title: 'Error',
				body: this.key ? owner.ldc.devices.getByKey(this.key)?.siteName + ' could not be restored from backup. Please try again.' : 'An error occurred while trying to reset this device. Please try again.',
				titleBackground: 'var(--color-brand)',
				titleColor: 'var(--color-inverseOnSurface)'
			});
		this.modal?.destroy();
	}

	onAccountsManaged(command: number, result: number) {
		if (result)
			owner.ldc.getGroups(this.id, this.companyKey!)
	};
};

class DriverFormView extends View {
	id: number;
	name: string;
	fp: FrameParser;
	deviceID: number;
	topWrapper: HTMLElement;
	formWrapper: HTMLElement;
	buttonWrapper: HTMLElement;
	driverName: TextInput;
	root: ConfigFormElement;
	constructor(name: string, fp: FrameParser, deviceID: number) {
		super();
		this.id = owner.ldc.registerGraph(this);
		this.name = name;
		this.fp = fp;
		this.deviceID = deviceID;
	}
	initialize(parent: HTMLElement) {
		super.initialize(parent);
		this.wrapper = createElement('div', 'driver-view__wrapper', this.parent);
		let topWrapper = createElement('div', 'driver-view__top-wrapper', this.wrapper);
		this.formWrapper = createElement('div', 'driver-view__form-wrapper', this.wrapper);
		this.buttonWrapper = createElement('div', 'driver-view__button-wrapper', this.wrapper);
		if (this.name == "") {
			this.driverName = new TextInput(topWrapper, 'Driver Name', 'var(--color-onSurface)', {
				'autocomplete': 'off',
				'autocapitalize': 'off',
				'autocorrect': 'off',
				'spellcheck': 'false',
				'background': 'true'
			});
			this.driverName.onchange = () => {
				this.name = this.driverName.value;
			}
			let count = this.fp.pop_u16();
			let select = createElement('select', 'driver-view__select', topWrapper);
			for (let i = 0; i < count; i++) {
				let name = this.fp.pop_string();
				let option = createElement('option', '', select, name);
				option.value = name;
			}
			select.onchange = () => {
				let selection = select.value
				owner.ldc.fm.buildFrame(LiveData.LDC_GET_DRIVER_FORM, this.deviceID, this.id);
				owner.ldc.fm.push_string(selection);
				owner.ldc.send();
			}
			let changeEvent = new Event('change');
			select.dispatchEvent(changeEvent);
		}
		else {
			this.buildForm(this.fp)
		}
		this.fInitialized = true;
		return this;
	}

	onDriverFormReceived(fp: FrameParser) {
		this.buildForm(fp)
	}

	buildForm(fp: FrameParser) {
		this.formWrapper.removeChildren();
		this.buttonWrapper.removeChildren();
		let driverID = fp.pop_string();
		this.root = new ConfigFormElement(null, fp, this.formWrapper)
		this.root.popAttributes(fp);
		let submitButton = createElement('button', 'se-button', this.buttonWrapper, 'Submit');
		submitButton.style.border = '2px solid var(--color-primary)';
		submitButton.style.fontSize = '1em';
		let cancelButton = createElement('button', 'se-button', this.buttonWrapper, 'Cancel');
		cancelButton.style.border = '2px solid var(--color-error)';
		cancelButton.style.fontSize = '1em';
		submitButton.onclick = () => {
			if (!Node.isValidName(this.name))
				new Dialog(document.body, {
					title: 'Invalid Tag Name',
					titleBackground: 'var(--color-error)',
					titleColor: 'var(--color-inverseOnSurface)',
					body: 'Driver Names must contain only english alphabet letters, numbers, and underscores and can not start with a number.',
				});
			else
				owner.ldc.submitDriverAttributes(this.deviceID, this.id, driverID, this.name, this.root);
		}
		cancelButton.onclick = () => this.modal?.destroy();
	}

	onSubmitDriverAttributesResponse(fp: FrameParser) {
		this.root.popAttributes(fp);
		this.modal?.destroy();
	}
}



