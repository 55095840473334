import DOMPurify from 'dompurify';
import { WidgetMap } from './elements';
import { Widgets } from './widgets/lib/widget';

export default class HTMLSanitizer {
    // @ts-ignore
	nativeSanitizer: Sanitizer;
    addAllowedElements: Map<string/*custom tag*/, string[/*custom attributes*/]>;
    domPurifyProps: DOMPurify.Config = {
        FORCE_BODY: true, // Needed this to make inline css work
        CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: (tagName) => this.addAllowedElements.has(tagName), // Make sure this tag is in our allow list
            attributeNameCheck: (attr) => this.allowedElementsContainsAttr(attr), // TODO: is there a way to scope these per tag?
            allowCustomizedBuiltInElements: true, // allow customized built-ins
        },
      };

    constructor(addAllowedElements:Map<string, string[]> = new Map(), fImportLensRegistry:boolean=false) {
        this.addAllowedElements = addAllowedElements;

        if(fImportLensRegistry) // Conditionally append all custom tags and attributes that exist in the Lens registry
        {
            for (let props of Widgets) //@ts-ignore
                this.addAllowedElement(props.tag, customElements.get(props.tag)?.observedAttributes);
        }

        this.addAllowedElement('tag-def', ['abs-path', 'role']);

        try { // Attempt to use native HTML sanitization if available
            //@ts-ignore
            let nativeConfig = Sanitizer.getDefaultConfiguration();
            nativeConfig.allowElements.push(...this.addAllowedElements.keys());
            for(let [tag, attributes] of this.addAllowedElements) {
                attributes.forEach(attribute => {
                    if(nativeConfig.allowAttributes[attribute] != undefined)
                        nativeConfig.allowAttributes[attribute].push(tag);
                    else
                        nativeConfig.allowAttributes[attribute] = [tag];
                });
            }
            nativeConfig.allowCustomElements = true;
            nativeConfig.allowUnknownMarkup = true; // Consider html elements that the browser doesn't know about (IDK why this has to be done in addition to allowCustomElements...)
            // @ts-ignore
            this.nativeSanitizer = new Sanitizer(nativeConfig);
        } catch(error) {
            console.log("This browser does not support native HTML sanitization");
        }
    }

    addAllowedElement(tag: string, attributes: string[] = []) {
        this.addAllowedElements.set(tag, [] as string[]); // Add the element to the map with no supported custom attrs
        attributes.forEach((attr)=>{ this.addAllowedElements.get(tag)?.push(attr); }); // Add all the custom attrs if any
    }

    allowedElementsContainsAttr(attribute: string): boolean {
        for(let [_, attributes] of this.addAllowedElements)
            if(attributes.includes(attribute))
                return true; // Found the attribute in our allow list
        return false; // We don't have a record of this attribute
    }

    setHTML(parent: HTMLElement, html: string, fForceNonNativeBehavior:boolean=false) {
        if(this.nativeSanitizer != undefined && !fForceNonNativeBehavior) {
            // @ts-ignore
            parent.setHTML(html, {sanitizer: this.nativeSanitizer});
        } else {
            // Do non-native sanitization
            parent.innerHTML = DOMPurify.sanitize(html, this.domPurifyProps).toString();
        }
    }
}