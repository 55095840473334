import SVGWidget from './svgwidget';
import {createElement} from '../elements';

//The Option driver will create an HTML select element allowing the user
//to change an environment variable. An custom environment-variable
//change event will be fired on the HTML container of the svg element
//in order to inform other code that an environment variable has changed.
//Currently, CustomTab listens for this event on the parent, and resets
//the SVG with a new enviroment when it fires.


//options:
//	data-se-var:"string" determines the environment variable that will be written to
//	data-se-value_<ID>:"..."  determines the value that will be set to the variable if <ID> is chosen
//	data-se-text_<ID>:"..."  determines the text that will be displayed in the dropdown for item <ID>
//Note: ordered the box by <ID>'s parseInt value.
export default class GraphBox extends SVGWidget {
	constructor(nodeRoot,element){
		super(nodeRoot,element);
		
        //Find the nearest HTML node:
		this.HTMLcontainer = SVGWidget.findFirstHTMLParent(this.element);

		this.readSettings();
		
		this.setup();
	}
	
	
	//Reads the settings from attributes and stores them for use in setup()
	readSettings(){
		this.selectOptions = {}
		var prefix = "seValue_";
		this.absolutePos = true;								// Checks if the SVG element wants to be absolute or relatively positioned
		for (var attrName in this.element.dataset){
			if (attrName.substr(0,prefix.length) === prefix){
				this.selectOptions[attrName.substr(prefix.length)] = this.readOptionSettings(attrName.substr(prefix.length));
			}
			if (attrName === "seAbsolute"){						// Check for absolute positioning
				let tmp = SVGWidget.readAttribute(this.element, "seAbsolute");
				this.absolutePos = (tmp === "false") ? false : true;		// Default is true for compatability reasons with Canton Plant
			}
		}
        this.variable = SVGWidget.readAttribute(this.element,"seVar"); //default one day, the user should type a number of seconds
		
	}
	
    //Read the settings related to a single option
	readOptionSettings(optionID){
        var value = SVGWidget.readAttribute(this.element,'seValue_'+optionID,'SELECT_DEFAULT_VALUE');
        var text =  SVGWidget.readAttribute(this.element,'seText_'+optionID,'SELECT_DEFAULT_TEXT');
        
        if (text === 'SELECT_DEFAULT_TEXT') text = value;
        
		return {
			 value:	value,
		     text:	text,
            };
	}
	
    setup(){
        //Create element and position it.
		this.selectElement = createElement('select', '', this.HTMLcontainer);		// There IS a special custom-select class in bootstrap, but NOT one in our files. Therefore, needed to remove the class to make it look correct for Canton SVG
		this.selectElement.absolutePos = this.absolutePos;								// Set a property passed to positionHTMLElement to either relative or absolutely position the element.
        SVGWidget.positionHTMLElement(this.selectElement,this.HTMLcontainer,this.element);
        
        //attach options
        
        
        //Iterate through IDs from numeric least to numeric greatest.
        var sortedIDs = Object.keys(this.selectOptions)
                            .map(function (s){return Number.parseInt(s);})
                            .sort(function(a,b){return a-b})
                            .map(function (i){return i.toString();});
        
        for (var i=0;i<sortedIDs.length;i++){ 
            var id = sortedIDs[i];
            var opt = createElement('option','custom-option',this.selectElement);
            opt.value = this.selectOptions[id].value;
            opt.textContent = this.selectOptions[id].text;
        }
        
        //Load default option from existing environment, if possible.
        if (this.element._environment && this.element._environment[this.variable]){
            this.selectElement.value = this.element._environment[this.variable];
        }
        
        this.selectElement.onchange = this.change.bind(this);
    }
    
    change(event){
        //Create an event containing the changed env var, 
        //and dispatch it to the HTML container.
        
        //NOTE: We are using a custom event for two reasons,
        //   First, in the future instead of reloading the entire
        //    svg we might want drivers to detect when params have changed.
        //   Secondly, it's a relatively nice way to shoot some data
        //    upstream against our normal function call stack.
        var delta = {};
        delta[this.variable] = this.selectElement.value;
        this.HTMLcontainer.dispatchEvent(
            // Set bubbles to true to enable the event to "bubble up" to something that will eventually catch it
            new CustomEvent('SEESVG-varchange',{'detail':delta, bubbles:true}));
    }
}
