import {Widget} from "./widget";
import {createUniqueId, createElement} from './elements';
import {Node, NodeQuality} from './node';
import assert from './debug';
import './rangeslider.css';
import owner from "../owner";
import { Role } from "./role";
import { convert } from "./widgets/lib/tagunits";
export default class RangeSlider extends Widget {
    constructor(element, maxNode, minNode, enableNode, properties) {
        super();
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element    = element;
        this.minNode    = minNode;
        this.maxNode    = maxNode;
        this.enabled    = enableNode
        this.rangeColor = 'var(--color-orange-3)';

        if (this.minNode.roles.has(Role.ROLE_TLC_CONSTRAINT_MIN))
            this.min        = this.minNode.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
        else this.min = this.minNode;

        if (this.maxNode.roles.has(Role.ROLE_TLC_CONSTRAINT_MAX))
            this.max        = this.maxNode.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
        else
            this.max        = this.maxNode;

        this.registerAsWidget(this.element);

        // 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
        // First step: Establish default properties:
        this.fVertical				= false;    // Draw bar horizontally by default
        this.minRange               = 0;        // No minimum range
        this.redXMask				= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.units                  = this.min.units;

        // Second step: copy properties over to the slider object:
        this.copy(properties);

        this.fInitialized	= false;
    }

    initialize() {
        this.wrapper            = createElement('div',  'range-slider__wrapper', this.element);
        let spinnerWrapper      = createElement('div', 'range-slider__spinner-wrapper', this.wrapper);
        this.minSpinner         = createElement('input', 'spinner range-slider__spinner', spinnerWrapper, '', {type: 'number'});	// Create the input element
        this.maxSpinner         = createElement('input', 'spinner range-slider__spinner', spinnerWrapper, '', {type: 'number'});	// Create the input element
        this.container          = createElement('div',  'range-slider__container', this.wrapper);

        this.bar                = createElement('div',  'range-slider__bar', this.container);
        this.range              = createElement('div',  'range-slider__range', this.container);
        this.minSlider          = createElement('input', 'range-slider__slider', this.container, undefined, {type:'range'});
        this.maxSlider          = createElement('input', 'range-slider__slider', this.container, undefined, {type:'range'});
        this.leftThumb          = createElement('div', 'range-slider__thumb range-slider__left', this.container);
        this.rightThumb         = createElement('div', 'range-slider__thumb range-slider__right', this.container);

        this.minSlider.oninput = () => {
            this.minSlider.value = Math.min(parseFloat(this.minSlider.value), parseFloat(this.maxSlider.value) - this.minRange)
            this.minSpinner.value = this.minSlider.value;
            let percent = (this.minSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
            this.leftThumb.style.left = percent + '%';
            this.range.style.left = percent + '%';
            this.minSpinner.style.left = Math.min(this.range.offsetLeft - this.minSpinner.clientWidth / 2, this.maxSpinner.offsetLeft - this.minSpinner.clientWidth) + 'px';
        }
        this.minSlider.onchange = () => {
            let percent = (this.minSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
            this.leftThumb.style.left = percent + '%';
            this.range.style.left = percent + '%';
            this.minSpinner.style.left = Math.min(this.range.offsetLeft - this.minSpinner.clientWidth / 2, this.maxSpinner.offsetLeft - this.minSpinner.clientWidth) + 'px';
            owner.settingsManager.pushPending(this.min, convert(parseFloat(this.minSlider.value), this.units, this.min.units), this);
        }
        this.maxSlider.oninput = () => {
            let minValue = parseFloat(this.minSlider.value);
            let maxValue = parseFloat(this.maxSlider.value);
            this.maxSlider.value = Math.max(minValue + this.minRange, maxValue)
            this.maxSpinner.value = this.maxSlider.value;

            let percent = (this.maxSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
            this.rightThumb.style.right = (100 - percent) + '%';
            this.range.style.right = (100 - percent) + '%';
            this.maxSpinner.style.left = Math.max(this.range.offsetLeft + this.range.clientWidth - this.maxSpinner.clientWidth / 2, this.minSpinner.offsetLeft + this.minSpinner.clientWidth) + 'px';
        }
        this.maxSlider.onchange = () => {
            let percent = (this.maxSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
            this.rightThumb.style.right = (100 - percent) + '%';
            this.range.style.right = (100 - percent) + '%';
            this.maxSpinner.style.left = Math.max(this.range.offsetLeft + this.range.clientWidth - this.maxSpinner.clientWidth / 2, this.minSpinner.offsetLeft + this.minSpinner.clientWidth) + 'px';
            owner.settingsManager.pushPending(this.max, convert(parseFloat(this.maxSlider.value), this.units, this.max.units), this);
        }

        this.minSpinner.onchange = () => {
            this.minSpinner.value = Math.min(parseFloat(this.minSpinner.value), parseFloat(this.maxSlider.value) - this.minRange)
            this.minSlider.value = this.minSpinner.value;
            this.minSlider.onchange()
        }

        this.maxSpinner.onchange = () => {
            this.maxSpinner.value = Math.max(parseFloat(this.minSlider.value) + this.minRange, parseFloat(this.maxSpinner.value));
            this.maxSpinner.value = Math.min(this.maxSpinner.value, this.maximum)

            this.maxSlider.value = this.maxSpinner.value;
            this.maxSlider.onchange()
        }

        this.minSlider.onmousedown = () => {
            this.minSpinner.classList.add("range-slider__mousedown");
        };
        this.minSlider.onmouseup = () => {
            this.minSpinner.classList.remove("range-slider__mousedown");
        };

        this.maxSlider.onmousedown = () => {
            this.maxSpinner.classList.add("range-slider__mousedown");
        };
        this.maxSlider.onmouseup = () => {
            this.maxSpinner.classList.remove("range-slider__mousedown");
        };

        this._determineMinMaxRes ();		// determine minimum, maximum, resolution

        this.fInitialized = true;

        this.min.subscribe(this, this.wrapper, this.redXMask);
        this.max.subscribe(this, this.wrapper, this.redXMask);
        if (this.enabled)
            this.enabled.subscribe(this, this.wrapper, this.redXMask);
        this.updateSliders();

        return(this);
    }

    update(node) {	// Called by the job whenever node value/quality changes:
        assert (this.fInitialized);
        if (node.quality != NodeQuality.NQ_GOOD)	// bad quality:
            return;
        switch (node) {
            case this.min:
                assert (!isNaN(node.getValue(this.fPending)));
                break;
            case this.max:
                assert (!isNaN(node.getValue(this.fPending)));
                break;
            default:
                break;
        }
        this.updateSliders();
    };

    set rangeColor(color) {
        if (this.fInitialized)
            this.range.style.backgroundColor = color;
    }

    getValue() {	// No need for bar graph to have a node. You can call this directly instead:
        assert (this.fInitialized);
        var pixels = this.fVertical ? this.barRect.top + this.length - this.bar.y.baseVal.value : this.bar.x.baseVal.value - this.barRect.left + this.length;
        return (this.maximum - this.minimum) * pixels / this.length + this.minimum;
    };



    updateSliders() {
        this.maxSlider.max = this.maximum;
        this.maxSlider.min = this.minimum;
        this.minSlider.min = this.minimum;
        this.minSlider.max = this.maximum;
        this.minSlider.value = this.min.convertValue(this.units, true)
        this.maxSlider.value = this.max.convertValue(this.units, true)
        let percent = (this.minSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
        this.leftThumb.style.left = percent + '%';
        this.range.style.left = percent + '%';
        percent = (this.maxSlider.value - this.minimum) / (this.maximum - this.minimum) * 100;
        this.rightThumb.style.right = (100 - percent) + '%';
        this.range.style.right = (100 - percent) + '%';
        this.minSpinner.value = this.minSlider.value;
        this.maxSpinner.value = this.maxSlider.value;
        requestAnimationFrame(() => {
            this.minSpinner.style.left = this.range.offsetLeft - this.minSpinner.clientWidth / 2 + 'px';
            this.maxSpinner.style.left = Math.max(this.range.offsetLeft + this.range.clientWidth - this.maxSpinner.clientWidth / 2, this.minSpinner.offsetLeft + this.minSpinner.clientWidth) + 'px';
        });

        if (this.min.roles.has(Role.ROLE_PUMP_MIN_BEP_RATIO)) {
            this.rangeFill = 'var(--color-green-8)';
            this.backFill = 'var(--color-red-8)';
            this.bar.style.boxShadow = 'inset 2px 2px 6px #a82222, inset -2px -2px 6px #e42e2e';
        }

        if (this.min.roles.has(Role.ROLE_PUMP_MIN_AOR)) {
            this.rangeFill = 'var(--color-yellow-8)';
            this.backFill = 'var(--color-red-8)';
            this.bar.style.boxShadow = 'inset 2px 2px 6px #a82222, inset -2px -2px 6px #e42e2e';
        }

        if (this.enabled && !this.enabled.getValue(this.fPending)) {
            this.rangeFill = 'var(--color-surfaceContainerHigh)';
            this.backFill = 'var(--color-surfaceContainer)';
            this.bar.style.boxShadow = 'none';
        }

        this.maxSlider.max  = this.maximum;
        this.maxSlider.min  = this.minimum;
        this.minSlider.max  = this.maximum;
        this.minSlider.min  = this.minimum;
        this.minSpinner.max = this.maximum;
        this.minSpinner.min = this.minimum;
        this.maxSpinner.max = this.maximum;
        this.maxSpinner.min = this.minimum;
        this.maxSlider.step = this.resolution;
        this.minSlider.step = this.resolution;
        this.minSpinner.step = this.resolution;
        this.maxSpinner.step = this.resolution;

        this.minSpinner.disabled = this.enabled && !this.enabled.getValue(this.fPending);
        this.maxSpinner.disabled = this.enabled && !this.enabled.getValue(this.fPending);
        this.leftThumb.setAttribute('disabled', this.enabled && !this.enabled.getValue(this.fPending));
        this.rightThumb.setAttribute('disabled', this.enabled && !this.enabled.getValue(this.fPending))

        if (this.rangeFill)
            this.range.style.backgroundColor = this.rangeFill;

        if (this.backFill) {
            this.bar.style.backgroundColor = this.backFill;
        }
    }

    // Internal methods:
    _determineMinMaxRes() {
        // Determine bar minimum and maximum range in engineering units:
        // If user defined 'minimum' and 'maximum' properties already, then honor them regardless of node range.
        // Default is 0 to 100 with resolution of 1, if no value node defined:
        if (this.minimum === undefined) {
            this.minimum = this.min && this.min.engMin ? convert(this.min.engMin, this.min.units, this.units? this.units : this.min.units) : 0;
        }
        if (this.maximum === undefined) {
            this.maximum = this.max && this.max.engMax ? convert(this.max.engMax, this.max.units, this.units? this.units : this.max.units) : 0;
        }
        if (this.resolution === undefined)
            this.resolution = Math.min(convert(this.min.resolution, this.min.units, this.units? this.units : this.min.units), convert(this.max.resolution, this.max.units, this.units? this.units : this.max.units));
    };

    destroy() {
        if (this.minNode) this.minNode.unsubscribe(this);
        if (this.maxNode) this.maxNode.unsubscribe(this);
        if (this.enabled) this.enabled.unsubscribe(this);
        this.unregisterAsWidget()
    }
}