import Page from './page';
import TabBar, { TabBarInfo } from '../components/tabbar';
import DeviceIcon from '../images/icons/device.svg';
import owner, { Routes } from '../../owner';
import AccountSettings from '../views/accountsettingsview';
import DeviceSettings from '../views/devicesettingsview_classic';
import AccountsIcon from '../images/icons/accounts.svg';
import SiteAccessView from '../views/siteaccessview';
import Dialog from '../dialog';
import { getHash } from '../router/router';

export default class SettingsPage extends Page {
    props: {[key: string]: string};
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent, props);
        this.props = {...props}; // create copy of the route properties
        this.tabBar = new TabBar(parent);
        var tabs: TabBarInfo[] = [];
        if(owner.ldc.user.fAdmin)
            tabs.push(
                {name: "Users", 		icon: AccountsIcon,		content: new AccountSettings(owner.ldc, owner.menuPanel.getCompanyKey())},
                {name: "Groups",        icon: AccountsIcon,     content: new SiteAccessView(owner.ldc)}
            );

        if (owner.ldc.user.fDevConfig)
            tabs.push(
                //{name: "Profile", 		icon: ProfileIcon,	content: ProfileView},
                {name: "Devices",       icon: DeviceIcon,   content: new DeviceSettings(owner.ldc)}
            );
        if (tabs.length == 0) {
                new Dialog(document.body, {
                    titleBackground: owner.colors.hex('--color-red-8'),
                    titleColor: owner.colors.hex('--color-inverseOnSurface'),
                    title: 'No Settings',
                    body: 'You do not have access to any settings',
                    buttons: [{'title':'Return',color:'var(--color-green-8)', callback: () => location.hash = getHash(Routes.Home)}],

            });
            return;
        }
        if (!this.props.tab) this.props.tab = tabs[0].name;
        this.tabBar.addTabs(tabs);
        this.tabBar.selectTab(this.props.tab);
        owner.navBar.setTitle('Admin');
        window.addEventListener('resize', () => this.tabBar.currentView?.resize());
    }

    onCompanySelected(): void {
        this.tabBar.currentView?.onCompanySelectorChanged(owner.menuPanel.getCompanyKey());
    }

    setProps(newProps: {[key: string]: string}) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'tab': // if the tab changes, select the new tab
                        this.tabBar.selectTab(this.props.tab);
                        break;
                    default:
                        break;
                };
            };
        };
    };
};