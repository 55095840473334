// This is a guy that makes it easy to subscribe and unsubscribe to a bunch of nodes
// that you intend to keep updated for the entire life time of another object.

import { Node } from "./node";

// Just create a NodeManager, add the nodes you want, subscribe, and then call destroy at the end
export default class NodeManager {
	widget: any;
	nodes: Node[];
    constructor(widget) {
        this.widget	= widget;	// Must pass in a reference to whoever we are trying to keep up with nodes for
        this.nodes	= [];		// All the nodes we are subscribed to
    }

	addNodeByName(parentNode, name) {	// Find a Node child and subscribe to it --> NOT RECURSIVE
		return this.addNode(parentNode.findChild(name));
	}

	addNodeByRole(parentNode, role: string) {	// Find a Node child and subscribe to it --> NOT RECURSIVE
		return this.addNode(parentNode.findChildByRole(role));
	}

	addAllNodesWithRole(parentNode, role: string) {
		var nodes = parentNode.findByRole(role);
		this.nodes = this.nodes.concat(nodes);
		return nodes;
	}

	addNode(node) {
		if (node && (!this.nodes.includes(node)))					// Node might not exist
			this.nodes.push(node);	// Add the node if it does
		return node;
	}

	removeNode(node) {
		for (let i = 0; i < this.nodes.length; ++i) {
			if(node === this.nodes[i]) {
				this.nodes[i].unsubscribe(this.widget);
				this.nodes.splice(i,1);	        // Found the tag to remove
				break;							// No need to look further
			}
		}
	}

	subscribe() {
		for (var i = 0; i < this.nodes.length; ++i)
			this.nodes[i].subscribe(this.widget);
	}

	destroy() {
		for (var i = 0; i < this.nodes.length; ++i) {
			if(this.nodes[i] && this.nodes[i].subscribers) {
				this.nodes[i].unsubscribe(this.widget);
			}
		}
	}
};
