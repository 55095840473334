import LiveData from './livedata';
import assert from './debug';
import BarGraph from './bargraph';
import owner from '../owner';
import LiveDataClient, { GroupInfo, LoginStatus } from './livedataclient';
import FrameParser from './frameparser';
import { Device } from './device';
import Logo from '../library/images/logo-white.png';
import PassKeys from './passkeys';

export interface PermissionGroup {
	group?: string;
	fWrites?: boolean;
}

interface UserBranding {
	logo: string;
}

export enum SMSSubscriptionStatus {
	PENDING = 0,
	SUBSCRIBED = 1,
	UNSUBSCRIBED = 2,
	NO_PHONE_NUMBER = 3
}

class UserPreferences {
	theme: string;
	units: { [key: string]: string }
	constructor(prefs: any) {
		this.theme = prefs['theme'] ?? 'Light';
		this.units = prefs['units'] ?? {};
	}

}
// This file defines the user object:
export default class User {
	ldc: LiveDataClient;
	sessionID: number;
	username: string;
	fullName: string;
	firstName: string;
	lastName: string;
	companyName: string;
	companyKey: string;
	permissions: PermissionGroup[] = [];
	fWizard: boolean;
	fTagConfig: boolean;
	fDevConfig: boolean;
	fAdmin: boolean;
	resetPassword: boolean;
	lastVersion: string;
	color: string;
	timerID: number;
	branding: UserBranding = {
		logo: Logo
	};
	preferences: UserPreferences = new UserPreferences({});
	verifiedUntil: number = 0;
	passKeys: PassKeys;
	constructor(ldc: LiveDataClient) {
		this.ldc = ldc;
		this.passKeys = new PassKeys();
	}

	canWrite(device: Device): boolean {
		return this.findPermission(device).fWrites ?? false;
	}

	canModifyTags() {
		return this.fTagConfig
	}

	canModifyDevice() {
		return this.fDevConfig;
	}

	writesEnabled() {	// check if they're enabled
		return this.verifiedUntil > Math.round(Date.now() / 1000) || (process.env.TARGET == 'localDevice'); // Either you have writes enabled or you are a client to a local device (in which case, you always have writes enabled)
	}

	findPermission(device: Device): PermissionGroup {
		var rule: PermissionGroup = {};
		for (var i = 0; i < this.permissions.length; ++i) {
			var p = this.permissions[i];
			if (p.group === "*") {			// Global rule
				if (rule.group === undefined)
					rule.group = p.group;	// Give it read-only access
				rule.fWrites = rule.fWrites || p.fWrites;	// OR in writes
				continue;
			}

			for (var j = 0; j < device.groups.length; ++j) {
				var fMatch = false;
				var devGroup: GroupInfo | undefined = device.groups[j];
				while (devGroup) {
					fMatch = p.group === devGroup.name;
					if (fMatch)		// If there's a match
						break;		// Stop iterating and check out the rules
					devGroup = devGroup.parent;
				}
				if (!fMatch)		// No match to compare
					continue;		// Try the net group
				if (rule.group === undefined)
					rule.group = p.group;	// Give it read-only access
				rule.fWrites = rule.fWrites || p.fWrites;	// OR in writes
			}
		}
		return rule; // Return whatever rule we found or a blank object
	}

	updatePreferences(preferences: string) {
		let prefs = JSON.parse(preferences);
		this.preferences = new UserPreferences(prefs);
	}

	destroy() {
		// Delete everything that we wouldn't want a different user to be able to find

	}

	changePassword(oldPassword: string, newPassword: string) {
		assert(this.ldc.socket && this.ldc.socket.readyState == 1, "Client needs to be connected to change password!");	// readyState == 1 means OPEN
		assert(this.sessionID, "User needs a session id to change password");
		assert(oldPassword !== undefined && newPassword !== undefined, "one of the passwords is undefined");
		assert(oldPassword != '' && newPassword != '', 'one of the passwords is empty');

		// Build a frame to change password based on logged in user's session ID
		this.ldc.fm.buildFrame(LiveData.WVC_CHANGE_PASSWORD, this.sessionID);
		this.ldc.fm.push_string(oldPassword);	// Append old password followed by the new password
		this.ldc.fm.push_string(newPassword);
		this.ldc.send();							// Transmit the frame
	}

	onChangePasswordResponse(fp: FrameParser) {	// Process user password change response:
		assert(fp.command == LiveData.WVCR_CHANGE_PASSWORD, "User::onChangePasswordResponse called for bad frame!");
		assert(fp.sessionID == this.sessionID, "Session ID doesn't match!");
		return fp.pop_u8() ? true : false;
	}
};
