import { type InputSetpoint } from '../setpoint/inputsetpoint';
import { Tag, TagAttribute, type TagDefinition } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import template from './rangeslider.html';
import { Attribute, ColorAttribute } from '../../lib/attributes';

@RegisterWidget({tag: 'range-slider', displayName: 'Digital Gauge', template: template})
export class RangeSlider extends Widget {
    minimum     : number = 0;
	maximum     : number = 100;
	lowValue    : number;
	highValue   : number;
	resolution  : number;
	onchange: () => void = () => {};
	minRange    : number  = 0;                        // No minimum range
	fInitialized: boolean = false;

	minSpinner: InputSetpoint;
	maxSpinner: InputSetpoint;
	container : HTMLElement;
	range     : HTMLElement;
	minSlider : HTMLInputElement;
	maxSlider : HTMLInputElement;
	leftThumb : HTMLElement;
	rightThumb: HTMLElement;
    valueElement: HTMLElement;
    private clampedValue: number = NaN;

    @Attribute({displayName: 'Range Color', typeModifier: 'color'}) rangeColor: ColorAttribute;
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) backgroundColor: ColorAttribute;

    @TagAttribute({
        displayName: 'Min Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric']
    }) minTag: TagDefinition

    @TagAttribute({
        displayName: 'Max Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric']
    }) maxTag: TagDefinition;

    protected connectedCallback(): void {
        this.minSlider = this.shadowRoot!.querySelector("#minSlider")!;
        this.maxSlider = this.shadowRoot!.querySelector("#maxSlider")!;
        this.minSpinner = this.shadowRoot!.querySelector("#minSpinner") as InputSetpoint;
        this.maxSpinner = this.shadowRoot!.querySelector("#maxSpinner") as InputSetpoint;
        this.leftThumb = this.shadowRoot!.querySelector("#leftThumb")!;
        this.rightThumb = this.shadowRoot!.querySelector("#rightThumb")!;
        this.range = this.shadowRoot!.querySelector("#range")!;
        if (this.rangeColor)
            this.style.setProperty('--range-color', this.rangeColor.color);
        if (this.backgroundColor)
            this.style.setProperty('--background-color', this.backgroundColor.color);
        this.minSpinner.setpoint = {tag: this.minTag.tag};	// This has to happen before enliven or those elements fail to render
        this.maxSpinner.setpoint = {tag: this.maxTag.tag};
    }

    get maxValue() {
        return this.maxTag?.tag.getValue() ?? NaN;
    }

    get minValue() {
        return this.minTag?.tag.getValue() ?? NaN;
    }

    protected enliven(): void {
        let minTag = this.minTag.tag;
        let maxTag = this.maxTag.tag;

        this.minimum = isNaN(minTag.engMin) ? 0 : minTag.engMin;
        this.maximum = isNaN(maxTag.engMax) ? 100 : maxTag.engMax;

        let minMax = isNaN(minTag.engMax) ? this.maximum : minTag.engMax;
        let maxMin = isNaN(maxTag.engMin) ? this.minimum : maxTag.engMin;

        this.minSlider.min = this.maxSlider.min = `${this.minimum}`;
        this.minSlider.max = this.maxSlider.max = `${this.maximum}`;

        this.minSlider.oninput = () => {
            let value = Math.min(Math.min(this.minSlider.valueAsNumber, this.maxSlider.valueAsNumber), minMax);
            this.minSlider.value = `${value}`;

            this.updateElements();
            this.minTag.tag.setPendingValue(this.minSlider.valueAsNumber, this);
            this.onchange();
        }
        this.maxSlider.oninput = () => {
            let value = Math.max(Math.max(this.maxSlider.valueAsNumber, this.minSlider.valueAsNumber), maxMin);
            this.maxSlider.value = `${value}`;
            this.updateElements();
            this.maxTag.tag.setPendingValue(this.maxSlider.valueAsNumber, this);
            this.onchange();
        }
        requestAnimationFrame(() => this.updateElements());
    }

    updateElements() {
        let percent = (this.maxSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum);
        this.rightThumb.style.left = percent * 100 + '%';
        this.range.style.right = (1 - percent) * 100 + '%';

        percent = (this.minSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum);
        this.leftThumb.style.left = percent * 100 + '%';
        this.range.style.left = percent * 100 + '%';
    }

    update(tag: Tag) {
        this.updateElements();
        if (tag === this.minTag.tag)
            this.minSlider.value = `${tag.getValue()}`;
        else if (tag === this.maxTag.tag)
            this.maxSlider.value = `${tag.getValue()}`;
    };
};