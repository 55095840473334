import { NodeFlags, Node as Tag } from "../../../node";
import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute, type ColorString } from "../../lib/attributes";
import SwitchIcon from '../../../images/icons/switch.svg';
import { TagAttribute } from "../../lib/tag";
import type { TagDefinition } from "../../lib/tag";
import template from './toggleswitch.html';

@RegisterWidget({tag: 'toggle-switch', template: template, displayName: 'Toggle Switch', icon: SwitchIcon, section: 'Input'})
export class ToggleSwitch extends Widget {
    protected valueElement: HTMLDivElement;
    protected _checked: boolean = false;
    protected container: HTMLElement;
    protected check: HTMLInputElement;
    protected text: HTMLElement;
    onchange: ()=>void = () => {};

    @Attribute({displayName: 'On Text'}) onText: string = 'ON'
    @Attribute({displayName: 'Off Text'}) offText: string = 'OFF'
    @Attribute({displayName: 'On Color', typeModifier: 'color'}) onColor: ColorString;
    @Attribute({displayName: 'Off Color', typeModifier: 'color'}) offColor: ColorString;
    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;

    protected connectedCallback(): void {
        this.container = this.shadowRoot!.querySelector('.container') as HTMLDivElement;
        this.check = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        this.text  = this.shadowRoot!.getElementById('text') as HTMLInputElement;

        if (this.disabled) {
            this.container.classList.add('disabled')
            this.check.disabled = true;
        }
        else
            this.check.disabled = false;

        if (this.offColor)
            this.container.style.setProperty('--off-color', this.offColor);
        if (this.onColor)
            this.container.style.setProperty('--on-color', this.onColor);

        this.check.onchange = () => {
            this._checked = this.check.checked;
            this.setText(this.text, this.check.checked);
            this.onchange();
        }

        this.check.checked = this.checked;
        this.setText(this.text, this.check.checked);
    }

    get checked() {
        return this._checked;
    }

    set checked(v: boolean) {
        this._checked = v;
        let check = this.shadowRoot?.getElementById('check') as HTMLInputElement;
        if (check) {
            check.checked = this._checked;
            this.setText(this.text, this.check.checked);
        }
    }

    setText(textElement: HTMLElement, state: boolean) {
        textElement.textContent = state ? this.onText : this.offText;
    }
}

@RegisterWidget({tag: 'se-tag-toggle-switch', displayName: 'Toggle Switch', icon: SwitchIcon, section: 'Input'})
export class TagToggleSwitch extends ToggleSwitch {
    @TagAttribute({
        displayName: 'Switch Tag',
        shouldSubscribe: true,
        supportedTypes: ['boolean']
    }) toggleTag: TagDefinition;

    @Attribute({displayName: 'On Text'}) onText: string = 'ON'
    @Attribute({displayName: 'Off Text'}) offText: string = 'OFF'

    protected enliven(): void {
        if (!this.toggleTag.tag.isWriteable) {
            this.container.classList.add('disabled');
            this.check.disabled = true;
        }
        else
            this.check.disabled = false;

        if ((this.toggleTag.tag.flags & NodeFlags.NF_WRITE) == 0) {
            this.container.classList.add('unwriteable');
        }
        this.check.onchange = () => {
            this.toggleTag.tag.setPendingValue(this.check.checked ? 1 : 0, this);
            this.setText(this.text, this.check.checked);
            this.onchange && this.onchange();
        }
    }

    update(tag: Tag): void {
        this.checked = tag.getValue(this);
    }
}

