import { Attribute } from '../../../lib/attributes';
import { TagAttribute } from '../../../lib/tag';
import { RegisterWidget, Widget } from '../../../lib/widget';
import type { TagDefinition } from "../../../lib/tag";
import template from './pumpbadge.html';

@RegisterWidget({tag: 'pump-badge', displayName: 'Pump Badge', section: 'Pumps', template: template})
export class PumpBadge extends Widget {
    @Attribute({displayName: 'Text Color', typeModifier: 'color'}) backgroundColor: string = 'var(--color-orange-8)';
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) textColor: string = 'var(--color-inverseOnSurface)';
    @TagAttribute({displayName: 'Tag'}) pumpTag: TagDefinition;
}