import {Widget} from "./widget";
import createSVGElement from './svgelements';
import {NodeFlags, NodeQuality} from './node';
import Localization from './localization';
import MouseCapture from './mousecapture';
import assert from './debug';
import './shuttle.css';
import owner from "../owner";

/**
 * Digital indicator/pointer that can travel along with a bar graph (or the edge of a chart) to depict the current digital value
 *
 * Designed for the bargraph, but probably useful in more places.
 * Update: Now has option for user to grab and adjust the shuttle. -pcs
 */

// Shuttle has constructor, getMetrics(), render(), and update() methods.
// Shuttle inherits from Widget:

// Shuttle constructor function:
export class Shuttle extends Widget {
    constructor(svg, node, orientation, fWriteable, label, shuttleClass, fReverse, properties) {
        super();
        this.parent			= svg;			// parent svg canvas
        this.node			= node;
        this.orientation	= orientation;
        this.fWriteable 	= !!fWriteable;	// Default is false (!! -> convert to Boolean)
        this.classname		= shuttleClass || 'shuttle';
        this.reverseFactor	= !!fReverse ? -1 : 1;	// Set fReverse equal to true to make a vertical graph's value increase going down instead of up
        this.minimum		= 0;
        this.maximum		= 0;
        this.resolution		= 0;
        if (label)
            this.label	= label + ' ';
        this.copy(properties);

        if (node && this.minimum === this.maximum) {
            this.maximum	= this.node.engMax;
            this.minimum	= this.node.engMin;
            this.resolution = this.node.resolution;
        }
        this.digits = Math.max(0,Math.ceil(-Math.log(this.resolution)/Math.LN10));
        assert(!this.fWriteable || !node || this.node.isWriteable, 'Shuttle: if you set fWriteable, the node must be writeable (but you don\'t need write permission)');
        assert(this.maximum && this.maximum != this.minimum, 'Shuttle requires engineering min/max to be defined');
        assert(this.resolution > 0, 'Shuttle node resolution cannot be zero.');
    };

    updateScale(visibleScale, inside, outside, canvas) {
        this.minimum = visibleScale.minimum;
        this.maximum = visibleScale.maximum;
        this.resolution = visibleScale.resolution;
        this.destroy();
        this.render(inside, outside, canvas);
    }

    //Prototype values for all shuttle objects to inherit upon construction:
    getMetrics(overhang) {	// get left, top, right, and bottom overhang from shuttle baseline based on CSS shuttle font:
        this.metrics = {};

        // Get maximum text size (CSS pixels) based on shuttle min and max values and resolution:
        this.metrics.text		= this._getTextExtents ();
        this.metrics.text.halfHeight = Math.ceil(this.metrics.text.height / 2);

        this.metrics.shuttle	= {	// Add two pixels on the sides for border (dangly font things already make room at top and bottom):
                width:		this.metrics.text.width +  (Shuttle.marginx * 2),
                height:		this.metrics.text.height + (Shuttle.marginy * 2),
                halfWidth:	Math.ceil((this.metrics.text.width +  (Shuttle.marginx * 2))/2),
                halfHeight:	Math.ceil((this.metrics.text.height + (Shuttle.marginy * 2))/2)};

        this.metrics.pointer	= {
                // Preliminarily, set pointer width and height equal to shuttle width/height.
                // Width/height will be corrected below in the next switch statement:
                width:	this.metrics.shuttle.width,						// width of pointer canvas
                height:	this.metrics.shuttle.height,					// height of pointer canvas
                halfBase: Math.ceil(this.metrics.shuttle.height / 6)	// half of pointer's triangle base thickness (base of triangle abuts shuttle rectangle)
                };

        // left, top, right, and bottom are all overhang values relative to the shuttle ruler line:
        switch (this.orientation) {
        case 'left':	// Vertical shuttle on left side of owner:
            this.metrics.fVertical	= true;
            // Adjust pointer size:
            this.metrics.pointer.width		= this.metrics.text.halfHeight;
            this.metrics.pointer.halfHeight	= this.metrics.shuttle.halfHeight;
            // Hangover metrics:
            this.metrics.left		= this.metrics.shuttle.width + this.metrics.pointer.width - Shuttle.overlap;
            this.metrics.top		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.right		= 0;
            this.metrics.bottom		= this.metrics.pointer.halfBase + Shuttle.radius;
            break;

        case 'top':		// Horizontal shuttle on top of owner:
            this.metrics.fVertical = false;
            // Adjust pointer size:
            this.metrics.pointer.height = this.metrics.text.halfHeight;
            this.metrics.pointer.halfWidth = this.metrics.shuttle.halfWidth;
            // Hangover metrics:
            this.metrics.left		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.top		= this.metrics.shuttle.height + this.metrics.pointer.height - Shuttle.overlap;
            this.metrics.right		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.bottom		= 0;
            break;

        case 'right':	// Vertical shuttle to the right of owner:
            this.metrics.fVertical	= true;
            // Adjust pointer size:
            this.metrics.pointer.width = this.metrics.text.halfHeight;
            this.metrics.pointer.halfHeight = this.metrics.shuttle.halfHeight;
            // Hangover metrics:
            this.metrics.left		= 0;
            this.metrics.top		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.right		= this.metrics.pointer.width + this.metrics.shuttle.width - Shuttle.overlap;
            this.metrics.bottom		= this.metrics.pointer.halfBase + Shuttle.radius;
            break;

        case 'bottom':	// Horizontal shuttle along bottom-side of owner:
            this.metrics.fVertical = false;
            // Adjust pointer size:
            this.metrics.pointer.height = this.metrics.text.halfHeight;
            this.metrics.pointer.halfWidth = this.metrics.shuttle.halfWidth;
            // Hangover metrics:
            this.metrics.left		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.top		= 0;
            this.metrics.right		= this.metrics.pointer.halfBase + Shuttle.radius;
            this.metrics.bottom		= this.metrics.shuttle.height + this.metrics.pointer.height - Shuttle.overlap;
            break;
        }

        // Adjust the overhang metrics to accomodate the shuttle:
        overhang.left	= Math.max(overhang.left,	this.metrics.left);
        overhang.top	= Math.max(overhang.top,	this.metrics.top);
        overhang.right	= Math.max(overhang.right,	this.metrics.right);
        overhang.bottom	= Math.max(overhang.bottom,	this.metrics.bottom);

        // Return the shuttle overhang metrics (in CSS pixels):
        return this.metrics;
    };

    /*
    * Render the shuttle onto the parent svg canvas.
    *
    * @param {object} frameRect  rectangle object representing the outside of the stroked frame
    * @param {object} activeRect rectangle representing the inside of the frame (and the ruler length)
    * @param {object} canvasRect rectangle representing the entire extent of the drawing surface
    */
    render(inside, outside, canvas) {	// Create SVG elements:
        assert (this.metrics, 'Call getMetrics() before render()');
        this.inside = inside;

        this.position = 0;	// Initial position in pixels

        // Compute starting positions for shuttle and pointer:
        // The pointer is positioned to overhang 'Shuttle.overlap' pixels into the active rect, and
        // everything else is positioned from there:
        switch (this.orientation) {
        case 'left':	// Vertical shuttle on left side of owner:
            // Shuttle has limited movement, so constrain its movement to within the canvas:
            this.metrics.shuttle.maxy = canvas.bottom - this.metrics.shuttle.height;
            this.metrics.shuttle.miny = canvas.top;
            this.metrics.shuttle.textAnchor = 'end';
            this.metrics.shuttle.textx		= this.metrics.shuttle.width - Shuttle.marginx;
            break;

        case 'top':		// Horizontal shuttle on top of owner:
            // Shuttle has limited movement, so constrain its movement to within the canvas:
            this.metrics.shuttle.maxx = canvas.right - this.metrics.shuttle.width;
            this.metrics.shuttle.minx = canvas.left;
            this.metrics.shuttle.textAnchor = 'middle';
            this.metrics.shuttle.textx		= this.metrics.shuttle.halfWidth;
            break;

        case 'right':	// Vertical shuttle to the right of owner:
            // Shuttle has limited movement, so constrain its movement to within the canvas:
            this.metrics.shuttle.maxy = canvas.bottom - this.metrics.shuttle.height;
            this.metrics.shuttle.miny = canvas.top;
            this.metrics.shuttle.textAnchor = 'start';
            this.metrics.shuttle.textx		= Shuttle.marginx;
            break;

        case 'bottom':	// Horizontal shuttle along bottom-side of owner:
            // Shuttle has limited movement, so constrain its movement to within the canvas:
            this.metrics.shuttle.maxx = canvas.right - this.metrics.shuttle.width;
            this.metrics.shuttle.minx = canvas.left;
            this.metrics.shuttle.textAnchor = 'middle';
            this.metrics.shuttle.textx		= this.metrics.shuttle.halfWidth;
            break;
        }

        // Set the shuttle canvas element attributes:

        // Create the shuttle canvas element now so we can associate it with the widget:
        this._shuttle = createSVGElement('svg', null, this.parent, {
            width: 	this.metrics.shuttle.width,
            height:	this.metrics.shuttle.height
        });

        // Register _shuttle as the widget element:
        this.registerAsWidget(this._shuttle);

        // Create the shuttle rectangle:
        var rectangle = createSVGElement('rect', this.classname, this._shuttle, {
            x:				0,
            y:				0,
            width:			this.metrics.shuttle.width,
            height:			this.metrics.shuttle.height,
            rx:				Shuttle.radius,
            ry:				Shuttle.radius,
            adjustable:		this.fWriteable		// This sets the mouse cursor through CSS
        });

        // Create the shuttle text element:
        this._textElement = createSVGElement('text', 'shuttle-text', this._shuttle, {
            x : this.metrics.shuttle.textx,
            y : this.metrics.shuttle.halfHeight,
            'dominant-baseline' : 'central',
            'text-anchor' : this.metrics.shuttle.textAnchor
        });

        // Create the pointer canvas element:
        this._pointer	= createSVGElement('svg', null, this.parent, {
            width:	this.metrics.pointer.width,
            height:	this.metrics.pointer.height
        });

        // Create the pointer triangle:
        var path;

        switch (this.orientation) {
        case 'left':	// Vertical shuttle on left side of owner:
            path = 	'M 0 '	+ (this.metrics.pointer.halfHeight - this.metrics.pointer.halfBase) +
                    ' L '	+ this.metrics.pointer.width + ' ' + this.metrics.pointer.halfHeight +
                    ' L 0 '	+ (this.metrics.pointer.halfHeight + this.metrics.pointer.halfBase) +
                    ' z';
            break;

        case 'top':		// Horizontal shuttle on top of owner:
            path = 	'M '	+ (this.metrics.pointer.halfWidth - this.metrics.pointer.halfBase) +
                    ' 0 L '	+ (this.metrics.pointer.halfWidth + this.metrics.pointer.halfBase) +
                    ' 0 L '	+ this.metrics.pointer.halfWidth + ' ' + this.metrics.pointer.height +
                    ' z';
            break;

        case 'right':	// Vertical shuttle to the right of owner:
            path = 	'M 0 '	+ this.metrics.pointer.halfHeight +
            ' L '	+ this.metrics.pointer.width + ' ' + (this.metrics.pointer.halfHeight - this.metrics.pointer.halfBase) +
            ' L '	+ this.metrics.pointer.width + ' ' + (this.metrics.pointer.halfHeight + this.metrics.pointer.halfBase) +
            ' z';
            break;

        case 'bottom':	// Horizontal shuttle along bottom-side of owner:
            path = 	'M '	+ this.metrics.pointer.halfWidth +
                    ' 0 L '	+ (this.metrics.pointer.halfWidth + this.metrics.pointer.halfBase) + ' ' + this.metrics.pointer.height +
                    ' L '	+ (this.metrics.pointer.halfWidth - this.metrics.pointer.halfBase) + ' ' + this.metrics.pointer.height +
                    ' z';
            break;

        default:
            assert (false);
            path = '';
            break;
        }
        createSVGElement('path', this.classname, this._pointer, {d:	path});

        shuttleInput.register(this, this.parent, this.orientation);	// Register so overlaps can be resolved

        // This will set all the parameters that rely on the inner rectangle. It will
        // also define the travel distance for the shuttle
        this.updateInnerRect(inside);

        // Pixel ratio should always be a positive number <= 1.0.
        // First, compute ratio of (total travel pixels) : (number of resolution increments).
        // Clamp it to a max of 0.5 so the sensitivity always decreases when moving away from the travel axis:
        var minPixelRatio = Math.min(0.5, Math.abs(this.travel) / ((this.maximum - this.minimum) / this.resolution));

        // Use an exponential function to represent the sensitivity with respect to the cursor distance from
        // the travel axis. Use a function of the form: y = e^(rx), where x is distance in pixels,
        // and y is pixel ratio.
        // Determine r by solving for point1(x1,y1) and point2(x2,y2), where:
        // x1: 0							// distance from edge of deadband
        // y1: 1.0							// maximum pixel ratio
        // x2: Shuttle.mouseDistance		// distance where exponential function crosses minPixelRatio
        // y2: minPixelRatio
        // For x's > x2, ratio will be < minPixelRatio

        // r = ((ln(y2) - ln(y1)) / (x2 - x1)
        this.rPixelRatio = (Math.log(minPixelRatio) - Math.log(1)) / Shuttle.mouseDistance;

        if (this.fWriteable) {
            rectangle.onmousedown = this.processMouseEvent;	// Set a mousedown handler for the shuttle rectangle:
            // touch adjustments to shuttles on mobile are very coarse and hard to set to a specific value
            // there is not really room to drag them horizontally to make fine adjustments that are needed
            // rectangle.addEventListener('touchstart', this.processMouseEvent, false);
            rectangle._Shuttle = this;						// store this object on the shuttle's rectangle SVG element
            rectangle.ondblclick = this.processDoubleClick;	// On double clicks, launch a handler
        }

        if (this.node)
            this.node.subscribe(this, this._shuttle);
    };

    updateInnerRect(rect) {
        assert(this.position !== undefined, "Call render on a shuttle before setTravelOrigin");

        this.travel = this.reverseFactor * (this.metrics.fVertical ? rect.height : rect.width);	// Total travel length in pixels

        // Compute starting positions for shuttle and pointer:
        switch (this.orientation) {
        case 'left':	// Vertical shuttle on left side of owner:
            this.metrics.pointer.x = (rect.left + Shuttle.overlap) - this.metrics.pointer.width;
            this.metrics.pointer.y = rect.bottom - this.metrics.shuttle.halfHeight;
            this.metrics.shuttle.x = this.metrics.pointer.x - this.metrics.shuttle.width;
            this.metrics.shuttle.y = this.metrics.pointer.y;
            break;

        case 'top':		// Horizontal shuttle on top of owner:
            this.metrics.pointer.x = rect.left - this.metrics.pointer.halfWidth;
            this.metrics.pointer.y = (rect.top + Shuttle.overlap) - this.metrics.pointer.height;
            this.metrics.shuttle.x = rect.left - this.metrics.shuttle.halfWidth;
            this.metrics.shuttle.y = this.metrics.pointer.y - this.metrics.shuttle.height;
            break;

        case 'right':	// Vertical shuttle to the right of owner:
            this.metrics.pointer.x = rect.right - Shuttle.overlap;
            this.metrics.pointer.y = rect.bottom - this.metrics.shuttle.halfHeight;
            this.metrics.shuttle.x = this.metrics.pointer.x + this.metrics.pointer.width;
            this.metrics.shuttle.y = this.metrics.pointer.y;
            break;

        case 'bottom':	// Horizontal shuttle along bottom-side of owner:
            this.metrics.pointer.x = rect.left - this.metrics.pointer.halfWidth;
            this.metrics.pointer.y = rect.bottom - Shuttle.overlap;
            this.metrics.shuttle.x = rect.left - this.metrics.shuttle.halfWidth;
            this.metrics.shuttle.y = this.metrics.pointer.y + this.metrics.pointer.height;
            break;
        }
        this._shuttle.setAttribute('x', this.metrics.shuttle.x);
        this._shuttle.setAttribute('y', this.metrics.shuttle.y);
        this._pointer.setAttribute('x', this.metrics.pointer.x);
        this._pointer.setAttribute('y', this.metrics.pointer.y);

        this.update(this.node);		// Force an update
    };

    processMouseEvent = function (evt) {	// 'this' is the rectangle svg element, and this._Shuttle is the shuttle object
        var shuttle = this._Shuttle;

        switch (evt.type) {
        case 'mousedown':
        case 'touchstart':
            if ((evt.type == 'touchstart' || evt.button == 0) && (!shuttle.node || shuttle.node.hasWritePermission() || owner.settingsManager)) {	// Left mouse button pressed and User has write permission:
                shuttle.capture = new MouseCapture(this, evt);	    // Capture the mouse
                shuttle._startPosition = shuttle.position;			// Store the starting pixel position of the shuttle
                shuttle.deviation = 0;								// deviation (pixels) due to sensitivity adjustment

                // Compute deadband normal to travel axis:
                var rect = shuttle._shuttle.getBoundingClientRect();
                if (shuttle.metrics.fVertical) {
                    shuttle.loDeadband	= rect.left + shuttle._shuttle.getScrollLeft();
                    shuttle.hiDeadband	= rect.right + shuttle._shuttle.getScrollLeft();
                } else { // shuttle moves horizontally:
                    shuttle.loDeadband	= rect.top + shuttle._shuttle.getScrollTop();
                    shuttle.hiDeadband	= rect.bottom + shuttle._shuttle.getScrollTop();
                }
            }
            break;

        case 'mousemove':
        case 'touchmove':
    //		console.log('mousemove dx=' + shuttle.capture.dx + ' dy=' + shuttle.capture.dy);
            // Shuttle position is computed from incremental move 'dx' and 'dy':
            // Sensitivity to movement in travel direction is a function of distance normal to the travel direction.
            // The farther the user pulls the mouse away from the center of the shuttle, the less sensitive the movement.
            // If the mouse stays within the shuttle rectangle, the mouse has a 1:1 sensitivity, and the shuttle exactly
            // tracks the user movement in the travel direction.
            // As the user moves the cursor away from the edge of the shuttle rectangle , shuttle movement becomes less sensitive to
            // mouse movement, allowing the user to adjust the shuttle to a finer resolution.
            var pos = shuttle.metrics.fVertical ? shuttle.capture.x : shuttle.capture.y;
            var distance;	// distance beyond edge of shuttle rectangle

            if (pos < shuttle.loDeadband)
                distance = pos - shuttle.loDeadband;	// distance is negative
            else if (pos > shuttle.hiDeadband)
                distance = pos - shuttle.hiDeadband;	// distance is positive
            else // within deadband:
                distance = 0;

            var ddistance	= shuttle.metrics.fVertical ? shuttle.capture.dx : shuttle.capture.dy;			// change in distance between cursor and travel line
            var proportion	= (distance==0) ? 0 :  ddistance / distance;

            // Compute pixel ratio based on cursor distance from travel axis (ratio is 1.0 when within deadband):
            var pixelRatio	= Math.exp(shuttle.rPixelRatio * Math.abs(distance));

            // Add movement component along the travel axis, using computed pixelRatio:
            var pixels = (shuttle.metrics.fVertical ? -shuttle.capture.dy : shuttle.capture.dx);
            shuttle.position += pixels * pixelRatio;

            // Compute running total of deviation (in pixels) between cursor and shuttle along travel axis:
            shuttle.deviation += pixels * (1 - pixelRatio);

            // Movement component normal to the travel axis. If mouse is moving away from the travel axis, then do nothing.
            // If mouse is moving toward the travel axis, then move the shuttle proportionally toward the current mouse
            // position. This way, if the user slides the mouse all the way to the travel axis, then the mouse and the
            // shuttle position will converge at the same point along the travel axis:
            if (proportion < 0) {	// User moved cursor toward the shuttle:
    //			var adjustment = shuttle.deviation * -proportion;	// This makes the cursor meet with the shuttle in a linear fashion
                var adjustment = shuttle.deviation * -proportion * pixelRatio;	// This makes the cursor meet with the shuttle in an exponential fashion

                shuttle.deviation	-= adjustment;	// subtract adjustment from deviation
                shuttle.position	+= adjustment;	// and add it to shuttle position
            }

            // Clamp shuttle position to between [0 and travel]:
            var position;
            if (shuttle.reverseFactor > 0)
                position = Math.min(shuttle.travel, Math.max(0, shuttle.position));
            else
                position = Math.max(shuttle.travel, Math.min(0, shuttle.position));

            // Compute new value based on revised shuttle.position:
            var value = ((position / shuttle.travel) * (shuttle.maximum - shuttle.minimum)) + shuttle.minimum;

            // Move the shuttle in response to the user cursor movement (for smooth movement between detents):
            shuttle.updatePosition();

            // Snap new value to nearest detent:
            value = Math.round(value / shuttle.resolution) * shuttle.resolution;

            if (value != shuttle.value) {	// Value changed, so write it out:
                if (shuttle.node)
                    owner.settingsManager.pushPending(shuttle.node, value, this);
                    //shuttle.node.setValue (value);
                if (shuttle.object && shuttle.attr) {
                    shuttle.object[shuttle.attr] = value;
                    if (shuttle.callback)
                        shuttle.callback();
                }
                shuttle.value = value;

                // Display current value written to node:
                shuttle.updateText();
            }
            break;

        case 'mouseup':
        case 'touchend':
            delete shuttle.capture;

            // Immediately snap to nearest detent of written value:
            shuttle.updateValue.call(shuttle, shuttle.value);

            // If current node value does not match most recently written shuttle value, then write the shuttle value
            // again, so that, if the used paused more than 2 seconds and the values don't match, the shuttle will
            // reflect the correct node value in 2 seconds (instead of immediately, which is not what the user expects):
            if (shuttle.node && shuttle.value != shuttle.node.getValue())
                shuttle.node.setValue(shuttle.value);
            if (shuttle.object && shuttle.attr) {
                shuttle.object[shuttle.attr] = shuttle.value;
                if (shuttle.callback)
                    shuttle.callback();
            }
            break;
        }
    };

    processDoubleClick = function(evt) {	// 'this' is the rectangle svg element, and this._Shuttle is the shuttle object
        shuttleInput.onShuttleDblClick(this._Shuttle);	// Tell the ShuttleInput Singleton that we were double clicked
    };

    update = function (node) {	// 'this' is the Shuttle object:
        assert(node === this.node);

        if (node === undefined || node.quality != NodeQuality.NQ_GOOD)
            return;

        // If the shuttle is not grabbed or is not waiting for a move response, move the shuttle to 'value':
        if (!this.capture && !shuttleInput.shouldNotUpdate(this))
            this.updateValue(node.getValue());
    };

    updateValue = function (value) {	// Update the shuttle position to 'value':
        // NOTE: The shuttle location will not necessarily match the bar location, which always reflects the actual node value.
        // If the user is moving the shuttle, or has recently released it and the snap-back timer is not expired, the bar
        // and the shuttle may have different positions for a moment!
        // This is why we separately store the shuttle position in "this.value":

        // Compute shuttle position based on 'value' to get the "snap-to-detent" behavior as soon as the user releases the shuttle:
        this.position = Math.round(this.travel * (value - this.minimum) / (this.maximum - this.minimum));
        this.value = value;
        this.updatePosition();
        this.updateText();
    };

    updatePosition = function () {	// 'this' is the Shuttle object:
        // Clamp this.position to within [0 to this.travel]:
        var position;
        if (this.reverseFactor > 0)
            position = Math.min(this.travel, Math.max(0, this.position));
        else
            position = Math.max(this.travel, Math.min(0, this.position));

        // Move shuttle to new position based on 'position':
        if (this.metrics.fVertical) {
            this._shuttle.setAttribute('y', Math.max(this.metrics.shuttle.miny, Math.min(this.metrics.shuttle.maxy, this.metrics.shuttle.y - position)));
            this._pointer.setAttribute('y', this.metrics.pointer.y - position);
        } else { // horizontal bar:
            this._shuttle.setAttribute('x', Math.max(this.metrics.shuttle.minx, Math.min(this.metrics.shuttle.maxx, this.metrics.shuttle.x + position)));
            this._pointer.setAttribute('x', this.metrics.pointer.x + position);
        }
        shuttleInput.onPosition(this);	// Tell the overlap resolution dude that we moved
    };

    updateText = function () {	// 'this' is the Shuttle object:
        // Update the text based on ''value':
        this._textElement.textContent = this._outputText(this.value);
    };

    destroy = function () {
        if(this.node && this.node.subscribers)
            this.node.unsubscribe(this);
        this.unregisterAsWidget();
        shuttleInput.unregister(this);	// We dead. No longer need overlap resolution
        this.parent.removeChild(this._shuttle);
        this.parent.removeChild(this._pointer);
    };

    _outputText = function(value) {
        if (isNaN(value))
            return '';
        if(this.label)
            return Localization.toLocal(this.label) + value.toFixed(this.digits);
        else
            return value.toFixed(this.digits);
    };

    _getTextExtents = function () {	// Get maximum text size (CSS pixels) based on shuttle min and max:
        // Create two text elements so we can query their size in pixels:
        var el1 = createSVGElement ('text', 'shuttle-text', this.parent, null, this._outputText(this.minimum));
        var el2 = createSVGElement ('text', 'shuttle-text', this.parent, null, this._outputText(this.maximum));

        // Get the size of the two extreme shuttle labels:
        var l1 = el1.getBBox();
        var l2 = el2.getBBox();

        // Finished with the elements, so extricate them from the DOM:
        this.parent.removeChild(el1);
        this.parent.removeChild(el2);

        return {	// return an object with width and height attributes:
            width: Math.max(l1.width, l2.width),
            height: Math.max(l1.height, l2.height)
            };
    };

    // Class-level variables:
    static radius	= 2;			// shuttle rectangle corner radius
    static overlap	= 1;			// amount shuttle should overlap over baseline (e.g. amount that shuttle pointer overlaps onto bar graph)
    static marginx	= 2;			// margin inside box on either side of text
    static marginy	= 1;			// margin inside box above and below text
    static mouseDistance = 150;		// distance from shuttle (in pixels) where mouse moves are desensitized to exactly 1 pixel per resolution

};

// This is a helper Singleton. Basic idea is that SVG input elements don't exist and SVG elements can
// only get a limited set of UI events. Specifically, they can't get any keyboard events. To that end,
// this guy keeps track of which shuttle has been recently double clicked (without being clicked off of)
// and will keep track of the keyboard presses that could be for new, written data.
export class ShuttleInput {
    constructor() {
        window.addEventListener('click', this.onClick.bind(this));		// Bind to the global events we need
        window.addEventListener('keydown', this.onKeyDown.bind(this));
    };

	register(shuttle, svg, orientation) {		// Shuttle was just created
		if (svg.shuttleList === undefined)				// No list of orientations defined for the SVG element
			svg.shuttleList = {};						// Create it
		if (svg.shuttleList[orientation] === undefined)	// No array created for this orientation
			svg.shuttleList[orientation] = [];			// Create it
		svg.shuttleList[orientation].push(shuttle);		// Add the shuttle to the list
	}

	unregister(shuttle) {						// Shuttle was destroyed
		var array = shuttle.parent.shuttleList[shuttle.orientation];	// Find the list of shuttles for this side
		array.splice(array.indexOf(shuttle), 1);		// Remove this shuttle from our list
	}

	sort(e1, e2) {	// This sorts bottom to top for vertical shuttles or right to left for horizontal shuttles
		var param = e1.metrics.fVertical ? 'y' : 'x';	// Get the appropriate parameter to check for overlap
		var a = parseInt(e1._shuttle.getAttribute(param));		// Attribute for element 1
		var b = parseInt(e2._shuttle.getAttribute(param));		// Attribute for element 12
		if (a > b)		// If y1 is greater than y2, it is further down the SVG element
			return -1;	// Sort so that the bottom is first
		else			// y2 is not less
			return a < b ? 1 : 0;	// y2 probably is lower, but check for equality anyways
	}

	checkForConflicts(array, i, size, param) {		// Check if the ith entry has problems with any shuttles that are lower (farther right) on the page
		var ith = array[i]._shuttle.getAttribute(param);	// Get the location of the ith shuttle
		for (var j = 0; j < i; ++j)	// Start at 0 and go until the ith entry
			if (Math.abs(ith - array[j]._shuttle.getAttribute(param)) < size) // Check if there's a conflict in spacing
				return j;	// Return the index of the entry we have a problem with
		return null;		// No issues found
	}

	onPosition(shuttle) {	// Called when a shuttle's position has changed
		if (this.fLocked)			// If we are the one's moving the shuttle, don't do anything
			return;
		var size, param;			// Hold the size of the shuttle we care about and the name of the parameter we need to evaluate
		if (shuttle.metrics.fVertical) {			// If the shuttle is vertical
			param = 'y';							// We care about the y axis
			size = shuttle.metrics.shuttle.height;	// And need to correct for this guy's height
		} else {									// Shuttle is horizontal
			param = 'x';							// We care about the x axis
			size = shuttle.metrics.shuttle.width;	// And need to correct for this guy's width
		}

		var array = shuttle.parent.shuttleList[shuttle.orientation];	// Array of shuttles on this same side of the same SVG elemtn
		var sorted = [];							// Arrange the shuttles going from bottom to top (or right to left)
		this.fLocked = true;						// Set the lock so we don't enter this method in an endless loop
		for (var i = 0; i < array.length; ++i)		// For each shuttle
			if (array[i]._shuttle && array[i].inside === shuttle.inside) {				// If the SVG elements have been rendered (remember, shuttles are created, then getMetrics, then drawn)
				array[i].updatePosition();			// Reset position to original spot before we sort by position
				sorted.binsert(array[i], this.sort);	// Insert the shuttle into our sorted array
			}
		this.fLocked = false;						// No longer calling update position

		for (var i = 1; i < sorted.length; ++i) { 	// Check all other shuttles after the first (we leave the lowest shuttle alone)
			var conflict = this.checkForConflicts(sorted, i, size, param); 	// First, check for inital conflicts
			if (conflict !== null) {				// If it is overlapped
                let maxChecks = 0;
				do {
					sorted[i]._shuttle.setAttribute(param, parseFloat(sorted[conflict]._shuttle.getAttribute(param)) - size);	// Move the shuttle up
					conflict = this.checkForConflicts(sorted, i, size, param); 	// Recheck for conflicts
                    maxChecks++;
				} while(conflict !== null && maxChecks < 20)			// Keep checking until it is cleared up
			}
		}
	}

	onShuttleDblClick(shuttle) {
		// Shuttle was doubled clicked. Give him focus for typing
		if (shuttle.node && !shuttle.node.hasWritePermission())
			return;

		this.shuttle = shuttle;
		this.shuttle._textElement.textContent = '';	// Clean out his label
	}

	shouldNotUpdate(shuttle) {
		return shuttle === this.shuttle;	// If a shuttle is being typed into, it shouldn't update
	}

	onClick(evt){
		if (this.shuttle) 				// Revert the shuttle
			this.shuttle.updateText();	// This will call update with its old value
		this.shuttle	= null;			// Clicked on a not-shuttle. Count that as a blur
		this.fModifed	= false;		// Definitely not modified anymore
	}

	onKeyDown(evt){
		if (!this.shuttle)	// No shuttle, nothing to do.
			return;

		 if ((48 <= evt.keyCode) && (evt.keyCode <= 57))	 	//	If between or equal to 0-9
			 this.addLetter(String.fromCharCode(evt.keyCode));
		 else if ((evt.keyCode == 190) || evt.keyCode == 110) 	// The period and the decimal point
			 this.addLetter('.');
		 else if ((96 <= evt.keyCode) && (evt.keyCode <= 105)) 	//	If between or equal to 0-9 on the number pad
			 this.addLetter(String.fromCharCode(evt.keyCode - 48));
		 else if (evt.keyCode == 8)
			 this.shuttle._textElement.textContent = this.shuttle._textElement.textContent.substr(0, this.shuttle._textElement.textContent.length - 1);
		 else if (evt.keyCode == 13) {	// Enter button
			 if (this.fModified) {		// If they made some input, try to write out a new node value
				var value = parseFloat(this.shuttle._textElement.textContent);
				if (!isNaN(value)) {	// We got a good value from the shuttle
					var node = this.shuttle.node;
					if (node) {
						if (node.flags & NodeFlags.NF_RANGE) {	// Clamp value to within range:
							if (value < node.engMin)
								value = node.engMin;
							else if (value > node.engMax)
								value = node.engMax;
						}

						if ((node.flags & NodeFlags.NF_RESOLUTION) && (node.resolution > 0))	// Check the resolution
							value = Math.round(value / node.resolution) * node.resolution;
						this.shuttle.node.setValue(value);	// Write the value out
					} else {
						this.shuttle.updateValue(value);
						if (this.shuttle.object && this.shuttle.attr) {
							this.shuttle.object[this.shuttle.attr] = this.shuttle.value;
							if (this.shuttle.callback)
								this.shuttle.callback();
						}
					}
				}
			 }
			 this.onClick();	// Blur!
		 } else if (evt.keyCode == 27)
			 this.onClick();	// Blur!

		 evt.preventDefault();	// Try to limit event propagation if a shuttle has 'focus'
		 evt.stopPropagation();
		 return false;
	}

	addLetter(letter){
		 this.shuttle._textElement.textContent += letter;	// Add the letter to the shuttle text
		 this.fModified = true;								// Make a note that we have been modified
	}
};

var shuttleInput = new ShuttleInput();	// Create the oneshot we have been looking for
