import { Node as Tag } from "../node";
import { RegisterWidget, Widget } from "./lib/widget";
import { TagAttribute, } from "./lib/tag";
import { Attribute } from "./lib/attributes";
import Icon from '../images/icons/button.svg';
import type { TagDefinition } from "./lib/tag";


const template: string = /*html*/`
<style>
    :host {
        width: 120px;
        height: 32px;
        min-height: 24px;
        min-width: 72px;
        display: block;
        position: relative;
        overflow: hidden;
        color: white;
        --text-color: var(--color-onSurface);
        --background-color: var(--color-surfaceContainerLowest);
        --border-color: var(--color-primary);
        border-radius: 999999px;
        border: 2px solid var(--border-color);
    }
    .container {
        height: 100%;
        width: 100%;
        container: switch / inline-size;
    }
    .container.disabled {
        height: 100%;
        width: 100%;
        opacity: 0.5;
    }
    input {
        display: none;
    }
    label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 35px;
        cursor: pointer;
        padding: 1px;
        background-color: var(--background-color);
        color: var(--text-color);
    }
    .container.disabled label {
        cursor: not-allowed
    }

    input:checked ~ label {
        border-radius: 999999px;
        box-shadow: inset 0 0 6px var(--border-color);
        color: var(--text-color);
    }
    .switch__disabled {
        opacity: 30%;
        cursor:not-allowed;
    }
</style>
<div class="container">
    <input id="check" type="checkbox">
    <label id="label" for="check"></label>
</div>
`.trim();

@RegisterWidget({tag: 'push-button', displayName: 'Push Button', icon: Icon, section: 'Input'})
export class PushButton extends Widget {
    protected valueElement: HTMLDivElement;
    protected checkElement: HTMLInputElement;
    protected container: HTMLElement;
    private _checked: boolean;
    onchange: ()=>void = ()=>{};

    @Attribute({displayName: 'Pressed Value'}) pressedValue: number = 0;
    @Attribute({displayName: 'Text'}) buttonText: string;
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) backgroundColor: string = 'var(--color-surfaceContainerLowest)';
    @Attribute({displayName: 'Border Color', typeModifier: 'color'}) borderColor: string = 'var(--color-primary)';
    @Attribute({displayName: 'Text Color', typeModifier: 'color'}) textColor: string = 'var(--color-onSurface)'

    render(): Node | null {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        this.style.setProperty('--border-color', this.borderColor);

        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected connectedCallback(): void {
        this.container = this.shadowRoot!.querySelector('.container') as HTMLDivElement;
        this.checkElement = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        let text  = this.shadowRoot!.getElementById('label') as HTMLDivElement;
        text.textContent = this.buttonText;

        this.checkElement.onchange = () => {
            this.onchange();
        }
    }

    protected enliven(): void {
        this.checkElement.checked = this.checked;
    }

    get checked() {
        return this._checked;
    }

    set checked(v: boolean) {
        this._checked = v;
        this.checkElement.checked = this._checked;
    }
}



@RegisterWidget({tag: 'tag-push-button', displayName: 'Tag Push Button', icon: Icon, section: 'Input', roles: ['PushButton', 'ResetFault']})
export class TagPushButton extends PushButton{
    @TagAttribute({
        displayName: 'Button Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['boolean']
    }) buttonTag: TagDefinition;

    protected enliven(): void {
        super.enliven();
        let text  = this.shadowRoot!.getElementById('label') as HTMLDivElement;
        text.textContent = this.buttonText ?? this.buttonTag.tag.getDisplayName(false, true);

        this.onchange = () => {
            this.buttonTag.tag.setPendingValue(this.pressedValue, this);
        }
    }

    update(tag: Tag): void {
        this.container.classList.toggle('disabled', tag.getValue(this) == this.pressedValue)
    }
}

