import './alarmpanel.css';
import './alarms.css';
import AlarmIcon 								from './images/icons/alarms.svg'
import BackIcon 								from './images/icons/arrow_back.svg';
import ForwardIcon 								from './images/icons/arrow_forward.svg';
import CloseIcon 								from './images/icons/close.svg';
import {createElement, getHumanReadableTime} 	from './elements';
import owner 									from '../owner';
import TextInput 								from './components/textinput';
import SearchIcon 								from './images/icons/search.svg';

export default class AlarmPanel {
    constructor(parent) {
        this.parent         = parent;
        this.isOpen         = false; // whether we are open (true) or closed (false)
        this.container      = createElement('div', 'alarm-panel__container', this.parent)	// Our container for the alarms panel
		this.alarms         = createElement('div', 'alarm-panel', this.container);			// Our actual alarms panel

        this.alarmSummaries = new SiteAlarmSummaries(this.alarms, owner.ldc.devices)

        // this.blur.addEventListener('click', () => this.toggle(), false);
    };

    open() {
        this.isOpen = true;
		this.container.addEventListener('transitionend', ()=>window.dispatchEvent(new Event('resize')), { once: true });
		if (window.innerWidth < 620)
			owner.menuPanel.close();
        this.container.classList.add('open');
        owner.navBar.openAlarm();
    };

    close() {
        this.isOpen = false;
		this.container.addEventListener('transitionend', ()=>window.dispatchEvent(new Event('resize')), { once: true });
        this.container.classList.remove('open');
        owner.navBar.closeAlarm();
    }

    toggle() {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open()
        }
    }

    destroy() {
        this.container.removeChildren();
        this.parent.removeChild(this.container);
    }
};

class SiteAlarmSummaries {
	constructor(parent, deviceSet) {
		this.parent				= parent;	// The side panels div
		this.devices			= deviceSet.array;
		this.nav				= createElement('div', 'alarm-summaries__nav', this.parent);
		let navTopRow 			= createElement('div', 'alarm-summaries__nav__row', this.nav);
		this.backButton			= createElement('img', 'alarm-summaries__nav__back-button hide', navTopRow, undefined, {'src': BackIcon});
		this.title				= createElement('div', 'alarm-summaries__nav__title', navTopRow, 'Alarms');
		this.closeButton		= createElement('img', 'alarm-summaries__nav__close-button', navTopRow, undefined, {'src': CloseIcon});

		let navBottom           = createElement('div', 'alarm-summaries__nav__search', this.nav);
        let searchContainer 	= createElement('div',  'alarm-summaries__search', navBottom);
        this.searchBar         	= createElement('input', 'alarm-summaries__search__input', searchContainer, undefined, {'type': 'text'});
        createElement('img', 'alarm-summaries__search__icon', searchContainer, undefined, {'src': SearchIcon});
        searchContainer.onclick = () => {
            this.searchBar.focus();
            searchContainer.classList.add('alarm-summaries__focus');
        }
		this.searchBar.onblur = () => {
            this.searchBar.blur();
			if (searchContainer.classList.contains('alarm-summaries__focus'))
            	searchContainer.classList.remove('alarm-summaries__focus');
		}
		this.alarmSection		= createElement('section', 'alarmListSection', this.parent);
		this.alarmContainer		= createElement('div', 'container alarmHomeList', this.alarmSection);
		this.messageThread		= createElement('div', 'alarm-list hide', this.alarmSection);
		this.alarmCount			= 0;

		this.rowMap				= new Map();	// Map of devices to rows
		this.stubMap			= new Map();	// Map of alarms to stubs
		this.createSiteRow(null);				// Create a row for all sites
		for (var i = 0; i < this.devices.length; ++i) {
			this.createSiteRow(this.devices[i]);	// And a row for each device
			this.alarmCount += this.devices[i].alarms.active
		}

		this.searchBar.oninput = () => this.searchByText();	// Search as they type
    	this.backButton.addEventListener('click', this.onBackClick.bind(this));	// Go back when they tell us to
    	//this.alarmTitleContent.addEventListener('click', this.onTitleClick.bind(this));
		this.closeButton.onclick = () => owner.alarmPanel.close();

		for (var i = 0; i < this.devices.length; ++i) 		// Get notified of all alarms
			this.devices[i].alarms.registerCallback(this);

		this.intervalID = setInterval(this.updateStubTimes.bind(this), 1000*60);	// Update stub timers every 60 seconds
	}

	refresh() {
		this.updateStubTimes();
		for (var [device, siteRow] of this.rowMap)
			siteRow.timeStamp.textContent	= this.getTextMessageTime(siteRow.tsActivated) + ' ';
	}

	show(device) {
		this.rowMap.get(device).click();
	}

	onTitleClick() {
		if (this.selectedSite)
			owner.navigation.path = this.selectedSite.key;	// Navigate to the selected site if we have one
	}

	createSiteRow(device) {
		var siteRow 			= createElement('div', 'alarm-site hide', this.alarmContainer);
		siteRow.onclick 		= this.onSiteClick.bind(this, device, siteRow);
		siteRow.tsActivated		= 0;
		var alarmNumber			= createElement('div', 'alarm-site__count', 	siteRow);
		siteRow.alarmIcon		= createElement('img', 'alarm-site__count__alarm-icon', alarmNumber, undefined, {'src':AlarmIcon});
		siteRow.alarmCount		= createElement('p', 'alarm-site__count__number', alarmNumber);
		var alarmInfo			= createElement('div', 'alarm-site__info', siteRow);
		var infoHeader			= createElement('div', 'row', alarmInfo);
		siteRow.siteName		= createElement('div', 'alarm-site__name', infoHeader, device ? device.siteName : "All Sites");
		var timeWrapper			= createElement('div', 'alarm-site__time', siteRow);
		siteRow.timeStamp		= createElement('span', null, timeWrapper);
		createElement('img', 'alarm-site__forward-icon', siteRow, undefined, {'src':ForwardIcon});
		var messageContainer	= createElement('div', 'row', alarmInfo);
		siteRow.message			= createElement('div', 'col-12 recentAlarmMessage', messageContainer);
		this.rowMap.set(device, siteRow);	// Update our map
	}

	onSiteClick(device, siteRow) {	// A site was clicked
		this.backButton.classList.remove('hide');				// Show the back button
		this.messageThread.classList.remove('hide');			// Show the alarm list
		this.alarmContainer.classList.add('hide');				// Don't show site list
		this.title.textContent = siteRow.siteName.textContent;	// Update the header
		this.selectedSite = device;		// Remember the selected site
		/*
		if (this.selectedSite)
			this.alarmTitleContent.classList.add('clickable');
			*/
		this.clearSearch();								// Clear the search bar
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;		// Scroll to the bottom like a text message thread
	}

	onBackClick() {					// The back button was clicked
		this.backButton.classList.add('hide');			// Hide the back button
		this.messageThread.classList.add('hide');		// Hide the alarm list
		this.alarmContainer.classList.remove('hide');	// Show the sites
		this.title.textContent = 'Alarms';	// Reset header message
		delete this.selectedSite;						// No selected site
		this.clearSearch();								// Clear the search bar
	}

	setStubTimes(alarm, stub) {
		stub.time.textContent = (getHumanReadableTime(alarm.tsActivated, '', ' ago') + " " + (new Date(alarm.tsActivated/1000)).format("(%HH:%mm:%ss on %yyyy-%MM-%dd)"));
		for (var i = 0; i < stub.replies.length; ++i)
			stub.replies[i].time.textContent = getHumanReadableTime(alarm.tsAcknowledged[i], '', ' ago');
	}

	updateStubTimes() {
		for (var [alarm, stub] of this.stubMap)	// For each alarm we have
			this.setStubTimes(alarm, stub);		// Update the stub times
	}

	clearSearch() {
		this.searchBar.value = '';		// Clear the search bar
		this.searchByText();			// Clear searched messages
	}

	searchByText() {
		var searchText = this.searchBar.value.toLowerCase();	// Convert to lower case once
		if (this.selectedSite === undefined)					// If there's no site selected
			this.searchSitesByText(searchText);					// Search the site rows
		else
			this.searchMessagesByText(searchText)				// Filter messages by the selected site
	}

	searchSitesByText(searchText) {
		for (var [device, siteRow] of this.rowMap) {			// For each row
			var fCollapse = false			// Assume we shouldn't hide the row until proven otherwise
			if (searchText.length != 0) {	// If we have something to search on
				if (!device)				// If there's not a device here (the All Sites row)
					fCollapse = true;		// Collapse it every time
				else if (!device.siteName.toLowerCase().includes(searchText)) {	// If we don't match site name
					fCollapse = true;		// Collapse the row unless we find a matching alarm at the site
					var alarmSet = device.alarms;
					for (var i = 0; i < alarmSet.alarms.length; ++i) {	// But first look to see if they match an alarm here
						var alarm = alarmSet.alarms[i]
						if (alarm.isActive() && alarm.message.toLowerCase().includes(searchText)) {	// If the alarm message matches
							fCollapse = false;	// Don't collapse
							break;				// And stop looking
						}
					}
				}
			}
			siteRow.classList.toggle('hide', fCollapse);
		}
	}

	searchMessagesByText(searchText) {
		for (var [alarm, stub] of this.stubMap) {	// For each alarm we have
			var fCollapse = false;					// Assume we don't hid until proven otherwise
			if (searchText.length != 0 || this.selectedSite) {			// If we have search text
				if (this.selectedSite && this.selectedSite !== alarm.alarmSet.device){	// If this site is hidden
					fCollapse = true;				// Collapse the alarm
				} else 								// We are the correct site
					fCollapse = !stub.innerText.toLowerCase().includes(searchText);	// Show the alarm if it matches
			}
			stub.classList.toggle('hide', fCollapse);
		}
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;	// Scroll to the bottom like a text message feed
	}

	getTextMessageTime (timestamp) {
		var now 			= (new Date()).getTime();
		var secondsElapsed 	= (now - timestamp/1000) / 1000;	// Convert timestamp to milliseconds, take the diff from current time, then convert to seconds
		timestamp			= new Date(timestamp/1000);			// Conver the timestamp to a JS Date object
		var midnight		= new Date();						// Get a Date for this instant
		midnight.setHours(0,0,0,0);								// And take it back to midnight
		var fYesterday = timestamp < midnight && timestamp > (midnight - 86400000);	// True if the alarm was yesterday
		if (secondsElapsed > 604800)					// Longer than a week
			return timestamp.format("%yyyy/%MM/%dd");
		else if (secondsElapsed > 86400 && !fYesterday)	// Longer than 24 hrs and not yesterday
			return timestamp.format("%DDDD");
		else if (fYesterday)							// Yesterday
			return 'Yesterday';
		else 											// Today
			return timestamp.format('%HH:%mm');
	}

	createPendingReply(alarm, stub){
		if(alarm.severity < 100 || stub.pendReply || (/*!alarm.alarmSet.fAlarmsUpdated &&*/ !alarm.isUnacknowledged()))
			return;         // Not creating secondary replies for now
		var pendReply = createElement('div', 'alarm-card__pend-reply', stub.replyContainer);
		//var pendReply = owner.appendStub(owner.templates.alarmPendingReply, stub.replyContainer);
		stub.pendReply = pendReply;
		pendReply.submit = createElement('div', 'alarm-card__pend-reply__submit', pendReply, 'Acknowledge');
		pendReply.cancel = createElement('div', 'alarm-card__pend-reply__cancel', pendReply, 'Cancel');
		pendReply.textBox = createElement('input', 'alarm-card__pend-reply__text-box hide', pendReply, undefined, {'type':'text'})
		pendReply.submit.onclick = this.onReplySubmit.bind(this, alarm, stub, pendReply);
		pendReply.cancel.onclick = this.destroyPendingReply.bind(this, alarm, stub, pendReply);
//		pendReply.textbox.classList.toggle('hide', !alarm.alarmSet.fAlarmsUpdated);
		pendReply.textBox.classList.add('hide');
	}

	onReplySubmit(alarm, stub, pendReply){
		alarm.ackMessage = pendReply.textBox.value;
		alarm.alarmSet.acknowledge([alarm]);
		this.destroyPendingReply(alarm, stub, pendReply);
	}

	destroyPendingReply(alarm, stub, pendReply){
		stub.replyContainer.removeChild(pendReply);
		delete stub.pendReply;
	}

	insertAlarm(a, b)	{return a.tsActivated > b.tsActivated;}	// Alarms go newest last
	insertDevice(a, b)	 {return a !== b && a.tsActivated >= b.tsActivated;}	// Devices go newest first
	insertChild(parent, child, sortFunction) {
		for (var i = 0; i < parent.children.length; ++i)	// Check all children of the parent
			if (sortFunction(child, parent.children[i]))	// If this node comes before this child
				return parent.insertChildAt(child, i);		// Insert the child into the DOM here
		return parent.appendChild(child);					// Only get here if we are the last in the list
	}

	onAlarmAdded(alarm, device) {
		// Insert new message section based on time
		var alarmCard = createElement('div', 'alarm-card');
		alarmCard.siteName = createElement('div', 'alarm-card__site-name', alarmCard);
		alarmCard.box = createElement('div', 'alarm-card__box', alarmCard);
		createElement('div', 'alarm-card__box__triangle', alarmCard.box);
		alarmCard.message 	= createElement('div', 'alarm-card__message', alarmCard.box);
		alarmCard.detail	= createElement('div', 'alarm-card__detail', alarmCard);
		alarmCard.time		= createElement('div', 'alarm-card__detail__time', alarmCard.detail);
		alarmCard.severity	= createElement('div', 'alarm-card__detail__severity', alarmCard.detail);
		alarmCard.replyContainer = createElement('div', 'alarm-card__reply', alarmCard);
		alarmCard.box.onclick = this.createPendingReply.bind(this, alarm, alarmCard);
		alarmCard.tsActivated = alarm.tsActivated;	// For sorting
		alarmCard.replies = [];							// Start off with no replies
		this.insertChild(this.messageThread, alarmCard, this.insertAlarm);	// Insert the child
		this.stubMap.set(alarm, alarmCard);
	}

	onAlarmChanged(alarm, fNew, device) {
  		var stub = this.stubMap.get(alarm);
  		if (!alarm.isActive())	{	// If the alarm isn'a active
  			if (stub) {
  				this.stubMap.delete(alarm);
  				this.messageThread.removeChild(stub)	// Remove the stub
  			}
			return;					// Nothing else to do
  		}

  		if (fNew) {	// These things don't change. Only set them the first time
  			stub.siteName.textContent = device.siteName + ' -- ' + alarm.tag;
  			stub.message.textContent = alarm.message;
  			stub.severity.textContent = alarm.severity;
  			stub.classList.toggle('hide', this.selectedSite && this.selectedSite !== device);
  		}

		if(alarm.severity >= 100) {	// If the alarm can be ack'd
			for (var i = stub.replies.length; i < alarm.userNames.length; ++i) {	// Acknowledged alarms get a reply message generated
				var reply = createElement('div', 'alarm-card__reply', stub.replyContainer);
				reply.userName = createElement('div', 'alarm-card__reply__user-name', reply);
				reply.alarmReplyBox = createElement('div', 'alarm-card__reply__box',reply);
				createElement('div', 'alarm-card__reply__box__triangle', reply.alarmReplyBox);
				reply.message = createElement('div', 'alarm-card__reply__message', reply.alarmReplyBox);
				reply.time = createElement('div', 'alarm-card__reply__time', reply)
				reply.alarmReplyBox.onclick = this.createPendingReply.bind(this, alarm, stub);
				reply.userName.textContent = alarm.userNames[i];

				if (!alarm.alarmSet.fAlarmsUpdated || true) // TODO: why?
					reply.message.textContent = 'Acknowledged.';
				else
					{ }
				stub.replies.push(reply);

//				if (alarm.userNames[i] !== owner.ldc.user.username)
//					reply._root.setAttribute('otheruser', true);
			}
		}
		this.setStubTimes(alarm, stub);
  	}

	updateRow(siteRow, alarm, activeCount, fUnAck) {
		if (alarm) {
			siteRow.tsActivated				= alarm.tsActivated;	// Used for sorting
			siteRow.timeStamp.textContent	= this.getTextMessageTime(alarm.tsActivated) + ' ';
			siteRow.message.textContent		= alarm.message;
		}

		siteRow.alarmIcon.classList.toggle('alarm-site__unacknowledged', fUnAck);
		siteRow.alarmCount.textContent	= activeCount;
  		siteRow.classList.toggle('hide', activeCount == 0);	// Hide the row if no active alarms
		this.insertChild(this.alarmContainer, siteRow, this.insertDevice)
	}

	findMostRecentAlarm(alarmSet) {		// Find the most recent active alarm for a given site
		if (alarmSet.active == 0)		// If the device doesn't have an active alarm
			return null;				// Just return null
		for (var j = alarmSet.alarms.length - 1; j >= 0; --j)	// Check alarms in reverse order
			if (alarmSet.alarms[j].isActive())	// If the alarm is unactive, keep looking
				return alarmSet.alarms[j];
	}

  	endAlarmCommand(device) {
		var mostRecent = {tsActivated: 0}, total = 0, fUnack = false;	// Find the most recent alarm for the All Sites entry
		for (var i = 0; i < this.devices.length; ++i) {		// For all devices
			var alarmSet = this.devices[i].alarms;			// Convenience reference to the alarm set
			total += alarmSet.active;						// Add in the total number of active alarms
			fUnack = fUnack || alarmSet.unackActive > 0;	// Remember if any alarms were not acknowledged
			var alarm = this.findMostRecentAlarm(alarmSet);	// Find the most recent active alarm for this device
			if (alarm && alarm.tsActivated > mostRecent.tsActivated)	// If this the most recent alarm we've seen
				mostRecent = alarm;							// Hold onto the alarm
		}

  		var siteRow = this.rowMap.get(device);	// Get the row for this device
  		this.updateRow(siteRow, this.findMostRecentAlarm(device.alarms), device.alarms.active, device.alarms.unackActive > 0);
		this.updateRow(this.rowMap.get(null), mostRecent, total, fUnack);	// Get the All Sites entry and update it
		this.alarmCount = total;
		if (owner.navBar)
			owner.navBar.alarmCount = total;


		if (this.searchBar.value.length)	// If there's search text
			this.searchByText();			// Research
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;	// Scroll to the bottom like a text message feed
  	}

  	onAlarmRemoved(alarm, device) {}	// Unused alarm callbacks
  	onHistorical() {}
}
