import { createElement } from "../elements";
import { GenericGraph, GraphNode, HDRequest, StaticGraph } from "../graph";
import Card from "./card";
import owner from "../../owner";
import Loader from "../loader";
import { SocketList, TagSocket } from "./tagsocket__classic";
import { LoggedFilter, UnitsFilter } from "../tagfilter";
import { TagUnitQuantity } from "../widgets/lib/tagunits";

export default class HistoricalCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 800,
                height: 600,
                minWidth: 300,
                minHeight: 200,
                type: 'historical',
            }
        }
        super(parent, editor, props);
        this.element.graphID    = owner.ldc.registerGraph(this.element);	// Register for graph data
        this.element.deviceMap  = new Map();
        this.element.socketList = new SocketList([
            new TagSocket('Trend Tag', 'Clever description here', ['period', 'reps'], [new UnitsFilter([TagUnitQuantity.TUQ_FLOWRATE]), new LoggedFilter(true, false)]),
        ], this);
        this.element.onGraphDataResponse = (interval, data, id)  => {
            var devNode = this.element.device;
            if (devNode === undefined || devNode.request === undefined || devNode.request.interval !== interval || devNode.request.nodes.length !== data[0].length) {
                return;
            }
            for (var i = 0; i < devNode.request.nodes.length; ++i) {			// Check all of our nodes
                if (data[1+i*4].length == 0){    // If no data came back
                    if (devNode.request.start*1000 < devNode.request.nodes[i].start) {            // If we are requesting data before data we have -- then there's no data to be had
                        data[1+i*4].push(devNode.request.start*1000, devNode.request.end*1000);    // Fill the area in with nulls
                        data[2+i*4].push(null, null);
                        data[3+i*4].push(null, null);
                        data[4+i*4].push(null, null);
                    } else {                        // This is actually a manually logged node trying to get current. Just trim it out for now
                        data[0].splice(i, 1);       // Remove the name
                        data.splice(1+i*4, 4);      // Remove the data rows
                        devNode.request.nodes.splice(i, 1);    // Remove it from the request
                        --i;                        // Decrement so the loop works
                        continue;                   // Nothing else to do for this node
                    }
                }
                if (devNode.request.start * 1000 < devNode.request.nodes[i].start && data[1 + i * 4][0] > devNode.request.start * 1000) {
                    data[1 + i * 4].unshift(data[1 + i * 4][0]);
                    data[2 + i * 4].unshift(null);
                    data[3 + i * 4].unshift(null);
                    data[4 + i * 4].unshift(null);
                }
                if (!devNode.request.nodes[i].fMax)
                    data[2 + i * 4] = data[4 + i * 4] = null;

                devNode.request.nodes[i].start = Math.min(devNode.request.nodes[i].start, data[1 + i * 4].front() / 1000);
                devNode.request.nodes[i].end = Math.max(devNode.request.nodes[i].end, data[1 + i * 4].back() / 1000 + interval);

                var conversion = devNode.request.nodes[i].node.convertFromCacheToDisplay(1);	// If the node needs to be converted
                if (conversion == 1)
                    continue;
                this.convertArray(data[2 + i * 4], conversion);		// Convert all columns that exist
                this.convertArray(data[3 + i * 4], conversion);
                this.convertArray(data[4 + i * 4], conversion);
            }

            let historicalData  = [[]];                 // initialize empty 2D array
            let avgData         = [[],null,[],null];    // initialize empty average array
            let curPeriodStart  = this.element.ranges[this.element.ranges.length - 1];
            for (let i=0; i<this.element.ranges.length; i++) {
                let rangeStart  = this.element.ranges[i];
                let rangeEnd    = this.element.ranges[i+1] ? this.element.ranges[i+1] : curPeriodStart;
                historicalData[0].push(new Date(rangeStart).toString());
                historicalData.push([],null,[],null);
                for (let j=0; j<data[1].length;j++) {
                    if (data[1][j] >= rangeStart && data[1][j] < rangeEnd) {
                        let seconds = data[1][j] - rangeStart;
                        historicalData[(i*4+1)].push(seconds + curPeriodStart);
                        historicalData[(i*4+3)].push(data[3][j]);

                        if (avgData[0][seconds / 1000 / this.element.interval])
                            avgData[2][seconds / 1000 / this.element.interval].push(data[3][j])
                        else {
                            avgData[0][seconds / 1000 / this.element.interval] = seconds + curPeriodStart;
                            avgData[2][seconds / 1000 / this.element.interval] = [data[3][j]]
                        }

                    }

                    else if (data[1][j] == rangeEnd) {
                        let seconds = rangeEnd - rangeStart
                        historicalData[(i*4+1)].push(seconds + curPeriodStart);
                        historicalData[(i*4+3)].push(data[3][j]);
                        if (avgData[0][seconds / 1000 / this.element.interval])
                            avgData[2][seconds / 1000 / this.element.interval].push(data[3][j])
                        else {
                            avgData[0][seconds / 1000 / this.element.interval] = seconds + curPeriodStart;
                            avgData[2][seconds / 1000 / this.element.interval] = [data[3][j]]
                        }
                    }

                }

                /*
                else { // this is the current period data
                    for (let j=0; j<data[1].length;j++) {
                        if (data[1][j]/1000 < rangeStart) {
                        }
                        else {
                            let date = new Date(data[1][j])
                            historicalData[(i*4+1)].push((curPeriodStart * 1000) + (date.getSeconds() + date.getMinutes()*60 + date.getHours()*60*60) * 1000);
                            historicalData[(i*4+3)].push(data[3][j]);
                        }
                    }
                }
                */

            }


            historicalData[0].push(new Date(curPeriodStart).toString());
            historicalData.push([],null,[],null);
            for (let j=0; j<data[1].length;j++) {
                if (data[1][j] >= curPeriodStart) {
                    historicalData[historicalData.length - 4].push(data[1][j]);
                    historicalData[historicalData.length - 2].push(data[3][j]);
                }
            }

            for (let i=0; i<avgData[2].length;i++) {
                if (Array.isArray(avgData[2][i]))
                    avgData[2][i] = avgData[2][i].reduce((a,b) => (a+b)) / avgData[2][i].length
            }

            historicalData.push(...avgData);
            historicalData[0].push('Average')

            for (let i=0;i<historicalData[historicalData.length - 4].length;i++) {
                if (historicalData[historicalData.length - 4][i] > historicalData[historicalData.length - 8][historicalData[historicalData.length - 8].length - 1]) {
                    historicalData[historicalData.length - 8].push(historicalData[historicalData.length - 4][i])
                    historicalData[historicalData.length - 6].push(null)
                }
            }

            let tag = this.resolveTag(this.element.socketList.sockets.get('Trend Tag').tags[0]);
            let today = new Date(curPeriodStart).toString();
            let options = {
                strokeWidth: 1,											// Make lines this thick
                colors: ['#ee8c90'],           // Colors previous trend lines
                ylabelcolor: '#d91f27',		// Left y-axis title color
                yAxisLineColor: '#d91f27',		// Color to make the left axis labels and line
                drawPoints: false,										// No need to show points
                nomouseover: true,                                      // Don't show a label for previous trends when we hover
                traditionalHover: true,                                 // Don't interpolate between points
                digitsAfterDecimal: 0,									// Display everything as an integer
                legend: 'true',											// We want a legend
                yAxisLabelWidth: 50,                                    // Give the axis label a little extra room
                ylabel: tag.getUnitsText(),	// Left y-axis title
            }

            options[today] =  {
                nomouseover: false,                                     // Make sure we show a label for today's trend when hovering
                strokeWidth: 3,                                         // Make this line thicker
                color: owner.colors.hex('--color-blue-8'),              // Make this line darker
                units: tag.getUnitsText(),    // Give the label units
            }
            options['Average'] =  {
                nomouseover: false,                                     // Make sure we show a label for today's trend when hovering
                strokeWidth: 3,                                         // Make this line thicker
                color: '#d91f27',            // Make this line orange
                units: tag.getUnitsText(),    // Give the label units
            }
            if (this.element.chart)
                this.element.chart.destroy();
            this.element.chart = new StaticGraph(this.ldc, this.element.ctx, this.element.ctx.clientWidth, this.element.ctx.clientHeight, historicalData, options,  true);	// This true means to allow hover events on the graph
        }

        this.element.ctx = createElement('div', 'card__historical__ctx', this.element);

        if (this.tags && this.tags.length > 0) {
            this.element.socketList.populate(this.tags);
        }

        else {
            this.tags = [];
            this.element.socketList.modify();
        }

        let tagOption       = createElement('div', 'card__dropdown__option', undefined, 'Edit Tags');
        tagOption.onclick   = () => {
            this.element.socketList.modify();
        }
        this.element.settingsList.push(tagOption);
    }

    convertArray(array, conversion) {
        if (array)
            for (var i = 0; i < array.length; ++i)
                if (array[i] !== null)
                    array[i] *= conversion;
    };

    requestDataForAllTags(start, end, interval) {
        for (let [deviceKey, tags] of this.element.deviceMap) {
            let starts = new Array(tags.length);
            starts.fill(start)
            let ends = new Array(tags.length);
            ends.fill(end);
            let device = owner.ldc.devices.getByKey(deviceKey)
            owner.ldc.getGraphData(this.element.graphID, device.id, interval, starts, ends, tags, true);
            device.request = new HDRequest(start, end, interval, tags);	// Add this request to our list of requested
        }
    };

    calculateInterval(start, end) {
        //assert(start < end, "Bad interval passed in!");
        let intervals = GenericGraph.intervals;

        var seconds = (end - start);								// Number of seconds we are currently viewing
        for (let index = 0; index < intervals.length; index++) {
            let points = seconds / intervals[index];					// Number of data points displayed should we choose this interval
            if (points < this.element.reps * 500)											// Select the first interval that will load up fewer than x000 data points
                return intervals[index];
        }
        return intervals[intervals.length -1];
    };

    resizeCallback() {
        this.rebuild()
    }

    rebuild() {
        let tag;
        let tags = this.element.socketList['sockets'].get('Trend Tag').tags
        if (tags.length > 0 && !tags[0].fBadStatus) { // if we have a trend tag
            tag                 = this.resolveTag(tags[0]);
            if (!tag)
                return;
            this.element.period = tags[0].period;
            this.element.reps   = tags[0].reps;
        }
        else return;
        this.element.loader     = new Loader(this.element);

        let end                 = new Date();				// Current time
        this.element.utcOffset  = end.getTimezoneOffset();  // get our timezone offset in case we want our ranges to fall on midnight
        this.element.endTime    = end.getTime() / 1000;
        this.element.ranges    = [];
        let days;
        switch (this.element.period) {
            case '1 Day':
                days = 1;
                break;
            case '1 Week':
                days = 7;
                break;
            case '30 Days':
                days = 30;
                break;
            case '1 Year':
                days = 365;
                break;
            //case '1 Week':
                //start = new Date(new Date().setDate(end.getDate() - (7 * this.element.reps)));
        }
        this.element.startTime = Math.floor(new Date().setDate(end.getDate() - (days * this.element.reps)) / 1000);
        for (let i=0;i<=this.element.reps;i++) {
            let range = new Date(new Date().setDate(end.getDate() - (days * (this.element.reps - i))));
            range.setHours(0,0,0,0)
            this.element.ranges.push(range.getTime())
        }


        //this.element.startTime	= this.element.endTime - (this.element.period * this.element.reps * 1000);
        this.element.interval   = this.calculateInterval(this.element.startTime, this.element.endTime);
        this.element.startTime  = Math.floor(this.element.startTime / this.element.interval) * this.element.interval;
        this.element.endTime    = Math.floor(this.element.endTime / this.element.interval) * this.element.interval;
        this.element.device     = tag.tree.device;
        let graphNode           = new GraphNode(tag, undefined, tag.getDisplayName(), undefined, undefined)
        this.element.device.request = new HDRequest(this.element.startTime, this.element.endTime, this.element.interval, [graphNode]);	// Add this request to our list of requested
        owner.ldc.getGraphData(this.element.graphID, this.element.device.id, this.element.interval, [this.element.startTime], [this.element.endTime], [graphNode], true);
        this.element.loader.destroy();
        //this.requestDataForAllTags(startTime, this.endTime, this.interval);
    }
}
