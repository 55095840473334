import SVGWidget from "./svgwidget";


//options:
//  data-se-node:"<node path>" determines the node that will be subscribed to
//  ...
//  data-se-start_<attr name>:"attr value"  lerp state when node is 0%
//  data-se-end_<attr name>:"attr value"    lerp state when node is 100%
export default class LerpAttributes extends SVGWidget{
  constructor(nodeRoot,element){
    super(nodeRoot,element);

    this.realNumberRegex = /[-+]?[0-9]*\.?[0-9]+/g;

    this.loadSettings(element);
    this.setupNodes(['']);
  }

  loadSettings(element){
    this.minimum = SVGWidget.readNumber(element,'seMinimum',null);
    this.maximum = SVGWidget.readNumber(element,'seMaximum',null);

    this.starts = this.processStrings(
      SVGWidget.readAttributes(this.element,'seStart_')
    );
    this.ends = this.processStrings(
      SVGWidget.readAttributes(this.element,'seEnd_')
    );

    //every key in starts is also in ends
    for (let key in this.starts){
        if (this.ends[key] === undefined){
            this.postError("Mismatched starts and ends!");
            delete this.starts[key];
        }
    }

    //every key in ends is also in starts
    for (let key in this.ends){
        if (this.starts[key] === undefined){
            this.postError("Mismatched starts and ends!");
            delete this.ends[key];
        }
    }

    //the arrays are the same in both
    for (let key in this.starts){
        let start = this.starts[key];
        let end = this.ends[key];

        if (start.length !== end.length){
            this.postError("Mismatched transforms: "+start.join('')+" and "+end.join(''));
                delete this.starts[key];
                delete this.ends[key];
                break;
        }

        for (let i=0; i<start.length; i++){
            let startIsNum = start[i] instanceof Number;
            let endIsNum   = end[i] instanceof Number;

            if (startIsNum !== endIsNum){ // !== is boolean xor
                this.postError("Mismatched transforms: "+start+" and "+end);
                delete this.starts[key];
                delete this.ends[key];
                break;
            }
        }
    }

    //validation done.
    //by this point, we have deleted all offensive keys from
    //starts and ends, and reported them to the user.
  }

  //Separates the numbers from the characters in an object's values.
  processStrings(object){
    for (let key in object){
      let string = object[key];

      //Find all numbers in the string.
      let result;
      let results = [];
      while (result = this.realNumberRegex.exec(string)){
          //endex: the index after the last character
          result.endex = result.index + result[0].length;
          results.push(result);
      }

      //Parse all numbers in the string.
      let index = 0;
      let endex;
      let array = [];
      for (var i=0; i<results.length; i++){
          //capture string part
          // from the previous end to the next start
          endex = results[i].index;
          array.push(string.substring(index,endex));
          //capture number part
          array.push(Number.parseFloat(results[i][0]));
          //advance index past end of number part
          index = results[i].endex;
      }
      //get the last little bit
      array.push(string.substring(index,string.length));

      //write back the broken up string
      object[key] = array;
    }
    return object; //don't be fooled, it also got changed in place.
  }

  update(node){
    const minimum = this.minimum ? this.minimum : node.engMin;
    const maximum = this.maximum ? this.maximum : node.engMax;
    const value = node.getValue();
    
    if (value > maximum || value < minimum)   // Out of range, set bad quality?
      return;

    const x = (value-minimum)
                / (maximum-minimum);
                //maps range -> [0,1]
    // console.log("LERP: x: " + x + " node: " + node.getValue() + " min: " + minimum + " max: " + maximum);

    for (let name in this.starts){
      const start = this.starts[name];
      const end = this.ends[name];

      const composition = [];
      for (let i=0;i<start.length;i++){
          if (start[i].concat){
              composition.push(start[i]);
              continue;
          }

          //if it's not a string, it's a number that we should
          //attempt to lerp.

          const s = start[i];
          const e = end[i];
          composition.push(s + x*(e-s));
          
          // console.log("LERP: start: " + start[i] + " x: " + x + " end: " + end[i] + " composition:(s+x*(e-s)): " + (s+x*(e-s)));
      }

      const string = composition.join('');
      this.element.setAttribute(name,string);
    }
  }
}
