import { Device }                   from '../../device';
import { Recipe }                   from './gizmo';
import { LoggedFilter }             from '../../tagfilter';
import { createElement }            from '../../elements';
import assert                       from '../../debug';
import { GenericGraph }             from '../../graph';
import EditorPage                   from '../../pages/editorpage';
import { Node }                     from '../../node';
import { HistoricalDataGizmo }      from './historicaldatagizmos';
import Cruncher, { DataInterval, HistoricalData }                     from '../../cruncher';
import TagSocket                    from '../tagsocket';
import './analyticsgizmos.css';
import owner from '../../../owner';
import { RateMap, TagUnit, convert } from '../../widgets/lib/tagunits';

export class AnalyticsGizmo extends HistoricalDataGizmo {
    interval: DataInterval;       // what resolution do we want
    endTime: number;
    startTime: number;
    device: Device;
    start: Date;
    end: Date;
    data: number[][];
    trendWrapper: HTMLElement;
    dataWrapper: HTMLElement;
    valueElement: HTMLElement;
    public connectedCallback(): void {
        super.connectedCallback();
        this.dataWrapper =createElement('div', 'analytics-widget__wrapper', this);
        this.socket = new TagSocket(owner.ldc, 'Live Data Tags', this, [new LoggedFilter(true, false)], [], true);
        this.sockets.set('Live Data Tags', this.socket);
        this.socket.refreshTags();
        if (!this.recipe.settings['Period'])
            this.recipe.settings['Period'] = '86400';   // set default period to one day
        if (!this.recipe.settings['Periods'])
            this.recipe.settings['Periods'] = '7';      // set default to one period
        if (!this.recipe.settings['Trend'])
            this.recipe.settings['Trend'] = true;     // show a trend by default

        this.cruncher = new Cruncher();
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingToggle('Trend', 'Show Trend: ');
        this.createSettingSelectInput('Period', 'Period: ', ['Minute', 'Hour', 'Day', 'Week', '30 Days', 'Year'], ['60', '3600', '86400', '604800', '2592000', '31536000'], '86400');
        this.createSettingNumberInput('Periods', 'Number of Periods:', 1);
    }

    rebuild() {
        this.dataWrapper.removeChildren();

        if (this.socket.tags.size < 1) {
            return
        }
        this.trendWrapper   = createElement('div', 'analytics-widget__trend-wrapper', this.dataWrapper);
        this.valueElement = createElement('div', '', this.dataWrapper);

        let period      = this.recipe.settings['Period'] as number;

        let intervals   = GenericGraph.intervals as DataInterval[];
        for (let i = intervals.length - 1; i>=0;--i) {
            if (period % intervals[i] == 0) {
                this.interval = intervals[i];
                break;
            }
        }
        this.end        = new Date();
        this.start      = new Date((this.end.getTime() / 1000 - parseInt(this.recipe.settings['Periods']) * (this.recipe.settings['Trend'] ? 2 * period : period)) * 1000);

        if (this.fNeedData)
            this.requestData(this.start, this.end, this.interval, this.socket.tags);
        else
            this.calculate(this.data);
    }

    onDataResponse(data: HistoricalData) {	// We got historical data back
        this.fNeedData  = false;
        let period      = this.recipe.settings['Period'] as number;
        let periods     = this.recipe.settings['Periods'] as number;
        let parsedData: number[][] = [[],[],[],[]];
        for (let i = 0; i < periods; ++i) {
            let index       = 0; // keep track of this for speediness
            let startTime = this.start.getTime() + i * period * 1000;
            for (let time = startTime; time <= startTime + period * 1000; time += this.interval * 1000) {	// Go through the entire interval they requested at the interval they requested
                let min: number | null     = this.getValue(time, index, data, null, 2);
                let average: number | null = this.getValue(time, index, data, null, 3);
                let max: number | null     = this.getValue(time, index, data, null, 4);
                if (average == null)
                    continue; //TODO: add null range for bad data
                parsedData[0].push(time);
                parsedData[1].push(min!);
                parsedData[2].push(average);
                parsedData[3].push(max!);
            }
        }
        this.hideWarning();
        this.data       = parsedData;
        this.calculate(parsedData);
    }

    createTrend(value: number) {
        this.trendWrapper.removeChildren();
        let icon            = createElement('div', 'analytics-widget__trend-wrapper__icon', this.trendWrapper, '▲');
        let text            = createElement('div', 'analytics-widget__trend-wrapper__text', this.trendWrapper, value.toFixed(2) + '%');
        this.trendWrapper.style.color = value > 0 ? 'green' : 'red'
        icon.style.transform = value > 0 ? 'rotate(180deg)' : '';
    }

    calculate(data: number[][]) {
        assert(false, 'Analytics Gizmos must define a calculate method');
    }

    accumulate(value: number) {
        let [tag] = this.socket.tags            // destructuring assignment to get first tag in our set
        assert(tag, 'Must have a tag here');
        let rate = RateMap.get(tag.units);
        assert(rate);
        return value / convert(1, rate!.time, TagUnit.TU_SECONDS) * this.recipe.settings['Period'];
    }
}

export class MinGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[1].slice(0, this.recipe.settings['Periods']);
            let previousValue   = Math.min(...previousPeriod);
            let currentPeriod   = data[1].slice(-this.recipe.settings['Periods']);
            value               = Math.min(...currentPeriod);
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);
        }
        else {
            value = Math.min(...data[1]);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}

export class MaxGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    };

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[1].slice(0, this.recipe.settings['Periods']);
            let previousValue   = Math.max(...previousPeriod);
            let currentPeriod   = data[1].slice(-this.recipe.settings['Periods']);
            value               = Math.max(...currentPeriod);
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);
        }
        else {
            value = Math.max(...data[4]);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}

export class AverageGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    };

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[1].slice(0, this.recipe.settings['Periods']);
            let previousValue   = Math.max(...previousPeriod);
            let currentPeriod   = data[1].slice(-this.recipe.settings['Periods']);
            value               = Math.max(...currentPeriod);
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);

        }
        else {
            value = Math.max(...data[1]);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}

export class CumulativeMinGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[2].slice(0, this.recipe.settings['Periods']); // get all the previous period's averages
            let previousValue   = this.accumulate(Math.min(...previousPeriod));          // get the minimum average and accumulate it
            let currentPeriod   = data[2].slice(-this.recipe.settings['Periods']);
            value               = this.accumulate(Math.min(...currentPeriod));
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);
        }
        else {
            value = Math.min(...data[1]);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}

export class CumulativeMaxGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[2].slice(0, this.recipe.settings['Periods']); // get all the previous period's maxes
            let previousValue   = this.accumulate(Math.max(...previousPeriod));          // get the maximum max and accumulate it
            let currentPeriod   = data[2].slice(-this.recipe.settings['Periods']);
            value               = this.accumulate(Math.max(...currentPeriod));
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);
        }
        else {
            value = Math.min(...data[1]);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}

export class CumulativeAverageGizmo extends AnalyticsGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }

    calculate(data: number[][]) {
        let value: number;
        if (this.recipe.settings['Trend']) {    // user wants to see how this value is trending
            let previousPeriod  = data[2].slice(0, this.recipe.settings['Periods']); // get all the previous period's averages
            let previousValue   = this.accumulate(previousPeriod.reduce((a,b)=>a+b,0) / previousPeriod.length);          // get the minimum average and accumulate it
            let currentPeriod   = data[2].slice(-this.recipe.settings['Periods']);
            value               = this.accumulate(currentPeriod.reduce((a,b)=>a+b,0) / currentPeriod.length);
            let change          = (value - previousValue) / previousValue;
            this.createTrend(change);
        }
        else {
            value = this.accumulate(data[2].reduce((a,b)=>a+b,0) / data[2].length);
        }
        this.valueElement.textContent = value.toFixed(0);
    }
}