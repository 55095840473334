import {createElement} from './elements';
import {Widget} from './widget';
import {Node, NodeQuality, VType} from './node';
import owner from '../owner';
import './radiobuttons.css';
import assert from './debug';

// HTML5 radiobutton inputs must have unique identical 'name' attributes within a set,
// and input and label must have unique matching id/for attributes across all sets of input/labels.

export class RadioButtons extends Widget {
	constructor(element, properties) {
		super();
		// 'element' is required:
		assert (element, 'element is a required property');
		assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
		this.element = element;
		this.element.radioButtons = this;		// Need a pointer back to this object

		this.registerAsWidget(element);			// register this element as a widget

		// 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
		// First step: Establish default properties:
		this.redXMask		= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
		this.list 			= 'Hand, Off, Auto';	// Default list is hand, off, auto

		// Second step: copy properties over to the bar graph object:
		this.copy(properties);

		var labels = this.radioSettings ?? this.list.split(',');	// returns an array of labels

		// Store array of buttons in 'this':
		this._buttons = [];
		this._labels = [];

		// Set up a button for each label:
		for (var i = 0; i < labels.length; ++i) {
			var input = createElement('input', 'radio-buttons__input', element);

			input.setAttribute('type',	'radio');
			// Each input element in the set of radiobuttons must have the same 'name':
			input.setAttribute('name',	'_rbs' + radioSetCounter);
			// Each input/label must have the same unique 'id'/'for' attribute:
			input.setAttribute('id',	'_rb' + radioButtonCounter);
			input._se_buttonIndex = this.radioSettings ? this.radioSettings[i].value : i;
			var label = createElement('label', 'radioButtonLabel', element, this.radioSettings ? this.radioSettings[i].name : labels[i].trim());	// trim leading and trailing whitespace

			// Each input/label must have the same unique 'id'/'for' attribute:
			label.setAttribute('for', 	'_rb' + radioButtonCounter);

			if (this.node.couldBeWritten()) { // Not a read-only node and they have the permission to write it one day:
				label.classList.add('writeable');	// Add class for formatting, updating disabled flag based on user write permission changes

				input.onclick = function() { //FIXME: This is pretty hacky, lets keep this in the radio button scope if we can
					var parent	= this.parentNode;	 			// The parent DOM element
					var node	= parent.radioButtons.node;		// This is the LiveData node attached to this radio button set

					assert(this._se_buttonIndex !== undefined);
					if (owner.settingsManager) {
						owner.settingsManager.pushPending(node, this._se_buttonIndex + (node.vtype == VType.VT_BOOL || parent.radioButtons.radioSettings ? 0 : 1), this);
					}
					else if(node.hasWritePermission())
						node.setValue(this._se_buttonIndex + (node.vtype == VType.VT_BOOL || parent.radioButtons.radioSettings ? 0 : 1));
					else
						parent.radioButtons.update(node);
				};
			} else	// node is not writeable, so do not set the 'writeable' class, and disable the button:
				input.disabled = true;

			this._buttons.push(input);		// Store button in top element array
			this._labels.push(label);		// Store the label so its width can be queried, if necessary

			++radioButtonCounter;
		}
		++radioSetCounter;

		assert (this.node, 'node is a required property for RadioButtons');
		this.node.subscribe(this, this.element, this.redXMask);
	};

	// Get the width of one of the buttons (more correctly, of its label)
	getButtonWidth(index) {
		assert(index < this._labels.length);
		// console.log("radioButtons getWidth(), label: " + this._labels[index] + " index: " + index + " width: " + this._labels[index].getWidth());
		return this._labels[index].getWidth();
	};

	// Sets the width of one of the buttons (more correctly, of its label)
	setButtonWidth(index, width) {
		assert(index < this._labels.length);
		// console.log("radioButtons setWidth(), index: " + index + " width: " + width);
		this._labels[index].setWidth(width);
	};

	// Node value/quality just changed, so update the persona widget's element(s) to reflect the change:
	update(node) {		// 'this' is the DOM element with 'persona' attribute:
		assert(node == this.node, 'Only one node should call RadioButtons.update');

		if (node.quality == NodeQuality.NQ_GOOD) {
			if (this.radioSettings) {
				for (let i=0;i<this.radioSettings.length;++i) {
					if (this.radioSettings[i].value == node.getValue(this.fPending)) {
						this._buttons[i].checked = true;
						break;
					}
				}
			}
			else {
				var button = node.vtype == VType.VT_BOOL ? (node.getValue(this.fPending) ? 2 : 1) : Math.floor(node.getValue(this.fPending));

				// Button value is '1' base (not zero-based), so ensure it falls within the limits:
				if (button < 1)
					button = 1;
				else if (button > this._buttons.length)
					button = this._buttons.length;

				for (var i = 0; i < this._buttons.length; ++i) // Expressly set which button we want checked
					this._buttons[i].checked = (i == (button - 1));
			}

		} else {
			// FIXME: Deal with bad quality somehow
		}
	};

	destroy = function() {
		this.node.unsubscribe(this);

		this.element.removeChildren();

		this.unregisterAsWidget();
	};
};

export var radioSetCounter 		= 0;
export var radioButtonCounter 	= 0;
