import {createElement} from '../elements';
import View from './view';

export default class DefaultView extends View {
    initialize(parent) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'default-view__wrapper', this.parent)
        var dataBank = createElement('div', null, this.wrapper, 'test tab');
        this.fInitialized = true;
        return this;
    }

    destroy() {
        this.parent.destroyWidgets(true);	// First, detach all LiveData nodes from all DOM elements
		this.parent.removeChildren();	// Second, delete all child DOM elements
    }
};
