import View from "./view";
import { createElement } from "../elements";
import './carouselview.css';

export default class CarouselView extends View {
    constructor(properties) {
        super();
        this.pages          = [];
        this.indicators     = [];
        this.currentIndex   = 0;

        this.copy(properties);
    }
    initialize(parent) {
        super.initialize(parent);
        this.wrapper        = createElement('div', 'carousel-view__wrapper', this.parent);
        this.title          = createElement('div', 'carousel-view__title', this.wrapper, this.title? this.title : '');
        this.content        = createElement('div', 'carousel-view__content', this.wrapper);
        this.pageContainer  = createElement('div', 'carousel-view__pages', this.content);

        let newWrapper      = createElement('div', 'tag-viewer__list-container', this.pageContainer);

        let buttonRow       = createElement('div', 'carousel-view__button-row', this.wrapper)

        this.backButton     = createElement('div', 'carousel-view__button', buttonRow, 'Back')
        this.backButton.onclick = () => this.back();

        this.nextButton     = createElement('div', 'carousel-view__button', buttonRow, 'Next')
        this.nextButton.onclick = () => this.advance();

        let indexRow        = createElement('div', 'carousel-view__indicator-row', this.wrapper);
        for (let i=0; i<this.pages.length; i++) {
            let indicator = createElement('div', 'carousel-view__indicator', indexRow);
            this.indicators.push(indicator);
        }
        newWrapper.appendChild(this.pages[0]());
        this.indicators[0].classList.add('carousel-view__selected');

    }
    advance() {
        if (this.currentIndex == this.pages.length -1)
            return

        if (this.fSliding) return   // check if we are already animating
        let oldWrapper = this.pageContainer.children[0];
        let childArray = Array.from(oldWrapper.children);                   // array of the items from the old wrapper
        for(let i=0;i<childArray.length; i++) {                             // loop through items and set their width as a static pixel value
            childArray[i].style.width = childArray[i].clientWidth + 'px'    // this prevents them from being "smushed"
        }
        let newWrapper = createElement('div', 'tag-viewer__list-container', this.pageContainer);
        this.currentIndex++

        newWrapper.appendChild(this.pages[this.currentIndex]());

        this.fSliding = true;
        // when the next css transition finishes (which should be the one we just triggered)
        oldWrapper.addEventListener('transitionend', this.finishedTransition.bind(this, oldWrapper, oldWrapper, true), { once: true });

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(() => {
            oldWrapper.style.width = oldWrapper.clientWidth + 'px';
            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(() => {
                oldWrapper.style.width = '0px';
            });
        });
        for (let i=0;i<this.indicators.length;i++) {
            this.indicators[i].classList.remove('carousel-view__selected');
        }
        this.indicators[this.currentIndex].classList.add('carousel-view__selected');
    }

    back(folder) {
        if (this.fSliding) return // check if we're already animating
        if (this.currentIndex == 0)
            return
        var oldWrapper  = this.pageContainer.childNodes[0];
        var width       = oldWrapper.clientWidth;
        var newWrapper  = createElement('div', 'tag-viewer__list-container', undefined);

        this.currentIndex--;

        newWrapper.appendChild(this.pages[this.currentIndex]());

        Array.from(newWrapper.children).forEach(child => {
            child.style.width = oldWrapper.childNodes[0].clientWidth + 'px'
        })
        newWrapper.style.width = '0px';
        this.pageContainer.prepend(newWrapper)
        this.fSliding = true;

        // when the next css transition finishes (which should be the one we just triggered)
        newWrapper.addEventListener('transitionend', this.finishedTransition.bind(this, newWrapper, oldWrapper, false), { once: true });

        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(() => {
            newWrapper.style.width = width + 'px';
        });
        for (let i=0;i<this.indicators.length;i++) {
            this.indicators[i].classList.remove('carousel-view__selected');
        }
        this.indicators[this.currentIndex].classList.add('carousel-view__selected');

    }

    finishedTransition(transitionElement, oldElement, fAdvance) {
        transitionElement.removeEventListener('transitionend', this.finishedTransition);
        this.fSliding = false;
        oldElement.destroyWidgets(true);
        oldElement.removeChildren();
        this.pageContainer.removeChild(oldElement);
        this.pageContainer.childNodes[0].style.width = '100%';
    }
}