import SVGWidget from './svgwidget';
import * as elements from '../elements';
import BarGraph from '../bargraph';
import createSVGElement from '../svgelements';
//The BarGraphBox lens will fill an SVG rectangle with a bar graph.
//
//REQUIRED ATTRIBUTES:
//	data-se-node:"<node path>"
//      ^Assign a node.
//
//
//NUMERIC INTERPRETATION ATTRIBUTES:
//	data-se-minimum:<number>    |   default: node's engMin
//      ^Set graph minimum to something other than the node's default.
//
//	data-se-maximum:<number>    |   default: node's engMax
//      ^Set graph maximum to something other than the node's default.
//
//	data-se-resolution:<number> |   default: node's resolution
//      ^Set graph resolution to something other than the node default.
//
//
//GRAPH APPEARANCE ATTRIBUTES:
//  data-se-vertical: <t or f>  |   default: true (vertical)
//      ^Determine if the graph is drawn vertically or horizontally.
//
//  data-se-scale-position:<top, bottom, left or right>
//      ^If set, draw a scale on the graph's given side.
//
// data-se-min-max-position:<top, bottom, left or right>
//      ^If set, draw min and max markers on the graph's given side.

export default class BarGraphBox {
	constructor() {}

	static attach(nodeRoot,element){
		var node = SVGWidget.readAndResolveNodePath(element,nodeRoot,'seNode');

        if (!node){
            //error reporting:
            //Because we are only a wrapper widget,
            //we only need to spin up and actual widget instance
            //when we need to use its non-static error reporting
            //methods.

            let widget = new SVGWidget(nodeRoot,element);
            widget.informNodeNotFound(
                '', //nodeID (BarGraphBox has no postfix support)
                SVGWidget.readAttribute(element,'seNode',''));//path
            return;
        }

		///////
		//Load graph settings

		var bbox = element.getBBox();
		var group = createSVGElement('g', null, element.parentElement, {transform:'translate('+bbox.x+','+bbox.y+')'});

		//attributes with node defaults
		var minimum = SVGWidget.readNumber(element,'seMinimum',node.engMin);
		var maximum = SVGWidget.readNumber(element,'seMaximum',node.engMax);
		var resolution = SVGWidget.readNumber(element,'seResolution',node.resolution);

		//Things you can set, but may be left undefined
		var scalePosition = SVGWidget.readAttribute(element,'seScalePosition',false);
		var minMaxPosition = SVGWidget.readAttribute(element,'seMinMaxPosition',false);

		var fVertical = SVGWidget.readFlag(element,'seVertical','t');

		var graph = new BarGraph(group,
			{
				node:node,
				width:bbox.width,
				height:bbox.height,

				scalePosition:scalePosition,
				minMaxPosition:minMaxPosition,

				minimum:minimum,
				maximum:maximum,
				resolution:resolution,
        barFillElement:element, //The element that defines the
                                //bargraph will be used for its
                                //fill.
        fVertical:fVertical,

				forceFit:true //demand that the graph be scaled to fit
			});
		graph.initialize();
	}
}
