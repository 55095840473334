import Page             from "./page";
import SettingsIcon     from '../images/icons/build.svg';
import ChartIcon        from '../images/icons/chart.svg';
import ListIcon         from '../images/icons/list.svg';
import TankIcon         from '../images/icons/tanklevel.svg';
import CalcIcon         from '../images/icons/calculate.svg';
import LinkIcon         from '../images/icons/link.svg';
import SaveIcon         from '../images/icons/save.svg';
import UndoIcon         from '../images/icons/undo.svg';
import RedoIcon         from '../images/icons/redo.svg';
import PrivateIcon      from '../images/icons/private.svg';
import PublicIcon       from '../images/icons/public.svg';
import AddIcon          from '../images/icons/add.svg';
import GaugeIcon        from '../images/icons/gauge.svg';
import DeleteIcon       from '../images/icons/delete.svg';
import HistoryIcon      from '../images/icons/history.svg';
import PieChartIcon     from '../images/icons/pie_chart.svg';
import AspectIcon       from '../images/icons/aspect.svg';
import NonAspectIcon    from '../images/icons/nonaspect.svg';
import EditIcon         from '../images/icons/edit.svg';
import CancelIcon       from '../images/icons/cancel.svg';
import owner, { Routes }            from "../../owner";
import {createElement}  from "../elements";
import ViewModal        from "../viewmodal";
import View             from "../views/view";
import assert           from "../debug";
import WidgetCard       from "../dashboard/widgetcard";
import ChartCard        from "../dashboard/chartcard";
import TankCard         from "../dashboard/tankcard";
import CalcCard         from "../dashboard/calccard";
import FeedCard         from "../dashboard/feedcard";
import LinkCard         from "../dashboard/linkcard";
import HistoricalCard   from "../dashboard/historicalcard";
import PumpCard         from "../dashboard/pumpcard";
import DeviceStatusCard from "../dashboard/devicestatus";
import CumulativeCard   from "../dashboard/cumulativecard";
import GaugeCard        from "../dashboard/gaugecard";
import Card             from "../dashboard/card";
import LiveData         from '../livedata';
import Dialog           from "../dialog";
import Muuri            from 'muuri';
import avatar           from "../components/avatar";
import './editorpageclassic.css';
import { getHash } from "../router/router";

export default class ClassicEditorPage extends Page {
    /**
     * @param  {HTMLElement}    parent
     * @param  {Object}         props
     */
    constructor(parent, props) {
        super(parent, props);
        this.parent     = parent;
        this.props      = { ...props }; // create copy of the properties parameter;
        this.wrapper    = createElement('div', 'editor-classic__wrapper', this.parent);     // wrapper element for all page elements
        this.nav        = createElement('div', 'editor-classic__nav', this.wrapper);   // top nav with top-level page controls
        this.nav.setAttribute('mode','view'); // by default, just looking not editing
        this.container  = createElement('div', 'editor-classic__container', this.wrapper);
        this.content    = createElement('div', 'editor-classic__content', this.container);    // dashboard content
        this.grid = new Muuri(this.content, {   // Muuri object to enable dragging and manage the DOM state
            dragEnabled: true,                  // enable dragging by default
            dragHandle: '.card__bar__button',   // make our cards only draggable when the top left corner is dragged
            visibleStyles: {
                opacity: 'none',                // always display our cards
            },
            hiddenStyles: {
                opacity: 'none',                // always display our cards
            },
        });
        this.grid.on('dragReleaseEnd', () => this.pushTrace()); // when we stop dragging a card, make sure we record the dashboard state
        this.ldc            = owner.ldc;
        this.graphID	    = this.ldc.registerGraph(this);	    // register for dashboard data
        this.trace          = [];                               // array for tracking changes to our dashboard's state
        this.traceIndex     = 0;                                // index for tracking where we are in our list of dashboard states

        let leftControls = createElement('div', 'editor-classic__controls', this.nav);              // container to hold the rest of the controls

        this.cancelButton = createElement('div', 'editor-classic__controls__button', leftControls); // button to stop editing this dashboard
        createElement('img', 'editor-classic__controls__button__icon', this.cancelButton, undefined, {'src':CancelIcon});
        this.cancelButton.onclick = () => this.nav.setAttribute('mode','view');

        this.deleteButton = createElement('div', 'editor-classic__controls__button', leftControls); // button to delete this dashboard
        createElement('img', 'editor-classic__controls__button__icon', this.deleteButton, undefined, {'src':DeleteIcon});
        let deleteOptions = {
            title: 'Delete Dashboard?',
            body: 'Are you sure you want to delete this dashboard? This action is permanent and cannot be reversed.',
            buttons: [{title:'Delete',callback:()=>this.deleteDashboard(),},{title:'Cancel',callback:()=>{}}]
        }
        this.deleteButton.onclick = () => new Dialog(document.body, deleteOptions)

        this.editButton = createElement('div', 'editor-classic__controls__button', this.nav); // button to delete this dashboard
        createElement('img', 'editor-classic__controls__button__icon', this.editButton, undefined, {'src':EditIcon});
        this.editButton.onclick = () => this.nav.setAttribute('mode','edit')

        let controls = createElement('div', 'editor-classic__controls', this.nav);              // container to hold the rest of the controls
        this.saveButton = createElement('div', 'editor-classic__controls__button', controls);   // button to save the dashboard in its current state
        this.saveButton.icon = createElement('img', 'editor-classic__controls__button__icon', this.saveButton, undefined, {'src':SaveIcon});
        this.saveButton.onclick = () => this.saveDashboard();

        this.privateButton = createElement('div', 'editor-classic__controls__button', controls); // button to toggle whether or not this dashboard is public
        this.privateButton.icon = createElement('img', 'editor-classic__controls__button__icon', this.privateButton, undefined, {'src':PrivateIcon});
        this.privateButton.onclick = () => {
            let pendingSharedUsers = new Map(this.dashboard.sharedUsers); // make a copy of our existing sharing settings
            let fPrivate = this.dashboard.fPrivate ? true : false;
            let fWrites  = this.dashboard.fWrites ? true : false;
            let companyKey = this.dashboard.companyKey + "";
            new ViewModal(new SharingSettingsView(this.dashboard.sharedUsers, this.dashboard), {
                title: 				    'Sharing Settings',
                titleTextColor:			'var(--color-inverseOnSurface)',
                titleBackgroundColor: 	'var(--color-primary)',
                maxWidth:               '400px',
                maxHeight:              '600px',
                buttons: [
                    {   // green button that creates a new dashboard when clicked
                        title:'Save',
                        callback:()=> {
                            this.saveDashboard();
                            return true;
                        },
                        borderColor: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                        title:'Cancel',
                        borderColor: owner.colors.hex('--color-error'),
                        callback:()=> {
                            this.dashboard.sharedUsers  = pendingSharedUsers;
                            this.dashboard.fPrivate     = fPrivate;
                            this.dashboard.fWrites      = fWrites;
                            this.dashboard.companyKey   = companyKey;
                            return true;
                        }
                    }
                ],
            });
            /*
            if(this.dashboard.creator == owner.ldc.user.username || this.dashboard.writeLevel <= owner.ldc.user.permissionLevel) {
                this.dashboard.fPrivate = !this.dashboard.fPrivate;
                if (this.dashboard.fPrivate)
                    this.privateButton.icon.src = PrivateIcon;
                else
                    this.privateButton.icon.src = PublicIcon;
            }
            */
        }

        this.aspectButton = createElement('div', 'editor-classic__controls__button', controls); // button to toggle whether or not this dashboard will resize with the window
        this.aspectButton.icon = createElement('img', 'editor-classic__controls__button__icon', this.aspectButton, undefined, {'src':AspectIcon});
        this.aspectButton.onclick = () => {
            if (!this.dashboard.width && !this.dashboard.height) {
                this.aspectButton.icon.setAttribute('src',NonAspectIcon)
                this.dashboard.width        = this.wrapper.offsetWidth;
                this.dashboard.height       = this.wrapper.offsetHeight;
                this.content.style.height = this.dashboard.height + 'px';
                this.content.style.width  = this.dashboard.width + 'px';
            }
            else {
                this.aspectButton.icon.setAttribute('src',AspectIcon)
                delete this.dashboard.width;
                delete this.dashboard.height;
                this.content.style.height = '100%';
                this.content.style.width = '100%';

            }
            this.refresh();
        }

        this.undoButton = createElement('div', 'editor-classic__controls__button editor-classic__controls__button__unclickable', controls);
        this.undoButton.icon = createElement('img', 'editor-classic__controls__button__icon', this.undoButton, undefined, {'src':UndoIcon});
        this.undoButton.onclick = () => this.undo();

        this.redoButton = createElement('div', 'editor-classic__controls__button editor-classic__controls__button__unclickable', controls);
        this.redoButton.icon = createElement('img', 'editor-classic__controls__button__icon', this.redoButton, undefined, {'src':RedoIcon});
        this.redoButton.onclick = () => this.redo();

        this.addButton = createElement('div', 'editor-classic__controls__button', controls); // button to add a new card to our dashboard
        createElement('img', 'editor-classic__controls__button__icon', this.addButton, undefined, {'src':AddIcon});
        createElement('div', 'editor-classic__controls__add__text', this.addButton, 'Add Widget')
        let selectorWidgets = [ // list of available widgets
            { name: 'Chart',        onSelect: () => this.createCard('chart'),       icon: ChartIcon,    caption: 'Custom time-series line chart with any tags' },
            { name: 'Tag',          onSelect: () => this.createCard('widget'),      icon: SettingsIcon, caption: 'Add a widget to control and display tag values' },
            { name: 'Feed',         onSelect: () => this.createCard('feed'),        icon: ListIcon,     caption: 'Add an event feed with real-time event updates' },
            { name: 'Tank',         onSelect: () => this.createCard('tank'),        icon: TankIcon,     caption: 'Add a tank widget with real-time level information' },
            { name: 'Calculations', onSelect: () => this.createCard('calculation'), icon: CalcIcon,     caption: 'Calculate using real-time tag values' },
            { name: 'Link',         onSelect: () => this.createCard('link'),        icon: LinkIcon,     caption: 'Link to another dashboard or page on Specific Energy' },
            { name: 'Daily Trend',  onSelect: () => this.createCard('historical'),  icon: HistoryIcon,  caption: 'Compare current daily trend data to previous days' },
            { name: 'Device Status',onSelect: () => this.createCard('devicestatus'),icon: PieChartIcon, caption: 'Show a pie chart with current device statuses' },
            { name: 'Gauge',        onSelect: () => this.createCard('gauge'),       icon: GaugeIcon,    caption: 'Display a gauge with custom color ranges' },

            //{ name: 'Cumulative',   onSelect: () => this.createCard('cumulative'), icon: PieChartIcon, caption: 'Show a pie chart with current device statuses' },

            //{ name: 'Pump',         onSelect: () => this.createCard('pump'),        icon: LinkIcon,     caption: 'Link to another dashboard or page on Specific Energy' },
        ]
        this.addButton.onclick = () => new ViewModal(new WidgetSelectorView(selectorWidgets), {
            title:                  'Select Widget',
            titleTextColor:			'var(--color-inverseOnSurface)',
			titleBackgroundColor: 	'var(--color-primary)',

        });

        if (this.props.id) { // if we are provided a dashboard id from our route, ask for it from wv
            this.ldc.fm.buildFrame(LiveData.WVC_GET_DASHBOARD, undefined, this.graphID);
            this.ldc.fm.push_u32(this.props.id);
            this.ldc.send();
        }
        else { // if we are not provided a dashboard id, create a new one
            let dialogProperties = {
                title: 'New Dashboard',
                body:  'Please enter a name for your new dashboard.',
                fText:  true,
                buttons: [
                    {   // green button that creates a new dashboard when clicked
                        title:'Create Dashboard',
                        callback:(name)=> this.createDashboard(name),
                        color: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                        title:'Cancel',
                        callback:() => window.history.back(),
                        color: owner.colors.hex('--color-error')
                    }
                ]
            }
            new Dialog(document.body, dialogProperties);
        }
    }

    refresh() {
        this.grid.refreshItems().layout();
        this.grid.synchronize();
    }

    ignoreProps(key, value) {
        let ignoreList = ['element', 'parent', 'editor', 'clone', 'fWrites', 'fPrivate', 'sharedUsers']
        for (let i = 0; i < ignoreList.length; i++) {
            if (key == ignoreList[i]) return undefined;
        }
        return value;
    }

    /**
     *
     * @param  {Card} card
     */
    removeCard(card) {
        let item = this.grid.getItem(card.element);
        this.grid.remove([item], {removeElements: true});
        this.pushTrace();

    }

    createCard(type, props) {
        let card = {}
        switch (type) {
            case 'widget':
                card = new WidgetCard(this.content, this, props);
                break;
            case 'chart':
                card = new ChartCard(this.content, this, props);
                break;
            case 'calculation':
                card = new CalcCard(this.content, this, props);
                break;
            case 'tank':
                card = new TankCard(this.content, this, props);
                break;
            case 'feed':
                card = new FeedCard(this.content, this, props);
                break;
            case 'link':
                card = new LinkCard(this.content, this, props);
                break;
            case 'historical':
                card = new HistoricalCard(this.content, this, props);
                break;
            case 'pump':
                card = new PumpCard(this.content, this, props);
                break;
            case 'devicestatus':
                card = new DeviceStatusCard(this.content, this, props);
                break;
            case 'cumulative':
                card = new CumulativeCard(this.content, this, props);
                break;
            case 'gauge':
                card = new GaugeCard(this.content, this, props);
                break;
            default: break;
        }
        this.grid.add(card.element)
        this.pushTrace();
    }

    buildFromSource(json) {
        let dashboard  = JSON.parse(json);
        this.dashboard = {
            id:         id,
            creator:    creator,
            name:       name,
            fPrivate:   fPrivate,
            readLevel:  readLevel,
            writeLevel: writeLevel,
            cards:      [],
            companyKey: "",
            devices:    []
        }
        this.generate(dashboard);
        this.pushTrace();
        if (this.dashboard.fPrivate)
            this.privateButton.icon.src = PrivateIcon;
        else
            this.privateButton.icon.src = PublicIcon;
    }

    onDashboardResponse(fp) {
        let success = fp.pop_u8() == 1;
        if (success) {
            let id                 = fp.pop_u32();
            let creator            = fp.pop_string();
            let name               = fp.pop_string();
            let version            = fp.pop_u16();
            let companyKey         = fp.pop_string();
            let fPrivate           = fp.pop_u8() == 1;
            let fWrites            = fp.pop_u8() == 1;
            let fReports           = fp.pop_u8() == 1;
            let reportFrequency    = fp.pop_u8();
            let reportFrequencyMod = fp.pop_u16();
            let reportHourOffset   = fp.pop_u8();
            let reportSize         = fp.pop_u8();
            let reportTimezone     = fp.pop_string();
            let dataSize           = fp.pop_u32()
            let data               = fp.pop_bytes(dataSize);
            let dashboard          = JSON.parse(data);
            let userCount          = fp.pop_u16();
                this.dashboard     = {
                id:         id,
                creator:    creator,
                name:       name,
                companyKey: companyKey,
                fPrivate:   fPrivate,
                cards:      [],
                sharedUsers:new Map(),
                devices:    []
            }
            for (let i=0;i<userCount;++i)
                this.dashboard.sharedUsers.set(fp.pop_string(), {
                    fAccess: fp.pop_u8(),
                    fWrites: fp.pop_u8(),
                    fReports: fp.pop_u8()
                });
            let assetCount  = fp.pop_u16();
            let deviceCount = fp.pop_u32();
            owner.navBar.setTitle(name);
            this.dashboard.fWrites = fWrites;
            this.generate(dashboard);
            this.pushTrace();
            if (this.dashboard.fPrivate)
                this.privateButton.icon.src = PrivateIcon;
            else
                this.privateButton.icon.src = PublicIcon;
        }
        else {
            let dialogProperties = {
                title:  'Failed to Load Dashboard',
                body:   'The dashboard you requested could not be loaded. Please check your url, and make sure you have permissions to access this dashboard.',
                buttons: [{title:'Return',callback:()=>{location.hash = getHash(Routes.Home)}}]
            }
            new Dialog(document.body, dialogProperties);
        }
    }

    onCreateDashboardResponse(fp) {
        let success = fp.pop_u8() == 1;
        if (success) {
            let id = fp.pop_u32();
            location.hash = getHash(Routes.Editor, {'id':id.toString()});
        }
        else {
            let dialogProperties = {
                title:  'Failed to Create Dashboard'
            }
            new Dialog(document.body, dialogProperties);
        }
        /*
        let cookie = document.cookie;
        cookie = cookie.split(', ');
        let result = {};
        for (let i = 0; i < cookie.length; i++) {
            let cur = cookie[i].split('=');
            result[cur[0]] = cur[1];
        }
        if (result['dashboard_1.3.0'] != 'true') {
            new Helper([
                {title:'Welcome to the Dashboard Editor', body: ()=>{return 'Our new Dashboard Editor allows you to create a custom interface. Click \'next\' below to see more.'}},
                {title:'Add Widget',            body:()=>{return 'Click the \'Add Widget\' button to add a new Widget to your Dashboard and continue the tutorial.'}, element:this.addButton, clickCallback: ()=>this.addButton.click()},
                {title:'Widgets',               body:()=>{return 'Each widget shows your data in a different way. We\'re expanding our list of Widgets constantly. If you want to see your data in a new way, we would love to hear about it.'}, selector:'#widget-wrapper'},
                {title:'Chart Widgets',         body:()=>{return 'Let\'s add our first Widget to our new Dashboard. The Chart Widget allows you to create a custom real-time chart with your data. Click the \'Chart\' option to continue.'}, selector:'.widget-selector__container', clickCallback: ()=>document.getElementsByClassName('widget-selector__container')[0].click()},
                {title:'Widget Settings',       body:()=>{return 'The Widget settings box lets you customize your Widget. Let\'s add a tag to our Widget and see some live data. Click the \'Add Tag\' button to continue.'}, selector:'.socket__add__button', clickCallback: ()=>document.getElementsByClassName('socket__add__button')[0].click()},
                {title:'Tag Finder',            body:()=>{return 'The Tag Finder helps you locate and select tags. You can navigate the Tag Finder like any modern file browser. Double click folders, or click the arrow next to the folder icon to go down a level in the tag tree. We\'ll open a device for you.'}, selector:'.modal__content'},
                {title:'Select Tags',           body:()=>{
                                                            let first = 0;
                                                            for (let i=0;i<document.getElementsByClassName('tag-viewer__item__large').length; i++) {
                                                                if (document.getElementsByClassName('tag-viewer__item__large')[i].style.display != 'none') {
                                                                    first = i;
                                                                    break;
                                                                }
                                                            }
                                                            owner.modal.currentView.deepDrop([document.getElementsByClassName('tag-viewer__item__large')[first]],true);
                                                            return 'You can add any logged tag to a Chart Widget. You can select a Tag by clicking it, or select multiple tags by holding Ctrl while clicking. Click \'Next\', and we\'ll select some tags for you.'
                                                        },selector:'.tag-viewer__top-wrapper'},
                {title:'Confirm Tag Selection', body:()=>{
                                                            let tagCount    = 0;
                                                            let item        = document.getElementsByClassName('tag-viewer__item__large')[0];
                                                            let tags        = [];
                                                            let found       =  false;
                                                            while (tags.length < 3) {
                                                                item = (item.nextElementSibling != null)? item.nextElementSibling : item.parentElement.nextElementSibling;
                                                                if (item.style.display == 'none')
                                                                    continue;
                                                                else if (item.classList.contains('tag-viewer__item-list'))
                                                                    item = item.firstChild;
                                                                else if (item.tag.flags & NodeFlags.NF_LOG || item.tag.flags & NodeFlags.NF_DERIVED)
                                                                    tags.push(item);
                                                            }
                                                            let sleep = (ms) => {
                                                                return new Promise(resolve => setTimeout(resolve, ms));
                                                            }
                                                            let selectItems = async () => {
                                                                for (let i=0;i<tags.length;i++) {
                                                                    let clickEvent = new MouseEvent('click', {ctrlKey:true})
                                                                    tags[i].dispatchEvent(clickEvent);
                                                                    await sleep(300)
                                                                }
                                                            }
                                                            selectItems();
                                                            return 'Click the \'Accept\' button to confirm the tag selection and create the Widget.'
                                                        }, selector:'.tag-viewer__button', clickCallback: ()=>document.getElementsByClassName('tag-viewer__button')[0].click()},
                {title:'Your First Widget!',    body:()=>{return 'You\'ve just created your first Widget on your new Dashboard. You can edit its title, resize, or delete it. Once you add more Widgets, you can also reorder them however you like. Click \'Next\' to continue.'}, selector:'.card__element'},
                {title:'Save',                  body:()=>{return 'Click the Save Button to save your Dashboard and add it to your list of Dashboards. Click \'Next\' to continue.'}, element: this.saveButton},
                {title:'Visibility',            body:()=>{return 'Currently, only you will be able to view this Dashboard. Clicking the Visibility button will change whether everyone else in your organization can view this Dashboard. Click \'Next\' to continue.'}, element: this.privateButton},
                {title:'Delete Dashboard',      body:()=>{return 'Click the Delete button to delete this dashboard and remove it from your list. Click \'Next\' to continue.'}, element: this.deleteButton},
                {title:'Congratulations!',      body:()=>{return 'You\'ve finished the Dashboard Creation Tutorial. You now know how to create and edit custom Dashboards. Click \'Finish\' to end the tutorial.'}},
            ],'dashboard_1.3.0', true).initialize();
        }
        */
        owner.refreshDashboards();
    }

    onModifyDashboardResponse(fp) {
        let success = fp.pop_u8() == 1;
        if (success)
            this.saveButton.icon.classList.add('editor-classic__controls__button__icon__primary');
        else
            new Dialog(document.body, {
                title: 'Error',
                body: 'An error occurred while trying to save the dashboard. Please wait a moment and try again.'
            })
        if (this.dashboard.fPrivate || this.dashboard.sharedUsers.size > 0)
            this.privateButton.icon.src = PrivateIcon;
        else
            this.privateButton.icon.src = PublicIcon;
    }

    onDeleteDashboardResponse(fp) {
        let success = fp.pop_u8() == 1;
        owner.refreshDashboards();
        this.content.removeChildren();
        new Dialog(document.body, {
            title: 'Dashboard Deleted',
            body: 'We\'ve removed ' + this.dashboard.name + ' from your dashboards. Would you like to create another?',
            buttons: [{title:'Yes',callback:()=>{new ClassicEditorPage(this.parent)}},{title:'No',callback:()=>location.hash = getHash(Routes.Home)}]
        });
    }

    createDashboard(name) {
        // default dashboard properties
        this.dashboard = {
            name:       name,
            creator:    owner.ldc.user.username,
            fPrivate:   true,
            fWrites:    false,
            cards:      [],
            sharedUsers:new Map(),
            companyKey: owner.ldc.user.companyKey,
            devices:    []
        };
        this.refresh();
        this.pushTrace();
        let data = JSON.stringify(this.dashboard, this.ignoreProps);
        this.ldc.fm.buildFrame(LiveData.WVC_CREATE_DASHBOARD, undefined, this.graphID);
        this.ldc.fm.push_string(this.dashboard.name);
        this.ldc.fm.push_u8(this.dashboard.fPrivate? 1 : 0);
        this.ldc.fm.push_u8(this.dashboard.fWrites? 1 : 0);
        this.ldc.fm.push_u16(1);
        this.ldc.fm.push_string(data);
        this.ldc.fm.push_u32(0); // TODO: this needs to be the device count //
        this.ldc.send();
    }

    saveDashboard() {
        this.grid.synchronize();
        let items = this.grid.getItems();
        this.dashboard.cards = [];
        for (let i=0;i<items.length;i++) {
            this.dashboard.cards.push(items[i]._element.card)
        }
		let data = JSON.stringify(this.dashboard, this.ignoreProps);
        owner.ldc.fm.buildFrame(LiveData.WVC_MODIFY_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_u32(this.dashboard.id);
		owner.ldc.fm.push_string(this.dashboard.name);
        owner.ldc.fm.push_u16(1);
        owner.ldc.fm.push_string(this.dashboard.companyKey ? this.dashboard.companyKey : "");
        owner.ldc.fm.push_u8(this.dashboard.fPrivate? 1 : 0);
        owner.ldc.fm.push_u8(this.dashboard.fWrites? 1 : 0);
        owner.ldc.fm.push_u8(0);
        owner.ldc.fm.push_u8(0);
        owner.ldc.fm.push_u16(0);
        owner.ldc.fm.push_u8(0);
        owner.ldc.fm.push_u8(0);
        owner.ldc.fm.push_string("America/Chicago");
        owner.ldc.fm.push_u32(data.length);
        owner.ldc.fm.push_bytes(data, data.length);
        owner.ldc.fm.push_u16(this.dashboard.sharedUsers.size)
        this.dashboard.sharedUsers.forEach((settings, username)=> {
            owner.ldc.fm.push_string(username);
            owner.ldc.fm.push_u8(settings.fAccess ? 1 : 0);
            owner.ldc.fm.push_u8(settings.fWrites ? 1 : 0);
            owner.ldc.fm.push_u8(0);
        })
        owner.ldc.fm.push_u16(this.dashboard.devices.length);
        for (let i=0;i<this.dashboard.devices.length;++i)
            owner.ldc.fm.push_string(this.dashboard.devices[i]);
		owner.ldc.fm.push_u16(0);
		owner.ldc.send();								// Send the message
    }

    deleteDashboard() {
        owner.ldc.fm.buildFrame(LiveData.WVC_DELETE_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_u32(this.dashboard.id);
        owner.ldc.send();								// Send the message
    }

    pushTrace() {
        this.grid.synchronize();
        let items = this.grid.getItems();
        this.dashboard.cards = [];
        for (let i=0;i<items.length;i++) {
            this.dashboard.cards.push(items[i]._element.card)
        }
        this.trace.length = this.traceIndex + 1;
        this.redoButton.classList.add('editor-classic__controls__button__unclickable');
        if (this.traceIndex > 0)
            this.undoButton.classList.remove('editor-classic__controls__button__unclickable');
        this.traceIndex++;
        this.saveButton.icon.classList.remove('editor-classic__controls__button__icon__primary');
        this.trace.push(JSON.parse(JSON.stringify(this.dashboard, this.ignoreProps)))
    }

    undo() {
        if (this.traceIndex == 1)
            return;
        this.traceIndex--;
        if (this.traceIndex == 1)
            this.undoButton.classList.add('editor-classic__controls__button__unclickable');
        this.saveButton.icon.classList.remove('editor-classic__controls__button__icon__primary');
        this.redoButton.classList.remove('editor-classic__controls__button__unclickable');
        this.dashboard.cards = [];
        this.content.removeChildren();
        this.generate(this.trace[this.traceIndex]);
    }

    redo() {
        if (this.traceIndex == this.trace.length - 1)
            return;
        this.traceIndex++
        if (this.traceIndex == this.trace.length - 1)
            this.redoButton.classList.add('editor-classic__controls__button__unclickable');
        this.saveButton.icon.classList.remove('editor-classic__controls__button__icon__primary');
        this.undoButton.classList.remove('editor-classic__controls__button__unclickable')
        this.dashboard.cards = [];
        this.content.removeChildren();
        this.generate(this.trace[this.traceIndex]);
    }

    generate(dashboard) {
        console.log(this.dashboard)
        this.grid.destroy();
        this.grid = new Muuri(this.content, {
            dragEnabled: true,
            dragHandle: '.card__bar__button',
            visibleStyles: {
                opacity: 'none',
            },
            hiddenStyles: {
                opacity: 'none',
            }
        });
        if (dashboard.height && dashboard.width) {
            this.aspectButton.icon.setAttribute('src',NonAspectIcon);
            this.dashboard.height       = dashboard.height;
            this.dashboard.width        = dashboard.width;
            this.content.style.height   = dashboard.height + 'px';
            this.content.style.width    = dashboard.width + 'px';
        }
        else {
            this.aspectButton.icon.setAttribute('src',AspectIcon);
            this.content.style.height   = '100%';
            this.content.style.width    = '100%';
        }
        if (this.dashboard.fWrites || (this.dashboard.sharedUsers.has(owner.ldc.user.username) && this.dashboard.sharedUsers.get(owner.ldc.user.username).fWrites) || owner.ldc.user.fWizard || owner.ldc.user.username == this.dashboard.creator) {
            this.nav.setAttribute('mode','view');
        }
        else this.nav.setAttribute('mode','disabled')
        this.grid.on('dragReleaseEnd', () => this.pushTrace());
        for (let i = 0; i < dashboard.cards.length; i++) {
            let card = dashboard.cards[i];
            let generatedCard = undefined;
            card.editor = this;
            switch (card.type) {
                case 'widget':
                    generatedCard = new WidgetCard(this.content, this, card);
                    break;
                case 'chart':
                    generatedCard = new ChartCard(this.content, this, card);
                    break;
                case 'calculation':
                    generatedCard = new CalcCard(this.content, this, card);
                    break;
                case 'tank':
                    generatedCard = new TankCard(this.content, this, card);
                    break;
                case 'feed':
                    generatedCard = new FeedCard(this.content, this, card);
                    break;
                case 'link':
                    generatedCard = new LinkCard(this.content, this, card);
                    break;
                case 'historical':
                    generatedCard = new HistoricalCard(this.content, this, card);
                    break;
                case 'devicestatus':
                    generatedCard = new DeviceStatusCard(this.content, this, card);
                    break;
                case 'devicestatus':
                    generatedCard = new DeviceStatusCard(this.content, this, card);
                    break;
                case 'gauge':
                    generatedCard = new GaugeCard(this.content, this, card);
                    break;
                default: break;
            }
            this.grid.add(generatedCard.element);
        }
        this.refresh();
    };

    setProps(newProps) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'id': // if the dashboard id changes, create a new editor page
                        new ClassicEditorPage(this.parent, newProps);
                        return;
                    default:
                        break;
                }
            }
        }
    };

    destroy() {
        if (this.dashboard) {
            for (let i=0;i<this.dashboard.cards.length;i++) {
                let card = this.dashboard.cards[i];
                card.destroy();
            }
        }
        this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
        this.parent.removeChildren();		// Delete any DOM elements left over
    }
}

class WidgetSelectorView extends View {
    constructor(widgets) {
        super();
        this.widgets = widgets;
        assert(this.widgets, 'WidgetSelectorView must have a widgets property');
    }
    initialize(parent) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'widget-selector__wrapper', this.parent);
        this.wrapper.id = 'widget-wrapper'
        for (let i = 0; i < this.widgets.length; i++) {
            let widget = this.widgets[i];
            let widgetContainer = createElement('div', 'widget-selector__container', this.wrapper);
            let icon = createElement('img', 'widget-selector__icon', widgetContainer, null, { 'src': widget.icon });
            let name = createElement('div', 'widget-selector__name', widgetContainer, widget.name);
            let caption = createElement('div', 'widget-selector__caption', widgetContainer, widget.caption)
            widgetContainer.onclick = () => {
                widget.onSelect();
                this.modal.destroy();
            }
        }
    }
}

class SharingSettingsView extends View {
    constructor(sharedUsers, dashboard) {
        super();
        this.settings       = [];
        this.sharedUsers    = sharedUsers;
        this.id			    = owner.ldc.registerGraph(this);
        this.dashboard      = dashboard;
    }

    initialize(parent) {
        super.initialize(parent);
        this.wrapper    = createElement('div', 'sharing-view__wrapper', this.parent);
        if (owner.ldc.isPowerUser()) {			// If the user is a Specific Energy, Inc. employee
			this.companySelector = createElement('select', 'UserSelector', this.wrapper);		// Give the user a drop down selector to change companies
			this.companySelector.onchange = this.onSelectorChange.bind(this);				// When the drop down changes, get users for that company
			owner.ldc.getCompanies(this.id);												// Load available companies
		} else {																		    // Normal user
            owner.ldc.getUserList(this.id, owner.ldc.user.companyKey);
		}
        this.userContainer  = createElement('div', 'sharing-view__user-container', this.wrapper);
        this.fInitialized   = true;
    }

    onSelectorChange() {
        //this.accountTable.removeUserRows();								// Remove pre-existing rows
		var companyKey;
		if (this.companySelector)										// If we can see multiple companies
			companyKey = this.companySelector.selectedOptions[0].key;	// Refresh the company we are looking at
		else
			companyKey = owner.ldc.user.companyKey;					// Refresh the users associated with the owner's company
        owner.ldc.getUserList(this.id, companyKey);
    }

    onCompaniesReceived(companies) {		// Got the companies back
        assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
		for (var i = 0; i < companies.length; ++i)	// For each company we got
			createElement('option', null, this.companySelector, companies[i].name).key = companies[i].key;	// Put an option in the selector
		this.onSelectorChange();					// Load the first company by default
    }

    onGetUserListResponse(fp) {
        this.userContainer.removeChildren();
        let userCount = fp.pop_u16();
        this.users = [];
        for (let i=0;i<userCount;++i) {
            this.users.push({
                username    : fp.pop_string(),
                firstName   : fp.pop_string(),
                lastName    : fp.pop_string()
            })
        }
        let uRow         = createElement('div', 'sharing-view__user-row', this.userContainer);
        let uUser        = createElement('div', 'sharing-view__user', uRow);
        createElement('div', 'sharing-view__user__name', uUser, 'All Users');
        this.uSelector = createElement('select', '', uRow);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_NONE);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_EDIT);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_VIEW_ONLY);

        this.uSelector.value = (this.dashboard.fPrivate || (this.companySelector && this.companySelector.selectedOptions[0].key != this.dashboard.companyKey)) ? SharingSettingsView.SHARE_NONE : (this.dashboard.fWrites ? SharingSettingsView.SHARE_EDIT : SharingSettingsView.SHARE_VIEW_ONLY);
        this.uSelector.onchange = () => {
            let value = this.uSelector.options[this.uSelector.selectedIndex].value;
            switch (value) {
                case SharingSettingsView.SHARE_NONE:
                    this.dashboard.fPrivate   = true;
                    this.dashboard.fWrites    = false;
                    break;
                case SharingSettingsView.SHARE_EDIT:
                    this.dashboard.fPrivate   = false;
                    this.dashboard.fWrites    = true;
                    break;
                case SharingSettingsView.SHARE_VIEW_ONLY:
                    this.dashboard.fPrivate   = false;
                    this.dashboard.fWrites    = false;
                    break;
            }
            this.sharedUsers.clear();
            if (this.companySelector)
                this.dashboard.companyKey = this.companySelector.selectedOptions[0].key;
            this.settings.forEach(setting => setting.value = setting.value == SharingSettingsView.SHARE_CREATOR ? setting.value : value)
        }

        for (let i=0;i<this.users.length;++i) {
            if (this.users[i].username == owner.ldc.user.username)
                continue;
            let row = createElement('div', 'sharing-view__user-row', this.userContainer);
            let user = createElement('div', 'sharing-view__user', row);
            avatar(user, this.users[i].firstName, this.users[i].lastName);
            createElement('div', 'sharing-view__user__name', user, this.users[i].firstName + ' ' + this.users[i].lastName);
            let selector = createElement('select', '', row);
            createElement('option', '', selector, SharingSettingsView.SHARE_NONE);
            createElement('option', '', selector, SharingSettingsView.SHARE_EDIT);
            createElement('option', '', selector, SharingSettingsView.SHARE_VIEW_ONLY);
            let setting = this.sharedUsers.get(this.users[i].username);
            if (this.users[i].username == this.dashboard.creator) {
                createElement('option', '', selector, SharingSettingsView.SHARE_CREATOR);
                selector.value = SharingSettingsView.SHARE_CREATOR;
                selector.disabled = true;
            }
            else if (!setting)
                selector.value = this.uSelector.options[this.uSelector.selectedIndex].value;
            else if (setting.fAccess == 0)
                selector.value = SharingSettingsView.SHARE_NONE
            else if (setting.fWrites == 1)
                selector.value = SharingSettingsView.SHARE_EDIT
            else
                selector.value = SharingSettingsView.SHARE_VIEW_ONLY;
            selector.user = this.users[i];
            selector.onchange = () => this.onSettingChange();
            this.settings.push(selector);
        }
    }

    onSettingChange() {
        for (let i=0;i<this.settings.length;++i) {
            let s       = this.settings[i];
            let user    = s.user;
            let setting = s.options[s.selectedIndex].value;
            if (setting == this.uSelector.options[this.uSelector.selectedIndex].value)
                this.sharedUsers.delete(user.username);
            else if (setting != SharingSettingsView.SHARE_CREATOR) {
                let sharedSetting = {
                    fAccess: setting == SharingSettingsView.SHARE_NONE ? 0 : 1,
                    fWrites: setting == SharingSettingsView.SHARE_EDIT ? 1 : 0
                }
                this.sharedUsers.set(user.username, sharedSetting)
            }
        }
    }

    static SHARE_NONE       = 'None';
    static SHARE_VIEW_ONLY  = 'View Only';
    static SHARE_EDIT       = 'Edit';
    static SHARE_CREATOR    = 'Creator'
}