import { Attribute } from '../../../lib/attributes';
import { TagAttribute } from '../../../lib/tag';
import { RegisterWidget, Widget } from '../../../lib/widget';
import type { TagDefinition } from "../../../lib/tag";
import template from './pumpbank.html';
import { Role } from '../../../../role';
import { type PumpCard } from '../pumpcard/pumpcard';

@RegisterWidget({tag: 'pump-bank', displayName: 'Pump Bank', section: 'Pumps', template: template})
export class PumpBank extends Widget {
    cards: HTMLDivElement;
    @Attribute({displayName: 'Text Color', typeModifier: 'color'}) backgroundColor: string = 'var(--color-orange-8)';
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) textColor: string = 'var(--color-inverseOnSurface)';
    @TagAttribute({displayName: 'Tag'}) pumpFolder: TagDefinition;

    protected connectedCallback(): void {
        this.cards = this.shadowRoot?.getElementById('cards') as HTMLDivElement;
    }

    protected enliven(): void {
        for (let pump of this.pumpFolder.tag.findByRole(Role.ROLE_PUMP)) {
            let card = document.createElement('pump-card') as PumpCard;
            card.pumpFolder = {tag: pump};
            this.cards.appendChild(card);
        }
    }
}