import {createElement} from './elements';
import ArrowForwardIcon from './images/icons/arrow_forward.svg';
import { VType} from './node';
import owner from '../owner';
import './configform.css';
import { UnitsMap } from './widgets/lib/tagunits';


// This file defines FormElement tree branch objects:
export default class ConfigFormElement {
	constructor(parent, fp, parentDiv) {
		this.parent			= parent;			// Null if root
		this.id				= fp.pop_string();	// identifies form element value (all radio button sibs have same id)
		this.label			= fp.pop_string();	// form element label/title/prompt
		this.help			= fp.pop_string();	// help text
		this.fRestarts	= fp.pop_u8() > 0;	// True if changing the element restarts the site code
		this.popGuts(fp);					// read guts of subclasses (if any)
		if(this.parent !== null)
			this.draw(parentDiv);				// Render the interface for this element
		ConfigFormElement.ElementMap[this.id] = this;	// Put ourselves in the map

		this.children	= [];				// Build an array of children
		var kids = fp.pop_u16();			// number of children
		if(kids > 0){						// If there are kids, create the elements to make a sub-section
			if(this.parent !== null){
				this.childWrapper = createElement('div', 'config-form__section', parentDiv);
				createElement('div', 'FormSectionSpacer', this.childWrapper);
			}
			else this.childWrapper = createElement('div', 'config-form__child-wrapper', parentDiv);

			this.childDiv = createElement('div', 'config-form__child-section', this.childWrapper);
		}

		for(var i = 0; i < kids; ++i)		// For each child
            this.children.push(this.popChild(fp));	// Extract next child

	}

	popChild(fp) {				// Create child based on type
		switch(fp.pop_u16()) {	// Get Form element type
			case ConfigFormElement.Type.Title:		    return new FormTitle	    (this, fp, this.childDiv, false);
			case ConfigFormElement.Type.TextBox:	    return new FormTextBox      (this, fp, this.childDiv);
			case ConfigFormElement.Type.CheckBox:	    return new FormCheckBox     (this, fp, this.childDiv, false);
			case ConfigFormElement.Type.RadioButton:    return new FormRadioButton  (this, fp, this.childDiv);
			case ConfigFormElement.Type.SelectBox:	    return new FormSelectBox    (this, fp, this.childDiv);
			case ConfigFormElement.Type.NumericBox:	    return new FormNumericBox   (this, fp, this.childDiv);
			case ConfigFormElement.Type.Section:	    return new FormTitle	    (this, fp, this.childDiv, true);
			case ConfigFormElement.Type.TimeBox:	    return new FormTimeBox      (this, fp, this.childDiv);
			case ConfigFormElement.Type.CheckSection:   return new FormCheckBox     (this, fp, this.childDiv, true);
			case ConfigFormElement.Type.DeviceSelect:	return new FormDeviceSelectBox (this, fp, this.childDiv, false);
			default:assert(false, "Unknown type.");     return new FormTitle        (this, fp, this.childDiv);
		}
	}

	popValue(fp) {
		var value = {type: fp.pop_u8()};	// Extract data type
		switch (value.type) {				// Based on type, extract data
			case VType.VT_UNKNOWN:	break;
			case VType.VT_S64:		value.data = fp.pop_u64();		break;
			case VType.VT_STRING:	value.data = fp.pop_string();	break;
			case VType.VT_F64:		value.data = fp.pop_f64();		break;
			case VType.VT_BOOL:		value.data = fp.pop_u8();		break;
		}
		return value;
	}

	popGuts() {}		// Default is no additional guts, so do nothing
	createGuts() {}
	pushGuts() {assert(false, "Someone hasn't added a pusher.");}
	update() {}
	isChanged() {return false;}

	createInput(type) {		// Convenience method. This is used by a bunch of children
		this.input = createElement('input', 'config-form__spinner', this.inputWrapper);		// Create an input
		this.input.type = type;														// Give it the passed in type
		this.input.setAttribute('required', true);
		this.input.onchange = this.onInput.bind(this);
	}

	onInput(e) {
		this.input.classList.toggle('FormError', this.input.validationMessage.length > 0);

		if (this.fRestarts && this.isChanged())	// TODO: Only give this warning on a delay or on blur instead of on every change?
			alert("Warning: changing " + this.label + " will restart the program.");
	}

	draw(parentDiv) {	// Render
		this.wrapper = createElement('div', 'config-form__field-wrapper', parentDiv);			// Wrapper for this row
		var label = createElement('div', 'config-form__label', this.wrapper, this.label);	// Text label for row
		label.setAttribute("title",this.help);									// New Tooltip using tippy
        /*tippy(label,
        {
            position: 'right',
            animation: 'shift',
            duration: 60,
            arrow: true,
            theme: 'light',
            trigger: 'mouseenter',
        });*/
		this.inputWrapper = createElement('div', 'FormInputWrapper', this.wrapper);	// A wrapper for whatever we want (toggles, text, timers)
		this.createGuts();														// Create guts, if any
	}

	push(fm) {	// Add our attributes back into the frame
		if (this.id) 	// Title elements don't have ids and don't need to append an attribute
			this.pushGuts(fm);			// Append id, attributes
		for(var i = 0; i < this.children.length; ++i)	// For each child
			this.children[i].push(fm);	// Push id, attribute pairs
	}

	popAttributes(fp) {	// Get all the attributes from the server
		while(fp.bytesLeft > 0) {	// While there's still more
			var id = fp.pop_string();		// Get the element

			var element = ConfigFormElement.ElementMap[id];	// Get the element this is for
			element.value = this.popValue(fp);	// Pop the value

			element.error = fp.pop_string()					// Save the error string
			element.input.classList.toggle('FormError', element.error.length > 0);	// Update error message
			element.update(element.value);							// Update the element's value
		}
    }
};

ConfigFormElement.ElementMap = {};	// To hold all elements, ordered by string-based ID
ConfigFormElement.RadioMap = {};	// To hold arrays with the sets of radio buttons, which will be ordered by their string-based ID
ConfigFormElement.Type = {			// Type enum
	Title: 			0,
	TextBox:		1,
	CheckBox:		2,
	RadioButton:	3,
	SelectBox:		4,
	NumericBox:		5,
	Section:		6,
	TimeBox:		7,
	CheckSection:	8,
	DeviceSelect: 	9
};

class FormTitle extends ConfigFormElement {	// Title element subclass. Pretty much just extends FormElement
	constructor(parent, fp, parentDiv, fClickable) {
		super(parent, fp, parentDiv);	// Call parent class constructor
		if (fClickable && this.childDiv) {
			this.wrapper.onclick = this.onClick.bind(this);
			this.wrapper.classList.add('config-form__wrapper');
			this.arrow = createElement('img', 'FormMenuArrow', this.inputWrapper, undefined, {'src':ArrowForwardIcon});
			this.onClick();
		} else if(!fClickable)
			this.classList.add('FormMainTitle');
	}

	onClick() {	// Called when a title div is clicked
		//this.childWrapper.classList.toggle('HiddenFormSection');	// Only show the selected children
		this.arrow.classList.toggle('FormMenuArrowFlipped');
	}
};

class FormTextBox extends ConfigFormElement {	// Text box subclass
	popGuts(fp) {
		this.pattern = fp.pop_string();	// Regex filter the input must match
		console.log(this.pattern);
	}

	createGuts() {	// Have to create an input element for the text field
		this.createInput('text');			// Make a text box input
		this.input.pattern = this.pattern;	// Make it match our regular expression
	}

	pushGuts(fm) {
		fm.push_string(this.id);			// Append id
		fm.push_u8(VType.VT_STRING);		// Add a string type
		fm.push_string(this.input.value);	// Add the value
	}

	update(value) {
		this.input.value = value.data;		// Update raw input text
		this.cachedValue = value.data;
	}

	isChanged() {
		return this.input.value != this.cachedValue;
	}

	onInput(e) {
		//this.input.classList.toggle('FormError', this.input.validationMessage.length > 0);

		if (this.fRestarts && this.isChanged())	// TODO: Only give this warning on a delay or on blur instead of on every change?
			alert("Warning: changing " + this.label + " will restart the program.");
	}
};

class FormCheckBox extends ConfigFormElement {	// Check box subclass
	constructor(parent, fp, parentDiv, fClickable) {		// If fClickable is set, we should show or hide the child elements and update their disabled status
		super(parent, fp, parentDiv);				        // Call parent class constructor
		if (fClickable && this.childDiv) {					// If we're clickable and actually have child elements
			this.wrapper.onclick = this.onClick.bind(this);	// Bind the on click callback to ourselves
			this.wrapper.classList.add('FormClickable');	// Add the class to give it a pointer
			this.input.onclick = this.onChange.bind(this);	// Add an on change callback to the check box
			this.onClick();									// Sync state on first call
		}
	}

	onClick() {	// Called when a title div is clicked
		//this.childWrapper.classList.toggle('HiddenFormSection');	// Only show the selected children
	}

	onChange(e) {
		if (e)	// First call doesn't have an event in our constructor
			e.stopPropagation();	// Don't bubble up to the wrapper, which would show/hide children
		this.updateChildren(!this.input.checked, this);		// Update enabled status recursively
	}

	updateChildren(fDisabled, element) {
		for (var i = 0; i < element.children.length; ++i) {	// For each child
			var child = element.children[i];				// Get a convenience reference
			if (child.input)						// If it has an input
				child.input.disabled = fDisabled;	// Update the diabled status
			this.updateChildren(fDisabled, child);	// Check all its children
		}
	}

	createGuts() {	// Have to create an input element
//		this.createInput('checkbox');	// Make a check box input

		var switcher = createElement('label', 'switch FormValue', this.inputWrapper);		// Create an input
		this.input = createElement('input', null, switcher);
		createElement('div', 'slider', switcher);
		this.input.type = 'checkbox';												// Give it the passed in type
		this.input.setAttribute('required', true);
		this.input.onchange = this.onInput.bind(this);
	}

	pushGuts(fm) {
		fm.push_string(this.id);			// Append id
		fm.push_u8(VType.VT_BOOL);		// Add a string type
		fm.push_u8(this.input.checked);		// Add the checked status
	}

	update(value) {
		this.input.checked = value.data;	// Update checked status
		this.cachedValue = value.data;
		this.onChange();					// Make sure everything is enabled or disabled as appropriate
	}

	isChanged() {
		return this.input.checked != this.cachedValue;
	}
};

class FormRadioButton extends ConfigFormElement {	// Radio button subclass
	createGuts() {// Have to create an input element
		var buttons = FormElement.RadioMap[this.id];			// Check to see if this ID is already in the radio map
		if (buttons === undefined)								// No array of buttons yet?
			buttons = FormElement.RadioMap[this.id] = [];	// Create one

		this.createInput('radio');	// Make a radio button input
		this.input.name = this.id;	// Link it to all radio buttons with this ID
		this.input.checked = buttons.length == 0;		// Start of with this one defauled
		buttons.push(this);			// Add this guy to the array
	}

	pushGuts(fm) {
		var buttons = FormElement.RadioMap[this.id];	// Get all the radio buttons that have the same id
		if (buttons.indexOf(this) != 0)					// If we aren't the first radio button
			return;										// Don't append us more than once

		fm.push_string(this.id);						// Append id
		fm.push_u8(VType.VT_STRING);					// Add a string type
		for (var i = 0; i < buttons.length; ++i)		// For each button
			if (buttons[i].input.checked) {				// If the button is checked
				fm.push_string(buttons[i].label);		// Add its label
				return;									// All done
			}
	}

	update(value) {
		var buttons = FormElement.RadioMap[this.id];	// Get all the radio buttons that have the same id
		for (var i = 0; i < buttons.length; ++i)		// For each button
			buttons[i].input.checked = value.data == buttons[i].label;	// Set checked if it matches
		this.cachedValue = value.data;
	}

	isChanged() {
		var buttons = FormElement.RadioMap[this.id];	// Get all the radio buttons that have the same id
		for (var i = 0; i < buttons.length; ++i)		// For each button
			if (buttons[i].input.checked)				// If this is the checked input
				return this.cachedValue != buttons[i].label;	// Check if it has changed
	}
};

class FormSelectBox extends ConfigFormElement {	// Selection box subclass
	popGuts(fp) {	// Selection boxes get extra data in the frame
		this.options = [];	// To hold our list of options
		var count = fp.pop_u16();					// Option count
		for (var i = 0; i < count; ++i) 			// For each option
			this.options.push(this.popValue(fp));	// Save the option in our set
	}

	createGuts() {	// Have to create an selection element
		this.input = createElement('select', 'config-form__select', this.inputWrapper);				// We create a selector
		this.input.onchange = this.onInput.bind(this);
		for (var i = 0; i < this.options.length; ++i)									// And for each option
			createElement('option', null, this.input).text = this.options[i].data;	// Add an entry
	}

	pushGuts(fm) {
		var option = this.options[this.input.selectedIndex];
		fm.push_string(this.id);	// Append id
		fm.push_u8(option.type);	// Add the type
		switch (option.type) {		// Based on type, extract data
			case VType.VT_UNKNOWN:	return;
			case VType.VT_S64:		fm.push_u64(option.data);		return;
			case VType.VT_STRING:	fm.push_string(option.data);	return;
			case VType.VT_F64:		fm.push_f64(option.data);		return;
			case VType.VT_BOOL:		fm.push_u8(option.data);		return;
		}
	}

	update(value) {
		for (var i = 0; i < this.options.length; ++i) {	// Go through each option
			if (this.options[i].data == value.data) {	// Find the option that matches the value
				this.input.selectedIndex = i;			// Set the index for that guy
				break;									// No need to go any further
			}
		}
		this.cachedValue = value.data;
	}

	isChanged() {
		var option = this.options[this.input.selectedIndex];	// Get the selected option
		return this.cachedValue != option.data;			// See if its value matches the data
	}
};

class FormDeviceSelectBox extends ConfigFormElement {	// Selection box subclass
	createGuts() {	// Have to create an selection element
		this.input = createElement('select', 'FormValue', this.inputWrapper);				// We create a selector
		this.input.onchange = this.onInput.bind(this);
		owner.ldc.devices.array.forEach(device => {
			createElement('option', '', this.input, device.siteName, {'value':device.key});
		})
	}

	pushGuts(fm) {
		fm.push_string(this.id);	// Append id
		fm.push_u8(VType.VT_STRING);// Add a int type
		fm.push_string(this.input.options[this.input.selectedIndex].value);
	}

	update(value) {
		this.input.value = value.data;
		this.cachedValue = value.data;
	}

	isChanged() {
		let value = this.input.options[this.input.selectedIndex].value;	// Get the selected option
		return this.cachedValue != value;			// See if its value matches the data
	}
};
class FormNumericBox extends ConfigFormElement {	// Numeric box subclass
	popGuts(fp) {	// Selection boxes get extra data in the frame
		this.min	= fp.pop_f64();
		this.max	= fp.pop_f64();
		this.step	= fp.pop_f64();
		this.units	= fp.pop_u16();
	}

	createGuts() {	// Have to create an input element
		this.createInput('number');		// Make a numeric spinner input
		this.input.min = this.min;		// Save min and max
		this.input.max = this.max;
		this.input.step = this.step;	// Save step change
		createElement('label', "FormUnits", this.inputWrapper, UnitsMap.get(this.units).abbrev);
	}

	pushGuts(fm) {
		fm.push_string(this.id);				// Append id
		if (this.step >= 1) {
			fm.push_u8(VType.VT_S64);				// Add a int type
			fm.push_u64(this.input.valueAsNumber);	// Add the checked status
		} else {
			fm.push_u8(VType.VT_F64);				// Add a float type
			fm.push_f64(isNaN(this.input.valueAsNumber) ? 0 : this.input.valueAsNumber);	// Add the checked status
		}
	}

	update(value) {
		this.input.valueAsNumber = value.data;	// Update number value
		this.cachedValue = value.data;
	}

	isChanged() {
		return this.input.valueAsNumber != this.cachedValue;	// See if its value matches the cache
	}
};

class FormTimeBox extends ConfigFormElement {	// Time box subclass
	createGuts() {	// Have to create an input element
		this.createInput('time');	// Make a time box input
	}

	pushGuts(fm) {
		fm.push_string(this.id);							// Append id
		fm.push_u8(VType.VT_S64);							// Add a string type
		fm.push_u64(this.input.valueAsNumber / 1000);		// Add the checked status
	}

	update(value) {
		this.input.valueAsNumber = value.data*1000;	// Update checked status
	}
};
