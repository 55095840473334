import assert from './debug';
import createSVGElement from './svgelements';
import MouseCapture from './mousecapture';
import { Widget } from './widget';
import { NodeQuality, NodeFlags } from './node';

/**
 * Create an svg handle that the user can grab and slide vertically or horizontally along an underlying object
 * Used by bargraph.js, but general-purpose enough to be used elsewhere
 */

// Handle inherits Widget base class.

// Handle constructor function:
export default class Handle extends Widget{
	constructor(svg, node, fVertical) {
		super();
		assert (svg.tagName == 'svg', 'parent must be an SVG canvas element');
		assert (node && node.isWriteable, 'You must provide a writeable node to Handle (but you don\'t need write permission)');
		this.parent		= svg;			// parent svg canvas
		this.node		= node;			// node connected to handle
		this.fVertical	= (fVertical===undefined) ? true : fVertical;	// true if handle moves vertically, false if horizontally
	};

	/**
	 * Get overhang metrics (top, left, right, bottom)
	 * @param {object} overhang has read-only and modifiable properties
	 */
	getMetrics(overhang) {	// get left, top, right, and bottom overhang past underlying rectangle:
		this.metrics = {};

		// Handle should be no thicker than 60% of width:
		if (this.fVertical) {	// handle moves vertically:
			this.metrics.width	= overhang.width + overhang.strokeWidth * 2 + Handle.protrusion * 2;
			this.metrics.height	= Math.ceil(Math.min(Handle.thickness, 0.6 * this.metrics.width));
			this.metrics.top	= this.metrics.bottom = Math.ceil(this.metrics.height / 2);					// half the handle hangs over the edge
			this.metrics.left	= this.metrics.right = Handle.protrusion + overhang.strokeWidth;			// side protrusion
			this.metrics.radius	= Math.min(Handle.radius, this.metrics.height / 8);						// Create radius that's not too big for tiny handles
		} else {				// handle moves horizontally:
			this.metrics.height	= overhang.height + overhang.strokeWidth * 2 + Handle.protrusion * 2;
			this.metrics.width	= Math.ceil(Math.min(Handle.thickness, 0.6 * this.metrics.height));
			this.metrics.top = this.metrics.bottom = Handle.protrusion + overhang.strokeWidth;			// side protrusion
			this.metrics.left = this.metrics.right = Math.ceil(this.metrics.width / 2);						// half the handle hangs over the edge
			this.metrics.radius	= Math.min(Handle.radius, this.metrics.width / 8);						// Create radius that's not too big for tiny handles
		}

		// Adjust the overhang metrics to accomodate the handle:
		overhang.left	= Math.max(overhang.left,	this.metrics.left);
		overhang.top	= Math.max(overhang.top,	this.metrics.top);
		overhang.right	= Math.max(overhang.right,	this.metrics.right);
		overhang.bottom	= Math.max(overhang.bottom,	this.metrics.bottom);

		// Return the scale overhang metrics (in CSS pixels):
		return this.metrics;
	};

	/**
	 * Render the handle onto the parent svg canvas.
	 *
	 * @param {object} inside rect
	 * @param {object} outside rect
	 */
	render(inside, outside) {	// Create a bunch of SVG elements:
		assert (this.metrics, 'Call getMetrics() before render()');

		this._rect	= inside;

		// Create the handle canvas element:
		this._svg = createSVGElement('svg', null, this.parent, {
			width:	this.metrics.width,
			height:	this.metrics.height,
			x:		inside.left - this.metrics.left,
			y:		inside.top - this.metrics.top
		});

		// Register _svg as the widget element:
		this.registerAsWidget(this._svg);

		// Offset pixels to center of handle:
		this._offset	= (this.fVertical ? this.metrics.height : this.metrics.width) / 2;
		this._length	= this.fVertical ? inside.height : inside.width;

		// Create the movable cursor/handle thingy:
		var handle = createSVGElement('rect', 'handle', this._svg, {
			x:				0,
			y:				0,
			width:			this.metrics.width,
			height:			this.metrics.height,
			rx:				this.metrics.radius,
			ry:				this.metrics.radius
		});

		// Shrink its size so the full stroke lands inside the svg canvas:
		handle.shrinkByStrokeWidth();

		// Create a gradient fill:
		handle.createGradientFill(this._svg, true);

		// Create a red 'hairline' for the cursor handle (borrowed from sliderule parlance):
		var strokeW = handle.getStrokeWidth();
		createSVGElement('line', 'handle-hairline', this._svg, {
			x1:				this.fVertical ? (strokeW / 2)					: (this.metrics.width / 2),
			x2:				this.fVertical ? (this.metrics.width - strokeW)	: (this.metrics.width / 2),
			y1:				this.fVertical ? (this.metrics.height / 2)		: (strokeW / 2),
			y2:				this.fVertical ? (this.metrics.height / 2)		: (this.metrics.height - strokeW)
		});

		// Set a mousedown handler for the handle:
		handle.onmousedown = this.processMouseEvent;
		handle.addEventListener('touchstart', this.processMouseEvent, false);
		handle._Handle = this;	// store this object on the handle DOM element

		// Connect the node:
		this.node.subscribe(this);
	};

	processMouseEvent(evt) {	// 'this' is the handle svg element, and this._Handle is the Handle object
		var handle = this._Handle;

		switch (evt.type) {
		case 'mousedown':
		case 'touchstart':
			if ((evt.type == 'touchstart' || evt.button == 0) && handle.node.hasWritePermission()) {	// Left mouse button pressed and User has write permission:
				handle.capture = new MouseCapture(this, evt);	// Capture the mouse
				handle._startPosition = handle._position;			// Store the starting pixel position of the handle
			}
			break;

		case 'mousemove':
		case 'touchmove':
			var node	= handle.node;

			// Track the user's handle movement:
			handle.updateHandlePosition.call (handle, handle._startPosition + (handle.fVertical ? -handle.capture.deltaY : handle.capture.deltaX));

			// this._position now has the up-to-date position, so use it to compute a new value:
			var value = (handle._position / handle._length) * (node.engMax - node.engMin) + node.engMin;

			if (node.flags & NodeFlags.NF_RESOLUTION) { // Set the value to the nearest detent:
				assert (node.resolution > 0);
				// This next code assumes that node.engMin lands on a detent (is a multiple of 'resolution'):
				value = Math.round(value / node.resolution) * node.resolution;
			}

			if (value != handle._value) {	// Value changed, so write it out:
				node.setValue (value);
				handle._value = value;
			}
			break;

		case 'mouseup':
		case 'touchend':
	//		console.log('mouseup dx=' + handle.capture.deltaX + ' dy=' + handle.capture.deltaY + ' clicked=' + (handle.capture.clicked ? 'yes' : 'no'));
			delete handle.capture;

			// Immediately snap to nearest detent of written value
			handle.updateHandleValue.call(handle, handle._value);

			// If current node value does not match most recently written handle value, then write the handle value
			// again, so that, if the user paused more than 2 seconds and the values don't match, the handle will
			// eventually reflect the correct node value (within 2 seconds):
			if (handle._value != handle.node.getValue())
				handle.node.setValue(handle._value);
			break;
		}
	};

	update(node) {	// 'this' is the Handle object, and node value/quality changed:
		assert (node === this.node);

		if (node.quality != NodeQuality.NQ_GOOD)
			return;

		// If the handle is not grabbed or is not waiting for a move response, move the handle to the value:
		if (!this.capture) {
			this.updateHandleValue (node.getValue());	// Ignore quality, since owner should have the big red x if q is bad.
		}
	};

	updateHandleValue(value) {	// Update the handle position to 'value':
		// NOTE: The handle location will not necessarily match the bar location, which always reflects the actual node value.
		// If the user is moving the handle, or has recently released it and the snap-back timer is not expired, the bar
		// and the handle may have different positions!
		// This is why we separately store the handle position in "this._value";
		var pixels	= Math.min(this._length, Math.max(0, this._length * (value - this.node.engMin) / (this.node.engMax - this.node.engMin)));

		this.updateHandlePosition (pixels);

		// Store the new handle value (engineering units and CSS units):
		this._value = value;
	};

	updateHandlePosition = function (pixels) { // update displayed handle position to (pixels/this._length) along the bargraph
		// Enforce limits and store the new CSS pixel bar location:
		this._position = Math.min(this._length, Math.max(0, pixels));

		// Move the handle to new position:
		if (this.fVertical)
			this._svg.setAttribute('y', this._rect.top + this._length - this._position - this._offset);
		else // horizontal bar:
			this._svg.setAttribute('x', this._rect.left + this._position - this._offset);
	};

	destroy = function () {
		if (this.capture) {					// Cancel the mouse capture:
			this.capture.destroy();
			delete this.capture;
		}

		this.node.unsubscribe(this);
		this.unregisterAsWidget();
	};

	// Class-level variables:
	static protrusion	= 1;			// handle protrudes x pixels beyond active rect on each side
	static thickness	= 18;			// handle thickness in the travel direction (at extremes, protrudes (handleThickness / 2 - strokeWidth)
	static radius		= 4;			// handle radius (CSS pixels)
};
