import { createElement } from "../elements";
import Card from "./card";
import NodeManager from "../nodemanager";
import ResponsiveBarGraph from "../responsivebargraph";
import ValueDisplay from "../valuedisplay";
import { UnitsFilter } from "../tagfilter";
import { SocketList, TagSocket } from "./tagsocket__classic";
import { TagUnitQuantity } from "../widgets/lib/tagunits";

export default class TankCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 300,
                height: 150,
                minWidth: 300,
                minHeight: 100,
                tags: [],
                type: 'tank',
            }
        }
        super(parent, editor, props);
        this.element.tagMap         = new Map();
        this.element.percentMap     = new Map();
        this.element.barMap         = new Map();
        this.element.nodeManager    = new NodeManager(this.element); // this widget needs a node manager to track the level
        this.element.socketList     = new SocketList([
            new TagSocket('Level Tag', 'Clever description here', ['name', 'ranges'], [new UnitsFilter([TagUnitQuantity.TUQ_LENGTH])], undefined, true),
            new TagSocket('Inflow Tag', 'Tag representing the flow into the tank.', undefined, [new UnitsFilter([TagUnitQuantity.TUQ_FLOWRATE])], undefined, false)
        ], this);
        this.element.wrapper = createElement('div', 'card__wrapper', this.element)

        this.element.update = (tag) => {
            let div = this.element.percentMap.get(tag); // need to manually update our percent full
            if (!div) return;
            if (tag.engMax) {
                let min = div.fWetWell? div.lowLevel.getValue() : tag.engMin
                let percent = ((tag.getValue() - min) / (div.fWetWell? div.highLevel.getValue() : tag.engMax - min) * 100)
                div.innerHTML = percent.toFixed(0);
            }
        }

        let tagOption       = createElement('div', 'card__dropdown__option', undefined, 'Edit Tags');
        tagOption.onclick   = () => {
            this.element.socketList.modify();
        }
        this.element.settingsList.push(tagOption)

        if (this.tags && this.tags.length > 0) {
            this.element.socketList.populate(this.tags);    // if we have tags already, make sure we populate the sockets
            this.rebuild();                                 // rebuilding here should build up the DOM with our tags
        }

        else {                                  // this must be a brand new widget
            this.tags = [];                     // initialize an empty array of tags
            this.element.socketList.modify();   // call modify on our socket list to open a modal so we can add some tags
        }
    }

    addTank() {

    }

    rebuild() {
        this.element.wrapper.removeChildren();
        this.element.nodeManager.destroy();
        this.element.nodeManager    = new NodeManager(this.element); // this widget needs a node manager to track the level

        let levelNode   = {};
        let fWetWell    = false;
        let lowLevel    = undefined;
        let highLevel   = undefined;
        let levelColor  = undefined;
        let name        = ''
        let sockets     = this.element.socketList['sockets'];

        for (let i=0; i<this.element.socketList.sockets.get('Level Tag').tags.length;i++) {
            let tag         = this.element.socketList.sockets.get('Level Tag').tags[i];
            if (!this.resolveTag(tag))
                return;
            levelNode       = this.element.nodeManager.addNode(this.resolveTag(tag)); // store a pointer to the level node

            let widgetWrapper = createElement('div', 'card__widget-wrapper', this.element.wrapper);
            this.element.tagMap.set(tag,widgetWrapper);
            createElement('div', 'card__widget-wrapper__name', widgetWrapper, tag.name);
            let container = createElement('div', 'card__widget-wrapper__tank-container', widgetWrapper)
            let tankWrapper = createElement('div', 'tank__list__card-container__tank', container);
            let bar = new ResponsiveBarGraph(tankWrapper, {
                node:       levelNode,
                fVertical:  true,
                color:      tag.ranges[1][0],
                ranges:     tag.ranges,
                lowLevel:   lowLevel,
                highLevel:  highLevel,
                minimum:    tag.ranges[0][0],
                maximum:    tag.ranges[0][tag.ranges[0].length - 1]
            }).initialize();	// Create and initialize the bar graph
            if (tag.ranges[0].length > 2) {
                for (let i=1;i<tag.ranges[0].length - 1;i++) {
                    bar.addFloat(tag.ranges[0][i]);
                }
            }


            let dataWrapper = createElement('div', 'tank__list__card-container__data-wrapper',container);
            let data = createElement('div', 'tank__list__card-container__data', dataWrapper);

            let levelWrapper = createElement('div', 'tank__list__card-container__level', data)
            new ValueDisplay(levelWrapper, { node: levelNode }).initialize();
            createElement('div', 'tank__list__card-container__level__units', levelWrapper, levelNode.getUnitsText());
            let percentWrapper = createElement('div', 'tank__list__card-container__level', data)
            let percentDiv = createElement('div', 'tank__list__card-container__percent', percentWrapper);
            percentDiv.fWetWell = fWetWell;
            percentDiv.ranges   = tag.ranges;
            this.element.percentMap.set(levelNode, percentDiv);
            createElement('div', 'tank__list__card-container__level__units', percentWrapper, '%');
        }

        this.element.nodeManager.subscribe();

    }

    destroy() {
        window.removeEventListener('resize', this.resizeEvent);
        if (this.element.socketList) {
            this.element.socketList.destroy();
        }
        this.element.nodeManager.destroy();
    }
}
