//This file defines all of the settings that distinguish different deployments
// of the web client from each other.

/* When deploying to really real whoville, set:
                        address to real whoville
                        autologin to null
                        loadSVGs to false
*/

const Application = {    // Application context for authentication server
    JWT_WHOVILLE: 0,
    JWT_WHATIF: 1
} as const;

type AutoLoginInfo = {
  username: string;
  password: string;
}

interface DeploymentSettings {
  address: string;
  authentication: string;
  applicationContext: number;
  autologin: AutoLoginInfo | null;
  performCaptcha: boolean;
}


export const deploySettings: DeploymentSettings = {
  address: 'wss://specificenergy.com:8443',  //real whoville
  authentication: 'wss://specificenergy.com:8443', // authentication server
  applicationContext: Application.JWT_WHOVILLE, // Whoville application
  autologin: null, //don't try to autologin
  performCaptcha: true
}

export const testSettings: DeploymentSettings = {
  address	:         'wss://test.specificenergy.com:8443',  //real whoville
  authentication	: 'wss://test.specificenergy.com:8443', // authentication server
  applicationContext : Application.JWT_WHOVILLE, // Whoville application
  autologin : null, //don't try to autologin
  performCaptcha : true
}

export const betaSettings: DeploymentSettings = {
  address: 'wss://beta.specificenergy.com:8443', // beta whoville
  authentication: 'wss://beta.specificenergy.com:8443', // beta authentication server
  applicationContext: Application.JWT_WHOVILLE,            // Whoville application
  autologin: null,  //don't try to auto login
  performCaptcha: false
}

export const localSettings: DeploymentSettings = {
  address: 'wss://127.0.0.1:8443',
  authentication: 'wss://127.0.0.1:8443',
  applicationContext: Application.JWT_WHOVILLE,  // Whoville application
  autologin: {
    username: 'dev',
    password: 'password'
  },  //tries to log in on test account
  performCaptcha: false
}

export const insecureLocal: DeploymentSettings = {
  address: 'ws://127.0.0.1:8443',  // test whoville
  authentication: 'ws://127.0.0.1:8443', // authentication server
  applicationContext: Application.JWT_WHOVILLE,  // Whoville application
  autologin: null,
  performCaptcha: false
}

export const transcoSettings: DeploymentSettings = {
  address: 'wss://transco-specificenergy.com:8443',  //real whoville
  authentication: 'wss://transco-specificenergy.com:8443', // authentication server
  applicationContext: Application.JWT_WHOVILLE, // Whoville application
  autologin: null, //don't try to autologin
  performCaptcha: true
}
