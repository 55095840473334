import { RegisterWidget, Widget } from './lib/widget';

const template = /*html*/`
<style>
    :host{
        display:inline-block;
        height:100%;
        width:100%;
        overflow:hidden;
    }
    div{
        min-height: 36px;
    }
    svg-wrapper{
        height:400px;
        width:400px;
        display:block;
    }
    .hmi-wrapper{
        display:flex;
        flex-wrap:wrap;
        height:100%;
        width:100%;
        padding:1px;
    }
    .hmi-wrapper div{
        outline: 1px solid var(--color-gray-6);
    }
    .hmi-content{
        height:100%;
        width:100%;
    }
</style>
`.trim();

@RegisterWidget({tag: 'hmi-root', displayName: 'Root'})
export class RootElement extends Widget
{
    wrapper: HTMLElement;
    content: HTMLElement;
    private defaultStyles: HTMLStyleElement;
    private static styleID: string = 'hmi-custom-styles';
    private customStyles: HTMLStyleElement;

    public getAllDescendantElements(): HTMLElement[]
    {
        let traverseBody = (elementToSearch: HTMLElement, foundElements: HTMLElement[]) => {
            if (elementToSearch.childNodes.length && !(elementToSearch instanceof Widget))
            {
                elementToSearch.childNodes.forEach(child =>
                {
                    foundElements.push(child as HTMLElement);
                    traverseBody(child as HTMLElement, foundElements);
                });
            }
        }
        let descendants: HTMLElement[] = [];
        traverseBody(this.wrapper, descendants);
        return descendants;
    }

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('hmi-wrapper');
        let node = templateElement.content.cloneNode(true);
        node.appendChild(this.wrapper);
        return node;
    }

    getStyles(element: Element): CSSStyleRule {
        let styleSheet = this.styleElement.sheet!;
        let index = 0;
        for (let rule of styleSheet.cssRules)
        {
            if (rule instanceof CSSStyleRule && rule.selectorText == `#${element.id}`)
                break;
            else
                index++
        }
        let rule = styleSheet.cssRules[index] ?? styleSheet.cssRules[styleSheet.insertRule(`#${element.id}{}`)];
        return rule as CSSStyleRule;
    }

    private get styleElement(): HTMLStyleElement
    {
        if (this.customStyles)
            return this.customStyles;
        else
        {
            let customStyles = this.shadowRoot!.getElementById(RootElement.styleID) as HTMLStyleElement
            if (!customStyles)
            {
                customStyles = document.createElement('style') as HTMLStyleElement;
                customStyles.id = RootElement.styleID;
                customStyles.appendChild(document.createTextNode('*{}'))
                this.wrapper.prepend(customStyles);
            }
            this.customStyles = customStyles as HTMLStyleElement;
            return this.customStyles;
        }
    }

    /**
     *Take all the css we created in the CSS Object Model and inject it directly into the DOM so it will be captured in innerHTML
     *
     * @memberof RootElement
     */
    public serializeStyles() {
        let cssOmStyles = [].slice.call(this.styleElement.sheet!.cssRules).reduce((prev: String, cssRule: CSSRule) => {
            return prev + cssRule.cssText;
        }, "")
        this.styleElement.innerHTML = '';
        let styles = document.createTextNode(cssOmStyles);
        this.styleElement.appendChild(styles);
    }

    public disconnectedCallback(): void {}
}