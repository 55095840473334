import assert from "./debug";
import ArrowDownIcon from './images/icons/arrow_down.svg';
import { createElement, createUniqueId } from "./elements";
import { Node, NodeFlags } from "./node";

/**
 * TagFilter
 * simple storage structure for filtering tag views
 * @param  {string} name
 * @param  {boolean=false} fDefault
 * @param  {boolean=true} fToggleable
 * @param  {boolean=true} fNested
 */
export class TagFilter {
    name:           string;     // Filter name
    fNested:        boolean;    // Whether to search tag's children for passing tags
    fActive:        boolean;    // Whether this filter is currently active
    fToggleable:    boolean;    // whether or not the user is able to toggle filter active state
    constructor(name: string, fDefault: boolean = false, fToggleable: boolean = true, fNested: boolean = true) {
        assert(name, 'Filters must be provided a name')
        this.name           = name;
        this.fNested        = fNested;
        this.fActive        = fDefault;
        this.fToggleable    = fToggleable;
    }

    filter(tag: Node) {
        if (!this.fActive) // if our filter isn't active simply return true
            return true;
        return this.filterCondition(tag);
    }

    filterOnly(tag: Node) {
        if (!this.fActive) // if our filter isn't active simply return true
        return true;
        let pass: boolean       = false;                // this will be true if our Tag meets the filter criteria
        if (this.filterCondition(tag)) {
            pass = true;
        }
        return pass;
    }

    filterCondition(tag: Node) : boolean {
        assert(false, 'Each filter must have its own filter condition');
        return true;
    }

    static isFolder(tag: any) : boolean {     // helper method to check if our tag is a folder
        let fFolder: boolean = false;
        if (tag instanceof Node)
            fFolder = Node.isFolder(tag);
        else if (tag.fPseudo)
            fFolder = tag.children != undefined;
        return fFolder;
    }

    createToggle(element: HTMLElement, callback: Function) {
        let id                      = createUniqueId();
        let checkWrapper            = createElement('div', 'se-checkbox', element);
        let checkbox            = createElement('input', '', checkWrapper, '', {'type':'checkbox', 'id':id});
        checkbox.checked = this.fActive;
        createElement('label', 'explorer__filter-name', checkWrapper, this.name, { 'htmlFor': id });
        checkbox.onchange = () => {
            this.fActive = checkbox.checked;
            callback();
        }
    }
}
/**
 * Return whether or not a tag's role matches one or more of the provided roles
 *
 * @export
 * @class RoleFilter
 * @extends {TagFilter}
 */
export class RoleFilter extends TagFilter {
    roles: string[];
    constructor(roles: string[], fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('Role', fDefault, fToggleable, fNested);
        assert(roles, 'Role Filters must be provided a list of roles');
        this.roles = roles;
    }

    filterCondition(tag: Node) : boolean {
        if (tag instanceof Node)   // only Nodes have roles
        {
            if(this.roles.length == 0)
                return true;
            for(let role in this.roles)
            {
                if(tag.roles.has(role))
                    return true;
            }
        }
        return false;
    }
}

export class LoggedFilter extends TagFilter {
    constructor(fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('Logged', fDefault, fToggleable, fNested);
    }

    filterCondition(tag: Node) : boolean {
        return (tag instanceof Node && ((tag.flags & NodeFlags.NF_LOG) != 0 || (tag.flags & NodeFlags.NF_DERIVED) != 0))   // only Nodes can be logged
    }
}

export class UnitsFilter extends TagFilter {
    units: number[]; // store an array of the units we care about
    constructor(units: number[], fDefault: boolean = true, fToggleable: boolean = false, fNested: boolean = true) {
        super('Units', fDefault, fToggleable, fNested);
        this.units = units;
    }

    filterCondition(tag: Node) : boolean {
        return (tag instanceof Node && this.units.some((unit) => unit == (tag.units & 0xFF00))) // true if any of our units matches the tag unitW
    }

    // this filter gets a unique toggle element to handle all the different units
    createToggle(element: HTMLElement, callback: Function) {
        let id: string                  = createUniqueId()                                                                  // get a globally unique id to link the input and label
        let filterCheck: HTMLElement    = createElement('div', 'se-checkbox', element, undefined, undefined);
        let box: any                    = createElement('input', '', filterCheck, '', { type: 'checkbox', 'id': id });	// Create our input element
        box.checked = this.fActive;
        createElement('label', 'explorer__filter-name', filterCheck, '', { 'htmlFor': id });
        box.onchange = () => {
            this.fActive = !this.fActive;
            callback();
        }

        let select = createElement('div', 'explorer__filter-name', filterCheck, 'Units', undefined);
        createElement('img', 'tag-viewer__item__icon', select, undefined, {'src':ArrowDownIcon})

        select.onclick =  () => {this.createDropdown(select, box, callback)}
    }

    createDropdown(element: HTMLElement, box: any, callback: Function) {
        let dropdown: any = createElement('div', 'card__dropdown', document.body, undefined, undefined);
        dropdown.onclick = () => {
            dropdown.removeChildren();
            document.body.removeChild(dropdown)
        };

        let dropdownContainer   = createElement('div', 'card__dropdown__container', dropdown, undefined, undefined); //@ts-ignore
        for (let [key, value] of UnitsMap) {
            //let setting = createElement('')
            let unit: string                    = value.abbrev.toString();
            let newID: string                   = createUniqueId()                                                                  // get a globally unique id to link the input and label
            let optionCheck: HTMLElement        = createElement('div', 'se-checkbox', dropdownContainer, undefined, undefined);                               //
            let optionBox: any                  = createElement('input', '', optionCheck, '', { type: 'checkbox', 'id': newID });			// Create our input element
            createElement('label', 'explorer__filter-name', optionCheck, unit, { 'htmlFor': newID });
            optionBox.checked = this.units.includes(key);
            dropdownContainer.appendChild(optionCheck)
            optionBox.onclick = (e: Event) => {
                e.stopPropagation()
            }
            optionBox.onchange = () => {
                if (optionBox.checked) {
                    this.units.push(key)
                    if (!box.checked) {
                        box.checked = true;
                        box.onchange()
                    }
                }
                else {
                    for (let i=0; i<this.units.length;i++) {
                        if (this.units[i] == key)
                            this.units.splice(i,1);
                    }
                    if (this.units.length == 0) {
                        box.checked = false;
                        box.onchange();
                    }
                }
                callback();
            }
        }

        dropdownContainer.style.top = element.getBoundingClientRect().top + 'px';
        dropdownContainer.style.left = element.getBoundingClientRect().left - dropdownContainer.clientWidth + element.clientWidth + 'px';
        return dropdown;
    }
}

/**
 * @param  {TextInput} input
 * @param  {boolean=false} fDefault
 * @param  {boolean=false} fToggleable
 * @param  {boolean=false} fNested
 */
export class SearchFilter extends TagFilter {
    input: HTMLInputElement;
    constructor(input: HTMLInputElement) {
        super('Search', true, false, true);
        assert(input, 'Search filters must be provided a text input as an argument.')
        this.input = input;
    }

    filterCondition(tag: Node) : boolean {
        let pattern = this.input.value.split("").map((x)=>{
            return `(?=.*${x})`
        }).join("");
        if (pattern.length == 0) return true;
        let regex  = new RegExp(`${pattern}`, "g")
        let title  = tag.getDisplayName();
        console.log(title);
        console.log(title.search(regex))
        return regex.test(title);
    }
}

export class SettingsFilter extends TagFilter {
    constructor(fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('Settings', fDefault, fToggleable, fNested);
    }

    filterCondition(tag: Node) : boolean {
        return (tag instanceof Node && tag.isWriteable);
    }
}

export class ValueFilter extends TagFilter {
    constructor(fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('Value', fDefault, fToggleable, fNested);
    }

    filterCondition(tag: Node) : boolean {
        return (tag instanceof Node && tag.vtype > 0 && tag.vtype < 13);
    }
}

export class DeviceFilter extends TagFilter {
    constructor(fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = false) {
        super('Device', fDefault, fToggleable, fNested);
    }

    filterCondition(tag: Node) : boolean {
        return (tag === tag.tree.nodes[0]);
    }
}

export class VTypeFilter extends TagFilter {
    types: number[];
    constructor(types: number[], fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('VType', fDefault, fToggleable, fNested);
        this.types = types;
    }

    filterCondition(tag: Node) : boolean {
        return (this.types.includes(tag.vtype));
    }
}

export class MinMaxFilter extends TagFilter {
    constructor(fDefault: boolean = false, fToggleable: boolean = false, fNested: boolean = true) {
        super('MinMax', fDefault, fToggleable, fNested);
    }

    filterCondition(tag: Node) : boolean {
        return tag.engMin !== undefined && tag.engMax !== undefined;
    }
}