import owner from '../../owner';
import { createElement } from '../elements';
import { Dashboard, ShareSettings } from '../pages/editorpage';
import FrameParser from '../frameparser';
import View from './view';
import assert from '../debug';
import avatar from '../components/avatar';

export interface SerializedCompanyInfo {
    name: string;
    key : string;
}
export default class SharingSettingsView extends View {
    settings       : HTMLSelectElement[] = [];
    userMap        : Map<HTMLSelectElement, any> = new Map();
    sharedUsers    : Map<string, ShareSettings> = new Map();
    dashboard      : Dashboard;
    id             : number;
    companySelector: HTMLSelectElement;
    userContainer  : HTMLElement;
    fInitialized   : boolean = false;
    users          : any[];
    uSelector      : HTMLSelectElement;
    constructor(sharedUsers: Map<string, ShareSettings>, dashboard: Dashboard) {
        super();
        this.sharedUsers    = sharedUsers;
        this.id			    = owner.ldc.registerGraph(this);
        this.dashboard      = dashboard;
    }

    public initialize(parent: HTMLElement): SharingSettingsView {
        super.initialize(parent);
        this.wrapper    = createElement('div', 'sharing-view__wrapper', this.parent);
        if (owner.ldc.isPowerUser()) {			// If the user is a Specific Energy, Inc. employee
			this.companySelector          = createElement('select', 'UserSelector', this.wrapper);  // Give the user a drop down selector to change companies
			this.companySelector.onchange = this.onSelectorChange.bind(this);                       // When the drop down changes, get users for that company
			owner.ldc.getCompanies(this.id);  // Load available companies
		} else {																		    // Normal user
            owner.ldc.getUserList(this.id, owner.ldc.user.companyKey);
		}
        this.userContainer  = createElement('div', 'sharing-view__user-container', this.wrapper);
        this.fInitialized   = true;
        return this;
    }

    private onSelectorChange(): void {
        //this.accountTable.removeUserRows();								// Remove pre-existing rows
		var companyKey;
		if (this.companySelector)										// If we can see multiple companies
			companyKey = this.companySelector.selectedOptions[0].value;	// Refresh the company we are looking at
		else
			companyKey = owner.ldc.user.companyKey;					// Refresh the users associated with the owner's company
        owner.ldc.getUserList(this.id, companyKey);
    }

    onCompaniesReceived(companies: SerializedCompanyInfo[]) {		// Got the companies back
        assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
		for (var i = 0; i < companies.length; ++i)	// For each company we got
			createElement('option', '', this.companySelector, companies[i].name).value = companies[i].key;	// Put an option in the selector
		this.onSelectorChange();					// Load the first company by default
    }

    onGetUserListResponse(fp: FrameParser) {
        this.userContainer.removeChildren();
        let userCount = fp.pop_u16();
        this.users = [];
        for (let i=0;i<userCount;++i) {
            this.users.push({
                username    : fp.pop_string(),
                firstName   : fp.pop_string(),
                lastName    : fp.pop_string()
            })
        }
        let uRow         = createElement('div', 'sharing-view__user-row', this.userContainer);
        let uUser        = createElement('div', 'sharing-view__user', uRow);
        createElement('div', 'sharing-view__user__name', uUser, 'All Users');
        this.uSelector = createElement('select', '', uRow);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_NONE);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_EDIT);
        createElement('option', '', this.uSelector, SharingSettingsView.SHARE_VIEW_ONLY);

        this.uSelector.value = (this.dashboard.fPrivate || (this.companySelector && this.companySelector.selectedOptions[0].value != this.dashboard.companyKey)) ? SharingSettingsView.SHARE_NONE : (this.dashboard.fWrites ? SharingSettingsView.SHARE_EDIT : SharingSettingsView.SHARE_VIEW_ONLY);
        this.uSelector.onchange = () => {
            let value = this.uSelector.options[this.uSelector.selectedIndex].value;
            switch (value) {
                case SharingSettingsView.SHARE_NONE:
                    this.dashboard.fPrivate   = true;
                    this.dashboard.fWrites    = false;
                    break;
                case SharingSettingsView.SHARE_EDIT:
                    this.dashboard.fPrivate   = false;
                    this.dashboard.fWrites    = true;
                    break;
                case SharingSettingsView.SHARE_VIEW_ONLY:
                    this.dashboard.fPrivate   = false;
                    this.dashboard.fWrites    = false;
                    break;
            }
            this.sharedUsers.clear();
            if (this.companySelector)
                this.dashboard.companyKey = this.companySelector.selectedOptions[0].value;
            this.settings.forEach(setting => setting.value = setting.value == SharingSettingsView.SHARE_CREATOR ? setting.value : value)
        }

        for (let i=0;i<this.users.length;++i) {
            if (this.users[i].username == owner.ldc.user.username)
                continue;
            let row = createElement('div', 'sharing-view__user-row', this.userContainer);
            let user = createElement('div', 'sharing-view__user', row);
            avatar(user, this.users[i].firstName, this.users[i].lastName);
            createElement('div', 'sharing-view__user__name', user, this.users[i].firstName + ' ' + this.users[i].lastName);
            let selector = createElement('select', '', row);
            createElement('option', '', selector, SharingSettingsView.SHARE_NONE);
            createElement('option', '', selector, SharingSettingsView.SHARE_EDIT);
            createElement('option', '', selector, SharingSettingsView.SHARE_VIEW_ONLY);
            let setting = this.sharedUsers.get(this.users[i].username);
            if (this.users[i].username == this.dashboard.creator) {
                createElement('option', '', selector, SharingSettingsView.SHARE_CREATOR);
                selector.value = SharingSettingsView.SHARE_CREATOR;
                selector.disabled = true;
            }
            else if (!setting)
                selector.value = this.uSelector.options[this.uSelector.selectedIndex].value;
            else if (setting.fAccess == 0)
                selector.value = SharingSettingsView.SHARE_NONE
            else if (setting.fWrites == 1)
                selector.value = SharingSettingsView.SHARE_EDIT
            else
                selector.value = SharingSettingsView.SHARE_VIEW_ONLY;
            this.userMap.set(selector, this.users[i]);
            selector.onchange = () => this.onSettingChange();
            this.settings.push(selector);
        }
    }

    onSettingChange() {
        for (let i=0;i<this.settings.length;++i) {
            let s       = this.settings[i];
            let user    = this.userMap.get(s)!;
            let setting = s.options[s.selectedIndex].value;
            if (setting == this.uSelector.options[this.uSelector.selectedIndex].value)
                this.sharedUsers.delete(user.username);
            else if (setting != SharingSettingsView.SHARE_CREATOR) {
                let sharedSetting = {
                    fAccess: setting == SharingSettingsView.SHARE_NONE ? 0 : 1,
                    fWrites: setting == SharingSettingsView.SHARE_EDIT ? 1 : 0
                }
                this.sharedUsers.set(user.username, sharedSetting)
            }
        }
    }

    static SHARE_NONE       = 'None';
    static SHARE_VIEW_ONLY  = 'View Only';
    static SHARE_EDIT       = 'Edit';
    static SHARE_CREATOR    = 'Creator'
}