import assert from './library/debug';
//This file defines all of the settings that distinguish different deployments
// of the web client from each other.

/* When deploying to really real whoville, set:
                        address to real whoville
                        autologin to null
                        loadSVGs to false
*/

const Application = {    // Application context for authentication server
    JWT_WHOVILLE: 0,
    JWT_WHATIF: 1
};

export var deploySettings = {
  address: 'wss://specificenergy.com:8443',  //real whoville
  authentication: 'wss://specificenergy.com:8443', // authentication server
  applicationContext: Application.JWT_WHOVILLE, // Whoville application
  autologin: null, //don't try to autologin
  performCaptcha: true
}

export var testSettings = {
  address	:         'wss://test.specificenergy.com:8443',  //real whoville
  authentication	: 'wss://test.specificenergy.com:8443', // authentication server
  applicationContext : Application.JWT_WHOVILLE, // Whoville application
  autologin : null, //don't try to autologin
  performCaptcha : true
}

export var betaSettings = {
  address: 'wss://beta.specificenergy.com:8443', // beta whoville
  authentication: 'wss://beta.specificenergy.com:8443', // beta authentication server
  applicationContext: Application.JWT_WHOVILLE,            // Whoville application
  autologin: null,  //tries to log in on test account
  performCaptcha: false
}

export var localSettings = {
  address: 'wss://127.0.0.1:8443',
  authentication: 'wss://127.0.0.1:8443',
  applicationContext: Application.JWT_WHOVILLE,  // Whoville application
  autologin: ['dev', 'password', '', '', '', '', ''],  //tries to log in on test account
  performCaptcha: false
}

export var insecureLocal = {
  address: 'ws://127.0.0.1:8443',  // test whoville
  authentication: 'ws://127.0.0.1:8443', // authentication server
  applicationContext: Application.JWT_WHOVILLE,  // Whoville application
  autologin: null,
  performCaptcha: false
}
