import createSVGElement from './svgelements';
import assert from './debug';
import './scale.css';

// The Scale object draws a scale along the left, top, right, or bottom edge of a rectangle.
// The current implementation always annotates the scale (lowest value to highest) from
// bottom to top or left to right.
// The scale requires a parent svg canvas element to hold all the labels (svg text elements)
// and tick marks (svg path element).
// First, you have to create the Scale object, and then call render() to generate the svg
// elements. You should only call render once per object construction or you'll get multiple scales
// drawn right on top of each other.
// The reason for the two-step process is to allow an interim step: scale.getMetrics()
// which will return the left, top, right and bottom overhang values in pixels. This allows
// the owner rectangle to adjust its own size to accomodate the scale prior to calling scale.render().
// Therefore, the normal calling sequence is as follows:
// 1. Construct scale
// 2. Call scale.getMetrics() and adjust the svg rectangle (graph, etc) to make room for the scale.
// 3. Call scale.render(activeRect, frameRect) to draw a scale where the scale tic marks align with
//		activeRect and the scale is offset to land just outside the frameRect.
// Scale is fully customized through CSS:
// CSS class 'scale-label' formats the labels
// CSS class 'scale-tic'formats the tic marks and ruler line

export default class Scale {
    constructor(svg, minimum, maximum, resolution, position, fStrokeRulerLine, ticClassName) {
        assert (minimum < maximum);
        this.parent			= svg;			// parent svg canvas
        this.min			= minimum;		// miminum scale value
        this.max			= maximum;		// maximum scale value
        this.res			= resolution;	// resolution of scale
        this.digits 		= Math.max(0,Math.ceil(-Math.log(resolution)/Math.LN10));	// digits to show to the right of the decimal
        this.pos			= position;		// side of box on which to draw scale
        this.fVertical		= (position=='left' || position=='right');	// vertical or horizontal
        this.fStrokeRuler	= (fStrokeRulerLine===undefined) ? true : fStrokeRulerLine;		// default is true
        this.ticClassName	= ticClassName || 'scale-tic';
        this.elements		= [];
    };

	getMetrics(overhang) {
		if (!this.metrics) {
			this.metrics = {};

			this.metrics.fVertical = this.fVertical;

			// Get ruler and tic line width:
			var ticElement = createSVGElement ('line', 'scale-tic', this.parent);
			this.metrics.ticWidth = ticElement.getStrokeWidth();
			this.parent.removeChild(ticElement);

			// Get maximum label size (CSS pixels) based on scale min and max:
			var label = this._getLabelSize (this.min, this.max);

			// Store label metrics based on scale min and max:
			this.metrics.maxLabelWidth	= label.width;
			this.metrics.labelHeight	= label.height;
			this.metrics.halfLabelHeight= Math.ceil(this.metrics.labelHeight / 2);
			this.metrics.halfLabelWidth	= Math.ceil(this.metrics.maxLabelWidth / 2);

			// Establish the CSS-pixel length of the tic marks:
			this.metrics.majorTicLength	= Math.ceil(this.metrics.labelHeight / 2);
			this.metrics.minorTicLength	= Math.ceil(this.metrics.labelHeight / 4);

			if (this.metrics.majorTicLength < this.metrics.ticWidth * 2) { // too stubby:
				this.metrics.majorTicLength = this.metrics.ticWidth * 2;
				this.metrics.minorTicLength = this.metrics.ticWidth;
			}

			// Lengthen tic for overlapping ruler line if it is being drawn:
			if (this.fStrokeRuler) {
				this.metrics.majorTicLength	+= this.metrics.ticWidth;
				this.metrics.minorTicLength	+= this.metrics.ticWidth;
			}

			// Compute indent pixels:
			switch (this.pos) {
			case 'left':	// Vertical scale on left side of owner:
				this.metrics.left	= this.metrics.maxLabelWidth + this.metrics.majorTicLength;
				this.metrics.top	= Math.max(0, this.metrics.halfLabelHeight - overhang.strokeWidth);
				this.metrics.right	= 0;
				this.metrics.bottom	= this.metrics.top;
				break;

			case 'top':		// Horizontal scale on top of owner:
				this.metrics.left	= Math.max(0, this.metrics.halfLabelWidth - overhang.strokeWidth);
				this.metrics.top	= this.metrics.labelHeight + this.metrics.majorTicLength;
				this.metrics.right	= this.metrics.left;
				this.metrics.bottom	= 0;
				break;

			case 'right':	// Vertical scale to the right of owner:
				this.metrics.left	= 0;
				this.metrics.top	= Math.max(0, this.metrics.halfLabelHeight - overhang.strokeWidth);
				this.metrics.right	= this.metrics.maxLabelWidth + this.metrics.majorTicLength;
				this.metrics.bottom	= this.metrics.top;
				break;

			case 'bottom':	// Horizontal scale along bottom-side of owner:
				this.metrics.left	= Math.max(0, this.metrics.halfLabelWidth - overhang.strokeWidth);
				this.metrics.top	= 0;
				this.metrics.right	= this.metrics.left;
				this.metrics.bottom	= this.metrics.labelHeight + this.metrics.majorTicLength;
				break;
			}
		}

		// Adjust the overhang metrics to accomodate the scale:
		overhang.left	= Math.max(overhang.left,	this.metrics.left);
		overhang.top	= Math.max(overhang.top,	this.metrics.top);
		overhang.right	= Math.max(overhang.right,	this.metrics.right);
		overhang.bottom	= Math.max(overhang.bottom,	this.metrics.bottom);

		// Return the scale overhang metrics (in CSS pixels):
		return this.metrics;
	}

	/*
	 * Render the scale onto the parent svg canvas.
	 *
	 * @param inside 	{rect object} inside rectangle	-- tic marks should align with full length of inside rect
	 * @param outside	{rect object} outside rectangle	-- should be used to determine offset of scale from inside rect
	 */
	render(inside, outside) {	// Create a bunch of SVG elements:
		var x;
		var y;

		switch (this.pos) {
			case 'left':	// Vertical scale on left side of owner:
				x = outside.left;
				y = inside.bottom;
				this.length = inside.height;
				break;

			case 'top':		// Horizontal scale on top of owner:
				x = inside.left;
				y = outside.top;
				this.length = inside.width;
				break;

			case 'right':	// Vertical scale to the right of owner:
				x = outside.right;
				y = inside.bottom;
				this.length = inside.height;
				break;

			case 'bottom':	// Horizontal scale along bottom-side of owner:
				x = inside.left;
				y = outside.bottom;
				this.length = inside.width;
				break;

			default:
				x = y = 0;
				assert (false);
				break;
		}

		assert (this.metrics, 'getMetrics() must be called before render()');

		var path = '';	// path string for SVG path element to draw path

		// Stroke the ruler line:
		if (this.fStrokeRulerLine)
			path += this._strokeRulerLine (x, y);

		// Compute major and minor tic mark spacing based on final 'length' param:
		this._computeTicSpacing();

		// Set up the relative and initial values:
		var x0;
		var y0;
		var x1relMinor;
		var y1relMinor;
		var x1relMajor;
		var y1relMajor;
		var textAnchor;
		var baseline;

		switch (this.pos) {
		case 'left':	// Vertical scale on left side of owner:
			x0			= x;
			y0			= 0;
			x1relMinor	= -this.metrics.minorTicLength;
			y1relMinor	= 0;
			x1relMajor	= -this.metrics.majorTicLength;
			y1relMajor	= 0;
			textAnchor	= 'end';
			baseline	= 'central';
			break;

		case 'top':		// Horizontal scale on top of owner:
			x0			= 0;
			y0			= y;
			x1relMinor	= 0;
			y1relMinor	= -this.metrics.minorTicLength;
			x1relMajor	= 0;
			y1relMajor	= -this.metrics.majorTicLength;
			textAnchor	= 'middle';
			baseline	= 'text-top';
			break;

		case 'right':	// Vertical scale to the right of owner:
			x0			= x;
			y0			= 0;
			x1relMinor	= this.metrics.minorTicLength;
			y1relMinor	= 0;
			x1relMajor	= this.metrics.majorTicLength;
			y1relMajor	= 0;
			textAnchor	= 'start';
			baseline	= 'central';
			break;

		case 'bottom':	// Horizontal scale along bottom-side of owner:
			x0			= 0;
			y0			= y;
			x1relMinor	= 0;
			y1relMinor	= this.metrics.minorTicLength;
			x1relMajor	= 0;
			y1relMajor	= this.metrics.majorTicLength;
			textAnchor	= 'middle';
			baseline	= 'text-before-edge';	// Chrome doesn't recognize 'text-bottom'
			break;

		default:
			x0 = y0 = x1relMinor = y1relMinor = x1relMajor = y1relMajor = textAnchor = baseline = 0;
			assert (false);
			break;
			}

		if (this.minorTicsPerMajorTic > 1) {	// Draw the minor tic marks:
			var minorTicSpacing		= this.majorTicSpacing / this.minorTicsPerMajorTic;
			var minorTic			= Math.floor(this.max / minorTicSpacing) * minorTicSpacing;
			var end					= this.min - (this.unitsPerPixel / 2);	// add half pixel width to ensure bottom label/tic is drawn

			while (minorTic >= end) {
				if (this.metrics.fVertical)
					y0 = y - Math.round((minorTic - this.min) * this.pixelsPerUnit) - this.metrics.ticWidth / 2;
				else
					x0 = x + Math.round((minorTic - this.min) * this.pixelsPerUnit) + this.metrics.ticWidth / 2;

				// Add tic mark to path:
				path += ' M ' + x0 + ' ' + y0 + ' l ' + x1relMinor + ' ' + y1relMinor;

				minorTic -= minorTicSpacing;
			}
		}

		// Major tic marks:
		var majorTic	= Math.floor(this.max / this.majorTicSpacing) * this.majorTicSpacing;
		var end			= this.min - (this.unitsPerPixel / 2);	// add half pixel width to ensure bottom label/tic is drawn

		while (majorTic >= end) {
			if (this.metrics.fVertical)
				y0 = y - Math.round((majorTic - this.min)* this.pixelsPerUnit) - this.metrics.ticWidth / 2;
			else
				x0 = x + Math.round((majorTic - this.min) * this.pixelsPerUnit) + this.metrics.ticWidth / 2;

			// Add tic mark to path:
			path += ' M ' + x0 + ' ' + y0 + ' l ' + x1relMajor + ' ' + y1relMajor;

			// Add major tic mark label as svg text element:
			this._addLabel(x0 + x1relMajor, y0 + y1relMajor, majorTic, textAnchor, baseline);

			majorTic -= this.majorTicSpacing;
		}

		// Create the path element:
		var path = createSVGElement ('path', this.ticClassName, null, {d:path});
		this.parent.insertBefore(path, this.parent.firstChild);
		this.elements.push(path);
	}

	destroy() {
		for (var i = 0; i < this.elements.length; ++i)
			this.parent.removeChild(this.elements[i]);
		this.elements.length = 0;
	}

	_addLabel(x, y, labelValue, textAnchor, baseline) {	// Add an SVG text element as label at the end of this tic mark:
		// Add label as SVG text element:
		var label = createSVGElement ('text', 'scale-label', null,{
			x:						x,
			y:						y,
			'text-anchor':			textAnchor,
			'dominant-baseline': 	baseline
			},  this._getLabel(labelValue));
		this.parent.insertBefore(label, this.parent.firstChild);
		this.elements.push(label);
	}

	_getLabel(value) {	// Eliminate trailing zeros and decimal point to streamline scale text:
		return value.toFixed(this.digits).replace(/\.0+$/,'');
	}

	_computeTicSpacing() {	// Compute major and minor tick spacing based on css pixel length in this.length:
		assert (this.metrics, 'getMetrics() must be called first');

		// (Re)compute the following values:
		// this.majorTicSpacing			(engineering units)
		// this.minorTicsPerMajorTic	(integer)
		// this.pixelsPerUnit			(float)
		// this.unitsPerPixel			(float)

		// Determine major label and major tic spacing:
		var scaleLengthEng 		= this.max - this.min;					// engineering units
		var scaleLengthPixels	= this.length - this.metrics.ticWidth;	// squeeze in by 1/2 tic width on each end of scale

		this.pixelsPerUnit		= scaleLengthPixels / scaleLengthEng;
		this.unitsPerPixel		= 1 / this.pixelsPerUnit;

		var labelThicknessEng	= (this.metrics.fVertical ? this.metrics.labelHeight : this.metrics.maxLabelWidth) * this.unitsPerPixel;

		// Rules for major tic mark spacing:
		// 1. Should be no closer than 3x label thickness for vertical or 1.5x label thickness for horizontal scales
		// 2. Decrease by 2x until there are at least two labels (allowing as close as 3 label heights (1.5 for horizonta scales))
		// 3. Should be at least 'resolution'
		// 4. Round up to nearest 'tic' value

		// Rule 1:
		this.majorTicSpacing = labelThicknessEng * (this.metrics.fVertical ? 3 : 1.5);

		// Rule 2:
		while ((scaleLengthEng / this.majorTicSpacing < 2) && (this.majorTicSpacing / labelThicknessEng > (this.metrics.fVertical ? 3 : 1.5)))
			this.majorTicSpacing /= 2;

		// Rule 3:
		if (this.majorTicSpacing < this.res)
			this.majorTicSpacing = this.res;

		// Rule 4:
		for (var i = 0, tic; tic = Scale.tics[i]; ++i) {
			if (this.majorTicSpacing < tic) {
				this.majorTicSpacing = tic;

				// Select minor tic interval as 2 intervals below major tic interval:
				this.minorTicsPerMajorTic = (i >= 2) ? (tic / Scale.tics[i-2]) : 1;

				break;	// out of 'for' loop
			}
		}
	}

	_getLabelSize(val1, val2) {	// Get maximum label size (CSS pixels) based on scale min and max:
		// Create two text elements so we can query their size in pixels:
		var el1 = createSVGElement ('text', 'scale-label', this.parent, null, this._getLabel(val1));
		var el2 = createSVGElement ('text', 'scale-label', this.parent, null, this._getLabel(val2));

		// Get the size of the two extreme scale labels:
		var l1 = el1.getBBox();
		var l2 = el2.getBBox();

		// Done with the elements, so extricate them from the DOM:
		this.parent.removeChild(el1);
		this.parent.removeChild(el2);

		var result = new Object();
		result.width = Math.max(l1.width, l2.width);
		result.height = Math.max(l1.height, l2.height);
		return result;
	}

	_strokeRulerLine(x, y) {	// Stroke the ruler line starting at x,y for this.length
		var x0;
		var y0;
		var x1rel;
		var y1rel;

		switch (this.pos) {
		case 'left':	// Vertical scale on left side of owner:
			x0		= x - this.metrics.ticWidth / 2;
			y0		= y;
			x1rel	= 0;
			y1rel	= -this.length;
			break;

		case 'top':		// Horizontal scale on top of owner:
			x0		= x;
			y0		= y - this.metrics.ticWidth / 2;
			x1rel	= this.length;
			y1rel	= 0;
			break;

		case 'right':	// Vertical scale to the right of owner:
			x0		= x + this.metrics.ticWidth / 2;
			y0		= y;
			x1rel	= 0;
			y1rel	= -this.length;
			break;

		case 'bottom':	// Horizontal scale along bottom-side of owner:
			x0		= x;
			y0		= y + this.metrics.ticWidth / 2;
			x1rel	= this.length;
			y1rel	= 0;
			break;

		default:
			x0 = y0 = x1rel = y1rel = 0;
			assert (false);
			break;
		}
		// Return svg 'path' string:
		return  'M ' + x0 + ' ' + y0 + ' l ' + x1rel + ' ' + y1rel;
    }

    // Major tic mark possibilities (minor tics are selected as major tic array position - 2):
    static tics = [0.0005, 0.001, 0.002, 0.005, 0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500,
        1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000];

};
