import SVGWidget from "./svgwidget";

//---PROBABLY SHOULD BE DEPRECATED---
// Currently, we don't imagine that the user will be writing css files.
// So, setting classes isn't that useful.
// It can currently be safely removed - none of my code uses it.

//The Conditional Class lens will change the classes on an SVGElement (or really, any other element)
//depending on the truth-value of its attached node.
//It will add the classes in its false-class string (space-separated) when the node switched to false, e.g.
//Note that it will never double-add a class nor remove a class that it didn't add. If a class was already
//there when it tried to add it, then it will not remove the class when it leaves the state.
//options:
//	data-se-node:"<node path>" determines the node that will be subscribed to
//	data-se-false-class:""   space-separated string of class names to be added when the node goes false
//	data-se-true-class:""   space-separated string of class names to be added when the node goes true
export default class ConditionalClass extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

		this.addedClasses = [];
		this.falseClasses = SVGWidget.readAttribute(element,"seFalseClass").split(' ');
		this.trueClasses = SVGWidget.readAttribute(element,"seTrueClass").split(' ');
		this.changeClasses(this.trueClasses);
		//We will keep track of which classes we add so that we don't remove any that were there to start with.


		//This should always be the LAST thing that happens in the constructor!
		this.subscribeToNode(SVGWidget.readAndResolveNodePath(element,nodeRoot,"seNode"));
	}

	update(node){
		//We will let JS handle the cast to boolean however it sees fit.
		//In the future perhaps, more advanced work will be in order.
		//console.log(this.trueClasses,this.falseClasses,this.addedClasses);
		if (node.getValue()){
			this.changeClasses(this.trueClasses);
		}
		else{
			this.changeClasses(this.falseClasses);
		}
	}


	//ConditionalClass.changeClasses will change our element's class list from one set to another.
	//It will never remove a class that it didn't add, nor will it add a class that's already there.
	changeClasses(classList){
		for (var i=0;i<this.addedClasses.length;i++){
			this.element.classList.remove(this.addedClasses[i]);
		}
		this.addedClasses = [];
		for (var i=0;i<classList.length;i++){
			if (this.element.classList.contains(classList[i]))continue; //don't double-add
			this.element.classList.add(classList[i]);
			this.addedClasses.push(classList[i]);
		}
	}
}
