import { Alarm } from "./alarm";
import { Device } from './device';
import owner from '../owner';

export default class AlarmManager {
    activeAlarms: Set<Alarm> = new Set();
    activeAlarmCount: number;
    callbacks: any[] = [];
    constructor() {
		for (var i = 0; i < owner.ldc.devices.array.length; ++i) 
            owner.ldc.devices.array[i].alarms.registerCallback(this);   
    }

    onAlarmAdded(alarm: Alarm) {

	}
  	onAlarmChanged() {
    }
  	onAlarmRemoved() {}
  	onHistorical() {}
  	endAlarmCommand(device: Device) {
		for (let i=0;i<device.alarms.alarms.length;i++) {
			let alarm = device.alarms.alarms[i];
			if (alarm.isActive())
			    this.activeAlarms.add(alarm);
            else 
                this.activeAlarms.delete(alarm);
		}
        this.activeAlarmCount = this.activeAlarms.size;
        for (const listener of this.callbacks) {
			listener.onAlarmChanged();
		};
    }

    registerForAlarmChanges(listener: any) {
		this.callbacks.push(listener);
		return this;
	}
}