import Page from "./page";
import owner, { Routes } from '../../owner';
import './mappage.css';
import ViewModal from "../viewmodal";
import { DeviceConfigurationView } from "../views/devicesettingsview";

export default class AddDevicePage extends Page {
    constructor(parent, props) {
        super(parent, props);
        new ViewModal(new DeviceConfigurationView(undefined, props.tag, owner.menuPanel.getCompanyKey()), {
            maxWidth:               '600px',
            minHeight:              '600px',
            title:                  'Pump Settings',
            titleTextColor:         'var(--color-inverseOnSurface)',
            titleBackgroundColor:   'var(--color-primary)',
            buttons: [{
                title:          'Finish',
                borderColor:    owner.colors.hex('--color-primary'),
                callback:       ()=>{location.hash = getHash(Routes.Home)},
            }]
        })
    }

    setProps() { }
}