import CloseIcon from '../images/icons/cancel.svg';
import DragIcon from '../images/icons/drag.svg';
import SettingsIcon from '../images/icons/settings.svg';
import { createElement } from "../elements";
import assert from "../debug";
import './card.css';
import owner from '../../owner';

 export default class Card {
    constructor(parent, editor, props) {
        assert(parent, 'All cards must be provided a parent element');
        this.parent = parent;
        this.editor = editor;

        this.copy(props);

        this.element            = createElement('div', 'card__element', this.parent);
        this.element.card       = this;
        this.element.bar        = createElement('div', 'card__bar', this.element);
        this.element.drag       = createElement('img', 'card__bar__button', this.element.bar, null, { 'src': DragIcon });
        this.element.titleBox   = createElement('input', 'card__bar__title', this.element.bar, null, { 'type': 'text' });
        this.element.titleText  = createElement('div', 'card__title', this.element);
        this.element.warning    = createElement('div', 'card__warning', this.element, '!');

        this.element.style.width            = this.width + 'px';
        this.element.style.height           = this.height + 'px';

        this.element.titleBox.oninput = () => {
            this.element.titleText.textContent = this.element.titleBox.value;
            this.name = this.element.titleBox.value;
        }

        this.element.settings = createElement('img', 'card__bar__button', this.element.bar, null, { 'src': SettingsIcon });
        this.element.settingsList = [];
        this.element.close = createElement('img', 'card__bar__button', this.element.bar, null, { 'src': CloseIcon });
        this.element.resize = createElement('div', 'card__resize', this.element);

        this.element.resize.onmousedown = (e) => {
            this.dx = e.pageX - this.element.getBoundingClientRect().left ;
            this.dy = e.pageY - this.element.getBoundingClientRect().top + this.parent.getBoundingClientRect().top;
            this.onResizeFinishedRef  = this.onResizeFinished.bind(this)
            this.onResizeRef          = this.onResize.bind(this)
            document.addEventListener("mouseup", this.onResizeFinishedRef);
            document.addEventListener("mousemove", this.onResizeRef);
        }
        this.element.settings.onclick = () => this.onSettingsClicked();
        this.element.close.onclick = () => {
            this.editor.removeCard(this);
        }
        if (this.name)
            this.element.titleText.textContent = this.element.titleBox.value = this.name;
        this.resizeEvent     = () => this.resizeCallback();
        window.addEventListener('resize', this.resizeEvent);
    }
    /**
     * Helper method to copy all of the provided properties to the Card
     * @param {Object} props
     */
	copy(props) {	// Copy set of properties over to this widget object
		for (var attr in props) {
			if (props.hasOwnProperty(attr) && typeof props[attr] !== 'undefined')
				this[attr] = props[attr];
		}
    }
    /**
     * Method called when the user is dragging the resize button
     */
    onResize() {
        let e = window.event;
        e.preventDefault();
        this.element.bar.style.opacity = 1;
        this.width = Math.max(Math.round((e.clientX - this.element.getBoundingClientRect().left)/10)*10, this.minWidth);
        this.height = Math.max(Math.round((e.clientY - this.element.getBoundingClientRect().top)/10)*10, this.minHeight);
        if (this.width != this.element.clientWidth || this.height != this.element.clientHeight) {
            this.element.style.width = this.width + 'px';
            this.element.style.height = this.height + 'px';
            this.editor.refresh();
        }
    }

    /**
     * Method to clean up event listeners and sync our state
     */
    onResizeFinished() {
        this.element.bar.style.opacity = null;
        document.removeEventListener("mouseup",     this.onResizeFinishedRef);
        document.removeEventListener("mousemove",   this.onResizeRef);
        document.removeEventListener("mousemove",   this.onResize.bind(this));
        document.removeEventListener("mouseup",     this.onResizeFinished.bind(this));
        document.removeEventListener("touchend",    this.onResizeFinished.bind(this));
        document.removeEventListener("touchcancel", this.onResizeFinished.bind(this));
        this.editor.refresh();
        this.editor.pushTrace();
        this.resizeCallback();

        return;
    }

    /**
     * This method is called whenever our resize is finished. It should be overridden if a card needs
     * custom resize behavior.
     */
    resizeCallback() {
        console.log('No custom resizeCallback method provided for this card')
    }

    onSettingsClicked() {
        this.createDropdown(this.element.settings);
    }
    /**
     * Helper method to retrieve the node object from a serialized tag
     * @param {Object} tag
     */
    resolveTag(tag) {
        let device = owner.ldc.devices.getByKey(tag.deviceKey);
        let resolvedTag = device.tree.findNode(tag.path);
        return resolvedTag;
    }

    /**
     * Helper method to retrieve a list of node objects from a list of serialized tags
     * @param {[Object]} tags
     */
    resolveTags(tags) {
        let resolvedTags = [];
        for (let i=0;i<tags.length;i++) {
            let device = owner.ldc.devices.getByKey(tags[i].deviceKey);
            let resolvedTag = device.tree.findNode(tags[i].path);
            resolvedTags.push(resolvedTag)
        }
        return resolvedTags;
    }
    /**
     * Method to add a dropdown menu to an element
     *
     * @param  {HTMLElement} element
     */
    createDropdown(element) {
        let dropdown = createElement('div', 'card__dropdown', document.body);
        dropdown.onclick = () => {
            dropdown.removeChildren();
            document.body.removeChild(dropdown)
        };

        let dropdownContainer   = createElement('div', 'card__dropdown__container', dropdown);
        for (let i=0; i<this.element.settingsList.length;i++) {
            let setting = this.element.settingsList[i];
            dropdownContainer.appendChild(setting)
        }

        dropdownContainer.style.top = element.getBoundingClientRect().top + 'px';
        dropdownContainer.style.left = element.getBoundingClientRect().left - dropdownContainer.clientWidth + element.clientWidth + 'px';
        return dropdown;
    }
    rebuild() {

    }

    destroy() {
        window.removeEventListener('resize', this.resizeEvent);
        if (this.element.socketList) {
            this.element.socketList.destroy();
        }
    }
}

