import { Gizmo }            from "./gizmo";
import EditorPage           from '../../pages/editorpage';
import { createElement }    from "../../elements";
import ContainerIcon        from "../../images/icons/container.svg";
import { StyleCategories }  from '../../views/dashboardstyleview';
import owner from '../../../owner';

export class ContainerGizmo extends Gizmo {
    noChildWrapper: HTMLElement;
    allowableStyles: StyleCategories[] = [StyleCategories.POSITION, StyleCategories.DIMENSIONS]
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.LAYOUT, StyleCategories.BORDER, StyleCategories.BACKGROUND, StyleCategories.SPACING]
        this.applyStyles();
        this.onChildrenDidChange();
    }

    applyDefaults() {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '75%');
        }
        if (typeof this.getStyleSetting('height', 0) === 'undefined') {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
    }

    onChildrenDidChange(): void {
        if (this.editor && (!this.recipe.children || this.recipe.children.length == 0)) {
            this.classList.add('_empty__container')
            this.noChildWrapper = createElement('div', '_gizmo__overlay__wrapper', this.overlay);
            createElement('img', '_gizmo__overlay__icon', this.noChildWrapper, undefined, {'src':ContainerIcon});
            createElement('div', '_gizmo__overlay__icon', this.noChildWrapper, 'Empty Container');
        }
        else {
            if (this.noChildWrapper && this.noChildWrapper.parentElement === this.overlay) {
                this.overlay.removeChild(this.noChildWrapper);
            }
            this.classList.remove('_empty__container');
        }
    }

    onResize(): void {
        if (this.recipe.children)
            this.recipe.children.forEach(child => child.gizmo!.onResize())
    }
}

export class RowContainerGizmo extends ContainerGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }
}

export class ColumnContainerGizmo extends ContainerGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
    }
}
