import { createElement, createUniqueId }            from '../../elements';
import { DragDropGraph }                            from "../../graph";
import EditorPage, {TreeItem}                       from "../../pages/editorpage";
import { LoggedFilter, MinMaxFilter, SettingsFilter, VTypeFilter }from "../../tagfilter";
import owner                                        from "../../../owner";
import { Node, VType, NodeSubscriber }              from '../../node';
import ViewModal                                    from "../../viewmodal";
import {RadialGauge}                                from 'canvas-gauges'
import ResponsiveBarGraph                           from "../../responsivebargraph";
import DashboardView                                from '../../views/dashboardview';
import Switch                                       from "../../components/switch";
import ValueDisplay                                 from "../../valuedisplay";
import assert                                       from "../../debug";
import { StyleCategories, SerializedTagCategories } from '../../views/dashboardstyleview';
import LiveData                                     from "../../livedata";
import FrameParser                                  from "../../frameparser";
import ImageBrowserView                             from "../../views/imagebrowserview";
import NodeManager                                  from "../../nodemanager";
import { Dashboard }                                from '../../pages/editorpage';
import TagSocket                                    from '../tagsocket';

import "../../views/dashboardstylesview.css";
import './gizmo.css';

import AddIcon              from "../../images/icons/add.svg";
import AlarmIcon            from "../../images/icons/alarms-outline.svg";
import ArrowIcon            from "../../images/icons/arrow_down_filled.svg";
import AvgCumulativeIcon    from "../../images/icons/avg-cumulative.svg";
import AvgIcon              from "../../images/icons/avg.svg";
import BarChartIcon         from "../../images/icons/bar-chart.svg";
import BoxPlotIcon          from "../../images/icons/box-plot.svg";
import ButtonIcon           from "../../images/icons/button.svg";
import ChartIcon            from "../../images/icons/line-chart.svg";
import ClockIcon            from "../../images/icons/clock.svg"
import ContainerColIcon     from "../../images/icons/columns.svg";
import ContainerRowIcon     from "../../images/icons/rows.svg";
import DateIcon             from "../../images/icons/schedule.svg";
import GaugeIcon            from "../../images/icons/gauge.svg";
import ImageIcon            from "../../images/icons/image.svg";
import LinkIcon             from "../../images/icons/link.svg";
import MinIcon              from "../../images/icons/min.svg";
import MaxIcon              from "../../images/icons/max.svg";
import MinCumulativeIcon    from "../../images/icons/min-cumulative.svg";
import MaxCumulativeIcon    from "../../images/icons/max-cumulative.svg";
import PieChartIcon         from "../../images/icons/pie_chart.svg";
import PaletteIcon          from "../../images/icons/palette.svg";
import RadioButtonIcon      from "../../images/icons/radio-button.svg";
import SwitchIcon           from "../../images/icons/switch.svg";
import SliderIcon           from "../../images/icons/slider.svg";
import StackedBarIcon       from "../../images/icons/stackedbar.svg";
import TextIcon             from "../../images/icons/text.svg";
import TableIcon            from "../../images/icons/table.svg";
import ValueIcon            from "../../images/icons/value.svg";
import SEMapIcon            from "../../images/icons/se-map.svg";
import XIcon                from "../../images/icons/x.svg";
import PumpIcon             from "../../images/icons/pump.svg";
import StatusBarIcon        from "../../images/icons/status_level.svg";
import SimpleSlider from '../../simpleslider';
import { Alarm, ConfiguredAlarm } from '../../alarm';
import { RadioButton, RadioSelector } from '../../components/radio';
import { UnitsMap } from '../../widgets/lib/tagunits';
import { Tag, TagQuality } from '../../widgets/lib/tag';
import { ToggleSwitch } from '../../widgets/input/toggle/toggleswitch';

// a serialized representation of a tag and any tag-specific properties for a gizmo
// feel free to create additional optional attributes as needed
export interface SerializedTag {
    deviceKey: string;              // device Key of tag
    path: string;                   // device relative path
    socket: string;                 // name of the socket (we can have more than one per gizmo after all)
    settings?: {[key: string]: string};
}

enum StyleConditional {
    ALWAYS_TRUE                 = 0,
    ALWAYS_FALSE                = 1,
    IS_TRUE                     = 2,
    IS_FALSE                    = 3,
    IS_EQUAL_TO                 = 4,
    IS_NOT_EQUAL_TO             = 5,
    IS_GREATER_THAN             = 6,
    IS_LESS_THAN                = 7,
    IS_GREATER_THAN_OR_EQUAL_TO = 8,
    IS_LESS_THAN_OR_EQUAL_TO    = 9,
}

export interface StyleCondition {
    tag:            SerializedTag;
    conditional:    StyleConditional;
    value:          any;
    style:          {[key: number] : {[style: string]: string}}
};

// all the instructions we need to cook up a new gizmo!
export interface Recipe {
    id:         number;
    name:       string;
    style:      {[key: number] : {[style: string]: string}};
    classes:    StyleCondition[];
    tags:       SerializedTag[];
    settings:   {[key: string]: any};
    children:   Recipe[];
    gizmo:      Gizmo | null;
    treeItem?:  TreeItem;
}

export enum GizmoType {
    WT_CONTAINER        = 0,
    WT_TEXT             = 1,
    WT_CHART            = 2,
    WT_SWITCH           = 3,
    WT_SLIDER           = 4,
    WT_VALUE            = 5,
    WT_NUMERIC_INPUT    = 6,
    WT_PROFILE_CHART    = 7,
    WT_ALARM_TIMELINE   = 8,
    WT_BOX_PLOT         = 9,
    WT_IMAGE            = 10,
    WT_PIE_RANGE        = 11,
    WT_DATE             = 12,
    WT_GAUGE            = 13,
    WT_MIN              = 14,
    WT_MAX              = 15,
    WT_AVG              = 16,
    WT_CUM_MIN          = 17,
    WT_CUM_MAX          = 18,
    WT_CUM_AVG          = 19,
    WT_ALARM_COUNT      = 20,
    WT_BAR              = 21,
    WT_SE_MAP           = 22,
    WT_SETPOINT         = 23,
    WT_BASE             = 24,
    WT_HOA              = 25,
    WT_PUSH_BUTTON      = 26,
    WT_TANK             = 27,
    WT_TIME             = 28,
    WT_FRAME            = 29,
    WT_CONTAINER_COL    = 30,
    WT_CONTAINER_ROW    = 31,
    WT_LINK             = 32,
    WT_COLOR_STATUS     = 33,
    WT_HOA_BITS         = 35,
    WT_DAILY_TOTALS     = 36,
    WT_PUMP_BANK        = 37
}
/**
 * @export
 * @class Gizmo
 * @extends {HTMLElement}
 */
export class Gizmo extends HTMLElement {
    guid:               number;
    name:               string;
    view:               DashboardView;
    parentRecipe:       Recipe | null = null;
    overlay:            HTMLElement | undefined;
    clickHandler:       HTMLElement;
    _observer:          MutationObserver;
    editor:             EditorPage | undefined;
    treeItem:           TreeItem;
    recipe:             Recipe;
    wrapper:            HTMLElement;
    allowableStyles:    StyleCategories[] = [];
    dashboard:          Dashboard;
    public connectedCallback(): void {}

    _domChanged(): void {}

    applyStyles(): void {
        this.setAttribute('style', '');
        if (!this.recipe.style)
            this.recipe.style = {};
        for (let i=0;i<=this.view.currentQueryIndex;++i) {
            if (i==2 || i==4)
                continue;
            if (this.recipe.style[i]) {
                for (let [key, value] of Object.entries(this.recipe.style[i]))
                    this.style.setProperty(key, value);
            }
        }
        if (this.editor)
            this.editor.overlay.resize();
    }

    addOverlayElements(parent: HTMLElement): void {};

    applyDefaults() {};

    getStyleSetting(property: string, query: number) : string | undefined {
        if (!this.recipe.style)
            this.recipe.style = {};
        return this.recipe.style[query] && this.recipe.style[query][property]
    }

    modifyStyleSetting(property: string, query: number, value: string) {
        if (!this.recipe.style[query])
            this.recipe.style[query] = {};
        this.recipe.style[query][property] = value;
    }

    onTagsAdded(socket: TagSocket) {
        this.rebuild();
    };

    onTagsRemoved(socket: TagSocket) {
        //this.rebuild();
    };

    onTagSelectionChanged(tag: SerializedTag) {}
    onTagStatusChanged(socket: TagSocket, ) {};
    onTagSettingChanged() {
        this.rebuild();
    }
    onChildrenDidChange() {};
    onStyleDidChange() {};

    removeChildRecipe(childRecipe: Recipe) {
        for (let i=0;i<this.recipe.children.length;++i) {
            if (childRecipe === this.recipe.children[i]) {
                this.recipe.children.splice(i,1);
            }
        }
    }

    findSerializedTag(node: Node, socketName: string): SerializedTag | undefined {
        for (let i=0;i<this.recipe.tags.length;++i) {
            let serializedTag = this.recipe.tags[i];
            if (serializedTag.path == node.getDeviceRelativePath() && serializedTag.deviceKey == node.tree.device.key && serializedTag.socket == socketName)
                return serializedTag;
        }
    }

    populateSettings(editor: EditorPage) {
        //this.styleManager.buildDOM(this.editor.toolTabs.getSectionByName('Styles'))
    }

    createSection(parent: HTMLElement, name: string, callback?:(state: boolean)=>void, switchColor?: string) : HTMLElement {
        let titleContainer  = createElement('div', 'editor__container__setting', parent);
        let dropdownArrow   = createElement('img', 'editor__container__setting__arrow', titleContainer, '', {'src':ArrowIcon});
        dropdownArrow.onclick = () => {

        }
        createElement('div', 'editor__container__setting__title', titleContainer, name);
        if (callback) {
            new Switch(titleContainer, false, switchColor && 'var(--color-gray-2)', (state: boolean)=>callback(state))
        }
        let section = createElement('div', 'editor__container__setting__section', parent)
        return section;
    }



    createSettingToggle(setting: string, title: string, fDefault: boolean = false, tooltip?: string) {
        let settingWrapper  = createElement('div', 'gizmo__setting__row', this.editor!.toolTabs.getSectionByName('Settings'));
        let titleDiv        = createElement('div', '', settingWrapper, title);
        let toggle          = new Switch(settingWrapper, this.recipe.settings[setting] ?? fDefault, 'var(--color-primary)', (state: boolean) => {
            this.recipe.settings[setting] = state;
            this.rebuild();
        });
    }

    createSettingTextInput(setting: string, title: string, defaultText?: string, tooltip?: string) {
        let settingWrapper  = createElement('div', 'gizmo__setting__row', this.editor!.toolTabs.getSectionByName('Settings'));
        let titleDiv        = createElement('div', '', settingWrapper, title);
        let input           = createElement('input', 'editor__container__settings__input', settingWrapper, undefined, {'type':'text'});
        input.value         = this.recipe.settings[setting] ?? defaultText ?? '';
        input.onchange = () => {
            this.recipe.settings[setting] = input.value;
            this.rebuild();
        };
    }

    createSettingNumberInput(setting: string, title: string, step?: number, tooltip?: string) {
        let settingWrapper  = createElement('div', 'gizmo__setting__row', this.editor!.toolTabs.getSectionByName('Settings'));
        let titleDiv        = createElement('div', '', settingWrapper, title);
        let input           = createElement('input', '', settingWrapper, undefined, {'type':'number', 'step':step?.toString()});
        input.value         = this.recipe.settings[setting] ?? 'Default';
        input.onchange = () => {
            this.recipe.settings[setting] = input.value;
            this.rebuild();
        };
    }

    createSettingSelectInput(setting: string, title: string, displayNames: string[], settingNames: string[], defaultValue: string, tooltip?: string) {
        assert(displayNames.length == settingNames.length, 'Mismatch array lengths for settings select input');
        let settingWrapper  = createElement('div', 'gizmo__setting__row', this.editor!.toolTabs.getSectionByName('Settings'));
        let titleDiv        = createElement('div', '', settingWrapper, title);
        let input           = createElement('select', '', settingWrapper);
        for (let i=0;i<displayNames.length;++i) {
            createElement('option', '', input, displayNames[i], {'value':settingNames[i]});
        }
        input.value = this.recipe.settings[setting] ?? defaultValue;
        input.onchange = () => {
            this.recipe.settings[setting]  = input.value;
            this.rebuild();
        };
    }

    createSettingsColorInput(setting: string, title: string) {
        let settingWrapper  = createElement('div', 'gizmo__setting__row', this.editor!.toolTabs.getSectionByName('Settings'));
        let titleDiv        = createElement('div', '', settingWrapper, title);
        let input           = createElement('input', '', settingWrapper, undefined, {'type':'color'});
        input.value         = this.recipe.settings[setting] ?? '#000000';
        input.onchange = () => {
            this.recipe.settings[setting] = input.value;
            this.rebuild();
        };
    }
    /**
     * Creates a setting pallette for a storing a named range setting with a corresponding color setting.
     *
     *
     * @param {HTMLElement} parent
     * @memberof Gizmo
     */
    createRangeSettings(parent: HTMLElement) {
        let wrapper             = this.createSection(parent,'Ranges')
        let optionWrapper       = createElement('div', 'option__range__wrapper', wrapper)
        let rangeWrapper        = createElement('div', 'socket__range', optionWrapper);
        let nameColumn          = createElement('div', 'socket__range__column', rangeWrapper);
        let inputColumn         = createElement('div', 'socket__range__column', rangeWrapper);
        let colorColumn         = createElement('div', 'socket__range__column', rangeWrapper);
        let addButton           = createElement('div', 'socket__add__button', optionWrapper);
        createElement('div', 'socket__add__button__text', addButton, 'Add Range');
        createElement('img', 'socket__add__button__icon', addButton, undefined, {'src':AddIcon});
        let minInput = createElement('input', 'dwsetting__range__value', inputColumn, '',  {'autocomplete'     : 'off',
                                                                                            'autocapitalize'   : 'off',
                                                                                            'type'             : 'number'});	// Create the input element

        let addRange = (i: number) => {
            let ranges = this.recipe.settings['Ranges'];
            let rangeName   = createElement('input', 'dwsetting__range__name', nameColumn);
            rangeName.value = ranges[i].name;
            rangeName.onchange = () => {
                this.recipe.settings['Ranges'][i].name = rangeName.value as string;
                this.rebuild();
            }
            let rangeInput  = createElement('input', 'dwsetting__range__value', inputColumn, undefined, {   'autocomplete'     : 'off',
                                                                                                            'autocapitalize'   : 'off',
                                                                                                            'type'             : 'number'});	// Create the input element
            rangeInput.value = ranges[i].value;
            rangeInput.onchange = () => {
                this.recipe.settings['Ranges'][i].value = parseFloat(rangeInput.value);
                this.rebuild();
            }
            let rangeColor      = createElement('input', 'dwsetting__range__color', colorColumn, '', {'type':'color'});
            rangeColor.value    = ranges[i].color
            rangeColor.oninput  = () => {
                this.recipe.settings['Ranges'][i].color = rangeColor.value;
                this.rebuild();
            }
        }

        minInput.value = this.recipe.settings['Minimum'];
        minInput.onchange = () => {
            this.recipe.settings['Minimum'] = minInput.value;
            this.rebuild();
        }
        let ranges = this.recipe.settings['Ranges'];
        for (let i=0;i<ranges.length;++i) {
            addRange(i);
        }

        addButton.onclick = () => {
            ranges.push({
                name: '',
                value: ranges[ranges.length - 1].value + 1,
                color: '#f3523f'
            });
            addRange(ranges.length - 1);
            this.rebuild();
        }
    }
    /**
     * Creates a setting pallette for a generic key value pair editor.
     *
     * @param {HTMLElement} parent
     * @param {string} title
     * @param {string} addButtonText    //
     * @param {boolean} [fColor=false]  // make our input a color picker
     * @memberof Gizmo
     */
    createMapSettings(parent: HTMLElement, title: string, addButtonText: string, fColor: boolean = false) {
        let wrapper             = this.createSection(parent, title)
        let optionWrapper       = createElement('div', 'option__range__wrapper', wrapper)
        let rangeWrapper        = createElement('div', 'socket__range', optionWrapper);
        let nameColumn          = createElement('div', 'socket__range__column', rangeWrapper);
        let inputColumn         = createElement('div', 'socket__range__column', rangeWrapper);
        let addButton           = createElement('div', 'socket__add__button', optionWrapper);
        createElement('div', 'socket__add__button__text', addButton, addButtonText);
        createElement('img', 'socket__add__button__icon', addButton, undefined, {'src':AddIcon});

        let addSetting = (i: number) => {
            let ranges      = this.recipe.settings['VMaps'];
            let value       = createElement('input', 'dwsetting__range__value', nameColumn);
            value.value = ranges[i].value;
            value.onchange = () => {
                this.recipe.settings['VMaps'][i].value = value.value as string;
                this.rebuild();
            }
            let mapInput  = createElement('input', 'dwsetting__range__name', inputColumn, undefined, {  'autocomplete'     : 'off',
                                                                                                        'autocapitalize'   : 'off',
                                                                                                        'type'             : fColor ? 'color' : 'text'});	// Create the input element
            mapInput.value = ranges[i].text;
            mapInput.onchange = () => {
                this.recipe.settings['VMaps'][i].text = mapInput.value;
                this.rebuild();
            }
        }
        let maps = this.recipe.settings['VMaps'];
        for (let i=0;i<maps.length;++i) {
            addSetting(i);
        }

        addButton.onclick = () => {
            maps.push({
                value: (maps[maps.length - 1]?.value ?? 0) + 1,
                text: 'Default'
            });
            addSetting(maps.length - 1);
            this.rebuild();
        }
    }

    createColorMapSettings(parent: HTMLElement) {

    }

    onHoverEnd(e: MouseEvent) {
        this.style.outline = '';
    }

    onResize() {};

    onMove() {};

    rebuild() {};

    // do all our destructor stuff here
    disconnectedCallback() {
        // Later, you can stop observing
    }

    static allowableStyles: StyleCategories[] = [];
}

export class LiveDataGizmo extends Gizmo implements NodeSubscriber {
    warning: HTMLImageElement;
    sockets: Map<string, TagSocket> = new Map();
    public connectedCallback(): void {
        super.connectedCallback();
        this.warning    = createElement('img', '_gizmo__warning', this, '', {src:XIcon});
    }

    update(node: Node) {};
	onNodeChanged(node: Node) {};
	onNodeRemoved(node: Node) {};
    onNodeDisconnected(node: Tag) {};
	onAlarm(node: Node, alarm: Alarm, fAdded: boolean, fChanged: boolean, fDeleted: boolean) {};
	onConfiguredAlarm(node: Node, configuredAlarm: ConfiguredAlarm, fAdded: boolean) {};
    onTreeComplete(socketName: string) {
        this.rebuild();
    };

    showWarning(title?: string) {
        this.warning.style.opacity = '0.7';
        if (title)
            this.warning.title = title;
    }

    hideWarning() {
        this.warning.style.opacity = '0';
    }
}

export class IFrameGizmo extends Gizmo {
    frame: HTMLIFrameElement;
    public connectedCallback(): void {
        this.frame = createElement('iframe', '', this);
        this.frame.src = this.recipe.settings['src'];
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingTextInput('src', 'Source', undefined, 'Update this');
    }

    rebuild() {
        this.frame.src = this.recipe.settings['src'];
    }
}

export class ValueGizmo extends LiveDataGizmo {
    socket: TagSocket;
    public connectedCallback(): void {
        super.connectedCallback();
        this.wrapper        = createElement('div', 'full__width full__height', this)
        this.allowableStyles = [StyleCategories.DIMENSIONS, StyleCategories.TYPOGRAPHY, StyleCategories.POSITION, StyleCategories.BACKGROUND];
        if (!('VMaps' in this.recipe.settings)) {
            this.recipe.settings['VMaps'] = [];
        }
        this.socket = new TagSocket(owner.ldc, 'Live Value Tags', this, [], [], false);
        this.sockets.set('Live Value Tags', this.socket);
        this.socket.refreshTags();
    }
    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingToggle('fUnits', 'Show Units:');
        this.createMapSettings(editor.toolTabs.getSectionByName('Settings'), 'Value Overrides', 'Add Override', false);
        this.createSettingNumberInput('decimals', 'Decimal Places:', 1);
    }

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        if (tag)
            tag.subscribe(this);
    }


    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '96px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '54px');
        }
    }

    update(node: Node) {
        if (node.quality != TagQuality.TQ_GOOD) {
            this.showWarning();
            return;
        }
        this.hideWarning();
        this.setValue(node);
    }

    setValue(node: Node)
    {
        let value = '';
        if ('decimals' in this.recipe.settings)
            value = `${node.getValue().toFixed(this.recipe.settings['decimals'])} ${this.recipe.settings['fUnits'] ? node.getUnitsText() : ''}`
        else
            value = node.getFormattedText(this.recipe.settings['fUnits']);
        this.wrapper.textContent = value;
        for (let override of this.recipe.settings['VMaps']) {
            if (override.value == node.getValue()) {
                this.wrapper.textContent = override.text;
            }
        }
    }

    disconnectedCallback(): void {
        let [tag] = this.socket.tags;
        if (tag)
            tag.unsubscribe(this);
        super.disconnectedCallback()
    }

    rebuild() {
        let [tag] = this.socket.tags;
        if (tag)
            this.update(tag);
    }
}

export class BarGizmo extends LiveDataGizmo {
    socket: TagSocket;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.BORDER, StyleCategories.BACKGROUND]
        this.wrapper = createElement('div', 'full__width full__height', this);
        this.socket = new TagSocket(owner.ldc, 'Bar Input Tag', this, [new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], true, false)], [], true);
        this.sockets.set('Bar Input Tag', this.socket);
        this.socket.refreshTags();
    }
    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingToggle('fHorizontal', 'Horizontal: ');

        //this.createSizeSettings(editor.toolTabs.getSectionByName('Style'));
    }


    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '120px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '24px');
        }
    }

    rebuild() {
        this.wrapper.removeChildren();
        let tags = Array.from(this.socket.tags);
        if (tags.length < 1)
            return;

        this.hideWarning();
        new ResponsiveBarGraph(this.wrapper, {node: tags[0], fVertical: !this.recipe.settings['fHorizontal']}).initialize();
    }
}

export class TextGizmo extends Gizmo {
    public connectedCallback(): void {
        super.connectedCallback();
        this.wrapper = createElement('div', 'full__width full__height', this);
    }

    setText(text: string) {
        this.wrapper.textContent = text;
    }
}
export class DateGizmo extends TextGizmo {
    tickInterval: NodeJS.Timeout;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.DIMENSIONS, StyleCategories.TYPOGRAPHY, StyleCategories.POSITION, StyleCategories.BACKGROUND];
        this.wrapper.textContent = new Date().toLocaleString(this.recipe.settings['locale']);
        this.tickInterval = setInterval(()=>this.setText(new Date().toLocaleString(this.recipe.settings['locale'])), 1000);
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        let localeStrings = ['af-ZA','am-ET','ar-AE','ar-BH','ar-DZ','ar-EG','ar-IQ','ar-JO','ar-KW','ar-LB','ar-LY','ar-MA','arn-CL','ar-OM','ar-QA','ar-SA','ar-SD','ar-SY','ar-TN','ar-YE','as-IN','az-az','az-Cyrl-AZ','az-Latn-AZ','ba-RU','be-BY','bg-BG','bn-BD','bn-IN','bo-CN','br-FR','bs-Cyrl-BA','bs-Latn-BA','ca-ES','co-FR','cs-CZ','cy-GB','da-DK','de-AT','de-CH','de-DE','de-LI','de-LU','dsb-DE','dv-MV','el-CY','el-GR','en-029','en-AU','en-BZ','en-CA','en-cb','en-GB','en-IE','en-IN','en-JM','en-MT','en-MY','en-NZ','en-PH','en-SG','en-TT','en-US','en-ZA','en-ZW','es-AR','es-BO','es-CL','es-CO','es-CR','es-DO','es-EC','es-ES','es-GT','es-HN','es-MX','es-NI','es-PA','es-PE','es-PR','es-PY','es-SV','es-US','es-UY','es-VE','et-EE','eu-ES','fa-IR','fi-FI','fil-PH','fo-FO','fr-BE','fr-CA','fr-CH','fr-FR','fr-LU','fr-MC','fy-NL','ga-IE','gd-GB','gd-ie','gl-ES','gsw-FR','gu-IN','ha-Latn-NG','he-IL','hi-IN','hr-BA','hr-HR','hsb-DE','hu-HU','hy-AM','id-ID','ig-NG','ii-CN','in-ID','is-IS','it-CH','it-IT','iu-Cans-CA','iu-Latn-CA','iw-IL','ja-JP','ka-GE','kk-KZ','kl-GL','km-KH','kn-IN','kok-IN','ko-KR','ky-KG','lb-LU','lo-LA','lt-LT','lv-LV','mi-NZ','mk-MK','ml-IN','mn-MN','mn-Mong-CN','moh-CA','mr-IN','ms-BN','ms-MY','mt-MT','nb-NO','ne-NP','nl-BE','nl-NL','nn-NO','no-no','nso-ZA','oc-FR','or-IN','pa-IN','pl-PL','prs-AF','ps-AF','pt-BR','pt-PT','qut-GT','quz-BO','quz-EC','quz-PE','rm-CH','ro-mo','ro-RO','ru-mo','ru-RU','rw-RW','sah-RU','sa-IN','se-FI','se-NO','se-SE','si-LK','sk-SK','sl-SI','sma-NO','sma-SE','smj-NO','smj-SE','smn-FI','sms-FI','sq-AL','sr-BA','sr-CS','sr-Cyrl-BA','sr-Cyrl-CS','sr-Cyrl-ME','sr-Cyrl-RS','sr-Latn-BA','sr-Latn-CS','sr-Latn-ME','sr-Latn-RS','sr-ME','sr-RS','sr-sp','sv-FI','sv-SE','sw-KE','syr-SY','ta-IN','te-IN','tg-Cyrl-TJ','th-TH','tk-TM','tlh-QS','tn-ZA','tr-TR','tt-RU','tzm-Latn-DZ','ug-CN','uk-UA','ur-PK','uz-Cyrl-UZ','uz-Latn-UZ','uz-uz','vi-VN','wo-SN','xh-ZA','yo-NG','zh-CN','zh-HK','zh-MO','zh-SG','zh-TW','zu-ZA'];
        this.createSettingSelectInput('locale', 'Format Locale: ', localeStrings, localeStrings,  navigator.language);
    }
}

export class TimeGizmo extends TextGizmo {
    tickInterval: NodeJS.Timeout;
    public connectedCallback(): void {
        super.connectedCallback();
        this.wrapper.textContent = new Date().toLocaleString(this.recipe.settings['locale']);
        this.tickInterval = setInterval(()=>this.setText(new Date().toLocaleString(this.recipe.settings['locale'])), 1000);
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        let localeStrings = ['af-ZA','am-ET','ar-AE','ar-BH','ar-DZ','ar-EG','ar-IQ','ar-JO','ar-KW','ar-LB','ar-LY','ar-MA','arn-CL','ar-OM','ar-QA','ar-SA','ar-SD','ar-SY','ar-TN','ar-YE','as-IN','az-az','az-Cyrl-AZ','az-Latn-AZ','ba-RU','be-BY','bg-BG','bn-BD','bn-IN','bo-CN','br-FR','bs-Cyrl-BA','bs-Latn-BA','ca-ES','co-FR','cs-CZ','cy-GB','da-DK','de-AT','de-CH','de-DE','de-LI','de-LU','dsb-DE','dv-MV','el-CY','el-GR','en-029','en-AU','en-BZ','en-CA','en-cb','en-GB','en-IE','en-IN','en-JM','en-MT','en-MY','en-NZ','en-PH','en-SG','en-TT','en-US','en-ZA','en-ZW','es-AR','es-BO','es-CL','es-CO','es-CR','es-DO','es-EC','es-ES','es-GT','es-HN','es-MX','es-NI','es-PA','es-PE','es-PR','es-PY','es-SV','es-US','es-UY','es-VE','et-EE','eu-ES','fa-IR','fi-FI','fil-PH','fo-FO','fr-BE','fr-CA','fr-CH','fr-FR','fr-LU','fr-MC','fy-NL','ga-IE','gd-GB','gd-ie','gl-ES','gsw-FR','gu-IN','ha-Latn-NG','he-IL','hi-IN','hr-BA','hr-HR','hsb-DE','hu-HU','hy-AM','id-ID','ig-NG','ii-CN','in-ID','is-IS','it-CH','it-IT','iu-Cans-CA','iu-Latn-CA','iw-IL','ja-JP','ka-GE','kk-KZ','kl-GL','km-KH','kn-IN','kok-IN','ko-KR','ky-KG','lb-LU','lo-LA','lt-LT','lv-LV','mi-NZ','mk-MK','ml-IN','mn-MN','mn-Mong-CN','moh-CA','mr-IN','ms-BN','ms-MY','mt-MT','nb-NO','ne-NP','nl-BE','nl-NL','nn-NO','no-no','nso-ZA','oc-FR','or-IN','pa-IN','pl-PL','prs-AF','ps-AF','pt-BR','pt-PT','qut-GT','quz-BO','quz-EC','quz-PE','rm-CH','ro-mo','ro-RO','ru-mo','ru-RU','rw-RW','sah-RU','sa-IN','se-FI','se-NO','se-SE','si-LK','sk-SK','sl-SI','sma-NO','sma-SE','smj-NO','smj-SE','smn-FI','sms-FI','sq-AL','sr-BA','sr-CS','sr-Cyrl-BA','sr-Cyrl-CS','sr-Cyrl-ME','sr-Cyrl-RS','sr-Latn-BA','sr-Latn-CS','sr-Latn-ME','sr-Latn-RS','sr-ME','sr-RS','sr-sp','sv-FI','sv-SE','sw-KE','syr-SY','ta-IN','te-IN','tg-Cyrl-TJ','th-TH','tk-TM','tlh-QS','tn-ZA','tr-TR','tt-RU','tzm-Latn-DZ','ug-CN','uk-UA','ur-PK','uz-Cyrl-UZ','uz-Latn-UZ','uz-uz','vi-VN','wo-SN','xh-ZA','yo-NG','zh-CN','zh-HK','zh-MO','zh-SG','zh-TW','zu-ZA'];
        this.createSettingSelectInput('locale', 'Format Locale: ', localeStrings, localeStrings,  navigator.language);
    }
}

export class CustomTextGizmo extends TextGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.TYPOGRAPHY, StyleCategories.SPACING];
        this.wrapper.removeChildren();
        this.wrapper.textContent = this.recipe.settings['content'] ?? 'Default Text';
    };

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        let contentSection  = this.createSection(editor.toolTabs.getSectionByName('Settings'),'Content');
        let textInput       = createElement('input', '', contentSection, '', {'type':'text'});
        textInput.value     = this.recipe.settings['content'] ?? '';
        textInput.oninput   = () => {
            this.recipe.settings['content'] = textInput.value;
            this.setText(textInput.value);
        }
    }
};

export class SwitchGizmo extends LiveDataGizmo {
    socket: TagSocket;
    toggle: ToggleSwitch;
    public connectedCallback(): void {
        super.connectedCallback();
        this.wrapper = createElement('div', '', this);
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.socket = new TagSocket(owner.ldc, 'Toggle Input Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_BOOL], true, false)], [], false);
        this.sockets.set('Toggle Input Tags', this.socket);
        this.socket.refreshTags();
    }
    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingTextInput('trueText', 'On Text:', 'On', 'Update this');
        this.createSettingTextInput('falseText', 'Off Text:', 'Off', 'Update this');
    }
    rebuild() {
        let [tag] = this.socket.tags;
        if (!tag)
            return
        this.hideWarning();
        this.toggle = createElement('se-tag-toggle-switch', '', this.wrapper, '', {toggleTag: {tag: tag}, onText: this.recipe.settings['trueText'] ?? 'On', offText: this.recipe.settings['falseText'] ?? 'Off'})
    }
}

export class RadioGizmo extends LiveDataGizmo {
    socket: TagSocket;
    radio: RadioSelector;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.POSITION, StyleCategories.BACKGROUND, StyleCategories.BORDER];
        this.wrapper = createElement('div', '', this);
        if (!this.recipe.settings['Radios']) {
            this.recipe.settings['Radios'] = [
                { name: 'Off',  value: 0 },
                { name: 'On',   value: 1 },
            ]
        }
        this.buildRadios();
        this.socket = new TagSocket(owner.ldc, 'HOA Input Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], true, false)], [], true);
        this.sockets.set('HOA Input Tags', this.socket);
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        //this.createSizeSettings(editor.toolTabs.getSectionByName('Style'));
        this.createRadioSettings(editor.toolTabs.getSectionByName('Settings'))
    }

    createRadioSettings(parent: HTMLElement) {
        let wrapper             = this.createSection(parent,'Options')
        let optionWrapper       = createElement('div', 'option__range__wrapper', wrapper)
        let rangeWrapper        = createElement('div', 'socket__range', optionWrapper);
        let nameColumn          = createElement('div', 'socket__range__column', rangeWrapper);
        let inputColumn         = createElement('div', 'socket__range__column', rangeWrapper);
        let addButton           = createElement('div', 'socket__add__button', optionWrapper);
        addButton.style.width   = '100%';
        createElement('div', 'socket__add__button__text', addButton, 'Add Option');
        createElement('img', 'socket__add__button__icon', addButton, undefined, {'src':AddIcon});
        let radios = this.recipe.settings['Radios'];
        for (let i=0;i<radios.length;++i) {
            this.addRadio(i, nameColumn, inputColumn);
        }

        addButton.onclick = () => {
            radios.push({
                name: 'Default',
                value: radios[radios.length - 1].value + 1,
            });
            this.addRadio(radios.length - 1, nameColumn, inputColumn);
            this.rebuild();
        }
    }

    addRadio(i: number, nameColumn: HTMLElement, inputColumn: HTMLElement) {
        let radios = this.recipe.settings['Radios'];
        let radioName   = createElement('input', 'dwsetting__range__name', nameColumn);
        radioName.value = radios[i].name;
        radioName.onchange = () => {
            this.recipe.settings['Radios'][i].name = radioName.value as string;
            this.rebuild();
        }
        let rangeInput  = createElement('input', 'dwsetting__range__value', inputColumn, undefined, {   'autocomplete'     : 'off',
                                                                                                        'autocapitalize'   : 'off',
                                                                                                        'type'             : 'number'});	// Create the input element
        rangeInput.value = radios[i].value;
        rangeInput.onchange = () => {
            this.recipe.settings['Radios'][i].value = parseFloat(rangeInput.value);
            this.rebuild();
        }
    }

    update(node: Node): void {
        if (!this.socket.tags.has(node))
            return;
        if (node.quality != TagQuality.TQ_GOOD) {
            this.showWarning();
            return;
        }
        this.hideWarning();
        this.radio.select(node.getValue(this), false);
    }

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        if (tag) {
            tag.subscribe(this);
        }
    }

    buildRadios() {
        let buttons: RadioButton[] = [];
        this.recipe.settings['Radios'] && this.recipe.settings['Radios'].forEach(radio => {
            buttons.push({
                name: radio.value,
                displayName: radio.name,
            })
        });
        this.radio = new RadioSelector(this.wrapper, {
            buttons: buttons,
            changeCallback: (selection) => {
                let [tag] = this.socket.tags
                if (tag)
                    owner.settingsManager.pushPending(tag, selection, this);
            }
        })
    }

    rebuild() {

    }
}

export class HOAGizmo extends LiveDataGizmo {
    socket: TagSocket;
    radioID: string;
    radioMap: Map<string, Node> = new Map();
    inputMap: Map<string, HTMLInputElement> = new Map();
    nodeManager: NodeManager = new NodeManager(this);
    implicitOffID: string;
    public connectedCallback(): void {
        super.connectedCallback();
        this.radioID = createUniqueId();
        this.allowableStyles    = [StyleCategories.POSITION, StyleCategories.BACKGROUND, StyleCategories.BORDER];
        this.wrapper = createElement('div', 'flex__row', this);
        this.socket = new TagSocket(owner.ldc, 'HOA Boolean Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_BOOL], true, false)], [], true, [SerializedTagCategories.HOA]);
        this.sockets.set('HOA Boolean Tags', this.socket);
        if (!this.recipe.settings['offButtonIndex'])
            this.recipe.settings['offButtonIndex'] = 1;
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingToggle('offBit', 'All Off Button: ');
        this.createSettingNumberInput('offButtonIndex', 'Off Button Index: ', 1);
    }

    update(node: Node) {
        this.hideWarning();
        for (let [id, tag] of this.radioMap) {
            if (tag === node) {
                let input = this.inputMap.get(id)!;
                input.checked = !!node.getValue(this);
            }
        }
        if (this.recipe.settings['offBit'] ) {
            let anyOn = false;
            for (let [id, tag] of this.radioMap) {
                if (this.inputMap.get(id)?.checked) {
                    anyOn = true;
                    break;
                }
            }
            if (!anyOn)
                this.inputMap.get(this.implicitOffID)!.checked = true;
        }
    }

    rebuild() {
        this.nodeManager.destroy();
        this.nodeManager = new NodeManager(this);
        this.radioMap.clear();
        this.wrapper.removeChildren();
        let fWriteable = Array.from(this.socket.tags).every(tag => tag.couldBeWritten());
        // Set up a button for each label:
        let tags = Array.from(this.socket.tags);
        let fCreatedOff = false;
        for (let i=0;i<tags.length;++i) {
            if (this.recipe.settings['offButtonIndex'] && this.recipe.settings['offButtonIndex'] == i) {
                this.createInput(undefined, fWriteable);
                fCreatedOff = true;
            }
            this.nodeManager.addNode(tags[i])
            this.createInput(tags[i], fWriteable);
		}
        if (this.recipe.settings['offBit'] && this.recipe.settings['offButtonIndex'] && !fCreatedOff)
            this.createInput(undefined, fWriteable);
        this.nodeManager.subscribe();
    }

    createInput(tag: Node | undefined, fWriteable: boolean) {
        let name = 'Off';
        let buttonID = createUniqueId();
        if (tag) {
            let serializedTag = this.socket.tagSerializedTagMap.get(tag)!;
            name = serializedTag.settings?.name ?? tag.getDisplayName();
            this.radioMap.set(buttonID, tag);
        }
        else
            this.implicitOffID = buttonID;
        let input = createElement('input', 'radio-buttons__input', this.wrapper, '', {type: 'radio', name: this.radioID, id: buttonID});
        this.inputMap.set(buttonID, input);
        let label = createElement('label', 'radioButtonLabel', this.wrapper, name, {htmlFor: buttonID});	// trim leading and trailing whitespace
        if (fWriteable) { // Not a read-only node and they have the permission to write it one day:
            label.classList.add('writeable');	// Add class for formatting, updating disabled flag based on user write permission changes
            input.onchange = () => {
                if (input.checked) {
                    for (let otherTag of this.socket.tags) {
                        if (tag !== otherTag)
                            owner.settingsManager.pushPending(otherTag, 0, this);
                    }
                    if (tag)
                        owner.settingsManager.pushPending(tag, input.checked ? 1 : 0, this);
                }
            }
        } else	// node is not writeable, so do not set the 'writeable' class, and disable the button:
            input.disabled = true;
    }
}

export class PushButtonGizmo extends LiveDataGizmo {
    socket: TagSocket;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.BACKGROUND, StyleCategories.LAYOUT, StyleCategories.TYPOGRAPHY, StyleCategories.BORDER, StyleCategories.SPACING];

        this.wrapper = createElement('div', 'flex__row justify__center align__center', this);
        this.createButton();

        this.socket = new TagSocket(owner.ldc, 'Push Button Input Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_BOOL], true, false)], [], true);
        this.sockets.set('Push Button Input Tags', this.socket);
        this.socket.refreshTags();
    }
    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingTextInput('text', 'Button Text:');
        this.createSettingToggle('fFlip', 'Flip Setting: ')
    }

    handleKeydown(e: KeyboardEvent) {
        if (e.key === 'enter' || e.key === 'space') {
            e.preventDefault();
            this.onClick();
        }
    }

    createButton() {
        this.wrapper.textContent = this.recipe.settings['text'] ?? 'Default';
        this.wrapper.setAttribute('role', 'button');
        this.wrapper.setAttribute('aria-label', 'push');
        this.wrapper.setAttribute('tabIndex','0');
        this.wrapper.onkeydown = (e) => this.handleKeydown(e);
        this.wrapper.onclick = () => this.onClick();
    }

    onClick() {
        let [tag] = this.socket.tags;
        if (tag && this.wrapper.getAttribute('disabled') == 'false') {
            if (owner.settingsManager) {
                owner.settingsManager.pushPending(tag, this.recipe.settings['fFlip'] ? 0 : 1, this);
            }
        }
    }

    update(node: Node): void {
        if (!this.socket.tags.has(node))
            return;
        if (node.quality != TagQuality.TQ_GOOD) {
            this.wrapper.setAttribute('disabled', 'true');
            this.showWarning();
            return;
        }
        this.hideWarning();
        this.wrapper.setAttribute('disabled', ((this.recipe.settings['fFlip'] && node.getValue() == 0) || (!this.recipe.settings['fFlip'] && node.getValue() == 1)) ? 'true' : 'false')
        this.onclick = () => this.onClick();
    }

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        if (tag) {
            tag.subscribe(this);
        }
    }

    rebuild() {
        this.createButton();
        let [tag] = this.socket.tags;
        if (tag) {
            this.update(tag);
        }
    }
}

export class SetpointGizmo extends LiveDataGizmo {
    socket: TagSocket;
    nodeManager: NodeManager = new NodeManager(this);
    input: HTMLInputElement;
    unitsWrapper: HTMLElement;
    public connectedCallback(): void {
        super.connectedCallback();
        this.wrapper = createElement('div', 'flex__row full__width align__center full__height', this);
        this.buildInput();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.BORDER, StyleCategories.BACKGROUND, StyleCategories.TYPOGRAPHY]
        this.socket = new TagSocket(owner.ldc, 'Setpoint Input Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], undefined, true, false)], [], true, []);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingToggle('fUnits', 'Show Units:');
    }

    buildInput() {
        this.input  = createElement('input', '', this.wrapper, '', {type: 'number'});
        this.input.style.flex = '1';
        this.input.disabled = true;
        this.unitsWrapper = createElement('div', '', this);
        this.unitsWrapper.style.width = '48px';
        this.input.onchange = () => {
            if (!this.input.disabled) {
                let [tag] = this.socket.tags;
                if (!tag)
                    return;
                owner.settingsManager.pushPending(tag, parseFloat(this.input.value), this)
            }
        }
    }

    update(node: Node) {
        if (!this.socket.tags.has(node))
            return;
        if (node.quality != TagQuality.TQ_GOOD) {
            this.input.disabled = true;
            this.showWarning();
            return;
        }
        this.hideWarning();
        this.input.disabled = false;
        this.input.value = node.getFormattedTextFromValue(node.getValue(this), false);
        this.unitsWrapper.textContent = this.recipe.settings['fUnits'] ? UnitsMap.get(node.units)!.abbrev : null;
    }

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        if (tag) {
            this.input.step = this.recipe.settings['step'] ?? tag.resolution;
            tag.subscribe(this);
        }
    }

    rebuild(): void {
        let [tag] = this.socket.tags;
        this.unitsWrapper.textContent = tag && this.recipe.settings['fUnits'] ? UnitsMap.get(tag.units)!.abbrev : null;
    }
}

export class SliderGizmo extends LiveDataGizmo {
    socket: TagSocket;
    nodeManager: NodeManager = new NodeManager(this);
    slider: SimpleSlider;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.BORDER, StyleCategories.BACKGROUND, StyleCategories.TYPOGRAPHY]
        this.socket = new TagSocket(owner.ldc, 'Slider Tags', this, [new SettingsFilter(true, false), new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], undefined, true, false), new MinMaxFilter()], [], true, []);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
    }

    onResize(): void {
        if (this.slider)
            this.slider.updateSliders();
    }

    rebuild() {
        this.nodeManager.destroy();
        let tags = Array.from(this.socket.tags);
        if (tags.length < 1)
            return;
        let tag = tags[0];
        this.slider = new SimpleSlider(this, tag.engMin, tag.engMax, tag.resolution, undefined, (value)=> {
            console.log(value);
        })
    }
}
export class GenericGraphGizmo extends LiveDataGizmo {
    graphWrapper: HTMLElement;
    graph: DragDropGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    socket: TagSocket;
    noTagsWrapper: HTMLElement;
    socketID: string = 'Live Data Tags';
    public connectedCallback(): void {
        super.connectedCallback();
        this.graphWrapper       = createElement('div', 'chart-gizmo__wrapper', this);
        this.controlRow         = createElement('div', 'chart-gizmo__controls', this.graphWrapper);
        this.graphRow           = createElement('div', 'chart-gizmo__graph', this.graphWrapper);
        this.legendRow          = createElement('div', 'chart-gizmo__legend', this.graphWrapper);
        this.socket             = new TagSocket(owner.ldc, this.socketID, this, [new LoggedFilter(true, false, true)], [], true, [SerializedTagCategories.CHART]);
        this.sockets.set(this.socket.name, this.socket);
        this.allowableStyles    = [StyleCategories.POSITION, StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.SPACING];
        if (this.recipe.tags.length == 0)   // If we don'r have any tags yet
            this.rebuild();                 // Just build an empty graph
        else
            this.socket.refreshTags();
    }

    addOverlayElements(parent: HTMLElement): void {
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '300px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingToggle('fInteractive', 'Make Interactive: ');
        this.createSettingToggle('fDates', 'Date Selection: ');
        this.createSettingToggle('fHighlight', 'Highlight on hover: ', true);
        this.createSettingToggle('fLegend', 'Legend: ', true);
        this.createSettingSelectInput('range', 'Default Range: ', ['Hour','Day','Week','Year'], ['3600000','86400000','604800000','31540000000'], '86400000');
    }

    rebuild() {
        this.hideWarning();
        if (this.editor)
            this.editor.overlay.refresh()
        if (this.graph)
            this.graph.destroy();
        var end = new Date();
        let start: Date = new Date(new Date().getTime() - ('range' in this.recipe.settings ? parseInt(this.recipe.settings['range']) : 86400000));
        let fHighlight = this.recipe.settings['fHighlight'] ?? true

        this.controlRow.style.display = this.recipe.settings['fDates'] ? '' : 'none';
        this.legendRow.style.display = (this.recipe.settings['fLegend'] ?? true) ? 'flex' : 'none';
        this.graph = new DragDropGraph(owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight, start, end, this.recipe.settings['fInteractive'] ?? false, this.recipe.settings['fDates'] ?? false, this.recipe.settings['fLegend'] ?? true, this.controlRow, this.legendRow, {
            stophighlighting: !fHighlight,
        });
        this.addTags();
        this.graph.requestDataForAllDevices(start.getTime(), end.getTime());
        this.onResize();
    }

    onTreeComplete(socketName: string): void {
        assert(socketName == this.socketID);
        if (!this.graph)
            this.rebuild();
        this.addTags();
    }

    addTags() {
        for (let tag of this.socket.tags) {
            let serializedTag = this.socket.tagSerializedTagMap.get(tag)!;
            let max: number | null = null;
            let min: number | null = null;
            if (typeof serializedTag.settings?.max != 'undefined')
                max = parseFloat(serializedTag.settings?.max);
            if (typeof serializedTag.settings?.min != 'undefined')
                min = parseFloat(serializedTag.settings?.min);
            let invalidMinMax = min !== null && max !== null && min == max;
            this.graph.addNode(tag, true, serializedTag.settings?.name, serializedTag.settings?.color, true, invalidMinMax ? null : max, invalidMinMax ? null : min);
        }
    }

    onTagsRemoved(socket: TagSocket): void {
        this.rebuild();
    }

    onStyleDidChange(): void {
        this.onResize();
    }

    onResize() {
        if (this.graph && this.graph.graph) {
            this.graph.resize(this.graphRow.clientWidth, this.graphRow.clientHeight);
        }
    }
}

export interface DataRange {
    name: string;
    value: number;
    color: string;
}
export class ImageGizmo extends LiveDataGizmo {
    image: HTMLImageElement;
    graphID: number;
    pendingFile: File;
    socket: TagSocket;
    nodeManager: NodeManager;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.DIMENSIONS];
        this.graphID        = owner.ldc.registerGraph(this);
        this.image      = createElement('img', `image-gizmo__image`, this);
        if ('Image' in this.recipe.settings) {
            if (this.dashboard.assets.has(this.recipe.settings['Image'])) {
                this.image.onerror  = () => {
                    this.image.onerror = () =>this.onLoadFailed();     // don't want to get stuck in a loop of errors if this also fails
                    this.getLink()
                }
                this.image.src = this.dashboard.assets.get(this.recipe.settings['Image'])!;
            }
            else
                this.getLink();
        }
        this.hideWarning();
        this.socket         = new TagSocket(owner.ldc, 'Visibility Toggle', this, [new VTypeFilter([VType.VT_BOOL], true, false)], [], false, [SerializedTagCategories.VISIBILITY]);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        let contentSection  = this.createSection(editor.toolTabs.getSectionByName('Settings'),'Content');
        let seeImages = createElement('button', 'se-button', editor.toolTabs.getSectionByName('Settings'), 'Select Image');
        seeImages.onclick = () => {
            new ViewModal(new ImageBrowserView('/SE_Dashboard/', (uuid: string) => this.onUUIDChanged(uuid)), {
                maxWidth: '600px',
				title: 'Image Browser',
				titleTextColor: 'var(--color-inverseOnSurface)',
				titleBackgroundColor: 'var(--color-primary)',
            });
        }
    }

    onUUIDChanged(uuid: string) {
        this.recipe.settings['Image'] = uuid;
        this.getLink();
    }

    getLink() {
        owner.ldc.fm.buildFrame(LiveData.WVC_GET_DOWNLOAD_URL, undefined, this.graphID);
        owner.ldc.fm.push_string(this.recipe.settings['Image']);
        owner.ldc.fm.push_string(owner.ldc.user.companyKey);
        owner.ldc.send();										// Send the frame
    }

    onGetDownloadURLResponse(fp: FrameParser) {
        let success = fp.pop_u8();
        if (success) {
            let url         = fp.pop_string();
            this.image.src  = url;
            this.dashboard.assets.set(this.recipe.settings['Image'], url);
        }
    }

    update(node: Node) {
        if (!this.socket.tags.has(node))
            return;
        if (node.quality != TagQuality.TQ_GOOD) {
            this.showWarning();
            return;
        }
        let serializedTag = this.findSerializedTag(node, this.socket.name);
        if (!serializedTag)
            return;
        this.hideWarning();
        let hideWhen = serializedTag.settings ? serializedTag.settings['hideWhen'] : 0;
        this.image.classList.toggle('hide', node.getValue() == hideWhen);
    }

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        if (tag)
            tag.subscribe(this);
    }

    onLoadFailed() {

    }

    refresh() {
        owner.ldc.fm.buildFrame(LiveData.WVC_GET_DOWNLOAD_URL, undefined, this.graphID);
        owner.ldc.fm.push_string(this.recipe.settings['Image']);
        owner.ldc.fm.push_string(owner.ldc.user.companyKey);
        owner.ldc.send();										// Send the frame
    }
}

export class ColorStatusGizmo extends LiveDataGizmo {
    socket: TagSocket;
    value: ValueDisplay;
    nodeManager: NodeManager;
	label: HTMLDivElement;
    public connectedCallback(): void {
        super.connectedCallback();
        this.label 			= createElement('div', 'full__width', this, this.recipe.settings['label']);
        this.wrapper        = createElement('div', 'full__width full__height', this);
        this.allowableStyles = [StyleCategories.DIMENSIONS, StyleCategories.BORDER, StyleCategories.POSITION, StyleCategories.BACKGROUND];
        if (!('VMaps' in this.recipe.settings)) {
            this.recipe.settings['VMaps'] = [{
                value: 0,
                text: '#ff0000'
            },
            {
                value: 1,
                text: '#ff0000'
            }]
        }
        this.nodeManager = new NodeManager(this);

        this.socket = new TagSocket(owner.ldc, 'Live Value Tags', this, [], [], true);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
    }
    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createSettingToggle('fUnits', 'Show Units:');
        this.createMapSettings(editor.toolTabs.getSectionByName('Settings'), 'Value Overrides', 'Add Override', true);

		// Label section
		let contentSection  = this.createSection(editor.toolTabs.getSectionByName('Settings'), 'Label');
        let textInput       = createElement('input', '', contentSection, '', {'type':'text'});
        textInput.value     = this.recipe.settings['label'] ?? '';
        textInput.oninput   = () => {
            this.recipe.settings['label'] = textInput.value;
            this.setLabel(textInput.value);
        }
    }

	setLabel(newLabel: string) {
		this.label.innerText = newLabel;
	}

    onTreeComplete(socketName: string): void {
        let [tag] = this.socket.tags;
        assert(tag);
        this.nodeManager.addNode(tag);
        this.nodeManager.subscribe();
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '54px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '54px');
        }
    }

    update(node: Node) {
        this.hideWarning();
        let value = node.getValue();
        this.wrapper.style.backgroundColor = '';
        for (let override of this.recipe.settings['VMaps']) {
            if (override.value == value) {
                this.wrapper.style.backgroundColor = override.text;
            }
        }
    }

    rebuild() {
        let [tag] = this.socket.tags
        if (tag)
            this.update(tag);
    }
}

export class GaugeGizmo extends LiveDataGizmo {
    socket: TagSocket;
    gauge: RadialGauge;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.SPACING, StyleCategories.TYPOGRAPHY];
        if (!this.recipe.settings['Ranges']) {
            this.recipe.settings['Ranges'] = [{
                name: 'Default',
                value: 100,
                color: '#f3523f'
            }]
        }
        if (!this.recipe.settings['Minimum'])
            this.recipe.settings['Minimum'] = 0;
        this.wrapper = createElement('div', 'gauge-gizmo__wrapper', this);
        this.wrapper.style.width = '100%';
        this.wrapper.style.height = '100%';
        this.socket = new TagSocket(owner.ldc, 'Gauge Input Tag', this, [new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], true, false)], [], true);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
    }

    populateSettings(editor: EditorPage) {
        super.populateSettings(editor);
        this.createRangeSettings(editor.toolTabs.getSectionByName('Settings'))
    }

    hex2rgba(hex: string, alpha = 1) : string{
        const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    };

    update(node: Node): void {
        if (!this.socket.tags.has(node))
            return;
        if (node.quality != TagQuality.TQ_GOOD) {
            this.showWarning();
            return;
        }
        this.hideWarning();
        if (this.gauge) {
            this.gauge.value = node.getValue();
            this.gauge.valueText = node.getFormattedText(false);
        }

    }

    buildGauge(tag: Node) {
        this.wrapper.removeChildren();
        let rangeZones: any[] = [];
        let majorTicks: string[] = [this.recipe.settings['Minimum'] + ''];
        let ranges = this.recipe.settings['Ranges'];
        if (ranges)
            for (let i=0;i<ranges.length;++i) {
                majorTicks.push(ranges[i].value + '')
                rangeZones.push({
                    color: this.hex2rgba(ranges[i].color),
                    from: i == 0 ? this.recipe.settings['Minimum'] : ranges[i-1].value,
                    to: ranges[i].value
                })
            }
        let canvas = createElement('canvas', 'gauge-gizmo__canvas', this.wrapper, '', {'width':this.wrapper.clientWidth, 'height':this.wrapper.clientHeight});
        this.gauge = new RadialGauge({
            renderTo: canvas,
            width: this.wrapper.clientWidth,
            height: this.wrapper.clientHeight,
            title: false,
            minValue: this.recipe.settings['Minimum'],
            maxValue: ranges[ranges.length - 1].value,
            exactTicks: true,
            majorTicks: majorTicks,
            minorTicks: 2,
            strokeTicks: false,
            highlights: rangeZones,
            colorMajorTicks: '#f5f5f5',
            colorMinorTicks: '#ddd',
            colorTitle: '#fff',
            colorNeedle: 'rgba(240, 128, 128, 1)',
            needleShadow: false,
            valueBox: true,
            animationRule: 'bounce',
            animationDuration: 500,
            borders: true,
            borderMiddleWidth: 0,
            borderInnerWidth: 0,
            colorValueTextShadow: false,
            colorUnits:owner.colors.hex('--color-onSurface'),
            colorNumbers: owner.colors.hex('--color-onSurface'),
            colorValueBoxRect: 'rgba(0,0,0,0)',
            colorValueBoxRectEnd: 'rgba(0,0,0,0)',
            colorValueBoxBackground: 'rgba(0,0,0,0)',
            colorValueBoxShadow: 'rgba(0,0,0,0)',
            units: tag.getUnitsText(),
            valueInt: 0,
            valueDec: tag.digits,
            fontValueSize: 42,
            borderShadowWidth: 0,
        });
        this.gauge.draw();
    }

    rebuild(): void {
        this.showWarning();
        let [tag] = this.socket.tags;
        if (tag) {
            this.buildGauge(tag);
            this.update(tag);
        }
    }

    onResize(): void {
        this.rebuild();
    }

    onTreeComplete(socketName: string): void {
        assert(socketName === 'Gauge Input Tag');
        let [tag] = this.socket.tags;
        if (tag) {
            if (this.gauge)
                this.gauge.units = UnitsMap.get(tag.units)?.abbrev;
            else
                this.buildGauge(tag);
            tag.subscribe(this);
        }
    }

    disconnectedCallback() {
        let [tag] = this.socket.tags;
        if (tag)
            tag.unsubscribe(this);
        super.disconnectedCallback()
    }
}

export enum GizmoCategory {
    LAYOUT      = "Layout",
    BASIC       = "Basic",
    CHARTS      = "Chart",
    SETPOINTS   = "Setpoint",
    LIVE_VALUE  = "Live Value",
    ALARMS      = "Alarms",
    ANALYTICS   = "Analytics",
    NAVIGATION  = "Navigation",
}

interface GizmoInfo {
    name: string;
    tagName: string;
    icon: string;
    category: GizmoCategory;
}

export const GizmoMap: Map<GizmoType, GizmoInfo> = new Map([
    //[GizmoType.WT_BASE,             {name: 'Base',              tagName: 'gizmo-base',              icon: ContainerIcon,    category: GizmoCategory.BASIC}],
    //[GizmoType.WT_CONTAINER,        {name: 'Container',         tagName: 'gizmo-container',         icon: ContainerIcon,    category: GizmoCategory.BASIC}],
    [GizmoType.WT_CONTAINER_COL,    {name: 'Column Container',   tagName: 'gizmo-container-col',     icon: ContainerColIcon,  category: GizmoCategory.LAYOUT}], //
    [GizmoType.WT_CONTAINER_ROW,    {name: 'Row Container',      tagName: 'gizmo-container-row',     icon: ContainerRowIcon,  category: GizmoCategory.LAYOUT}],
    [GizmoType.WT_TEXT,             {name: 'Text',               tagName: 'gizmo-text',              icon: TextIcon,          category: GizmoCategory.BASIC}],
    [GizmoType.WT_IMAGE,            {name: 'Image',              tagName: 'gizmo-image',             icon: ImageIcon,         category: GizmoCategory.BASIC}],
    [GizmoType.WT_DATE,             {name: 'Date',               tagName: 'gizmo-date',              icon: DateIcon,          category: GizmoCategory.BASIC}],
    [GizmoType.WT_TIME,             {name: 'Time',               tagName: 'gizmo-time',              icon: ClockIcon,         category: GizmoCategory.BASIC}],
    [GizmoType.WT_CHART,            {name: 'Line',               tagName: 'gizmo-chart',             icon: ChartIcon,         category: GizmoCategory.CHARTS}],
    [GizmoType.WT_PROFILE_CHART,    {name: 'Stacked Bar',        tagName: 'gizmo-chart-profile',     icon: StackedBarIcon,    category: GizmoCategory.CHARTS}],
    [GizmoType.WT_BOX_PLOT,         {name: 'Box',                tagName: 'gizmo-chart-box',         icon: BoxPlotIcon,       category: GizmoCategory.CHARTS}],
    [GizmoType.WT_PIE_RANGE,        {name: 'Pie',                tagName: 'gizmo-chart-pie',         icon: PieChartIcon,      category: GizmoCategory.CHARTS}],
    [GizmoType.WT_BAR,              {name: 'Status Bar',         tagName: 'gizmo-chart-bar',         icon: StatusBarIcon,     category: GizmoCategory.CHARTS}],
    [GizmoType.WT_SE_MAP,           {name: 'SE Map',             tagName: 'gizmo-se-map',            icon: SEMapIcon,         category: GizmoCategory.CHARTS}],
    [GizmoType.WT_SWITCH,           {name: 'Switch',             tagName: 'gizmo-switch',            icon: SwitchIcon,        category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_SLIDER,           {name: 'Slider',             tagName: 'gizmo-slider',            icon: SliderIcon,        category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_SETPOINT,         {name: 'Setpoint',           tagName: 'gizmo-setpoint',          icon: ValueIcon,         category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_HOA,              {name: 'Radio Button',       tagName: 'gizmo-hoa',               icon: RadioButtonIcon,   category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_PUSH_BUTTON,      {name: 'Push Button',        tagName: 'gizmo-push-button',       icon: ButtonIcon,        category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_VALUE,            {name: 'Live Value',         tagName: 'gizmo-value',             icon: ValueIcon,         category: GizmoCategory.LIVE_VALUE}],
    [GizmoType.WT_GAUGE,            {name: 'Gauge',              tagName: 'gizmo-gauge',             icon: GaugeIcon,         category: GizmoCategory.LIVE_VALUE}],
    [GizmoType.WT_TANK,             {name: 'Tank',               tagName: 'gizmo-tank',              icon: ValueIcon,         category: GizmoCategory.LIVE_VALUE}],
    [GizmoType.WT_COLOR_STATUS,     {name: 'Color Indicator',    tagName: 'gizmo-color-status',      icon: PaletteIcon,       category: GizmoCategory.LIVE_VALUE}],
    [GizmoType.WT_ALARM_TIMELINE,   {name: 'Alarm Timeline',     tagName: 'gizmo-alarm-timeline',    icon: AlarmIcon,         category: GizmoCategory.ALARMS}],
    [GizmoType.WT_ALARM_COUNT,      {name: 'Alarm Count',        tagName: 'gizmo-alarm-count',       icon: AlarmIcon,         category: GizmoCategory.ALARMS}],
    [GizmoType.WT_MIN,              {name: 'Minimum',            tagName: 'gizmo-analytics-min',     icon: MinIcon,           category: GizmoCategory.ANALYTICS}],
    [GizmoType.WT_MAX,              {name: 'Maximum',            tagName: 'gizmo-analytics-max',     icon: MaxIcon,           category: GizmoCategory.ANALYTICS}],
    [GizmoType.WT_AVG,              {name: 'Average',            tagName: 'gizmo-analytics-avg',     icon: AvgIcon,           category: GizmoCategory.ANALYTICS}],
    [GizmoType.WT_CUM_MIN,          {name: 'Cumulative Min',     tagName: 'gizmo-analytics-cum-min', icon: MinCumulativeIcon, category: GizmoCategory.ANALYTICS}],
    [GizmoType.WT_CUM_MAX,          {name: 'Cumulative Max',     tagName: 'gizmo-analytics-cum-max', icon: MaxCumulativeIcon, category: GizmoCategory.ANALYTICS}],
    [GizmoType.WT_CUM_AVG,          {name: 'Cumulative Average', tagName: 'gizmo-analytics-cum-avg', icon: AvgCumulativeIcon, category: GizmoCategory.ANALYTICS}],
    //[GizmoType.WT_FRAME,            {name: 'Frame',              tagName: 'gizmo-frame',             icon: DateIcon,          category: GizmoCategory.BASIC}],
    [GizmoType.WT_LINK,             {name: 'Link',               tagName: 'gizmo-link',              icon: LinkIcon,          category: GizmoCategory.NAVIGATION}],
    [GizmoType.WT_HOA_BITS,         {name: 'HOA Switch',         tagName: 'gizmo-hoa-bits',          icon: SwitchIcon,        category: GizmoCategory.SETPOINTS}],
    [GizmoType.WT_DAILY_TOTALS,     {name: 'Daily Totals',       tagName: 'gizmo-daily-totals',      icon: BarChartIcon,      category: GizmoCategory.CHARTS}],
    [GizmoType.WT_PUMP_BANK,        {name: 'Pump Bank',          tagName: 'gizmo-pump-bank',         icon: PumpIcon,          category: GizmoCategory.LIVE_VALUE}]
]);
