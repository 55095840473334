import { Gizmo, GizmoType, GenericGraphGizmo, SwitchGizmo, HOAGizmo, ValueGizmo, ImageGizmo, DateGizmo, SliderGizmo, GaugeGizmo, BarGizmo, SetpointGizmo, RadioGizmo, PushButtonGizmo, GizmoMap, CustomTextGizmo, TimeGizmo, IFrameGizmo, ColorStatusGizmo } from './gizmos/gizmo';
import { DailyProfilesGizmo, BoxPlotGizmo, PieRangeGizmo, DailyTotalsGizmo } from './gizmos/historicaldatagizmos';
import { AlarmTimelineGizmo, AlarmCountGizmo } from './gizmos/alarmgizmos';
import { MinGizmo, MaxGizmo, AverageGizmo, CumulativeAverageGizmo, CumulativeMaxGizmo, CumulativeMinGizmo } from './gizmos/analyticsgizmos';
import { UnifiedSEMapGizmo } from './gizmos/unifiedsemapgizmo';
import { TankGizmo } from './gizmos/tankgizmo';
import { RowContainerGizmo, ColumnContainerGizmo } from './gizmos/containergizmos';
import { LinkGizmo } from './gizmos/navigationgizmos';
import { PumpBankGizmo } from './gizmos/pumpbankgizmo';

export function registerGizmos() {
    //@ts-ignore
    let gizmoClassMap: Map<GizmoType, typeof Gizmo> = new Map([
        //[GizmoType.WT_BASE,             Gizmo],
        //[GizmoType.WT_CONTAINER,        ContainerGizmo],
        [GizmoType.WT_TEXT,             CustomTextGizmo],
        [GizmoType.WT_CHART,            GenericGraphGizmo],
        [GizmoType.WT_SWITCH,           SwitchGizmo],
        [GizmoType.WT_VALUE,            ValueGizmo],
        [GizmoType.WT_PROFILE_CHART,    DailyProfilesGizmo],
        [GizmoType.WT_ALARM_TIMELINE,   AlarmTimelineGizmo],
        [GizmoType.WT_BOX_PLOT,         BoxPlotGizmo],
        [GizmoType.WT_IMAGE,            ImageGizmo],
        [GizmoType.WT_PIE_RANGE,        PieRangeGizmo],
        [GizmoType.WT_DATE,             DateGizmo],
        [GizmoType.WT_SLIDER,           SliderGizmo],
        [GizmoType.WT_GAUGE,            GaugeGizmo],
        [GizmoType.WT_MIN,              MinGizmo],
        [GizmoType.WT_MAX,              MaxGizmo],
        [GizmoType.WT_AVG,              AverageGizmo],
        [GizmoType.WT_CUM_MIN,          CumulativeMinGizmo],
        [GizmoType.WT_CUM_MAX,          CumulativeMaxGizmo],
        [GizmoType.WT_CUM_AVG,          CumulativeAverageGizmo],
        [GizmoType.WT_ALARM_COUNT,      AlarmCountGizmo],
        [GizmoType.WT_BAR,              BarGizmo],
        [GizmoType.WT_SE_MAP,           UnifiedSEMapGizmo],
        [GizmoType.WT_SETPOINT,         SetpointGizmo],
        [GizmoType.WT_HOA,              RadioGizmo],
        [GizmoType.WT_PUSH_BUTTON,      PushButtonGizmo],
        [GizmoType.WT_TANK,             TankGizmo],
        [GizmoType.WT_TIME,             TimeGizmo],
        [GizmoType.WT_CONTAINER_COL,    ColumnContainerGizmo],
        [GizmoType.WT_CONTAINER_ROW,    RowContainerGizmo],
        [GizmoType.WT_LINK,             LinkGizmo],
        [GizmoType.WT_COLOR_STATUS,     ColorStatusGizmo],
        [GizmoType.WT_HOA_BITS,         HOAGizmo],
        [GizmoType.WT_DAILY_TOTALS,     DailyTotalsGizmo],
        [GizmoType.WT_PUMP_BANK,        PumpBankGizmo]
    ]);

    for (let [type, gizmoClass] of gizmoClassMap) {
        customElements.define(GizmoMap.get(type)!.tagName, gizmoClass)
    }
}