import { createElement } from "../elements";
import Card from "./card";
import owner from "../../owner";
import { Chart } from 'chart.js'

export default class DeviceStatusCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 600,
                height: 350,
                minWidth: 300,
                minHeight: 300,
                type: 'devicestatus',
            }
        };
        super(parent, editor, props);
        let devices = owner.sortedDevices;
        this.element.ctx = createElement('canvas', 'card__historical__ctx', this.element);
        let deviceData = [0,0,0]
        for (let i=0;i<devices.length;i++) {
            if (!devices[i]._connected)
                deviceData[0]++
            else if (devices[i].alarms.active > 0)
                deviceData[1]++
            else
                deviceData[2]++
        }

        let doughnutChart = new Chart(this.element.ctx, {
            type: 'doughnut',
            data: {
                labels: ["Disconnected", "Active Alarm", "Nominal"],
                datasets: [{
                  backgroundColor: [
                    owner.colors.hex('--color-onSurfaceVariant'),
                    owner.colors.hex('--color-alarm-1'),
                    owner.colors.hex('--color-green-8'),
                  ],
                  data: deviceData
                }]
              }
            //options: options
        });
    }
}
