import createSVGElement from '../svgelements';
import { NodeQuality } from '../node';
import GenericFlower from './genericflower';
import { TagUnitQuantity } from '../widgets/lib/tagunits';

// The Valve driver is designed specifically for valves and nothing else. You might want to check out other things
// inheriting from GenericFlower like the Blower driver or the Pump driver
// Necessary attributes:
//      data-se-node="VALVE'S/NODE/PATH"
//      data-se-lens="valve"
//
// Other assumptions: Assumes that the element that has data-se-lens="valve" is an SVG rect element.
// Otherwise, this will likely break.
//
// Supplemental attributes:
//		data-se-direction="left|right|up|down"			defines what direction the valve should be pointing in
//
//		data-se-isomer="cw|ccw"							defines the isomer (i.e. should the valve's spout be
//															just clockwise or just counter clockwise of the
//															direction mentioned
//
//		data-se-text-offset="0|1|2|3"					defines how many quarter turn offsets the text on the
//															valve should be offset to look proper (not upside down)
//
//		data-se-node-mode="VALVE'S/STATUS/PATH"			defines what mode the valve is in (Auto or Manual)
//
//		data-se-status_[SC]="(n|a)?(Error|Off|On|OPOR)"	defines what status code SC means (whether an error, valve is
//															off, valve is on, or valve is outside of POR) and what mode
//															the valve is running in if needed (n: not in auto, a:
//															in auto). Do not need to specify the mode of the valve
//
//		data-se-status-exp_[SC]="A string of you're very own creation"
//														defines what status code SC should show to the user via a Tippy

export default class Valve extends GenericFlower {
    static attach(nodeRoot, element) {
        return new this(nodeRoot, element);
    }

    constructor(nodeRoot, element) {
        super(nodeRoot, element);
	}

	getViewBox(isVertical) {
		this.isVertical = isVertical;
		return (isVertical) ? "0 0 60 100" : "0 0 100 60";
	}

	setTextLocation(direction) {
		this.textLocation = {x: -100, y: -100 };
	}

	// Creates the valve widget and corresponding text elements in the parent SVG element
	createStuff() {
		if (this.valvPath)
			return;
		if (this.rotation !== undefined) {
			// Maybe all of this style should be in a CSS document, but no other SVG widget has a CSS document, and there isn't THAT much style here.
			this.textLocation["text-anchor"] = "middle";		// Anchor in the middle so that single digits through triple digits look normal
			let transformString = ((this.rotation %180 != 0) ? "translate(-20, 20)"  : "") + "rotate(" + this.rotation + ", 50, 30)";		// Comput the rotation of this element
			this.valvePath = createSVGElement("path", "error-state", this.parentElem, { d:"M 0 60 L 50 40 L 100 60 L 100 0 L 50 20 L 0 0 Z", "transform":transformString});
			if (this.modeNode) {
				this.textLocation.y -= 5;
			}
			this.textLocation.transform = "rotate(" + this.textRotation + ", " + this.textLocation.x + ", " + (this.textLocation.y - 10) + ")";
			this.valveText = createSVGElement("text", null, this.parentElem, this.textLocation);
			if (this.modeNode) {
				this.textLocation.y += 5;
				delete this.textLocation.transform;
				this.modeText = createSVGElement("tspan", "small", this.valveText, this.textLocation);
			}
		}
		if (this.node == undefined) {
			this.postError("Node not found: " + this.nodePaths[""], this.parentElem);
		}
		this.setNodeStatus();
	}

	get path() {
		return this.valvePath;
	}

	get text() {
		return this.valveText;
	}

	setModeStuff() {
		let node = this.modeNode;
		let type = node.units & 0xFF00;
		if (node.quality == NodeQuality.NQ_GOOD && type == TagUnitQuantity.TUQ_UNDEFINED) {
			let val = node.getValue();
			let text = "off";
			if (this.modeStatusLookup && this.modeStatusLookup[val.toString()]) {	// Use the mode lookup if one is provided
				text = this.modeStatusLookup[val.toString()];
			}
			else {
				if (node.getValue()) {			// In Auto or manual... not sure which, assume in auto
					text = "auto";
				}
				else if (this.status === 1){	// Check if the valve is running according to the status.
					text = "hand";
				}
				else if (this.status === 2) {	// Check if some error
					text = "error";
				}
				else if (this.status === 3) {	// Check if running out of POR
					text = "out of POR";
				}
				else if (this.status === 4) {	// Check if node wasn't found... Odd since we found the mode's node...
					text = "";					// maybe mis-intered node path?
				}
				// else: text remains 'off' since valve is determined not to be running and assumed to not be in auto,
				// otherwise, a value for the status code would be provided.
			}
//			if (this.modeText) {
				this.modeText.innerHTML = text;
//			}
		}
	}
};
