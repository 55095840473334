import {Widget} from './widget';
import assert from './debug';
import NodeManager from './nodemanager';
import { Node } from './node';
import {createElement} from './elements';
import './squishedcurve.css';
import ValueDisplay from './valuedisplay';
import { Device } from './device';
import { PumpTwin, PumpTwins } from './curves';
import { TagQuality } from './widgets/lib/tag';
import { Role } from './role';
import { convert, TagUnit } from './widgets/lib/tagunits';

export default class SquishedCurve extends Widget  {
    fUseNode: boolean = true;
    redXMask: 0;
    element: HTMLElement;
    pump: Node;
    modelPump: Node | null;
    device: Device;
    nodeManager: NodeManager = new NodeManager(this);
    fSimple: boolean = true;
    rootNode: Node;
    pumpSystem: Node | null;
    squishedCurve: HTMLElement;
    sliderRow: HTMLElement;
    valueRow: HTMLElement;
    valueContainer: HTMLElement;
    valueTriangle: HTMLElement;
    slider: HTMLElement;
    sliderAOR: HTMLElement;
    porMin: Node;
    porMax: Node;
    aorMin: Node;
    aorMax: Node;
    fUseAOR: Node;
    flowNode: Node;
    maxSpeed: number;
    flow: HTMLElement;
    percentBEP: Node;
    running: Node;
    bepRatio: number = 0.7;
    fullRatio: number = 1;
    bepFlow: number;
    twin: PumpTwin | null;
    sliderPOR: HTMLElement;
    valueDisplay: ValueDisplay | undefined;
    fUpdate: boolean = true;
    constructor(element: HTMLElement, pump: Node, modelPump: Node | null = null, properties?: {[key: string]: string}) {
        super();

        assert (element,    'element is a required property');
        assert (pump,       'pump is a required property');

        // Default Properties
        this.fUseNode	    = true;		// Default is to find the Percent BEP node and use it
        this.redXMask		= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)

        this.copy(properties);			// Copy properties over that can override the defaults above

        this.element    = element;
        this.pump       = pump;
        this.modelPump  = modelPump;
        this.device     = pump.tree.device;

        this.registerAsWidget(this.element);	// register this element as a widget
    }

    initialize() {

        let rootNode        = this.device.tree.nodes[0]!;
        this.pumpSystem	    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node

        this.squishedCurve  = createElement('div', 'squished-curve', this.element);
        this.sliderRow      = createElement('div', 'squished-curve__row-bottom', this.squishedCurve);
        this.valueRow       = createElement('div', 'squished-curve__row-top', this.squishedCurve);
        this.valueContainer = createElement('div', 'squished-curve__value-container', this.valueRow)
        this.valueTriangle  = createElement('div', 'squished-curve__value__triangle', this.valueContainer);
        this.slider         = createElement('div', 'squished-curve__slider',      this.sliderRow);
        this.sliderAOR      = createElement('div', 'squished-curve__slider__region aor', this.sliderRow);
        this.sliderPOR      = createElement('div', 'squished-curve__slider__region por', this.sliderRow);

        this.valueContainer.classList.add('hide');

        // Find all the nodes we need from this curve
        this.porMin		= this.nodeManager.addNodeByRole(this.pump, Role.ROLE_PUMP_MIN_BEP_RATIO);			// Find the min and max POR nodes
        this.porMax		= this.nodeManager.addNodeByRole(this.pump, Role.ROLE_PUMP_MAX_BEP_RATIO);

        if (this.pumpSystem) {
            //let maxSpeedNode = this.nodeManager.addNodeByRole(this.pumpSystem,Role.ROLE_MAX_SPEED_HZ);
            let maxSpeedNode = this.pumpSystem.findChildByRole(Role.ROLE_MAX_SPEED_HZ);
            if (maxSpeedNode)
                this.maxSpeed	=   maxSpeedNode.getValue();	// Find the max speed node's final value once
        }

        this.aorMin		= this.nodeManager.addNodeByRole(this.pump,Role.ROLE_PUMP_MIN_AOR);
        this.aorMax		= this.nodeManager.addNodeByRole(this.pump,Role.ROLE_PUMP_MAX_AOR);
        this.fUseAOR    = this.nodeManager.addNodeByName(this.pump, 'UseAOR');

        this.flowNode   = this.nodeManager.addNode(this.modelPump ? this.modelPump.findChildByRole(Role.ROLE_MODEL_PUMP_FLOW) : null);

        this.flow       = createElement('div', 'squished-curve__flow', this.sliderRow)
        if (this.flowNode)
            this.valueDisplay = new ValueDisplay(this.flow, {node:this.flowNode,fShowUnits:false, fBlankZeros:false}).initialize();

        this.percentBEP = this.nodeManager.addNode(this.modelPump ? this.modelPump.findChild('PercentBEP') : null);
        this.running    = this.nodeManager.addNodeByRole(this.pump, Role.ROLE_BOOL_RUNNING);

        this.nodeManager.subscribe();

        if (this.modelPump) {
            this.fSimple = false;
            this.device.requestPumpTwins(this);
        }
        else
            this.setBarWidths(0.7, 1.2, 0.7, 1.2, 0.5, true);
        this.update(this.running);
        return this;
    }

    update(node: Node) {	// Called by the job whenever node value/quality changes:
        assert(node);
		if (node.quality != TagQuality.TQ_GOOD)	// Don't have pump curves or bad quality
			return;

		switch(node) {
			case this.porMin:		// For either POR limit node
			case this.porMax:
			case this.aorMin:
			case this.aorMax:
			case this.fUseAOR:
				var minPOR = this.porMin.getValue();
				var maxPOR = this.porMax.getValue();
				var fAor = this.fUseAOR && this.fUseAOR.getValue();
				var minAOR = fAor ? Math.min(this.aorMin.getValue(), minPOR) : minPOR;
				var maxAOR = fAor ? Math.max(this.aorMax.getValue(), maxPOR) : maxPOR;
                this.setBarWidths(minAOR, maxAOR, minPOR, maxPOR, this.bepRatio, fAor);
            break;
            case this.flowNode:
            case this.percentBEP:
            case this.running:	// Update the indicator's position and color based on the percent of BEP flow
                if (this.fUpdate)
                    this._updateIndicator(this.running.getValue() ? this.fSimple ? 0 : this.percentBEP.getValue() : 0);
            break;
            default:
                assert(false, 'Unexpected node update');
			break;
		}
    };

    setBarWidths(aorMin: number, aorMax: number, porMin: number, porMax: number, bepRatio: number, fAor: boolean) {
        let minAOR = fAor ? Math.min(aorMin, porMin) : porMin;
        let maxAOR = fAor ? Math.max(aorMax, porMax) : porMax;
        this.sliderPOR.style.width  = (porMax - porMin) * bepRatio * 100 + '%';
        this.sliderPOR.style.left   = porMin * bepRatio * 100 + '%';
        this.sliderAOR.style.width  = (maxAOR - minAOR) * bepRatio * 100 + '%';
        this.sliderAOR.style.left   = minAOR * bepRatio * 100 + '%';
    }

    setFlow(flow: number, speed: number) {
        this.flow.textContent = Math.round(flow).toString();
        if (speed === 0) {
            this._updateIndicator(0);
            return;
        }
        else {
            let speedNode = this.pump.findChildByRole(Role.ROLE_ACT_SPEED);
            if (speedNode)
                this._updateIndicator(flow * this.maxSpeed / convert(speed, speedNode.units, TagUnit.TU_HZ, this.maxSpeed) / this.bepFlow * 100)
            else
                this._updateIndicator(flow / this.bepFlow * 100)
        }
    }


    _updateIndicator(ratio: number) {
        this.slider.classList.remove('squished-curve__pulse');
        if (ratio == 0) {
            this.slider.style.backgroundColor = 'var(--color-surfaceContainerLow)';
            this.sliderAOR.style.backgroundColor = 'var(--color-surfaceContainer)';
            this.sliderPOR.style.backgroundColor = 'var(--color-surfaceContainerHigh)';
            if (!this.fSimple) {
                this.valueContainer.classList.add('hide');
            }
            else {
                if (this.running.getValue()) {
                    this.slider.style.backgroundColor = 'var(--color-red-8)';
                    this.sliderAOR.style.backgroundColor = 'var(--color-yellow-8)';
                    this.sliderPOR.style.backgroundColor = 'var(--color-green-8)';
                }
            }
        }
        else {
            this.valueContainer.classList.remove('hide');
            this.valueContainer.style.marginLeft = ratio * this.bepRatio + '%';
            if (this.porMin && this.porMax) {
                var minPOR = this.porMin.getValue();
                var maxPOR = this.porMax.getValue();

                var fAor = this.fUseAOR && this.fUseAOR.getValue();
                var minAOR = fAor ? Math.min(this.aorMin.getValue(), minPOR) : minPOR;
                var maxAOR = fAor ? Math.max(this.aorMax.getValue(), maxPOR) : maxPOR;
                this.valueTriangle.style.borderBottomColor = 'var(--color-green-8)';
                if (ratio < minAOR * 100 || maxAOR * 100 < ratio) {
                    this.valueTriangle.style.borderBottomColor = 'var(--color-red-8)';
                    this.slider.classList.add('squished-curve__pulse');
                }
                this.slider.style.backgroundColor = 'var(--color-red-8)';
                this.sliderAOR.style.backgroundColor = 'var(--color-yellow-8)';
                this.sliderPOR.style.backgroundColor = 'var(--color-green-8)';
            }
        }
    }

    onPumpTwinsComplete(pumpTwins: PumpTwins) {
        this.twin           = pumpTwins.getTwin(this.pump);
        assert(this.twin, `No pump twin found for pump '${this.pump.name}' on device '${this.device.siteName}'`)
        if (this.twin) {
            this.bepFlow		= this.twin.bepFlow;	// Save the BEP flow for the pump
            let maxFlow        = this.twin.zeroHeadFlow;
            this.bepRatio       = this.bepFlow / maxFlow;
            this.fullRatio      = pumpTwins.maxZeroHeadFlow / maxFlow;
            this.update(this.porMin);				// Now that we now our BEP and max flow, update the location of the POR rectangle
            if (this.fUseNode)						// If we are using the node
                this.update(this.percentBEP);		// Also update the position of the indicator rectangle
        }
    }

    destroy() {
        this.valueDisplay?.destroy();
        this.nodeManager.destroy();
        this.unregisterAsWidget();
    }
};
