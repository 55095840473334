import { TagAttribute, type TagDefinition } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import { type InputSetpoint } from "../../input/setpoint/inputsetpoint";
import { UnitsMap } from "../../lib/tagunits";
import template from './floattimeout.html';

@RegisterWidget({tag:'float-timeout', roles:['HighTimeout', 'LowTimeout'], displayName: 'Float Timeout', template: template})
export class FloatTimeout extends Widget {
    valueElement: HTMLInputElement;
    @TagAttribute({
        displayName: 'Float Timeout',
        supportedTypes: ['numeric']
    }) floatTimeout: TagDefinition;

    protected enliven(): void
    {
        let tag = this.floatTimeout.tag;
        let input = this.shadowRoot?.getElementById("setpoint") as InputSetpoint;
        input.setpoint = {tag: tag};
        let preText = this.shadowRoot?.getElementById('preText')! as HTMLDivElement;
        preText.textContent = `Search for ${tag.roles.has('HighTimeout') ? 'High' : 'Low'} Float for`
        let units = this.shadowRoot?.getElementById('units')! as HTMLDivElement;
        units.textContent = UnitsMap.get(tag.units)?.plural ?? '';
    }
}