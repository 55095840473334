import owner, { ApplicationType } from '../owner';
import assert from './debug';
import { WritesEnabler } from './dialog';
import Dialog from './dialog';
import FrameMaker from './framemaker';
import LiveData from './livedata';
import FrameParser from './frameparser';
import LoginPage from './pages/loginpage';
import Localization from './localization';
import { decodeFirst, encode, decodeFirstSync } from 'cbor-web'
import createLoader, { createElement, createUniqueId } from './elements';
import PassKeyIcon from './images/icons/passkeys-logo.svg'
import './passkeys.css';

// This guy is our generic response handler that helps us resolve promises upon receiving a response to a passkey command
// * All passkey commands expect responses...
class PasskeyResponse {
    owner: typeof owner;
    expectedCommand: number;
    responseCallback: (FrameParser) => void;
    graphId: number;
    subCommand: number;

    constructor(responseCallback: (FrameParser) => void, expectedCommand: number) {
        this.owner = owner;
        this.responseCallback = responseCallback;
        this.expectedCommand = expectedCommand;
        this.graphId = this.owner.ldc.registerGraph(this);
    }

    onPasskeyCommand(fp: FrameParser) {
        this.subCommand = fp.pop_u8();
        assert(this.subCommand == this.expectedCommand, `Mismatch on passkey subcommand response ${this.subCommand}`);
        this.responseCallback(fp); // Response callback usually parses the rest of the response and then resolves a promise
        this.owner.ldc.unregisterGraph(this.graphId);
    }
}

enum SigningResult {
    FAIL = 0,
    SUCCESS = 1,
    CANCELED = 2
}

export class PasskeyLoginButton {
    owner: typeof owner;
    parent: HTMLElement;
    isInitialLogin: boolean;
    button: HTMLButtonElement;
    writeModeLifespan: number | undefined;
    callback: ((result: boolean) => void) | undefined;
    deactivated: boolean = false;
    observer: IntersectionObserver;
    challenge: ArrayBuffer;
    loader: HTMLDivElement;
    isSafari: boolean; // Special case for safari -https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
    buttonTextElement: HTMLDivElement;
    buttonText: string;

    constructor(parent: HTMLElement, isInitialLogin: boolean, writeModeLifespan?: number, callback?: (result: boolean) => void, customText?: string) {
        this.owner = owner;
        this.parent = parent;
        this.isInitialLogin = isInitialLogin;
        this.writeModeLifespan = writeModeLifespan; // Updated by WritesEnabler dialog
        this.callback = callback;

        this.button = createElement('button', 'se-button login__button login__passkey-button', this.parent);
        this.loader = createLoader(this.button, 24, 'passkey_button-loader');
        this.loader.style.display = 'none';
        createElement('img', 'login__button__icon', this.button, undefined, { 'src': PassKeyIcon });
        this.buttonText = customText ? customText : 'Login with a passkey';
        this.buttonTextElement = createElement('div', 'login__passkey-button-text', this.button, this.buttonText);

        this.owner.ldc.user.passKeys.setCurrentLoginButton(this);
        this.button.onclick = () => this.defaultButtonClick();
    }

    async defaultButtonClick() {
        this.isSafari = (this.owner.applicationType === ApplicationType.BROWSER && navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") < 0) || this.owner.applicationType === ApplicationType.IOS_APP;

        await this.getNewChallenge();

        if (this.isSafari) {
            this.button.style.border = "solid";
            this.button.style.borderColor = "green";
            this.buttonTextElement.textContent = "Click to continue";
            this.button.onclick = () => this.safariButtonClick();
            await this.activateConditionalUI(false);
        }
        else  // Else - just sign it
            await this.signChallenge(true);
    }

    async safariButtonClick() {
        if (!await this.signChallenge(true)) {
            this.button.style.border = "";
            this.button.style.borderColor = "";
            this.buttonTextElement.textContent = this.buttonText;
            this.button.onclick = () => this.defaultButtonClick(); // Back to the default
        }
    }

    showWaitingOnDeviceIndication(showIt: boolean) {
        if (showIt) {
            this.loader.style.display = '';
            this.button.classList.add("login__passkey-button-disabled");
            this.buttonTextElement.textContent = "Follow device prompts...";
        } else {
            this.buttonTextElement.textContent = this.buttonText;
            this.loader.style.display = 'none';
            this.button.classList.remove("login__passkey-button-disabled");
        }
    }

    async signChallenge(isButtonClick: boolean): Promise<boolean> {
        if (isButtonClick)
            this.showWaitingOnDeviceIndication(true);
        let result = await this.owner.ldc.user.passKeys.signChallenge(this.challenge, this.isInitialLogin, isButtonClick);
        if (isButtonClick && result !== SigningResult.SUCCESS /* If it worked - don't let them  click the button again in case they are still looking at the login page for a second */)
            this.showWaitingOnDeviceIndication(false);
        let success = result == SigningResult.SUCCESS;
        this.callback && result != SigningResult.CANCELED && this.callback(success); // Only notify the supplied callback if it was not a cancelled operation
        return success;
    }

    async getNewChallenge() {
        this.loader.style.display = '';
        this.button.classList.add("login__passkey-button-disabled");
        this.buttonTextElement.textContent = "Obtaining challenge...";
        this.challenge = await this.owner.ldc.user.passKeys.getChallenge(this.isInitialLogin);
        this.buttonTextElement.textContent = this.buttonText;
        this.loader.style.display = 'none';
        this.button.classList.remove("login__passkey-button-disabled");
    }

    async activateConditionalUI(getChallenge: boolean = true) {
        if (await this.owner.ldc.user.passKeys.thisDeviceSupportsConditionalMediation()) {
            if (getChallenge)
                this.challenge = await this.owner.ldc.user.passKeys.getChallenge(this.isInitialLogin);
            if (!await this.signChallenge(false)) // Conditional UI prompt
                await this.activateConditionalUI(); // Give them another shot with the conditional UI
        }
    }

    static doesUserPreferPasskeys(): boolean {
        return localStorage.getItem("prefersPasskeyLogin") == "true";
    }

    static savePasskeyPreference(prefersPasskeys: boolean) {
        localStorage.setItem("prefersPasskeyLogin", prefersPasskeys ? "true" : "false");
    }
}

export function buf2hex(buffer) { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
}

export interface CredentialMetadata {
    userId: ArrayBuffer;
    credentialId: ArrayBuffer;
    nickName: string;
    lastUsed: number;
    created: number;
    aaguid: ArrayBuffer;
    fidoData: any;
}

export default class PassKeys {
    // Note - the WebAuthn api is only available in "secure contexts" - https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts
    // You will run into issues if you try to access this over non-https if you're not on localhost or 127.0.0.1

    loginButton: PasskeyLoginButton;
    owner: typeof owner;
    pendingCredentialGet: AbortController = new AbortController(); // So we can cancel any pending credential gets if we need to
    static abortMessage: string = "New credential.get requested";
    static iOSAbortMessage: string = "AbortError: Aborted by AbortSignal.";

    constructor() {
        this.owner = owner;
    }

    failedAuthentication() {
        // If we're on the login page - adjust the status text
        if (this.owner.currentPage instanceof LoginPage)
            this.owner.currentPage.status.textContent = Localization.getLocalText("Failed to authenticate with passkey.");
    }

    setCurrentLoginButton(button: PasskeyLoginButton) {
        if (this.loginButton)
            this.loginButton.deactivated = true;
        this.loginButton = button;
    }

    async setCredentialNickName(credentialId: ArrayBuffer, nickName: string): Promise<boolean> {
        return new Promise((resolve) => {
            new WritesEnabler(() => {
                var fm = new FrameMaker();
                fm.buildFrame(LiveData.WVC_PASSKEY, 0, new PasskeyResponse((fp: FrameParser) => {
                    resolve(fp.pop_u8() == 1); // Nickname was set successfully
                }, LiveData.PK_SET_NICKNAME).graphId);
                fm.push_u8(LiveData.PK_SET_NICKNAME);
                fm.push_u64(credentialId.byteLength);
                fm.push_buffer(credentialId);
                fm.push_string(nickName);
                this.owner.ldc.sendFrame(fm);
            });
        });
    }

    async getCredentials(userName: string = this.owner.ldc.user.username /* Default to getting your own creds */): Promise<CredentialMetadata[]> {
        return new Promise((resolve) => {
            var fm = new FrameMaker();
            fm.buildFrame(LiveData.WVC_PASSKEY, 0, new PasskeyResponse((fp: FrameParser) => {
                let results: CredentialMetadata[] = [];
                let count = fp.pop_u64();
                for (var i = 0; i < count; ++i) {
                    let result: CredentialMetadata = {
                        userId: fp.pop_buffer(fp.pop_u64()),
                        credentialId: fp.pop_buffer(fp.pop_u64()),
                        nickName: fp.pop_string(),
                        lastUsed: fp.pop_u64(),
                        created: fp.pop_u64(),
                        aaguid: fp.pop_buffer(fp.pop_u64()),
                        fidoData: {}
                    };
                    try {
                        result.fidoData = JSON.parse(new TextDecoder().decode(fp.pop_buffer(fp.pop_u64())));
                    } catch {
                        result.fidoData = {};
                    }
                    results.push(result);
                }
                resolve(results);
            }, LiveData.PK_GET_CREDENTIALS).graphId);
            fm.push_u8(LiveData.PK_GET_CREDENTIALS);
            fm.push_string(userName);
            this.owner.ldc.sendFrame(fm);
        });
    }

    async revokeCredential(userId: ArrayBuffer, credentialId: ArrayBuffer): Promise<boolean> {
        return new Promise((resolve) => {
            new WritesEnabler(() => {
                var fm = new FrameMaker();
                fm.buildFrame(LiveData.WVC_PASSKEY, 0, new PasskeyResponse((fp: FrameParser) => {
                    resolve(fp.pop_u8() == 1); // Credential was successfully revoked or not
                }, LiveData.PK_REVOKE_CREDENTIAL).graphId);
                fm.push_u8(LiveData.PK_REVOKE_CREDENTIAL);
                fm.push_u64(userId.byteLength);
                fm.push_buffer(userId);
                fm.push_u64(credentialId.byteLength);
                fm.push_buffer(credentialId);
                this.owner.ldc.sendFrame(fm);
            });
        });
    }

    async getChallenge(isInitialAuthentication: boolean = true): Promise<ArrayBuffer> {
        return new Promise((resolve) => {
            var fm = new FrameMaker();
            fm.buildFrame(LiveData.WVC_PASSKEY, 0, new PasskeyResponse((fp: FrameParser) => {
                resolve(fp.pop_buffer(fp.pop_u64())); // Let the caller know we now have a challenge
                if (isInitialAuthentication && this.owner.currentPage instanceof LoginPage)
                    this.owner.currentPage.user.focus(); // Focus on the username input now that we have a challenge - iOS should prompt for passkey use instead of password
            }, LiveData.PK_GET_AUTH_CHALLENGE).graphId);
            fm.push_u8(LiveData.PK_GET_AUTH_CHALLENGE); // Ask for a challenge so we can sign it
            if (!isInitialAuthentication) // Use the existing socket
                this.owner.ldc.sendFrame(fm);
            else // Assume we need a new a socket if we're going through initial authentication
                this.owner.ldc.initializeSocket(() => this.owner.ldc.sendFrame(fm), this.owner.ldc.authenticationServer); // We won't have a socket yet so we create one
        });
    }

    async register(isExplicit: boolean /* Explicit request for passkey vs passively offering to create one */): Promise<boolean> {
        assert(owner.ldc && owner.ldc.isLoggedIn(), "Authentication required to register a new passkey."); // Must be logged in to create a passkey

        return new Promise(async (resolve) => {
            const initiateRegistration = () => {
                this.abortPendingPasskeyOperations();
                var fm = new FrameMaker();
                fm.buildFrame(LiveData.WVC_PASSKEY, undefined, new PasskeyResponse(async (fp: FrameParser) => {
                    // Extract all of the registration info
                    let userId = fp.pop_buffer(fp.pop_u64());
                    let challenge = fp.pop_buffer(fp.pop_u64());
                    var existingCredentials: ArrayBuffer[] = [];
                    let credCount = fp.pop_u64();
                    for (var i = 0; i < credCount; ++i)
                        existingCredentials.push(fp.pop_buffer(fp.pop_u64()));
                    resolve(await this.registerCredential(userId, challenge, existingCredentials)); // And hand it off to the authenticator for signing/sending
                }, LiveData.PK_BEGIN_REGISTRATION).graphId);
                fm.push_u8(LiveData.PK_BEGIN_REGISTRATION);
                this.owner.ldc.sendFrame(fm);
            }

            if (isExplicit) // Just start the registration process
                initiateRegistration();
            else if (!this.userHasBeenAskedToUpgrade()) { // Otherwise, ask them if they want to if they haven't been already
                let defaultExplanation = 'Passkeys are more secure and easier to use than password and multi-factor authentication methods.';
                let appExplanation = 'Specific Energy is migrating to passkeys in order to strengthen security and continue supporting authenticating via Face ID, touch ID, or passcode.';
                let isApp = this.owner.applicationType != ApplicationType.BROWSER;
                new Dialog(document.body, {
                    title: isApp ? 'Set up a passkey' : 'Would you like to set up a passkey?',
                    body: isApp ? appExplanation : defaultExplanation,
                    titleBackground: 'var(--color-primary)',
                    titleColor: 'var(--color-inverseOnSurface)',
                    fButtons: true,
                    fImportant: true,
                    buttons: [
                        {
                            'title': 'Create a passkey',
                            color: 'var(--color-green-8)',
                            callback: initiateRegistration
                        },
                        {
                            'title': 'Cancel',
                            color: 'var(--color-red-8)',
                        }
                    ]
                });
            }
        });
    }

    userHasBeenAskedToUpgrade(): boolean { // User has been asked to upgrade to passkeys on this device
        if (this.owner.applicationType === ApplicationType.BROWSER) // FIXME: Remove this line when we're ready to start prompting password users to create passkeys across the board - otherwise just force ask app users
            return true;
        if (localStorage.getItem("passkey-upgrade-prompt-" + this.owner.ldc.user.username.hashCode()) != null)
            return true; // Already been asked
        else { // Haven't been asked - set an item that indicates they've been asked with a timestamp (in case we ever support re-prompting)
            localStorage.setItem("passkey-upgrade-prompt-" + this.owner.ldc.user.username.hashCode(), Date.now().toString());
            return false;
        }
    }

    async thisDeviceSupportsConditionalMediation(): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (typeof PublicKeyCredential !== "undefined" && typeof PublicKeyCredential.isConditionalMediationAvailable !== "undefined") {
                if (await PublicKeyCredential.isConditionalMediationAvailable())
                    resolve(true);
            }
            resolve(false); // Otherwise - this device doesn't support conditional mediation
        });
    }


    // I don't love this but the authData basically consists of 37 statically sized bytes
    // followed by attestedCredentialData which contains a length for credentialId but not the
    // COSE encoded public key and then finally info about extensions in a CBOR encoded format
    // https://www.w3.org/TR/webauthn-2/#authenticator-data
    // Because of we don't have the length of public key - we're just starting at the end of the byte string
    // and CBOR decoding until it succeeds and we have a credProtect value or we hit the COSE key location
    async getCredProtectLevel(authData: ArrayBuffer, reverseOffset: number = 1, coseKeyLocation: number = 0): Promise<number> {
        if (coseKeyLocation == 0) { // We're just getting started calculate the location of the COSE_key so that we know when to stop looking for CBOR extensions
            let staticOffset = 36 /* 37th byte is the end of auth data */ + 16 /* aaguid */ + 2 /* credentialIdLength */;
            let view = new DataView(authData);
            let credentialIDLength = view.getInt16(staticOffset - 1 /* starting with the first byte of the 16 bit int */, false /* big-endian */);
            coseKeyLocation = 36 + credentialIDLength; // Skip over the credentialID bytes
        }

        if (reverseOffset == coseKeyLocation)
            return 0; // Failed to find any credProtect extension

        try {
            let decoded = await decodeFirst(authData.slice(authData.byteLength - reverseOffset, authData.byteLength));
            if (decoded.hasOwnProperty("credProtect"))
                return decoded.credProtect;
            else
                throw new Error("Decoded something that didn't contain credProtect");
            // Else keep going until we find the credProtect extension setting or we bump into the beginning of the COSE_key
        } catch {
            return await this.getCredProtectLevel(authData, ++reverseOffset, coseKeyLocation);
        }
    }

    private getRootDomain(): string {
        // Get the root domain i.e. dashboard.specificenergy.com -> specificenergy.com
        return window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
    }

    async registerCredential(userId: ArrayBuffer, challenge: ArrayBuffer, existingCredentials: ArrayBuffer[]): Promise<boolean> {
        return new Promise(async (resolve) => {
            new WritesEnabler(async () => { // Must have writes enabled to make a change to your account (don't want someone walking up with a Yubikey and adding passkeys to your account willy nilly)
                var publicKeyCredentialCreationOptions = {
                    challenge: challenge,
                    rp: {
                        name: "Specific Energy",
                        id: this.getRootDomain(), // Root domain of our website (i.e. specificenergy.com)
                    },
                    user: {
                        /*
                            user.id is not protected by authentication on authenticators. If we were to put a username here, someone with a stolen
                            authenticator could read the user's username without going through user verification. - https://github.com/w3c/webauthn/issues/1763
                        */
                        id: userId, // Limited to 64 bytes - 32 Random bytes is what we use
                        name: this.owner.ldc.user.username, // This is transmitted during authentication and is what we will use to look up the public key for corresponding username
                        displayName: this.owner.ldc.user.fullName,
                    },
                    attestationFormats: ["packed", "fido-u2f", "TPM"], // Attestation formats we support
                    excludeCredentials: [],
                    pubKeyCredParams: [
                        // Recommended algos for wide support - https://w3c.github.io/webauthn/#dom-publickeycredentialcreationoptions-pubkeycredparams
                        { alg: -7 /* ES256  */, type: "public-key" },
                        { alg: -8 /* Ed25519  */, type: "public-key" },
                        { alg: -257 /* RS256 */, type: "public-key" }
                    ],
                    authenticatorSelection: {
                        // authenticatorAttachment: "platform" as AuthenticatorAttachment, // If we leave this unset, devices will give the option of platform OR hardware authenticators
                        requireResidentKey: true, // Makes the credential "discoverable" so that a user can select it during authentication - this is required for passkeys' use of WebAuthn
                        residentKey: "required"
                        // userVerification: "required" as UserVerificationRequirement
                    },
                    attestation: "direct", // Ask the authenticator to supply an attestation statement
                    /*
                        Leaving the below commented out as well as the userVerification setting above - this is because
                        even though we don't request any extensions to be used by the authenticator - newer Yubikeys that support
                        "credProtect" will send over something like {credProtect: 3} indicating that operations on this credential
                        require userVerification - we require and are checking for userVerification during authentication (it's a bit
                        contained in the signed assertion) so we don't really care what the value of credProtect is or if the extension is
                        even supported by the authenticator. Even some series 5 YubiKeys have firmware that doesn't support this extension but
                        still enforce user verification where required. We collect this information because we need it to validate the
                        attestation signature on the back-end
                    */
                    // Tell the authenticator that credential access requires user verification (cannot be missing or optional)
                    // Some older authenticators do not support the credProtect extension. The browser gives a nice message saying
                    // that "this website requires a newer security key".
                    // extensions: {
                    //     credentialProtectionPolicy: "userVerificationRequired",
                    //     enforceCredentialProtectionPolicy: true
                    // } as AuthenticationExtensionsClientInputs
                } as PublicKeyCredentialCreationOptions;

                // Add the user's existing credentials as "excludedCredentials" so they don't try to register them more than once
                for (const existingCredential of existingCredentials)
                    publicKeyCredentialCreationOptions.excludeCredentials!.push({ id: existingCredential, type: 'public-key' });

                new Dialog(document.body, {
                    title: 'Passkey setup',
                    body: 'You will now be prompted to set up a passkey on this device.',
                    callback: async () => {
                        this.abortPendingPasskeyOperations(); // Abort any existing credential requests
                        try {
                            const credential = await navigator.credentials.create({
                                publicKey: publicKeyCredentialCreationOptions
                            }) as PublicKeyCredential;

                            // Send credential to whoville for persistence
                            var fm = new FrameMaker();
                            fm.buildFrame(LiveData.WVC_PASSKEY, undefined, new PasskeyResponse((fp: FrameParser) => {
                                resolve(true); // If we got a response frame back - it was successful!
                            }, LiveData.PK_REGISTER_CREDENTIAL).graphId);
                            fm.push_u8(LiveData.PK_REGISTER_CREDENTIAL);
                            const response = credential.response as AuthenticatorAttestationResponse; // Make sure typescript knows what this thing is
                            fm.push_u64(response.clientDataJSON.byteLength); // client data in json format
                            fm.push_buffer(response.clientDataJSON);
                            let cborDecodedAttestation = await decodeFirst(response.attestationObject); // CBOR formatted attestationObject
                            fm.push_string(cborDecodedAttestation.fmt);
                            fm.push_s32(response.getPublicKeyAlgorithm());
                            // Retrieve the auth data from the decoded attestation object
                            let authData = cborDecodedAttestation.authData.buffer.slice(cborDecodedAttestation.authData.byteOffset, cborDecodedAttestation.authData.byteLength + cborDecodedAttestation.authData.byteOffset);
                            fm.push_u64(authData.byteLength);
                            fm.push_buffer(authData);
                            fm.push_u8(await this.getCredProtectLevel(authData));
                            let cborEncodedAttestationStatement = encode(cborDecodedAttestation.attStmt);
                            if (cborDecodedAttestation.fmt != "none") { // Send the attestation statement if there is one
                                fm.push_u64(cborEncodedAttestationStatement.buffer.byteLength);
                                fm.push_buffer(cborEncodedAttestationStatement.buffer);
                            }
                            this.owner.ldc.sendFrame(fm);

                        } catch (e) {
                            console.error(e);
                            new Dialog(document.body, {
                                title: 'Error',
                                body: 'Failed to create a passkey.'
                            })
                        }
                    }
                })
            });
        });
    }

    abortPendingPasskeyOperations() {
        this.pendingCredentialGet.abort(PassKeys.abortMessage);
        this.pendingCredentialGet = new AbortController(); // Assign a new abort controller
    }

    async signChallenge(challenge: ArrayBuffer, isInitialAuthentication: boolean/* not enabling writes */, isExplicitAuthentication: boolean): Promise<SigningResult> {
        this.abortPendingPasskeyOperations(); // Abort any existing credential requests
        return new Promise(async (resolve) => {
            const publicKeyCredentialRequestOptions = {
                // Server generated challenge
                challenge: challenge,
                // The same RP ID as used during registration
                rpId: this.getRootDomain(),
                userVerification: 'required' // Require that the authenticator attest that it verified the user (i.e. pin, faceID, windows hello, etc.)
            } as PublicKeyCredentialRequestOptions;

            try {
                const credential = await navigator.credentials.get({
                    publicKey: publicKeyCredentialRequestOptions,
                    signal: this.pendingCredentialGet.signal,
                    // Specify 'conditional' to activate conditional UI - required forces passkey behavior whether credentials are discoverable or not
                    mediation: isExplicitAuthentication ? 'required' : 'conditional'
                }) as PublicKeyCredential;
                const response = credential.response as AuthenticatorAssertionResponse;
                // Send data used to to form the
                var fm = new FrameMaker();
                let subCommand = isInitialAuthentication ? LiveData.PK_AUTHENTICATE : LiveData.PK_ENABLE_WRITE_MODE;

                fm.buildFrame(LiveData.WVC_PASSKEY, 0, new PasskeyResponse((fp: FrameParser) => {
                    let success = fp.pop_u8() == 1;

                    // If we were enabling writes - get the write-enabled token and store it
                    if (success && subCommand == LiveData.PK_ENABLE_WRITE_MODE) {
                        let token = fp.pop_string(); // Write-enabled token
                        this.owner.ldc.pathStorage.setItem(WritesEnabler.pathStorageName, token).then(() => { // Save the token so we don't have to do this until exp
                            this.owner.ldc.user.verifiedUntil = JSON.parse(window.atob(token.split('.')[1]))["exp"];
                        });
                    }

                    if (!success)
                        this.failedAuthentication();
                    // PasskeyLoginButton.savePasskeyPreference(true); // We will explicitly prompt them for passkey auth on this device until they try to use passwords again
                    resolve(success ? SigningResult.SUCCESS : SigningResult.FAIL); // Let the caller know whether it succeeded
                }, subCommand).graphId);

                fm.push_u8(subCommand);
                if (isInitialAuthentication) // Only send app context if this is initial authentication
                    fm.push_u32(this.owner.applicationContext);
                else // Enabling write mode
                    fm.push_u16(this.loginButton.writeModeLifespan);
                fm.push_u64(response.userHandle!.byteLength);
                fm.push_buffer(response.userHandle!);
                const rawId = credential.rawId as Uint8Array | ArrayBuffer;
                if (rawId instanceof ArrayBuffer) { // Super annoying but sometimes it's a Uint8Array and sometimes it's an ArrayBuffer...
                    fm.push_u64(rawId.byteLength);
                    fm.push_buffer(rawId);
                }
                else {
                    fm.push_u64(rawId.buffer.byteLength);
                    fm.push_buffer(rawId.buffer);
                }
                fm.push_u64(response.clientDataJSON.byteLength);
                fm.push_buffer(response.clientDataJSON);
                fm.push_u64(response.signature.byteLength);
                fm.push_buffer(response.signature);
                fm.push_buffer(response.authenticatorData);
                this.owner.ldc.sendFrame(fm);
                this.owner.ldc.fUsedPasswordLogin = false; // Ensure the loginpage knows that our latest authentication attempt was not done with a password
            } catch (e) {
                // alert(e);
                if (e != PassKeys.abortMessage) // A user aborting a passkey auth attempt should not be indicated as a failure
                    console.log(e); // Otherwise just log the error and let them try again

                if (isExplicitAuthentication) // We don't care about getting notified of canceled conditional prompts
                    resolve(SigningResult.CANCELED);
            }
        }
        );
    }
}