import SVGWidget from "./svgwidget";


//The TemplateText driver replaces a node's text-content with the value
//of an environment variable on startup.
//Syntax: abc{file}def -> abcFile.svgdef
export default class TemplateText extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);
		
        //Optimization: Only scan through the string /once/. Note that for short strings,
        //multiple scans with the browser's fast implementation may be faster than one
        //scan with a custom JS tokenizer.
        var text = this.element.textContent;
        for (var name in element._environment){
            text = text.replace(new RegExp('{'+name+'}', 'g'), element._environment[name]);
        }
        this.element.textContent = text;
	}
}
