import {createElement} from './elements';
import createSVGElement from './svgelements';
import {NodeQuality} from './node';
import NodeManager from './nodemanager';
import Scale from './scale';
import {Shuttle} from './shuttle';
import {Widget} from './widget';
import owner from '../owner';
import './tankgraph.css';
import { Role } from './role';

export default class TankGraph extends Widget {
    constructor(parent, tankFolder, fTarget, fWetWell, options) {
        super();
        this.element		= createElement('div', 'tank-graph__wrapper', parent);	// Create a wrapper node
        this.svgDiv         = createElement('div', 'tank-graph__svg', this.element);
        //this.description    = createElement('div', 'tank-graph__description', this.element);
        this.width			= this.svgDiv.clientWidth;
        this.height			= this.svgDiv.clientHeight;
        this.fTarget 		= fTarget;
        this.fWetWell		= fWetWell;

        this.copy(options);

        this.registerAsWidget(parent);	// Register with the element like a good little widget
        // Find all the nodes we need from this tank
        this.nodeManager	= new NodeManager(this);
        this.levelNode		= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_LEVEL);
        this.stopLevelNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_STOP_LEVEL);
        this.startLevelNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_START_LEVEL);
        this.fullLevelNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_FULLFLOW_LEVEL);
        this.stopFlowNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_STOP_FLOW);
        this.fullFlowNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_FULL_FLOW);
        this.deadbandNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_DEADBAND);
        this.outputNode		= this.nodeManager.addNodeByRole(tankFolder, fTarget ? Role.ROLE_TLC_TANK_MIN_FLOW : Role.ROLE_TLC_TANK_MAX_FLOW);

        // Store the maximum values we will have for our axes
        this.yAxisMax		= this.levelNode.engMax;
        this.xAxisMax		= this.fullFlowNode.engMax;

    	if (this.fWetWell) {	// If this is a wet well
    		if (this.fullLevelNode.quality != SE.NodeQuality.NQ_GOOD) {	// If the full level node hasn't gotten a value yet
    			this.fullLevelNode.subscribe(this);					// Subscribe to the node
    			return;
    		}
    		this.yAxisMax	= Math.min(this.yAxisMax, Math.ceil(this.fullLevelNode.getValue(this.fPending) * 1.1));	// Calculate the max based on the full level value
    	}
    	this.finishConstruction();	// Finish up constructing the graph
    };

    finishConstruction() {
        this.createSVGElements(this.levelNode.parent);	// Create the elements for our graph (but nothing will look interesting yet)

        this.fRedraw		= false;	// Will be true when our fills and lines need to be redrawn
        this.fUpdateRect	= false;	// Will be true if we should update the deadband shuttle's inner rectangle

        this.callback		= this.onJobCompleted.bind(this);
        owner.addJobCompletedCallback(this.callback);	// Register for the callback

        this.nodeManager.subscribe();

        this.onJobCompleted();	// Force a redraw now that we've updated for all our nodes so we don't wait for a job to complete before redrawing
    };

    update(node) {
        if (node.quality != NodeQuality.NQ_GOOD)	// Bad quality on this node...don't recalculate the point
            return;

        // Based on which node update, recalculate the appropriate point for our curve
        switch(node) {	// Switches use the '===' comparator, so should be good for our use case here
            case this.levelNode:
                this.levelY		= this.graphHeight * (this.yAxisMax - this.levelNode.getValue(this.fPending)) / this.yAxisMax;
            break;

            case this.stopLevelNode:
                this.stopY		= this.graphHeight * (this.yAxisMax - this.stopLevelNode.getValue(this.fPending)) / this.yAxisMax;
            break;

            case this.startLevelNode:
                this.startY		= this.graphHeight * (this.yAxisMax - this.startLevelNode.getValue(this.fPending)) / this.yAxisMax;
            break;

            case this.fullLevelNode:
    			if (this.callback === undefined) {	// If we didn't finish construction before we got here
    				assert(this.fWetWell, 'Weird update call loop');	// We better be a wet well
    				this.yAxisMax	= Math.min(this.yAxisMax, Math.ceil(this.fullLevelNode.getValue(this.fPending) * 1.1));	// Recalculate the max
    				this.fullLevelNode.unsubscribe(this);	// Unsubscribe to the full level node so the node manager can resubscribe us
    				this.finishConstruction();				// Finish up constructing the graph
    				return;									// The stuff below will be taken care of during the node manager resubscribe
    			}

                this.fullY		= this.graphHeight * (this.yAxisMax - this.fullLevelNode.getValue(this.fPending)) / this.yAxisMax;
                this.deadbandRect.bottom	= this.outerhang.top + this.overhang.top + this.fullY;
                this.fUpdateRect			= true;		// We need to update the deadband shuttle's rectangle now
            break;

            case this.deadbandNode:
                this.deadbandY	= this.graphHeight * this.deadbandNode.getValue(this.fPending) / this.yAxisMax;
            break;

            case this.fullFlowNode:
                this.maxFlowX	= this.graphWidth * this.fullFlowNode.getValue(this.fPending) / this.xAxisMax;
                this.deadbandRect.right		= this.outerhang.left + this.overhang.left + this.maxFlowX;
                this.fUpdateRect			= true;		// We need to update the deadband shuttle's rectangle now
            break;

            case this.stopFlowNode:
                this.minFlowX	= this.graphWidth * this.stopFlowNode.getValue(this.fPending) / this.xAxisMax;
            break;

            case this.outputNode:
                this.outputX	= this.graphWidth * this.outputNode.getValue(this.fPending) / this.xAxisMax;
            break;

            default:
                assert(false, "Unknown node called update on TankGraph");
            return;
        }

        this.fRedraw = true;	// If we get here, the node that updated is forcing a redraw of our graph
    };

    onJobCompleted() {
        // Change the rectangle for the deadband shuttle so it can move with the full flow and full level values
        // We could calculate the all the box's parameters, but it only needs bottom, right, and height
        // and the height doesn't change (as the max of the deadband node is constant)
        if (this.fUpdateRect) {
            if (this.fShuttles) {
                //this.deadbandShuttle.updateInnerRect(this.deadbandRect);
            }
            this.fUpdateRect	= false;	// We've update the inner rectangle for the shuttle
        }
        if (this.fRedraw) {		// Redraw all of our elements that might have moved
            this.drawLines(this.graphWidth, this.graphHeight);	// Move our lines (dotted and full)
            this.fRedraw		= false;	// We've redrawn our graph and are now current
        }
    };

    createSVGElements(tankFolder) {
        // Create our SVG element and two 'g' elements. One g is for the graph, the other is for the axis labels
        this.svg				= createSVGElement('svg', null, this.svgDiv, {width: this.width, height: this.height});
        this.background 		= createSVGElement('g', 'tankGraphBase', this.svg);
        this.graph 				= createSVGElement('g', null, this.background);

        var graphBackground		= createSVGElement('rect', 'tankGraphBackground', this.graph);
        //graphBackground.createGradientFill(this.svg, false);

        // Create our background shapes first so they'll appear behind the lines
        this.svgLevelFill		= createSVGElement('rect', 'tankGraphLevelFill', this.graph);
        //this.svgLevelFill.createGradientFill(this.svg, false);

        // Make four lines that will be our parallelogram
        this.svgLowDeadband		= createSVGElement('line', null, this.graph);
        this.svgHighLine		= createSVGElement('line', null, this.graph);
        this.svgHighDeadband	= createSVGElement('line', null, this.graph);
        this.svgLowLine			= createSVGElement('line', null, this.graph);

        // Make all the dotted lines that connect shuttles to the parallelogram
        this.svgStopLevel		= createSVGElement('line', 'tankGraphDottedLine', this.graph);
        this.svgStartLevel		= createSVGElement('line', 'tankGraphDottedLine', this.graph);
        this.svgFullLevel		= createSVGElement('line', 'tankGraphDottedLine', this.graph);
        this.svgMinFlow			= createSVGElement('line', 'tankGraphDottedLine', this.graph);
        this.svgMaxFlow			= createSVGElement('line', 'tankGraphDottedLine', this.graph);

        // Create scales for the x and y axis
        var xAxisScale	= new Scale(this.svg, this.fullFlowNode.engMin, this.fullFlowNode.engMax, this.fullFlowNode.resolution, 'bottom', false, 'blackTics');
        var yAxisScale	= new Scale(this.svg, this.levelNode.engMin, this.yAxisMax, this.levelNode.resolution, 'left', false, 'blackTics');

        // Create all the shuttles we have
        var visibleScale = {minimum: 0, maximum: this.yAxisMax, resolution: this.levelNode.resolution};
        this.shuttles	= [];
        //this.shuttles.push(new Shuttle(this.svg, this.stopLevelNode,    'left',     this.stopLevelNode.isWriteable,   'Stop',     undefined, undefined, visibleScale));
        //this.shuttles.push(new Shuttle(this.svg, this.startLevelNode,   'left',     this.startLevelNode.isWriteable,  'Start',    undefined, undefined, visibleScale));
        this.shuttles.push(new Shuttle(this.svg, this.levelNode,        'left',     this.levelNode.isWriteable,       ''))
        //this.shuttles.push(new Shuttle(this.svg, this.fullLevelNode,    'left',     this.fullLevelNode.isWriteable,   'Full',     undefined, undefined, visibleScale));
        //this.shuttles.push(new Shuttle(this.svg, this.stopFlowNode,     'bottom',   this.stopFlowNode.isWriteable,    'Stop'));
        //this.shuttles.push(new Shuttle(this.svg, this.fullFlowNode,     'bottom',   this.fullFlowNode.isWriteable,    'Full'));

        // Make the shuttle for the deadband which is a little weirder than the other deadbands. Not only do we
        // have to update the inner rectangle for this shuttle, but it will be reversed (drag down to increase)
        // if we are a source tank
        //this.deadbandShuttle	= new Shuttle(this.svg, this.deadbandNode, 'right', this.deadbandNode.isWriteable, 'Deadband', null, !this.fTarget, visibleScale);

        // Create shuttles that indicate the output flow and the current level. These exist outside the grabbable shuttles
        // in a second perimeter around the graph of flow versus level
        this.outputShuttle		= new Shuttle(this.svg, this.outputNode, 'bottom', false, undefined, 'greenShuttle');

        this.overhang			= {	// The overhang for the inner shuttles
                left:			0,
                top:			0,	// For the y axis label (plus four pixels of padding)
                right:			54,	// For the x axis label (plus four pixels of padding)
                bottom:			0,
                strokeWidth:	1};

        // The inner shuttle overhang is also used for the scales
        xAxisScale.getMetrics(this.overhang);
        yAxisScale.getMetrics(this.overhang);

        if (this.fShuttles) {
            for (var i = 0; i < this.shuttles.length; ++i)		// For each shuttle
                this.shuttles[i].getMetrics(this.overhang);		// Get how much it will overhang
        }
        //this.deadbandShuttle.getMetrics(this.overhang);		// Don't forget about our deadband shuttle

        this.outerhang	= {		// The overhang for the outer shuttles
            left:		0,
            top:		0,
            right:		0,
            bottom:		0
        };

        this.outputShuttle.getMetrics(this.outerhang);	// Get how much it will overhang

        //this.overhang.left		+= 5;	// Give the left and bottom of the outerhang five pixels of buffer
        //this.overhang.bottom	+= 5;

        var graphRect			= {	// This object describes where there graph of flow versus level lives in the SVG space
			width:		this.width - this.overhang.left - 24 /*- this.overhang.right*/ - this.outerhang.left,// - this.outerhang.right,
			height:		this.height - this.overhang.top - this.overhang.bottom - this.outerhang.top,
			left:		this.overhang.left + this.outerhang.left,
			top:		this.overhang.top + this.outerhang.top,
			right:		this.width - this.overhang.right - this.outerhang.right,
			bottom:		this.height - this.overhang.bottom};

        // Store the width and height of the graph element alone
        this.graphWidth			= graphRect.width;
        this.graphHeight		= graphRect.height;

        graphBackground.updateFill(0, 0, this.graphWidth, this.graphHeight);

        // We could calculate the all the box's parameters, but it only needs bottom, right, and height
        // because it is a shuttle aligned on the right of the box
        this.deadbandRect		= {	// This object describes where the deadband node's shuttle should translate in. This box changes
                height:		graphRect.height * this.deadbandNode.engMax / this.yAxisMax,	// This is the static height of the deadband rect
                right:		graphRect.right,
                bottom:		graphRect.bottom};

        // Put the necessary amount of whitespace around the graph based on the shuttle overhang
        this.graph.translate(graphRect.left, graphRect.top);

        // Draw the axes one time only
        createSVGElement('line', null, this.graph).updateLine(0, 0, 0, graphRect.height);
        createSVGElement('line', null, this.graph).updateLine(0, graphRect.height, graphRect.width, graphRect.height);

        // Write out the axis labels one time only
        createSVGElement('text', 'tankGraphAxisLabel', this.background).updateLabel(graphRect.left + 5, graphRect.top - 4, (this.fLabels? 'Level [' + this.levelNode.getUnitsText() + ']' : ''));
        createSVGElement('text', 'tankGraphAxisLabel', this.background).updateLabel(graphRect.right + 4, graphRect.bottom, (this.fLabels? 'Flow [' + this.outputNode.getUnitsText() + ']' : ''));

        var svgRect				= {	// A rectangle representing our canvas as a whole
                width:		this.width,		// Give them the width
                height:		this.height,	// Give them the height
                left:		0,
                top:		0,
                right:		this.width,
                bottom:		this.height};

        // Call render on all the scales first so that the appear behind the shuttles
        xAxisScale.render(graphRect, graphRect);
        yAxisScale.render(graphRect, graphRect);

        // Call render on each shuttle in the inner circle of shuttles
        if (this.fShuttles) {
            for (var i = 0; i < this.shuttles.length; ++i)					// For each shuttle
                this.shuttles[i].render(graphRect, null, svgRect);			// Make it instantiate itself
            //this.deadbandShuttle.render(this.deadbandRect, null, svgRect);	// Don't forget about our deadband shuttle
        }

        // These lines don't go on the graph, but on the background
        this.svgFlow			= createSVGElement('line', 'tankGraphDottedLine', this.background);

        // Create three lines that will show our 'Z': that is, our current operation situation. This is created
        // after the axis lines so that they will appear on top of them
        this.svgZHighLine		= createSVGElement('line', 'tankGraphZLine', this.graph);
        this.svgZMidLine		= createSVGElement('line', 'tankGraphZLine', this.graph);
        this.svgZLowLine		= createSVGElement('line', 'tankGraphZLine', this.graph);

        // These guys can have arrows indicating direction on them. Create an array to hold the arrows
        this.svgZHighLine.arrows	= [];
        this.svgZLowLine.arrows		= [];

        // Also create a small circle that will show the exact point the output flow is being produced at
        this.svgZPoint			= createSVGElement('g', 'tankGraphZReticle', this.graph);
        createSVGElement('circle', null, this.svgZPoint, {r:6});
        createSVGElement('line', null, this.svgZPoint, {x1: 0, y1: -6, x2: 0, y2: 6});
        createSVGElement('line', null, this.svgZPoint, {x1: -6, y1: 0, x2: 6, y2: 0});

        // Create our outermost shuttles. First, we adjust a few graphRect parameters to get this guys
        // trending along the right line. We need to fool these shuttles to act upon a line parallel to the
        // inner line of shuttles. This second line needs to have the same travel distance
        var outputRect = {	width:		graphRect.width,
                            height:		graphRect.height,	// Give them the height
                            left:		this.overhang.left + this.outerhang.left,
                            top:		graphRect.top,
                            right:		graphRect.right,
                            bottom:		this.height - this.outerhang.bottom};
        if (this.fShuttles)
            this.outputShuttle.render(outputRect, null, svgRect);	// Make our output flow label instantiate itself
    };

    drawLines(width, height) {
        var topCurveHighPoint;		// The highest point on the parallelogram
        var bottomCurveLowPoint;	// The lowest point on the parallelogram
        var highDeadbandX;			// The x-value of the higher side of the parallelogram
        var lowDeadbandX;			// The x-value of the lower side of the parallelogram
        var signedDeadband;			// The deadband value with the appropriate sign such that you can add it to this.fullY and stay on the parallelogram
        var fPastFullFlow;			// True if we are beyond the full level of the parallelogram

        if (this.fTarget) {
            topCurveHighPoint	= this.stopY;		// Highest point is the stop flow
            bottomCurveLowPoint = this.fullY;		// Lowest point is the full flow
            highDeadbandX		= this.minFlowX;	// The high deadband side is on the min flow side
            lowDeadbandX		= this.maxFlowX;	// The low deadband side is on the max flow side
            signedDeadband		= -this.deadbandY;
            fPastFullFlow		= this.levelY > this.fullY;
        } else {
            topCurveHighPoint	= this.fullY;		// Highest point is the full flow
            bottomCurveLowPoint	= this.stopY;		// Lowest point is the stop flow
            highDeadbandX		= this.maxFlowX;	// The high deadband side is on the max flow side
            lowDeadbandX		= this.minFlowX;	// The low deadband side is on the min flow side
            signedDeadband		= this.deadbandY;
            fPastFullFlow		= this.levelY < this.fullY;
        }

        // Based on the highest and lowest points, calculate the other two points of the parallelogram
        var bottomCurveHighPoint	= topCurveHighPoint + this.deadbandY;
        var topCurveLowPoint		= bottomCurveLowPoint - this.deadbandY;

        // Update our parallelogram
        this.svgHighDeadband.updateLine(highDeadbandX, topCurveHighPoint, highDeadbandX, bottomCurveHighPoint);
        this.svgLowLine.updateLine(highDeadbandX, bottomCurveHighPoint, lowDeadbandX, bottomCurveLowPoint);
        this.svgLowDeadband.updateLine(lowDeadbandX, bottomCurveLowPoint, lowDeadbandX, topCurveLowPoint);
        this.svgHighLine.updateLine(lowDeadbandX, topCurveLowPoint, highDeadbandX, topCurveHighPoint);

        // Calculate where the start level's dotted line should end.
        // Clamp the start point to be on the curve and not extrapolate off of it
        var startX =  this.minFlowX + (this.startY - this.stopY + signedDeadband) * (this.maxFlowX - this.minFlowX) / (this.fullY - this.stopY + signedDeadband);
        if (startX < this.minFlowX)
            startX = this.minFlowX;
        if (startX > this.maxFlowX)
            startX = this.maxFlowX;

        // Update the dotted lines
        this.svgStopLevel.updateLine(0, this.stopY, this.minFlowX, this.stopY);
        this.svgStartLevel.updateLine(0, this.startY, startX, this.startY);
        this.svgFullLevel.updateLine(0, this.fullY, this.maxFlowX, this.fullY);
        this.svgMinFlow.updateLine(lowDeadbandX, height, lowDeadbandX, bottomCurveLowPoint);
        this.svgMaxFlow.updateLine(highDeadbandX, height, highDeadbandX, bottomCurveHighPoint);

        // Update our current operating line
        if (this.outputX == 0) {	// There is no current output value. We draw this line differently
            var lowEdgeY = Math.max(this.startY, bottomCurveHighPoint);					// Either we draw the line to the start level or the stop level minus deadband.
            this.svgZHighLine.updateLine(0, this.levelY, 0, lowEdgeY);					// Draw a vertical line from the operating point to the start point
            this.svgZMidLine.updateLine(0, lowEdgeY, startX, lowEdgeY);					// Draw a horizontal line along the startY
            this.svgZLowLine.updateLine(startX, lowEdgeY, this.maxFlowX, this.fullY);	// Draw a line along the output increasing line of the parallelogram
        } else if (fPastFullFlow) {	// We are operating outside the parallelogram on purpose
            this.svgZHighLine.updateLine(this.minFlowX, this.stopY, this.maxFlowX, this.fullY + signedDeadband);// Draw the stop level's side of the parallelogram
            this.svgZMidLine.updateLine(this.maxFlowX, this.fullY + signedDeadband, this.maxFlowX, this.fullY);	// Draw a vertical along the full's deadband side
            this.svgZLowLine.updateLine(this.maxFlowX, this.fullY, this.maxFlowX, this.levelY);					// Draw a vertical line from the level to the full point
        } else {					// We're in normal operation in the middle of the parallelogram
            // Calculate the intercepts for the output flow line
            var yIntercept		= (this.outputX - this.minFlowX) / (this.maxFlowX - this.minFlowX) * (this.fullY + signedDeadband - this.stopY);
            var topIntercept	= this.stopY + yIntercept;
            var bottomIntercept	= topIntercept - signedDeadband;

            this.svgZHighLine.updateLine(this.minFlowX, this.stopY, this.outputX, topIntercept);	// Draw a line along the top of the parallelogram
            this.svgZMidLine.updateLine(this.outputX, topIntercept, this.outputX, bottomIntercept);	// Draw a vertical line along the outputY
            this.svgZLowLine.updateLine(this.outputX, bottomIntercept, this.maxFlowX, this.fullY);	// Draw a line along the bottom of the parallelogram
        }

        // Update the circle on the 'z' that shows where the output flow meets the level(
        if (this.outputX && this.levelY) this.svgZPoint.translate(this.outputX, this.levelY);

        // Create or delete arrows showing directions for parts of our 'z' line
        this.manageArrows(this.svgZHighLine, true);
        this.manageArrows(this.svgZLowLine, false);

        // Update the dotted lines not on the graph
        var outputXGlobal = this.outputX + this.outerhang.left + this.overhang.left;
        this.svgFlow.updateLine(outputXGlobal, this.height - this.outerhang.bottom, outputXGlobal, this.height - this.outerhang.bottom - this.overhang.bottom);

        // Update the blue fill that represent the tank level
        this.svgLevelFill.updateFill(0, this.levelY, width, height - this.levelY);
    };

    manageArrows(line, fTurnArrowAround) {
        var x1				= parseInt(line.getAttribute('x1'));	// Get line parameters
        var x2				= parseInt(line.getAttribute('x2'));
        var y1				= parseInt(line.getAttribute('y1'));
        var y2				= parseInt(line.getAttribute('y2'));

        // Pythagorean theorem to get line length
        var length			= Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));
        var slope			= (y2 - y1) / (x2 - x1);
        var arrowCount		= 0;		// Preset the arrow count to zero

        if ((x1 != x2) && (y2 != y)) {	// We don't want to add arrows to vertical or horizontal lines
            var gaps		= Math.floor(length / 40);	// Space the center of the arrows 40 pixels apart
            if (gaps > 1)				// If we have room for a bunch of lines
                arrowCount	= gaps - 1;	// Put in as many arrows as we can
            else if (gaps == 1)			// If we have enough room for one full gap only
                arrowCount	= 1;		// Go ahead a put an arrow there
            // Else, gaps is zero. We don't have enough room for even a full space. Don't put anything in
        }

        while (line.arrows.length < arrowCount)	// Check if we need to add arrows to this guy
            line.arrows.push(createSVGElement('path', 'tankGraphZLine', this.graph, {d:'M 0 -4 l 2 8 l -4 0 l 2 -8'}));

        while (arrowCount < line.arrows.length)	// Check if we need to remove arrows from this guy
            this.graph.removeChild(line.arrows.pop());

        // Space the arrows evenly along the line properly and rotate them to point along the line
        var arrowOffset		= length / (arrowCount + 1);		// Space the arrows evenly along the line
        var arrowX			= (x2 - x1) * arrowOffset / length;	// The x-component of the change between triangles
        var arrowY			= arrowX * slope;					// The y-component of the change between triangles
        var x				= x1;								// Can't forget the initial offset
        var y				= y1;								// Can't forget the initial offset

        var degrees			= 90 + Math.atan(slope) * 180 / Math.PI;	// Calculate the angle from the negative y axis (which goes up) in degrees
        if (fTurnArrowAround)									// If the arrow should point the other way
            degrees			-= 180;								// Add half a circle so it faces the other way

        for (var i = 0; i < line.arrows.length; ++i) {
            x				+= arrowX;							// Each triangle is an additional gap over
            y				+= arrowY;

            // Add a transformation that moves the triangle along the line and points it along the line
            line.arrows[i].setAttributeNS(null, 'transform', 'translate(' + x + ',' + y + '), rotate(' + degrees + ')');
        }
    };

    createDescription() {
        this.description.innerHtml = `
        This tank is set to
        `
    }

    destroy() {
        if (this.fShuttles) {
            for (var i = 0; i < this.shuttles.length; ++i)
                this.shuttles[i].destroy();	// Destroy all of our shuttles

            if (this.deadbandShuttle) {
                this.deadbandShuttle.destroy();
                this.outputShuttle.destroy();
            }
        }

        if (this.callback)			// Cancel our onJobCallback if it was created
            owner.removeJobCompletedCallback(this.callback);

        this.nodeManager.destroy();	// Unsubscribe to all the nodes we subscribed to
        this.unregisterAsWidget();	// Unregister with the element like a good little widget
    };
};


