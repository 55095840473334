import ArrowIcon        from "../images/icons/arrow_down_filled.svg";
import DesktopIcon      from "../images/icons/desktop.svg";
import AddIcon          from "../images/icons/add.svg";
import StyleIcon        from "../images/icons/style.svg";
import TagIcon          from "../images/icons/tags.svg";
import SettingsIcon     from "../images/icons/settings.svg";
import SaveIcon         from "../images/icons/save.svg";
import ShareIcon        from "../images/icons/public.svg";
import DeviceIcon       from "../images/icons/device.svg";
import MoveIcon         from "../images/icons/drag.svg";
import ResizeIcon       from "../images/icons/aspect.svg";
import ArrowBackIcon    from "../images/icons/double_arrow_left.svg";
import MoreIcon         from "../images/icons/more.svg";
import DeleteIcon       from "../images/icons/delete.svg";
import Page             from "./page";
import owner, { Routes }from "../../owner";
import { createElement }from "../elements";
import ViewModal        from "../viewmodal";
import LiveData         from '../livedata';
import Dialog           from "../dialog";
import './editorpage.css';
import FrameParser      from "../frameparser";
import { TabManager }   from "../components/tabmanager";
import { RadioSelector, RadioButton } from '../components/radio';
import DashboardView, { MediaQueries } from '../views/widgetdashboardview';
import { AttributeEditorView, DashboardStyleView, StyleCategories } from '../views/attributeeditorview';
import Dropdown from "../components/dropdown";
import ReportScheduleView from "../views/reportscheduleview";
import SharingSettingsView from '../views/sharingsettingsview';
import { DevicesSettingsView } from "../views/deviceselectview";
import { TagSocketView } from "../views/widgettagview";
import tippy from 'tippy.js';
import { Widget, Widgets, defaultAttrValues } from "../widgets/lib/widget";
import { v4 as uuidv4 } from 'uuid';
import { RootElement } from '../widgets/hmiroot';
import HTMLSanitizer from "../htmlsanitizer";
import { ExtendedAttributeMetadata, attrMetadataSymbol } from "../widgets/lib/attributes";
import { tagAttributeMetadataSymbol, tagSetAttributeMetadataSymbol } from "../widgets/lib/tag";
import ContainerIcon from '../images/icons/container.svg';
import assert from "../debug";
import { Treeable } from "../views/treeview";
import { getHash } from "../router/router";
import TextIcon from '../images/icons/text.svg';

interface EditorPageProps {
    id?: number;
}

//FIXME: capture styles in cross-dashboard copy/paste
//FIXME: get resize/delete/move on overlay working again
//FIXME: port over existing dashboards
//FIXME: get all setting inputs working for objects
//FIXME: undo/redo
interface DashboardHistory {
    text:       string;
    trace:      string;
}

const NameAttribute = 'hmi-name';
const StyleID = '_widget_styles';

export interface Dashboard {
    id                  : number;
    creator             : string;
    name                : string;
    companyKey          : string;
    fPrivate            : boolean;
    fWrites             : boolean;
    sharedUsers         : Map<string, ShareSettings>;
    reportUsers         : Map<string, boolean>;
    devices             : string[];                 // list of device keys for device specific or templated dashboards
    assets              : Map<string, string>;
    thumb               : string;
    fReports            : boolean,
    reportFrequency     : number;
    reportFrequencyMod  : number;
    reportHourOffset    : number;
    reportSize          : number;
    reportTimezone      : string;
    version             : number;
}

export interface DashboardTreeable extends Treeable {
    id: number;
}

/**
 *The Page that allows us to create and modify dashboards
 *
 * @export
 * @class EditorPage
 * @extends {Page}
 */
export default class WidgetEditorPage extends Page {
    props: EditorPageProps;
    wrapper: HTMLElement;
    nav:  HTMLElement;
    preview: HTMLElement;
    addPanel: HTMLElement;
    addButton: HTMLImageElement;
    toolPanel: HTMLElement;
    viewWrapper: HTMLElement;
    tree: ContainerTree;
    settingsTabs: HTMLElement[] = [];
    styleEditor: HTMLElement;
    sectionMap: Map<string, HTMLElement> = new Map();
    styleView: DashboardStyleView;
    attributeView: AttributeEditorView;
    addOptionsMap: Map<HTMLElement, string> = new Map();
    toolTabs: TabManager;
    aspectButtons: RadioSelector;
    graphID: number;
    history: DashboardHistory[] = [];
    traceIndex: number = 1;
    selectedElement: HTMLElement | null = null;
    view: DashboardView;
    overlay: WidgetOverlay;
    saveButton: HTMLElement;
    aspect: number = 0;
    keyListener(e: KeyboardEvent) {};
    dragListener() {};
    cancelListener() {};
    upListener() {};
    anyUpListener: ()=>void;
    anyClickListener: ()=>void;
    copiedElement: Element | null;
    observer: MutationObserver;
    settingsWrapper: HTMLElement;
    querySelector: RadioSelector;
    socketView: TagSocketView;
    rootElement: RootElement;
    selectedStyleObject: {[key: string] : string};
    sanitizer: HTMLSanitizer;
    addWrapper: HTMLElement;
    clickHandler: HTMLElement;
    hoveredElement: HTMLElement;
    hoverOverlay: Overlay;
    customStyles: HTMLStyleElement;
    constructor(parent: HTMLElement, props?: EditorPageProps) {
        super(parent);
        owner.navBar.title.textContent = 'HMI Editor'
        this.parent     = parent;
        this.props      = { ...props };
        this.wrapper    = createElement('div', 'editor__wrapper', this.parent);     // wrapper element for all page elements
        this.nav        = createElement('div', 'editor__nav', this.wrapper);
        let content     = createElement('div', 'editor__content', this.wrapper);
        this.addPanel   = createElement('div', 'editor__add', content);
        this.addPanel.setAttribute('open', 'true');
        this.addWrapper = createElement('div', 'editor__add__wrapper', this.addPanel)

        this.preview    = createElement('div', 'editor__preview', content);
        this.toolPanel  = createElement('div', 'editor__tool', content);
        this.graphID    = owner.ldc.registerGraph(this);
        this.sanitizer  = new HTMLSanitizer(undefined, true);

        this.viewWrapper    = createElement('div', 'editor__preview__window', this.preview);

        let tree                = createElement('div', 'editor__tree', this.toolPanel);
        this.settingsWrapper    = createElement('div', 'editor__settings hide', this.toolPanel);
        this.addButton          = createElement('img', 'editor__nav__button', this.nav, '', {'src':ArrowBackIcon});
        tippy(this.addButton, {
            content  : 'Toggle Components Panel',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        //this.anyClickListener = () =>         console.log(this.view.gizmoContainer.getStyles(this.selectedElement!)); //TODO: Do we need this?
        //this.pushTrace();
        this.keyListener = (e: KeyboardEvent) => this.onKeyDown(e);

        this.sectionMap.set('Basic', this.createSection('Basic'));

        this.createRecipeButton('div', 'Container', 'Layout', ContainerIcon);
        this.createRecipeButton('p', 'Text', 'Basic', TextIcon);

        for (let properties of Widgets) {
            this.createRecipeButton(properties.tag, properties.displayName, properties.section, properties.icon);
        }


        this.toolTabs = new TabManager(this.settingsWrapper, '', [
            {name: 'Tags', icon: TagIcon, displayName: 'Tags'},
            {name: 'Styles', icon: StyleIcon, displayName: 'Style'},
            {name: 'Settings', icon: SettingsIcon, displayName: 'Settings'},
        ]);

        this.styleView  = new DashboardStyleView(this).initialize(this.toolTabs.getSectionByName('Styles'));    // Instantiate but don't initialize yet so our radio selector is above this view
        this.socketView = new TagSocketView(owner.ldc).initialize(this.toolTabs.getSectionByName('Tags'));
        this.attributeView = new AttributeEditorView((attributes) =>
        {
            if (this.selectedElement instanceof Widget)
                for (let [attrName, metadata] of this.selectedElement.constructor[attrMetadataSymbol])
                    if (typeof attributes[attrName] !== 'undefined')
                        this.selectedElement.setAttribute(attrName, attributes[attrName]);
                    else
                        this.selectedElement.removeAttribute(attrName);
            requestAnimationFrame(() => this.overlay.resize())
        }).initialize(this.toolTabs.getSectionByName('Settings'))

        let queries: RadioButton[] = [];
        for (let i=0;i<MediaQueries.length;++i) {
            let query = MediaQueries[i];
            if (query.name.includes('Landscape'))
                continue;
            queries.push({
                name: query.name,
                icon: query.icon,
                displayName: '',
                id: i,
                tooltip: `<strong>${query.maxW == Infinity ? query.minW : query.maxW}px breakpoint</strong> <br>Styles set here will apply to windows sized ${query.maxW == Infinity ? query.minW : query.maxW}px and under (unless a smaller breakpoint overrides it).`
            })
        }

        this.querySelector = new RadioSelector(createElement('div', 'editor__nav__aspect', this.nav), {
            title: '',
            buttons:  queries,
            defaultSelection: queries[0].name,
            changeCallback: (name) => {
                if (!this.view || !this.view.fInitialized)
                    return;
                for (let i=0;i<MediaQueries.length;++i) {
                    let query = MediaQueries[i];
                    if (query.name == name) {
                        this.aspect = i;
                        this.resize();
                        this.refreshSelectedStyles();
                        break;
                    }
                }
            }
        });

        this.addButton.onclick = () => {
            if (this.addPanel.getAttribute('open') == 'true')
                this.closeAddPanel();
            else
                this.openAddPanel();
        }

        let shareButton = createElement('img', 'editor__nav__button', this.nav, '', {'src':ShareIcon});
        tippy(shareButton, {
            content  : 'Sharing Settings',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        shareButton.onclick = (e: MouseEvent) => {
            let pendingSharedUsers = new Map(this.view.dashboard.sharedUsers);   // make a copy of our existing sharing settings
            let fPrivate = this.view.dashboard.fPrivate ? true : false;          // make a copy of our private setting
            let fWrites  = this.view.dashboard.fWrites ? true : false;           // make a copy of our view only setting
            let companyKey = this.view.dashboard.companyKey + "";                // make a copy of our company key
            new ViewModal(SharingSettingsView.bind(undefined, document.body, this.view.dashboard.sharedUsers, this.view.dashboard), {
                title: 				    'Sharing Settings',
                titleTextColor:	        'var(--color-inverseOnSurface)',
                titleBackgroundColor: 	'var(--color-primary)',
                maxWidth:               '400px',
                maxHeight:              '600px',
                buttons: [
                    {   // green button that saves the sharing settings
                        title:'Save',
                        callback:()=> {
                            this.saveDashboard();
                            return true;
                        },
                        borderColor: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the sharing settings changes
                        title:'Cancel',
                        borderColor: owner.colors.hex('--color-error'),
                        callback:()=> {
                            this.view.dashboard.sharedUsers  = pendingSharedUsers;
                            this.view.dashboard.fPrivate     = fPrivate;
                            this.view.dashboard.fWrites      = fWrites;
                            this.view.dashboard.companyKey   = companyKey;
                            return true;
                        }
                    }
                ],
            });
        };
        let deviceButton    = createElement('img', 'editor__nav__button', this.nav, '', {'src':DeviceIcon});
        tippy(deviceButton, {
            content  : 'Add to Device',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        deviceButton.onclick = () => {
            let pendingDevices = [...this.view.dashboard.devices];   // make a copy of our existing sharing settings
            //@ts-ignore TODO: Remove this ignore once we migrate all dashboards over
            new ViewModal(new DevicesSettingsView(this.view.dashboard.devices, this.view.dashboard, owner.ldc), {
                title: 				    'Add To Device',
                titleTextColor:	        'var(--color-inverseOnSurface)',
                titleBackgroundColor: 	'var(--color-primary)',
                maxWidth:               '400px',
                maxHeight:              '600px',
                buttons: [
                    {   // green button that saves the new device settings
                        title:'Save',
                        callback:()=> {
                            this.saveDashboard();
                            return true;
                        },
                        borderColor: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the new device settings
                        title:'Cancel',
                        borderColor: owner.colors.hex('--color-error'),
                        callback:()=> {
                            this.view.dashboard.devices      = pendingDevices;
                            return true;
                        }
                    }
                ],
            });
        }
        //createElement('img', 'editor__nav__button', this.nav, '', {'src':UndoIcon}).onclick = () => this.undo();
        //createElement('img', 'editor__nav__button', this.nav, '', {'src':RedoIcon}).onclick = () => this.redo();
        this.saveButton = createElement('img', 'editor__nav__button', this.nav, '', {'src':SaveIcon});
        tippy(this.saveButton, {
            content  : 'Save',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        this.saveButton.onclick = () => this.saveDashboard();

        createElement('img', 'editor__nav__button', this.nav, '', {'src':MoreIcon}).onclick = (e) => {
            new Dropdown(e, ['Delete Dashboard', 'Rename Dashboard', 'Import Config', 'Export Config', 'Reports'], (selection: string) => {
                switch (selection) {
                    case 'Delete Dashboard':
                        this.deleteDashboard();
                    break;
                    case 'Rename Dashboard':
                        this.renameDashboard();
                    break;
                    case 'Print Dashboard':
                        this.printDashboard(850, 1100);
                    break;
                    case 'Import Config':
                        this.importConfig();
                    break;
                    case 'Export Config':
                        this.exportConfig();
                    break;
                    case 'Reports':
                        new ViewModal(ReportScheduleView.bind(undefined, undefined, this.view.dashboard, owner.ldc.user.fWizard ? this.view.dashboard.companyKey : owner.ldc.user.companyKey), {
                            title: 				    'Report Scheduler',
                            titleTextColor:		    'var(--color-inverseOnSurface)',
                            titleBackgroundColor: 	'var(--color-primary)',
                            maxWidth:               '1200px',
                        });
                    break;
                }
            })
        };

        if (this.props.id) { // if we are provided a dashboard id from our route, ask for it from wv
            this.view   = new DashboardView(owner.ldc, this.props.id, this, ()=>{
                this.clickHandler   = createElement('div', 'editor__click-handler', this.view.wrapper);
                this.hoverOverlay   = new Overlay(this, owner.colors.hex('--color-blue-3'));
                //let children = this.view.gizmoContainer.getAllDescendantElements();
                //children.forEach(child => this.addClickHandler(child));
                this.overlay    = new WidgetOverlay(this);
                this.tree       = new ContainerTree(tree, this, this.view.dashboardWrapper);
                this.observer   = new MutationObserver(mutationRecords => {
                    requestAnimationFrame(()=>this.overlay.refresh())
                });

                  // observe everything except attributes
                this.observer.observe(this.view.dashboardWrapper, {
                    attributes: true,
                    attributeOldValue: true,
                    childList: true,    // observe direct children
                    subtree: true,      // and lower descendants too
                    characterDataOldValue: true // pass old data to callback
                });
                this.tree.refresh();
                this.createEventListeners();
            }).initialize(this.viewWrapper);
        }
        else { // if we are not provided a dashboard id, create a new one
            let dialogProperties = {
                title: 'New HMI',
                body:  'Please enter a name for your new HMI.',
                fText:  true,
                titleBackground: 'var(--color-primary)',
                titleColor: 'var(--color-inverseOnSurface)',
                buttons: [
                    {   // green button that creates a new dashboard when clicked
                        title:'Create HMI',
                        callback:(name: string)=> this.createDashboard(name),
                        color: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                        title:'Cancel',
                        callback:() => window.history.back(),
                        color: owner.colors.hex('--color-error')
                    }
                ]
            }
            new Dialog(document.body, dialogProperties);
        }
    }

    refreshSelectedStyles()
    {
        if (!this.selectedElement)
            return;
        this.styleView.setSettingsToEdit();
    }

    applyStyles()
    {
        let rule = this.getStyles(this.selectedElement!);
        if (rule === null)
            return;
        let styleString = '';
        let text = Object.entries(this.selectedStyleObject).forEach((key, value) => {
            styleString += `${key}:${value};`
        })
        rule.cssText = styleString;
    }

    createEventListeners() {
        addEventListener('scroll', this.overlay.resize, true);
        addEventListener('keydown', this.keyListener);
        addEventListener('mouseup', this.anyClickListener);
        addEventListener('resize', this.resize.bind(this));
        //this.preview.onmousedown = () => { // Poor man's click listener (because resize and move overlay listeners)
        //    let upCallback = () => {
        //        debugger
        //        this.preview.removeEventListener('mouseup', upCallback);
        //        this.onSelectionChanged(null);
        //    }
        //    this.preview.addEventListener('mouseup', upCallback);
        //}

        let enterListener = () => {
            let moveListener = (e: MouseEvent) => {
                let element = this.findFirstHovered(e.pageX, e.pageY);
                this.hoverOverlay.hmiElement = element;
            }
            let leaveListener = () => {
                if (this.hoveredElement)
                    this.hoveredElement.style.backgroundColor = '';
                this.clickHandler.removeEventListener('mouseleave', leaveListener);
                this.clickHandler.removeEventListener('mousemove', moveListener);
            }
            this.clickHandler.addEventListener('mouseleave', leaveListener);
            this.clickHandler.addEventListener('mousemove', moveListener);
        }

        this.clickHandler.addEventListener('mouseenter', enterListener);
        this.clickHandler.onclick = (e) => {
            let element = this.findFirstHovered(e.pageX, e.pageY);
            if (element === this.selectedElement) {
                if (this.selectedElement?.parentElement === this.view.dashboardWrapper)
                    this.onSelectionChanged(null);
                else
                    this.onSelectionChanged(this.selectedElement?.parentElement ?? null);
            }
            else
                this.onSelectionChanged(element);
        }

    }

    findFirstHovered(x: number, y: number, type?: typeof HTMLElement): HTMLElement | null {
        let hoverElements = document.elementsFromPoint(x, y);
        let element: HTMLElement | null = null;
        let inTheSandwich = false;
        for (let i=0;i<hoverElements.length;++i) {                      // loop through the elements under the cursor
            if (hoverElements[i] === this.clickHandler)
                inTheSandwich = true;
            else if (hoverElements[i] === this.view.dashboardWrapper)
                return null;
            else if (inTheSandwich)
                return hoverElements[i] as HTMLElement;
        };
        return element;
    }

    async onKeyDown(e: KeyboardEvent) {
        let ctrl = e.ctrlKey ? e.ctrlKey : ((e.key === 'Meta') ? true : false); // ctrl detection
        if (e.target instanceof HTMLInputElement && document.hasFocus() && e.target === document.activeElement)
            return;

        else if (e.key === 'Backspace' || e.key === 'Delete') {
            this.selectedElement?.parentElement?.removeChild(this.selectedElement);
            this.tree.refresh();
            this.onSelectionChanged(null);
        }
        else if (e.key == 'Escape') {
            this.onSelectionChanged(null);
        }
        else if (ctrl) {
            if ( (e.key == 'c' || e.key == 'C')) {
                if (this.selectedElement) {
                    navigator.clipboard.writeText(this.selectedElement.outerHTML);
                }
            }
            else if ( (e.key == 'v' || e.key == 'V')) {
                try {
                    let recipeText = await navigator.clipboard.readText()
                    let parentElement: HTMLElement;
                    if (this.selectedElement) {
                        if (this.selectedElement instanceof HTMLDivElement)    // else, if we have selected a new gizmo and it is a container
                            parentElement = this.selectedElement;              // create the new gizmo inside the selected container
                        else
                            parentElement = this.selectedElement.parentElement ?? this.view.dashboardWrapper;
                    }
                    else
                        parentElement = this.view.dashboardWrapper;

                    let tmp = createElement('div');
                    tmp.innerHTML = recipeText;
                    for (let child of tmp.children)
                        parentElement.appendChild(child);
                    //this.sanitizer.setHTML(parentElement, recipeText)
                    this.tree.refresh();
                }
                catch {
                    return;
                }
            }
            else if ( (e.key == 'z' || e.key == 'Z')) {
                e.preventDefault();
                if (e.shiftKey)
                    this.redo();
                else
                    this.undo();
            }
            else if ( (e.key == 's' || e.key == 'S')) {
                e.preventDefault();
                this.saveDashboard();
            }
        }

        else
            this.pushHistory();
    }

    deleteElement(element: HTMLElement) {
        element.parentElement?.removeChild(element);
        this.tree.refresh();
    }

    createRecipeButton(tagName: string, displayName: string, section?: string, icon?: string) {
        if (section && !this.sectionMap.has(section))
            this.sectionMap.set(section, this.createSection(section));

        let button      = createElement('div', 'editor__view-options__container');
        createElement('img', 'editor__view-options__icon', button, '', {'src':icon ?? DesktopIcon});
        createElement('div', 'editor__view-options__text', button,  displayName);
        button.setAttribute('data-tippy-content', displayName);
        button.addEventListener('mousedown', this.onMouseDown.bind(this));
        this.sectionMap.get(section ? section : 'Basic')?.append(button);
        this.addOptionsMap.set(button, tagName);
    }

    createDashboard(name: string) {
        let dashboard = {
            id:                 0,
            creator:            owner.ldc.user.username,
            name:               name,
            companyKey:         owner.ldc.user.companyKey,
            fPrivate:           true,
            fWrites:            false,
            assets:             new Map(),
            sharedUsers:        new Map(),
            devices:            [],
            thumb:              '',
            fReports:           false,
            reportFrequency:    1,
            reportFrequencyMod: 0,
            reportHourOffset:   13,
            reportSize:         0,
            reportTimezone:     'America/Chicago',
            recipes:            [],
            version:            4
        }
        let data = '';
        owner.ldc.fm.buildFrame(LiveData.WVC_CREATE_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_string(dashboard.name);
        owner.ldc.fm.push_u8(dashboard.fPrivate? 1 : 0);
        owner.ldc.fm.push_u8(dashboard.fWrites? 1 : 0);
        owner.ldc.fm.push_u16(dashboard.version);
        owner.ldc.fm.push_string(data);
        owner.ldc.fm.push_u32(dashboard.devices.length); // number of devices (should always be zero here);
        owner.ldc.send();
    }

    /**
     * Method called after whoville response to dashboard creation frame.
     * @param  {FrameParser} fp
     */
    onCreateDashboardResponse(fp: FrameParser) {
        debugger;
        let success = fp.pop_u8() == 1;
        if (success) {
            let id      = fp.pop_u32();
            let link    = getHash(Routes.WidgetCreator, {'id':id.toString()});
            window.location.hash = link;
        }
        else {
            let dialogProperties = {
                title:  'Failed to Create Dashboard'
            }
            new Dialog(document.body, dialogProperties);
        };
    };

    private async saveDashboard()
    {
        this.serializeStyles(); // We potentially have css in the css object model that isn't serialized to the DOM
		let data = this.view.dashboardWrapper.innerHTML;

        const stream = new Blob([data], {
            type: 'text/html',
        }).stream();
        //@ts-ignore
        const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
        const arrayBuffer = await new Response(compressedReadableStream).blob().then(result => result.arrayBuffer());

        console.log(`uncompressed: ${data.length}`);
        console.log(`compressed: ${arrayBuffer.byteLength}`);

        owner.ldc.fm.buildFrame(LiveData.WVC_MODIFY_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_u32(this.view.dashboard.id);
		owner.ldc.fm.push_string(this.view.dashboard.name);
        owner.ldc.fm.push_u16(4);
        owner.ldc.fm.push_string(this.view.dashboard.companyKey ? this.view.dashboard.companyKey : "");
        owner.ldc.fm.push_u8(this.view.dashboard.fPrivate ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.fWrites ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.fReports ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.reportFrequency);
        owner.ldc.fm.push_u16(this.view.dashboard.reportFrequencyMod);
        owner.ldc.fm.push_u8(this.view.dashboard.reportHourOffset);
        owner.ldc.fm.push_u8(this.view.dashboard.reportSize);
        owner.ldc.fm.push_string(this.view.dashboard.reportTimezone);
		owner.ldc.fm.push_u32(arrayBuffer.byteLength);
        owner.ldc.fm.push_buffer(arrayBuffer);
        owner.ldc.fm.push_u16(this.view.dashboard.sharedUsers.size);
        this.view.dashboard.sharedUsers.forEach((settings, username)=> {
            owner.ldc.fm.push_string(username);
            owner.ldc.fm.push_u8(settings.fAccess ? 1 : 0);
            owner.ldc.fm.push_u8(settings.fWrites ? 1 : 0);
            owner.ldc.fm.push_u8(this.view.dashboard.reportUsers.get(username) ? 1 : 0)
        })
        owner.ldc.fm.push_u16(this.view.dashboard.devices.length);
        for (let i=0;i<this.view.dashboard.devices.length;++i)
            owner.ldc.fm.push_string(this.view.dashboard.devices[i]);
        owner.ldc.fm.push_u16(this.view.dashboard.assets.size);
        for (let uuid of this.view.dashboard.assets.keys()) {
            owner.ldc.fm.push_string(uuid);
        }
		owner.ldc.send();								// Send the message
    }

    onModifyDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;

        if (success)
            this.saveButton.style.filter = 'invert(54%) sepia(79%) saturate(470%) hue-rotate(115deg) brightness(95%) contrast(88%)';
        else
            new Dialog(document.body, {
                title: 'Error',
                body: 'An error occurred while trying to save the dashboard. Please wait a moment and try again.'
            })
    }

    undo() {
        //TODO: this
    }

    redo() {
        //TODO: this
    }

    exportConfig() {
		let data    = this.view.dashboardWrapper.innerHTML;
        //let config  = new Blob([data], {type: 'text/html'});
        let downloadLink = document.createElement('a');
		downloadLink.download = this.view.dashboard.name + '.html';			// File name to download as
		downloadLink.href = 'data:text/html;charset=utf-8,' + encodeURIComponent(data!);
		downloadLink.click();							// Simulate clicking on the hyperlink
    }

    importConfig() {
        let srcInput        = createElement('input', '', undefined, '', {'type':'file', 'accept':'text/html'});
        srcInput.onchange   = () => {
            let reader = new FileReader();
            reader.onload = (e) => {
                try {
                    this.sanitizer.setHTML(this.view.dashboardWrapper, reader.result as string);
                    this.tree.refresh();
                } catch {
                    new Dialog(document.body, {
                        title: 'Invalid Config File',
                        body: 'There was an issue loading the dashboard. Please check your config file and try again.'
                    });
                }
            };
            reader.readAsText(srcInput.files![0]);
        }
        srcInput.click();
    }

    deleteDashboard() {
        let dialogProperties = {
            title: 'Delete Dashboard?',
            titleColor: 'var(--color-inverseOnSurface)',
            titleBackground: 'var(--color-red-8)',
            body:  `Are you sure you want to permanently remove ${this.view.dashboard.name}?`,
            buttons: [
                {   // red button that deletes the dashboard
                    title:'Delete Dashboard',
                    callback: ()=>{
                        owner.ldc.fm.buildFrame(LiveData.WVC_DELETE_DASHBOARD, undefined, this.graphID);
                        owner.ldc.fm.push_u32(this.view.dashboard.id);
                        owner.ldc.send();								// Send the message
                    },
                    color: owner.colors.hex('--color-error')
                },
                {   // gray button that cancels the dashboard deletion and returns the user to the dashboard
                    title:'Cancel',
                    color: owner.colors.hex('--color-onSurfaceVariant')
                }
            ]
        }
        new Dialog(document.body, dialogProperties);
    }

    printDashboard(width: number, height: number) {
        window.open(`${location.origin}/${getHash(Routes.Viewer, {'id':this.view.dashboard.id.toString(),'print':'true'})}`, "", `width=${width},height=${height}`);
    }

    renameDashboard() {
        let dialogProperties = {
            title: 'Rename',
            body:  'Please enter a new name for your dashboard.',
            fText:  true,
            buttons: [
                {   // green button that sets the new name and saves the dashboard when clicked
                    title:'Rename Dashboard',
                    callback:(name: string)=> {
                        this.view.dashboard.name = name;
                        this.saveDashboard();
                    },
                    color: owner.colors.hex('--color-primary')
                },
                {   // red button that cancels the dashboard renaming and returns the user to the dashboard
                    title:'Cancel',
                    color: owner.colors.hex('--color-error')
                }
            ]
        }
        new Dialog(document.body, dialogProperties);
    }

    onDeleteDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;
        owner.refreshDashboards();
        this.viewWrapper.removeChildren();
        new Dialog(document.body, {
            title  : 'Dashboard Deleted',
            titleColor: 'var(--color-inverseOnSurface)',
            titleBackground: 'var(--color-primary)',
            body   : 'We\'ve removed ' + this.view.dashboard.name + ' from your dashboards. Would you like to create another?',
            buttons: [{
                title   : 'Yes',
                callback: ()=>{new WidgetEditorPage(this.parent)},
            },
            {
                title   : 'No',
                callback: ()=>window.location.hash = getHash(Routes.Home)
            }]
        });
    };

    pushHistory() {
        //TODO: this
        //if (!this.view.dashboard)
        //    return;
        //let traceString = JSON.stringify(this.view.dashboard.recipes, this.recipeParser);
        //if (this.history.length === 0 || this.history[this.history.length - 1].trace !== traceString) {
        //    this.history.push({
        //        text: '',
        //        trace: traceString
        //    });
        //}
        //this.traceIndex = this.history.length - 1;
    }

    resize() {
        this.preview.style.overflowY                = '';
        this.viewWrapper.style.height               = '';
        this.viewWrapper.style.overflowY            = '';
        this.view.wrapper.style.height              = '';
        this.view.dashboardWrapper.style.overflow   = '';
        this.view.dashboardWrapper.style.height     = '';
        this.view.dashboardWrapper.style.minHeight  = '';
        this.viewWrapper.style.minHeight            = '';
        this.viewWrapper.style.width                = '';
        let query = MediaQueries[this.aspect];
        this.viewWrapper.style.height = query.maxH === Infinity ? '100%' : `${query.maxH}px`;
        this.viewWrapper.style.width  = query.maxW === Infinity ? '100%' : `${query.maxW}px`;
        if(this.selectedElement)
            this.styleView.updateSettings();
        this.view.resize();
        this.overlay.resize();
    }

    closeAddPanel() {
        this.addPanel.addEventListener('transitionend', ()=>this.resize(), { once: true });
        this.addPanel.setAttribute('open', 'false');
        this.addButton.src = AddIcon;
    }

    openAddPanel() {
        this.addPanel.addEventListener('transitionend', ()=>this.resize(), { once: true });
        this.addPanel.setAttribute('open', 'true');
        this.addButton.src = ArrowBackIcon;
    }

    onMouseDown(e: MouseEvent) {
        this.onSelectionChanged(null);
        this.dragListener = this.onMouseMoveStart.bind(this, e.target as HTMLElement, e);
        document.addEventListener('mousemove', this.dragListener, {once:true});
        this.cancelListener = this.onMouseCancel.bind(this)
        document.addEventListener('mouseup', this.cancelListener, {once:true});
    }

    onMouseMoveStart(target: HTMLElement, originalEvent: MouseEvent, e: MouseEvent) {
        let draggedElement = target.cloneNode(true) as HTMLElement;
        document.body.appendChild(draggedElement);
        draggedElement.style.position   = 'absolute';
        draggedElement.style.zIndex     = '1000';
        draggedElement.style.marginLeft = `-${originalEvent.pageX  - target.getBoundingClientRect().left}px`;
        draggedElement.style.marginTop  = `-${originalEvent.pageY  - target.getBoundingClientRect().top}px`;
        document.removeEventListener('mouseup', this.cancelListener);
        this.dragListener = this.onMouseMove.bind(this, draggedElement);
        document.addEventListener('mousemove', this.dragListener);
        this.upListener = this.onMouseUp.bind(this, draggedElement, this.addOptionsMap.get(target)!);
        document.addEventListener('mouseup', this.upListener, {once:true});
    }

    onMouseMove(draggedElement: HTMLElement, e: MouseEvent) {
        draggedElement.style.top    = e.pageY + 'px';
        draggedElement.style.left   = e.pageX + 'px';
        let hoverElements           = document.elementsFromPoint(e.pageX, e.pageY);

        for (let i=0;i<hoverElements.length;++i) {                      // loop through the elements under the cursor
            let element = hoverElements[i];                             // convenience reference
            if (element instanceof HTMLDivElement) {

            }
        };
    };

    onMouseUp(draggedElement: HTMLElement, tagName: string, e: MouseEvent) {
        let hoverElements = document.elementsFromPoint(e.pageX, e.pageY);
        document.body.removeChild(draggedElement);
        document.removeEventListener('mousemove', this.dragListener);
        for (let i=0;i<hoverElements.length;++i) {
            let element = hoverElements[i];
            if (this.isDescendantOf(element, this.view.dashboardWrapper) && (element instanceof HTMLDivElement)) {
                this.addElement(tagName, element);
                return;
            }
            if (element === this.view.dashboardWrapper) {
                this.addElement(tagName, this.view.dashboardWrapper);
                return;
            }
        }
    }

    isDescendantOf(element: Element, parent: HTMLElement) {
        while(element.parentElement) {
            console.log(element)
            if (element.parentElement === parent)
                return true;
            element = element.parentElement;
        }
        return false;
    }

    private addElement(tagName: string, parentElement: HTMLElement)
    {
        let newLens = document.createElement(tagName);
        newLens.id = `_${uuidv4()}`;
        parentElement.appendChild(newLens);
        this.tree.refresh();

        queueMicrotask(()=>this.onSelectionChanged(newLens));
    }

    onMouseCancel(e: MouseEvent) {
        document.removeEventListener('mousemove', this.dragListener);
    }

    createSection(name: string) : HTMLElement {
        let titleContainer = createElement('div', 'editor__container__setting', this.addWrapper);
        createElement('img', 'editor__container__setting__arrow', titleContainer, '', {'src':ArrowIcon});
        createElement('div', 'editor__container__setting__title', titleContainer, name);
        let section = createElement('div', 'editor__add__section', this.addWrapper);
        return section;
    }

    onSelectionChanged(selectedElement: HTMLElement | null) {
        this.saveButton.style.filter = '';
        this.toolTabs.getSectionByName('Settings').removeChildren();
        this.selectedElement      = selectedElement;
        this.tree.onSelectionChanged(this.selectedElement);
        if (this.selectedElement) {
            this.settingsWrapper.classList.remove('hide');
            this.refreshSelectedStyles();
            this.toolTabs.hideSection('Tags');
            let supportedProps: Readonly<Map<string, ExtendedAttributeMetadata>> = this.selectedElement.constructor[attrMetadataSymbol];
            //this.attributeView.element = this.selectedElement;
            if (supportedProps) // should have supported properties if this is a Widget
            {
                this.attributeView.populateSettings(supportedProps);
                let attributes: {[key: string]: string} = {};
                let autoAttributes: {[key: string]: string} = {};
                for (let [attrName, metadata] of supportedProps)
                {
                    let setting = this.selectedElement.getAttribute(attrName);
                    if (setting !== null)
                        attributes[attrName] = setting;
                    if (this.selectedElement[defaultAttrValues].has(attrName))
                    {
                        if (this.selectedElement[defaultAttrValues].has(attrName))
                        {
                            let attribute = this.selectedElement[defaultAttrValues].get(attrName);
                            let stringAttr = '';
                            switch (metadata.type) {
                                case "String":
                                    stringAttr = attribute;
                                break;
                                case "Boolean":
                                case "Number":
                                    stringAttr = attribute.toString();
                                break;
                                case "RangeAttribute":
                                    stringAttr = JSON.stringify(attribute)
                                case "Object":
                                case "Array":
                                    stringAttr = JSON.stringify(attribute);
                                break;
                                default:
                                    stringAttr = attribute;
                            }
                            if (stringAttr !== '')
                                autoAttributes[attrName] = stringAttr;
                        }
                    }
                }
                this.attributeView.setSettingsToEdit(attributes, {}, autoAttributes);
            }
            else if (selectedElement instanceof HTMLParagraphElement) {

            }
            let supportedTags = this.selectedElement.constructor[tagAttributeMetadataSymbol];
            let supportedTagSets = this.selectedElement.constructor[tagSetAttributeMetadataSymbol];
            if (supportedTags || supportedTagSets) {
                this.toolTabs.showSection('Tags');
                this.socketView.element = this.selectedElement;
            }
        }
        else {
            this.settingsWrapper.classList.add('hide');
        }
        this.overlay.hmiElement = this.selectedElement;
    };

    setProps(newProps: {[key: string]: string}) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'id': // if the device key changes, create a new device page
                        new WidgetEditorPage(this.parent, newProps);
                        return;
                    default:
                        break;
                }
            }
        }
    };

    getStyles(element: Element): CSSStyleRule {
        let styleSheet = this.styleElement.sheet!;
        let index = 0;
        for (let rule of styleSheet.cssRules)
        {
            if (rule instanceof CSSStyleRule && rule.selectorText == `#${element.id}`) {
                return styleSheet.cssRules[index] as CSSStyleRule;
            }
            if (element.id === '') {
                element.id = `_${uuidv4()}`;
            }
            return styleSheet.cssRules[styleSheet.insertRule(`#${element.id}{}`)] as CSSStyleRule;
        }
        let rule = styleSheet.cssRules[index] ?? styleSheet.cssRules[styleSheet.insertRule(`#${element.id}{}`)];
        return rule as CSSStyleRule;
    }

    /**
     * return our dashboard's style element if it exists. If not, create a new one
     */
    private get styleElement(): HTMLStyleElement
    {
        if (this.customStyles)
            return this.customStyles;
        else
        {
            let customStyles = this.view.dashboardWrapper.querySelector(`#${StyleID}`) as HTMLStyleElement
            if (!customStyles)
            {
                customStyles = document.createElement('style') as HTMLStyleElement;
                customStyles.id = StyleID;
                customStyles.appendChild(document.createTextNode('*{}'))
                this.view.dashboardWrapper.prepend(customStyles);
            }
            this.customStyles = customStyles as HTMLStyleElement;
            return this.customStyles;
        }
    }

    /**
     *Take all the css we created in the CSS Object Model and inject it directly into the DOM so it will be captured in innerHTML
     *
     * @memberof RootElement
     */
    public serializeStyles() {
        let cssOmStyles = [].slice.call(this.styleElement.sheet!.cssRules).reduce((prev: String, cssRule: CSSRule) => {
            return prev + cssRule.cssText;
        }, "")
        this.styleElement.innerHTML = '';
        let styles = document.createTextNode(cssOmStyles);
        this.styleElement.appendChild(styles);
    }

    destroy() {
        removeEventListener('mouseup', this.anyClickListener);
        removeEventListener('resize', this.resize.bind(this));
        removeEventListener('keydown', this.keyListener);
        if (this.overlay)
            this.overlay.destroy();
        this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
        this.parent.removeChildren();		// Delete any DOM elements left over
    }

    scrollElementIntoView(element: HTMLElement) {
        let boundingElement = this.view.wrapper.getBoundingClientRect();
        let elementRect = element.getBoundingClientRect();
        if (elementRect.bottom < boundingElement.top || elementRect.top > boundingElement.bottom)
            element.scrollIntoView();
    }
}

export interface ShareSettings {
    fAccess: number;
    fWrites: number;
}

export interface TreeItem {
    element     : HTMLElement;
    childList   : HTMLElement;
    arrow?      : HTMLElement;
    name        : HTMLElement;
    icon        : HTMLElement;
    fOpen       : boolean;
    hmiElement  : HTMLElement;
}

class ContainerTree {
    parentElement  : HTMLElement;
    editor         : WidgetEditorPage;
    element        : HTMLElement;
    elementMap     : Map<Element, TreeItem> = new Map(); // Map of HMI element to corresponding item in tree
    hoveredElement : HTMLElement;
    topHover       : HTMLElement;
    bottomHover    : HTMLElement;
    scrollTimeout  : NodeJS.Timeout;
    moveListener   : (e: MouseEvent) => any;
    upListener     : (e: MouseEvent) => any;
    collapseMap    : Map<HTMLElement, boolean> = new Map();
    optionBar      : HTMLElement;
    hmiElement     : HTMLElement;
    constructor(parent: HTMLElement, editor: WidgetEditorPage, hmiElement: HTMLElement) {
        this.parentElement  = parent;
        this.editor         = editor;
        this.hmiElement     = hmiElement;
        this.optionBar      = createElement('div', 'editor__tree__options', this.parentElement)
        this.element        = createElement('div', 'editor__tree__container', this.parentElement);
        this.topHover       = createElement('div', 'editor__tree__hover__top', this.parentElement);
        this.bottomHover    = createElement('div', 'editor__tree__hover__bottom', this.parentElement);

        let collapseButton = createElement('div', 'editor__tree__button', this.optionBar);
        createElement('img', 'editor__tree__button__icon', collapseButton, '', {'src':ArrowIcon}).style.transform = 'rotate(180deg)';
        collapseButton.onclick = () => this.collapseAll();

        let expandButton = createElement('div', 'editor__tree__button', this.optionBar);
        createElement('img', 'editor__tree__button__icon', expandButton, '', {'src':ArrowIcon});
        expandButton.onclick = () => this.expandAll();
    }

    refresh() {
        this.element.removeChildren();
        for (let child of this.hmiElement.children)
            this.createTree(child as HTMLElement)

        this.editor.onSelectionChanged(this.editor.selectedElement)
    };

    select(treeItem: TreeItem) {
        let currentItem = treeItem;
        while(currentItem.hmiElement.parentElement !== this.editor.view.dashboardWrapper) {
            let parentItem = this.elementMap.get(currentItem.hmiElement.parentElement!)!
            if (!parentItem.fOpen) {
                this.expand(parentItem);
            }
            currentItem = parentItem;
        }
        treeItem.element.setAttribute('_selected', 'true');
        let boundingElement = this.element.getBoundingClientRect();
        let treeItemRect = treeItem.element.getBoundingClientRect();
        if (treeItemRect.bottom < boundingElement.top || treeItemRect.top > boundingElement.bottom)
            treeItem.element.scrollIntoView();
    }

    addItem(hmiElement: HTMLElement) {
        if (hmiElement instanceof HTMLStyleElement)
            return;
        let parent      = this.elementMap.get(hmiElement.parentElement!)?.childList ?? this.element;
        let treeElement = createElement('div', 'editor__tree__item', parent)
        let treeItem: TreeItem = {
            element     : treeElement,
            childList   : createElement('div', 'editor__tree__item__list', parent),
            arrow       : Array.from(hmiElement.children).some(child => child.tagName !== 'TAG-DEF') ? createElement('img', 'editor__tree__item__arrow', treeElement, '', {'src':ArrowIcon}): undefined,
            icon        : createElement('img', 'editor__tree__item__icon', treeElement, '', {'src': Widgets.find(props => props.tag == hmiElement.tagName.toLowerCase())?.icon ?? ''}),
            name        : createElement('div', 'editor__tree__item__name', treeElement, hmiElement.getAttribute(NameAttribute) ?? hmiElement.tagName.toLowerCase()),
            fOpen       : hmiElement.getAttribute('tree-open') == undefined ? true : hmiElement.getAttribute('tree-open') == 'true',
            hmiElement  : hmiElement
        }
        treeItem.element.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onDragStart.bind(this, hmiElement, e);
            this.upListener = this.onDragCancelled.bind(this, hmiElement);
            document.addEventListener('mousemove', this.moveListener);
            document.addEventListener('mouseup', this.upListener, {once: true});
        }

        treeItem.element.ondblclick = () => {
            this.createTextInput(hmiElement);
        }

        if (treeItem.arrow) {
            treeItem.arrow.onmousedown = (e) => {
                e.stopImmediatePropagation()
                if (treeItem.fOpen)
                    this.collapse(treeItem);
                else
                    this.expand(treeItem);
            }
        }

        if (treeItem.arrow) {
            treeItem.childList.style.height = treeItem.fOpen ? '' : '0px'
            treeItem.arrow!.style.transform  = treeItem.fOpen ? '' : 'rotate(-90deg)'
        }

        this.elementMap.set(hmiElement, treeItem);
    }

    collapseAll() {
        for (let treeItem of this.elementMap.values()) {
            this.collapse(treeItem);
        }
    }

    expandAll() {
        for (let treeItem of this.elementMap.values()) {
            this.expand(treeItem);
        }
    }

    collapse(treeItem: TreeItem) {
        if (!treeItem.arrow || !treeItem.fOpen)
            return;
        treeItem.childList.style.height = '0px';
        treeItem.arrow!.style.transform  = 'rotate(-90deg)';
        treeItem.fOpen = false;
        this.collapseMap.set(treeItem.hmiElement, !treeItem.fOpen);
    }

    expand(treeItem: TreeItem) {
        if (!treeItem.arrow || treeItem.fOpen)
            return;
        treeItem.childList.style.height = ''
        treeItem.arrow!.style.transform  = ''
        treeItem.fOpen = true;
        this.collapseMap.set(treeItem.hmiElement, !treeItem.fOpen);
    }

    createTree(element: HTMLElement) {
        this.addItem(element);
        for (let child of element.children) {
            if (child.tagName == 'TAG-DEF')
                continue;
            this.createTree(child as HTMLElement);
        }
    };

    onDragStart(hmiElement: HTMLElement, ogEvent: MouseEvent, e: MouseEvent) {
        if (Math.abs(ogEvent.pageX - e.pageX) > 5 || Math.abs(ogEvent.pageY - e.pageY) > 5) {   // make sure we realllly want to drag this guy
            this.elementMap.get(hmiElement)!.childList.style.pointerEvents = 'none';
            document.removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            document.removeEventListener('mouseup', this.upListener);       // get rid of the old up listener

            this.moveListener = this.onDrag.bind(this, hmiElement);             // make sure we remember our listener so we can clean it up later
            document.addEventListener('mousemove', this.moveListener);
            this.upListener = this.onDragFinished.bind(this, hmiElement);       // make sure we remember our listener so we can clean it up later
            document.addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onDrag(hmiElement: HTMLElement, e: MouseEvent) {
        clearInterval(this.scrollTimeout);
        let element         = e.target as HTMLElement;
        if (element === this.topHover || element === this.bottomHover) {
            element = this.findFirstHovered(e) ?? element;
            this.topHover.style.opacity = '1';
            if (element === this.topHover)
                this.element.scrollTop += 5;
            else
                this.element.scrollTop -= 5;

            this.scrollTimeout = setTimeout(() => {
                this.onDrag(hmiElement, e);
            }, 10);
        }
        else {
            this.topHover.style.opacity = '0';
            this.bottomHover.style.opacity = '0';
        }

        let hoveredHMIElement    = [...this.elementMap.values()].find(treeItem => treeItem.element === element);

        for (let [hmiElement, treeItem] of this.elementMap) {
            treeItem.element.style.backgroundColor = '';                      // set all the element style properties back to defaults
            treeItem.element.style.border = '';
            treeItem.element.style.outline = '';
            treeItem.element.style.boxShadow = '';
        }
        if (!hoveredHMIElement)    // if we aren't hovering over a tree item, or if we are hovering over the element we are dragging
            return;

        if (!this.hoveredElement)                               // if we weren't previously hovering over a tree item
            this.hoveredElement = element;                      // make sure we remember that we are now
        else if (this.hoveredElement != element) {              // else, if we are now hovering over a different element
            this.hoveredElement = element;                      // and remember which element we are hovering over
        }
        let rect = element.getBoundingClientRect();
        let height = rect.bottom - rect.top;
        if (hoveredHMIElement instanceof HTMLDivElement) {
            if (e.pageY < rect.top + height / 4) {
                element.style.borderTop = '1px solid var(--color-onSurface)';
                element.style.boxShadow = '0 -1px 0 var(--color-onSurface)';
            }
            else if (e.pageY > rect.top +  3 * height / 4) {
                element.style.borderBottom = '1px solid var(--color-onSurface)';
                element.style.boxShadow = '0 1px 0 var(--color-onSurface)'
            }
            else
                element.style.outline = '2px solid var(--color-blue-4)';
        }
        else if (e.pageY < rect.top + height / 2) {
            element.style.borderTop = '1px solid var(--color-onSurface)';
            element.style.boxShadow = '0 -1px 0 var(--color-onSurface)'

        }
        else  {
            element.style.borderBottom = '1px solid var(--color-onSurface)';
            element.style.boxShadow = '0 1px 0 var(--color-onSurface)'
        }
    };

    // We just ended our drag with a mouseup event
    onDragFinished(draggedHMIElement: HTMLElement, e: MouseEvent) {
        clearInterval(this.scrollTimeout);
        document.removeEventListener('mousemove', this.moveListener);
        this.elementMap.get(draggedHMIElement)!.childList.style.pointerEvents = 'all';

        if (!this.hoveredElement)
            return;

            let element             = e.target as HTMLElement;
            let hoveredHMIElement   = [...this.elementMap.values()].find(treeItem => treeItem.element === element)?.hmiElement;

            if (!hoveredHMIElement || hoveredHMIElement === draggedHMIElement)    // if we aren't hovering over a tree item, or if we are hovering over the element we are dragging
                return;

            if (this.hoveredElement != element) {                   // else, if we are now hovering over a different element
                this.hoveredElement = element;                      // and remember which element we are hovering over
            }
            let rect = element.getBoundingClientRect();
            let height = rect.bottom - rect.top;
            if (hoveredHMIElement instanceof HTMLDivElement && hoveredHMIElement !== draggedHMIElement.parentElement) {
                if (e.pageY < rect.top + height / 2)
                    hoveredHMIElement.parentElement?.insertBefore(draggedHMIElement, hoveredHMIElement);
                else
                    hoveredHMIElement.prepend(draggedHMIElement);
            }
            else if (e.pageY < rect.top + height / 2) // we are hovering over the top half of the element, so we want to add this gizmo above
                hoveredHMIElement.parentElement?.insertBefore(draggedHMIElement, hoveredHMIElement);
            else
                hoveredHMIElement.parentElement?.after(draggedHMIElement);
            this.refresh();
    }

    onDragCancelled(draggedHMIElement: HTMLElement, e: MouseEvent) {
        document.removeEventListener('mousemove', this.moveListener);
        this.editor.onSelectionChanged(draggedHMIElement);
        this.editor.scrollElementIntoView(draggedHMIElement);
    }

    findFirstHovered(e: MouseEvent): HTMLElement | null {
        let allElements = document.elementsFromPoint(e.pageX, e.pageY);
        for (let currentElement of allElements) {
            let hoveredGizmo = this.elementMap.get(currentElement as HTMLElement);
            if (hoveredGizmo) {
                return currentElement as HTMLElement;
            }
        }
        return null;
    }

    createTextInput(element: HTMLElement) {
        let treeItem = this.elementMap.get(element);
        let input = createElement('input', 'editor__tree__name-input', treeItem?.element, '', {'value':treeItem?.hmiElement.tagName});
        input.focus();
        input.setSelectionRange(0, input.value.length)
        input.onblur = () => {
            if (input.value != '')
                element.setAttribute('hmi-name', input.value);
            this.refresh();
        }
    }

    onSelectionChanged(selectedElement: HTMLElement | null) {
        for (let [element, treeItem] of this.elementMap) {
            treeItem.element.setAttribute('_selected', 'false')
        }
        if (selectedElement)
            this.elementMap.get(selectedElement)?.element.setAttribute('_selected', 'true')
    }
}

class Overlay {
    editor      : WidgetEditorPage;
    protected _hmiElement : Element | null = null;
    container   : HTMLElement;
    wrapper     : HTMLElement;
    infoContainer: HTMLElement;
    overlay:    HTMLElement;

    constructor(editor: WidgetEditorPage, color: string) {
        this.editor         = editor;
        this.container      = createElement('div', 'overlay__container hide', this.editor.preview);
        this.infoContainer   = createElement('div', 'overlay__info-container', this.container);
        this.overlay         = createElement('div', 'overlay__overlay', this.container);
        this.wrapper        = createElement('div', 'overlay__wrapper', this.overlay);
        this.wrapper.style.borderColor = color;
        this.wrapper.style.outlineColor = color;
        this.container.onclick = (e) => this.clickCallback(e)
    }

    set hmiElement(v: Element | null) {
        if (!v) {
            this.hide();
        }
        else {
            this.show();
            this._hmiElement = v;
            this.onShown(v);
            this.resize();
        }
        //this.resizer.classList.toggle('hide', !this._gizmo?.allowableStyles.includes(StyleCategories.DIMENSIONS)) TODO: make a map of allowable styles. or something. I don't know
        this.refresh();
    }

    get hmiElement(): Element | null
    {
        return this._hmiElement;
    }

    onShown(v: Element) {
    }

    clickCallback(e: MouseEvent) {
        this.editor.onSelectionChanged(this._hmiElement as HTMLElement);
    }

    hide() {
        this.container.classList.add('hide');
    }

    show() {
        this.container.classList.remove('hide');
    }

    resize() {
        if (!this._hmiElement)
            return;
        let rect        = this._hmiElement.getBoundingClientRect();
        let previewRect = this.editor.preview.getBoundingClientRect();
        this.container.style.left   = rect.left - previewRect.left + 'px';
        this.container.style.top    = rect.top - previewRect.top - 18 + 'px';
        this.container.style.height = rect.height + 18  + 'px';
        this.container.style.width  = rect.width + 'px';
    }

    refresh() {
        this.wrapper.removeChildren();
        //this._hmiElement?.addOverlayElements(this.wrapper); // TODO: does this need to exist still?
    }

    destroy() {
        this.editor.preview.removeChild(this.container);
    }
}

class WidgetOverlay extends Overlay {
    title       : HTMLElement;
    resizeButton: HTMLElement;
    moveListener: (e: MouseEvent) => any;
    upListener  : (e: MouseEvent) => any;
    resizer     : HTMLElement;
    deleter     : HTMLElement;
    xOffset     : number = 0;
    yOffset     : number = 0;
    constructor(editor: WidgetEditorPage) {
        super(editor, owner.colors.hex('--color-blue-6'));
        this.title          = createElement('div', 'overlay__title', this.infoContainer);
        let move            = createElement('div', 'overlay__move', this.infoContainer);
        createElement('img', 'overlay__move__icon', move, '', {'src':MoveIcon});
        this.resizer        = createElement('div', 'overlay__resize', this.overlay);
        createElement('img', 'overlay__move__icon', this.resizer, '', {'src':ResizeIcon});
        this.deleter        = createElement('div', 'overlay__delete', this.overlay);
        createElement('img', 'overlay__move__icon', this.deleter, '', {'src':DeleteIcon});

        this.deleter.onclick = () => {
            this._hmiElement?.parentElement?.removeChild(this._hmiElement);
            this.editor.tree.refresh();
        }

        move.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onMoveStart.bind(this, e);
            this.upListener = this.onClickCancelled.bind(this);
            addEventListener('mousemove', this.moveListener);
            addEventListener('mouseup', this.upListener, {once: true});
        }

        this.resizer.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onResizeStart.bind(this, e);
            this.upListener = this.onClickCancelled.bind(this);
            addEventListener('mousemove', this.moveListener);
            addEventListener('mouseup', this.upListener, {once: true});
        }

        this.wrapper.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            this.editor.onSelectionChanged(this._hmiElement?.parentElement === this.editor.view.dashboardWrapper ? null : this._hmiElement?.parentElement!);
        }
    }

    onMoveStart(clickEvent: MouseEvent, moveEvent: MouseEvent) {
        if (Math.abs(clickEvent.pageX - moveEvent.pageX) > 5 || Math.abs(clickEvent.pageY - moveEvent.pageY) > 5) {   // make sure we realllly want to drag this guy
            removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
            //this._hmiElement!.style.position = 'absolute';
            let rect = this._hmiElement!.parentElement!.getBoundingClientRect();
            let gizRect = this._hmiElement!.getBoundingClientRect();
            this.xOffset = clickEvent.clientX - gizRect.left;
            this.yOffset = clickEvent.clientY - gizRect.top;
            this.moveListener = this.onMoveDrag.bind(this, rect, this._hmiElement!.clientWidth, this._hmiElement!.clientHeight);             // make sure we remember our listener so we can clean it up later
            addEventListener('mousemove', this.moveListener);
            this.upListener = this.onResizeFinished.bind(this);       // make sure we remember our listener so we can clean it up later
            addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onResizeStart(clickEvent: MouseEvent, moveEvent: MouseEvent) {
        if (Math.abs(clickEvent.pageX - moveEvent.pageX) > 5 || Math.abs(clickEvent.pageY - moveEvent.pageY) > 5) {   // make sure we realllly want to drag this guy
            removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
            let rect = this.container.getBoundingClientRect();
            let gizRect = this._hmiElement!.getBoundingClientRect();
            this.xOffset = clickEvent.clientX - gizRect.right;
            this.yOffset = clickEvent.clientY - gizRect.bottom;
            this.moveListener = this.onResizeDrag.bind(this, rect);             // make sure we remember our listener so we can clean it up later
            addEventListener('mousemove', this.moveListener);
            this.upListener = this.onResizeFinished.bind(this);       // make sure we remember our listener so we can clean it up later
            addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onMoveDrag(rect: DOMRect, width: number, height: number, moveEvent: MouseEvent) {
        let deltaX = moveEvent.clientX - rect.left;
        let deltaY = moveEvent.clientY - rect.top; //TODO: this all needs to be reworked
        //this._hmiElement!.style.position = 'absolute';
        //this._hmiElement!.style.top = Math.max(Math.min(deltaY - this.yOffset, rect.height - height + this.yOffset), 0) + 'px';
        //this._hmiElement!.style.left = Math.max(Math.min(deltaX - this.xOffset, rect.width - width + this.xOffset), 0)  + 'px';
        //this._hmiElement?.applyStyles();
        this.editor.styleView.updateSettings();
        this.resize();
    }

    onResizeDrag(rect: DOMRect, moveEvent: MouseEvent) {
        let width = moveEvent.pageX - rect.left - this.xOffset;
        let height = moveEvent.pageY - rect.top + this.yOffset; //TODO: rework this too
        this.editor.styleView.setStyle(this.hmiElement!, 'width', `${width}px`);
        this.editor.styleView.setStyle(this.hmiElement!, 'height', `${height}px`);
        this.editor.styleView.updateSettings();
        this.resize();
    }

    onResizeFinished(e: MouseEvent) {
        e.stopPropagation();
        removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
        removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
        this.editor.styleView.updateSettings();

        //if (this.hmiElement instanceof Lens)
        //    this.hmiElement.refresh();
    }

    onClickCancelled(e: MouseEvent) {
        e.stopPropagation();
        removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
        removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
    }

    onShown(v: HTMLElement) {
        this.title.textContent = v.getAttribute(NameAttribute) ?? v.tagName.toLowerCase();
    }

    clickCallback(e: MouseEvent) {
        let hoverElements = document.elementsFromPoint(e.pageX, e.pageY);
        let sub = false;
        for (let i=0;i<hoverElements.length;++i) {
            if (hoverElements[i] == this._hmiElement) {
                sub = true;
                continue;
            }
            else if (sub) {
                this.editor.onSelectionChanged(hoverElements[i] as HTMLElement);
                break;
            }
        }
    }
}