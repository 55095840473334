import assert from "../debug";
import { createElement } from "../elements";
import { NodeQuality } from "../node";
import { Widget } from "../widget";
import View from "./view";
import './ifsoverview.css';
import ColoredHeader from "../coloredheader";
import { GenericGraph } from "../graph";
import owner from "../../owner";
import createSVGElement from "../svgelements";
import { BooleanFill } from "../graph";
import { Role } from "../role";
export default class IFSOverview extends View {
    constructor (device, ldc) {
        super(parent);
        this.device 	    = device;
        this.ldc            = ldc;
    }

    initialize(parent) {
        super.initialize(parent);
        this.rootNode      = this.device.tree.nodes[0];
        this.pumpFolders    = this.rootNode.findByRole(Role.ROLE_IFS_PUMP);	// Save a pointer to the pump folders
        assert(this.pumpFolders.length > 0, 'Failed to find any pump folders in IFS Overview');
        this.wrapper        = createElement('div', 'view__wrapper', this.parent);
        let container       = createElement('div', 'ifs-overview', this.wrapper);
        let pumpContainer   = createElement('div', 'ifs-overview__pumps',container);
        let graphContainer  = createElement('div', 'ifs-overview__graph', container);
        this.graphRow		= createElement('div', 'overview__overview-graph-wrapper__graph-row', graphContainer);
		this.controlDiv		= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
		this.graphDiv		= createElement('div', 'overview__overview-graph-wrapper__graph-row__graph', this.graphRow);
		this.legendDiv		= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
        this.graphTags      = [];
        this.createPumps(pumpContainer);
        this.resize();
        this.fInitialized = true;
        return this;
    }

    createPumps(parent) {
        for (let i = 0; i < this.pumpFolders.length; i++) {
            let f = this.pumpFolders[i];
            let pumpContainer   = createElement('div', 'ifs-overview__pump', parent);
            var svg = createSVGElement('svg', 'pump-triangle-svg', pumpContainer);	// The SVG wrapper element
            svg.style.position = 'absolute';
            createSVGElement('path', 'pump-triangle-fill', svg, {
                d:		'M 0 0 L 0 20 L 20 0 Z',	// Move to 0,0. Line to 0,20. Line to 20,0. End path.
                fill:	'var(--color-surfaceContainerLowest)'						// Make the triangle whatever color they gave us
            });
            createSVGElement('path', 'pump-triangle-fill', svg, {
                d:		'M 0 0 L 0 20 L 20 0 Z',	// Move to 0,0. Line to 0,20. Line to 20,0. End path.
                fill:	BooleanFill.colorStrings[i]						// Make the triangle whatever color they gave us
            });
            let title           = createElement('div', 'ifs-overview__pump__title', pumpContainer, f.getDisplayName());
            let dataContainer   = createElement('div', 'ifs-overview__pump__data', pumpContainer)
            let valueContainer  = createElement('div', 'ifs-overview__pump__values', dataContainer);
            let faultContainer  = createElement('div', 'ifs-overview__pump__fault', dataContainer);
            let statusContainer = createElement('div', 'ifs-overview__pump__status', dataContainer);
            let gaugeNames      = ['MotorSpeed', 'MotorVoltage', 'BussVDC', 'MotorFrequency', 'Temperature', 'Level', 'MotorCurrent', 'Flow'];
            // Create value displays
            for (let i=0;i<gaugeNames.length;i++) {
                let tag = f.findChild(gaugeNames[i]);
                if (tag) {
                    createElement('tag-badge', 'ifs-overview__pump__values__gauge', valueContainer, '', {statusTag: {tag: tag}, showUnits: true});
                    this.graphTags.push(tag);
                }
            }
            let faultTag = f.findChild(Role.ROLE_FAULT_CODE);
            if (faultTag)
                new VFDStatusWidget(createElement('div', 'vfd-status', faultContainer), {node: faultTag}).initialize();

            let statusNames      = ['Ready', 'Running', 'Reverse', 'Fault', 'Alarm', 'At Speed'];
            for (let i=0;i<statusNames.length;i++) {
                let tag = f.findChild(statusNames[i]);
                if (tag)
                    this.createStatusLight(statusContainer, tag, statusNames[i] == 'Alarm' || statusNames[i] == 'Fault');
            }
            let buttonNames = ['ReverseCommand', 'FaultReset'];
            for (let i=0;i<buttonNames.length;i++) {
                let tag = f.findChild(buttonNames[i]);
                if (tag)
                    this.createButton(statusContainer, tag)
            }
        }
    }

    createStatusLight(parent, node, fAlarm) {
        if (!node)
            return
        let row = createElement('div', 'ifs-overview__status', parent);
        let statusLight = createElement('div', 'ifs-overview__status__light', row);
        createElement('div', 'ifs-overview__status__title', row, node.getDisplayName());
        new ColoredHeader(statusLight, {nodes: [node], trueClass: fAlarm ? ['ifs-overview__status__light__amber'] : ['ifs-overview__status__light__green']});	// Actually create the colored header
    }

    createButton(parent, node) {
        createElement('tag-push-button', 'ifs-overview__button__widget', createElement('div', 'ifs-overview__button', parent), '', {buttonTag: {tag: node}})
    }

    resize() {
        var end			= new Date();						// Current time
		var endTime     = end.getTime();
		var start		= new Date(endTime - 1000*3600*24);	// Default to the last 24 hours
		var startTime   = start.getTime();
		if (this.graph) this.graph.destroy();
		// "wall to wall" graph on mobile widths
		if(window.innerWidth < 620) {
			var gOptions =  {
				drawYAxis: 			false,
                drawXAxis:          false,
                xLabelWidth:        0,
				yLabelWidth: 	 	0,
				rightGap:			0
			};
		}
		this.graph = new GenericGraph(this.ldc, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, start, end, false, this.controlDiv, this.legendDiv, gOptions);	// Create the graph that takes care of the hard stuff for us
		this.graph.makeInteractive(true);	// Let them pan and zoom and such
		this.graph.createLegend();			// Make a legend happen
		this.graph.createDateSelection();	// Give them buttons that change the range of data presented
		this.graph.axesCanChange();			// Axis can change between attached nodes
        for (let i=0;i<this.graphTags.length;++i) {
            let tag = this.graphTags[i];
            this.graph.addNode(tag, true, tag.parent.getDisplayName() + '/' + tag.getDisplayName(), owner.colors.hex('--color-graph-' + (i + 1)), true, tag.engMax, tag.engMin);
        }
        /*
        let = this.boosterFolder.findChildByRole(Role.ROLE_SOURCE_TANK_LEVEL);
		var estLevel 	= this.boosterFolder.findChildByRole(Role.ROLE_TARGET_TANK_LEVEL);
		var bDischarge 	= this.boosterFolder.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);

		if(gstLevel)
			this.graph.addNode(gstLevel, true, gstLevel.name, "red", true);
		if(estLevel)
			this.graph.addNode(estLevel, true, estLevel.name, "black", true);
		if(bDischarge)
			this.graph.addNode(bDischarge, true, bDischarge.name, "purple", true);
            */
        let runningNodes = [];
        for (let i = 0; i < this.pumpFolders.length; i++) {
            let pumpFolder = this.pumpFolders[i];
            runningNodes.push(pumpFolder.findChild('Running'));
        }
        if (runningNodes.length > 0)
            this.graph.createBooleanFill(runningNodes, true);

        this.graph.requestDataForAllDevices(startTime, endTime, this.graph._calculateInterval(startTime, endTime), 0);
    }
}

class VFDStatusWidget extends Widget {
    constructor(element, props) {
        super();
		// 'element' is required:
		assert (element, 'element is a required property');
		assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
		this.element = element;
		this.registerAsWidget(element);			// register this element as a widget

		// 'properties' is optional -- if defaults are all acceptable, properties is not necessary.

        this.copy(props);
		// First step: Establish default properties:
		this.redXMask       = 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.vfdStatusText  = {
                0   : {status:'No Fault' , cause: '', measure:''},
                1   : {status:'Overintensity', cause: 'Frequency converter has detected too high current (>4#In) in the motor cable:\n▪ Short-circuit in motor cables\n▪ Sudden significant overload\n▪ Motor inadequate', measure:'▪ Check load\n▪ Check cables\n▪ Check motor'},
                2   : {status:'Overvoltage' , cause: 'DC-link voltage is above the limits in table 4.3:\n▪ Deceleration time too short\n▪ Grid voltage spikes' , measure: 'Increase deceleration time.\nUse OWCut or a braking resistance (in option)'},
                3   : {status:'Earth fault' , cause: 'Current measurement function has detected that the sum of the motor\'s phase currents is not zero:\n▪ Insulation fault in cables or motor' , measure: 'Check motor and its cabling'},
                7   : {status:'Saturation' , cause: '▪ Defective IGBT component\n▪ Brake resistance short circuiting' , measure: 'Do not restart. Contact us on +33 (0)6.833.98.42.92.'},
                8   : {status:'System fault' , cause:'▪ Defective components\n▪ Malfunction' , measure: 'Reset fault and restart.\nIf fault reoccurs, contact us on +33 (0)6.833.98.42.92.'},
                9   : {status:'Undervoltage' , cause:'DC-link voltage is below the limits in table 4.7:\n▪ Most likely cause: mains voltage too low.\n▪ Internal fault in frequency converter.' , measure: 'In the event of a temporary power outage, reset fault and restart frequency converter. Check mains voltage. If correct, fault lies with converter. Contact us on +33 (0)6.833.98.42.92.' },
                10  : {status:'Input phase' , cause: '▪ Phase network absent.' , measure: 'Check mains and voltage cable'},
                11  : {status:'Output phase supervision' , cause:'▪ The current measurement function has detected a missing phase in the motor cable.' , measure: 'Check motor and its cabling.'},
                13  : {status:'Converter under temperature' , cause:'▪ Radiator temperature below -10\xB0C/14\xB0F' , measure: ''},
                14  : {status:'Frequency converter over temperature' , cause: '▪ Radiator temperature is too high\n▪ An overtemperature alarm is signalled when the radiator temperature is too high (according to model: 70\xB0C/158\xB0F to 90\xB0C/194\xB0F' , measure: 'Check volume and flow of cooling air.\nCheck build-up of radiator deposits.\nCheck ambient temperature.\nCheck switching frequency is not too high for the ambient temperature and motor load.'},
                15  : {status:'Motor stall' , cause: '▪ Anti-stall protection tripped.' , measure: 'Check motor.'},
                16  : {status:'Motor over temperature' , cause: '▪ Abnormal motor heating detected by frequency converter thermal model.\n▪ Motor overload.', measure: 'Reduce motor load. If no motor overload, check thermal model parameters.'},
                17  : {status:'Underload (Autolevel)' , cause: '' , measure: ''},
                22  : {status:'EEPROM' , cause: '' , measure: ''},
                23  : {status:'Checksum error' , cause: '▪ Operating fault.\n▪ Defective component.' , measure: ''},
                24  : {status:'Counter fault' , cause: 'Displayed counter values are incorrect:\n▪ Faulty component\n▪ Defective component' , measure:'Contact us on +33 (0)6.833.98.42.92.'},
                25  : {status:'Microprocessor Watchdog fault' , cause:  '▪ Operating fault.\n▪ Faulty component.', measure:'Reset fault and restart. If fault reoccurs, contact us on +33 (0)6.833.98.42.92.'},
                26  : {status:'Operation inhibition' , cause: 'The startup of frequency converter is inhibited', measure:'Invalidate the operation inhibition.'},
                29  : {status:'Thermistor fault' , cause: '▪ Thermistor input on optional board has detected an increase in motor temperature' , measure:'Check cooling and motor load. Check thermistor connection (if the thermistor input of the optional board is not in use it must be short-circuited).'},
                31  : {status:'IGBT (hard-switched) over temperature' , cause: '▪ The thermal protection between the UPS and IGBT has detected a too high transient overload.' , measure:'Check load level. Check motor size.'},
                34  : {status:'Internal bus communication' , cause: '▪ Environmental interference of defective equipment. (A message sent to the bus remained unanswered)', measure:'Reset fault and restart. If fault persists, contact us on +33 (0)6.833.98.42.92.'},
                35  : {status:'Application fault' , cause: '▪ Selected application does not work.' , measure:''},
                39  : {status:'Unit deleted' , cause: '▪ Optional board deleted.', measure:'Reset.'},
                40  : {status:'Unit not recognized' , cause: '▪ Optional board or inverter not recognized.', measure:'Contact us on +33 (0)6.833.98.42.92.'},
                41  : {status:'IGBT over temperature (soft-switched)' , cause: '▪ The thermal protection between the UPS and IGBT has detected a too high transient overload', measure:'Check load level. Check motor.'},
                44  : {status:'Unit changed' , cause: '▪ Optional board replaced.\n▪ Optional board with factory settings.', measure:'Reset.'},
                45  : {status:'Unit added' , cause: '▪ Optional board added.' , measure:'Reset.'},
                50  : {status:'Sensor loss' , cause: 'Current at analogue input below required minimum:\n▪ Control cable damaged or disconnected.\n▪ Source of defective signal.', measure:'Check cabling of level pressure sensor upstream of OW.'},
                51  : {status:'External fault' , cause: '▪ Digital input fault. Digital input has been set as an external fault input and has been activated.', measure: 'Check settings and device indicated by the external fault message. Also check the device\'s cabling.'},
                52  : {status:'Communication fault to keypad' , cause: '▪ Communication broken between operator keybad and the frequency converter.', measure: 'Check operator keypad connection and its cable.'},
                53  : {status:'Communication bus fault' , cause: '▪ Defective connection between the master and bus board.', measure: 'Check installation. If installation is correct, contact us on +33 (0)6.833.98.42.92.'},
                54  : {status:'Busfault' , cause: '▪ Slot fault between I/O optional board and inverter control board. Optional board or connectors defective.', measure: 'Check the board and its connectors (Slots).'},
                55  : {status:'Overflow' , cause: '', measure: ''}
            }
    }

    initialize() {
        createElement('div', 'vfd-status__title', this.element, 'VFD Status');
        this.currentWrapper  = createElement('div', 'vfd-status__current-wrapper', this.element);
        let codeWrapper     = createElement('div', 'vfd-status__code-wrapper', this.currentWrapper);
        let statusWrapper   = createElement('div', 'vfd-status__status-wrapper', this.currentWrapper);
        this.causeWrapper    = createElement('div', 'vfd-status__text-wrapper hide', this.element);
        this.correctWrapper  = createElement('div', 'vfd-status__correct-wrapper hide', this.element);

        // Create subtitles
        createElement('div', 'vfd-status__subtitle', codeWrapper,   'Code');
        createElement('div', 'vfd-status__subtitle', statusWrapper, 'Status');
        createElement('div', 'vfd-status__subtitle', this.causeWrapper,  'Possible Cause');
        createElement('div', 'vfd-status__subtitle', this.correctWrapper,   'Corrective Measures');

        // Create the value element:
        this.codeElement    = createElement('p', 'vfd-status__code', codeWrapper);
        this.statusElement  = createElement('p', 'vfd-status__status', statusWrapper);
        this.causeElement   = createElement('p', 'vfd-status__cause' , this.causeWrapper);
        this.correctElement = createElement('p', 'vfd-status__cause' , this.correctWrapper);

        this.fInitialized = true;
        console.log(this.node)
        // Connect node after fInitialized, since it may immediately call our update() method:
        if (this.node)
            this.node.subscribe(this, this.element, this.redXMask);
        return this; // Used for chaining
    };

    update(node) {	// node value/quality changed:
        assert (this.fInitialized);
        if (node.quality!=NodeQuality.NQ_GOOD)	{	// blank out the value:
            this.codeElement.textContent = '';
            this.statusElement.textContent = '';
            this.causeElement.textContent = '';
            this.correctElement.textContent = '';
            return;
        }
        else {
            let value = node.getValue();
            if (value > 0) {
                this.causeWrapper.classList.remove('hide');
                this.correctWrapper.classList.remove('hide');
                this.element.style.backgroundColor = 'var(--color-alarm-1)'
            }
            this.codeElement.textContent    = node.getFormattedText();
            if (typeof this.vfdStatusText[value] === 'undefined')
                return;
            this.statusElement.textContent  = this.vfdStatusText[value].status;
            this.causeElement.textContent   = this.vfdStatusText[value].cause;
            this.correctElement.textContent = this.vfdStatusText[value].measure;
        }
        // else leave the old value, which will have a red x over it
    };

    destroy() {
        assert (this.fInitialized);

        if (this.node)
            this.node.unsubscribe(this);

        this.element.removeChildren();					// Remove elements we created
        this.element.classList.remove('digital-gauge');	// Remove class we added

        this.unregisterAsWidget();
    };
}
