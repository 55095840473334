import {createElement} from '../elements';
import owner from '../../owner';
import './configtabview.css';
import View from './view';
import { WritesEnabler } from '../dialog';

// This tab display currently configured alarm on a system and allows alarms to be added/modified
export default class ConfigTabViewClassic extends View {
    constructor(device) {
		super();
        this.device 		= device;
        this.deviceID		= this.device.id;	// Get a protocol reference to our device
    };

	initialize(parent) {	// Called when the tab is brought up for the first time
		super.initialize(parent);
		this.wrapper = createElement('div', 'config__wrapper', this.parent);
		let logContainer = createElement('div', 'config__container', this.wrapper);
		let logTitle = createElement('div', 'config__title', logContainer, 'Startup Log');
		this.log = createElement('div', 'config__log', logContainer);		// Log division
		var tab = createElement('div', 'configWrapper', this.wrapper);		// Wrapper for the whole tab
		createElement('div', 'configEntry', this.log, 'Trying to grab lastest log from site...');	// Sample log statements

		let optionsContainer = createElement('div', 'config__options', this.wrapper)

		createElement('div', 'config__title', optionsContainer, 'Modify Tags');		// Right side title

		createElement('div', 'configSubtitle', optionsContainer, 'Pull the current tag map from the device:');
		var getWrapper = createElement('div', 'configInput', optionsContainer);    // Wrapper for the button input
		this.graphID			= owner.ldc.registerGraph(this);				// Register the graph so we can get data callbacks
		this.getButton 			= createElement('input', 'se-button', getWrapper);	// Input to get the latest file from the box
		this.getButton.type 	= 'button';									// Make it a button
		this.getButton.value	= 'Pull tags';								// Button text
		this.getButton.onclick	= this.onGetFile.bind(this);				// What to do when the button is clicked

		createElement('div', 'configSubtitle', optionsContainer, 'Push a new tag map to the device:');	// Subtitle for push
		var seetWrapper = createElement('div', 'configInput', optionsContainer);	    // Wrapper for file selector input
		this.setButton			= createElement('input', null, seetWrapper);	// Create a new input
		this.setButton.type		= 'file';										// Make it a file selector
		this.setButton.name		= "files[]";									// Name it will give selected files
		this.setButton.accept	= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';	// Excel files only, please
		this.setButton.onchange = this.onSetFile.bind(this);					// What to do when the selected file is changed
		this.setButton.disabled	= !owner.ldc.user.fTagConfig;

		owner.ldc.getStartupLog(this.graphID, this.deviceID);					// Get the startup log so we can display it
		this.fInitialized = true;

		return this;
	}

	onLogReceived(log) {	// Got back the startup log
		this.log.removeChildren();	// Remove any existing children
		this.log.list = createElement('ul', 'configList', this.log);
		for (var i = 0; i < log.length; ++i)	// For each log entry
			createElement('li', log[i].type ? 'configError' : 'configEntry', this.log.list, log[i].text);	// Create a text display for it
	}

	onGetFile() {	// The user clicked the button to pull the tag map from the device
		owner.ldc.getConfigFile(this.graphID, this.deviceID);	// Request the map
	}

	onSetFile() {	// The user has selected a new file
		new WritesEnabler(()=>{
			if (this.setButton.files.length == 0)	// Nothing selected
				return;								// Nothing to do
			var file = this.setButton.files[0];		// Reference the selected file
			var reader = new FileReader();			// Create file reader
			var tab = this;							// Create a reference to our ConfigTab so the anonymous function below can use it
			reader.onload = function(e) {tab.onFileLoad(e.target.result, file.size);};	// When the tab loads, call the onFileLoad method
			reader.readAsBinaryString(file.slice(0, file.size));	// Read the file as a binary string
		});
	}

	onFileLoad(file, fileSize) {	// Selected file has been loaded
		// TODO: Instead of immediately sending, do a diff for the user? At least confirm
		owner.ldc.setConfigFile(this.graphID, this.deviceID, file, fileSize);
	}

	onConfigFile(blob) {						// Got the new config file
		// Create an href element that will allow the user to download the data as an Excel file
		var downloadLink = document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
		downloadLink.download = this.device.siteName+'_TagMap.xlsx';			// File name to download as
		downloadLink.href = URL.createObjectURL(blob);	// Make a URL for the file
		downloadLink.click();							// Simulate clicking on the hyperlink
	}

	onConfigFileSet() {	// They got our new file
		alert('config set')
//		console.log('Config file set');
	}

	destroy() {
		if (this.graphID)						// If we were initialized
            owner.ldc.unregisterGraph(this.graphID);		// Unregister ourselves as a callback
		this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}
};
