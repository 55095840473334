import { Attribute } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './accordion.html';

@RegisterWidget({tag: 'se-accordion', template: template, displayName: 'Accordion'})
export class Accordion extends Widget {
    isOpen: boolean = false;
    private isTransitioning: boolean = false;
    private transitionCallback: (value: boolean | PromiseLike<boolean>) => void
    @Attribute({displayName: 'Open By Default'}) defaultOpen: boolean = false;

    protected connectedCallback(): void {
        this.style.setProperty('--accordion-max-height', this.defaultOpen ? `none` : '0px');
        this.isOpen = this.defaultOpen;
    }

    open(): Promise<boolean> {
        if (this.isTransitioning || this.isOpen)
            return new Promise(resolve => resolve(false));
        this.isTransitioning = true;
        this.isOpen = true;
        this.style.setProperty('--transition', 'none');
        this.style.setProperty('--accordion-max-height', '0px');
        requestAnimationFrame(() => {
            this.style.setProperty('--transition', 'max-height 0.2s ease-in-out');
            this.style.setProperty('--accordion-max-height', `${this.scrollHeight}px`);
            this.addEventListener('transitionend', () => this.onTransitionEnd(), {once: true});
        });
        return new Promise(resolve => this.transitionCallback = () => resolve(true));
    }

    close(): Promise<boolean>  {
        if (this.isTransitioning || !this.isOpen)
            return new Promise(resolve => resolve(false));
        this.isTransitioning = true;
        this.isOpen = false;
        this.style.setProperty('--transition', 'none');
        this.style.setProperty('--accordion-max-height', `${this.scrollHeight}px`);
        requestAnimationFrame(() => {
            this.style.removeProperty('--transition');
            this.style.setProperty('--accordion-max-height', '0px');
            this.addEventListener('transitionend',  () => this.onTransitionEnd(), {once: true});
        });

        return new Promise(resolve => this.transitionCallback = () => resolve(true));
    }

    toggle() {
        if (this.isOpen)
            this.close();
        else
            this.open();
    }

    private onTransitionEnd() {
        this.isTransitioning = false;
        if (this.isOpen)
            this.style.setProperty('--accordion-max-height', 'none');
        this.transitionCallback(true);
    }
}
