import {Node, NodeQuality }           from '../node';
import NodeManager      from '../nodemanager';
import Localization     from '../localization';
import {createElement, createUniqueId}  from '../elements';
import createSVGElement from '../svgelements';
import {Widget}         from '../widget';
import LiveData         from '../livedata';
import {StaticGraph}  	from '../graph';
import {Color}          from '../color';
import owner            from '../../owner';
import assert           from '../debug';
import {PumpBank}       from '../pumpbank';
import CheckIcon 		from '../images/icons/check.svg'
import XIcon	 		from '../images/icons/cancel.svg';
import CircleIcon 		from '../images/icons/circle.svg';
import View from './view';
import './specificenergyview.css';
import Switch from '../components/switch';
import './simplifiedsemapview.css';
import Helper from '../helper';
import { TagUnit, TagUnitQuantity, UnitsMap, convert } from '../widgets/lib/tagunits';
import { Role } from '../role';
import { getHash, getRouteAndProperties } from '../router/router';

// Make a graph of all the Configuration points the uber controller remembers.
export default class SimplifiedSpecificEnergyView extends View {
    constructor(device, ldc) {
		super();
		assert (device.isTreeComplete(), 'Device must already have a complete node tree');
        this.ldc		= ldc;			// The LiveDataClient
        this.device 	= device;
		this.fBuilt 	= false;
		this.selectedFlow = 0;
		this.minPORRange = 0.01;
	};

	initialize(parent) {
		super.initialize(parent);
		this.rootNode  		= this.device.tree.nodes[0];
        this.pumpSystem		= this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
        this.dpoFolder		= this.rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
        this.periods		= this.dpoFolder.findByRole(Role.ROLE_TLC_PERIOD);	// Parts of Day folders
        this.index			= -1;
		this.flow			= -1;	// IIR filter storage values for the current point
		this.sec			= -1;
		this.filterID		= setInterval(this.updateCurrentPoint.bind(this), 200, this);	// Update five times a second
		this.selectedAuto	= false;

		this.flowLimits		= [0, 0, 0, 0];	// This is used to draw the min and max flow limits (x axis). Will be [minFlow, minFlow, maxFlow, maxFlow]
		this.SECMaxes		= [0, 0, 0, 0];	// This is used to draw the min and max flow limits (y axis). Will be [0, maxSEC, maxSEC, 0]
		this.limitedFlows	= [];
		this.limitedSECs	= [];
		this.globalEnables	= [];	// Find the enabled nodes for all the global constraints. Then we can show the user we a constraint is overwritten

		this.labels			= ['FlowLimits', 'FlowDeadband', 'SecDeadband'];
		this.colors			= [owner.colors.hex('--color-orange-3'), owner.colors.hex('--color-orange-2'), owner.colors.hex('--color-onSurface')]; // colors for sandbox
		this.visible		= [true, true, true];

		this.flowBandXs		= [0,0,0,0];
		this.secBandXs		= [];
		this.secBandYs		= [];
		this.secBandMaxes	= [];


		this.selectedRegime	= 1;			// Start off on the 1st regime selected
		this.selectedPoint	= 0;			// Start off on the first point in the 0 regime

		this.regimeMap 		= new Map();
		this.pumps			= this.pumpSystem.findByRole(Role.ROLE_PUMP);	// Find all the pump folders
        var modelPumps		= this.pumpSystem.findByRole(Role.ROLE_MODEL_PUMP);
        var maintenance		= this.pumpSystem.findChild('Maintenance');
		this.nodeManager	= new NodeManager(this);
		this.lastCurveCheck = this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SYSTEM_MODEL_TIMESTAMP);
		this.energyCost 	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_ENERGY_COST);
		this.offLineNodes 	= this.nodeManager.addAllNodesWithRole(this.pumpSystem, Role.ROLE_PUMP_OFFLINE);
		this.targetSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_TARGET_SPEED);
		this.idealSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_IDEAL_SPEED);
		this.advisoryError	= this.nodeManager.addNodeByName(this.dpoFolder, 'AdvisorySpeedError');
		this.flowNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_TOTAL_FLOW);
		this.secNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SEC);
		this.pressureNode	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_DISCHARGE_PRESSURE);
		this.energyMetric	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_STATION_EFFICIENCY);
		this.qMin			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMIN);
		this.qMax			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMAX);
		this.nPumps			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_MAX_PUMPS);
		this.dpoInControl	= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_DPO_IN_CONTROL);
		this.offHull		= this.nodeManager.addNodeByName(this.dpoFolder, 'OffConvexHull');
		this.noValidPoints	= this.nodeManager.addNodeByName(this.dpoFolder, 'NoValidPoints');
		this.fFlowDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, 'UsingFlowDeadband');
		this.fSecDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, 'UsingSecDeadband');
		this.powerLimited	= this.nodeManager.addNodeByName(this.dpoFolder, 'PowerLimited');
		this.nPumpsLimited	= this.nodeManager.addNodeByName(this.dpoFolder, 'MaxPumpsLimited');
		this.odorContolled	= this.nodeManager.addNodeByName(this.pumpSystem, 'OdorControlTriggered');
		this.floatOverride	= this.nodeManager.addNodeByName(this.pumpSystem, 'Override');
		if (maintenance)
			this.maintenance	= this.nodeManager.addNodeByName(maintenance, 'MaintenanceCycleState');

		var pressureUnits	= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_PRESSURE) || TagUnit.TU_PSI;
		this.convertPSI		= convert(1, TagUnit.TU_PSI, pressureUnits);

		var powerUnits		= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_POWER) || TagUnit.TU_KW;
		this.convertKW		= convert(1, TagUnit.TU_KW, powerUnits);

		let maxSpeedNode	= this.pumpSystem.findChildByRole(Role.ROLE_MAX_SPEED_HZ); // Final max line frequency node
		var speedUnits		= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) || TagUnit.TU_HZ;
		this.convertHz		= convert(1, TagUnit.TU_HZ, speedUnits, maxSpeedNode.getValue());

		this.convertSEC		= convert(1, TagUnit.TU_KW_HR_PER_MG, this.secNode.units);

		this.lineOptions	= {drawPoints: false, connectSeparatedPoints: false, nomouseover: true};
		var fillOptions		= {connectSeparatedPoints: false, nomouseover: true};	// For fills, no hover and don't draw lines
		this.options		= {
			colors:						this.colors,		// Colors
			drawY2Line:					true,
			visibility:					this.visible,		// Which lines to show and hide
			connectSeparatedPoints:		false,				// Lines between points
			drawPoints: 				false,				// Put an indicator at each point
			pointSize: 					2,					// How big to make the circle
			xAxisAsNumber: 				true, 				// Interpret the x-axis as numbers, not time stamps
			drawCallback:				this.onDraw.bind(this),
			clickCallback:				this.onCanvasClicked.bind(this),
			AutoHull: 					fillOptions,
			AutoLine:					this.lineOptions,
			ActHull: 					fillOptions,
			ActLine: 					this.lineOptions,
			drawYAxis:					false,
            drawYGrid:              	false,
            drawXGrid:              	false,
			SecDeadband: {
				drawPoints:				false,
				connectSeparatedPoints: false,
				nomouseover:			true
			},
			FlowLimits: {									// Options specific to the shaded area representing qMin and qMax
				drawPoints:				false,
				strokeWidth:			0, 					// Don't draw lines for these guys
				fillAlpha: 				0.4,					// All the way opaque (as we have selected colors appropriately)
				drawBehind:				true,				// Draw behind the axis
				nomouseover:			true				// This flags tells Dygraph to ignore this line for mouse events
			},
			FlowDeadband: {									// Options specific to the shaded area representing qMin and qMax
				strokeWidth:			0, 					// Don't draw lines for these guys
				fillAlpha: 				0.4,					// All the way opaque (as we have selected colors appropriately)
				drawBehind:				true,				// Draw behind the axis
				nomouseover:			true				// This flags tells Dygraph to ignore this line for mouse events
			},
			Limited:					fillOptions,
			xlabel: 					Localization.toLocal("Flow (" + this.flowNode.getUnitsText() + ")"),			// X-axis title
			ylabel: 					'',//Localization.toLocal("Specific Energy (" + this.secNode.getUnitsText() + ")"),	// Y-axis title
			yRangePad:					200,
		};

		assert(this.pumps.length == this.offLineNodes.length)
		assert(this.pumps.length == this.targetSpeeds.length, 'Should have a next speed for each pump!');
		assert(this.idealSpeeds.length == this.targetSpeeds.length, 'Should have a next speed for each pump!');

		this.totalBase = 0;
		var basePowers = [];
		this.hoas = [], this.startFails = [], this.stopFails = [], this.faulted = [], this.preferred = [], this.fPORs = [];
		for (var i = 0; i < this.targetSpeeds.length; ++i) {
			basePowers.push(this.pumps[i].findChild('BaseLoad').getValue());	// This is a final node
			this.totalBase += basePowers.back();
			this.hoas.push(this.nodeManager.addNodeByRole(this.pumps[i], Role.ROLE_PUMP_OVERALL_HOA));
			this.startFails.push(this.nodeManager.addNodeByName(this.pumps[i], "StartFail"));
			this.stopFails.push(this.nodeManager.addNodeByName(this.pumps[i], "StopFail"));
			this.faulted.push(this.nodeManager.addNodeByRole(this.pumps[i], Role.ROLE_BOOL_FAULTED));
			this.preferred.push(this.nodeManager.addNodeByName(this.pumps[i], "fPreferred"));
			this.fPORs.push(this.nodeManager.addNodeByName(modelPumps[i], "OutsidePOR"));
		}
		this.limiting				= new PowerLimiting(this, this.dpoFolder, basePowers);	// This guy will color points differently that are over metered power
		this.graphID				= this.ldc.registerGraph(this);	// Register for graph data
		this.fRedraw				= false;		// We will set this to true when it is time to replot the graph
		this.fLandscape 			= window.innerHeight < 420;
		this.wrapper 				= createElement('div', 'specific-energy-view__page-wrapper',this.parent);
		this.parameterWrapper		= createElement('div', 'overview__parameter-wrapper', this.wrapper)
		var overviewWrapper 		= createElement('div', 'overview__overview-graph-wrapper', this.wrapper); // center div that holds the gauges and chart
		var dataWrapper				= createElement('div', 'specific-energy-view__data-wrapper hide', this.wrapper);
		let parameterContainer		= createElement('div', 'overview__parameter-wrapper__value-container',this.parameterWrapper);
		this.parameterTable 		= createElement('div', 'overview__parameter-table', parameterContainer);
		this.pumpBankWrapper 		= createElement('div', 'overview__pump-bank-wrapper', overviewWrapper);
		this.pumpTable 				= createElement('div', 'overview__parameter-table', parameterContainer)
		var overviewGraphContainer 	= createElement('div', 'overview__overview-graph-container', overviewWrapper); // graph flex wrapper
		this.graphRow				= createElement('div', 'overview__overview-graph-wrapper__graph-row', overviewGraphContainer);
		this.constraintContainer 	= createElement('div', 'specific-energy-view__constraint-container',dataWrapper);
		var SEWrapper 				= createElement('div', 'specific-energy-view__se-wrapper', this.graphRow);
		var SEGraphContainer 		= createElement('div', 'specific-energy-view__se-graph-container', SEWrapper);
		this.SEGraph 				= createElement('div', 'specific-energy-view__se-graph', SEGraphContainer);
		this.graphRow				= createElement('div', 'simplified-specific-energy-view__se-graph-wrapper__graph-row', this.SEGraph);
		let gridContainer 			= createElement('div', 'specific-energy-view__grid-container', this.SEGraph);
		let gridHeader 				= createElement('div', 'specific-energy-view__grid-header', gridContainer);
		this.grid 					= createElement('div', 'specific-energy-view__grid', gridContainer);
		this.speeds 				= createElement('div', 'specific-energy-view__grid__speeds', this.grid);
		this.graphDiv				= createElement('div', 'simplifiedSECMapGraphDiv', this.graphRow);

		this.speeds.style.width = `${this.targetSpeeds.length * 48 + 96}px`;

		// dygraph is greedy about capturing scroll events. this little event listener makes sure to pass scroll events along to the grid
		this.graphDiv.onwheel = (e) => {
			this.grid.scrollTop += e.deltaY;
		}

		this.graphRow.style.paddingLeft = this.pumps.length * 48 + 96 + 'px';
		this.graphRow.style.paddingTop 	= '96px';

		// Find constraint folder
		var constraintFolder	= this.dpoFolder.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FOLDER);
		this.subscribeToEnableNodes(constraintFolder);
		this.subscribeToEnableNodes(this.dpoFolder.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ROC_FOLDER));
		this.subscribeToEnableNodes(this.dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER));
		let targetFolder = this.dpoFolder.findChildByRole(Role.ROLE_TLC_TARGET_TANK_FOLDER);
		let sourceFolder = this.dpoFolder.findChildByRole(Role.ROLE_TLC_SOURCE_TANK_FOLDER);

		this.subscribeToEnableNodes(targetFolder);
		this.subscribeToEnableNodes(sourceFolder)

		this.flowNodes = [], this.dischargeNodes = [], this.suctionNodes = [];	// Fresh arrays to hold the nodes for each pv
		for (var i = 0; constraintFolder && i < constraintFolder.children.length; ++i)
			this.checkRole(constraintFolder.children[i], Role.ROLE_TLC_CONSTRAINT_PV, false);		// Sort the nodes by process variable

		var folder	= this.dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER);	// Find the folder, if it exists
		this.powerNodes	= folder ? folder.children : [];		// Store the nodes

		this.flowDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, "FlowDeadband");
		this.secDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, "SEC_Deadband");

		var flow = this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);
		createElement('tag-badge', 'overview__gauge PumpStationFlow', this.parameterTable, '', {statusTag: {tag: flow}, showUnits: true});

		var sec = this.pumpSystem.findChildByRole(Role.ROLE_SEC);
		createElement('tag-badge', 'overview__gauge PumpStationSEC', this.parameterTable, '', {statusTag: {tag: sec}, showUnits: true});

		var suction 	= this.pumpSystem.findChildByRole(Role.ROLE_SUCTION_PRESSURE);
		if (suction)
			createElement('tag-badge', 'overview__gauge PumpStationSuction', this.parameterTable, '', {statusTag: {tag: suction}, showUnits: true});

		var discharge 	= this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		if (discharge)
			createElement('tag-badge', 'overview__gauge PumpStationDP', this.parameterTable, '', {statusTag: {tag: discharge}, showUnits: true});

		var chlorine	= this.dpoFolder.findChildByRole(Role.ROLE_CHLORINE_RESIDUAL);
		if (chlorine)
			createElement('tag-badge', 'overview__gauge PumpStationChlorine', this.parameterTable, '', {statusTag: {tag: discharge}, showUnits: true});

		this.operatorChecklist 		= createElement('div', 'specific-energy-view__operator', this.parameterTable);
		createElement('div', 'specific-energy-view__operator__title', this.operatorChecklist, 'Operator Checklist');
		this.allPORCheck 		= createElement('div', 'specific-energy-view__operator__constraint', this.operatorChecklist);
		this.allPORCheck.icon 	= createElement('img', 'specific-energy-view__operator__constraint__icon ', this.allPORCheck)
		this.allPORCheck.text 	= createElement('div', '', this.allPORCheck, 'All pumps in POR');

		let recommendations 		= createElement('div', 'specific-energy-view__operator', this.parameterTable);
		createElement('div', 'specific-energy-view__operator__title', recommendations, 'Recommendations');
		this.recommendations 	= createElement('div', 'specific-energy-view__recommendations', recommendations);

		let description 		= createElement('div', 'specific-energy-view__operator', this.parameterTable);
		createElement('div', 'specific-energy-view__operator__title', description, 'Status');
		this.description 		= createElement('div', 'specific-energy-view__description', description);

		this.pumpBank 			= new PumpBank(this.pumpSystem, this.fLandscape? this.pumpTable : this.pumpBankWrapper, this.dpoFolder);

		this.currentPulse	= createGraphPulse(this.graphDiv, 'green');
		this.selectPulse	= createGraphPulse(this.graphDiv, '#17FFE8');

		this.constraintLines = [];
		this.constraints = [...this.powerNodes, ...this.flowNodes, ...this.dischargeNodes, ...this.suctionNodes];
		if (targetFolder) {
			this.constraints.push(targetFolder)
		}
		if (sourceFolder) {
			this.constraints.push(sourceFolder)
		}

		this.lineWrapper = createSVGElement('svg', 'secMapSVGline', this.graphDiv, {height:500});
		this.createGraphLines(this.powerNodes);	// We put power nodes first becasue they are the hardest to position the names of
		this.createGraphLines(this.flowNodes);
		this.createGraphLines(this.dischargeNodes);
		this.createGraphLines(this.suctionNodes);
		var wetWellFolder = this.dpoFolder.findChild('SourceTankMinFlow');
		this.fWetWell = wetWellFolder !== null;
		this.createGraphLine(wetWellFolder, 0, Role.ROLE_TLC_SOURCE_TANK_FOLDER)
		this.createGraphLine(this.dpoFolder.findChild('SourceTankMaxFlow'), 1, Role.ROLE_TLC_SOURCE_TANK_FOLDER);
		this.createGraphLine(this.dpoFolder.findChild('TargetTankMinFlow'), 0, Role.ROLE_TLC_TARGET_TANK_FOLDER);

		this.selectedFlowLine 		= createSVGElement('g', null, this.lineWrapper);					// Start out collapsed in case there's not an SEC map yet
		this.selectedFlowLine.child = createSVGElement('line', 'specific-energy-view__grid__flow-line', this.selectedFlowLine, {'stroke':'var(--color-blue-8)', 'stroke-width':'2px'});
		this.selectedFlowLabel 		= createElement('div', 'specific-energy-view__flow-line__label', this.wrapper);
		this.selectedFlowSpinner 	= createElement('input', 'specific-energy-view__flow-line__spinner', this.selectedFlowLabel, undefined, {'type':'number', 'step':1/(10 ** this.flowNode.digits)})
		createElement('div', 'specific-energy-view__flow-line__units', this.selectedFlowLabel, this.flowNode.getUnitsText());
		this.selectedFlowSpinner.onblur = () => {
			this.currentSwitch.state 	= false;
			this.selectedFlow 			= parseFloat(this.selectedFlowSpinner.value);

			this.drawRanges();
		}
		this.spinnerDiv				= createElement('div', 'secMapSpinnerWrapper', this.graphDiv);

		// Create a division to hold the graph we are going to create onJobComplete

		var advisoryNode	= this.dpoFolder.findChild('AdvisoryMode');
		this.fAdvisoryMode	= advisoryNode && advisoryNode.getValue();

		this.message 		= createElement('div', null, this.description);

		this.fSmall			= false

		var stationData		= createElement('div', 'secMapSelectedPointRow', this.innerWrapper);
		var dataLabel 		= createElement('div', 'secMapSelectedPointColumn', stationData);
		var dataValue		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', stationData);
		var dataUnits		= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', stationData);

		createElement('div', 'secMapSelectedPointCell', dataLabel, 'Flow:');
		createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = this.flowNode.getUnitsText();

		createElement('div', 'secMapSelectedPointCell', dataLabel, 'Specific Energy:');
		this.selectedSEC	= createElement('div', 'secMapSelectedPointCell', dataValue);
		createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = this.secNode.getUnitsText();

		this.sucLabel		= createElement('div', 'secMapSelectedPointCell', dataLabel, 'Suction Pressure:');
		this.selectedSuc	= createElement('div', 'secMapSelectedPointCell', dataValue);
		this.sucUnits		= createElement('div', 'secMapSelectedPointCell', dataUnits, UnitsMap.get(pressureUnits).abbrev);

		var discharge		= this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		this.disLabel		= createElement('div', 'secMapSelectedPointCell', dataLabel, 'Discharge Pressure:');
		this.selectedDis	= createElement('div', 'secMapSelectedPointCell', dataValue);
		this.disUnits		= createElement('div', 'secMapSelectedPointCell', dataUnits, discharge ? discharge.getUnitsText() : '');

		this.offlineMap = new Map();
		// Create and store the individual divs for pump data
		for (var i = 0; i < this.pumps.length; ++i) {
			this.offlineMap.set(this.pumps[i], [createElement('div', 'specific-energy-view__grid-header__name', gridHeader, `${this.pumps[i].getDisplayName()} (${UnitsMap.get(speedUnits).abbrev})`)]);
		}

		createElement('div', 'specific-energy-view__grid-header__savings', gridHeader, `Annual Additional Cost`);

		let optionsContainer = createElement('div', 'specific-energy-view__grid__options', gridHeader);

		let regimeFilterContainer 	= createElement('div', 'specific-energy-view__grid__options__wrapper', optionsContainer)
		createElement('div', 'specific-energy-view__grid__options__title', regimeFilterContainer, 'Show All: ')
		this.regimeFilter 			= new Switch(regimeFilterContainer, false, 'var(--color-primary)', (state) => {
			this.drawRanges();
		})

		let currentSwitchContainer 	= createElement('div', 'specific-energy-view__grid__options__wrapper', optionsContainer)
		createElement('div', 'specific-energy-view__grid__options__title', currentSwitchContainer, 'Track Current Flow: ')

		this.currentSwitch 			= new Switch(currentSwitchContainer, true, 'var(--color-primary)', (state) => {
			this.drawRanges();
		})

		var isCurrentRow	= createElement('div', 'secMapSelectedPointRow', this.innerWrapper);
		var currentLabel 	= createElement('div', 'secMapSelectedPointColumn', isCurrentRow);
		var currentCheckBox	= createElement('div', 'se-checkbox', isCurrentRow);
		let currentId 		= createUniqueId()
		this.selectCurrent	= createElement('input', 'secMapSelectedPointCell', currentCheckBox, null, {'type':'checkbox', 'id':currentId});
		createElement('label', null, currentCheckBox, 'Current Operating Point', {'htmlFor':currentId})

		let idealCheckBox 	= createElement('div', 'se-checkbox', isCurrentRow);
		this.selectCurrent.onchange	= this.goToCurrentTarget.bind(this);
		let idealId = createUniqueId()
		this.selectIdeal			= createElement('input', 'secMapSelectedPointCell', idealCheckBox, null, {'type':'checkbox', 'id':idealId});
		createElement('label', null, idealCheckBox, 'Recommended Operating Point', {'htmlFor':idealId})
		this.selectIdeal.onchange	= this.goToIdealTarget.bind(this);

		this.keyPressFunction	= this.onKeyPress.bind(this);	// We want to listen for the left and right arrow keys
		window.addEventListener('keydown', this.keyPressFunction);
		this.nodeManager.subscribe();

		this.jobCallback	= this.onJobCompleted.bind(this);
		owner.addJobCompletedCallback(this.jobCallback);	// Register for the callback
        owner.navBar.registerHelp('Help with Operation Tab', ()=>this.buildHelper());
		this.fInitialized = true;
		return this;
	}

	onCanvasClicked(e, x) {
		this.selectedFlowSpinner.blur()
		if (typeof x == 'undefined')
			return;
		this.selectedFlow = x;
		this.currentSwitch.state = false;
		this.fRedraw = true;
		this.onJobCompleted();
	}

	getConfigFromSpeeds(speeds) {
		var config = 0;
		for (var i = 0; i < speeds.length; ++i)
			if (speeds[i].getValue() > 0)
				config += (1 << i);
		return config;
	}

	updateCurrentPoint() {	// Called five times a second
		// SKUN-57, node.getValue() throws assert if ReadPermission is false
		if (this.graph == undefined || this.flowNode.quality != NodeQuality.NQ_GOOD || this.secNode.quality != NodeQuality.NQ_GOOD || !this.flowNode.hasReadPermission() || !this.secNode.hasReadPermission())
			return;

		if (this.flow == -1) {	// Firt time we've been through here, just take the initial value
			this.flow = this.flowNode.getValue();
			this.sec = this.secNode.getValue();
		} else {				// Every other time, 95% of the old value and 5% of the new
			this.flow = this.flow * 0.95 + this.flowNode.getValue()*0.05;
			this.sec = this.sec * 0.95 + this.secNode.getValue()*0.05;
		}
	}

	onDraw(g, fInitial) {
		if (fInitial)
			return;

		this.updateCurrentPoint();
	}

	onKeyPress(e) {
		if (this.graphDiv.clientHeight == 0)	// If we aren't on the displayed tab
			return;								// Leave. This way, graph doesn't change sizes if they go back to our tab
		if (e.keyCode == 13) {
			this.selectedFlowSpinner.blur();
		}
	}

	queryTargetPoint() {
		var targetConfig = this.getConfigFromSpeeds(this.targetSpeeds);	// Get our config
		var fConfigInAuto = false;										// Assume config is in the actual regimes until found otherwise
		for (var i = 0; i < this.autoRegimes.length; ++i) {		// Check all fully auto configs
			if (this.autoRegimes[i].config == targetConfig) {	// If the config matches
				fConfigInAuto = true;							// The config really is in the auto system
				break;											// No need to look further
			}
		}
		this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, targetConfig, fConfigInAuto, this.targetSpeeds, this.convertHz, null);
	}

	queryIdealPoint() {
		// The ideal point might not be in the full auto system if we are alternating. This is because DPO doesn't send points twice
		// (once for the auto system and actual system). So, check to see if the regime is in the auto regimes instead of assuming.
		var idealConfig = this.getConfigFromSpeeds(this.idealSpeeds);	// Get our config
		var fConfigInAuto = false;										// Assume config is in the actual regimes until found otherwise
		for (var i = 0; i < this.autoRegimes.length; ++i) {		// Check all fully auto configs
			if (this.autoRegimes[i].config == idealConfig) {	// If the config matches
				fConfigInAuto = true;							// The config really is in the auto system
				break;											// No need to look further
			}
		}
		this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, idealConfig, fConfigInAuto, this.idealSpeeds, this.convertHz, null);
	}

	updateMessagefunction() {	// Brain dump of the UberController
		if (this.nPumpsLimited === undefined)		// If we don't have the newest nodes, this method won't work right
			return;
		if (this.lastCurveCheck.getValue() == 0)	// No solved system yet
			return this.message.textContent = '';	// No message allowed
		if (!this.dpoInControl.getValue() && !this.fAdvisoryMode)	// If we aren't in advisory mode and not in control
			return this.message.textContent = 'The DPO is not in control of this pump system.'	// Say so

		var minCon, maxCon;
		for (var i = 0; i < this.constraintLines.length; ++i) {	// Check all the lines
			var line = this.constraintLines[i];		// Convenience reference to the line
			if (line.isPowerMeter)					// If the line is for power constraints
				continue;							// Skip it
			if (line.active && line.active.getValue()) {			// If the line if in control
				if (line.fMax)						// Save it as the min or max constraint
					maxCon = line;
				else
					minCon = line;
			}
			if (minCon !== undefined && maxCon !== undefined)	// If we have both
				break;											// Leave the loop
		}
		if (this.maintenance) {							// If we are an LSG with a maintenance cycle
			var state = this.maintenance.getValue();	// Get the state value
			// If we are in a maintenance state, we will set the message and leave
			if (state == 1) 							// Initialized FIXME: Factor out the state names
				return this.message.textContent = "Float test initialized."
			else if (state == 2)						// Filling
				return this.message.textContent = "Float test underway. Filling the wet well."
			else if (state == 3) 						// Draining
				return this.message.textContent = "Float test underway. Draining the wet well."
		}

		var message = '';								// Start the message off as blank
		if (!this.offHull.getValue())					// Not off the hull
			message += " Operating at minumum Specific Energy. ";	// We're on the convex hull
		else if (!this.noValidPoints.getValue())		// Not no valid points
			message += " Meeting all constraints. ";	// We're meeting constraints, but not on the hull
		else											// Operating outside constraints
			message += " Unable to meet all constraints. ";			// We're trying our best

		if (minCon) {									// Found a minimum constraint
			if (minCon.tankRole != null) {				// If the min constraint is the target tanks
				if (minCon.spNode.getValue() == 0)		// No flow requested
					message += this.fWetWell ? "Monitoring wet well level" : 'Target tanks are full';	// Say so
				else									// Flow requested
					message += this.fWetWell ? 'Draining wet well' : 'Filling target tanks';			// Say so
			} else {									// Not a tank as a min constraint
				message += 'Maintaining min ' + (minCon.pvNode.roles.has(Role.ROLE_TOTAL_FLOW) ? 'flow' : 'pressure');	// Say we are maintaining a flow/pressure constraint
				if (minCon.dbNode)						// If it is deadbanded
					message += ' setpoint';				// Call it a setpoint
			}
			if (maxCon && minCon.clamped.getValue()) {	// If we have a maximum and it's clamping the min constraint
				message += ', but limited by ';			// Say we are limited
				if (maxCon.tankRole != null)			// If it's the source tank
					message += 'source tanks. ';		// Say so
				else if (maxCon.pvNode.roles.has(Role.ROLE_TOTAL_FLOW))	// If it is flow based
					message += 'max flow. ';
				else
					message += 'max pressure. ';		// It must be pressure based
			} else										// Not being clamped
				message += '. ';						// Just let it breathe
		} else											// No minimum constraint
			message += 'No minimum constraints enabled. ';	// Say so

		if (this.odorContolled && this.odorContolled.getValue())	// If the odor control logic is running the show
			message += "Running to satisfy odor control logic. ";

		var hoas = [], startFails = [], stopFails = [], faults = [], prefs = [];	// To hold all the status bits
		this.fIssues = 0;	// Make of issues we need to talk about. Starts out empty
		for (var i = 0; i < this.targetSpeeds.length; ++i) {		// For each pump
			startFails.push(this.startFails[i] && this.startFails[i].getValue());			// Is any pump start failed
			stopFails.push(this.stopFails[i] && this.stopFails[i].getValue());			// Is any pump stop failed
			faults.push(this.faulted[i] && this.faulted[i].getValue());	// Is any pump faulted
			prefs.push(this.preferred[i].getValue());				// Is any pump being alternated
			// Here, we treat pumps that are being alternated as being in hand so they don't get swooped up in the autoStub below
			hoas.push(this.hoas[i].quality !== NodeQuality.NQ_GOOD ? undefined : prefs[i] ? 1 : this.hoas[i].getValue());	// Get the HOA, taking note if it is not communicating.
			var fRunning = this.targetSpeeds[i].getValue() > 0;		// Are we running it?
			var fWantToRun = this.idealSpeeds[i].getValue() > 0;	// Do we really want to run it?
			if (fRunning != fWantToRun)								// If the pump running doesn't match the ideal
				this.fIssues += (1 << i);							// Make a note of it
		}

		// TODO: Say if auto speeds are affecting us
		var wrapper = {message: message, autoStub: this.buildMessageStub(3, hoas)};			// Have to build this wrapper to pass the message and autoStub by reference
		this.printStub(wrapper, undefined, hoas, ' not communicating. ', false, true);		// First, check for pumps that are communicating
		this.printStub(wrapper, 1, stopFails, ' failed to stop. ', true, false, true);		// Second, check for stop fails
		this.printStub(wrapper, 1, startFails, ' failed to start. ', false, true, true);	// Third, check for start fails
		this.printStub(wrapper, 1, faults, ' faulted. ', false, true, true);				// Fourth, check for faults
		this.printStub(wrapper, 1, prefs, ' being exercised.', true, true);					// Fifth, check for alternation
		if (this.floatOverride && this.floatOverride.getValue())							// For LSGs, special case the float override logic
			wrapper.message += 'Float logic is in control of the station. ';
		else {	// Display HOA limiting us if the float isn't active
			this.printStub(wrapper, 1, hoas, ' in HAND. ', true);							// Sixth, check for pumps in hand. We gotta check the HOA stuff last
			this.printStub(wrapper, 2, hoas, ' in OFF. ', false, true);						// Seventh, check for pumps in off
		}
		message = wrapper.message;			// Pull the new message out of the wrapper

		if (this.fSecDeadband.getValue())			// If the SEC deadband is active. In the uber controller, SEC deadband trumps flow deadband
			message += "Currently in the Specific Energy deadband. ";
		else if (this.fFlowDeadband.getValue())		// If the flow deadband is active
			message += "Currently in the flow deadband. ";

		if (this.powerLimited.getValue())			// Power limit is taking hold
			message += "Limited by power constraints.";
		if (this.nPumpsLimited.getValue())			// Max pumps limit is changing the operating point
			message += "Limited by max pumps constraint. ";
		this.message.textContent = message;			// Set the message
	}

	printStub(wrapper, value, array, tailString, fRunning, fUseAuto, fSkipVerb) {	// Build up a message based on the array passed in.
		var stub = this.buildMessageStub(value, array);		// Find the names of pumps in this state
		if (stub.length > 0) {								// Found any pumps
			if (fUseAuto && wrapper.autoStub.length > 0) {	// If we want to add a clause about pumps running that are in HOA = AUTO
				wrapper.message += (fRunning ? "Not running" : "Running ") + wrapper.autoStub + ' because ' + stub;	// Tell which pumps we are running instead
				wrapper.autoStub = Role.ROLE_UNDEFINED;						// Clear out the auto stub so it isn't used again
			} else 											// Just wanna talk about these pumps OR we aren't running other pumps isntead
				wrapper.message += (fRunning ? "Running" : "Not running ") + stub + ' because ' + (stub.search(',') > 0 ? 'they' : 'it');	// Just say so
			if (!fSkipVerb)									// If they want a verb
				wrapper.message += (stub.search(',') > 0 ? ' are' : ' is');	// Add it in
			wrapper.message += tailString;					// Add the tail
		}
	}

	buildMessageStub(value, array) {	// List the names of pumps whose value in array matches the passed in value
		var stub = Role.ROLE_UNDEFINED;							// Start out with an empty string
		for (var j = 0; this.fIssues != 0 && j < this.targetSpeeds.length; ++j) {	// While there's discrepancies, check pumps
			if (this.fIssues & (1 << j) && array[j] === value) {	// If this pump has an issue and is in the target state
				if (stub.length > 0)			// If we already have a pump listed
					stub += ", ";				// Add a comma
				stub += this.pumps[j].name;		// Add in the pump name
				this.fIssues -= (1 << j);		// Mark this issue as resolved
			}
		}
		return stub;							// Return whatever string we ended up with
	}

	update(node) {
		switch(node) {
			case this.offHull:
			case this.noValidPoints:
			case this.fFlowDeadband:
			case this.fSecDeadband:
			case this.powerLimited:
			case this.nPumpsLimited:
			case this.dpoInControl:
				this.fUpdateMessage = true;	// Update our text dump
			break;

			case this.flowNode:
			case this.secNode:
			case this.energyMetric:
			break;	// Handled elsewhere

			case this.qMin:
			case this.qMax:
			case this.flowDeadband:
				var qMin = this.qMin.getValue();	// Get the control min and max flow
				var qMax = this.qMax.getValue();
				var qDb  = this.flowDeadband.getValue();
				/*
				if (qMax > this.flowNode.engMax)	// Clamp the max just increase because it can be something like 1.8e+308
					qMax = this.flowNode.engMax;
				this.rangeLabel.innerHTML = qMin.toFixed(this.flowNode.digits) + "-" + qMax.toFixed(this.flowNode.digits) + ' ' + node.getUnitsText();
				*/
				if (qDb < (qMax - qMin)) {
					this.flowBandXs[0] = this.flowBandXs[1] = qMin;
					this.flowBandXs[2] = this.flowBandXs[3] = qMin + qDb;
					qMin += qDb;
				} else
					this.flowBandXs[0] = this.flowBandXs[1] = this.flowBandXs[2] = this.flowBandXs[3] = 0;

				this.flowLimits[0] = this.flowLimits[1] = qMin;	// Store this min flow as both start points so we get a vertical line
				this.flowLimits[2] = this.flowLimits[3] = qMax;	// Store this max flow as both end points so we get a vertical line
				this.fRedraw = this.graph != undefined;
			break;

			case this.lastCurveCheck:	// If the last time the curves updated changed
				this.fModelReq = true;
				this.ldc.getRegimeCurves(this.graphID, node.tree.device.id);	// Time to query those new curves
			break;

			case this.secDeadband:
				//this.updateSecDeadband();
			break;
			/*
			case this.tankOnline:
				this.modalBox.name.textContent = this.tankOnline.parent.getDisplayName() + (this.tankOnline.getValue() ? '' : ' -- Valved Off');
			break;
			*/

			default:
				if (node.roles && node.roles.has(Role.ROLE_PUMP_OFFLINE)) {
					let pumpNode = node.parent;
					if (pumpNode && this.offlineMap.has(pumpNode))
						this.offlineMap.get(pumpNode).forEach(div => div.classList.toggle('specific-energy-view__offline', node.getValue() == 1))
				}
				if (node.row) {			// If this guys has a row displayed
					this.fRedraw = true;		// Changed a line's enabled status
				} else if (this.targetSpeeds.indexOf(node) != -1)  {
					//if (this.autoRegimes && this.actualRegimes)
						//this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, this.getConfigFromSpeeds(this.targetSpeeds), false, this.targetSpeeds, this.convertHz, null);
					this.fUpdateMessage = true;
				} else if (this.idealSpeeds.indexOf(node) != -1) {
					//if (this.autoRegimes && this.actualRegimes)
						//this.queryIdealPoint();
					this.fUpdateMessage = true;
				} else if (this.hoas && this.hoas.indexOf(node) != -1 || this.startFails && this.startFails.indexOf(node) != -1 || this.stopFails && this.stopFails.indexOf(node) != -1 || this.faulted && this.faulted.indexOf(node) != -1) {
					this.fUpdateMessage = true;
				} else if (!this.fRedraw && this.graph) {	// If we are drawing the graph, we have to move all the indicators anyway
					for (var i = 0; i < this.constraintLines.length; ++i) {	// Check each constraint line
						var line = this.constraintLines[i];					// Convenience reference
						if (node === line.spNode || node === line.fbNode || node === line.active || node === line.clamped) {	// If the enable or the setpoint changed
							this.fRedraw = true;		// Must redraw all lines in case we get into overlap issues
							break;
						}
					}
				}
			break;
		}
	}

	onRegimeCurvesResponse(fp) {
		this.fModelReq		= false;
		var oldVis = this.visible.length > 3 ? this.visible.slice(-4) : [true, true, true, true]; // If we have old visibilities, get the last five elements of the array
		var fShowAuto 			= this.visible.length > 3 ? this.visible[3] : true;
		var fShowAutoInvalid	= this.visible.length > 3 ? this.visible[6] : true;
		var fShowActual 		= this.visible.length > 3 ? this.visible[3 + 4 * this.autoRegimes.length] : true;
		var fShowActualInvalid	= this.visible.length > 3 ? this.visible[6 + 4 * this.autoRegimes.length] : true;
		this.labels.length	= this.colors.length = this.visible.length = 3;
		this.limitedFlows.length = this.limitedSECs.length = 0;
		this.data			= [	this.labels, this.flowLimits, null, [0, 0, 0, 0], this.SECMaxes,	// Background of min and max flow
		         			  	this.flowBandXs, null, [0, 0, 0, 0], this.SECMaxes,
								this.secBandXs, null, this.secBandYs, this.secBandMaxes];

		// We use this trick because it is impossible to pass a javascript number by reference
		this.limits = {minFlow: Number.MAX_VALUE, maxFlow: 0, minSEC: Number.MAX_VALUE, maxSEC: 0};	// To hold overall statistics on data ranges

		this.actualRegimes	= [];	// This will hold our big build of of data for each regime we have
		this.autoRegimes	= [];
		var actHullFlows	= [], actHullSECs	= [];
		var autoHullFlows 	= [], autoHullSECs	= [];

		this.extractModelSystem(fp, this.autoRegimes, autoHullFlows, autoHullSECs, this.fAdvisoryMode ? 'green' : 'lightgreen', false, fShowAuto, fShowAutoInvalid);
		this.extractModelSystem(fp, this.actualRegimes, actHullFlows, actHullSECs, 'green', true, fShowActual, fShowActualInvalid);

		this.labels.push('Limited');
		this.colors.push('#C12727');
		this.visible.push(true);
		this.data.push(this.limitedFlows, null, this.limitedSECs, null);

		var actLineFlows = [], actLineSECs = [], autoLineFlows = [], autoLineSECs = [];
		if (this.actualRegimes.length > 0) {	// If the model was solving, it is possible we got nothing back
			// Interpolate lines for all of the convex hulls (which aren't straight)
			if (this.limits.minFlow == Number.MAX_VALUE && this.limits.minSEC == Number.MAX_VALUE) {
				this.limits.minFlow = this.limits.minSEC = 0;
				this.limits.maxFlow = this.limits.maxSEC = 5;
			}

			if (this.limits.maxFlow == this.limits.minFlow) {
				if (!isNaN(this.flow)) {
					this.limits.minFlow = Math.min(this.flow * 0.95, this.limits.minFlow * 0.95);
					this.limits.maxFlow = Math.max(this.flow * 1.05, this.limits.maxFlow * 1.05);
				}
				else  {
					this.limits.minFlow *= 0.95
					this.limits.maxFlow *= 1.05
				}
			}

			if (this.limits.maxSEC == this.limits.minSEC) {
				if (!isNaN(this.sec)) {
					this.limits.minSEC = Math.min(this.sec * 0.95, this.limits.minSEC * 0.95);
					this.limits.maxSEC = Math.max(this.sec * 1.05, this.limits.maxSEC * 1.05);
				}
				else  {
					this.limits.minSEC *= 0.95
					this.limits.maxSEC *= 1.05
				}
			}

			var flowPerPixel		= 5*(this.limits.maxFlow-this.limits.minFlow)/this.graphDiv.clientWidth;	// Calculate this so we can put a point each five pixels
			interpolateSECs(flowPerPixel, actHullFlows, actHullSECs, actLineFlows, actLineSECs);
			interpolateSECs(flowPerPixel, autoHullFlows, autoHullSECs, autoLineFlows, autoLineSECs);

			var xAxisBuffer			= (this.limits.maxFlow - this.limits.minFlow) * .05;						// Calculate 5% of the plotted flow range
			this.options.dateWindow	= [this.limits.minFlow - xAxisBuffer, this.limits.maxFlow + xAxisBuffer];	// Give a 5% buffer on each side

			var yAxisBuffer 		= (this.limits.maxSEC - this.limits.minSEC) * .1;							// Calculate 10% of the plotted SEC range
			this.options.valueRange	= [this.limits.minSEC - yAxisBuffer, this.limits.maxSEC + yAxisBuffer];		// Give a 10% buffer on each side

			this.SECMaxes[1]		= this.SECMaxes[2] = this.options.valueRange[1];	// Give the fill curve the maximum value so it fills the whole area

			this.labels.push('AutoHull', 'AutoLine', 'ActHull', 'ActLine');	// Add this way so the actual hull on top of the auto hull
			var dpoHullColor = this.fAdvisoryMode ? 'black' : 'gray';
			this.colors.push(dpoHullColor, dpoHullColor, 'black', 'black');
			this.visible.push(false, false, false, false);	// If the hid the line, don't reshow it all of the sudden
			this.data.push(	autoHullFlows,	null, autoHullSECs,		null,
				autoLineFlows,	null, autoLineSECs,		null,
				actHullFlows,	null, actHullSECs,		null,
				actLineFlows,	null, actLineSECs,		null);
			//this.updateSecDeadband();

			this.fRedraw = true;		// Need to redraw
			this.onJobCompleted();		// Redraw the graph
			if (this.currentSwitch.state)
				this.queryTargetPoint();
			this.graph?.resize();
		}
	}

	buildRegimes() {
		let regimes = [...this.autoRegimes];
		if (!this.fAdvisoryMode && (this.dpoInControl.getValue() == 1))
			regimes.unshift(...this.actualRegimes);
		let rows = [];
		this.offlineMap.forEach((value, key)=> {
			value.length = 1;
		});
		for (let i=0; i< regimes.length; ++i) {
			let regime = regimes[i];
			let row = createElement('div', 'specific-energy-view__grid__row');
			// Uncomment these lines to debug regimes
			//let text = createElement('div', '', row, regime.config);
			//text.style.position = 'absolute';
			//text.style.zIndex = '8';
			row._minFlow = regime[0].flow;
			row._maxFlow = regime[regime.length - 1].flow
			row._minSEC  = Math.min(...regime.map(d=>d.sec));
			let regimeStats = {
				minFlow: regime[0].flow,
				maxFlow: regime[regime.length - 1].flow,
				speeds: [],
				row: row,
				minSEC: Math.min(...regime.map(d=>d.sec)),
				currentSEC: undefined,
				//TODO: Make a way for this information to be provided by the user
				invalidPumpCombo: (this.device.key == 'US.GEORGIA.COBBMARIETTA.WYCKOFFHSP') && ((regime.config & 0b00010000) != 0 && (regime.config & 0b00001000) != 0)
			}
			this.regimeMap.set(regime.config, regimeStats);

			for (let j=0;j<this.pumps.length;++j) {
				let pumpSpeed = createElement('div', `specific-energy-view__grid__row__speed ${this.offLineNodes[j].getValue == 1 ? 'specific-energy-view__offline' : ''}`, row);
				regimeStats.speeds.push(pumpSpeed);
				this.offlineMap.get(this.pumps[j]).push(pumpSpeed);
			};

			regimeStats.sec = createElement('div', 'specific-energy-view__grid__row__sec', row);
			regimeStats.range = createElement('div', 'specific-energy-view__grid__row__range', row);
			rows.push(row);
		}

		rows.sort((a, b) => (a._minFlow < b._minFlow) ? 1 : -1);

		for (let i=0; i<rows.length;++i) {
			this.grid.appendChild(rows[i]);
		}

		let targetConfig = this.getConfigFromSpeeds(this.targetSpeeds);
		this.regimeMap.get(targetConfig)?.range.scrollIntoView();

		this.fBuilt = true;
	}

	drawRanges() {
		let fPOR = this.fPORs.some(node => node.getValue() == true);
		if (fPOR) {
			this.allPORCheck.classList.remove('specific-energy-view__constrained');
			this.allPORCheck.icon.src = XIcon;
		}
		else {
			this.allPORCheck.classList.add('specific-energy-view__constrained');
			this.allPORCheck.icon.src = CheckIcon;
		}

		this.recommendations.removeChildren()
		for (let i=0;i<this.targetSpeeds.length;++i) {
			let target 	= this.targetSpeeds[i].getValue();
			let ideal 	= this.idealSpeeds[i].getValue();
			if (Math.abs(target - ideal) > this.advisoryError.getValue()) {
				let message = '';
				if (target == 0) {
					message += `Turn on ${this.pumps[i].getDisplayName()} and ramp up to ${this.idealSpeeds[i].getFormattedText(true)}`
				}
				else if (ideal == 0) {
					message += `Turn off ${this.pumps[i].getDisplayName()}`
				}
				else if (target < ideal) {
					message += `Ramp ${this.pumps[i].getDisplayName()} up to ${this.idealSpeeds[i].getFormattedText(true)}`
				}
				else {
					message += `Ramp ${this.pumps[i].getDisplayName()} down to ${this.idealSpeeds[i].getFormattedText(true)}`
				}
				let rec = createElement('div', 'specific-energy-view__recommendations__list', this.recommendations)
				createElement('img', 'specific-energy-view__recommendations__list__icon', rec, undefined, {'src':CircleIcon})
				createElement('div', 'specific-energy-view__recommendations__list__text', rec, message)
			}

		}

		let regimes = [...this.actualRegimes, ...this.autoRegimes];
		let targetConfig = this.getConfigFromSpeeds(this.targetSpeeds);

		if (this.currentSwitch.state) {
			this.selectedFlow = this.flowNode.getValue();
			if (this.currentSwitch.state && typeof targetConfig != 'undefined') {
				let currentRegime = regimes.find(regime => regime.config == targetConfig);
				if (!currentRegime) {
					this.buildRegimes();
					return;
				}
				for (let i=0; i<currentRegime.length;++i) {
					let point = currentRegime[i];
					if (point.speeds && point.speeds.every((speed, index) => Math.abs(this.targetSpeeds[index].getValue() - speed) < 0.01)) {
						this.selectedFlow = point.flow;
					}
				}
			}
			this.queryTargetPoint();
		}

		for (let i=0; i< regimes.length; ++i) {
			let regime = regimes[i];
			let startPORCoord;
			let startAORCoord;

			let regimeStats = this.regimeMap.get(regime.config);
			if (!regimeStats)
				continue;
			regimeStats.currentSEC = undefined;
			regimeStats.range.removeChildren();
			let xRange = this.graph.dygraph.xAxisRange();
			let range = regimeStats.range;
			let fPOR = false;
			let fAOR = false;
			for (let j=0;j<regime.length;++j) {
				if (regime[j].flag == 0 && !fPOR) { // first point in POR
					fPOR = true;
					range.parentElement._hasPOR = true;
					startPORCoord = this.graph.dygraph.toDomXCoord(regime[j].flow)
				}
				if (regime[j].flag == 2 && !fAOR) {
					fAOR = true;
					range.parentElement._hasPOR = true;
					startAORCoord = this.graph.dygraph.toDomXCoord(regime[j].flow)
				}
				if ((regime[j].flag != 2 || j == regime.length - 1) && fAOR) { // first non-AOR point after being in AOR
					let porBar = createElement('div', 'specific-energy-view__grid__row__range__flag specific-energy-view__grid__row__range__flag__aor', range);
					let coord 						= this.graph.dygraph.toDomXCoord(regime[j].flow);
					let fixedSpeed 					= regimeStats.minFlow == regimeStats.maxFlow
					let barWidth 					= (fixedSpeed ? range.clientWidth * this.minPORRange : coord - startAORCoord);
					porBar.style.width 				= barWidth + 'px';
					porBar.style.left 				= (fixedSpeed ? startAORCoord - barWidth / 2 : startAORCoord) + 'px';
					porBar.style.zIndex 			= '2'
					fAOR = false;
				}
				if ((regime[j].flag != 0 || j == regime.length - 1) && fPOR) {	// first non-POR point after being in POR
					let porBar = createElement('div', 'specific-energy-view__grid__row__range__flag specific-energy-view__grid__row__range__flag__por', range);
					let coord 						= this.graph.dygraph.toDomXCoord(regime[j].flow);
					let fixedSpeed 					= regimeStats.minFlow == regimeStats.maxFlow
					let barWidth 					= (fixedSpeed ? range.clientWidth * this.minPORRange : coord - startPORCoord);
					porBar.style.width 				= barWidth + 'px';
					porBar.style.left 				= (fixedSpeed ? startPORCoord - barWidth / 2 : startPORCoord) + 'px';
					porBar.style.zIndex 			= '3'
					fPOR = false;
				}
			}

			let coord 		= this.graph.dygraph.toDomXCoord(regime[regime.length - 1].flow);
			let zeroFlow 	= this.graph.dygraph.toDomXCoord(xRange[0]);

			let outsideBar 	= createElement('div', 'specific-energy-view__grid__row__range__flag', range);
			outsideBar.style.width 				= coord - zeroFlow + 'px';
			outsideBar.style.left 				= zeroFlow + 'px';
			outsideBar.style.backgroundColor 	= '#ff9896';
			if (this.selectedFlow) {
				let currentFlow = this.currentSwitch.state && regime.config == targetConfig;
				let fixedSpeed = regimeStats.maxFlow == regimeStats.minFlow;
				let flowBuffer = 0
				if (fixedSpeed)
					flowBuffer = (this.limits.maxFlow - this.limits.minFlow) * this.minPORRange / 2;
				let inFlowRange = regimeStats.minFlow - flowBuffer <= this.selectedFlow && regimeStats.maxFlow + flowBuffer >= this.selectedFlow;
				if (!currentFlow && inFlowRange) {
					let index = 0;
					for (let k=0;k<regime.length;++k) {
						if (regime[k].flow > this.selectedFlow)
							break;
						index = k;
					}
					this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, false, regime.config, i>=this.actualRegimes.length, null, null, index);	// Ask for it
				}
				else if (!currentFlow) {
					for (let speedDiv of regimeStats.speeds) {
						speedDiv.textContent = '';
						speedDiv.style.backgroundColor = '';
					}
					regimeStats.sec.textContent = '';
				}
			}
		}

		var x = this.graph.dygraph.toDomXCoord(this.selectedFlow);			// Get the sp position in pixels
		var xAxisRange 	= this.graph.dygraph.xAxisRange();	// Plotted x axis range
		var y1 			= this.graph.dygraph.toDomYCoord(this.graph.dygraph.yAxisRange()[0])+3;	// Bottom of the graph in pixels
		var minX = this.graph.dygraph.toDomXCoord(xAxisRange[0]);	// Left edge of the graph in pixels
		var maxX = this.graph.dygraph.toDomXCoord(xAxisRange[1]);	// Right edge of the graph in pixels
		x = Math.max(minX, Math.min(maxX, x));	// Calculate clamped position
		if (!isNaN(x))
			this.selectedFlowLine.translate(x, 0);										// Move to clamped position
		this.selectedFlowLine.child.updateLine(0, y1, 0, 4);							// Rescale line so we stretch the full graph height
		//this.selectedFlowLabel.textContent 	= `${this.selectedFlow.toFixed(this.flowNode.digits)} ${this.flowNode.getUnitsText()}`;
		if (this.selectedFlowSpinner !== document.activeElement && this.selectedFlow != NaN)
			this.selectedFlowSpinner.value = this.selectedFlow.toFixed(this.flowNode.digits);
		let viewRect = this.wrapper.getBoundingClientRect();
		this.selectedFlowLabel.style.bottom = viewRect.height + viewRect.top - this.selectedFlowLine.getBoundingClientRect().top + 'px';
		this.selectedFlowLabel.style.left 	= this.selectedFlowLine.getBoundingClientRect().left - viewRect.left - this.selectedFlowLabel.clientWidth / 2 + 'px';
	}

	extractModelSystem(fp, regimes, secFlows, secSECs, color, fActual, fVisible, fShowInvalid) {
		var secConversion = convert(16666.67, TagUnit.TU_GPM, this.flowNode.units)/this.convertKW*this.convertSEC;	// Term to calculate SEC in kWh/MG
		var lineLetter = fActual ? SimplifiedSpecificEnergyView.actualLineLetter	: SimplifiedSpecificEnergyView.autoLineLetter;
		var goodletter = fActual ? SimplifiedSpecificEnergyView.actualLetter		: SimplifiedSpecificEnergyView.autoLetter;
		var okayletter = fActual ? SimplifiedSpecificEnergyView.actualOkayLetter	: SimplifiedSpecificEnergyView.autoOkayLetter;
		var badletter  = fActual ? SimplifiedSpecificEnergyView.actualBadLetter	: SimplifiedSpecificEnergyView.autoBadLetter;

		var regimeCount = fp.pop_u16();				// Count of regimes attached
		for (var i = 0; i < regimeCount; ++i) {		// For each regime they attached
			var regFlows = [], regSECs = [], goodSECs = [], okaySECs = [], badSECs = [];		// These arrays will hold the graph data for this regimes

			var regime = [];						// This will hold all the points for one regime
			regimes.push(regime);					// Add it to the big glut of regimes

			var config = fp.pop_u16();				// Pumps that are on in this regime set
			regime.config = config;					// Store the config on the regime for convenience

			var nPumps = config - ((config >> 1) & 0x55555555);	// Very fast bit count (for up to 32 bits)
			nPumps = (nPumps & 0x33333333) + ((nPumps >> 2) & 0x33333333)
			nPumps = ((nPumps + (nPumps >> 4) & 0xF0F0F0F) * 0x1010101) >> 24;

			this.labels.push(lineLetter + i, goodletter + i, okayletter + i, badletter + i);		// Add a label for this guy
			var c = fp.pop_u8() ? color : 'purple';
			if (this.nPumps && nPumps > this.nPumps.getValue())
				c = '#128a68';
			this.colors.push(c, c, '#ecc900', '#ff2828');	// Allowed or not allowed in the convex hull
			this.visible.push(false, false, false, false);		// Add a visibility indicator for this regime
			this.data.push(regFlows, null, regSECs, null, regFlows, null, goodSECs, null, regFlows, null, okaySECs, null, regFlows, null, badSECs, null);
			this.options[lineLetter + i] = this.lineOptions;

			var pointCount = fp.pop_u16();			// Points for this regime
			for (var j = 0; j < pointCount; ++j) {	// For each regime point
				var point = {flow: this.flowNode.convertFromCacheToDisplay(fp.pop_f32())}; // To sum the point data
				var power = fp.pop_f32() * this.convertKW;	// Point total power
				point.flag = fp.pop_u8();
				point.sec = point.flow > 0 ? secConversion * power / point.flow : 0;
				regime.push(point);				// Add the point to this regime
				checkLimits(this.limits, point.flow, point.sec);

				regFlows.push(point.flow);			// Add the totals to the arrays
				regSECs.push(point.sec);
				if (point.flag == 2) {			// If it was just in AOR
					goodSECs.push(null);
					okaySECs.push(point.sec);	// Add yellow to this point
					badSECs.push(null);
				} else if (point.flag > 0) {	// If any of the pumps had some problem
					goodSECs.push(null);
					okaySECs.push(null);
					badSECs.push(point.sec);	// Add red to this point
				} else {
					goodSECs.push(point.sec);
					okaySECs.push(null);
					badSECs.push(null);
				}
			}
		}

		this.limiting.findPowerLimits(regimes, this.limitedFlows, this.limitedSECs);

		var points = fp.pop_u16();			// Count of points in the actual convex hull
		for (var i = 0; i < points; ++i) {	// For each point in the actual convex hull
			var flow	= this.flowNode.convertFromCacheToDisplay(fp.pop_f32());		// Get the flow
			var power	= fp.pop_f32();
			secFlows.push(flow);		// Store the values
			secSECs.push(flow > 0 ? secConversion * power/ flow : 0);	// Turn the power into an SEC in kWh
		}
	}

	onPointResponse(fp) {
		if (!this.autoRegimes && !this.actualRegimes) {	// If don't have data
			fp.skip(fp.size);							// Don't respond to data
			return;
		}

		var fAuto	= fp.pop_u8();	// If the point they want is in the auto system
		var regime	= fp.pop_u16();	// Regime for this point
		var index	= fp.pop_u16();	// Index of the point they want

		var regimes = fAuto	? this.autoRegimes : this.actualRegimes;	// Get the right set of curves based on flag
		for (var i = 0; i < regimes.length; ++i) {	// Check each regime
			if (regimes[i].config != regime)	// If this is the right regime (they are in order, but potentially sparse)
				continue;
			if (index >= regimes[i].length)
				break;

			var point = regimes[i][index];		// Get a reference to the point
			point.flows = [], point.powers = [], point.speeds = [], point.flags = [];	// Build up data arrays for it
			for (var j = 0; j < this.targetSpeeds.length; ++j) {	// For each pump
				if (regime & (1 << j)) {			// If the pump was on
					point.flags.push(fp.pop_u16());	// Get flags
					point.flows.push(this.flowNode.convertFromCacheToDisplay(fp.pop_f32()));	// Get flow and convert to correct units
					point.powers.push(fp.pop_f32()*this.convertKW);					// Get power and convert to correct units
					point.speeds.push(fp.pop_f32()*this.convertHz);					// Get speed and convert to correct units
				} else {							// The pump is off
					point.flags.push(0);			// And its data is uninteresting
					point.flows.push(0);
					point.powers.push(0);
					point.speeds.push(0);
				}
			}
			if (fp.pop_u8())	// If there's a suction pressure
				point.suction = fp.pop_f32();	// Grab it
			if (fp.pop_u8())	// If there's a discharge pressure
				point.discharge = fp.pop_f32();	// Grab it

			let regimeStats = this.regimeMap.get(regime);
			if (!regimeStats)
				continue;
			for (let j=0;j<point.speeds.length;++j) {
				regimeStats.speeds[j].textContent = point.speeds[j] == 0 ? '' : point.speeds[j].toFixed(1);
				regimeStats.speeds[j].style.backgroundColor = point.speeds[j] == 0 ? '' : this.isInAOR(point.flags[j]) ? '#d5b60a' : point.flags[j] > 0 ? '#ff9896' : '#219653'
			}
			regimeStats.currentSEC 		= point.sec;
			regimeStats.currentFlags 	= point.flags.some(flag => flag != 0)

			if (!this.isAboutToRender) {
				this.isAboutToRender = true;
				setTimeout(()=> {this.renderResults();}, 200);
			}
			return;	// Found our point
		}
		fp.skip(fp.size());	// Skip the data

	}

	isInAOR(flags) {
		return ((flags & LiveData.MSS_BELOW_POR_RANGE) != 0 && (flags & LiveData.MSS_BELOW_AOR_RANGE) == 0) || ((flags & LiveData.MSS_ABOVE_POR_RANGE) != 0 && (flags & LiveData.MSS_ABOVE_AOR_RANGE) == 0)
	}

	renderResults() {
		this.isAboutToRender = false;
		let bestConfig 	= 0;
		let bestSEC 	= Infinity;
		for (let [config, regimeStats] of this.regimeMap) {
			if (!this.regimeFilter.state && regimeStats.invalidPumpCombo) {
				regimeStats.range.parentElement.classList.add('hide');
				continue;
			}
			if (regimeStats.currentSEC) {
				if (regimeStats.currentSEC < bestSEC) {
					bestConfig = config;
					bestSEC = regimeStats.currentSEC;
				}
			}
			if (config !== 0)
				regimeStats.range.parentElement.classList.remove('specific-energy-view__current', 'specific-energy-view__best', 'hide')
			else
				regimeStats.range.parentElement.classList.add('hide');
		}
		let currentConfig = this.getConfigFromSpeeds(this.targetSpeeds);	// Get our config
		let currentRegime = this.regimeMap.get(currentConfig);
		if (currentRegime)
			currentRegime.range.parentElement.classList.add('specific-energy-view__current');

		let bestRegime = this.regimeMap.get(bestConfig);
		bestRegime.range.parentElement.classList.add('specific-energy-view__best');

		for (let [config, regimeStats] of this.regimeMap) {
			if (regimeStats.currentSEC) {
				let deltaSEC = (regimeStats.currentSEC - bestRegime.currentSEC) * this.energyCost.getValue() * convert(this.selectedFlow, this.flowNode.units, TagUnit.TU_MGD) * 365;
				regimeStats.sec.textContent = `$ ${deltaSEC.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
			}
			if (config != bestConfig && config != currentConfig && !regimeStats.range.parentElement._hasPOR && !this.regimeFilter.state)
				regimeStats.range.parentElement.classList.add('hide');
			if (this.device.key == 'US.GA.GWINNETTCOUNTY.BEAVERRUIN') {
				let runningPumps = [];
				for (let j=0;j<regimeStats.speeds.length / 2;++j) {
					if (regimeStats.speeds[j].textContent != '')
						runningPumps.push(j)
				}
				for (let j=0; j<runningPumps.length;++j) {
					if (regimeStats.speeds[runningPumps[j] + 4].textContent == '')
						regimeStats.range.parentElement.classList.add('hide');
				}
				runningPumps = [];
				for (let j=regimeStats.speeds.length - 1;j>regimeStats.speeds.length / 2 - 1;--j) {
					if (regimeStats.speeds[j].textContent != '')
						runningPumps.push(j)
				}
				for (let j=0; j<runningPumps.length;++j) {
					if (regimeStats.speeds[runningPumps[j] - 4].textContent == '')
						regimeStats.range.parentElement.classList.add('hide');
				}
			}
		}
	}

	goToCurrentTarget() {
		this.goToTarget(this.selectCurrent, this.actualRegimes, this.targetSpeeds);
	}

	goToIdealTarget() {
		if (!this.goToTarget(this.selectIdeal, this.autoRegimes, this.idealSpeeds))
			this.goToTarget(this.selectIdeal, this.actualRegimes, this.idealSpeeds);
	}

	goToTarget(checkbox, regimes, speeds) {
		if (!this.graph)	// If we haven't drawn a graph
			return;			// Nothing to check

		var config = this.getConfigFromSpeeds(speeds);
		var closestRegime = -1;
		var closestPoint = -1;
		var totalError = 100E6;
		for (var i = 0; i < regimes.length; ++i) {	// Check all the actual regimes we have
			var regime = regimes[i];
			if (regime.config == config) {			// Found the correct regime
				for (var j = 0; j < regime.length; ++j) {
					if (regime[j].speeds) {	// If this point's speeds match the reference speeds
						var error = this.pointSpeedsClose(regime.config, regime[j], speeds);
						if (error < totalError) {
							closestRegime = i;
							closestPoint = j;
							totalError = error;
							if (error < 0.005)
								break;
						}
					}
				}
				break;	// Found the correct regime, no need to look farther
			}
		}
		if (closestRegime >= 0) {
			this.selectedAuto       = regimes === this.autoRegimes;
			this.selectedRegime     = closestRegime;    // Update our selected point variables
			this.selectedPoint      = closestPoint;
			checkbox.checked = true;
			return true;                            // Found our point and updated. Now we just leave
		}
		checkbox.checked = false;	// If we made it this far, we didn't find our current point. Uncheck the box
		return false;
	}

	pointSpeedsClose(config, point, speeds) {
		if (!point.speeds)
		    return;
		var totalError = 0;
		for (var i = 0; i < speeds.length; ++i) {
		    if (speeds[i])
		        totalError += Math.abs(speeds[i].getValue() - point.speeds[i]); // This pump's speed doesn't match. Don't check the rest of the speeds.
		}
		return totalError;                      // All the speeds match
    }

	pointSpeedsMatchReference(config, point, speeds) {
		if (!point.speeds)
			return;
		for (var i = 0; i < speeds.length; ++i) {
			if (speeds[i] && Math.abs(speeds[i].getValue() - point.speeds[i]) > 0.05)
				return false;	// This pump's speed doesn't match. Don't check the rest of the speeds.
		}
		return true;			// All the speeds match
	}

	onTimer() {	// This is called one a one-second timer to update the solution age
		if(this.lastCurveCheck.hasReadPermission())	// SKUN-57, added a check to prevent asserts when already logged out but timer still running
			this.ageLabel.textContent = ((Date.now() - this.lastCurveCheck.getValue()/1000)/60000).toFixed(1) + " min";
	}

	static activeClassname      = 'hide';
	static clampedClassname     = 'secMapSVGlineClamped';
	static disabledClassname    = 'hide';

	createGraphLines(nodes) {
		for (var i = 0; i < nodes.length; ++i)
			this.createGraphLine(nodes[i], nodes[i].roles.has(Role.ROLE_TLC_CONSTRAINT_MAX) ? 1 : nodes[i].roles.has(Role.ROLE_TLC_CONSTRAINT_MIN) ? 0 : 2, null);
	}

	createGraphLine(constraint, type, tankRole) {
		if (!constraint)
			return;

		var line = createSVGElement('g', null, this.lineWrapper);					// Start out collapsed in case there's not an SEC map yet
		line.isPowerMeter = constraint.roles.has(Role.ROLE_TLC_POWER_METER);
		if (line.isPowerMeter)
			line.classList.add('hide');
		line.child 		= createSVGElement(line.isPowerMeter ? 'path' : 'line', 'secMapSVGPowerLine', line);
		line.spNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);		// Set point node
		line.fbNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK);	// Feedback node (if it exists)
		line.enable		= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);	// Enabled node
		line.dbNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_DB);		// deadband node
		var pvNode		= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_PV);		// Process variable
		line.pvNode 	= pvNode && pvNode.sourceID != undefined ? pvNode.tree.getNode(pvNode.sourceID) : pvNode;	// Get the source node if there is one
		line.start 		= constraint.findChild('Start');
		line.stop 		= constraint.findChild('Stop');
		line.active 	= constraint.findChild('Active');
		line.clamped 	= constraint.findChild('Clamped');
		line.tankRole 	= tankRole;
		line.name = Localization.toLocal(constraint.getDisplayName());
		line.label = createSVGElement('text', 'secMapSVGlabel', line, {'text-anchor': 'end', 'dominant-baseline' : 'hanging', 'font-size':'0.75em'});
		line.label.onclick = line.label.ontouch = () => location.hash = getHash(...getRouteAndProperties(location.hash, {'tab':'Controls'}));

		line.type = type;
		line.fMax = type == 1;
		line.label.updateLabel(0, 0, line.name);
		line.conversion = line.isPowerMeter ? 1 : convert(1, (line.fbNode || line.spNode).units, this.qMin.units);
		line.checkElement = createElement('div', 'specific-energy-view__operator__constraint', this.operatorChecklist);
		line.checkElement.icon = createElement('img', 'specific-energy-view__operator__constraint__icon ', line.checkElement)
		line.checkElement.text = createElement('div', '', line.checkElement);


		this.nodeManager.addNode(line.spNode);
		this.nodeManager.addNode(line.fbNode);
//		this.nodeManager.addNode(line.enabled); We're already subscribed to this node
		this.nodeManager.addNode(line.active);
		this.nodeManager.addNode(line.clamped);
		this.constraintLines.push(line);
	}

	updateGraphLines() {
		var xAxisRange 	= this.graph.dygraph.xAxisRange();	// Plotted x axis range
		var y1 			= this.graph.dygraph.toDomYCoord(this.graph.dygraph.yAxisRange()[0])+3;	// Bottom of the graph in pixels
		var minX = this.graph.dygraph.toDomXCoord(xAxisRange[0]);	// Left edge of the graph in pixels
		var maxX = this.graph.dygraph.toDomXCoord(xAxisRange[1]);	// Right edge of the graph in pixels
		for (var i = 0; i < this.constraintLines.length; ++i) {	// For each line
			var line = this.constraintLines[i];
			var sp = (line.fbNode || line.spNode).getValue()*line.conversion;	// Get the setpoint node

			var text = line.name + ': ' + (line.fbNode? line.spNode.getFormattedText(true)  + ', ' + line.fbNode.getFormattedText(true) : line.spNode.getFormattedText(true)); // Make a copy of the name so we can modify it
			line.label.updateLabel(0,0, text);
			var width = line.label.textLength.baseVal.value;	// Get the width of the label

			if (line.isPowerMeter && this.options.dateWindow) {	// We put power nodes first becasue they are the hardest to position the names of (moing down would need to shift it laterally)
				var limit  = sp - this.totalBase;	// Get the current limit -- including base load which is taken off the top
				var deltaQ = (this.options.dateWindow[1] - this.options.dateWindow[0]) / 50;	// Our unit for flow change for this calculation
				var toMGD = convert(1, this.flowNode.units, TagUnit.TU_MGD);			// Calculate change from flow units to MGD once
				var deltaX = this.graph.dygraph.toDomXCoord(this.options.dateWindow[0] + deltaQ) - this.graph.dygraph.toDomXCoord(this.options.dateWindow[0]);	// Delta x between point
				sp = 24 * limit / this.options.valueRange[1] / toMGD;	// Calculate where our label goes (and where our line starts) at the top of the y axis
				var path = 'M 0 0 ';	// Start our path
				var fExit = false;		// True if we need to bail out
				for (var q = sp + deltaQ, offset = 0; q - this.options.dateWindow[1] < deltaQ; q += deltaQ) {	// q is in flowNode.units
					var sec = 24 * limit / q / toMGD;	// This is kWh/MG
					if (sec <= this.options.valueRange[0]) {
						sec = this.options.valueRange[0];
						var newQ = 24 * limit / sec / toMGD;
						deltaX *= (deltaQ - (q - newQ)) / deltaQ;
						fExit = true;
					}
					offset += deltaX;
					path += 'L ' + offset + " " + this.graph.dygraph.toDomYCoord(sec * this.convertSEC);	// Convert SEC to target units and make a line
					if (fExit)
						break;
				}
				line.child.setAttribute('d', path);		// Set the path on the element
			}
			var x = this.graph.dygraph.toDomXCoord(sp);			// Get the sp position in pixels
			line.child.classList.toggle('collapse',  sp < xAxisRange[0] || xAxisRange[1] < sp);	// Hide the line if it's not on the graph
			var xShift = line.fMax ? 0 : -20;	// If we're flipped, translate the line so the arrow is pushing on the line
			var yShift = line.fMax ? -(this.graphDiv.clientHeight) + width + 40 : -20;
			line.label.updateLabel(yShift, xShift, text);					// Update the label
			x = Math.max(minX, Math.min(maxX, x));	// Calculate clamped position
			if (!isNaN(x))
				line.translate(x, 0);										// Move to clamped position
			if (line.active) {
				if (line.active.getValue())
					line.classList.remove('hide')	// Show red if we're active
				else line.classList.add('hide')
			}
			line.child.updateLine(0, y1, 0, 4);										// Rescale line so we stretch the full graph height
			line.left = (line.fMax ^ line.fFlipped ? x : x - width) + xShift;		// Calculate the left most edge of text for positioning
			line.right = (line.fMax ^ line.fFlipped ? x + width : x) + xShift;		// Calculate the right most edge of text for positioning

			let flow = this.flowNode.getValue();
			if (line.active) {
				if (line.active.getValue()) {
					line.checkElement.classList.remove('hide');
					let fConstrained = false;
					if (line.type == 0) {
						if (sp < flow )
							fConstrained = true;
					}
					else if (line.type == 1) {
						if (sp > flow )
							fConstrained = true;

					}
					else {
						if (flow < this.qMax.getValue() && flow > this.qMin.getValue()) {
							fConstrained = true;
						}
					}
					if (fConstrained) {
						line.checkElement.classList.add('specific-energy-view__constrained');
					}
					else
						line.checkElement.classList.remove('specific-energy-view__constrained');

					line.checkElement.icon.setAttribute('src', fConstrained ? CheckIcon : XIcon);

					let overUnder = line.type == 1 ? 'Under' : line.type == 0 ? 'Over' : 'Within';
					line.checkElement.text.textContent = `${overUnder} ${text}`;
				}
				else
					line.checkElement.classList.add('hide');
			}
		}
	}

	onJobCompleted() {
		if (!this.fRedraw || !this.autoRegimes || !this.fInitialized)	// If we don't need to redraw the graph
			return;								// Just leave
		this.graphHeight 	= this.graphDiv.clientHeight;
		this.graphWidth 	= this.graphDiv.clientWidth;
		if (this.fUpdatePower) {				// If the power stuff changed
			this.limitedFlows.length = this.limitedSECs.length = 0;	// Remove any old plotted points
			this.limiting.findPowerLimits(this.autoRegimes, this.limitedFlows, this.limitedSECs);	// Find the new limited points
			this.limiting.findPowerLimits(this.actualRegimes, this.limitedFlows, this.limitedSECs);
			this.fUpdatePower = false;			// Done updating the power stuff
		}
		if (this.fUpdateMessage) {				// If we need to update our human readable message
			this.updateMessagefunction();		// Do it
			this.fUpdateMessage = false;		// And say we done did it
		}

		if (this.graph)							// If we've drawn a graph before
			this.graph.destroy();				// Delete it first

		// Create a new graph to plot our points
		this.graph = new StaticGraph(this.ldc, this.graphDiv, this.graphWidth, this.graphHeight, this.data, this.options, false);
		//this.lineWrapper.setAttribute('width', this.graphDiv.clientWidth);
		this.updateGraphLines();

		if (!this.fBuilt)
			this.buildRegimes();

		this.drawRanges();

		if (this.helper) {
			this.helper.resize();
		}
		this.fRedraw = false;		// Don't need to redraw the graph anymore
	}

	resize() {			// This is called when the page is reloaded
		this.pumpBank.resize();
		this.graphHeight 	= this.graphDiv.clientHeight;
		this.graphWidth 	= this.graphDiv.clientWidth;
		if (this.fSmall != this.graphDiv.clientWidth < 920) {
			this.fSmall = !this.fSmall;
			if (this.fLandscape != window.innerHeight < 420) {
				this.fLandscape = !this.fLandscape;
				this.pumpBank.destroy();
				this.pumpBank = new PumpBank(this.pumpSystem, this.fLandscape? this.pumpTable : this.pumpBankWrapper, this.dpoFolder);
			}
		}
		this.fRedraw = true;
		this.onJobCompleted();		// Tell the graph to quickly realign itself
	}

	destroy() {
		window.removeEventListener('keydown', this.keyPressFunction);
		if (this.nodeManager) {	// Initialize might not have been called and this wouldn't exist
			this.nodeManager.destroy();		// Unsubscribe to all the nodes we subscribed to
			for (var i = 0; i < this.globalEnables.length; ++i)		// Unsubscribe to all global enabled nodes
				this.globalEnables[i].unsubscribe(this);
			if(this.tankOnline)
				this.tankOnline.unsubscribe(this);
			if (this.spinner)						// If we have a spinner displayed
				this.spinner.destroyWidgets();		// Destroy it
			this.ldc.unregisterGraph(this.graphID);
			owner.removeJobCompletedCallback(this.jobCallback);
			clearInterval(this.timerId);
			clearInterval(this.filterID);
			this.limiting.destroy();
		}
		owner.navBar.unregisterHelper('Help with Operation Tab');
		super.destroy();
	}

	powerLimitsUpdated() {	// Called by the power limiting helper when something changes
		this.fUpdatePower	= true;		// Time to update the power onJobComplete
		this.fRedraw		= true;		// And time to redraw the graph
	}

	subscribeToEnableNodes(folder) {
		if (!folder)	// No folder?
			return;		// no problem
		for (var i = 0; i < folder.children.length; ++i) {				// For each constraint folder
			var enabledNode = folder.children[i].findChild('Enabled') || folder.children[i].findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);	// Find the enabled node
			enabledNode.subscribe(this);								// Subscribe to the node
			this.globalEnables.push(enabledNode);						// Add it to our vector of enabled nodes
		}
	}

	fillByFolder(div, period, role) {
		var folder	= period.findChildByRole(role);		// Find the folder, if it exists
		div.nodes	= folder ? folder.children : [];	// Store the nodes
		this.fillSection(div);							// Fill with life
	}

	checkRole(node, pvRole) {
		var pv = node.findChildByRole(pvRole);				// The process variable alias node
		var roles = node.tree.nodes[pv.sourceID].roles;		// Find the role of the source node, which will tell us what this constrains

		if (roles.has(Role.ROLE_TOTAL_FLOW))				// Flow constraint
			this.flowNodes.push(node);
		else if (roles.has(Role.ROLE_DISCHARGE_PRESSURE))	// Discharge pressure constraint
			this.dischargeNodes.push(node);
		else
			this.suctionNodes.push(node);				// Suction pressure constraint
	}

    onViewHidden() {
        owner.navBar.unregisterHelper('Help with Operation Tab');
    }

    onViewShown() {
        super.onViewShown();
        owner.navBar.registerHelp('Help with Operation Tab', ()=>this.buildHelper())
    }

    buildHelper() {
		this.helper =  new Helper('Operation Tab', [
            {title:'Operation Tab',			body:`View operating conditions, operator recommendations, and most cost-effective configuration based on available pumps.

			The screen includes Digital Gauges, Pump overviews, and an interactive chart of pump combinations.`, element:this.wrapper},
            {title:'Operator Support', 		body:`The outputs of key operating parameters are shown here.

			Quick-glance operating conditions and recommendations are displayed. ` , element:this.parameterTable},
			{title:'View Possible Configurations',    body:`View pump combinations for a given flow. The bar chart defaults to Track Current Flow.

			The flow demand line crosses each configuration and shows if demand can be met with all pumps in POR.

			The beige region represents operational limits within the configured constraints. `, element:this.operatorChecklist},
			{title:'Lookup Flows ',    	body:`Type in flow in the bubble or use arrow keys.

			Pumps are considered available unless flagged as “Offline”.`, element:this.selectedFlowLabel},
			{title:'Lookup Energy Costs',        	body:`The blue row shows the current configuration.

			The green row shows the most cost-effective operation for current conditions.

			The cost impact of continued operation in any configuration is shown in the “Annual Additional Cost” column.`, element:this.speeds},
			{title:'Visual POR Indication',  		body:`The bar chart displays green for the flow range in which the pump combination meets the target within each pump's preferred operating range (POR).

			The speeds to achieve target flow are displayed in the chart. `, element: this.graphDiv},
        ]).initialize();
    }

	static actualLineLetter = 'Q';
	static actualLetter     = 'R';
	static actualOkayLetter = 'S';
	static actualBadLetter  = 'T';
	static autoLineLetter   = 'C';
	static autoLetter       = 'D';
	static autoOkayLetter   = 'E';
	static autoBadLetter    = 'F';
};

// Helper class that keeps track any power limiting in place
class PowerLimiting {
    constructor(graph, dpoFolder, basePowers) {
        this.meters			= [];
        this.basePowers		= basePowers;
        var powerMeters 	= dpoFolder.findChild('MeterDemands');
        if (powerMeters) {		// If we have any power meters
            var meters		= dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER);

            for (var j = 0; j < meters.children.length; ++j) {
                this.meters.push({	pumps:			meters.children[j].findChild("Pumps").getValue(),	// This is a final value
                                    enabledNode:	powerMeters.children[j].findChild("LimitEnabled"),	// Find the enabled node for the power meter
                                    limitNode:		powerMeters.children[j].findChild("DemandLimit")});	// Find the limit node for the power meter
                this.meters[j].enabledNode.subscribe(this);	// Subscribe to the meter's nodes
                this.meters[j].limitNode.subscribe(this);
            }
        }
        this.graph = graph;	// Save a reference to the graph that created us. We do this last to block calls in the update method
    };

	update() {
		if (!this.graph)	// No graph when we subscribe to the nodes
			return;
		this.graph.powerLimitsUpdated();	// Tell the graph something changed
	}

	findPowerLimits(regimes, limitedFlows, limitedSECs) {	// Parse regimes and add flows and SECs to the two arrays
		for (var j = 0; j < this.meters.length; ++j) {
			if (this.meters[j].enabledNode.getValue())				// This meter is active. Use it for power limiting
				this.checkPower(this.meters[j], regimes, limitedFlows, limitedSECs);
		}
	}

	checkPower(meter, regimes, limitedFlows, limitedSECs) {
		var pumps = meter.pumps;				// Get the pumps this meter cares about
		var limit = meter.limitNode.getValue();	// Get the limited value of the ndoe
		for (var k = 0; k < regimes.length; ++k) {	// For each regime
			var points = regimes[k];
			for (var l = 0; l < points.length; ++l) {	// For each point in the regime
				var meterPower = this.graph.flowNode.convertFromCacheToDisplay(points[l].flow) * points[l].sec * 60 / 1000000;	// FIXME: This doesn't work for multiple meters
				for (var m = 0; m < this.basePowers.length; ++m) {	// For each power
					if ((pumps & (1 << m)) > 0)				// If the meter cares about this pump
						meterPower += this.basePowers[m];	// Add in its power and its base load power
				}
				if (meterPower > limit) {				// If the total power is over the limit
					limitedFlows.push(points[l].flow);	// Add the flow and SEC to the array
					limitedSECs.push(points[l].sec);
				}
			}
		}
	}

	destroy() {
		for (var j = 0; j < this.meters.length; ++j) {
			this.meters[j].enabledNode.unsubscribe(this);
			this.meters[j].limitNode.unsubscribe(this);
		}
	}
};

//Conviencience helper class that lets us show when the tank is online or not
export class TankOutput extends Widget {
    constructor(element, outputNode, onlineNode) {
        super();
        this.registerAsWidget(element);		// Register like a good little widget
        this.element	= element;			// Store a pointer to our element
        this.outputNode	= outputNode;		// Save the output node
        this.outputNode.subscribe(this);	// Subscribe to the ouput node
        this.onlineNode	= onlineNode;		// Save the online node
        this.onlineNode.subscribe(this);	// Subscribe to the online node
    };

    update() {
        if(this.onlineNode)	// If we have all the elements we need
            this.element.textContent = this.onlineNode.getValue() ?  this.outputNode.getFormattedText() : 'Valved Off';	// Check if we are online or not
    };

    destroy() {
        this.outputNode.unsubscribe(this);	// Unsubscribe from everything
        this.onlineNode.unsubscribe(this);
        this.unregisterAsWidget();			// Unregister like a good little widget
    };
};

// Global methods so WhatIf can use it by importing this file
export function createGraphPulse(wrapper, stroke) {
	var svgPulse		= createSVGElement('svg', 'secMapSVGpulse hide collapse', wrapper, {width:14, height:14});
	createPulseInnards(svgPulse, stroke, 7, 2, 7);
	return svgPulse;
};

export function createPulseInnards(svgPulse, stroke, circleCenter, circleSize, pulseSize) {
	var svgOuterCircle	= createSVGElement('circle', null, svgPulse, {stroke:stroke, cx: circleCenter, cy: circleCenter, r: 1, 'stroke-width': 3, fill: 'none'});
	var svgInnerCircle	= createSVGElement('circle', null, svgPulse, {cx: circleCenter, cy: circleCenter, r: circleSize, 'stroke-width': 0, fill: stroke});
	createSVGElement('animate', null, svgOuterCircle, {attributeType:'XML', attributeName: 'r', from: 0, to: pulseSize, dur:'2s', repeatCount: 'indefinite'});
	createSVGElement('animate', null, svgOuterCircle, {attributeType:'XML', attributeName: 'opacity', from: 0.75, to: 0, dur:'2s', repeatCount: 'indefinite'});
};

export function createCaption(wrapper, label, color, clickCallback, fLine, fSVG, fNoDot) {
	var caption	= createElement('div', 'secMapCaption', wrapper);			// Create a wrapper for the caption
	var text = createElement('label', 'secMapCaptionText', caption, label);	// Create the text label for the caption
	text.style.backgroundColor = new Color(color);

	if (clickCallback) {
		caption.onclick = clickCallback;	// Add an click callback to hide lines and such
		caption.addEventListener('touchstart', clickCallback);
		caption.classList.add('secMapClickableCaption');
	}

	if (fSVG)
		createPulseInnards(createSVGElement('svg', 'secMapCaptionCanvas', caption, {width:20, height:20}), color, 10, 3, 8);
	else {
		// Now, create a very small canvas are recreate a dot and such. Each canvas is 20 x 20
		var canvas = createElement('canvas', 'secMapCaptionCanvas', caption);
		canvas.width = canvas.clientWidth;	// This is necessary or the canvas pixels are scaled
		canvas.height = canvas.clientHeight;

		var ctx = canvas.getContext("2d");	// Get the drawing context
		if(fNoDot !== true) {
			ctx.fillStyle = color;			// Set the fill color
			ctx.beginPath();				// Start a path
			ctx.arc(10,10,3,0, 2*Math.PI);	// Make a full circle
			ctx.fill();						// Fill in the circle
		}
        if (fLine) {
        	ctx.strokeStyle = color;		// Set the stroke color
        	ctx.lineWidth = 2;				// Set the line width
        	ctx.beginPath();				// Start a path
        	ctx.moveTo(0, 10);				// Move to the left edge of the graph (aligned on a half pixel cause it makes lines sharper)
        	ctx.lineTo(20, 10);				// Make the horizontal line
        	ctx.stroke();					// Draw the line
        }
	}
};

export function interpolateSECs(flowPerPixel, flowArray, secArray, outputFlowArray, outputSECArray) {
	if (flowPerPixel <= 0)
		return;
	for (var i = 1; i < flowArray.length; ++i) {
		var lowFlow		= flowArray[i-1];
		var lowSEC		= secArray[i-1];
		var highFlow	= flowArray[i];
		var highSEC		= secArray[i];

		var q = lowFlow;
		while ((highFlow - q) > flowPerPixel) {	// Put a point every pixel
			outputFlowArray.push(q);			// Store this intermediate flow
			outputSECArray.push(q==0?0:(lowSEC*(highFlow-q)*lowFlow + highSEC*(q-lowFlow)*highFlow)/(q*(highFlow-lowFlow)));
			q += flowPerPixel;
		}
		outputFlowArray.push(highFlow);			// Store this intermediate flow
		outputSECArray.push(highSEC);
	}
};

export function checkLimits(limits, flow, sec) {
	if (flow == 0)	// We don't want zero flow points to be shown
		return;		// Just leave

	if (flow < limits.minFlow)	// If this is the smallest flow we have seen
		limits.minFlow = flow;	// Store this as the smallest flow we have seen
	if (flow > limits.maxFlow)	// If this is the biggest flow we have seen
		limits.maxFlow = flow;	// Store this as the biggest flow we have seen

	if (sec < limits.minSEC)	// If this is the smallest SEC we have seen
		limits.minSEC = sec;	// Store this as the smallest SEC we have seen
	if (sec > limits.maxSEC)	// If this is the biggest SEC we have seen
		limits.maxSEC = sec;	// Store this as the biggest SEC we have seen
};
