import Dropdown from "./dropdown";
import owner from "../../owner";

export default class Clock {
    timeDiv         : HTMLDivElement | undefined;
    dateDiv         : HTMLDivElement | undefined;
    timeFormat      : string;
    dateFormat      : string;
    timeZoneFormat  : string;
    time            : HTMLElement;
    timeZoneDiv     : HTMLElement | undefined;
    timeZoneText    : HTMLElement;
    date            : HTMLElement;
    dropdown        : Dropdown;
    timerID         : NodeJS.Timer;
    constructor(timeDiv?: HTMLDivElement, dateDiv?: HTMLDivElement, timeZoneDiv?: HTMLDivElement, timeFormat: string = '%HH:%mm:%ss', timeZoneFormat: string = '(%zz)', dateFormat: string = '%DDDD, %MMMM %d, %yyyy') {
        this.timeDiv                    = timeDiv;
        this.dateDiv                    = dateDiv
        this.timeZoneDiv                = timeZoneDiv;
        this.timeFormat                 = timeFormat;
        this.dateFormat                 = dateFormat;
        this.timeZoneFormat             = timeZoneFormat;
        if (this.timeZoneDiv)
            this.timeZoneDiv.onclick        = (e) => {
                e.stopPropagation();
                let options = ['Local', 'UTC'];
                if (owner.selectedDevice) options.push('Site');
                this.dropdown = new Dropdown(e, options, this.selectCallback.bind(this))
            }

        // Schedule ourselves to be called again in one second:
		this.timerID = setInterval(() => {
            let date = new Date();
            if (this.timeDiv)
                this.timeDiv.textContent = date.format(this.timeFormat);
            if (this.dateDiv)
                this.dateDiv.textContent = date.format(this.dateFormat);  
            if (this.timeZoneDiv)
                this.timeZoneDiv.textContent = date.format(this.timeZoneFormat);
        }, 1000);
    }

    selectCallback(selection: string) {
        owner.changeTimeZone(selection);
        if (this.timeZoneDiv)
            this.timeZoneDiv.textContent = (new Date()).format(this.timeZoneFormat);
    }
}
