import { Attribute } from "../../lib/attributes";
import { TagAttribute, VType } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './inputsetpoint.html';

@RegisterWidget({tag:'input-setpoint', displayName: 'Input Setpoint', template: template})
export class InputSetpoint extends Widget {
    valueElement: HTMLInputElement;
    @Attribute({displayName: 'Step'}) step: number;
    @Attribute({displayName: 'Min'}) min: number;
    @Attribute({displayName: 'Max'}) max: number;
    @Attribute({displayName: 'Show Arrows'}) showArrows: boolean = true;

    @TagAttribute({
        displayName: 'Setpoint Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['numeric', 'string']
    }) setpoint: TagDefinition;

    set value(v: any) {
        this.valueElement.value = `${v}`;
    }

    get value() {
        return this.valueElement.value;
    }

    protected connectedCallback(): void {
        this.style.setProperty('--arrowAppearance', this.showArrows ? 'auto' : 'none');
    }

    protected enliven(): void
    {
        this.valueElement = this.shadowRoot?.getElementById('input')! as HTMLInputElement;
        let tag = this.setpoint.tag;
        let isString = tag.vtype == VType.VT_STRING
        this.valueElement.type = isString ? 'text' : 'number'
        this.valueElement.disabled = !tag.isWriteable;
        if (!isString) {
            this.valueElement.step = (this.step ? this.step : tag.resolution ?? 1).toFixed(tag.digits);
            if (!isNaN(tag.engMin))
                this.valueElement.min = tag.engMin.toFixed(tag.digits);
            if (!isNaN(tag.engMax))
                this.valueElement.max = tag.engMax.toFixed(tag.digits);
        }

        this.valueElement.onchange = (e) => this.setpoint.tag.setPendingValue(isString ? this.valueElement.value : this.valueElement.valueAsNumber, this);
    }

    update(tag: Tag): void {
        if (tag.vtype == VType.VT_STRING)
            this.valueElement.value = <string>tag.getValue(this);
        else
            this.valueElement.value = (<number>tag.getValue(this)).toFixed(tag.digits);
    }
}