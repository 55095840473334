import {Widget} from "./widget";
import createSVGElement from './svgelements'
import {createUniqueId} from './elements';
import {Node, NodeQuality} from './node';
import Scale from './scale';
import assert from './debug';
import MinMax from "./minmax";
import { Shuttle } from "./shuttle";
import Handle from './handle';
import './bargraph.css';

// Converts an html element into a bar graph.
// Supports CSS styling for its bargraph-frame, bargraph-background, and bargraph-bar elements.
// Inherits rounded corners from its parent element, if the parent is an HTML DOM element (not SVG)
// You have the option of eliminating the frame (stroke:none), and/or making the background transparent (fill:none).
// You can add a scale, shuttles and handles:
// Specify scale location as a bar graph property
// Programatically add multiple shuttles and handles by calling addShuttle() and addHandle()
// Programatically add a scale by calling addScale()

// BarGraph no longer has to have a node. If you want to control it directly, simply set minimum, maximum, and
// resolution properties in the constructor, and call setValue() to change the bargraph value.

// BarGraph usage:
// element:			(required)		DOM element that will be converted to a bargraph. Will be resized as needed.
// properties:		(optional)
//	node:			(default: none)	node from which bar gets its current value
//  width:			(default=25):	width of bar in CSS pixels, not including the frame
//  height:			(default=200):	height of bar in CSS pixels, not including frame
//  fVertical		(default=true):	true: bar is vertical, false: bar is horizontal
//  scalePosition	(default=none): position of scale relative to bar ('left', 'top', 'right', or 'bottom')
//	minMaxPosition	(default=none): position of bar min and max labels ('left', 'top', 'right', or 'bottom')
//	minimum/maximum	(default: use node range)

// BarGraph inherits Widget:
export default class BarGraph extends Widget {
    constructor(element, properties) {
        super();
        // 'element' is required:
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element = element;

        this.registerAsWidget(element);

        // 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
        // First step: Establish default properties:
        this.width					= 25;		// CSS pixels for inner bar (not counting frame thickness)
        this.height					= 200;		// CSS pixels for inner bar (not counting frame thickness)
        this.fVertical				= true;		// Draw bar vertically
        this.overhang				= {left:0, top:0, right:0, bottom:0};	// default margins around bar
        this.redXMask				= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.barStyle				= 'bargraph-bar';
        this.backgroundStyle		= 'bargraph-background';
        this.frameStyle				= 'bargraph-frame';
        this.fBarGradient			= true;
        this.fBackgroundGradient	= true;

        // Second step: copy properties over to the bar graph object:
        this.copy(properties);

        // Third step: Create internal properties:
        this.shuttles		= [];
        this.handles		= [];
        this.fInitialized	= false;
    };

    initialize() {
        this._determineMinMaxRes ();		// determine minimum, maximum, resolution
        this._determineBorderRadius();		// determine bar graph border radius
        // Create SVG viewport with default size, and add it to DOM so we can query CSS styles and sizes:
        this.svg = createSVGElement('svg', null, this.element, {width:10, height:10});
        this._determineFrameStrokeWidth ();	// determine stroke width of bar graph frame

        // Create a scale object if one was specified:
        if (this.scalePosition)
            this.scale = new Scale (this.svg, this.minimum, this.maximum, this.resolution, this.scalePosition, false);

        // Create a MinMax object if one was specified:
        if (this.minMaxPosition)
            this.minMax = new MinMax (this.svg, this.minimum, this.maximum, this.resolution, this.minMaxPosition);

        // Create shuttles (replace attributes with object in array):
        for (var i = 0, shuttle; shuttle = this.shuttles[i]; ++i)
            this.shuttles[i] = new Shuttle (this.svg, shuttle.node, shuttle.position, shuttle.fAdjustable, shuttle.label, shuttle.class, null, shuttle.properties);

        // Create handles (replace attributes with object in array):
        for (var i = 0, handle; handle = this.handles[i]; ++i)
            this.handles[i] = new Handle (this.svg, handle.node, this.fVertical);

        // Add useful read-only properties to the overhang metrics object:
        this.overhang.width			= this.width;
        this.overhang.height		= this.height;
        this.overhang.strokeWidth	= this.frameStrokeWidth;
        // Make room for frame width:
        this.overhang.left			= Math.max(this.overhang.left,	this.frameStrokeWidth);
        this.overhang.top			= Math.max(this.overhang.top,	this.frameStrokeWidth);
        this.overhang.right			= Math.max(this.overhang.right,	this.frameStrokeWidth);
        this.overhang.bottom		= Math.max(this.overhang.bottom,this.frameStrokeWidth);

        // Allow each doodad to expand the overhang:
        if (this.scale)												// adjust for scale:
            this.scale.getMetrics(this.overhang);
        if (this.minMax)											// adjust for min max labels
            this.minMax.getMetrics(this.overhang);
        for (var i = 0, shuttle; shuttle = this.shuttles[i]; ++i)	// adjust for shuttles:
            shuttle.getMetrics(this.overhang);
        for (var i = 0, handle; handle = this.handles[i]; ++i)		// adjust for handles:
            handle.getMetrics(this.overhang);

        //If requested by the creator (with the forceFit flag),
        //Contract the size of the bar box itself so that the doodads don't
        //go past the box specified by width, height.
        if (this.forceFit){
            this.width -= (this.overhang.left + this.overhang.right);
            this.height -= (this.overhang.top + this.overhang.bottom);
        }

        // Define the bar rectangle, frame rectangle, and canvas rectangle:
        this.barRect = {
            width:		this.width,
            height:		this.height,
            left:		this.overhang.left,
            top:		this.overhang.top,
            right:		this.overhang.left + this.width,
            bottom:		this.overhang.top + this.height};

        this.frameRect = {
            width:		this.barRect.width	+ this.frameStrokeWidth * 2,
            height:		this.barRect.height	+ this.frameStrokeWidth * 2,
            left:		this.barRect.left	- this.frameStrokeWidth,
            top:		this.barRect.top	- this.frameStrokeWidth,
            right:		this.barRect.right	+ this.frameStrokeWidth,
            bottom:		this.barRect.bottom + this.frameStrokeWidth};

        this.canvasRect = {
            width:		this.overhang.left + this.width + this.overhang.right,
            height:		this.overhang.top + this.height + this.overhang.bottom,
            left:		0,
            top:		0,
            right:		this.overhang.left + this.width + this.overhang.right,
            bottom:		this.overhang.top + this.height + this.overhang.bottom};

        // Set the final svg size:
        this.svg.setAttribute('width', this.canvasRect.width);
        this.svg.setAttribute('height', this.canvasRect.height);

        // The bar graph is drawn with a clipping rect so that we can clip the corners of
        // the bar and background rectangles. The clipping rectangle is aligned to the
        // center of the frame stroke.
        // The user can adjust the frame width (or set it to zero), and everything will
        // still look right with rounded rects.
        // Create a clipping path:
        var clipId		= createUniqueId();
        var clipPath	= createSVGElement('clipPath', null, this.svg, {id:clipId});

        // Center the clipping rect on the frame stroke line:
        createSVGElement('rect', this.frameStyle, clipPath, {
            x:		this.frameRect.left		+ this.frameStrokeWidth / 2,
            y:		this.frameRect.top		+ this.frameStrokeWidth / 2,
            width:	this.frameRect.width	- this.frameStrokeWidth,
            height:	this.frameRect.height	- this.frameStrokeWidth,
            rx:		this.borderRadius,
            ry:		this.borderRadius,
            id:		clipId});

        // Create the background rect:
        var bgRect = createSVGElement('rect', this.backgroundStyle, this.svg, {
            x:			this.barRect.left,
            y:			this.barRect.top,
            width:		this.barRect.width,
            height:		this.barRect.height,
            'clip-path': 'url(#' + clipId + ')' 	// Applies the clipping rect to this element
        });

        // Apply an 'outy' gradient to the background:
        //if (this.fBackgroundGradient)
            //bgRect.createGradientFill(this.svg, !this.fVertical);

        // Total length of active bar, in CSS pixels:
        this.length	= this.fVertical ? this.barRect.height : this.barRect.width;

        this.bar = createSVGElement('rect', this.barStyle, this.svg, {
            x:			this.barRect.left,
            y:			this.barRect.top,
            width:		this.barRect.width,
            height:		this.barRect.height,
            'clip-path': 'url(#' + clipId + ')'}); 	// Applies the clipping rect to this element

        // Apply an 'outy' gradient to the bar:
        //if (this.fBarGradient)
            //this.bar.createGradientFill(this.svg, !this.fVertical, false, this.color);

        // Add the frame to the SVG element:
        createSVGElement('rect', this.frameStyle, this.svg, {
            x:		this.frameRect.left		+ this.frameStrokeWidth / 2,
            y:		this.frameRect.top		+ this.frameStrokeWidth / 2,
            width:	this.frameRect.width	- this.frameStrokeWidth,
            height:	this.frameRect.height	- this.frameStrokeWidth,
            rx:		this.borderRadius,
            ry:		this.borderRadius
        });

        // Render the scale, shuttles and handles. Give them all three rects, and they can use whatever values they need:
        if (this.scale)
            this.scale.render (this.barRect, this.frameRect, this.canvasRect);
        if (this.minMax)
            this.minMax.render (this.barRect, this.frameRect, this.canvasRect);
        for (var i = 0, shuttle; shuttle = this.shuttles[i]; ++i)
            shuttle.render (this.barRect, this.frameRect, this.canvasRect);
        for (var i = 0, handle; handle = this.handles[i]; ++i)
            handle.render (this.barRect, this.frameRect, this.canvasRect);

        this.fInitialized = true;

        // Lastly, connect the node, which could immediately call this.update():
        if (this.node) {
            this.node.subscribe(this, this.svg, this.redXMask);
            this.setValue(this.node.getValue() || this.minimum);
        } else
            this.setValue(this.minimum);

        return this; // Used for chaining and storage
    };

    destroy () {
        assert (this.fInitialized);

        for (var i = 0, shuttle; shuttle = this.shuttles[i]; ++i)
            shuttle.destroy();
        for (var i = 0, handle; handle = this.handles[i]; ++i)
            handle.destroy();

        if (this.node && this.node.subscribers)
            this.node.unsubscribe(this);

        this.element.removeChildren();
        this.unregisterAsWidget();
    };

    update(node) {	// Called by the job whenever node value/quality changes:
        assert (this.fInitialized);
        assert (node == this.node);

        if (node.quality != NodeQuality.NQ_GOOD)	// bad quality:
            return;

        assert (!isNaN(node.getValue()));

        this.setValue (node.getValue());
    };

    getValue() {	// No need for bar graph to have a node. You can call this directly instead:
        assert (this.fInitialized);
        var pixels = this.fVertical ? this.barRect.top + this.length - this.bar.y.baseVal.value : this.bar.x.baseVal.value - this.barRect.left + this.length;
        return (this.maximum - this.minimum) * pixels / this.length + this.minimum;
    };

    setValue(value) {	// No need for bar graph to have a node. You can call this directly instead:
        assert (this.fInitialized);
        var pixels	= Math.min(this.length, Math.max(0, this.length * (value - this.minimum) / (this.maximum - this.minimum)));

        // Update the bar position:
        if (this.fVertical)
            this.bar.setAttribute('y', this.barRect.top + this.length - pixels);
        else // horizontal bar:
            this.bar.setAttribute('x', this.barRect.left + pixels - this.length);

        return this;
    };

    // Add a user-adjustable handle to the bar graph.
    // If node is undefined or null, then use the bar graph node.
    addHandle(node) {
        assert (!this.fInitialized);
        node = node || this.node;	// If node is undefined, then use the bar graph node (if it exists):

        // Temporarily add this handle's properties as an object to the handles array:
        if (node && node.couldBeWritten())
            this.handles.push({node: node});

        return this;
    };

    // Add a shuttle to the bar graph.
    // If node is undefined, then use the bar graph node.
    // position must be 'left', 'top', 'right', or 'bottom'
    // fAdjustable determines whether user can drag shuttle to adjust node value
    addShuttle(node, position, fAdjustable, label, properites) {
        assert (!this.fInitialized);
        node = node || this.node;	// If node is undefined, then use the bargraph node:

        assert (position == 'left' || position == 'top' || position == 'right' || position == 'bottom');

        // Temporarily add this handle's properties as an object to the shuttles array:
        this.shuttles.push({
            node:			node,
            position:		position,
            fAdjustable:	(fAdjustable && node.couldBeWritten()),
            label:			label,
            properties:		properites
        });

        return this;
    };

    // Adds a set of identical shuttle for an array of nodes passed in
    addShuttleSet(nodes, position, fAdjustable, label, sClass, properites) {
        assert (!this.fInitialized);
        for(var i = 0; i < nodes.length; ++i){
            nodes[i] = nodes[i] || this.node;	// If node is undefined, then use the bargraph node:

            assert (position == 'left' || position == 'top' || position == 'right' || position == 'bottom');

            // Temporarily add this handle's properties as an object to the shuttles array:
            this.shuttles.push({
                node:			nodes[i],
                position:		position,
                fAdjustable:	(fAdjustable && nodes[i].couldBeWritten()),
                label:			label,
                class:			sClass,
                properties:		properites
            });
        }

        return this;
    };

    // Internal methods:
    _determineMinMaxRes() {
        // Determine bar minimum and maximum range in engineering units:
        // If user defined 'minimum' and 'maximum' properties already, then honor them regardless of node range.
        // Default is 0 to 100 with resolution of 1, if no value node defined:
        if (this.minimum === undefined)
            this.minimum = this.node && this.node.engMin ? this.node.engMin : 0;
        if (this.maximum === undefined)
            this.maximum = this.node && this.node.engMax  ? this.node.engMax : 100;
        if (this.resolution === undefined)
            this.resolution = this.node && this.node.resolution  ? this.node.resolution : 1;
    };

    _determineBorderRadius() {
        // Get the 'border-radius' style from the parent DOM element (this), and apply it to
        // the bar graph frame and clipping elements. Unfortunately, 'rx' and 'ry' cannot be directly
        // styled by CSS3, since they are not 'presentation attributes' according to W3C.
        // So, if the parent element is not an HTML element, but rather another SVG element,
        // then 'border-radius' will be invalid, and unavailable. So we can only have rounded
        // bargraphs if the parent element is an HTML element:
        this.borderRadius = this.element.getCSSInt('border-top-left-radius');	// Shortcut property 'border-radius' does not exist, so use the top left radius as proxy
        if (isNaN(this.borderRadius)) // Not defined, so set default:
            this.borderRadius = 4;
    //	console.log('border radius = ' + this.borderRadius);
    };

    _determineFrameStrokeWidth() {
        // Create temporary bargraph frame attached to the DOM just so we can query its
        // stroke width so that all rects are properly offset inside the parent DOM element.
        // Otherwise the outer half of the frame lines would be clipped, since the frame
        // line is centered on its rect:
        assert (this.svg, 'svg viewport must be created before calling this method');

        var frame = createSVGElement('rect', this.frameStyle, this.svg);

        this.frameStrokeWidth = frame.getStrokeWidth();
        this.svg.removeChild(frame);
    };
};
