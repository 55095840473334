import Owner from '../../owner';
import { LineChart } from '../widgets/linechart';
import { LineGraph } from '../widgets/linegraph';
import './debugpage.css';
import Page from './page';
export default class DebugPage extends Page {
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent);
        let device = Owner.ldc.devices.getByKey('US.TX.AQUAWSC.HWY195');
        device!.requestNodeTree(() => {
            let nodes = device!.tree.nodes;
            let chart = document.createElement('line-graph') as LineGraph;
            chart.style.width = '100%';
            chart.style.height = '100%';

            let chartNodes = nodes.filter(node => node && node.isLogged).map(filtered => {return {tag: filtered!}})
            chartNodes.length = 20;
            chart.liveValueTags = chartNodes
            parent.appendChild(chart);
        })

    }
}