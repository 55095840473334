import './menupanel.css';
import {createElement, createUniqueId} from '../elements';
import owner, { Routes }            from '../../owner';
import ViewModal from '../viewmodal';
import PasskeyView from '../views/passkeyview';
import PassKeyIcon from '../images/icons/passkeys-logo.svg'

import ArrowDownIcon        from '../images/icons/arrow_down.svg';
import DoubleArrowIcon      from '../images/icons/double_arrow_left.svg';
import LogoutIcon           from '../images/icons/logout.svg';

import assert from '../debug';
import { PumpTwins } from '../curves';
import avatar from './avatar';
import tippy, { Instance, Props, createSingleton } from 'tippy.js';
import { RouteInfo } from '../router/router';
import 'tippy.js/dist/tippy.css'; // optional for styling
import Dropdown from './dropdown';
import { LogoutMessageData } from './logouttimer';
import { presetUnitMap } from '../widgets/lib/tagunits';

interface RouteLink {
    displayName: string;
    icon: HTMLImageElement;
    subList: HTMLElement;
    element: HTMLElement;
    arrow: HTMLImageElement;
    subElements: Map<string, HTMLElement>;
}

export default class MenuPanel {
    parent       : HTMLElement;
    isOpen       : boolean = innerWidth > 620;
    container    : HTMLElement;
    blur         : HTMLElement;
    menu         : HTMLElement;
    deviceMap    : Map<string, HTMLElement> = new Map();
    id           : number;
    company      : HTMLSelectElement;
    subCount     : HTMLElement;
    deviceList   : HTMLElement;
    hmiList      : HTMLElement;
    dashboardList: HTMLElement;
    menuWrapper  : HTMLElement;
    topLinks     : HTMLElement;
    routeMap     : Map<string, RouteLink> = new Map();
    tippy        : any;
    static colorStrings: string[] = [/* CSS HEX */
        '#ff595e',
        '#ff924c',
        '#ffca3a',
        '#8ac926',
        '#52a675',
        '#1982c4',
        '#4267ac',
        '#6a4c93',
    ]
    constructor(parent: HTMLElement) {
        this.parent         = parent;
        this.menuWrapper    = createElement('div', `menu-panel__wrapper ${this.isOpen? 'open' : ''}`, this.parent);

        // Hide the navigation panel if this is the interface for a single local device
        if(process.env.TARGET == "localDevice")
            this.menuWrapper.classList.add('hide');

        let avatarWrapper   = createElement('div', 'menu-panel__avatar', this.menuWrapper);
        avatar(avatarWrapper, owner.ldc.user.firstName, owner.ldc.user.lastName, undefined, 'var(--color-deep-purple-8)');
        //new Switch(this.menuWrapper, false, '', (state) => owner.wrapper.setAttribute("classic", state ? "false" : "true"))
        this.menu           = createElement('div', 'menu-panel', this.menuWrapper);
        let user            = createElement('div', 'menu-panel__user', this.menu);      // user information section
        this.topLinks        = createElement('div', 'menu-panel__top-links', this.menu);
        let bottomLinks     = createElement('div', 'menu-panel__bottom-links', this.menu);
        //createElement('div', 'menu-panel__name', user, owner.ldc.user.fullName);        // user name
        this.id			    = owner.ldc.registerGraph(this);

        if (owner.ldc.isPowerUser()) {			// If the user is a Specific Energy, Inc. employee
            let checkWrapper = createElement('div', 'menu-panel__check-wrapper', user);
            let suppressCheckbox = createElement('se-checkbox', '', checkWrapper);
            suppressCheckbox.onchange = () => owner.fSuppressAlarms = suppressCheckbox.checked;
            createElement('div', '', checkWrapper, 'Suppress Alarms');

            checkWrapper = createElement('div', 'menu-panel__check-wrapper', user);
            let audibleCheckbox = createElement('se-checkbox', '', checkWrapper);
            audibleCheckbox.onchange = () => owner.fAudible = audibleCheckbox.checked;
            createElement('div', '', checkWrapper, 'Audible Alarms');

			this.company = createElement('select', 'menu-panel__company-selector', avatarWrapper);		// Give the user a drop down selector to change companies
			this.company.onchange = () => this.onSelectorChange();				// When the drop down changes, get users for that company
            assert(Array.isArray(owner.companies), "onCompaniesReceived has a bad callback");
            createElement('option', 'menu-panel__company-selector__option', this.company, 'All').value = '';	// Put an option in the selector
            for (var i = 0; i < owner.companies.length; ++i)	// For each company we got
                createElement('option', 'menu-panel__company-selector__option', this.company, owner.companies[i].name).value = owner.companies[i].key;	// Put an option in the selector

            this.onSelectorChange();					// Load the first company by default
            //this.subCount = createElement('div', 'menu-panel__user hide', user, 'Subbed Tags: ' + owner.subbedNodes.length    )
            /*
            let themeRow    = createElement('div', 'flex__row', user);
            createElement('div', '', themeRow, 'Theme: ');
            let themePicker = createElement('select', 'menu-panel__company-selector', themeRow);
            owner.colors.getThemes().forEach(theme => {
                createElement('option', '', themePicker, theme);
            })
            themePicker.onchange = () => {
                let theme = themePicker.options[themePicker.selectedIndex];
                debugger;
                owner.colors.setTheme(theme.value);
            }
            */

		} else															                    // Normal user
            createElement('div', 'menu-panel__company', avatarWrapper, owner.ldc.user.companyName);  // company name

        let unitsWrapper = createElement('div', 'menu-panel__units-wrapper', user)
        createElement('div', 'menu-panel__units-label', unitsWrapper, 'Units: ')
        let switchLabels = ['US', 'SI'];
        for (let i=0;i<switchLabels.length;++i) {
            let buttonID = createUniqueId();
            let input = createElement('input', 'radio-buttons__input', unitsWrapper);	// Create an input element
            input.setAttribute('type', 'radio');	// Make it a radio button
            input.setAttribute('Name', '_unitSwitch');	// Give all the buttons the same name
            input.setAttribute('id', buttonID);	    // Each button needs to match the label
            input.onchange = () => this.onUnitSelectionChanged(switchLabels[i]);
            let label = createElement('label', 'radioButtonLabel', unitsWrapper, switchLabels[i]);
            label.setAttribute('for', buttonID);	// Each input/label must have the same unique 'id'/'for' attribute:
        }

        let logoutContainer = createElement('div', 'flex__row', user);
        logoutContainer.style.paddingBottom = '10px';
        logoutContainer.style.cursor = 'pointer';
        createElement('img', 'menu-panel__button__icon', logoutContainer, undefined, {'src':LogoutIcon});
        let logoutText = createElement('div', '', logoutContainer, 'Logout in 10:00');
        logoutContainer.onclick = (e: MouseEvent) => {
            new Dropdown(e, ['Logout', owner.logoutTimer.fEnabled ? 'Disable Autologout' : 'Enable Autologout'], (selection: string)=>{
                switch(selection) {
                    case 'Logout':
                        owner.ldc.logOut();
                    break;
                    case 'Disable Autologout':
                        owner.logoutTimer.stop();
                    break;
                    case 'Enable Autologout':
                        owner.logoutTimer.start()
                    break;
                }
            });
        };

        //let brandRow    = createElement('div', 'flex__row', user);
        //createElement('div', '', brandRow, 'Brand Color: ');
        //let colorPicker = createElement('input', '', brandRow, '', {type:'color', value:owner.colors.brand});
        //colorPicker.onchange = () => owner.colors.setBrand(colorPicker.value);

        owner.logoutTimer.register(this, (data: LogoutMessageData) => {
            switch (data.message) {
                case 'tick':
                    logoutText.textContent = `Logout in ${owner.logoutTimer.timeLeft}`;
                    break;
                case 'stop':
                    logoutText.textContent = 'Autologout Disabled';
                    break;
            }
        });

        let passkeyButton = createElement('button', 'se-button', user);
        createElement('img', 'login__button__icon', passkeyButton, undefined, { 'src': PassKeyIcon });
        createElement('div', 'login__passkey-button-text', passkeyButton, 'Manage passkeys');
        passkeyButton.onclick = () => new ViewModal(new PasskeyView(owner.ldc.user.username), {
            maxWidth: '1000px',
            title: `${owner.ldc.user.firstName} ${owner.ldc.user.lastName}'s Passkeys`,
            titleTextColor: 'var(--color-inverseOnSurface)',
            titleBackgroundColor: 'var(--color-primary)',
        });

        let toggle = this.createLink(bottomLinks, '', 'Toggle Sidebar', DoubleArrowIcon, () => {this.toggle()})
        toggle.element.classList.add('menu-panel__toggle');
        toggle.icon.classList.add('menu-panel__toggle__icon');
    };

    createLink(parent: HTMLElement, routeName: string, displayName: string, icon: string, callback?: () => void, fDropdown?: boolean, tooltip?: string, color?: string) {
        let container       = createElement('div', 'menu-panel__collapse-list__container', parent);
        let inputRow        = createElement('div', 'menu-panel__collapse-list__container__input-row', container);
        let id              = createUniqueId();
        let input           = createElement('button', 'menu-panel__radio-input', inputRow, '', {'type':'button', 'id':id});
        let titleContainer  = createElement('label', 'menu-panel__collapse-list__top', inputRow, '', {'htmlFor':id});
        createElement('div', 'menu-panel__collapse-list__shadow', titleContainer);
        let colorContainer = createElement('div', 'menu-panel__collapse-list__color-container', inputRow);
        let colorShade = createElement('div', 'menu-panel__collapse-list__color-container__color', colorContainer);
        colorShade.style.backgroundColor = color ?? '';
        let iconElement = createElement('img', 'menu-panel__collapse-list__icon', titleContainer, '', {'src':icon, 'id':'menu-tippy'});
        iconElement.setAttribute('data-tippy-content',displayName);
        createElement('div', 'menu-panel__collapse-list__title', titleContainer, displayName);
        let arrow = createElement('img', `menu-panel__collapse-list__icon ${!fDropdown ? 'hide' : ''}`, inputRow, '', {'src':ArrowDownIcon});
        arrow.style.rotate = '-90deg';
        let accordion = createElement('se-accordion', '', container, '', {defaultOpen: false});
        let list = createElement('div', 'menu-panel__collapse-list', accordion);

        arrow.onclick = (e) => {
            e.stopPropagation();
            accordion.toggle();
            arrow.style.rotate = accordion.isOpen ? '': '-90deg'
        }
        input.onclick = (e) => {
            e.stopPropagation();
            if (!accordion.isOpen)
                accordion.open();
            if (callback)
                callback();
        }
        let routeLink: RouteLink = {
            displayName: displayName,
            icon: iconElement,
            subList: list,
            element: container,
            arrow: arrow,
            subElements: new Map(),
        }

        return routeLink;
    }

    createSubLink(routeName: string, name: string, href: string, icon?: string, filterKey?: string) {
        let routeLink = this.routeMap.get(routeName);
        if (!routeLink)
            return;
        let subLinkElement = createElement('div', 'menu-panel__collapse-list__item-container', routeLink.subList);
        subLinkElement.title = name;
        if (filterKey)
            routeLink.subElements.set(filterKey, subLinkElement);
        subLinkElement.onclick = () => window.location.hash = href;
        if (icon)
            createElement('img', 'menu-panel__collapse-list__icon', subLinkElement, '', {'src':icon})
        createElement('div', 'menu-panel__collapse-list__item-title', subLinkElement, name);
        if (filterKey)
            this.deviceMap.set(filterKey, subLinkElement);
        return subLinkElement
    }

    /**
     * Callback for route changes on page navigation
     * @param route
     * @returns
     */
    onRouteChanged(route: string, props: {[key: string]: string}) {
        if (owner.fMobile)
            this.close();
        for (let [name, link] of this.routeMap) {
            link.element.setAttribute('selected', 'false');
            link.subElements.forEach(element => {
                element.setAttribute('selected', 'false');
            })
        }
        let routeLink = this.routeMap.get(route);
        if (!routeLink)
            return;

        routeLink.element.setAttribute('selected', 'true');
        //TODO: make this work again
        let [firstKey] = Object.keys(props);
        if (firstKey !== undefined) {
            let subElement = routeLink.subElements.get(props[firstKey]);
            subElement?.setAttribute('selected', 'true');
        }
        //if (route.paramNames.length > 0 && route.props[route.paramNames[0]] !== undefined) {
        //    let subElement = routeLink.subElements.get(route.props[route.paramNames[0]]);
        //    if (subElement)
        //        subElement.setAttribute('selected', 'true');
        //}
    }

    /**
     * Special method used by the owner to set up links used to navigate the page.
     * @param href
     * @param routeInfo
     * @param fDropDown
     */
    createRouteLink(href: string, routeName: string, routeInfo: RouteInfo, fDropDown?: boolean) {
        let link = this.createLink(this.topLinks, routeName, routeInfo.displayName, routeInfo.icon, fDropDown? ()=>{} : () => location.hash = href, fDropDown, undefined, MenuPanel.colorStrings[this.routeMap.size]);
        link.icon.classList.add('menu-panel__has-tooltip'); // Add a class that identifies this link to receive a tippy
        //link.icon.parentElement!.style.backgroundColor = MenuPanel.colorStrings[this.routeMap.size] ?? ''
        this.routeMap.set(routeName, link);     // Keep track of this guy
    }

    /**
     *  Create all tool tips for links with the class '.menu-panel__has-tooltip'
     */
    createTippys() {
        this.tippy = createSingleton(tippy('.menu-panel__has-tooltip'), {
			placement: 'right',
			offset: [0, 18],    // Magic offset so we show up off the side of the menu panel
            delay: 500,         // Half second delay on hover
            moveTransition: 'transform 0.2s ease-out',
		});
    }

    onUnitSelectionChanged(selection: string) {
        for (let i=0;i<owner.sortedDevices.length;++i) {
            let device = owner.sortedDevices[i];
            if (device.tree) {
                let newUnitsMap = new Map(device.cachedUnitsMap); // reset each tree's unitsMap back to its original state
                presetUnitMap.get(selection)?.forEach((value, key) => {      // for each of our key value pairs mapped from our selection
                    newUnitsMap.set(key,value);                    // set the units to the new setting
                })
                device.tree.nodes.forEach(node => node?.updateUnits(newUnitsMap));
            }
            device.pumpTwins = new PumpTwins(device);
        }
        if (owner.currentPage) {
            owner.currentPage.refresh();    // just refresh our page (too complicated to try to find everywhere we may have listed a unit)
        }
    }

    clearSubLinks(routeName: string) {
        let routeLink = this.routeMap.get(routeName);
        if (!routeLink)
            return;
        routeLink.subList.removeChildren();
    }

    getCompanyKey(): string {
        let companyKey: string;
		if (this.company && this.company.selectedIndex > -1)					    // If we can see multiple companies
			companyKey = this.company.options[this.company.selectedIndex].value;	// Refresh the company we are looking at
		else
            companyKey = owner.ldc.user.companyKey;		                            // Refresh the users associated with the owner's company
        return companyKey;
    }

    onCompaniesReceived(companies) {		// Got the companies back
        assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
        createElement('option', 'menu-panel__company-selector__option', this.company, 'All', {value:''});	// Put an option in the selector
        companies.sort(((a, b) => {
            if (a.name < b.name)
                return -1;
            else if (a.name > b.name)
                return 1;
            else
                return 0;
        }));
		for (var i = 0; i < companies.length; ++i)	// For each company we got
            createElement('option', 'menu-panel__company-selector__option', this.company, companies[i].name, {value: companies[i].key}); // Put an option in the selector
		this.onSelectorChange();					// Load the first company by default
    }

    onSelectorChange() {
        assert(owner.ldc.isPowerUser(), 'Non power user attempting to change company selection')
		let companyKey = this.getCompanyKey();
        this.deviceMap.forEach((element, deviceKey) => {
            if (deviceKey.includes(companyKey) || companyKey == 'All')
                element.classList.remove('hide');
            else
                element.classList.add('hide');
        })
        owner.currentPage.onCompanySelected();
        if (owner.fInitialized)
            owner.refreshDashboards();
    }

    toggleCollapse(dropIcon,element) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        var isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if(isCollapsed) {
          element.expand();
          if (dropIcon) dropIcon.style.transform = 'rotate(0deg)';
        } else {
          element.collapse();
          if (dropIcon) dropIcon.style.transform = 'rotate(-90deg)';
        }
    }

    open() {
        this.menuWrapper.addEventListener('transitionend', ()=>window.dispatchEvent(new Event('resize')), { once: true });
        if (this.tippy)
            this.tippy.disable();
        this.isOpen = true;
        this.menuWrapper.classList.add('open');
        if (window.innerWidth < 620)
            owner.alarmPanel.close();
        owner.navBar.openMenu();
    };

    close() {
        this.menuWrapper.addEventListener('transitionend', ()=>window.dispatchEvent(new Event('resize')), { once: true });

        if (this.tippy)
            this.tippy.enable();
        this.isOpen = false;
        this.menuWrapper.classList.remove('open');
        owner.navBar.closeMenu();
    }

    toggle() {
        if (this.isOpen)
            this.close();
        else
            this.open();
    }

    destroy() {
        this.parent.removeChild(this.container);
    }
};