import owner, { Routes }    from '../../owner';
import Page                 from './page';
import HomeIcon             from '../images/icons/home.svg';
import ChartIcon            from '../images/icons/chart.svg';
import BuildIcon            from '../images/icons/build.svg';
import PumpIcon             from '../images/icons/pump.svg';
import DashboardIcon        from '../images/icons/dashboard.svg';
import ReportIcon           from '../images/icons/report.svg';
import DollarIcon           from '../images/icons/dollar.svg';
import DeviceIcon           from '../images/icons/device.svg';
import OperatorIcon         from '../images/icons/operator.svg';
import defaultView          from '../views/defaultview';
import SpecificEnergyView   from '../views/specificenergyview';
import SimplifiedSpecificEnergyView from '../views/simplifiedsemapview';
import ChartView            from '../views/chartview_classic';
import Overview             from '../views/pumpstation';
import ConfigTabView        from '../views/configtabview';
import PumpCurveView        from '../views/pumpcurveview';
import CustomTabView        from '../views/customtabview';
import AssetManagementView  from '../views/assetmanagementview';
import ResultsView          from '../views/resultsview';
import IFSOverview          from '../views/ifsoverview';
import SECIcon              from '../images/icons/SEC.svg';
import TabBar               from '../components/tabbar';
import ReportView           from '../views/reportview';
import LiftStationOverview  from '../views/liftstationoverview';
import Dialog               from '../dialog';
import WellOverview         from '../views/welloverview';
import FormView             from '../views/formview';
import { createElement }    from '../elements';
import ConstraintView       from '../views/constraintview';
import EventFeedView        from '../views/eventfeedview';
import ListIcon             from '../images/icons/list.svg';
import './devicepage.css'
import OvivoReport from '../views/ovivoreport';
import AlarmLogView from '../views/alarmlogview';
import DashboardView from '../views/dashboardview';
import { Device } from '../device';
//import AnalyticsView from '../views/analyticsview';
import { TabBarInfo } from '../components/tabbar';
import DisconnectedIcon from '../images/icons/disconnected.svg';
import FrameParser from '../frameparser';
import ConfigTabViewClassic from '../views/configtabview_classic';
import TagFinderView from '../views/tagfinderview';
import MaintenanceView from '../views/maintenanceview';
import { TagUnit } from '../widgets/lib/tagunits';
import { Role } from '../role';
import WidgetDashboardView from '../views/widgetdashboardview';
import TaggerView from '../views/taggerview';
import { getHash, getRouteAndProperties } from '../router/router';
import { DPOOverview } from '../widgets/dpo/overview/overview';

interface DashboardDetails {
	version: number;
	id: number;
}

export default class DevicePage extends Page {
    device: Device;
    container: HTMLElement;
    props: {[key: string]: string};
    id: number;
    disconnectDialog: Dialog;
    fileNames: string[];
    titleBar: HTMLElement;
    resizeListener: EventListener;
    tabs: any[] = [];
    disconnectOverlay: HTMLElement;
    banner: HTMLElement;
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent);
        this.props  = {...props}; // create copy of the route properties
        this.device = owner.ldc.devices.getByKey(this.props.key)!;
        if (!this.device) {
            let defaultDevice = owner.sortedDevices[0];
            if (!defaultDevice) {
                owner.selectedDevice == undefined;
                let properties = {
                    title:  'No Taggers',
                    body:   'Your account does not have access to any Taggers. Please contact your system administrator for access.',
                    buttons:[{'title':'Return','callback':()=>window.location.hash = getHash(Routes.Home)}]
                }
                new Dialog(document.body, properties);
                return;
            }
            location.hash = getHash(...getRouteAndProperties(location.href, {'key':defaultDevice.key}));
            return;
        }

        this.container      = createElement('div', 'device__container', parent);
        this.id             = owner.ldc.registerGraph(this);

        owner.selectedDevice = this.device;
        owner.navBar.setTitle(`${this.device.siteName}${this.device.connected ? '' : ' (Disconnected)'}`);

        this.disconnectOverlay = createElement('div', 'device__disconnect-overlay', this.parent);
        createElement('img', 'device__disconnect-overlay__icon', this.disconnectOverlay, undefined, {'src':DisconnectedIcon});
        createElement('div', 'device__disconnect-overlay__text', this.disconnectOverlay, 'Device Disconnected');
        this.device.onConnect.set(this, () => this.onDeviceStatusChange(this.device));
        this.device.onDisconnect.set(this, () => this.onDeviceStatusChange(this.device));
        this.onDeviceStatusChange(this.device);
    }

    onDeviceStatusChange(device: Device): void {
        if (device.connected) {
            if (this.tabBar)
                this.tabBar.destroy();

            this.disconnectOverlay.classList.add('hide');
            this.device.requestNodeTree(() => this.onNodeTreeComplete(this.device));
        }
        else {
            this.disconnectOverlay.classList.remove('hide')
            if (this.device.cachedTree && !this.tabBar)
                this.device.requestNodeTree(() => this.onNodeTreeComplete(this.device));
        }
    }

    onNodeTreeComplete(device: Device) { // called by device onnodetreecomplete
        this.container.removeChildren();
        this.tabs = [];
        let titleRow        = createElement('div', 'device__title-row', this.container);
        let titleContainer  = createElement('div', 'device__title-row__title', titleRow);
        createElement('img', 'device__title-row__title__icon', titleContainer, '', {src: DeviceIcon});
        createElement('div', 'device__title-row__title__title', titleContainer, this.device.siteName);
        createElement('div', 'device__title-row__status', titleRow);

        this.banner       = createElement('div', 'device__banner', this.container);
        this.tabBar       = new TabBar(this.container);
        let rootNode      = device.tree.nodes[0]!;
        let pumpSystem    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);
        let flare         = rootNode.findChildByRole(Role.ROLE_FLARE_FOLDER);
        let IFSTag        = rootNode.findChildByRole(Role.ROLE_IFS_PUMP);
        let dpoFolder     = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
        let alternators   = rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
        if (alternators.length == 0)
		    alternators   = rootNode.findByRole(Role.ROLE_TOP_LEVEL);
        let hasConfig     = rootNode.findChildByRole(Role.ROLE_CONFIG_ERRORS);
        let fTagger       = rootNode.findChildByRole(Role.ROLE_VERSION);
        let assetMode     = dpoFolder ? dpoFolder.findChild('AssetManagementMode') : null;

        device.fAssetOnly	= assetMode && assetMode.getValue() && !owner.ldc.isPowerUser();

        this.fileNames		= device.customFiles;

        if(alternators.length > 1) {		// Simple Mustang Well
            this.tabs.push(
                {name: "Overview", 		icon: HomeIcon,		content: new WellOverview(this.device, owner.ldc)},
            );
        } else if (alternators.length == 1 && pumpSystem) {		// Mustang well with opt boosters
            alternators.push(pumpSystem);
            this.tabs.push(
                {name: "Well", 		            icon: HomeIcon,		content: new WellOverview(this.device, owner.ldc)},
                {name: "Booster",               icon: HomeIcon,     content: new Overview(this.device, owner.ldc)},
                {name: "Specific Energy Map",   icon: SECIcon,      content: new SpecificEnergyView(this.device, owner.ldc)},
                {name: "Pumps", 		        icon: PumpIcon,		content: new PumpCurveView(this.device)},
                {name: "Controls",              icon: BuildIcon,    content: new ConstraintView(this.device) },
                {name: "Asset Management",      icon: DollarIcon,	content: new AssetManagementView(this.device, owner.ldc)},
            );
        } else if (pumpSystem) { //It's got an uber controller
            let minBEP = pumpSystem.findByRole(Role.ROLE_PUMP_MIN_BEP_RATIO);
            let maxBEP = pumpSystem.findByRole(Role.ROLE_PUMP_MAX_BEP_RATIO);
            let minAOR = pumpSystem.findByRole(Role.ROLE_PUMP_MIN_AOR);
            let maxAOR = pumpSystem.findByRole(Role.ROLE_PUMP_MAX_AOR);
            if (minBEP)
                for (const ratio of minBEP)
                    ratio.units = TagUnit.TU_RATIO;
            if (maxBEP)
                for (const ratio of maxBEP)
                    ratio.units = TagUnit.TU_RATIO;
            if (minAOR)
                for (const ratio of minAOR)
                    ratio.units = TagUnit.TU_RATIO;
            if (maxAOR)
                for (const ratio of maxAOR)
                    ratio.units = TagUnit.TU_RATIO;

            var floatNode = pumpSystem.findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR);	// Display default view differently if it's a lift station
            this.tabs.push(
                {name: "Overview", 		    icon: HomeIcon,		content: floatNode ? new LiftStationOverview(this.device) : new Overview(this.device, owner.ldc)},
            );

            let maintenanceFolder = rootNode.findChildByRole(Role.ROLE_MAINTENANCE_FOLDER);
            if (maintenanceFolder) {
                let activeTask = maintenanceFolder.findChildByRole(Role.ROLE_MAINTENANCE_TASK_ACTIVE);
                if (activeTask)
                    createElement('lsg-maintenance-banner', '', this.banner, '', {statusTag: {tag:activeTask}})
                this.tabs.push({name: "Maintenance", 		icon: BuildIcon, content: new MaintenanceView(this.device)});
            }

            if (!device.fAssetOnly && dpoFolder) {// No Solver tab in asset management mode
                if (!floatNode && device.key !== 'UK.THAMES.COPPERMILLSHL')
                    this.tabs.push({ name: "Operation", icon: OperatorIcon, content: new SimplifiedSpecificEnergyView(this.device, owner.ldc) });
                this.tabs.push(
                    { name: "Specific Energy Map",   icon: SECIcon,      content: new SpecificEnergyView(this.device, owner.ldc) },
                    { name: "Controls",              icon: BuildIcon,    content: new ConstraintView(this.device) },
                    { name: "Results",               icon: ReportIcon,   content: new ResultsView(this.device, owner.ldc) },
                );
            }
            this.tabs.push({name: "Pumps", 	            icon: PumpIcon,		content: new PumpCurveView(this.device)})
            this.tabs.push({name: "Asset Management",   icon: DollarIcon,	content: new AssetManagementView(this.device, owner.ldc)});

        } else if (alternators.length == 1) {
            if(alternators[0].findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR)) { //It must be a lift station.
                this.tabs.push(
                    {name: "Overview", 		icon: HomeIcon,	    content: new LiftStationOverview(this.device)},
                    {name: "Configuration", icon: BuildIcon,    content: new FormView(this.device, owner.ldc) }, //TODO: need configuration for lift stations
                );
            } else {	// We assume this is a well
                this.tabs.push(
                    {name: "Overview", icon: HomeIcon, content: new WellOverview(this.device, owner.ldc)},
                );
            }

        } else if (flare) {
            this.tabs.push(
                {name: "Overview", icon: HomeIcon, content: new defaultView()},
            );
        }
        else if (IFSTag) {
            this.tabs.push({name: "Overview", icon: HomeIcon, content: new IFSOverview(this.device, owner.ldc)});
        }
        else if (fTagger) {
            this.tabs.push({name: 'Overview', icon: HomeIcon, content: new TaggerView(this.device.tree.nodes[0]!)})
        }
        if (device.customFiles.length > 0) {	// It has at least one custom file
            if (device.key == 'US.TX.MUSTANGSUD.SANDBROCK.WWTP' || device.key == 'US.OVIVO.OH.CANTON')
                this.tabs.push({name: "Dashboards", icon: DashboardIcon, content: new CustomTabView(this.device, owner.ldc)});
            else
                for (let fileName of device.customFiles) {
                    this.tabs.push({name: fileName, icon: DashboardIcon, content: new CustomTabView(this.device, owner.ldc, fileName)});
                }
        }
        if (device.key.match("US.OVIVO.OH.CANTON")) {				// Can we make this less ugly?
            this.tabs.push({name: "Analysis", icon: DashboardIcon, content: new OvivoReport(this.device, owner.ldc)});
        }

        this.tabs.splice(4,0,{name: "Chart", icon: ChartIcon,    content: new ChartView(this.device, owner.ldc)});
        this.tabs.push({name: "Alarm Settings",  icon: ListIcon,     content: new EventFeedView(this.device, owner.ldc)});
        this.tabs.push({name: "Alarm Log", icon: ListIcon, content: new AlarmLogView(this.device, owner.ldc)});
        this.tabs.push({name: "Tag Finder", icon: DashboardIcon, content: new TagFinderView(owner.ldc, this.device)});
        this.tabs.push({name: "Reports",     icon: ReportIcon,	content: new ReportView(this.device, owner.ldc)});


        if (hasConfig && owner.ldc.user.fTagConfig)
            this.tabs.push({name: "Tag Config", icon: DashboardIcon, content: fTagger ? new ConfigTabView(this.device, owner.ldc, {deviceKey: this.device.key}) : new ConfigTabViewClassic(this.device)});

        if (pumpSystem) {
            var floatNode = pumpSystem.findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR);	// Display default view differently if it's a lift station
            if (floatNode)
                this.tabs.push({name: "Form",          icon: ListIcon,    content: new FormView(this.device, owner.ldc) });
        }
        let maintenanceFolder = rootNode.findChildByRole(Role.ROLE_MAINTENANCE_FOLDER);
        if (maintenanceFolder) {
            let element = this.tabs.find(tag => tag === 'Specific Energy Map')
            this.tabs.push(this.tabs.splice(this.tabs.indexOf(element), 1)[0]);
        }
        this.resizeListener = () => this.tabBar.currentView && this.tabBar.currentView.resize();
        window.addEventListener('resize', this.resizeListener);
        for (let [name, details] of this.device.dashboards) {
            this.tabs.unshift({name: name,     icon: DashboardIcon,	content: details.version > 3 ? new WidgetDashboardView(owner.ldc, details.id) : new DashboardView(owner.ldc, details.id)});
        }
        /*
        if (owner.ldc.user.fWizard) {
            for (let i=0;i<owner.companies.length;++i) {
                if (this.device.key.includes(owner.companies[i].key) && owner.companies[i].key != "" && owner.companies[i].key != "US") {
                    owner.ldc.getDashboards(this.id, owner.companies[i].key);
                    break;
                }
            }
        }
        else
            owner.ldc.getDashboards(this.id, owner.ldc.user.companyKey);
            */
        if (this.tabs.length > 0)
            this.tabBar.addTabs(this.tabs);
        this.tabBar.selectTab(this.props.tab);
    };

    resize() {
        if (this.tabBar)
            this.tabBar.currentView && this.tabBar.currentView.resize()
    }

    onDashboardsResponse(fp: FrameParser) {
        if (owner.currentPage !== this) { // We might get this back after another page navigation if the user is clicking around super fast, or has a slow connection
            fp.skip(fp.size());
            return;
        }
        let count       = fp.pop_u32();
        for (let i=0;i<count;i++) {
            let id          = fp.pop_u32();
            let name        = fp.pop_string();
            fp.pop_u16();       // version
            fp.pop_string();    // creator
            let deviceCount = fp.pop_u32();
            for (let j=0;j<deviceCount;++j) {
                let key = fp.pop_string();
                if (key == this.device.key)
                    this.tabs.unshift({name: name,     icon: DashboardIcon,	content: new DashboardView(owner.ldc, id)});
            }
        }
        if (this.tabs.length > 0)
            this.tabBar.addTabs(this.tabs);
        this.tabBar.selectTab(this.props.tab);
    }

    setProps(newProps: {[key: string]: string}) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'key': // if the device key changes, create a new device page
                        new DevicePage(this.parent, newProps);
                        return;
                    case 'tab': // if the tab changes, select the new tab
                        this.tabBar.selectTab(this.props['tab']);
                        break;
                    case 'index': // if the index changes, pass the new index to the current view
                        this.tabBar.currentView && this.tabBar.currentView.onIndexChange(this.props['index']);
                        break;
                    case 'filename':
                        this.tabBar.currentView && this.tabBar.currentView.onFileChange(this.props['filename']);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    destroy() {
        this.device.onConnect.delete(this);
        this.device.onDisconnect.delete(this);
        window.removeEventListener('resize', this.resizeListener);
        if (this.tabBar)
            this.tabBar.destroy();
        super.destroy();
    }
}