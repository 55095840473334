import SVGWidget from "./svgwidget";
import {createElement} from '../elements';
import {RadioButtons} from '../radiobuttons';

//For demo - interactivity.
export default class WriteHOA extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

        this.setupNodes([''],true);
        if (!this.nodes['']) return; //abort
        if (!this.nodes[''].isWriteable){
            this.postError("This isn't a writeable node!");
            return;
        }
		this.setup();
	}

    setup(){
        //Find the nearest HTML node:
		var parent = SVGWidget.findFirstHTMLParent(this.element);

		//Create wrapper and position it
		var div = createElement('div',null,parent);
        SVGWidget.positionHTMLElement(div,parent,this.element);

        var widget = new RadioButtons(div,{node:this.nodes['']});
        widget.initialize();
    }

}
