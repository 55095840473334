/*
    PathRestrictedStorage stores encrypted objects in IndexedDB that can only be decrypted by JavaScript running on the path for which they were set. This allows
    us to store items on a client's browser without the risk of malicious JavaScript running on other Specific Energy paths on the same domain
    reading sensitive information that belongs to another application. The primary use case for this is authentication tokens. PathRestrictedStorage allows us
    to store /WebClient authentication tokens on the client's browser without exposing those tokens to the marketing site "/" or "/WhatIf".

    Usage:
        let pathStorage = new PathRestrictedStorage(); // Instantiate a PathRestrictedStorage object

        // Example: use it outside an async function
        pathStorage.setItem("myItem", "sensitive-client-value").then(()=>{ // Set item
            pathStorage.getItem("myItem").then((item)=>{ console.log(item) }); // Get item
        });

        // Example: inside an async
        async doStuff() {
            await pathStorage.setItem("myItem", "sensitive-client-value");
            const item = await pathStorage.getItem("myItem");
        }


    Evaluation of different web storage options:
        IndexedDB+Cookies(chosen solution):
            IndexedDB is key/value store that unlike sessionStorage and localStorage, supports binary types like ArrayBuffer. IndexDB databases are available across all
            paths/protocols on a given domain. However, in order to restrict IndexedDB contents to a particular path/protocol, PathRestrictedStorage stores encrypted
            objects in an IndexDB database. Contents are encrypted with a key that's stored as a cookie that is scoped to a specific path/protocol. This allows us to
            store large objects in web storage that are scoped to a particular path. i.e. /WebClient's database contents can be read by the marketing site at "/". However,
            because the marketing site cannot access the key to decrypt the database contents that lives as a cookie scoped for /WebClient, none of the data is meaningful
            to the marketing site. Malicious marketing site JavaScript could corrupt this data, but that's an acceptable risk given that the primary use case is to prevent
            malicious marketing site JavaScript from hijacking WebClient sessions.
        SessionStorage:
            Sessionstorage is scoped for the tab you're on. If you close the tab or the browser, sessionStorage goes poooof. The benefit of this is that there's no risk
            of exposing auth JWTs to other applications on our domain like the marketing site. The downside is that if you open another tab, you have to log in again...

        Local storage:
            Local storage has plenty of space for our JWTs but it's available to all paths/protocols on our domain. i.e. all the scripts loaded by the marketing site
            (like hubspot scripts) could access JWTs in localStorage which increases the risk of exposure.

        Entirely Cookie based:
            IF we want to support using these tokens across tabs/closing the browser, a way to do that is by setting the token as a cookie as shown below. The downside
            to doing this is that the cookies are appended as request headers that match the domain/path/protocol. Cookies are also limited to 4096 bytes per site so
            if we try to cram too much data into JWTs, this could become an issue.

                var validityPeriod = 20; //minutes
                var date = new Date();
                date.setTime(date.getTime()+(validityPeriod*60*1000));
                document.cookie = `__Secure-auth=${jwt}; Expires=${date.toGMTString()}; Path=${window.location.pathname}; Secure; SameSite=Strict`;

            * __Secure- prefix ensures that the setting of the cookie happened in a secure (HTTPS) context (i.e. an evil HTTP MITM cannot set this cookie)
            * Expires instructs the client's browser to remove the cookie after expiration
            * Path attr is used to restrict use of the cookie to the path when the cookie is set (i.e. /WebClient and /WhatIf shouldn't be able to see each other's cookies)
            * Secure instructs the browser to only send the cookie in a secure (HTTPS) context
            * SameSite=Strict tells the browser to never send the cookie in third-party contexts (i.e. https://randomwebsite.com's JS makes an AJAX request to https://specificenergy.com...no cookie will be included)
*/

export default class PathRestrictedStorage {

    dbOpenRequest: IDBOpenDBRequest;
    keyLife: number;
    objectStoreNames: string;
    key: CryptoKey;
    cookieName: string;
    dbInitialized: Promise<void>; // We wait on this to resolve before proceeding with any requests to interact with the DB

    constructor() {
        this.keyLife                            = 43200/*minutes*/; // 30 days (after 30 days, the encryption key will expire and existing data will get overwritten with the new key)
        this.objectStoreNames                   = "restricted_path_objects";
        this.cookieName                         = (process.env.TARGET === 'localTest') ? "key" : "__Secure-key";    // Only enforce setting this cookie in a secure context if we're not running in insecure local mode
        this.dbInitialized                      = this.initialize();
    }

    async initialize(): Promise<void> {
        return new Promise((resolve) => {
            this.dbOpenRequest                      = indexedDB.open(`PathRestrictedStorage-${window.location.pathname}`/*, version*/);
            this.dbOpenRequest.onupgradeneeded      = this.onupgradeneeded;
            this.dbOpenRequest.onerror              = this.onerror;
            this.dbOpenRequest.onsuccess            = () => { resolve(); }
        })
    }

    onupgradeneeded(event: any) {
        let db = event.target.result;
        if (!db.objectStoreNames.contains('restricted_path_objects')) {         // if there's no "restricted_path_objects" store
            db.createObjectStore('restricted_path_objects', { keyPath: 'id' });   // create it
        }
    }

    onerror(event: any) {
        console.error("Error", event.target.result);
    }

    async getItem(name: string): Promise<any> {
        await this.dbInitialized;
        return new Promise((resolve) => {
            let transaction = this.dbOpenRequest.result.transaction(this.objectStoreNames, "readonly");
            let objects = transaction.objectStore(this.objectStoreNames);
            let request = objects.get(name);
            request.onsuccess = (event: any)=>{
                if (event.target.result) {   // We found an object with this name
                    this.decryptItem(event.target.result.value, event.target.result.iv).then((decrypted) => { resolve(decrypted); });
                }
                else {
                    resolve(undefined);
                }
            };
        });
    }

    async setItem(name: string, item: any): Promise<void> {
        await this.dbInitialized;
        return new Promise(async (resolve) => {
            let iv = crypto.getRandomValues(new Uint8Array(12));
            let ciphertext = await this.encryptItem(item, iv);
            let transaction = this.dbOpenRequest.result.transaction(this.objectStoreNames, "readwrite");
            let objects = transaction.objectStore(this.objectStoreNames);
            let object = {
                id: name,                       // Key used to reference the object
                iv: iv,                         // Store the IV with the object to be used during decryption
                value: ciphertext,              // Encrypted object we want to store
                created: new Date()
            }
            let request = objects.put(object);  // Put overwrites existing object if there is one
            request.onsuccess = (event: any)=>{ resolve(); };
        });
    }

    async deleteItem(name: string): Promise<void> {
        await this.dbInitialized;
        return new Promise(async (resolve) => {
            let transaction = this.dbOpenRequest.result.transaction(this.objectStoreNames, "readwrite");
            let objects = transaction.objectStore(this.objectStoreNames);
            let request = objects.delete(name);
            request.onsuccess = (event: any)=>{ resolve(); };
        });
    }

    setEncryptionKey() {
        /*
            Explanation of cookie attributes:
                * __Secure-: prefix ensures that the setting of the cookie happened in a secure (HTTPS) context (i.e. an evil HTTP MITM cannot set this cookie)
                * Expires instructs the client's browser to remove the cookie after expiration
                * Path: attr is used to restrict use of the cookie to the path when the cookie is set (i.e. /WebClient and /WhatIf shouldn't be able to see
                  each other's cookies)
                * Secure: instructs the browser to only send the cookie in a secure (HTTPS) context
                * SameSite=Strict: tells the browser to never send the cookie in third-party contexts (i.e. https://randomwebsite.com's JS makes an AJAX
                  request to https://specificenergy.com...no cookie will be included)
        */
        var date = new Date();
        date.setTime(date.getTime() + (this.keyLife * 60 * 1000));
        document.cookie = `${this.cookieName}=${this.hexEncode(crypto.getRandomValues(new Uint32Array(16)))}; Expires=${date['toGMTString']()}; Path=${location.pathname}; Secure; SameSite=Strict`;
    }

    async convertToCryptoKey(keyData: string) {           // Convert the random hex bytes to a CryptoKey object
        let enc = new TextEncoder();
        let keyBuffer = enc.encode(keyData);
        var key = await crypto.subtle.importKey(
            "raw",
            keyBuffer,
            { name: "AES-GCM", length: 128 },
            true,
            ["decrypt", "encrypt"]
        );
        return key;
    }

    async getEncryptionKey(): Promise<CryptoKey> {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${this.cookieName}=`);
        if (parts.length === 2)
            return this.convertToCryptoKey(parts.pop()!.split(';').shift()!); // We found an existing encryption key
        else {
            this.setEncryptionKey();                                        // Set a new key
            return this.getEncryptionKey();                                 // Load the new key
        }
    }

    hexEncode(buffer: ArrayBuffer) {
        return [...new Uint8Array(buffer)]
            .map(x => x.toString(16).padStart(2, '0'))
            .join('');
    }

    async encryptItem(item: any, iv: Uint8Array) {
        let enc = new TextEncoder();
        let encoded = enc.encode(item);
        // iv will be needed for decryption
        this.key = await this.getEncryptionKey();
        return crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv
            },
            this.key,
            encoded
        );
    }
    async decryptItem(ciphertext: ArrayBuffer, iv: Uint8Array): Promise<string | undefined> {
        return new Promise(async (resolve) => {
            this.key = await this.getEncryptionKey();
            try {
                let decrypted = await crypto.subtle.decrypt(
                    {
                        name: "AES-GCM",
                        iv: iv
                    },
                    this.key,
                    ciphertext
                );
                let dec = new TextDecoder();
                resolve(dec.decode(decrypted));
            } catch (err) {
                console.log("Failed to retrieve encrypted object form storage");
                console.log(err);
                resolve(undefined); // Perfectly fine as callers of getItem expect to receive undefined in the case of missing stored objects
            }
        });
    }
}