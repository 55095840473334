import SVGWidget from "./svgwidget";
import createSVGElement from '../svgelements';
import { NodeQuality } from '../node';
import { TagUnit, TagUnitQuantity } from "../widgets/lib/tagunits";

// The GenericFlower driver provides the baseline for any widget that could use a flowing element (pump,
// valve, blower, etc.)
// Necessary attributes:
//      data-se-node="FLOWER'S/NODE/PATH"
//
// Other assumptions: Assumes that the element that has data-se-lens="" is an SVG rect element.
// Otherwise, this will likely break.
//
// Supplemental attributes:
//		data-se-direction="left|right|up|down"			defines what direction the flower should be pointing in
//
//		data-se-isomer="cw|ccw"							defines the isomer (i.e. should the flower's spout be
//															just clockwise or just counter clockwise of the
//															direction mentioned
//
//		data-se-text-offset="0|1|2|3"					defines how many quarter turn offsets the text on the
//															flower should be offset to look proper (not upside down)
//
//		data-se-node-mode="FLOWER'S/STATUS/PATH"		defines what mode the flower is in (Auto or Manual)
//
//		data-se-status_[SC]="(n|a)?(Error|Off|On|OPOR)"	defines what status code SC means (whether an error, is
//															off, is on, or is outside of POR) and what mode
//															it is running in if needed (n: not in auto, a:
//															in auto). Do not need to specify the mode
//
//		data-se-status-exp_[SC]="A string of you're very own creation"
//														defines what status code SC should show to the user via a Tippy

export default class GenericFlower extends SVGWidget {
    static attach(nodeRoot, element) {
        return new this(nodeRoot, element);
    }

    constructor(nodeRoot, element) {
        super(nodeRoot, element);
        this.HTMLcontainer = SVGWidget.findFirstHTMLParent(this.element);
		let that = this;
        this.treeTimeout = setInterval(function(){
            if(that.treesComplete(that.devices)){
                that.readSettings();
                clearInterval(that.treeTimeout);
            }
        }, 250, that);							// Keep recalling until eventually have a completed tree for device
	}

	get node() {								// Getter for nodes, important to use this.nodes[] because of reconnecting
		return this.nodes[""];					// device issue that completely loses the old nodes
	}

	get modeNode() {							// Should do this for every node that the program wants to use.
		return this.nodes["Mode"];
	}

	setTextLocation(direction) {
		assert(false, "method not implemented");
	}

	readSettings() {							// Read the settings and set up the variables / members properly
		this.setupDevices([''], false);			// Set up devices and nodes referenced, [''] means that we require
		this.setupNodes([''], false);			// some data-se-node="~DEV/NODE/../PATH"
		let direction = SVGWidget.readAttribute(this.element, "seDirection", "right");	// Read the direction, important for display, and not much else
		this.rotation = 0;						// How far to rotate the flower in degrees clockwise from positive x-axis
		this.setTextLocation(direction);
		var isVertical = false;
		switch (direction) {
		case "right":							// Nothing to do: already have 0 degrees set
			break;
		case "up":								// Up is -90 degrees or +270 degrees clockwise from positive x-axis
			this.rotation = 270;
			isVertical = true;
			break;
		case "left":							// Left is 180 degs from right
			this.rotation = 180;
			break;
		case "down":							// Down is 90 from right
			this.rotation = 90;
			isVertical = true;
			break;
		default:
			assert(false, "Invalid attribute assignment to data-se-direction for a GenericFlower SVG Widget.");
			break;
		}
		let number = Number(SVGWidget.readAttribute(this.element, "seTextOffset", "0"));
		if (number === 0 || number) {			// Text rotation because maybe the SVG itself is rotated (like the stuff with Sandbrock's trains),
			this.textRotation = 90 * number;	// so rotating the text to be the correct orientation would be useful
		}
		else {
			assert(false, "Invalid attribute assignment to data-se-text-offset for a GenericFlower SVG Widget.");
			this.textRotation = 0;				// Sensible default in lack of better option
		}
		this.createParentSVG(isVertical);		// Create the SVG element that will fully contain the flower widget

		// Isomers: because a flower's design might be asymmetrical about both axes(plural of axis) it has an extruded portion
		// that is not shared and so rotating the flower in the four cardinal directions is not enough to get the possible
		// orientation of every flower that one would want. In these case, we might want to "flip" the flower to give a
		// better design than the alternative. Isomer (see https://en.wikipedia.org/wiki/Isomer), which in this case is
		// referring to a stereoisomer (specifically the cis-/trans-isomers), but that is too big of a word. Clocwise (cw)
		// means that the output of the flower is clockwise from the direction noted above. Counterclockwise (ccw) is
		// obviously counter-clockwise of the the direction mentioned above.
		let isomer = SVGWidget.readAttribute(this.element, "seIsomer", "ccw");
		if (isomer == "cw") {
			this.flip = true;
		}
		else if (isomer != "ccw") {
			assert(false, "Custom file erroneous arguments to se-isomer.");
		}
		// Get what the flower is doing based off of the statuses (i.e. running, off, error, out of POR)
		let stati = SVGWidget.readAttributes(this.element, "seStatus_");
		if (Object.keys(stati).length > 1) {
			this.statusLookup = stati;
		}
		// States what the mode means (hand or off or auto, possibly more if more modes are necessary)
		let modeStati = SVGWidget.readAttributes(this.element, "seStatusMode_");
		if (Object.keys(modeStati).length > 1) {
			this.modeStatusLookup = modeStati;
		}
		// An explanation for why the flower is colored the way it is (so as to determine w/o looking at
		// an alarm log whether a flower error is faulted or simply not running in auto) and maybe why a
		// flower is off or running
		let statusExp = SVGWidget.readAttributes(this.element, "seStatusExp_");
        if (Object.keys(statusExp).length > 1) {
            this.statusExpLookup = statusExp;
        }
		this.createStuff();
	}

	getViewBox() {
		assert(false, "Method not implemented!");
	}

    createParentSVG(isVertical) {
		// Need the differences because SVG elements don't like to be disproportionately scaled
		let viewBox = this.getViewBox(isVertical);
		let conformBox = this.element.getBBox();
		conformBox.viewBox = viewBox;

		// Need a parent group for the SVG element because Google Chrome does not like SVG elements having a transform attribute
		// and by not like, I mean it completely ignores it. No errors or warnings, it just does nothing.
		let parentG = createSVGElement('g', null, this.element.parentNode, {transform:(this.element.getAttribute("transform")) ? this.element.getAttribute("transform") : ""});
		this.parentElem = createSVGElement('svg', "generic-flower", parentG, conformBox);
		let defs = createSVGElement('defs', null, this.parentElem, "");
		let pattern = createSVGElement('pattern', null, defs, {id:"oporDiagonalHatch", patternUnits:"userSpaceOnUse", width:20, height:20});
		createSVGElement('path', 'on-state', pattern, {d:"M 0 0 l 5 0 L 0 5 L 0 0 M15 0 l 5 0 l 0 5 L 5 20 l -5 0 l 0 -5 L 15 0 M 20 15 l 0 5 l -5 0 l 5 -5"});
		createSVGElement('path', 'error-state', pattern, {d:"M 5 0 l 10 0 L 0 15 L 0 5 L 5 0 M 20 5 l 0 10 L 15 20 L 5 20 L 20 5"});
		tippy(this.parentElem, {
			position: 'right',
			animation: 'shift',
			duration: 60,
			arrow: true,
			theme: 'light',
			trigger: 'click mouseenter',
			dynamicTitle: true,
		});
    }

	// Creates the flower widget and corresponding text elements in the parent SVG element
	createStuff() {
		assert(false, "Method not implemented!");
	}

	update(node) {
		if (this.node == node) {
			this.setNodeStatus();
		}
		else if (this.modeNode == node) {		// If needed
			this.setModeStuff();
		}
	}

	setModeStuff() {
		assert(false, "Method not implemented!");
	}

	// Figure out what the status for the node will be as well as color the node and set the primary flower text to some
	// notion of speed if possible.
	setNodeStatus() {
		this.status = 0;						// 0 - off, 1 - running, 2 - error, 3 - outside of POR, 4 - Node not found
		let text = "";
		if (this.node && this.node.quality == NodeQuality.NQ_GOOD) {	// Ensure that the node can be read
			let type = this.node.units & 0xFF00;					// Get the node's type
			switch (type) {
			case TagUnitQuantity.TUQ_UNDEFINED:								// If undefined, it is almost certainly an enumerated value
				let val = this.node.getValue();						// Get the value
				let lookup = (this.statusLookup) ?					// Get any status lookup if possible
								this.statusLookup[val.toString()] :
								undefined;
				if (lookup) {										// If we have a lookup, then use it
					let firstChar = lookup.charAt(0);				// As mentioned in docs at start of file,
																	// the first character can be a lower case 'a' or 'n'
					if (firstChar === "n" || firstChar === "a")		// 'a' means in auto, n' means not in auto
						lookup = lookup.substring(1);				// Need to remove the first character from the string
																	// because it is an 'a' or 'n'
					switch (lookup) {								// Extract a status value from the lookup
					case "Error":									// could use raw numbers here instead, but makes
						this.status = 2;							// the custom file more readable
						break;
					case "Off":
						this.status = 0;
						break;
					case "On":
						this.status = 1;
						break;
					case "OPOR":
						this.status = 3;
						break;
					default:
						assert(false, "Illegal argument of data-se-status_" + val + ": " + lookup);
						return;
					}
					if (this.modeText)								// If we have some mode text, set it up
						if (firstChar === "a")
							this.modeText.innerHTML = "auto";
						else if (firstChar === "n"){
							this.modeText.innerHTML = "man";
						}
				}
				else												// If no lookup, use a sensible default
					this.status = (val != 0) ? 1 : 0;
				if (this.statusExpLookup && this.statusExpLookup[val.toString()]) {	// If we have some title information, use it
/*					// This is where using a newer version of Tippy would help. Instead of deleting the first Tippy and
					// creating another with exactly the same properties except the title. Also order is important,
					// as destroying a Tippy sets the title card back to whatever it was before
					if (this.tippyStuff) {
						// Need to pass in the popper and to get the popper, need the selector element for Tippy
						this.tippyStuff.destroy(this.tippyStuff.getPopperElement(this.tippyStuff.selector));
					}
					// Set what the tippy will be
					this.parentElem.setAttribute("title", this.statusExpLookup[val.toString()]);
					// Create a new one with
					this.tippyStuff = Tippy(this.parentElem, {
						position: 'right',
						animation: 'shift',
						duration: 60,
						arrow: true,
						theme: 'light',
						trigger: 'click mouseenter',
						dynamicTitle: true,
					}); */
					this.parentElem.setAttribute("title", this.statusExpLookup[val.toString()]);	// Assume that a Tippy is already created and listening for dynamic title changes
				}
				break;
			case TagUnitQuantity.TUQ_FLOWRATE:								// If a flow rate, want to show the flow rate
				text = this.node.getValue().toString();				// on the flowing element as well as the status
				if (isNaN(this.node.engMax)) {
					this.status = (this.node.getValue() > 0) ? 1 : 0;
				}
				else {
					this.status = (this.node.getValue() >= (this.node.engMax / 5)) ? 1 : 0;
				}
				if (this.node.getValue() > this.node.engMax)
					this.status = 3;
				break;
			case TagUnitQuantity.TUQ_FRACTION:								// If a fraction, get it as a percent
				text = this.node.convertValue(TagUnit.TU_PERCENT);
				this.status = (text >= 20.0) ? 1 : 0;
				if (text > 100.0)
					this.status = 3;
				text = text.toString();
				break;
			default:												// Error: node unit doesn't have the right units
				assert(false, "Custom file indicated that this was a flowing widget, but it does not have the correct units.");
				text = "CFU!";
				this.status = 2;
			}
		}
		else if (this.node) {
			this.status = 2;										// Bad quality, but node found
		}
		else {
			this.status = 4;
			text = "NNF!"
		}
		if (this.path)
			this.path.classList.remove("error-state", "on-state", "off-state", "opor-state");
		let cssClass = "off-state";
		switch (this.status) {										// Fill out the colors
		case 4:
			if (this.path) this.postError("Tag not found: " + this.nodePaths[''], this.path);
		case 0:
			cssClass = "off-state";
			break;
		case 1:
			cssClass = "on-state";
			break;
		case 2:
			cssClass = "error-state";
			break;
		case 3:
			cssClass = "opor-state";					// Don't know how to do striped fills... yet
			break;
		default:
			assert(false, "Massive logic error in SVGWidgets/GenericFlower.js!");
		}
		if (this.path) {
			this.path.classList.add(cssClass);
			this.text.innerHTML = text;
		}
	}
	get path() {
		assert(false, "Method not implemented!");
	}
	get text() {
		assert(false, "Method not implemented!");
	}
};
