import { createElement } from "../elements";
import View from "./view";
import './ovivoreport.css';

// FIXME: Whole lotta similar code between this and the OvivoReport project. See if we can combine into generic graphing methods once you get data
export default class OvivoReport extends View {
    constructor(device, ldc) {
        super();
		this.ldc		= ldc;			// The LiveDataClient
        this.device 	= device;
		const rootNode  = this.device.tree.nodes[0];
        this.nodeTree	= rootNode.tree;	// The system folder node
        this.flowMMF	= 42.00;			// FIXME: One day, get these from a Node
        this.fluxMMF	= 15.34;
        this.controls	= [];
        this.fluxPaths	= [	"/Custom/Train1/MbrA_Flux",	// FIXME: One day, this should work by role
                            "/Custom/Train1/MbrB_Flux",
                            "/Custom/Train2/MbrA_Flux",
                            "/Custom/Train2/MbrB_Flux",
                            "/Custom/Train3/MbrA_Flux",
                            "/Custom/Train3/MbrB_Flux",
                            "/Custom/Train4/MbrA_Flux",
                            "/Custom/Train4/MbrB_Flux",
                            "/Custom/Train5/MbrA_Flux",
                            "/Custom/Train5/MbrB_Flux",
                            "/Custom/Train6/MbrA_Flux",
                            "/Custom/Train6/MbrB_Flux"];
        this.flowPaths = [[	"/Custom/Train1/MbrA_LwrPrmFlow",
                            "/Custom/Train1/MbrA_UprPrmFlow",
                            "/Custom/Train1/MbrB_LwrPrmFlow",
                            "/Custom/Train1/MbrB_UprPrmFlow"],
                        [	"/Custom/Train2/MbrA_LwrPrmFlow",
                            "/Custom/Train2/MbrA_UprPrmFlow",
                            "/Custom/Train2/MbrB_LwrPrmFlow",
                            "/Custom/Train2/MbrB_UprPrmFlow"],
                        [	"/Custom/Train3/MbrA_LwrPrmFlow",
                            "/Custom/Train3/MbrA_UprPrmFlow",
                            "/Custom/Train3/MbrB_LwrPrmFlow",
                            "/Custom/Train3/MbrB_UprPrmFlow"],
                        [	"/Custom/Train4/MbrA_LwrPrmFlow",
                            "/Custom/Train4/MbrA_UprPrmFlow",
                            "/Custom/Train4/MbrB_LwrPrmFlow",
                            "/Custom/Train4/MbrB_UprPrmFlow"],
                        [	"/Custom/Train5/MbrA_LwrPrmFlow",
                            "/Custom/Train5/MbrA_UprPrmFlow",
                            "/Custom/Train5/MbrB_LwrPrmFlow",
                            "/Custom/Train5/MbrB_UprPrmFlow"],
                        [	"/Custom/Train6/MbrA_LwrPrmFlow",
                            "/Custom/Train6/MbrA_UprPrmFlow",
                            "/Custom/Train6/MbrB_LwrPrmFlow",
                            "/Custom/Train6/MbrB_UprPrmFlow"]];
        this.rasPaths = [  [	"/Custom/Train1/RasFlow1",
                                "/Custom/Train1/RasFlow2"],
                            [	"/Custom/Train2/RasFlow1",
                                "/Custom/Train2/RasFlow2"],
                            [	"/Custom/Train3/RasFlow1",
                                "/Custom/Train3/RasFlow2"],
                            [	"/Custom/Train4/RasFlow1",
                                "/Custom/Train4/RasFlow2"],
                            [	"/Custom/Train5/RasFlow1",
                                "/Custom/Train5/RasFlow2"],
                            [	"/Custom/Train6/RasFlow1",
                                "/Custom/Train6/RasFlow2"]];
        this.mlssPaths = [ [	"/Custom/Train1/MbrA_MIss",
                                "/Custom/Train1/MbrB_MIss"],
                            [	"/Custom/Train2/MbrA_MIss",
                                "/Custom/Train2/MbrB_MIss"],
                            [	"/Custom/Train3/MbrA_MIss",
                                "/Custom/Train3/MbrB_MIss"],
                            [	"/Custom/Train4/MbrA_MIss",
                                "/Custom/Train4/MbrB_MIss"],
                            [	"/Custom/Train5/MbrA_MIss",
                                "/Custom/Train5/MbrB_MIss"],
                            [	"/Custom/Train6/MbrA_MIss",
                                "/Custom/Train6/MbrB_MIss"]];
        this.turbidityPaths = [ [	"/Custom/Train1/MbrA_Turbidity",
                                    "/Custom/Train1/MbrB_Turbidity"],
                                [	"/Custom/Train2/MbrA_Turbidity",
                                    "/Custom/Train2/MbrB_Turbidity"],
                                [	"/Custom/Train3/MbrA_Turbidity",
                                    "/Custom/Train3/MbrB_Turbidity"],
                                [	"/Custom/Train4/MbrA_Turbidity",
                                    "/Custom/Train4/MbrB_Turbidity"],
                                [	"/Custom/Train5/MbrA_Turbidity",
                                    "/Custom/Train5/MbrB_Turbidity"],
                                [	"/Custom/Train6/MbrA_Turbidity",
                                    "/Custom/Train6/MbrB_Turbidity"]];
        this.permeabilityPaths = [ ["/Custom/Train1/MbrA_Permeability",
                                    "/Custom/Train1/MbrB_Permeability"],
                                [	"/Custom/Train2/MbrA_Permeability",
                                    "/Custom/Train2/MbrB_Permeability"],
                                [	"/Custom/Train3/MbrA_Permeability",
                                    "/Custom/Train3/MbrB_Permeability"],
                                [	"/Custom/Train4/MbrA_Permeability",
                                    "/Custom/Train4/MbrB_Permeability"],
                                [	"/Custom/Train5/MbrA_Permeability",
                                    "/Custom/Train5/MbrB_Permeability"],
                                [	"/Custom/Train6/MbrA_Permeability",
                                    "/Custom/Train6/MbrB_Permeability"]];

        this.alarmPaths = [	"/Custom/Plant/ScourAirPressureHi",
                            "/Custom/Plant/AcidFlowHi",
                            "/Custom/Plant/AcidWaterFlowHi",
                            "/Custom/Plant/BleachFlowHi",
                            "/Custom/Plant/BleachWaterFlowHi",
                            "/Custom/Plant/InfluentLevelHi",
                            "/Custom/Train1/AnInfluentFlowHi",
                            "/Custom/Train1/AxAirFlow1Hi",
                            "/Custom/Train1/AxAirFlow2Hi",
                            "/Custom/Train1/AxRecycleFlowHi",
                            "/Custom/Train1/MbrA_AirPressureHi",
                            "/Custom/Train1/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train1/MbrA_MIssHi",
                            "/Custom/Train1/MbrA_TMP_Hi",
                            "/Custom/Train1/MbrA_TurbidityHi",
                            "/Custom/Train1/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train1/MbrB_AirPressureHi",
                            "/Custom/Train1/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train1/MbrB_MIssHi",
                            "/Custom/Train1/MbrB_TMP_Hi",
                            "/Custom/Train1/MbrB_TurbidityHi",
                            "/Custom/Train1/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train1/PaAirFlow1Hi",
                            "/Custom/Train1/PaAirFlow2Hi",
                            "/Custom/Train1/RasAirFlow1Hi",
                            "/Custom/Train1/RasAirFlow2Hi",
                            "/Custom/Train1/RasRescreenFlowHi",
                            "/Custom/Train1/AxPump1Moisture",
                            "/Custom/Train1/AxPump1HiTemp",
                            "/Custom/Train1/RasPump1Moisture",
                            "/Custom/Train1/RasPump1HiTemp",
                            "/Custom/Train1/RasPump2Moisture",
                            "/Custom/Train1/RasPump2HiTemp",
                            "/Custom/Train1/MbrA_MissHiHi",
                            "/Custom/Train1/MbrB_MissHiHi",
                            "/Custom/Train1/MbrA_AvgDailyFluxHi",
                            "/Custom/Train1/MbrA_FluxHi",
                            "/Custom/Train1/MbrB_AvgDailyFluxHi",
                            "/Custom/Train1/MbrB_FluxHi",
                            "/Custom/Train2/AnInfluentFlowHi",
                            "/Custom/Train2/AxAirFlow1Hi",
                            "/Custom/Train2/AxAirFlow2Hi",
                            "/Custom/Train2/AxRecycleFlowHi",
                            "/Custom/Train2/MbrA_AirPressureHi",
                            "/Custom/Train2/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train2/MbrA_MIssHi",
                            "/Custom/Train2/MbrA_TMP_Hi",
                            "/Custom/Train2/MbrA_TurbidityHi",
                            "/Custom/Train2/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train2/MbrB_AirPressureHi",
                            "/Custom/Train2/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train2/MbrB_MIssHi",
                            "/Custom/Train2/MbrB_TMP_Hi",
                            "/Custom/Train2/MbrB_TurbidityHi",
                            "/Custom/Train2/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train2/PaAirFlow1Hi",
                            "/Custom/Train2/PaAirFlow2Hi",
                            "/Custom/Train2/RasAirFlow1Hi",
                            "/Custom/Train2/RasAirFlow2Hi",
                            "/Custom/Train2/RasRescreenFlowHi",
                            "/Custom/Train2/AxPump1Moisture",
                            "/Custom/Train2/AxPump1HiTemp",
                            "/Custom/Train2/RasPump1Moisture",
                            "/Custom/Train2/RasPump1HiTemp",
                            "/Custom/Train2/RasPump2Moisture",
                            "/Custom/Train2/RasPump2HiTemp",
                            "/Custom/Train2/MbrA_MissHiHi",
                            "/Custom/Train2/MbrB_MissHiHi",
                            "/Custom/Train2/MbrA_AvgDailyFluxHi",
                            "/Custom/Train2/MbrA_FluxHi",
                            "/Custom/Train2/MbrB_AvgDailyFluxHi",
                            "/Custom/Train2/MbrB_FluxHi",
                            "/Custom/Train3/AnInfluentFlowHi",
                            "/Custom/Train3/AxAirFlow1Hi",
                            "/Custom/Train3/AxAirFlow2Hi",
                            "/Custom/Train3/AxRecycleFlowHi",
                            "/Custom/Train3/MbrA_AirPressureHi",
                            "/Custom/Train3/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train3/MbrA_MIssHi",
                            "/Custom/Train3/MbrA_TMP_Hi",
                            "/Custom/Train3/MbrA_TurbidityHi",
                            "/Custom/Train3/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train3/MbrB_AirPressureHi",
                            "/Custom/Train3/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train3/MbrB_MIssHi",
                            "/Custom/Train3/MbrB_TMP_Hi",
                            "/Custom/Train3/MbrB_TurbidityHi",
                            "/Custom/Train3/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train3/PaAirFlow1Hi",
                            "/Custom/Train3/PaAirFlow2Hi",
                            "/Custom/Train3/RasAirFlow1Hi",
                            "/Custom/Train3/RasAirFlow2Hi",
                            "/Custom/Train3/RasRescreenFlowHi",
                            "/Custom/Train3/AxPump1Moisture",
                            "/Custom/Train3/AxPump1HiTemp",
                            "/Custom/Train3/RasPump1Moisture",
                            "/Custom/Train3/RasPump1HiTemp",
                            "/Custom/Train3/RasPump2Moisture",
                            "/Custom/Train3/RasPump2HiTemp",
                            "/Custom/Train3/MbrA_MissHiHi",
                            "/Custom/Train3/MbrB_MissHiHi",
                            "/Custom/Train3/MbrA_AvgDailyFluxHi",
                            "/Custom/Train3/MbrA_FluxHi",
                            "/Custom/Train3/MbrB_AvgDailyFluxHi",
                            "/Custom/Train3/MbrB_FluxHi",
                            "/Custom/Train4/AnInfluentFlowHi",
                            "/Custom/Train4/AxAirFlow1Hi",
                            "/Custom/Train4/AxAirFlow2Hi",
                            "/Custom/Train4/AxRecycleFlowHi",
                            "/Custom/Train4/MbrA_AirPressureHi",
                            "/Custom/Train4/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train4/MbrA_MIssHi",
                            "/Custom/Train4/MbrA_TMP_Hi",
                            "/Custom/Train4/MbrA_TurbidityHi",
                            "/Custom/Train4/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train4/MbrB_AirPressureHi",
                            "/Custom/Train4/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train4/MbrB_MIssHi",
                            "/Custom/Train4/MbrB_TMP_Hi",
                            "/Custom/Train4/MbrB_TurbidityHi",
                            "/Custom/Train4/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train4/PaAirFlow1Hi",
                            "/Custom/Train4/PaAirFlow2Hi",
                            "/Custom/Train4/RasAirFlow1Hi",
                            "/Custom/Train4/RasAirFlow2Hi",
                            "/Custom/Train4/RasRescreenFlowHi",
                            "/Custom/Train4/AxPump1Moisture",
                            "/Custom/Train4/AxPump1HiTemp",
                            "/Custom/Train4/RasPump1Moisture",
                            "/Custom/Train4/RasPump1HiTemp",
                            "/Custom/Train4/RasPump2Moisture",
                            "/Custom/Train4/RasPump2HiTemp",
                            "/Custom/Train4/MbrA_MissHiHi",
                            "/Custom/Train4/MbrB_MissHiHi",
                            "/Custom/Train4/MbrA_AvgDailyFluxHi",
                            "/Custom/Train4/MbrA_FluxHi",
                            "/Custom/Train4/MbrB_AvgDailyFluxHi",
                            "/Custom/Train4/MbrB_FluxHi",
                            "/Custom/Train5/AnInfluentFlowHi",
                            "/Custom/Train5/AxAirFlow1Hi",
                            "/Custom/Train5/AxAirFlow2Hi",
                            "/Custom/Train5/AxRecycleFlowHi",
                            "/Custom/Train5/MbrA_AirPressureHi",
                            "/Custom/Train5/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train5/MbrA_MIssHi",
                            "/Custom/Train5/MbrA_TMP_Hi",
                            "/Custom/Train5/MbrA_TurbidityHi",
                            "/Custom/Train5/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train5/MbrB_AirPressureHi",
                            "/Custom/Train5/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train5/MbrB_MIssHi",
                            "/Custom/Train5/MbrB_TMP_Hi",
                            "/Custom/Train5/MbrB_TurbidityHi",
                            "/Custom/Train5/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train5/PaAirFlow1Hi",
                            "/Custom/Train5/PaAirFlow2Hi",
                            "/Custom/Train5/RasAirFlow1Hi",
                            "/Custom/Train5/RasAirFlow2Hi",
                            "/Custom/Train5/RasRescreenFlowHi",
                            "/Custom/Train5/AxPump1Moisture",
                            "/Custom/Train5/AxPump1HiTemp",
                            "/Custom/Train5/RasPump1Moisture",
                            "/Custom/Train5/RasPump1HiTemp",
                            "/Custom/Train5/RasPump2Moisture",
                            "/Custom/Train5/RasPump2HiTemp",
                            "/Custom/Train5/MbrA_MissHiHi",
                            "/Custom/Train5/MbrB_MissHiHi",
                            "/Custom/Train5/MbrA_AvgDailyFluxHi",
                            "/Custom/Train5/MbrA_FluxHi",
                            "/Custom/Train5/MbrB_AvgDailyFluxHi",
                            "/Custom/Train5/MbrB_FluxHi",
                            "/Custom/Train6/AnInfluentFlowHi",
                            "/Custom/Train6/AxAirFlow1Hi",
                            "/Custom/Train6/AxAirFlow2Hi",
                            "/Custom/Train6/AxRecycleFlowHi",
                            "/Custom/Train6/MbrA_AirPressureHi",
                            "/Custom/Train6/MbrA_LwrPrmTMP_Hi",
                            "/Custom/Train6/MbrA_MIssHi",
                            "/Custom/Train6/MbrA_TMP_Hi",
                            "/Custom/Train6/MbrA_TurbidityHi",
                            "/Custom/Train6/MbrA_UprPrmTMP_Hi",
                            "/Custom/Train6/MbrB_AirPressureHi",
                            "/Custom/Train6/MbrB_LwrPrmTMP_Hi",
                            "/Custom/Train6/MbrB_MIssHi",
                            "/Custom/Train6/MbrB_TMP_Hi",
                            "/Custom/Train6/MbrB_TurbidityHi",
                            "/Custom/Train6/MbrB_UprPrmTMP_Hi",
                            "/Custom/Train6/PaAirFlow1Hi",
                            "/Custom/Train6/PaAirFlow2Hi",
                            "/Custom/Train6/RasAirFlow1Hi",
                            "/Custom/Train6/RasAirFlow2Hi",
                            "/Custom/Train6/RasRescreenFlowHi",
                            "/Custom/Train6/AxPump1Moisture",
                            "/Custom/Train6/AxPump1HiTemp",
                            "/Custom/Train6/RasPump1Moisture",
                            "/Custom/Train6/RasPump1HiTemp",
                            "/Custom/Train6/RasPump2Moisture",
                            "/Custom/Train6/RasPump2HiTemp",
                            "/Custom/Train6/MbrA_MissHiHi",
                            "/Custom/Train6/MbrB_MissHiHi",
                            "/Custom/Train6/MbrA_AvgDailyFluxHi",
                            "/Custom/Train6/MbrA_FluxHi",
                            "/Custom/Train6/MbrB_AvgDailyFluxHi",
                            "/Custom/Train6/MbrB_FluxHi"];

        this.addControl("/Custom/Setpoints/PaDO", [	"/Custom/Train1/PaDO1",	// See how close the setpoint was to the process variable
                                                    "/Custom/Train1/PaDO2",
                                                    "/Custom/Train2/PaDO1",
                                                    "/Custom/Train2/PaDO2",
                                                    "/Custom/Train3/PaDO1",
                                                    "/Custom/Train3/PaDO2",
                                                    "/Custom/Train4/PaDO1",
                                                    "/Custom/Train4/PaDO2",
                                                    "/Custom/Train5/PaDO1",
                                                    "/Custom/Train5/PaDO2",
                                                    "/Custom/Train6/PaDO1",
                                                    "/Custom/Train6/PaDO2"]);
        this.addControl("/Custom/Setpoints/RasFlow", [	"/Custom/Train1/RasFlow1",	// See how close the setpoint was to the process variable
                                                        "/Custom/Train1/RasFlow2",
                                                        "/Custom/Train2/RasFlow1",
                                                        "/Custom/Train2/RasFlow2",
                                                        "/Custom/Train3/RasFlow1",
                                                        "/Custom/Train3/RasFlow2",
                                                        "/Custom/Train4/RasFlow1",
                                                        "/Custom/Train4/RasFlow2",
                                                        "/Custom/Train5/RasFlow1",
                                                        "/Custom/Train5/RasFlow2",
                                                        "/Custom/Train6/RasFlow1",
                                                        "/Custom/Train6/RasFlow2"]);
        this.addControl("/Custom/Train1/MbrA_AirFlowSP", ["/Custom/Train1/MbrA_AirFlow"]);
        this.addControl("/Custom/Train1/MbrB_AirFlowSP", ["/Custom/Train1/MbrB_AirFlow"]);
        this.addControl("/Custom/Train2/MbrA_AirFlowSP", ["/Custom/Train2/MbrA_AirFlow"]);
        this.addControl("/Custom/Train2/MbrB_AirFlowSP", ["/Custom/Train2/MbrB_AirFlow"]);
        this.addControl("/Custom/Train3/MbrA_AirFlowSP", ["/Custom/Train3/MbrA_AirFlow"]);
        this.addControl("/Custom/Train3/MbrB_AirFlowSP", ["/Custom/Train3/MbrB_AirFlow"]);
        this.addControl("/Custom/Train4/MbrA_AirFlowSP", ["/Custom/Train4/MbrA_AirFlow"]);
        this.addControl("/Custom/Train4/MbrB_AirFlowSP", ["/Custom/Train4/MbrB_AirFlow"]);
        this.addControl("/Custom/Train5/MbrA_AirFlowSP", ["/Custom/Train5/MbrA_AirFlow"]);
        this.addControl("/Custom/Train5/MbrB_AirFlowSP", ["/Custom/Train5/MbrB_AirFlow"]);
        this.addControl("/Custom/Train6/MbrA_AirFlowSP", ["/Custom/Train6/MbrA_AirFlow"]);
        this.addControl("/Custom/Train6/MbrB_AirFlowSP", ["/Custom/Train6/MbrB_AirFlow"]);

        this.addControl("/Custom/Train1/MbrA_UprPrmFlowSP", ["/Custom/Train1/MbrA_UprPrmFlow",
                                                            "/Custom/Train1/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train1/MbrB_UprPrmFlowSP", ["/Custom/Train1/MbrB_UprPrmFlow",
                                                            "/Custom/Train1/MbrB_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train2/MbrA_UprPrmFlowSP", ["/Custom/Train2/MbrA_UprPrmFlow",
                                                            "/Custom/Train2/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train2/MbrB_UprPrmFlowSP", ["/Custom/Train2/MbrB_UprPrmFlow",
                                                            "/Custom/Train2/MbrB_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train3/MbrA_UprPrmFlowSP", ["/Custom/Train3/MbrA_UprPrmFlow",
                                                            "/Custom/Train3/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train3/MbrB_UprPrmFlowSP", ["/Custom/Train3/MbrB_UprPrmFlow",
                                                            "/Custom/Train3/MbrB_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train4/MbrA_UprPrmFlowSP", ["/Custom/Train4/MbrA_UprPrmFlow",
                                                            "/Custom/Train4/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train4/MbrB_UprPrmFlowSP", ["/Custom/Train4/MbrB_UprPrmFlow",
                                                            "/Custom/Train4/MbrB_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train5/MbrA_UprPrmFlowSP", ["/Custom/Train5/MbrA_UprPrmFlow",
                                                            "/Custom/Train5/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train5/MbrB_UprPrmFlowSP", ["/Custom/Train5/MbrB_UprPrmFlow",
                                                            "/Custom/Train5/MbrB_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train6/MbrA_UprPrmFlowSP", ["/Custom/Train6/MbrA_UprPrmFlow",
                                                            "/Custom/Train6/MbrA_LwrPrmFlow"], 0.8);
        this.addControl("/Custom/Train6/MbrB_UprPrmFlowSP", ["/Custom/Train6/MbrB_UprPrmFlow",
                                                            "/Custom/Train6/MbrB_LwrPrmFlow"], 0.8);
        // FIXME: Specific control checks
        //StringVector flowsForControlChecks = {	"/Custom/Train1/MbrA_LwrPrmFlow",
        //"/Custom/Train1/MbrB_LwrPrmFlow",
        //"/Custom/Train2/MbrA_LwrPrmFlow",
        //"/Custom/Train2/MbrB_LwrPrmFlow",
        //"/Custom/Train3/MbrA_LwrPrmFlow",
        //"/Custom/Train3/MbrB_LwrPrmFlow",
        //"/Custom/Train4/MbrA_LwrPrmFlow",
        //"/Custom/Train4/MbrB_LwrPrmFlow",
        //"/Custom/Train5/MbrA_LwrPrmFlow",
        //"/Custom/Train5/MbrB_LwrPrmFlow"};
        //checkPermControl(10, {	"/Custom/Train1/MbrA_Permeability",
        //"/Custom/Train1/MbrB_Permeability",
        //"/Custom/Train2/MbrA_Permeability",
        //"/Custom/Train2/MbrB_Permeability",
        //"/Custom/Train3/MbrA_Permeability",
        //"/Custom/Train3/MbrB_Permeability",
        //"/Custom/Train4/MbrA_Permeability",
        //"/Custom/Train4/MbrB_Permeability",
        //"/Custom/Train5/MbrA_Permeability",
        //"/Custom/Train5/MbrB_Permeability"}, flowsForControlChecks);
        //checkTmpControl(2.5, {	"/Custom/Train1/MbrA_LwrPrmTMP",
        //"/Custom/Train1/MbrB_LwrPrmTMP",
        //"/Custom/Train2/MbrA_LwrPrmTMP",
        //"/Custom/Train2/MbrB_LwrPrmTMP",
        //"/Custom/Train3/MbrA_LwrPrmTMP",
        //"/Custom/Train3/MbrB_LwrPrmTMP",
        //"/Custom/Train4/MbrA_LwrPrmTMP",
        //"/Custom/Train4/MbrB_LwrPrmTMP",
        //"/Custom/Train5/MbrA_LwrPrmTMP",
        //"/Custom/Train5/MbrB_LwrPrmTMP",}, flowsForControlChecks);
        //checkRasControl({	"/Custom/Train1/RasFlow1",
        //"/Custom/Train1/RasFlow2",
        //"/Custom/Train2/RasFlow1",
        //"/Custom/Train2/RasFlow2",
        //"/Custom/Train3/RasFlow1",
        //"/Custom/Train3/RasFlow2",
        //"/Custom/Train4/RasFlow1",
        //"/Custom/Train4/RasFlow2",
        //"/Custom/Train5/RasFlow1",
        //"/Custom/Train5/RasFlow2"}, flowsForControlChecks);
    };

	initialize(parent) {
		super.initialize(parent);
		this.graphID	= this.ldc.registerGraph(this);							// Register for graph data
		this.wrapper 	= createElement('div', 'ovivo__wrapper', this.parent);					// Overall wrapper
		this.container 	= createElement('div', 'ovivo__container', this.wrapper);
		var historical 	= createElement('div', null, this.container);					// Create a date input to select the time this operates on
		createElement('div', 'OvivoReportHeader', this.container, 'Weekly MBR Report');	// Create a title for the division
		this.date			= createElement('input', 'savingsInput', historical);	// Input element that defines the day
		this.date.type		= 'date';											// A date, meaning no time selection is possible
		this.date.required	= true;												// Required to get rid of HTML's weird x's
		var date = new Date();
		date.setHours(0, 0, 0, 0);												// Set the date to the most recent midnight
		date.setDate(date.getDate() - 1);										// And the day before.
		this.date.value		= date.format('%yyyy-%MM-%dd');						// Set the date to the input object
		this.date.max		= this.date.valueAsNumber;
		this.date.oninput 	= this.onInput.bind(this);
		this.utcOffset	= date.getTimezoneOffset()*60*1000;						// Calculate the time zone offset in milliseconds once
		this.report = createElement('div', null, this.container);
		this.createReport();
		this.fInitialized = true;
		return this;
	}

	onInput() {
		this.date.valueAsNumber = Math.min(this.date.valueAsNumber, this.date.max);
		if (this.timerID !== undefined)	// If the is a timer running
			clearTimeout(this.timerID);	// Clear it
		this.timerID = setTimeout(this.onInputHitch.bind(this), 300);	// Delay requesting data for a 0.3 seconds incase the button is rapidly chaning
	}

	onInputHitch() {
		this.report.removeChildren();	// Delete any DOM elements left over
		this.createReport();
	}

	createReport() {
		this.queryData(7, ["/Custom/Plant/InfluentFlow"], 60);

		var firstRow = createElement('div', 'OvivoReportRow', this.report);
		this.influentDiv = createElement('div', 'OvivoReportInfluent', firstRow);
		this.monthFluxDiv = createElement('div', 'OvivoReportMonthFlux', firstRow);

		this.dailyFluxDiv = createElement('div', 'OvivoReportRow', this.report);
		this.fluxLeg = createElement('div', 'OvivoReportLegend', this.report);	// Create another row wrapper so each graph can have a legend

		var thirdRow = createElement('div', 'OvivoReportRow', this.report);
		this.mlssDiv = createElement('div', 'OvivoReportGraph', thirdRow);
		this.turbDiv = createElement('div', 'OvivoReportGraph', thirdRow);

		var fourthRow = createElement('div', 'OvivoReportRow', this.report);
		this.permDiv = createElement('div', 'OvivoReportGraph', fourthRow);

		this.controlTable = createElement('div', 'OvivoReportTable', this.report);		// Create a table wrapper
		this.tagColumn = createElement('div', 'OvivoReportColumn', this.controlTable);	// Create a column for tag names
		createElement('div', 'OvivoReportHeaderCell', this.tagColumn, 'Parameter');		// Create a column header for the tag names

		var alarmTable = createElement('div', 'OvivoReportTable', this.report);		// Create a table of alarms
		this.alarmColumn = createElement('div', 'OvivoReportColumn', alarmTable);	// Node name column
		this.countColumn = createElement('div', 'OvivoReportColumn', alarmTable);	// Count column
		this.occurColumn = createElement('div', 'OvivoReportColumn', alarmTable);	// Last occurence column
		createElement('div', 'OvivoReportHeaderCell', this.alarmColumn, 'Alarm');	// Headers for each column
		createElement('div', 'OvivoReportHeaderCell', this.countColumn, 'Count');
		createElement('div', 'OvivoReportHeaderCell', this.occurColumn, 'Last Occurrence');
	}

	addControl(sp, pvs, min, name, call) {
		this.controls.push({sp: sp, pvs: pvs, min:min, name:name, call:call});
	}

	queryControls() {
		var n = [];
		for (var i = 0; i < this.controls.length; ++i) {
			n.push(this.controls[i].sp);
			n = n.concat(this.controls[i].pvs);
		}
		this.queryData(7, n, 60);
		this.requested = this.controls;
	}

	queryData(days, nodes, interval) {
		var date = new Date(this.date.valueAsNumber + this.utcOffset);	// Start of last day
		date.setDate(date.getDate() + 1);
		this.end = owner.timeZone.toUTC(date);	// End of last day
		date.setDate(date.getDate() - days);	// Start of first day
		this.start = owner.timeZone.toUTC(date);
		var graphNodes = [], starts = [], ends = [];
		for (var i = 0; i < nodes.length; ++i) {
			graphNodes.push({path: nodes[i], node: this.device.tree.findNode(nodes[i])});
			starts.push(this.start);
			ends.push(this.end);
		}
		this.requested = nodes;
		this.days = days;
		this.ldc.getGraphData(this.graphID, this.device.id, interval, starts, ends, graphNodes, true);
	}

	queryVectorData(days, checks, nodes, interval) {
		var n = [];
		for (var i = 0; i < nodes.length; ++i) {
			if (checks)
				n = n.concat(checks[i]);
			n = n.concat(nodes[i]);
		}
		this.queryData(days, n, interval);
		this.requested = nodes;
	}

	onGraphDataResponse(interval, data) {	// We got historical data back
		if (data[0].length == 1 && interval == 60) {
			this.createInfluentGraph(interval, data);
			this.queryData(30, this.fluxPaths, 3600);
		} else if (interval == 3600 && this.requested === this.fluxPaths) {
			this.createDailyFluxGraph(interval, data);
			this.queryVectorData(7, null, this.flowPaths, 60);
		} else if (interval == 60 && this.requested === this.flowPaths) {
//			this.createDailyPermeateGraph(interval, data);
			this.createFluxRateGraph(interval, data);
			this.queryVectorData(7, this.rasPaths, this.mlssPaths, 60);
		} else if (interval == 60 && this.requested == this.mlssPaths) {
			this.createWhiskerGraph(interval, data, this.mlssDiv, this.mlssPaths, this.rasPaths, 5, 'MLSS Profile', 'mg/L', [5000, 15000], 50, 30);
			this.queryVectorData(7, this.rasPaths, this.turbidityPaths, 60);
		} else if (interval == 60 && this.requested == this.turbidityPaths) {
			this.createWhiskerGraph(interval, data, this.turbDiv, this.turbidityPaths, this.rasPaths, 5, 'Turbidity Profile', 'NTU', [0,0.5], 40, 30);
			this.queryVectorData(7, this.flowPaths, this.permeabilityPaths, 60);
		} else if (interval == 60 && this.requested == this.permeabilityPaths) {
			this.createWhiskerGraph(interval, data, this.permDiv, this.permeabilityPaths, this.flowPaths, 0.1, 'Permeability Profile', 'GFD/psi', null, 40, 30);
			this.queryControls();
		} else if (interval == 60 && this.requested == this.controls) {
			this.createControlTable(interval, data);
			for (const alarmPath of this.alarmPaths)	// Query each alarm path individually so we don't ask for >1M continuum points at once
				this.queryData(7, [alarmPath], 1);
		} else if (interval == 1 && this.alarmPaths.includes(this.requested[0])) {
			this.createAlarmTable(interval, data);
		}
	}

	setUpDates() {
		this.starts = [];
		var date = new Date(this.date.valueAsNumber + this.utcOffset);
		for (var i = 0; i < this.days; ++i) {
			this.starts.unshift(owner.timeZone.toUTC(date)*1000);
			date.setDate(date.getDate() - 1);
		}
		this.ends = this.starts.slice(1);	// Slice all but the first guy
		date = new Date(this.date.valueAsNumber + this.utcOffset);
		date.setDate(date.getDate() + 1);
		this.ends.push(owner.timeZone.toUTC(date)*1000);
	}

	getValue(time, i, data, indexes, nullValue) {
		var setTimes	= data[1+4*i];		// Get the time column
		var setData		= data[3+4*i];		// Get the average column
		while(setTimes[indexes[i] + 1] < time && indexes[i] < setTimes.length - 2)	// Keep looking through the rows until we find the correct period
			++indexes[i];
		if (setData.length < 2 || setData[indexes[i]] === null || setData[indexes[i] + 1] === null)	// If either side is a null, this guy is a null
			return nullValue;
		else								// Else interpolate between the values on either side
			return setData[indexes[i]] + (setData[indexes[i] + 1] - setData[indexes[i]]) * (time - setTimes[indexes[i]]) / (setTimes[indexes[i]+1] - setTimes[indexes[i]]);
	}

	createInfluentGraph(interval, data) {
		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var dataSets = [];
		for (var day = 0; day < this.starts.length; ++day) {
			var linearized = [];
			for (var time = this.starts[day]; time < this.ends[day]; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
				for (var i = 0; i < indexes.length; ++i) 	// For each node we queried
					linearized.push(this.getValue(time, i, data, indexes, null));	// Get the value
			}
			dataSets.push(linearized);
		}
		var minutes = [], avgs = [];					// To hold the times and the average profile
		for (var i = 0; i < 60*24; ++i) {				// For each minute in a day
			minutes.push(this.starts.back() + i*60*1000);	// Create a timestamp that reflects the time for the last day of the report
			var avg = 0;								// Intialize average
			for (var j = 0; j < 7; ++j)					// For each profile
				avg += dataSets[j][i];					// Accrue sum
			avgs.push(avg/7);							// Save the average
		}
		var graphData = [[]];			// Our dygraph data
		for (var i = 0; i < 7; ++i) {
			graphData[0].push(i.toString());					// Add each profile
			dataSets[i].length = minutes.length;				// DST fix so arrays are same length
			graphData.push(minutes, null, dataSets[i], null);	// Put in each profile
		}
		graphData[0].push('Avg', 'MMF');		// Add our average last so it is drawn on top
		graphData.push(minutes, null, avgs, null, [minutes[0], minutes.back()], null, [this.flowMMF, this.flowMMF], null);
		new Dygraph(this.influentDiv, graphData, {	// Create the graph
			colors:					['#000', '#000', '#000', '#000', '#000', '#000', '#000', 'blue', 'green'],	// Each profile line is black, the average is blue
			drawY2Line:				true,
			stophighlighting:		true,
			includeZero:			false,
			title:					'Influent Flow Profile',
			ylabel:					'Flow (MGD)',
			alpha:					0.2,				// Make the profile lines famt
			Avg:					{alpha: 1, strokeWidth: 2.5},	// But make the average thick and bold
			MMF:					{alpha: 1, strokeWidth: 2.5},	// But make the average thick and bold
			titleHeight:			25,
			connectSeparatedPoints:	true,				// Draw lines
			pointSize:				0,					// Don't draw points
			axisLineWidth:			0.6,				// Make axis lines a little thicker
			gridLineWidth:			0.6,				// Make grid lines a little thicker
			minorYLines:			3,					// 0 horizontal minor lines between major lines
			minorXLines:			1,					// 0 vertical minor lines between major lines
			xLabelHeight:			15,					// Pixel size of any x axis title we add on
			yLabelWidth:			15,					// Pixel size of any y axis title we add on
			xAxisLabelWidth:		50,					// Give the x axis labels enough room to be legible
			yAxisLabelWidth:		30,
			strokeWidth:			1,
			digitsAfterDecimal:		1,
			axes:					{x: {pixelsPerLabel: 30}},
			axisLabelFontSize:		12
		});
	}

	createDailyFluxGraph(interval, data) {
		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var avgs = [];
		var maxFlux = 1E-6, minFlux = 1E6, avgFlux = 0;	// Find the max and min flux totals
		for (var day = 0; day < this.starts.length; ++day) {
			var dailyTotals = new Array(indexes.length).fill(0);
			for (var time = this.starts[day]; time < this.ends[day]; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
				for (var i = 0; i < indexes.length; ++i) 	// For each node we queried
					dailyTotals[i] += this.getValue(time, i, data, indexes, 0);	// Get the value
			}
			var avg = 0, count = 0;
			for (var i = 0; i < indexes.length; ++i) {
				if (dailyTotals[i] < 1)
					continue;
				avg += dailyTotals[i];
				++count;
			}
			if (count > 0) {
				avg /= count;
				avgs.push(avg / ((this.ends[day] - this.starts[day]) / 1000 / 3600));
				avgFlux += avgs.back();
				maxFlux = Math.max(maxFlux, avgs.back());
				minFlux = Math.min(minFlux, avgs.back());
			}
		}
		avgs.sort(function(a,b){return a < b ? -1 : 1;});
		var lowQuart = avgs[Math.floor(avgs.length / 4)];
		var highQuart = avgs[Math.floor(3 * avgs.length / 4)];
		var fluxProfile = [{min: minFlux, max: maxFlux, mean:avgFlux / avgs.length, lowQuart: lowQuart, highQuart: highQuart}];
		this.drawWhiskerGraph(this.monthFluxDiv, [this.starts.back()], fluxProfile, '30-Day Flux Profile', 'Total Daily Flux (GFD)', null, 35, 500, true, this.fluxMMF);
	}

	createFluxRateGraph(interval, data) {
		var area = 228196.81;
		var min = 1;
		var mmf = this.fluxMMF;
		var pdf = 32.14;
		var pif = 38;

		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var fluxColors = ['gray', 'lightgreen', 'darkgreen', 'orange', 'red'];	// Colors for each profile section
		var fluxNames = ['Low', 'ADF', 'MMF', 'PDF', 'PIF'];
		var fluxProf = [[]];
		var fluxSets = [];
		for (var j = 0; j < this.flowPaths.length; ++j) {
			var graphData = [[],[],[],[],[]];
			fluxSets.push(graphData);
			for (var day = 0; day < this.starts.length; ++day) {
				var lowMin = 0, adfMin = 0, mmfMin = 0, pdfMin = 0, pifMin = 0;	// Initialize each day total to 0
				for (var time = this.starts[day]; time < this.ends[day]; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
					var i = j * 4;
					var totalPrm = 0;						// Have to sum permeate flow over each running MBR
					var totalArea = 0;						// have to sum available area over each running MBR
					for (var l = 0; l < 2; ++l) {			// TODO: brittle, dude
						var mbrPrm = 0;
						for (var k = 0; k < 2; ++k, ++i)					// TODO: brittle, dude
							mbrPrm += this.getValue(time, i, data, indexes, 0);
						if (mbrPrm > 1)	// If the MBR was on for the minute
						{
							totalPrm += mbrPrm;	// Add the permeate flow total
							totalArea += area;	// Add in the area for this MBR
						}
					}
					var flux = totalArea > 0 ? totalPrm * 1000000 / totalArea : 0;	// Calculate total flux (converting MGD to Gallons)
					if (flux < min)			// If flux is super low
						++lowMin;			// Intermittent
					else if (flux < mmf)	// Less than MMF
						++adfMin;
					else if (flux < pdf)	// Less than PDF
						++mmfMin;
					else if (flux < pif)	// Less than PIF
						++pdfMin;
					else					// Super high
						++pifMin;
				}
				graphData[0].push(lowMin);
				graphData[1].push(adfMin);
				graphData[2].push(mmfMin);
				graphData[3].push(pdfMin);
				graphData[4].push(pifMin);
			}
		}

		// Create the daily flux breakdown
		var width = -5 * fluxSets.length + 40;
		var gap = (699/7 - fluxSets.length*width) / (2+0.5*(fluxSets.length - 1));
		var gapHours = gap * 24 * 7 / 699;
		var widthHours = width * 24 * 7 / 699;
		for (var k = 0; k < fluxSets.length; ++k) {
			var set = fluxSets[k];
			var dates = [], bargraphs = [[], [], [], [], [], [], [], [], [], []];
			for (var i = 0; i < this.starts.length; ++i) {	// For each day we were given
				dates.push(this.starts[i] + (-12 + gapHours + widthHours/2 + (gapHours/2 + widthHours)*k)*3600*1000);
				var val = 0;									// Initalize value to zero so data stacks
				for (var j = 0; j < fluxNames.length; ++j) {	// For each name
					bargraphs[j*2 + 0].push(val);				// Put the previous value in the appropriate minimum array
					val += set[j][i];							// Add in the value of this flow regime
					bargraphs[j*2 + 1].push(val);				// Put the new value in the appropriate average array
				}
			}
			for (var i = 0; i < fluxNames.length; ++i) {
				fluxProf[0].push(fluxNames[i] + k);
				fluxProf.push(dates, bargraphs[i*2 + 0], bargraphs[i*2 + 1], null);
			}
		}
		for (var j = 0; j < fluxNames.length; ++j)
			createElement('div', 'OvivoReportLegendEntry', this.fluxLeg, fluxNames[j]).style.color = fluxColors[j];	// Create a legend entry for each flow regime
		var fluxGraph = new Dygraph(this.dailyFluxDiv, fluxProf, {				// Create the stacked bargraph
			drawY2Line:				true,
			stophighlighting:		true,
			includeZero:			true,
			colors:					fluxColors,
			title:					'Daily Flux Profile',
			ylabel:					'Minutes',
			dateWindow:				[this.starts[0] - 12*3600*1000, this.starts.back() + 12*3600*1000],	// Put 12 hours on each side of the graph
			valueRange:				[0,1440],
			titleHeight:			25,
			connectSeparatedPoints:	false,
			bargraph:				true,
			bargraphWidth:			width,
			drawPoints:				false,
			axisLineWidth:			0.6,				// Make axis lines a little thicker
			gridLineWidth:			0.6,				// Make grid lines a little thicker
			minorYLines:			3,					// 0 horizontal minor lines between major lines
			minorXLines:			1,					// 0 vertical minor lines between major lines
			xLabelHeight:			15,					// Pixel size of any x axis title we add on
			yLabelWidth:			15,					// Pixel size of any y axis title we add on
			xAxisLabelWidth:		40,					// Give the x axis labels enough room to be legible
			yAxisLabelWidth:		40,
			axes:					{x: {pixelsPerLabel: 30}, y: {pixelsPerLabel: 25}},
			axisLabelFontSize:		12
		});

		indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var avgs = [];
		for (var day = 0; day < this.starts.length; ++day) {
			var dailyTotals = new Array(fluxSets.length).fill(0);
			for (var time = this.starts[day]; time < this.ends[day]; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
				var i = 0;
				for (var j = 0; j < this.flowPaths.length; ++j) {
					for (var k = 0; k < this.flowPaths[j].length; ++k, ++i)
						dailyTotals[j] += this.getValue(time, i, data, indexes, 0);	// Get the value
				}
			}
			for (var j = 0; j < fluxSets.length; ++j) {
				dailyTotals[j] /= ((this.ends[day] - this.starts[day]) / 1000 / 60);
				var e = createElement('div', 'OvivoLabelWrapper', this.dailyFluxDiv);
				var d = fluxGraph.toDomXCoord(fluxProf[j*20+1][day]);
				e.style.left = (d-5) + 'px';
				// We need this style line because the verison of phantom.js on whoville is pretty old
				createElement('div', 'OvivoLabel', e, dailyTotals[j].toFixed(2) + ' MG').style.WebkitTransform = 'rotate(-90deg)';  // Safari/Chrome
			}
		}
	}

	drawWhiskerGraph(div, dates, whiskerData, title, yLabel, maxes, yAxisLabelWidth, xAxisPixelsPerLabel, fWhiskers, line) {
		var vertDates = [];
		for (var i = 0; i < dates.length; ++i)
			vertDates.push(dates[i], dates[i], dates[i]);

		var graphData = [['vert', 'min', 'max', 'bar', 'mean'],
		                 vertDates, null, [], null,	// vert
		                 dates, [], [], null,		// min
		                 dates, [], [], null,		// max
		                 dates, [], [], null,		// bar
		                 dates, [], [], null];		// mean
		var colors = ['black', 'black', 'black', 'blue', 'cyan', 'green'];
		for (var k = 0; k < whiskerData.length; ++k) {		// For each day
			var day = whiskerData[k];					// Convenience reference to the profile data
			graphData[3].push(day.min, day.max, null);

			graphData[6].push(day.min);
			graphData[7].push(day.min);

			graphData[10].push(day.max);
			graphData[11].push(day.max);

			graphData[14].push(day.lowQuart);
			graphData[15].push(day.highQuart);

			graphData[18].push(day.mean);
			graphData[19].push(day.mean);
		}
		if (!fWhiskers) {
			graphData[0].splice(0, 3);
			graphData.splice(1, 12);
			colors.splice(0, 3);
		}
		var dateWindow = [dates[0] - 12*3600*1000, dates.back() + 12*3600*1000];	// Put 12 hours on each side of the graph
		if (line) {
			graphData[0].push('MMF');
			graphData.push(dateWindow, null, [line, line], null);
		}

		new Dygraph(div, graphData, {	// Create the profile graph
			drawY2Line:				true,
			stophighlighting:		true,
			colors:					colors,
			title:					title,
			ylabel:					yLabel,
			dateWindow:				dateWindow,
			valueRange:				maxes,
			titleHeight:			25,
			connectSeparatedPoints:	false,
			includeZero:			true,
			bargraph:				true,
			bargraphWidth:			20,
			vert:					{bargraph: false, strokeWidth: 2, connectSeparatedPoints: true},
			MMF:					{bargraph: false, strokeWidth: 2, connectSeparatedPoints: true},
			drawPoints:				false,
			axisLineWidth:			0.6,				// Make axis lines a little thicker
			gridLineWidth:			0.6,				// Make grid lines a little thicker
			minorYLines:			3,					// 0 horizontal minor lines between major lines
			minorXLines:			1,					// 0 vertical minor lines between major lines
			xLabelHeight:			15,					// Pixel size of any x axis title we add on
			yLabelWidth:			15,					// Pixel size of any y axis title we add on
			xAxisLabelWidth:		40,					// Give the x axis labels enough room to be legible
			yAxisLabelWidth:		yAxisLabelWidth,
			axes:					{x: {pixelsPerLabel: xAxisPixelsPerLabel}},
			axisLabelFontSize:		12
		});
    }

	createWhiskerGraph(interval, data, div, dataPaths, checkPaths, limit, title, yLabel, maxes, yAxisLabelWidth, xAxisPixelsPerLabel) {
		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var stats = [];
		for (var day = 0; day < this.starts.length; ++day) {
			var vals = [], avg = 0;	// All points and daily average
			for (var time = this.starts[day]; time < this.ends[day]; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
				var i = 0;
				for (var k = 0; k < dataPaths.length; ++k) {
					var totalCheck = 0;
					for (var j = 0; j < checkPaths[k].length; ++j, ++i) {
						totalCheck += this.getValue(time, i, data, indexes, 0);
					}
					var fTrainActive = totalCheck > limit;
					for (var j = 0; j < dataPaths[k].length; ++j, ++i) {
						if (!fTrainActive)	// No samples
							continue;		// Keep looking

						var val = this.getValue(time, i, data, indexes, null);
						if (val === null)
							continue;
						vals.push(val);		// Average for this minute
						avg += val;			// Add in total average
					}
				}
			}
			if (vals.length > 0)
				avg /= vals.length;	// Make it an actual average
			vals.sort(function(a,b){return a < b ? -1 : 1;});

			var lowQuart = vals[Math.floor(vals.length / 4)];
			var highQuart = vals[Math.floor(3 * vals.length / 4)];
			stats.push({mean: avg, lowQuart: lowQuart, highQuart: highQuart});
		}
		this.drawWhiskerGraph(div, this.starts, stats, title, yLabel, maxes, yAxisLabelWidth, xAxisPixelsPerLabel, false);
	}

	createControlTable(interval, data) {
		var rows = {}, cols =  {}, rowCount = 0;			// Save rows, columns, and keep track of row count
		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var index = 0;
		for (var j = 0; j < this.controls.length; ++j) {
			var control = this.controls[j];
			var totals	= new Array(control.pvs.length).fill(0);
			var asyncs	= new Array(totals.length).fill(0);
			var min 	= control.min !== undefined ? control.min : 0;
			for (var time = this.starts.front(); time < this.ends.back(); time += interval * 1000) {	// Step through seconds
				var sp = this.getValue(time, index, data, indexes, null);
				if (sp === null)
					continue;
				for (var k = 0; k < totals.length; ++k) {
					var pv = this.getValue(time, index + k + 1, data, indexes, null);
					if (pv === null || pv < sp * min)
						continue;
					++totals[k];
					if (Math.abs(sp - pv)/sp > 0.1)
						++asyncs[k];
				}
			}
			index += 1 + totals.length;
			for (var k = 0; k < totals.length; ++k) {	// For each parameter
				var name = control.pvs[k];									// Convenience pointer to the name
				var firstSlash = name.indexOf('/', 1);						// Find the first slash
				var secondSlash = name.indexOf('/', firstSlash + 1);		// Find the second slash
				var train = name.substring(firstSlash + 1, secondSlash);	// Get the parent node name (Train1)
				var param = name.substring(secondSlash + 1);				// Get the parameter name

				var row = rows[param];		// Find a a row with this parameter name
				if (row == undefined) {		// Don't have one? Make one
					var css = ++rowCount % 2 == 0 ? 'OvivoReportCell' : 'OvivoReportCell OvivoReportOddCell';	// Figure out which CSS class to use
					row = rows[param] = createElement('div', css, this.tagColumn, this.format(param));		// Create the row for this param name
				}

				var col = cols[train];		// Find a column with this train name
				if (col == undefined) {		// Don't have one? Make one
					col = cols[train] = createElement('div', 'OvivoReportColumn', this.controlTable);	// Create the column
					createElement('div', 'OvivoReportHeaderCell', col, this.format(train));				// Create a header cell
				}

				var cell = createElement('div', row.classList, col);					// Create the cell itself
				var indicator = createElement('div', 'OvivoReportIndicator', cell);	// Create the indicator
				indicator.setAttribute('bad', totals[k] > 43200/60 && (asyncs[k] / totals[k]) > 0.2);
			}
		}
	}

	createAlarmTable(interval, data) {
		this.setUpDates(interval, data);
		var indexes = new Array(data[0].length).fill(0);	// Keep track of how far we are through each column for speed
		var style = "%MM/%dd/%yyyy %hh:%mm:%ss";
		var rows = 0;
		for (var i = 0; i < indexes.length; ++i) {
			var count = 0, lastTime, fWasHigh = true;
			for (var time = this.starts.front(); time < this.ends.back(); time += 1000) {	// Go through the entire interval they requested at the interval they requested
				var fHigh = this.getValue(time, i, data, indexes, null);
				if (fHigh === null)
					continue;
				if (fHigh && !fWasHigh)	// If this is a rising edge
				{
					lastTime = time;	// Remember this time
					++count;			// Keep track of the count
				}
				fWasHigh = fHigh;		// Save value for next time
			}
			if (count == 0)
				continue;

			var css = ++rows % 2 == 0 ? 'OvivoReportCell' : 'OvivoReportCell OvivoReportOddCell';	// Figure out our CSS class
			createElement('div', css, this.alarmColumn, this.formatName(data[0][i]));			// Add the name of the alarm
			createElement('div', css, this.countColumn, count);									// Add the count of rising edges
			createElement('div', css, this.occurColumn, new Date(lastTime).format(style));		// Add the last rising edge time
		}
	}

    formatName(name) {
    	return this.format(name.substring(26));
    }

    format(name) {
		var fixedName = name.replace(/_|\/+/g, ' ');				// Replace all underscores with spaces
		return fixedName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');	// Word can end with lower-case letter or digit.
    }

	destroy() {
		if (this.graphID !== undefined)
			this.ldc.unregisterGraph(this.graphID);	// We no longer want any data from the LDC
		this.parent.destroyWidgets(true);	// Don't need to destroy our graphs. That will happen here automagically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}

	refresh() {

	}
};
