import owner from '../../owner';
import { createElement, createUniqueId } from '../elements';
import { Dashboard, ShareSettings } from '../pages/editorpage';
import View from './view';
import assert from '../debug';
import { SerializedCompanyInfo } from './sharingsettingsview';
import FrameParser from '../frameparser';
import LiveDataClient from '../livedataclient';

export class DevicesSettingsView extends View {
    settings:           HTMLInputElement[];
    deviceMap:          Map<HTMLInputElement, string> = new Map();
    devices:            string[];
    dashboard:          Dashboard;
    id:                 number;
    companySelector:    HTMLSelectElement;
    deviceContainer:    HTMLElement;
    fInitialized:       boolean = false;
    dSelector:          HTMLSelectElement;
    ldc:                LiveDataClient;
    constructor(devices: string[], dashboard: Dashboard, ldc: LiveDataClient) {
        super();
        this.settings       = [];
        this.devices        = devices;
        this.ldc            = ldc;
        this.id			    = this.ldc.registerGraph(this);
        this.dashboard      = dashboard;
    }

    public initialize(parent: HTMLElement): DevicesSettingsView {
        super.initialize(parent);
        this.wrapper    = createElement('div', 'sharing-view__wrapper', this.parent);
        this.deviceContainer  = createElement('div', 'sharing-view__user-container', this.wrapper);
        for (let i=0;i<owner.sortedDevices.length;++i) {
            let device          = owner.sortedDevices[i];
            let row             = createElement('div', 'sharing-view__user-row', this.deviceContainer);
            let user            = createElement('div', 'sharing-view__user', row);

            let id              = createUniqueId();
            let checkwrapper    = createElement('div', 'se-checkbox', createElement('div', 'tag-viewer__item__detail', user))
            let checkbox        = createElement('input', '', checkwrapper, '', { 'type': 'checkbox', 'id': id });
            checkbox.checked    = this.devices.includes(device.key);
            let label = createElement('label', '', checkwrapper, '', { 'htmlFor': id });
            checkbox.onchange = () => this.onSettingChange();
            createElement('div', 'sharing-view__user__name', user, device.siteName);
            this.deviceMap.set(checkbox, device.key);
            this.settings.push(checkbox);
        }
        this.fInitialized   = true;
        return this;
    }

    onSelectorChange() {
        //this.accountTable.removeUserRows();								// Remove pre-existing rows
		var companyKey;
		if (this.companySelector)										// If we can see multiple companies
			companyKey = this.companySelector.selectedOptions[0].value;	// Refresh the company we are looking at
		else
			companyKey = owner.ldc.user.companyKey;					// Refresh the users associated with the owner's company
        owner.ldc.getUserList(this.id, companyKey);
    }

    onCompaniesReceived(companies: SerializedCompanyInfo[]) {		// Got the companies back
        assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
		for (var i = 0; i < companies.length; ++i)	// For each company we got
			createElement('option', '', this.companySelector, companies[i].name).value = companies[i].key;	// Put an option in the selector
		this.onSelectorChange();					// Load the first company by default
    }

    onGetUserListResponse(fp: FrameParser) {
        this.deviceContainer.removeChildren();


    }

    onSettingChange() {
        for (let i=0;i<this.settings.length;++i) {
            let s       = this.settings[i];
            let key     = this.deviceMap.get(s)!;
            let setting = s.checked;
            let index   = this.devices.indexOf(key)
            if (setting) {
                if (index == -1)
                    this.devices.push(key);
            }
            else {
                if (index != -1)
                    this.devices.splice(index, 1);
            }
        }
    }

    static SHARE_NONE       = 'None';
    static SHARE_VIEW_ONLY  = 'View Only';
    static SHARE_EDIT       = 'Edit';
    static SHARE_CREATOR    = 'Creator'
}