import { createElement, getHumanReadableTime } from "../elements";
import CheckIcon from '../images/icons/check.svg';
import CommentIcon from '../images/icons/add_comment.svg';
import Card from "./card";
import owner, { Routes } from "../../owner";
import './feedcard.css';
import { getHash } from "../router/router";

export default class FeedCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 300,
                height: 400,
                minWidth: 200,
                minHeight: 400,
                type: 'feed',
            }
        }
        super(parent, editor, props);
        this.element.titleBox.value = this.name ? this.name : 'Alarm Feed';
        this.element.titleBox.oninput();
        this.element.alarmWrapper = createElement('div', 'feed-card__wrapper', this.element);
        this.element.alarms = [];
        this.element.active     = [];
        this.element.inactive   = [];
        this.index = 0;
        this.element.requestedAlarms = 0;
        this.element.onHistorical       = (ordered, latestTime, fCurrent, fBefore, fUnprompted) => {
        }
        this.element.onAlarmAdded       = (alarm) => {}
        this.element.onAlarmChanged     = (alarm) => {}
        this.element.onAlarmRemoved     = (alarm) => {}
        this.element.endAlarmCommand    = (device) => {
            if (++this.element.requestedAlarms >= owner.sortedDevices.length) {
                this.element.alarms = [];
                this.element.active = [];
                this.element.inactive = [];
                for (let i=0;i<owner.sortedDevices.length;i++) {
                    let device = owner.sortedDevices[i]
                    let alarms = device.alarms.alarms;
                    this.element.alarms.push(...alarms);
                    for (let i=0;i<alarms.length;i++) {
                        if (alarms[i].fActive)
                            this.element.active.push(alarms[i]);
                        else
                            this.element.inactive.push(alarms[i]);
                    }
                }
                this.element.alarms = this.element.alarms.sort((a, b) => b.tsActivated - a.tsActivated);
                this.element.active = this.element.active.sort((a, b) => b.tsActivated - a.tsActivated);
                this.element.inactive = this.element.inactive.sort((a, b) => b.tsActivated - a.tsActivated);
                this.updateFeed();
            }
            //device.alarms.requestHistorical(new Date().getTime()*1000, true, 20);
        }
        for (let i=0;i<owner.sortedDevices.length;i++) {
            let device = owner.sortedDevices[i];
            device.alarms.registerCallback(this.element);   // Get a callback on new alarms and events nost that we've set up everything
        }
    }

    updateFeed() {
        this.element.alarmWrapper.removeChildren();
        for(let i=0;i<Math.min(this.element.alarms.length, 30);i++) {
            let alarm = this.element.alarms[i];
            this.createAlarm(alarm);
        }
        /*
        for(let i=this.index;i<this.index + Math.min(this.element.alarms.length, 40);i++) {
            let alarm   = this.element.alarms[i];
            let div     = this.createAlarm(alarm);
            if (i==this.index+38) {
                let options = {
                    root: this.element.alarmWrapper,
                    rootMargin: '0px',
                    threshold: 1.0,
                }
                let observer = new IntersectionObserver(this.onScrollBottom.bind(this), options);
                observer.observe(div)
            }
        }
        */
        /*
        for(let i=0;i<this.element.inactive.length;i++) {
            let alarm = this.element.inactive[i];
            this.createAlarm(alarm);
        }
        */
    }

    onScrollBottom() {
        this.index += 38;
        //this.updateFeed()
    }

    createAlarm(alarm) {
        let alarmContainer = createElement('div', 'feed-card__alarm__container', this.element.alarmWrapper);
        let alarmTitle = createElement('div', 'feed-card__alarm__title', alarmContainer);
        let deviceName = createElement('div', 'feed-card__alarm__device', alarmTitle, alarm.alarmSet.device.siteName);
        deviceName.onclick = () => {
            location.hash = getHash(Routes.Device, {'key':alarm.alarmSet.device.key,'tab':'default'});
        }
        if (alarm.fActive)
            createElement('div', 'feed-card__alarm__active', alarmTitle, 'Active')
        createElement('div', 'feed-card__alarm__message', alarmContainer, alarm.message);
        createElement('div', 'feed-card__alarm__time', alarmTitle, getHumanReadableTime(alarm.tsActivated, '', ' ago'));
        let options = createElement('div', 'feed-card__alarm__options', alarmContainer);
        let comment = createElement('div', 'feed-card__alarm__options__button', options)
        createElement('img', 'feed-card__alarm__options__icon', comment, undefined, {'src':CommentIcon})
        createElement('div', 'feed-card__alarm__options__text', comment, 'Comment');
        let acknowledge = createElement('div', 'feed-card__alarm__options__button', options)
        createElement('img', 'feed-card__alarm__options__icon', acknowledge, undefined, {'src':CheckIcon})
        createElement('div', 'feed-card__alarm__options__text', acknowledge, 'Acknowledge')
        return alarmContainer;
    }
}
