import * as elements from '../elements';
import Page from './page';
import owner, { Routes } from '../../owner';
import Dialog from '../dialog';
import { getHash } from '../router/router';

export default class UndefinedPage extends Page {
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent, props);

        var button  = {
            'title'     :'Return',
            'callback'  :() => window.location.hash = getHash(Routes.Home),
            'color'     :'--color-primary'
        }
        let properties = {
            title:      '404',
            body:       'The page you are looking for does not exist. Please check your url and try again.',
            buttons:    [button],
        }
        owner.dialog = new Dialog(document.body, properties);
    };
};