import {createElement, createUniqueId} from '../elements';
import './textinput.css';

/**
 * TextInput Class
 * Provides default styling and animations to our text inputs site-wide.
 * It should be used anywhere the user is entering a small (< ~50 characters) of text.
 */
export default class TextInput {
    /**
     * @param  {HTMLElement}    parent
     * @param  {String}         placeholder     =''
     * @param  {String}         color           ='var(--color-primary'
     * @param  {Objec}          options
     */
    constructor(parent, placeholder = '', color = 'var(--color-secondary)', options) {
        this.parent = parent;
        this.placeholder = placeholder;     // default text to display in the input
        this.color = color;                 // color for the bar and focused text
        this.options = options;
        var id = createUniqueId();          // unique id for this input

        //default other options
        this.pattern    = '(.*?)';

        this.container  = createElement('div', 'text-input__container', this.parent);
        this.input      = createElement('input', 'text-input__input', this.container, null, {'required':true,'type':'text','id':id,'placeholder':' '});
        this.label      = createElement('label', 'text-input__label', this.container, this.placeholder, {'htmlFor':id});
        this.prebar     = createElement('span', 'text-input__bar-pre', this.container);
        this.bar        = createElement('span', 'text-input__bar', this.container);
        this.postbar    = createElement('span', 'text-input__bar-post', this.container);

        const attributes = Object.entries(options);
        for (const [key, value] of attributes) {    // method to copy in the attributes
            this.input.setAttribute(key, value);    // can be used to set any of the input attributes e.g. autocorrect
        }
        if (this.color) {
            this.prebar.style.background = this.postbar.style.background = this.color;
            this.input.onfocus  = () => this.label.style.color = this.color;
            this.input.onblur   = () => this.label.style.color = "var(--color-onSurfaceVariant)";
        }
    };

    focus() {
        this.input.focus();
    };

    select() {
        this.input.select();
    };

    disable() {
        this.input.disabled = true;
    }

    enable() {
        this.input.disabled = false;
    }

    get value() {
        return this.input.value;
    };

    set value(e) {
        this.input.value = e;
        if (this.color)
            this.label.style.color = this.color;
    }

    set oninput(e) {
        this.input.oninput = () => e();
    }

    set onchange(e) {
        this.input.onchange = () => e();
    }

    hide() {
        this.container.classList.add('hide');
    };

    show() {
        this.container.classList.remove('hide');
    };
    blur() {
        this.input.blur();
    }

    hidden() {
        if (this.container.classList.contains('hide')) {
            return(true);
        }
        else return(false);
    }

    remove() {
        this.container.removeChildren();	// delete all child DOM elements
        this.parent.removeChild(this.container)
    }

    disable() {
        this.input.disabled = true;
    }

    enable() {
        this.input.disabled = false;
    }
};