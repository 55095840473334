import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute } from "../../lib/attributes";
import Icon from '../../../images/icons/button.svg';
import { createElement } from "../../../elements";
import template from './tabbar.html';

export interface TabElements {
    tabElement: HTMLElement;
    barElement: HTMLElement;
}

@RegisterWidget({tag: 'tab-bar', displayName: 'Tab Bar', template: template, icon: Icon, section: 'Layout'})
export class TabBar extends Widget {
    protected valueElement: HTMLDivElement;
    private _value: string;
    private tabContainer: HTMLElement;
    private barContainer: HTMLElement;
    private elements: Map<string, TabElements> = new Map();
    onchange: ()=>void = ()=>{};

    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) backgroundColor: string = 'var(--color-surfaceContainer)';
    @Attribute({displayName: 'Border Color', typeModifier: 'color'}) borderColor: string = 'var(--color-primary)';
    @Attribute({displayName: 'Text Color', typeModifier: 'color'}) textColor: string = 'var(--color-onSurface)'
    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;
    @Attribute({displayName: 'Default Tab'}) defaultTab: string;

    protected connectedCallback(): void {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        this.style.setProperty('--border-color', this.borderColor);

        this.barContainer = this.shadowRoot?.querySelector('.bar__container') as HTMLDivElement;
        this.tabContainer = this.shadowRoot?.querySelector('.content') as HTMLDivElement;
    }

    enliven() {
        this.childNodes.forEach(child => {
            if (child instanceof HTMLElement) {
                let tabName = child.getAttribute('name')
                if (tabName === '' || tabName === null) {
                    throw(new Error('Invalid tab name'));
                }
                if (this.elements.has(tabName!))
                    throw(new Error('Duplicate tab names provided for tab bar'));

                child.slot = tabName!;
                createElement('slot', '', this.tabContainer, '', {'name': tabName!})
                let barElement = this.createTab(tabName!, child.getAttribute('icon-src'), tabName == this.value);
                child.style.display = tabName == this.value ? '' : 'none';
                this.elements.set(tabName!, {
                    tabElement: child,
                    barElement: barElement
                });
            }
        });
        this.selectTab(this.defaultTab ?? this.elements.keys().next().value);
        this.dispatchEvent(new Event('change'));
    }

    get value(): string {
        return this._value;
    }

    set value(v: string) {
        this._value = v;
        this.selectTab(v);
    }

    private createTab(name: string, icon?: string | null, isSelected?: boolean, fPrepend: boolean = false): HTMLDivElement {
        let newTab      = createElement('div', `tab ${isSelected ? 'selected' : ''}`);
        if (fPrepend)
            this.barContainer.prepend(newTab);
        else
            this.barContainer.append(newTab);
        let container   = createElement('div', 'tab__container', newTab);
        createElement('div', 'tab__selector', newTab);
        createElement('img', 'tab__icon', container, '', {'src':icon ?? ''});
        createElement('div', 'tab__name', container, name);

        newTab.onclick = () => this.selectTab(name);
        return newTab;
    }

    private selectTab(name: string) {
        for (let [tabName, elements] of this.elements) {
            let selected = tabName === name;
            elements.barElement.classList.toggle('selected', selected);
            elements.tabElement.style.display = selected ? '' : 'none';
        }
        this._value = name;
        this.dispatchEvent(new Event('change'));
    }

    protected onDisconnect(): void {

    }
}
