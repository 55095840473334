import SVGWidget from "./svgwidget";
import {Node, VType} from '../node';

//The Threshold Style driver selects between different user-set style strings,
// depending on the highest threshold that the node's value clears.
//When a node's value is exactly a threshold, it is still counted as having reached the threshold.
//
//Options:
//   data-se-node:"<node path>" determines the node that will be subscribed to
//   data-se-threshold_default:"<css style string>" (optional) the case where the value is below every threshold
//
//   data-se-threshold_<number>:"<css style string>"     specifies a threshold number on or above which the style will be applied

export default class ThresholdStyle extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

		if(!element.dataset.seNode){
			console.log("Error: element",element,"lacks 'se-node'.");
			return;
		}

		this.readStyleOptions();

		//This should always be the LAST thing that happens in the constructor!
		this.setupNodes(['']);
        let node = this.nodes[''];

		if (!node) return;
		//Yes, I know I said it should be the last thing, but this next part doesn't change anything I promise.

		//There may be a better way to do this,
		//but we need to report an error if the
		//node isn't a numeric type of some kind.
		var type = node.vtype;
		if (type == VType.VT_UNKNOWN ||
			type == VType.VT_STRING ||
			type == VType.VT_BLOCK)
			{
				console.log('Error: Node,',node,'is of type',type,"which is not a numeric type! The ThresholdStyle driver needs a numeric type. Who knows what's about to happen?");
			}
	}

	//readStyleOptions reads all attributes of the form seThreshold_<...>, and puts them in to a list for easy searching later.
	//The list will be sorted from highest threshold to lowest threshold to make searching faster.
	readStyleOptions(){
		var numbers = [];
		var prefix = 'seThreshold_';
		for (var name in this.element.dataset){
			if (name.substr(0,prefix.length) === prefix){
				var threshold = parseFloat(name.substr(prefix.length));

				if (!isNaN(threshold)){
					numbers.push(threshold);
				}
			}
		}
		numbers.sort(function(a,b){return b-a}); //Sort by numeric value. (greatest to smallest)
		this.thresholdStylePairs = []; //SORTED greatest to smallest
		for (var i=0;i<numbers.length;i++){
			var style = this.element.dataset[prefix+numbers[i].toString()];
			if (!style){
				console.log('Error:',prefix+numbers[i],'should have been found, but instead the attribute was written slightly differently.',numbers[i]);
				continue;
			}
			this.thresholdStylePairs.push([numbers[i],style])
		}
		var style = this.element.dataset[name];
		this.element._initialStyle = this.element.getAttribute('style') + ';';
	}

	update(node){
		var value = node.getValue(); //This better be a number. (See check in constructor.)

		//Caveman search
		//Here, we expect so few thresholds (often two) that a binary search would be pointless overhead.
		for (var i=0;i<this.thresholdStylePairs.length;i++){
			var pair = this.thresholdStylePairs[i];
			if (pair[0] <= value){
				this.element.style.cssText = this.element._initialStyle + pair[1];
				return;
			}
		}

		//If we have made it through the loop without returning,
		// then we want to apply the default. (if there is one!)
		if (this.element.dataset["seThreshold_default"]){
            //Later declarations take precedence over earlier ones - if that is not the case then this is a bug!
			this.element.style.cssText = this.element._initialStyle + this.element.dataset["seThreshold_default"];
		}
	}
}
