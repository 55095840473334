import createSVGElement from './svgelements';
import assert from './debug';
import './minmax.css'

// The MinMax object draws the min and max value along the left, top, right, or bottom edge of a rectangle.
// The current implementation always annotates the min max (lowest value to highest) from
// bottom to top or left to right.
// MinMax requires a parent svg canvas element to hold the two labels as svg text elements.
// First, you have to create the MinMax object, and then call render() to generate the svg 
// elements. You should only call render once per object construction or you'll get multiple mins/maxes
// drawn right on top of each other.
// The reason for the two-step process is to allow an interim step: minMax.getMetrics() 
// which will return the left, top, right and bottom overhang values in pixels. This allows
// the owner rectangle to adjust its own size to accomodate the minMax prior to calling minMax.render().
// Therefore, the normal calling sequence is as follows:
// 1. Construct minMax
// 2. Call minMax.getMetrics() and adjust the svg rectangle (graph, etc) to make room for the minMax.
// 3. Call minMax.render(activeRect, frameRect) to draw a minMax where the minMax tic marks align with
//		activeRect and the minMax is offset to land just outside the frameRect.
// MinMax is fully customized through CSS:
// CSS class 'minmax-label' formats the labels

// MinMax constructor function:
export default class MinMax {
    constructor(svg, minimum, maximum, resolution, position) {
        assert (svg.tagName == 'svg', 'parent must be an SVG canvas element');
        assert (minimum < maximum);
        this.parent			= svg;			// parent svg canvas
        this.min			= minimum;		// miminum minMax value
        this.max			= maximum;		// maximum minMax value
        this.res			= resolution;	// resolution of minMax
        this.digits 		= Math.max(0,Math.ceil(-Math.log(resolution)/Math.LN10));	// digits to show to the right of the decimal
        this.pos			= position;		// side of box on which to draw minMax
        this.fVertical		= (position=='left' || position=='right');	// vertical or horizontal
        this.gap			= 2;  			// Use this value to control how far the labels are from the bargraph in pixels.
    };

    getMetrics(overhang) {
		if (!this.metrics) {
			this.metrics = {};
			
			this.metrics.fVertical = this.fVertical;
			
			// Get maximum label size (CSS pixels) based on min and max:
			var label = this._getLabelSize (this.min, this.max);
			
			// Store label metrics based on minMax min and max:
			this.metrics.maxLabelWidth	= label.width;
			this.metrics.labelHeight	= label.height;
			
			// Compute indent pixels:
			switch (this.pos) {
			case 'left':	// Vertical minMax on left side of owner:
				this.metrics.left	= this.metrics.maxLabelWidth + overhang.strokeWidth + this.gap;
				this.metrics.top	= overhang.strokeWidth;
				this.metrics.right	= 0;
				this.metrics.bottom	= overhang.strokeWidth;
				break;
				
			case 'top':		// Horizontal minMax on top of owner:
				this.metrics.left	= overhang.strokeWidth;
				this.metrics.top	= this.metrics.labelHeight + overhang.strokeWidth;
				this.metrics.right	= overhang.strokeWidth;
				this.metrics.bottom	= 0;
				break;
				
			case 'right':	// Vertical minMax to the right of owner:
				this.metrics.left	= 0;
				this.metrics.top	= overhang.strokeWidth;
				this.metrics.right	= this.metrics.maxLabelWidth + overhang.strokeWidth + this.gap;
				this.metrics.bottom	= overhang.strokeWidth;
				break;
				
			case 'bottom':	// Horizontal minMax along bottom-side of owner:
				this.metrics.left	= overhang.strokeWidth;
				this.metrics.top	= 0;
				this.metrics.right	= overhang.strokeWidth;
				this.metrics.bottom	= this.metrics.labelHeight + overhang.strokeWidth;
				break;
			}
		}
		
		// Adjust the overhang metrics to accomodate the minMax:
		overhang.left	= Math.max(overhang.left,	this.metrics.left);
		overhang.top	= Math.max(overhang.top,	this.metrics.top);
		overhang.right	= Math.max(overhang.right,	this.metrics.right);
		overhang.bottom	= Math.max(overhang.bottom,	this.metrics.bottom);
		
		// Return the minMax overhang metrics (in CSS pixels):
		return this.metrics;
	}
	
	/*
	 * Render the minMax onto the parent svg canvas.
	 * 
	 * @param inside 	{rect object} inside rectangle	-- tic marks should align with full length of inside rect 
	 * @param outside	{rect object} outside rectangle	-- should be used to determine offset of minMax from inside rect
	 */
	render(inside, outside) {	// Create a bunch of SVG elements:
		assert (this.metrics, 'getMetrics() must be called before render()');
		var x0, y0;
		var x1, y1;
		var anchor0, anchor1;
		
		switch (this.pos) {
		case 'left':	// Vertical minMax on left side of owner:
			x0 = x1		= outside.left + this.gap;
			y0			= outside.bottom	- this.metrics.labelHeight / 2;
			y1			= outside.top		+ this.metrics.labelHeight / 2;
			anchor0		= anchor1 = 'end';
			break;
			
		case 'top':		// Horizontal minMax on top of owner:
			x0			= outside.left;
			anchor0		= 'start';
			x1			= outside.right;
			anchor1		= 'end';
			y0 = y1		= outside.top - this.metrics.labelHeight / 2;
			break;
			
		case 'right':	// Vertical minMax to the right of owner:
			x0 = x1		= outside.right + this.gap; // Add a buffer to move it away from the bargraph
			y0			= outside.bottom	- this.metrics.labelHeight / 2;
			y1			= outside.top		+ this.metrics.labelHeight / 2;
			anchor0		= anchor1 = 'start';
			break;
			
		case 'bottom':	// Horizontal minMax along bottom-side of owner:
			x0			= outside.left;
			anchor0		= 'start';
			x1			= outside.right;		
			anchor1		= 'end';
			y0 = y1		= outside.bottom + this.metrics.labelHeight / 2;
			break;
			
		default:
			x0 = y0 = x1 = y1 = anchor0 = anchor1 = 0;
			assert (false);
			break;
		}
	this._addLabel(x0, y0, this.min, anchor0, 'central');
	this._addLabel(x1, y1, this.max, anchor1, 'central');
	}
	
	destroy() {
		// nothing to do here
	}
	
	_addLabel(x, y, labelValue, textAnchor, baseline) {	// Add an SVG text element as label at the end of this tic mark:
		
		// Add label as SVG text element:
		createSVGElement ('text', 'minmax-label', this.parent,
			{
			x:						x,
			y:						y,
			'text-anchor':			textAnchor,
			'dominant-baseline': 	baseline
			}, 
			this._getLabel(labelValue));
	}
		
	_getLabel(value) {	// Eliminate trailing zeros and decimal point to streamline minMax text:
		return value.toFixed(this.digits).replace(/\.0+$/,'');
	}
	
	_getLabelSize(val1, val2) {	// Get maximum label size (CSS pixels) based on minMax min and max:
		// Create two text elements so we can query their size in pixels:
		var el1 = createSVGElement ('text', 'minmax-label', this.parent, null, this._getLabel(val1));
		var el2 = createSVGElement ('text', 'minmax-label', this.parent, null, this._getLabel(val2));
		
		// Get the size of the two extreme minMax labels:
		var l1 = el1.getBBox();
		var l2 = el2.getBBox();
		
		// Done with the elements, so extricate them from the DOM:
		this.parent.removeChild(el1);
		this.parent.removeChild(el2);
		
		var result = new Object();
		result.width = Math.max(l1.width, l2.width);
		result.height = Math.max(l1.height, l2.height);
		return result;
	}
};
