import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;
import { RegisterWidget, Widget } from "./lib/widget";
import { TagAttribute } from "./lib/tag";
import type { TagDefinition, Tag } from './lib/tag';
import RadialGaugeIcon from '../images/icons/gauge.svg'
import { Attribute } from './lib/attributes';
import { type RangeSetting } from '../views/attributeeditorview';

const template = /*html*/`
<style>
:host {
    display: block;
    height: 400px;
    width: 200px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
</style>
<div class="wrapper"></div>
`.trim()

@RegisterWidget({tag: 'radial-gauge', displayName: 'Radial Gauge', icon: RadialGaugeIcon, section: 'Charts'})
export class RadialGauge extends Widget {
    gauge: echarts.ECharts;
    @TagAttribute({displayName: 'Value Tag', shouldSubscribe: true}) valueTag: TagDefinition;
    @Attribute({displayName: 'Ranges', typeModifier: 'range'}) rangeSetting: RangeSetting = {
        minimum: 0,
        ranges: []
    };

    options: EChartsOption = {
        series: [
            {
                type: 'gauge',
                axisLine: {
                    lineStyle: {
                        width: 12,
                    }
                },
                pointer: {
                    itemStyle: {
                    color: 'auto'
                    }
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    splitNumber: 5,
                    lineStyle: {
                    color: '#fff',
                    width: 2,

                    }
                },
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                    color: '#fff',
                    width: 4
                    }
                },
                axisLabel: {
                    color: 'inherit',
                    distance: 15,
                    fontSize: 12
                },
                detail: {
                    valueAnimation: true,
                    color: 'inherit'
                },
                data: [
                    {
                        value: 70
                    }
                ]
            }
        ]
    };

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let chartDom = this.shadowRoot?.querySelector('.wrapper') as HTMLDivElement;
        this.gauge = echarts.init(chartDom);

        this.options.series![0].min = this.valueTag.tag.engMin;
        this.options.series![0].max = this.valueTag.tag.engMax;
        this.options.series![0].axisLine.lineStyle.color = [];
        for (let i=0;i<this.rangeSetting.ranges.length;++i) {
            let range = this.rangeSetting.ranges[i];
            let pct = (range.upperLimit - this.valueTag.tag.engMin) / (this.valueTag.tag.engMax - this.valueTag.tag.engMin);
            this.options.series![0].axisLine.lineStyle.color.push([pct, range.value]);
        }
        this.options.series![0].axisLine.lineStyle.color.push([1, '#ff1234']);

        this.gauge.setOption(this.options);
    }

    update(tag: Tag): void {
        this.gauge.setOption({
            series: [{
                data: [{
                    value: tag.getValue()
                }],
                detail: {
                    formatter: `${tag.valueText} ${this.valueTag.tag.unitsText}`,
                }
            }]
        })
    }
}