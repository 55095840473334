import './view.css';
import ViewModal from '../viewmodal';

export default class View {
    parent:         HTMLElement;
    wrapper:        HTMLElement;
    fInitialized:   boolean = false;
    modal:          ViewModal<View> | undefined;
    fShown:         boolean = false;
    initialize(parent: HTMLElement): View {
        this.parent = parent;
        return this;
    }

    destroy() {
        this.parent?.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent?.removeChildren();		// Delete any DOM elements left over
    }

    onIndexChange(index: string) {}

    onFileChange(fileName: string) {}

    resize() {}

    hide() {
        this.wrapper.classList.add('hide');
        this.onViewHidden();
    }

    show() {
        this.wrapper.classList.remove('hide');
    }

    onViewShown() {
        this.resize();
    }

    onViewHidden() {}

    onCompanySelectorChanged(companyKey: string) {};

    // helper methods (only one so far, but more to come):
	copy(properties: any) {	// Copy set of properties over to this widget object
		for (var attr in properties) {
			if (properties.hasOwnProperty(attr) && typeof properties[attr] !== 'undefined') {
                //@ts-ignore
				this[attr] = properties[attr];

            }
		}
    }
}