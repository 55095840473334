import Card from "./card";
import { Pump } from "../pump";
import { Role } from "../role";

export default class PumpCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 200,
                height: 200,
                minWidth: 200,
                minHeight: 150,
                tags: [],
                type: 'pump',
            }
        }
        super(parent, editor, props);
        this.tags = [{deviceKey: "US.TX.KEMPNERWSC.SH195", path: "/Pumps/Hwy195Pump1"}]
        var modelNode		= this.resolveTag(this.tags[0]).parent.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM).findByRole(Role.ROLE_MODEL_PUMP)[0];
        console.log(this.resolveTag(this.tags[0]).parent.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM).findByRole(Role.ROLE_MODEL_PUMP)[0])

        new Pump(this.resolveTag(this.tags[0]), this.element, undefined, undefined, modelNode)
    }
}