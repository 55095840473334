import SVGWidget from "./svgwidget";

//TODO: Write Docs
export default class ValueValve extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

		this.maxR = element.r.baseVal.value;
		this.setupNodes(['']);
	}
	update(node){
        //The sqrt of the fraction open is taken so that the value will be proportional to the /area/
        // of the attached circle element. This seems more visually accurate.

        // SKUN-61, Math.sqrt returns NaN if getValue() is negative, causing an an error setting the radius of the circle.
        // Furthermore Math.max will return NaN if one of the arguments is NaN.
        // NaN is bascially the Captain Trips of javascript.
        // MDN also states that isNaN() has nontrivial special case behavior and is unreliable...fml
        let radius = Math.max(0,Math.sqrt(node.getValue()/node.engMax) * this.maxR);
        this.element.setAttribute("r", Number.isNaN(radius) ? 0 : radius);
	}
}
