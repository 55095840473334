//LiveData protocol definitions:

export default class LiveData {	// Just to hold all the constants
    constructor() { }

    static LDC_RESPONSE = LiveData.LDC_RESPONSE;	// response flag (or'ed with protocol command in the response frame
    static LDC_PROTOCOL_ERROR = 0x40000000;	// protocol error response (incorrect message)
    static LDC_UNSUPPORTED = 0x20000000;	// either version or command unsupported
    static LDC_CMD_MASK = 0x0000FFFF;	// command values are all contained within lowest 16 bits

    //LiveData protocol commands (0 to 99)
    static LDC_FIRST = 0;			// first LiveData command
    static LDC_LAST = 99;			// last LiveData command

    static LDC_GET_NODE_CHILDREN = 1;		// client: given node id, get zero or more node (type, id, name, vartype, length, units, format, descr., role)
    static LDC_RESOLVE_TAGS = 2;		// client: send list of fully-qualified tag names and get back list of server tag ids
    //static LDC_WRITE_READ_TAGS	    	= 3;		// client: write tags then read tags as listed in command data
    static LDC_MAINTENANCE = 4;		// We are performing maintenance on the site code in some fashion --overloaded by the MAINTENANCE_XXX parameters
    static LDC_ADD_BASELINE = 5;		// Adding a baseline to a pump system
    static LDC_REGIME_CURVES = 6;		// client: get system curves from a device
    static LDC_SYSTEM_CURVE = 7;		// client: get system curves from a device
    //static LDC_PUMP_CURVES		    = 8;		// client: get pump curves from a device
    static LDC_TEST_CHECKLISTS = 9;		// client: get pre and post pump test checklists
    static LDC_SCHEDULE_REQUEST = 10;		// client: get next control periods that gain control
    static LDC_POINT_REQUEST = 11;		// client wants data about a specific point
    //static LDC_GET_CONFIG_FILE = 12;		// client wants a current config file
    //static LDC_SET_CONFIG_FILE = 13;		// client wants to update the current config file
    static LDC_SUBSCRIBE_TAGS = 14;		// Client wants exception based updates on Node LiveData
    static LDC_UNSUBSCRIBE_TAGS = 15;		// Client no longer wants exception based updates on Node LiveData
    static LDC_TAG_UPDATE = 16;		// There is a new update for Node LiveData
    static LDC_WRITE_TAG = 17;		// Clients wants to update a Node value
    static LDC_GET_FORM = 18;
    static LDC_SUBMIT_ATTRIBUTES = 19;
    static LDC_ASSIGN_DEVICE_KEY = 20        // Whoville is assigning a new device key to a device with a given service tag
    static LDC_CHALLENGE = 21        // Whoville is asking a device to respond to a challenge during authentication
    static LDC_GET_DRIVER_ATTRIBUTES = 22        // Client wants to get a specific driver form filled out with attributes
    static LDC_GET_DRIVER_FORMS = 23        // Client wants to get a list of all the available driver forms
    static LDC_SUBMIT_DRIVER_ATTRIBUTES = 24	    // Client wants to submit new attributes for a driver form
    static LDC_GET_DRIVERS = 25	    // Client wants to know what drivers are available and what potential data source's they have
    static LDC_GET_DRIVER_FORM = 26	    // Client wants a driver's default empty form
    static LDC_DELETE_DRIVER = 27	    // Client wants to delete a driver
    static LDC_SUBMIT_NODE_ATTRIBUTES = 28	    // Client wants to change a node's attributes
    static LDC_DELETE_NODE = 29	    // Client wants to delete a node
    static LDC_CREATE_TEMPORARY_NODE = 30	    // Client wants to create a temporary node while changing attributes
    static LDC_SUBMIT_TAG_CONFIG = 31        // Client wants to submit a new tag configuration string

    static LDC_RESPONSE	= 0x80000000  // response flag (or'ed with protocol command in the response frame

    //LiveData protocol command replies:
    static LDCR_GET_NODE_CHILDREN = (LiveData.LDC_GET_NODE_CHILDREN + LiveData.LDC_RESPONSE);
    static LDCR_RESOLVE_TAGS = (LiveData.LDC_RESOLVE_TAGS + LiveData.LDC_RESPONSE);
    //static LDCR_WRITE_READ_TAGS	        = (LiveData.LDC_WRITE_READ_TAGS + LiveData.LDC_RESPONSE);
    static LDCR_ADD_BASELINE = (LiveData.LDC_ADD_BASELINE + LiveData.LDC_RESPONSE);
    static LDCR_REGIME_CURVES = (LiveData.LDC_REGIME_CURVES + LiveData.LDC_RESPONSE);
    static LDCR_SYSTEM_CURVE = (LiveData.LDC_SYSTEM_CURVE + LiveData.LDC_RESPONSE);
    //static LDCR_PUMP_CURVES		        = (LiveData.LDC_PUMP_CURVES + LiveData.LDC_RESPONSE);
    static LDCR_TEST_CHECKLISTS = (LiveData.LDC_TEST_CHECKLISTS + LiveData.LDC_RESPONSE);
    static LDCR_SCHEDULE_REQUEST = (LiveData.LDC_SCHEDULE_REQUEST + LiveData.LDC_RESPONSE);
    static LDCR_POINT_REQUEST = (LiveData.LDC_POINT_REQUEST + LiveData.LDC_RESPONSE);
    //static LDCR_GET_CONFIG_FILE = (LiveData.LDC_GET_CONFIG_FILE + LiveData.LDC_RESPONSE);
    //static LDCR_SET_CONFIG_FILE = (LiveData.LDC_SET_CONFIG_FILE + LiveData.LDC_RESPONSE);
    static LDCR_TAG_UPDATE = (LiveData.LDC_TAG_UPDATE + LiveData.LDC_RESPONSE);
    static LDCR_GET_FORM = (LiveData.LDC_GET_FORM + LiveData.LDC_RESPONSE);
    static LDCR_SUBMIT_ATTRIBUTES = (LiveData.LDC_SUBMIT_ATTRIBUTES + LiveData.LDC_RESPONSE);
    static LDCR_GET_DRIVER_FORM = (LiveData.LDC_GET_DRIVER_FORM + LiveData.LDC_RESPONSE);
    static LDCR_GET_DRIVER_FORMS = (LiveData.LDC_GET_DRIVER_FORMS + LiveData.LDC_RESPONSE);
    static LDCR_SUBMIT_DRIVER_ATTRIBUTES = (LiveData.LDC_SUBMIT_DRIVER_ATTRIBUTES + LiveData.LDC_RESPONSE);
    static LDCR_GET_DRIVERS = (LiveData.LDC_GET_DRIVERS + LiveData.LDC_RESPONSE);
    static LDCR_GET_DRIVER_ATTRIBUTES = (LiveData.LDC_GET_DRIVER_ATTRIBUTES + LiveData.LDC_RESPONSE);
    static LDCR_DELETE_DRIVER = (LiveData.LDC_DELETE_DRIVER + LiveData.LDC_RESPONSE);
    static LDCR_SUBMIT_NODE_ATTRIBUTES = (LiveData.LDC_SUBMIT_NODE_ATTRIBUTES + LiveData.LDC_RESPONSE);
    static LDCR_DELETE_NODE = (LiveData.LDC_DELETE_NODE + LiveData.LDC_RESPONSE);
    static LDCR_CREATE_TEMPORARY_NODE = (LiveData.LDC_CREATE_TEMPORARY_NODE + LiveData.LDC_RESPONSE);
    static LDCR_SUBMIT_TAG_CONFIG = (LiveData.LDC_SUBMIT_TAG_CONFIG + LiveData.LDC_RESPONSE);

    //Whoville protocol commands (100 to 199) :
    static WVC_FIRST = 100;		// first Whoville command
    static WVC_LAST = 199;		// last Whoville command

    //Commands to Whoville from devices or clients:
    static WVC_CONNECT_DEVICE = 100;	// connect a LiveData server to Whoville, using device key. Returns success, company name, site name
    static WVC_GET_DEVICE_INFO = 101;	// client: get zero or more devices that match partial key. Respond with #devices, for each(key, id, company name, site name)
    static WVC_LOGIN = 102;	// primary login
    static WVC_CHANGE_PASSWORD = 103;	// client: change password of current user (send hash(username+oldpassword)+hash(username+newpassword))
    static WVC_GRAPH_QUERY = 104;	// client: request historical graph data from Whoville, or directly from device
    static WVC_SEND_BIG_TEST_MESSAGE = 105;	// client: send this with variable length payload, and wv should just send the same data in response
    static WVC_LOGOUT = 107;	// Use this command with the user with the given clientID. Response: clientID.
    static WVC_ACCOUNT_MANAGEMENT = 108;	// We are performing account management on wv in some fashion
    static WVC_COMPANIES = 109;	// We need all the company info we can get our hands on
    static WVC_USERS = 110;	// We need the users for a specific company
    static WVC_TRIAL_QUERY = 111;	// Request trials from the trial table in a given interval
    static WVC_VERIFY_USER = 112;	// client: check to see if the password checks out with the current one
    //static WVC_TRANSIENT_QUERY			= 113;	// Client: request data from the transient database
    static WVC_SNAPSHOT_QUERY = 114;	// client wants snapshot data
    static WVC_GET_REPORT_LIST = 115;	// client wants a list of a site's available PDF reports
    static WVC_GET_REPORT = 116;	// client wants a site's PDF report
    static WVC_PUMP_CURVES = 118;	// client wants pump curves
    static WVC_WHAT_IF = 119;	// Client is asking a what if question
    static WVC_GET_JAVASCRIPT_CRC = 121;
    static WVC_SAVE_GRAPH = 122;
    static WVC_GET_GRAPHS = 123;
    static WVC_GET_GROUPS = 125;
    static WVC_GET_TIME_ZONE = 129;
    static WVC_GET_LOG_FILE = 130;
    static WVC_PING = 131;
    static WVC_GET_CUSTOM_FILES = 132;
    static WVC_CONNECT_SERVICE_TAG = 133;
    static WVC_GET_CONFIG_FILE = 134;
    static WVC_GET_PROGRAM = 135;
    static WVC_GET_SERVICE_TAGS = 136;
    static WVC_ASSIGN_DEVICE_KEY = 137;	// Client is assigning a new device key to a service tag
    static WVC_CREATE_DASHBOARD = 138;
    static WVC_MODIFY_DASHBOARD = 139;
    static WVC_GET_DASHBOARD = 140;
    static WVC_GET_DASHBOARDS = 141;
    static WVC_DELETE_DASHBOARD = 142;
    static WVC_MIRROR_JARGON = 143;		// Client wants to delete a dashboard
    static WVC_CRASH_REPORT = 144;		// Client has crashed
    static WVC_GET_USER_LIST = 145;
    static WVC_FORGOT_PASSWORD = 146;  // Triggers an email with a password reset link
    static WVC_RESET_PASSWORD = 147;  // Resetting a password with a token from WVC_FORGOT_PASSWORD
    static WVC_JWT_LOGIN = 148;  // Client is logging in with a previously issued JWT authentication token
    static WVC_JWT_REFRESH = 149;  // Client is attempting to retrieve a new authentication token using a refresh token
    static WVC_JWT_REVOKE = 157;  // Client is asking us to revoke a token
    static WVC_VERIFY_SERVICE_TAG = 158;  // Client wants information about a service tag
    static WVC_CREATE_DEVICE_KEY = 159;	// Client wants to create a brand new site
    static WVC_NODE_CHANGED = 160;
    static WVC_NODE_REMOVED = 161;
    static WVC_GET_USER_PREFS = 162;
    static WVC_SET_USER_PREFS = 163;
    static WVC_GET_UPLOAD_URL = 164;
    static WVC_GET_DOWNLOAD_URL = 165;
    static WVC_GET_IMAGES = 166;
    static WVC_GET_PENDING_REPORTS = 167;
    static WVC_CONFIRM_UPLOAD = 168;
    static WVC_GET_USER_REPORTS = 169; 	// Client wants a list of report uuids
    static WVC_POST_HUBSPOT_FORM = 171;
    static WVC_IS_HUMAN = 173;  // reCAPTCHA response validation
    static WVC_GET_DEVICE_INFO_V2 = 174;  // Updated method to get information about devices
    static WVC_VERIFY_USER_BY_TOKEN = 176;
    static WVC_PASSKEY = 177;
    static WVC_CREATE_DEVICE_TOKEN = 178;
    static WVC_GET_DEVICE_TOKEN_METADATA = 179;
    static WVC_REVOKE_DEVICE_TOKEN = 180;

    //Commands from Whoville to clients:
    static WVC_DISCONNECTED = 150;	// wv: notify clients that device(s) disconnected, data comprised of series of (u_32)device_ids -- no response
    static WVC_CONNECTED = 151;	// wv: notify clients that device(s) connected, data comprised of series of (u_32)device_ids -- no response
    static WVC_MIRROR_LOG = 152;	// wv: send device last rowid for each of the five datalogger tables, and device is to respond by sending records that wv doesn't have
    static WVC_MIRROR_TRIAL = 153;	// wv: send device last rowid for each pump system trial table and device responds by sending new records and/or updating the last record.
    static WVC_EVENT_COMMAND = 156;	// wv: issue command to acknowledge/enable/disable/change settings of alarms and events

    //Whoville protocol command replies:
    static WVCR_CONNECT_DEVICE = (LiveData.WVC_CONNECT_DEVICE + LiveData.LDC_RESPONSE);
    static WVCR_GET_DEVICE_INFO = (LiveData.WVC_GET_DEVICE_INFO + LiveData.LDC_RESPONSE);
    static WVCR_LOGIN = (LiveData.WVC_LOGIN + LiveData.LDC_RESPONSE);
    static WVCR_CHANGE_PASSWORD = (LiveData.WVC_CHANGE_PASSWORD + LiveData.LDC_RESPONSE);
    static WVCR_GRAPH_QUERY = (LiveData.WVC_GRAPH_QUERY + LiveData.LDC_RESPONSE);
    static WVCR_MIRROR_LOG = (LiveData.WVC_MIRROR_LOG + LiveData.LDC_RESPONSE);
    static WVCR_MIRROR_TRIAL = (LiveData.WVC_MIRROR_TRIAL + LiveData.LDC_RESPONSE);
    static WVCR_SEND_BIG_TEST_MESSAGE = (LiveData.WVC_SEND_BIG_TEST_MESSAGE + LiveData.LDC_RESPONSE);
    static WVCR_ACCOUNT_MANAGEMENT = (LiveData.WVC_ACCOUNT_MANAGEMENT + LiveData.LDC_RESPONSE);
    static WVCR_COMPANIES = (LiveData.WVC_COMPANIES + LiveData.LDC_RESPONSE);
    static WVCR_USERS = (LiveData.WVC_USERS + LiveData.LDC_RESPONSE);
    static WVCR_GET_GROUPS = (LiveData.WVC_GET_GROUPS + LiveData.LDC_RESPONSE);
    static WVCR_TRIAL_QUERY = (LiveData.WVC_TRIAL_QUERY + LiveData.LDC_RESPONSE);
    static WVCR_VERIFY_USER = (LiveData.WVC_VERIFY_USER + LiveData.LDC_RESPONSE);
    //static WVCR_TRANSIENT_QUERY		= (LiveData.WVC_TRANSIENT_QUERY + LiveData.LDC_RESPONSE);
    static WVCR_SNAPSHOT_QUERY = (LiveData.WVC_SNAPSHOT_QUERY + LiveData.LDC_RESPONSE);
    static WVCR_GET_REPORT_LIST = (LiveData.WVC_GET_REPORT_LIST + LiveData.LDC_RESPONSE);
    static WVCR_GET_REPORT = (LiveData.WVC_GET_REPORT + LiveData.LDC_RESPONSE);
    static WVCR_PUMP_CURVES = (LiveData.WVC_PUMP_CURVES + LiveData.LDC_RESPONSE);
    static WVCR_EVENT_COMMAND = (LiveData.WVC_EVENT_COMMAND + LiveData.LDC_RESPONSE);
    static WVCR_WHAT_IF = (LiveData.WVC_WHAT_IF + LiveData.LDC_RESPONSE);
    static WVCR_GET_JAVASCRIPT_CRC = (LiveData.WVC_GET_JAVASCRIPT_CRC + LiveData.LDC_RESPONSE);
    static WVCR_GET_GRAPHS = (LiveData.WVC_GET_GRAPHS + LiveData.LDC_RESPONSE);
    static WVCR_CREATE_DASHBOARD = (LiveData.WVC_CREATE_DASHBOARD + LiveData.LDC_RESPONSE);
    static WVCR_MODIFY_DASHBOARD = (LiveData.WVC_MODIFY_DASHBOARD + LiveData.LDC_RESPONSE);
    static WVCR_GET_DASHBOARD = (LiveData.WVC_GET_DASHBOARD + LiveData.LDC_RESPONSE);
    static WVCR_GET_DASHBOARDS = (LiveData.WVC_GET_DASHBOARDS + LiveData.LDC_RESPONSE);
    static WVCR_DELETE_DASHBOARD = (LiveData.WVC_DELETE_DASHBOARD + LiveData.LDC_RESPONSE);
    static WVCR_GET_TIME_ZONE = (LiveData.WVC_GET_TIME_ZONE + LiveData.LDC_RESPONSE);
    static WVCR_GET_LOG_FILE = (LiveData.WVC_GET_LOG_FILE + LiveData.LDC_RESPONSE);
    static WVCR_PING = (LiveData.WVC_PING + LiveData.LDC_RESPONSE);
    static WVCR_GET_CUSTOM_FILES = (LiveData.WVC_GET_CUSTOM_FILES + LiveData.LDC_RESPONSE);
    static WVCR_GET_SERVICE_TAGS = (LiveData.WVC_GET_SERVICE_TAGS + LiveData.LDC_RESPONSE);
    static WVCR_ASSIGN_DEVICE_KEY = (LiveData.WVC_ASSIGN_DEVICE_KEY + LiveData.LDC_RESPONSE);
    static WVCR_VERIFY_SERVICE_TAG = (LiveData.WVC_VERIFY_SERVICE_TAG + LiveData.LDC_RESPONSE);
    static WVCR_CREATE_DEVICE_KEY = (LiveData.WVC_CREATE_DEVICE_KEY + LiveData.LDC_RESPONSE);
    static WVCR_RESET_PASSWORD = (LiveData.WVC_RESET_PASSWORD + LiveData.LDC_RESPONSE);
    static WVCR_NODE_CHANGED = (LiveData.WVC_NODE_CHANGED + LiveData.LDC_RESPONSE);
    static WVCR_NODE_REMOVED = (LiveData.WVC_NODE_REMOVED + LiveData.LDC_RESPONSE);
    static WVCR_GET_USER_LIST = (LiveData.WVC_GET_USER_LIST + LiveData.LDC_RESPONSE);
    static WVCR_JWT_LOGIN = (LiveData.WVC_JWT_LOGIN + LiveData.LDC_RESPONSE);
    static WVCR_JWT_REFRESH = (LiveData.WVC_JWT_REFRESH + LiveData.LDC_RESPONSE);
    static WVCR_GET_USER_PREFS = (LiveData.WVC_GET_USER_PREFS + LiveData.LDC_RESPONSE);
    static WVCR_SET_USER_PREFS = (LiveData.WVC_SET_USER_PREFS + LiveData.LDC_RESPONSE);
    static WVCR_GET_UPLOAD_URL = (LiveData.WVC_GET_UPLOAD_URL + LiveData.LDC_RESPONSE);
    static WVCR_GET_DOWNLOAD_URL = (LiveData.WVC_GET_DOWNLOAD_URL + LiveData.LDC_RESPONSE);
    static WVCR_GET_IMAGES = (LiveData.WVC_GET_IMAGES + LiveData.LDC_RESPONSE);
    static WVCR_GET_PENDING_REPORTS = (LiveData.WVC_GET_PENDING_REPORTS + LiveData.LDC_RESPONSE);
    static WVCR_GET_USER_REPORTS = (LiveData.WVC_GET_USER_REPORTS + LiveData.LDC_RESPONSE);
    static WVCR_IS_HUMAN = (LiveData.WVC_IS_HUMAN + LiveData.LDC_RESPONSE);
    static WVCR_POST_HUBSPOT_FORM = (LiveData.WVC_POST_HUBSPOT_FORM + LiveData.LDC_RESPONSE);
    static WVCR_DASHBOARD_REPORT_LIST = (LiveData.WVC_DASHBOARD_REPORT_LIST + LiveData.LDC_RESPONSE);
    static WVCR_GET_DEVICE_INFO_V2 = (LiveData.WVC_GET_DEVICE_INFO_V2 + LiveData.LDC_RESPONSE);
    static WVCR_VERIFY_USER_BY_TOKEN = (LiveData.WVC_VERIFY_USER_BY_TOKEN + LiveData.LDC_RESPONSE);
    static WVCR_PASSKEY = (LiveData.WVC_PASSKEY + LiveData.LDC_RESPONSE);
    static WVCR_CREATE_DEVICE_TOKEN = (LiveData.WVC_CREATE_DEVICE_TOKEN + 0x80000000);
    static WVCR_GET_DEVICE_TOKEN_METADATA = (LiveData.WVC_GET_DEVICE_TOKEN_METADATA + 0x80000000);
    static WVCR_REVOKE_DEVICE_TOKEN = (LiveData.WVC_REVOKE_DEVICE_TOKEN + 0x80000000);

    // Event subcommands:
    //static EVENT_UPDATE				= 0;	// Update active and unacknowledged alarms -- Only between DPOs and W
    //static EVENT_UPDATE_ALL			= 1;	// Delete existing sets before processing this update message (contains entire set) -- Only between DPOs and W
    static EVENT_SUBSCRIBE_ACTIVE = 2;	// Send from client to wv to subscribe to active or unacknowledged events
    //static EVENT_GET_METADATA		    = 3;	// Request alarm/event metadata from Whoville -- Deprecated
    static EVENT_ACKNOWLEDGE = 4;	// Acknowledge one or more alarms/events
    static EVENT_CREATE_ALARM = 5;	// Create a new alarm
    static EVENT_MODIFY_ALARM = 6;	// Modify an existing alarm
    static EVENT_GET_CONFIGURED = 7;	// Get data on all configured alarm
    static EVENT_ALARM_UPDATED = 8;	// Alarm configuration has been updated
    static EVENT_GET_HISTORICAL = 9;
    static EVTCMD_NEW_METADATA = 10;	// Whoville is sending incremental active/unacknowledged alarms AND metadata to a Client.
    static EVTCMD_NEW_METADATA_ALL = 11;	// Whoville is sending all active/unacknowledged alarms AND metadata to a Client.

    //Account management sub commands
    static ACCOUNT_ADD_USER = 3;	// Add a new user to whoville's tag map
    static ACCOUNT_MODIFY_USER = 4;	// Modify an already created user
    static ACCOUNT_DELETE_USER = 5;    // Delete an already created user
    static ACCOUNT_CREATE_GROUP = 6;
    static ACCOUNT_DELETE_GROUP = 7;
    static ACCOUNT_GROUP_DEVICES = 8;

    // Account management flags for modifying a user
    // static USER_PASSWORD	= 0x01;	    // Change a user's password
    static USER_FIRST = 0x02;	    // Change a user's first name
    static USER_LAST = 0x04;	    // Change a user's last name
    static USER_ENABLED = 0x08;	    // Change a user's enabled status
    static USER_EMAIL = 0x10;     // Change a user's email address
    static USER_PERMISSIONS = 0x20;
    static USER_WIZARD = 0x40;
    static USER_TAG_CONFIG = 0x80;
    static USER_DEV_CONFIG = 0x100;
    static USER_ADMIN = 0x200;
    static USER_CALLOUTS = 0x400;	// Change a user's phone number
    static USER_PHONE = 0x800;	// Change a user's two-factor status
    static USER_TWOFACTOR = 0x1000;

    // Live Data subscription mask
    static LDC_QUALITY = 0x1;
    static LDC_VALUE = 0x2;
    static LDC_RESET = 0x4;

    // Specifications for the Graph Data Query -- first, data types, then run length encoding point groups
    static BoxNone = 0;	// invalid box byte value
    static BoxResolution = 1;	// resolution value (double, > 0.0)
    static BoxUnits = 2;	// units (u_16)
    static BoxTrue = 3;	// true second value (varint delta second count)
    static BoxFalse = 4;	// false second value (varint delta second count)
    static BoxFullBoolean = 5;	// full n, sum (each a varint)
    static BoxDeltaBoolean = 6;	// delta n, sum (each a varint)
    static BoxFullNumeric = 7;	// full numeric value (varint # of resolutions)
    static BoxDeltaNumeric = 8;	// delta numeric (varint delta from previous numeric)
    static BoxGap = 9;	// gap (varint delta seconds count)
    static BoxFullRange = 10;	// full n, min, max, sum (each in varint # of resolutions)
    static BoxDeltaRange = 11;	// delta n, min, max, sum (each in varint # of resolutions)

    // Specifications for the Graph Data Query -- first, data types, then run length encoding point groups
    static TF_MIN = 0x01;
    static TF_MAX = 0x02;
    static TF_AVG = 0x04;
    static TF_STD = 0x08;
    static GT_NULL = 0x0000;
    static GT_REPEAT = 0x8000;
    static GT_UNIQUE = 0x4000;

    // Trial data flags
    static TRIAL_DATA_VOLUME = 0x01;	// Volume in MG
    static TRIAL_DATA_ENERGY = 0x02;	// Energy in kW-h
    static TRIAL_DATA_WATER_ENERGY = 0x04;	// Energy in the water in MG-feet
    static TRIAL_DATA_POTENTIAL_ENERGY = 0x08;	// Change in potential energy in MG-feet
    static TRIAL_DATA_DPO_STATE = 0x10;	// If we are in optimizing mode

    static SAVINGS_DATA_VOLUME = 0x01;	// Daily Volume in MG
    static SAVINGS_DATA_ENERGY = 0x02;	// Daily Energy Used in kW-h
    static SAVINGS_DATA_SEC = 0x04;	// Daily SEC in kW-h/MG
    static SAVINGS_DATA_EFFICIENCY = 0x08;	// Daily Efficiency as a decimal between 1 and 0
    static SAVINGS_DATA_BASELINE = 0x10;	// Daily Baseline Efficiency as a decimal between 1 and 0
    static SAVINGS_DATA_SAVINGS = 0x20;	// Daily Savings in kW-h

    // What if tool sub commands
    static WHAT_IF_GET_PROJECTS = 1;
    static WHAT_IF_CREATE_PROJECT = 2;
    static WHAT_IF_SAVE_PROJECT = 3;
    static WHAT_IF_LOAD_PROJECT = 4;
    static WHAT_IF_DOWNLOAD_PROJECT = 5;
    static WHAT_IF_DELETE_PROJECT = 6;
    static WHAT_IF_SOLVE_SNAPSHOT = 7;
    static WHAT_IF_SOLVE_EXTENDED_SIMULATION = 8;
    static WHAT_IF_MODIFY_OUTSIDE_ORGANIZATION_ACCESS = 9;

    // Pump System States
    static PSS_NO_TLC = 0;	// This Pump System does not have an uber controller
    static PSS_TLC_DISABLED = 1;	// PumpSystem has uber controller, but it is not in control
    static PSS_TLC_IN_CONTROL = 2;	// The uber controller is in control of the pump system
    static PSS_TLC_HOBBLED = 3;	// The uber controller is in control of the pump system, but a pump is in manual speed mode, or HOA is off or hand

    // ModelSnapshot Flags
    static MSS_BELOW_POR_RANGE = 0x0040;	// Pump is operating below POR range
    static MSS_ABOVE_POR_RANGE = 0x0080;	// Pump is operating above POR range
    static MSS_DEAD_HEADING = 0x0100;	// Pump is deadheading (no flow), probably also BELOW_BEP_RANGE
    static MSS_OVER_POWER_LIMIT = 0x0200;	// Pump is using too much shaft power
    static MSS_UNDER_NPSHR = 0x0400;	// Pump doesn't have enough NPSHa
    static MSS_LOCKED_OUT = 0x0800;	// Pump speed is unavailable
    static MSS_BELOW_AOR_RANGE = 0x1000;	// Pump is operating below AOR range
    static MSS_ABOVE_AOR_RANGE = 0x2000;	// PUmp is operating above AOR range

    // Passkey subcommands
    static PK_GET_CREDENTIALS = 0;	 // Get identifiers for already registered credentials associated with a user
    static PK_BEGIN_REGISTRATION = 1;	 // Auth'd client requesting to begin the registration process (send them all the info they need)
    static PK_REGISTER_CREDENTIAL = 2;	 // An authenticated user is attempting to register a new credential
    static PK_AUTHENTICATE = 3;	 // User attempting to authenticate with a signed challenge
    static PK_GET_AUTH_CHALLENGE = 4;	 // Get a challenge for authentication
    static PK_REVOKE_CREDENTIAL = 5;	 // Revoke a credential by credential ID
    static PK_ENABLE_WRITE_MODE = 6;	 // Enable write-mode by verifying your identity with a passkey
    static PK_SET_NICKNAME = 7;	 // Change the nickname associated with a passkey (for UI display)
};
