import { createElement } from "../elements";
import ViewModal from "../viewmodal";
import Card from "./card";
import NodeManager from "../nodemanager";
import CloseIcon from '../images/icons/cancel.svg';
import ArrowIcon from '../images/icons/arrow_back.svg'
import { evaluate } from 'mathjs';
import View from "../views/view";
import { SocketList, SocketView, TagSocket } from "./tagsocket__classic";
import { ValueFilter } from "../tagfilter";
import './calccard.css';

export default class CalcCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 300,
                height: 150,
                minWidth: 200,
                minHeight: 100,
                type: 'calculation',
            }
        }
        super(parent, editor, props);
        this.element.nodeManager    = new NodeManager(this.element);
        this.element.widgetWrapper  = createElement('div', 'editor__widget-card__widget-wrapper', this.element);
        this.element.valueDisplay   = createElement('div', 'editor__widget-card__value', this.element.widgetWrapper)
        this.element.scope          = {};
        this.element.socketList     = new SocketList([
            new TagSocket('Variable Tags', 'Tags to use in our equation.', ['name'], [new ValueFilter(true, false)], undefined, true)
        ], this);
        if (!this.orderedTags)
            this.orderedTags = [];
        if (this.tags) {
            this.element.socketList.populate(this.tags);
            this.rebuild();
        }

        else {
            this.tags = [];
            new ViewModal(new CalculationView(()=>this.calcCallback(), this));
        }

        return this;
    }

    onSettingsClicked() {
        new ViewModal(new CalculationView(()=>this.calcCallback(), this));
    }

    rebuild() {
        if (this.equation && this.orderedTags)
            this.calcCallback();
    }

    calcCallback() {
        this.element.nodeManager.destroy();
        this.element.nodeManager    = new NodeManager(this.element);
        if (this.orderedTags.some(tag => tag.fBadStatus)) // check if any of the tags we need are not available
            return;
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let variables = this.equation.match(/\@+\&/g);
        this.localEquation = this.equation.replace(/\s+/g, ''); // remove whitespace from our equation
        if (variables) {
            if (variables.length != this.orderedTags.length)
                this.orderedTags = this.tags;
            if (variables.length != this.orderedTags.length)
                return;
            for (let i = 0; i < variables.length; i++) {
                this.localEquation = this.localEquation.replace(/\@+\&/, letters[i]);
                this.element.scope[letters[i]] = this.resolveTag(this.orderedTags[i]);
            }
        }
        this.element.localScope = {}
        for (const [key, value] of Object.entries(this.element.scope)) {
            if (value === undefined)
                return;
            this.element.localScope[key] = value.getValue();
        }
        this.element.update = (tag) => {
            for (const [key, value] of Object.entries(this.element.scope)) {
                this.element.localScope[key] = value.getValue();
            }
            this.element.valueDisplay.textContent = evaluate(this.localEquation, this.element.localScope).toFixed(2);
        }
        this.element.valueDisplay.textContent = evaluate(this.localEquation, this.element.localScope).toFixed(2);
        for (let i = 0; i < this.orderedTags.length; i++) {
            this.element.nodeManager.addNode(this.resolveTag(this.orderedTags[i]));
        }
        this.element.nodeManager.subscribe();
    }
}

class CalculationView extends View {
    constructor(callback, card) {
        super();
        this.callback = callback;
        this.card = card;
        this.defaultEquation = card.equation ? card.equation : '';
        this.characters = ['+', '-', '(', ')', '*', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace'];
    }
    initialize(parent) {
        super.initialize(parent);
        this.wrapper        = createElement('div', 'calculation-view__wrapper', this.parent);
        this.socketWrapper  = createElement('div', 'calculation-view__sockets', this.wrapper)
        new SocketView(this.socketWrapper, this.card.element.socketList, this.buildVariables.bind(this)).initialize();

        let editorWrapper       = createElement('div', 'calculation-view__editor', this.wrapper);
        let equationWrapper     = createElement('div', 'calculation-view__equation-wrapper', editorWrapper)
        this.equation           = createElement('textarea', 'calculation-view__equation', equationWrapper);
        this.equationText       = createElement('div', 'caculation-view__equation__text', equationWrapper);
        let variableWrapper     = createElement('div', 'calculation-view__variable-wrapper', editorWrapper);
        this.variableList       = createElement('div', 'calculation-view__variable-list', variableWrapper);
        let acceptButton        = createElement('div', 'se-button calculation-view__button', this.wrapper, 'Apply Equation');
        window.addEventListener('keydown', this.textInput.bind(this))
        this.equation.onclick = () => {
            if (!this.equation.value[this.equation.selectionStart]
                || this.equation.value[this.equation.selectionStart] == ' ' && (!this.equation.value[this.equation.selectionStart - 1] || this.equation.value[this.equation.selectionStart - 1] == '&')
                || this.equation.value[this.equation.selectionStart] == '@') // if our cursor is already in a valid position
                return;
            else if (this.equation.value[this.equation.selectionStart] == ' ') // if our cursor is immediately following an operation character
                this.equation.selectionStart += 1;
            else
                this.equation.selectionStart -= 1;
            this.equation.selectionEnd = this.equation.selectionStart;
        }
        acceptButton.onclick    = () => {
            this.card.equation = this.equation.value
            this.callback();
            this.modal.destroy();
        }
        this.equation.onblur = () => {
            this.insertChar = this.equation.selectionStart;
        };
        this.equation.value = this.defaultEquation;
        this.buildVariables();
    }

    textInput() {
        let e       = window.event;
        if (e.target !== this.equation)
            return;
        e.preventDefault(); // if we know we are focused on our textarea, prevent the default text input
        if (!this.characters.includes(e.key)) // make sure we are entering a valid character
            return;
        let index   = this.equation.selectionStart; // figure out where our cursor is hanging out
        if (e.key === 'Backspace') {                // special handling for backspace
            if (this.equation.value[index - 1] === ' ') { // we are deleting an operation character and the surrounding spaces
                this.equation.value = this.equation.value.substring(0, index - 3) + this.equation.value.substring(index, this.equation.value.length);
                this.equation.selectionStart = this.equation.selectionEnd = index - 3;
            }
            else if (this.equation.value[index - 1] === '&') {// we are deleting a variable
                let variablesBefore = this.equation.value.substring(0, index - 1).match(/\@+\&/g);
                console.log(variablesBefore)
                console.log(this.card.orderedTags)
                this.card.orderedTags.splice(variablesBefore ? variablesBefore.length : 0, 1);
                this.equation.value = this.equation.value.substring(0, index - 1) + this.equation.value.substring(index, this.equation.value.length);
                index--
                while (this.equation.value[index - 1] == '@') {
                    this.equation.value = this.equation.value.substring(0, index - 1) + this.equation.value.substring(index, this.equation.value.length);
                    index--
                }
                this.equation.selectionStart = this.equation.selectionEnd = index;
            }
        }
        else {
            this.equation.value = this.equation.value.substring(0, index) + ' ' + e.key + ' ' + this.equation.value.substring(index, this.equation.value.length);
            this.equation.selectionEnd = this.equation.selectionEnd = index + 3
            console.log(e.key);
        }
        this.buildEquation()
    }

    buildVariables() {
        this.card.element.socketList.sync();
        this.variableList.removeChildren();
        this.card.element.socketList.sockets.get('Variable Tags').tags.forEach(tag => {
            let listItem    = createElement('div', 'calculation-view__list-item', this.variableList);
            let icon        = createElement('img', 'calculation-view__variable-icon', listItem, undefined, {'src':ArrowIcon});
            let variable    = createElement('div', 'calculation-view__variable', listItem, tag.name);
            listItem.onclick = () => {
                let name    = tag.name;
                let length  = name.length;
                let placeholder = new Array(length + 2).join('@') + '&';
                if (!this.insertChar)
                    this.insertChar = this.equation.value.length;
                let variablesBefore = this.equation.value.substring(0, this.insertChar).match(/\@+\&/g);
                this.card.orderedTags.splice(variablesBefore ? variablesBefore.length : 0, 0, tag);
                this.equation.value = this.equation.value.substring(0, this.insertChar) + placeholder + this.equation.value.substring(this.insertChar, this.equation.value.length);
                this.equation.focus();
                this.equation.selectionStart = this.insertChar + placeholder.length;
                this.buildEquation();
            }
        })
        this.buildEquation();
    }

    buildEquation() {
        this.equationText.removeChildren();
        let variables = this.equation.value.match(/\@+\&/g);
        let equationCopy = this.equation.value;
        let index = 0;
        if (variables != null) {
            for (let i = 0; i < variables.length; i++) {
                let matchIndex = equationCopy.search(/\@+\&/g);
                createElement('div', 'calculation-view__text__default', this.equationText, equationCopy.slice(index, matchIndex));
                let name = createElement('div', 'calculation-view__text__variable', this.equationText, this.card.orderedTags[i].name);
                equationCopy = equationCopy.replace(/\@+\&/, this.card.orderedTags[i].name);
                index = matchIndex + this.card.orderedTags[i].name.length;
            }
            createElement('div', 'calculation-view__text__default', this.equationText, equationCopy.slice(index, equationCopy.length));
        }
        else {
            createElement('div', 'calculation-view__text__default', this.equationText, equationCopy);
        }
    }
}