import View from "./view";
import owner from '../../owner';
import { createElement } from "../elements";
import './periodscheduleview.css';
import { Tag } from "../widgets/lib/tag";
import { Role } from "../role";
import NodeManager from "../nodemanager";
import { PeriodColors } from "../widgets/dpo/controls/utils";
import ArrowIcon from '../images/icons/arrow_right_filled.svg';

const startMonths = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
}

const endMonths = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
}

const startWeekDays = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday'
}

const endWeekDays = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday'
}
export default class PeriodScheduleView extends View {
    dpoFolder: Tag;
    constructor(dpoFolder: Tag) {
        super();
        this.dpoFolder = dpoFolder;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'period__wrapper', this.parent);
        let periods = this.dpoFolder.findByRole(Role.ROLE_TLC_PERIOD);
        for (let i=0;i<periods.length;++i) {
            let period = periods[i];
            let periodElement     = createElement('div', 'period__element', this.wrapper);
            createElement('div', 'period__element__color', periodElement).style.backgroundColor = PeriodColors[i];
            let periodWrapper = createElement('div', 'period__element__wrapper', periodElement);
            let titleWrapper = createElement('div', 'period__title__wrapper', periodWrapper);
            createElement('tag-name', 'period__element__name', titleWrapper, '', {nameTag: {tag: period}});
            let icon = createElement('img', 'period__arrow', titleWrapper, '', {src: ArrowIcon});
            let accordion = createElement('se-accordion', '', periodWrapper);
            titleWrapper.onclick = () => {
                accordion.toggle();
                icon.style.rotate = accordion.isOpen ? '90deg' : ''
            }
            let settingColumn = createElement('div', 'period__settings', accordion);
            if (i !== 0) {
                let calendar = createElement('div', 'period__calendar', settingColumn);
                let startMonth = period.findChild('StartMonth');
                createElement('tag-select', '', calendar, '', {valueTag: {tag: startMonth!}, valueMap: startMonths});

                let startDay = period.findChild('StartMonthDay');
                createElement('input-setpoint', 'period__date', calendar, '',  {setpoint: {tag: startDay!}}).style.minWidth = '';

                createElement('div', 'period__separator', calendar, 'through')
                let endMonth = period.findChild('EndMonth');
                createElement('tag-select', '', calendar, '', {valueTag: {tag: endMonth!}, valueMap: endMonths});
                let endDay = period.findChild('EndMonthDay');
                createElement('input-setpoint', 'period__date', calendar, '',  {setpoint: {tag: endDay!}}).style.minWidth = '';

                let startDayOfWeek = period.findChild('StartDay');
                let endDayOfWeek = period.findChild('EndDay');
                let weekDayWrapper = createElement('div', 'period__calendar', settingColumn);

                createElement('tag-select', '', weekDayWrapper, '', {valueTag: {tag: startDayOfWeek!}, valueMap: startWeekDays});
                createElement('div', 'period__separator', weekDayWrapper, 'through')
                createElement('tag-select', '', weekDayWrapper, '', {valueTag: {tag: endDayOfWeek!}, valueMap: endWeekDays});

                let timeWrapper = createElement('div', 'period__calendar', settingColumn);
                let startHour = period.findChild('StartHour');
                createElement('tag-select', '', timeWrapper, '', {valueTag: {tag: startHour!}, valueMap: {
                    0: '00:00',
                    1: '01:00',
                    2: '02:00',
                    3: '03:00',
                    4: '04:00',
                    5: '05:00',
                    6: '06:00',
                    7: '07:00',
                    8: '08:00',
                    9: '09:00',
                    10: '10:00',
                    11: '11:00',
                    12: '12:00',
                    13: '13:00',
                    14: '14:00',
                    15: '15:00',
                    16: '16:00',
                    17: '17:00',
                    18: '18:00',
                    19: '19:00',
                    20: '20:00',
                    21: '21:00',
                    22: '22:00',
                    23: '23:00',
                }});
                let endHour = period.findChild('EndHour');
                createElement('div', 'period__separator', timeWrapper, 'to')
                createElement('tag-select', '', timeWrapper, '', {valueTag: {tag: endHour!}, valueMap: {
                    0: '00:00',
                    1: '01:00',
                    2: '02:00',
                    3: '03:00',
                    4: '04:00',
                    5: '05:00',
                    6: '06:00',
                    7: '07:00',
                    8: '08:00',
                    9: '09:00',
                    10: '10:00',
                    11: '11:00',
                    12: '12:00',
                    13: '13:00',
                    14: '14:00',
                    15: '15:00',
                    16: '16:00',
                    17: '17:00',
                    18: '18:00',
                    19: '19:00',
                    20: '20:00',
                    21: '21:00',
                    22: '22:00',
                    23: '23:00',
                    24: '24:00',
                }});
            }
            let energyCost = period.findChildByRole(Role.ROLE_ENERGY_COST);
            let wrapper = createElement('div', 'period__calendar', settingColumn)
            createElement('input-setpoint', '', wrapper, '', {setpoint: {tag: energyCost!}});
            createElement('tag-units', '', wrapper, '', {unitsTag: {tag: energyCost!}});
        }
        this.fInitialized   = true;
        return this;
    }

};