import { type ConfiguredAlarm } from "../../alarm";
import { type Device } from "../../device";
import { type NodeSubscriber } from "../../node";
import { AttributeMetadata, AttributeType, Metadata, Serializable } from "./attributes";
import { TagUnit } from "./tagunits";
import { Widget } from "./widget";

export enum VType {
	VT_UNKNOWN	= 0,	// unknown type
	VT_BOOL		= 1,	// one-byte value (0 or 1)
	VT_U8		= 2,	// unsigned integers
	VT_U16		= 3,
	VT_U32		= 4,
	VT_U64		= 5,
	VT_S8		= 6,	// signed integers
	VT_S16		= 7,
	VT_S32		= 8,
	VT_S64		= 9,
	VT_F32		= 10,	// floating point
	VT_F64		= 11,
	VT_STRING	= 12,	// data points to an std::string or array of std::strings
	VT_BLOCK	= 13,	// block of data. Not yet implemented.
}

export const typeMap: Map<VType, string> = new Map([
	[VType.VT_UNKNOWN, 	'Unknown'],
	[VType.VT_BOOL, 	'Boolean'],
	[VType.VT_U8, 		'U8 Int'],
	[VType.VT_U16, 		'U16 Int'],
	[VType.VT_U32, 		'U32 Int'],
	[VType.VT_U64, 		'U64 Int'],
	[VType.VT_S8, 		'S8 Int'],
	[VType.VT_S16, 		'S16 Int'],
	[VType.VT_S32, 		'S32 Int'],
	[VType.VT_S64, 		'S64 Int'],
	[VType.VT_F32, 		'32 Float'],
	[VType.VT_F64, 		'64 Float'],
	[VType.VT_STRING, 	'String'],
	[VType.VT_BLOCK, 	'Data'],
]);

export enum TagQuality {
	TQ_GOOD				= 0,				// just here so you can set quality = NQ_GOOD instead of 0.
	TQ_UNINIT			= 0x00000008,		// data is not yet initialized (data is garbage)
	TQ_OFFLINE			= 0x00000010,		// data is off line (data is stale)
	TQ_UNREGISTERED		= 0x00000020,		// Not registered or UnregisterLiveData() was called
	TQ_MACHINE_NOT_FOUND= 0x00000040,		// could not find host machine
	TQ_PROCESS_NOT_FOUND= 0x00000080,		// could not find process on host machine
	TQ_DATA_NOT_FOUND	= 0x00000100,		// could not find data on host process
	TQ_CLOSED			= 0x00000200,		// unconnected
	TQ_PROTOCOL_ERROR	= 0x00000400,		// error in protocol code
	TQ_PERMISSION		= 0x00000800,		// user does not have permission (may be due to LDF_READ LDF_WRITE flags)
	TQ_DISCONNECTED		= 0x00001000,		// socket became disconnected -- will retry periodically
	TQ_OUT_OF_RANGE		= 0x00002000,		// value is out of range, and therefore invalid
}

export const QualityMap: Map<TagQuality, string> = new Map([
	[TagQuality.TQ_GOOD,				'Good'],
	[TagQuality.TQ_UNINIT,				'Uninitialized'],
	[TagQuality.TQ_OFFLINE,				'Tag Source Offline'],
	[TagQuality.TQ_UNREGISTERED,		'Unregistered'],
	[TagQuality.TQ_MACHINE_NOT_FOUND,	'Machine not Found'],
	[TagQuality.TQ_PROCESS_NOT_FOUND,	'Process not Found'],
	[TagQuality.TQ_DATA_NOT_FOUND,		'Data not Found'],
	[TagQuality.TQ_CLOSED,				'Closed'],
	[TagQuality.TQ_PROTOCOL_ERROR,		'Protocol Error'],
	[TagQuality.TQ_PERMISSION,			'Invalid Permission'],
	[TagQuality.TQ_DISCONNECTED,		'Disconnected'],
	[TagQuality.TQ_OUT_OF_RANGE,		'Out of Range']
]);

export interface Tag {
    name: string;
    parent: Tag;
	device: Device;
	absolutePath: string;
	children: Tag[];
    isLogged: Readonly<boolean>;
    isWriteable: Readonly<boolean>;
    description: string;
    roles: Set<string>;
    deviceRelativePath: string;
    engMin: number;
    engMax: number;
    rawMin: number;
    rawMax: number;
    resolution: number;
	digits: number;
    units: TagUnit;
    unitsText: string;
	vtype: VType;
	quality: TagQuality;
	flags: number;
	valueText: string;
	configured: ConfiguredAlarm[];
	convertValue: (desiredUnits: TagUnit, widget?: NodeSubscriber) => any;
	getValue: (widget?: NodeSubscriber) => any;
	getDisplayName: (showUnits?: boolean, fPretty?: boolean) => string;
	setPendingValue: (value: any, widget: NodeSubscriber) => void;
	getFormattedTextFromValue: (value: any, showUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string) => string;
	convertFromCacheToDisplay: (value: number) => number;
	findChild: (name: string) => Tag | null;
	findChildByRole: (role: string) => Tag | null;
	findByRole: (role: string) => Tag[];
	getFormattedText: (fUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string, widget?: NodeSubscriber) => string;
};

export type TagTypeOptions     = 'numeric' | 'boolean' | 'string' | 'folder' | 'root';
export type TagPropertyOptions = 'logged' | 'writeable' | 'scaled' | 'subscribeable' | 'alarmConfigured';
export type TagUnitOptions     = 'angle' | 'apparent-power' | 'area' | 'bpa' | 'concentration' | 'conductivity' | 'cost-per-volume' | 'currency' | 'current' | 'energy' | 'energy-cost' | 'flow-rate' | 'flux' | 'fraction' | 'frequency' | 'length' | 'mass' | 'mass-flow-rate' | 'power' | 'pressure' | 'reactive-power' | 'specific-capacity' | 'specific-energy' | 'specific-flux' | 'temperature' | 'time' | 'time-span' | 'turbidity' | 'voltage' | 'volume' | 'weight';

/**
 * Definition of all the properties that enable a tag to be used for a specific rendering purpose by a widget
 *
 * @export
 * @interface TagProperties
 */
export interface TagMetadata extends Metadata {
    displayName: string;
    isOptional?: boolean; // Whether or not this tag is required prior to rendering
    supportedPaths?: string[] // If provided, only support tags with a specific device-relative path
    shouldSubscribe?: boolean; // Whether or not to subscribe for live data updates. If true, will not render until all subscribed tags have good quality
    supportedTypes?: TagTypeOptions[]; // Supported Tag Types
    requiredProperties?: TagPropertyOptions[]; // Required Tag Properties
    supportedRoles?: string[]; // Supported Tag Roles
    supportedUnits?: TagUnitOptions[]; // Supported Units
    attributes?: TagAttributeMetadata[], // Optional tag-specific attributes
    requiresHistorical?: boolean // If true, only allow logged tags
}

export interface ExtendedTagMetadata extends TagMetadata {
	type: 'tag'
	privateKey: symbol;
	publicKey: string;
}
export interface ExtendedTagSetMetadata extends TagMetadata {
	type: 'set'
	privateKey: symbol;
	publicKey: string;
}

export interface TagSetMetadata extends TagMetadata {
    requiredCount?: number // Minimum number of tags required to render
}

export interface SerializedTag {
    location: string,
    attributes?: any;
}

export interface TagAttributeMetadata extends AttributeMetadata {
	id: string;
	type: AttributeType;
	default?: any;
}

export const tagAttributeMetadataSymbol = Symbol();
export const tagSetAttributeMetadataSymbol = Symbol();

export interface TagDefinition {
	tag: Tag;
	attributes?: {[key: string]: string};
};

export const TagAttribute = (properties: TagMetadata) => (target: Widget, key: string) => {
    const privateKey = Symbol();
    return Serializable({...properties, privateKey: privateKey, type: 'tag', publicKey: key}, tagAttributeMetadataSymbol, target, key, privateKey);
}

export const TagSetAttribute = (properties: TagSetMetadata) => (target: Widget, key: string) => {
    const privateKey = Symbol();
    return Serializable({...properties, privateKey: privateKey, type: 'set', publicKey: key}, tagSetAttributeMetadataSymbol, target, key, privateKey);
}

export const findGlobalTagsFromPath = (absolutePath: string): Promise<Tag[]> => {
	let pathComponents = absolutePath.split(':');
	if (pathComponents.length != 2)
		return new Promise<Tag[]>((resolve, reject) => {reject('Invalid absolute path')});
	return findGlobalTags(pathComponents[0], pathComponents[1].replaceAll('.', ''));
}

export const findGlobalTags = (deviceKey: string, deviceRelativePath: string) : Promise<Tag[]> => {
	let nodePromise = new Promise<Tag[]>((resolve, reject) =>
	{	//@ts-ignore TODO: Need a way to get our hands on the device??
		let device = owner.ldc.devices.getByKey(deviceKey);
		if(device)
		{
			let treeCompleteCallback = () =>
			{
				let splitPath = deviceRelativePath.replaceAll('.', '').split('#');
				if (splitPath.length > 2) // Multiple roles specified in path? Don't think we can handle that.
					reject('Invalid Tag path. Roles may only be specified at the end of a tag path');
				if (splitPath.length == 2) {
					let parentPath = splitPath[0];
					let role = splitPath[1];
					let node = device!.tree.findNode(parentPath);
					let nodes = node?.findChildrenByRole(role);
					if (nodes)
						resolve(nodes);
					else
						reject(`Unable to find any Tags in '${parentPath} that have role ${role}' on device '${device!.siteName}'`)
				}
				else {
					let node = device!.tree.findNode(deviceRelativePath);
					if (node)
						resolve([node]);
					else
						reject(`Unable to find Tag '${deviceRelativePath}' on device '${device!.siteName}'`);
				}
			}
			if(device.isTreeComplete())
				treeCompleteCallback();
			else if (device.connected || device.cachedTree)
				device.requestNodeTree(()=>treeCompleteCallback());
			else
				reject(`Device '${device.siteName}' is disconnected and does not have a cached site tree`);
		}
	});
	return nodePromise;
}