import { TagUnitOptions } from "./tag";

export enum TagUnitQuantity {
	TUQ_UNDEFINED			= 0x0000,	// Node unit quantity is not defined (just a number)
	TUQ_LENGTH				= 0x0100,	// feet, meters, angstroms
	TUQ_MASS				= 0x0200,	// kg, lbm, troy oz
	TUQ_WEIGHT				= 0x0300,	// lbf, N
	TUQ_TIMESPAN			= 0x0400,	// usec, hour, eon
	TUQ_TIME				= 0x0500,	// useconds since epoch
	TUQ_TEMPERATURE			= 0x0600,	// f, c, k
	TUQ_PRESSURE			= 0x0700,	// psi, pascals
	TUQ_VOLUME				= 0x0800,	// gallon, liter, MG
	TUQ_FREQUENCY			= 0x0900,	// rpm, Hz
	TUQ_FRACTION			= 0x0A00,	// percent, ratio
	TUQ_FLOWRATE			= 0x0B00,	// gpm. MGD, lps
	TUQ_POWER				= 0x0C00,	// HP, W, kW
	TUQ_ENERGY				= 0x0D00,	// kW-hr, Joules, ergs, btus, calories, Calories
	TUQ_SEC					= 0x0E00,	// JPL, kW-hr/MG
	TUQ_VOLTAGE				= 0x0F00,	// volts
	TUQ_CURRENT				= 0x1000,	// Amps, milliAmps
	TUQ_TURBIDITY			= 0x1100,	// NTU, FTU, FNU, JTU
	TUQ_CONCENTRATION		= 0x1200,	// Concentration (ppm, mg/l, etc.)
	TUQ_VELOCITY			= 0x1300,	// ft/sec, m/sec, in/sec
	TUQ_ENERGYCOST			= 0x1400,	// $/kWh
	TUQ_SPECIFICCAPACITY	= 0x1500,	// gpm/ft
	TUQ_REACTIVE_POWER		= 0x1600,	// kVAR
	TUQ_APPARENT_POWER		= 0x1700,	// kVA
	TUQ_CURRENCY			= 0x1800,	// $
	TUQ_MASS_FLOWRATE		= 0x1900,	// ppm
	TUQ_FLUX				= 0x1A00,	// Gallon-feet/day (gfd)
	TUQ_BPA					= 0x1B00,	// biological potential activity
	TUQ_CONDUCTIVITY		= 0x1C00,	// mS/cm
	TUQ_AREA				= 0x1D00,	// square feet, square meters
	TUQ_ANGLE				= 0x1E00,	// degrees, radians
	TUQ_SPECIFICFLUX		= 0x1F00, 	// gfd/psi
	TUQ_COST_PER_VOLUME 	= 0x2000, 	// $/MG
	TUQ_INSTANTANEOUS_FLUX  = 0x2100,   // g/square foot
	TUQ_MASK			    = 0xFF00,
};

export enum TagUnit {
	TU_UNDEFINED		= TagUnitQuantity.TUQ_UNDEFINED + 0,
	TU_FEET				= TagUnitQuantity.TUQ_LENGTH + 0,
	TU_INCHES			= TagUnitQuantity.TUQ_LENGTH + 1,
	TU_METERS			= TagUnitQuantity.TUQ_LENGTH + 2,
	TU_CMS				= TagUnitQuantity.TUQ_LENGTH + 3,
	TU_MMS				= TagUnitQuantity.TUQ_LENGTH + 8,
	TU_MILES			= TagUnitQuantity.TUQ_LENGTH + 10,
	TU_POUNDS_MASS		= TagUnitQuantity.TUQ_MASS + 0,
	TU_KILOGRAMS		= TagUnitQuantity.TUQ_MASS + 1,
	TU_POUNDS_FORCE		= TagUnitQuantity.TUQ_WEIGHT + 0,
	TU_NEWTONS			= TagUnitQuantity.TUQ_WEIGHT + 1,
	TU_USECONDS			= TagUnitQuantity.TUQ_TIMESPAN + 0,
	TU_MSECONDS			= TagUnitQuantity.TUQ_TIMESPAN + 1,
	TU_SECONDS			= TagUnitQuantity.TUQ_TIMESPAN + 2,
	TU_MINUTES			= TagUnitQuantity.TUQ_TIMESPAN + 3,
	TU_HOURS			= TagUnitQuantity.TUQ_TIMESPAN + 4,
	TU_DAYS				= TagUnitQuantity.TUQ_TIMESPAN + 5,
	TU_WEEKS			= TagUnitQuantity.TUQ_TIMESPAN + 6,
	TU_YEARS			= TagUnitQuantity.TUQ_TIMESPAN + 7,
	TU_TIME				= TagUnitQuantity.TUQ_TIME + 0,		// defined as microseconds since start of Jan 1; 1970 GMT (UTC)
	TU_DEGREES_F		= TagUnitQuantity.TUQ_TEMPERATURE + 0,
	TU_DEGREES_C		= TagUnitQuantity.TUQ_TEMPERATURE + 1,
	TU_FEET_HEAD		= TagUnitQuantity.TUQ_PRESSURE + 0,
	TU_INCHES_HEAD		= TagUnitQuantity.TUQ_PRESSURE + 1,
	TU_METERS_HEAD		= TagUnitQuantity.TUQ_PRESSURE + 2,
	TU_CMS_HEAD			= TagUnitQuantity.TUQ_PRESSURE + 3,
	TU_PSI				= TagUnitQuantity.TUQ_PRESSURE + 4,
	TU_PASCALS	   		= TagUnitQuantity.TUQ_PRESSURE + 5,
	TU_BAR		   		= TagUnitQuantity.TUQ_PRESSURE + 6,
	TU_OSI		   		= TagUnitQuantity.TUQ_PRESSURE + 7,	// ounces per square inch
	TU_MMS_HEAD			= TagUnitQuantity.TUQ_PRESSURE + 8,	// Must have same sub-index as NU_MMS
	TU_MPA		   		= TagUnitQuantity.TUQ_PRESSURE + 9,
	TU_MILES_HEAD 		= TagUnitQuantity.TUQ_PRESSURE + 10,	// Must have same sub-index as NU_MILES
	TU_KPA 				= TagUnitQuantity.TUQ_PRESSURE + 11,
	TU_GALLONS			= TagUnitQuantity.TUQ_VOLUME + 0,
	TU_KG				= TagUnitQuantity.TUQ_VOLUME + 1,
	TU_MG				= TagUnitQuantity.TUQ_VOLUME + 2,
	TU_LITERS			= TagUnitQuantity.TUQ_VOLUME + 3,
	TU_CF				= TagUnitQuantity.TUQ_VOLUME + 4,
	TU_CM				= TagUnitQuantity.TUQ_VOLUME + 5,
	TU_MEGALITERS		= TagUnitQuantity.TUQ_VOLUME + 6,
	TU_MILLILITERS 		= TagUnitQuantity.TUQ_VOLUME + 7,
	TU_RPM				= TagUnitQuantity.TUQ_FREQUENCY + 0,
	TU_HZ				= TagUnitQuantity.TUQ_FREQUENCY + 1,
	TU_PERCENT			= TagUnitQuantity.TUQ_FRACTION + 0,	// typically 0-100
	TU_RATIO			= TagUnitQuantity.TUQ_FRACTION + 1,	// typically 0-1
	TU_GPM				= TagUnitQuantity.TUQ_FLOWRATE + 0,
	TU_MGD				= TagUnitQuantity.TUQ_FLOWRATE + 1,
	TU_LPS				= TagUnitQuantity.TUQ_FLOWRATE + 2,
	TU_LPM				= TagUnitQuantity.TUQ_FLOWRATE + 3,
	TU_LPH				= TagUnitQuantity.TUQ_FLOWRATE + 4,
	TU_CFS				= TagUnitQuantity.TUQ_FLOWRATE + 5,
	TU_ML_PER_MIN		= TagUnitQuantity.TUQ_FLOWRATE + 6,
	TU_CM_PER_H			= TagUnitQuantity.TUQ_FLOWRATE + 7,
	TU_CFM				= TagUnitQuantity.TUQ_FLOWRATE + 8,
	TU_SCFH				= TagUnitQuantity.TUQ_FLOWRATE + 9,
	TU_MSCFD			= TagUnitQuantity.TUQ_FLOWRATE + 10,
	TU_CM_PER_SEC 		= TagUnitQuantity.TUQ_FLOWRATE + 11,	// cubic meters per second
	TU_CFH		  		= TagUnitQuantity.TUQ_FLOWRATE + 12,	// cubic feet per hour
	TU_CM_PER_MIN 		= TagUnitQuantity.TUQ_FLOWRATE + 13,	// cubic meters per minute
	TU_ML_PER_DAY		= TagUnitQuantity.TUQ_FLOWRATE + 14,
	TU_HP				= TagUnitQuantity.TUQ_POWER + 0,
	TU_WATTS			= TagUnitQuantity.TUQ_POWER + 1,
	TU_KW				= TagUnitQuantity.TUQ_POWER + 2,
	TU_MW				= TagUnitQuantity.TUQ_POWER + 3,
	TU_KW_HR			= TagUnitQuantity.TUQ_ENERGY + 0,
	TU_MW_HR			= TagUnitQuantity.TUQ_ENERGY + 1,
	TU_KW_HR_PER_MG		= TagUnitQuantity.TUQ_SEC + 0,
	TU_KW_HR_PER_ML		= TagUnitQuantity.TUQ_SEC + 1,
	TU_KW_HR_PER_CM		= TagUnitQuantity.TUQ_SEC + 2,
	TU_VOLTS			= TagUnitQuantity.TUQ_VOLTAGE + 0,
	TU_KVOLTS			= TagUnitQuantity.TUQ_VOLTAGE + 1,
	TU_MILLIVOLTS		= TagUnitQuantity.TUQ_VOLTAGE + 2,
	TU_AMPS				= TagUnitQuantity.TUQ_CURRENT + 0,
	TU_MILLIAMPS		= TagUnitQuantity.TUQ_CURRENT + 1,
	TU_NTU				= TagUnitQuantity.TUQ_TURBIDITY + 0,
	TU_MG_PER_L			= TagUnitQuantity.TUQ_CONCENTRATION + 0,
	TU_PPM				= TagUnitQuantity.TUQ_CONCENTRATION + 1,
	TU_FT_PER_SEC		= TagUnitQuantity.TUQ_VELOCITY + 0,
	TU_M_PER_SEC		= TagUnitQuantity.TUQ_VELOCITY + 1,
	TU_IN_PER_SEC		= TagUnitQuantity.TUQ_VELOCITY + 2,
	TU_MM_PER_SEC		= TagUnitQuantity.TUQ_VELOCITY + 3,
	TU_D_PER_KW_HR		= TagUnitQuantity.TUQ_ENERGYCOST + 0,
	TU_E_PER_KW_HR		= TagUnitQuantity.TUQ_ENERGYCOST + 1,
	TU_P_PER_KW_HR		= TagUnitQuantity.TUQ_ENERGYCOST + 2,
	TU_GPM_PER_FT		= TagUnitQuantity.TUQ_SPECIFICCAPACITY + 0,
	TU_VAR				= TagUnitQuantity.TUQ_REACTIVE_POWER + 0,
	TU_KVAR 			= TagUnitQuantity.TUQ_REACTIVE_POWER + 1,
	TU_VA  				= TagUnitQuantity.TUQ_APPARENT_POWER + 0,
	TU_KVA				= TagUnitQuantity.TUQ_APPARENT_POWER + 1,
	TU_DOLLARS			= TagUnitQuantity.TUQ_CURRENCY + 0,
	TU_EUROS			= TagUnitQuantity.TUQ_CURRENCY + 1,
	TU_POUNDS			= TagUnitQuantity.TUQ_CURRENCY + 2,
	TU_PPD				= TagUnitQuantity.TUQ_MASS_FLOWRATE + 0,
	TU_GFD				= TagUnitQuantity.TUQ_FLUX + 0,
	TU_BPA				= TagUnitQuantity.TUQ_BPA + 0,
	TU_USIEMENS_PER_CM	= TagUnitQuantity.TUQ_CONDUCTIVITY + 0,
	TU_SF 				= TagUnitQuantity.TUQ_AREA + 0,
	TU_SM 				= TagUnitQuantity.TUQ_AREA + 1,
	TU_DEGREES 			= TagUnitQuantity.TUQ_ANGLE + 0,
	TU_RADIANS 			= TagUnitQuantity.TUQ_ANGLE + 1,
	TU_GFD_PER_PSI 		= TagUnitQuantity.TUQ_SPECIFICFLUX + 0,
	TU_D_PER_MG			= TagUnitQuantity.TUQ_COST_PER_VOLUME + 0,
	TU_E_PER_CM			= TagUnitQuantity.TUQ_COST_PER_VOLUME + 1,
	TU_E_PER_ML			= TagUnitQuantity.TUQ_COST_PER_VOLUME + 2,
	TU_P_PER_CM			= TagUnitQuantity.TUQ_COST_PER_VOLUME + 3,
	TU_P_PER_ML			= TagUnitQuantity.TUQ_COST_PER_VOLUME + 4,
	TU_INST_FLUX 		= TagUnitQuantity.TUQ_INSTANTANEOUS_FLUX + 0
}

interface Unit
{
	quantity: TagUnitQuantity;
	keyWord: string;
	singular: string;
	plural: string;
	abbrev: string;
	conversion: number;
}

// Stolen from core/Node::convert() (gallons are US gallons) read underscores as 'per':
const ci_gal: number		= 231.0;	// definition (wikipedia)
const cf_gal: number	  	= ci_gal / (12.0 * 12.0 * 12.0);
const gal_cf: number	  	= 1.0 / cf_gal;
const m_ft: number	  		= 0.3048;	 // definition (wikipedia)
const ft_m: number	  		= 1.0 / m_ft;
const m3_cf: number	  		= m_ft * m_ft * m_ft;
const cf_m3: number	  		= 1.0 / m3_cf;
const m3_gal: number	  	= cf_gal * m3_cf;
const gal_m3: number	  	= 1.0 / m3_gal;
const kg_lbm: number	  	= 0.45359237;	 // definition of avoirdupois pound (wikipedia)
const lbm_kg: number	  	= 1.0 / kg_lbm;
const gravitySI: number 	= 9.80665;		   // definition of standard acceleration due to gravity (m/sec^2) (wikipedia)
const gravityUS: number 	= gravitySI / m_ft;  // ft/sec^2
const N_lbf: number	  		= kg_lbm * gravitySI;
const lbf_N: number	  		= 1.0 / N_lbf;
const lbf_cf: number	  	= 62.3;			 // H2O at 70F. Source: www.engineeringtoolbox.com
const psi_ft: number	  	= lbf_cf / 144.0;	 // psi per foot head
const ft_psi: number	  	= 1.0 / psi_ft;	 // feet head per psi
const g_cm3: number	  		= lbf_cf * N_lbf / gravitySI * 1000.0 * cf_m3 / (100.0 * 100.0 * 100.0);	// H2O at 21.111...C
const Pa_ft: number	  		= (g_cm3 * (100.0 * 100.0 * 100.0) * gravitySI / 1000.0) * m_ft;
const ft_Pa: number	  		= 1.0 / Pa_ft;
const ft_bar: number	  	= ft_Pa * 100000.0;
const l_gal: number	  		= m3_gal * 1000.0;  // 3.785411784
const gal_l: number	  		= 1.0 / l_gal;
const W_hp: number	  		= 550.0 * m_ft * N_lbf;  // 1 imperial hp = 550 ft-lbf/sec, 1 W = 1 Nm/sec (1 J/sec)
const hp_W: number	  		= 1.0 / W_hp;
const bar_psi: number	  	= ft_psi / ft_bar;

// Our unit map
export const UnitsMap = new Map<TagUnit, Unit>([
	[TagUnit.TU_UNDEFINED,			{keyWord: "",				quantity: TagUnitQuantity.TUQ_UNDEFINED,			singular: "",									plural: "",										abbrev: "",				conversion: 1.0}],
	[TagUnit.TU_FEET,				{keyWord: "ft",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "foot",								plural: "feet",									abbrev: "ft", 			conversion: 1.0}],
	[TagUnit.TU_INCHES,			    {keyWord: "in",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "inch", 								plural: "inches",								abbrev: "in",			conversion: 1.0/12.0}],
	[TagUnit.TU_METERS,			    {keyWord: "m",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "meter",								plural: "meters",								abbrev: "m",			conversion: ft_m}],
	[TagUnit.TU_CMS,				{keyWord: "cm",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "centimeter",							plural: "centimeters",							abbrev: "cm",			conversion: ft_m/100.0}],
	[TagUnit.TU_MMS,				{keyWord: "mm",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "millimeter",							plural: "millimeters",							abbrev: "mm",			conversion: ft_m/1000.0}],
	[TagUnit.TU_MILES,				{keyWord: "mi",				quantity: TagUnitQuantity.TUQ_LENGTH,				singular: "mile",								plural: "miles",								abbrev: "mi",			conversion: 5280.0}],
	[TagUnit.TU_POUNDS_MASS,		{keyWord: "lbm",			quantity: TagUnitQuantity.TUQ_MASS,			    	singular: "pound mass",							plural: "pounds mass",							abbrev: "lbm",			conversion: 1.0}],
	[TagUnit.TU_KILOGRAMS,			{keyWord: "kg",				quantity: TagUnitQuantity.TUQ_MASS,			    	singular: "kilogram",							plural: "kilograms",							abbrev: "kg",			conversion: lbm_kg}],
	[TagUnit.TU_POUNDS_FORCE,		{keyWord: "lbf",			quantity: TagUnitQuantity.TUQ_WEIGHT,				singular: "pound force",						plural: "pounds force",							abbrev: "lbf",			conversion: 1.0}],
	[TagUnit.TU_NEWTONS,			{keyWord: "N",				quantity: TagUnitQuantity.TUQ_WEIGHT,				singular: "newton",								plural: "newtons",								abbrev: "N",			conversion: lbf_N}],
	[TagUnit.TU_USECONDS,			{keyWord: "usec",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "microsecond",						plural: "microseconds",							abbrev: "µsec",			conversion: 1.0 / 1000000.0}],
	[TagUnit.TU_MSECONDS,			{keyWord: "msec",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "millisecond",						plural: "milliseconds",							abbrev: "msec",			conversion: 1.0 / 1000.0}],
	[TagUnit.TU_SECONDS,			{keyWord: "sec",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "second",								plural: "seconds",								abbrev: "sec",			conversion: 1.0}],
	[TagUnit.TU_MINUTES,			{keyWord: "min",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "minute",								plural: "minutes",								abbrev: "min",			conversion: 60.0}],
	[TagUnit.TU_HOURS,				{keyWord: "hr",				quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "hour",								plural: "hours",								abbrev: "hr",			conversion: 3600.0}],
	[TagUnit.TU_DAYS,				{keyWord: "day",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "day",								plural: "days",									abbrev: "day",			conversion: 86400.0}],
	[TagUnit.TU_WEEKS,				{keyWord: "week",			quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "week",								plural: "weeks",								abbrev: "wk",			conversion: 7 * 86400.0}],
	[TagUnit.TU_YEARS,				{keyWord: "yr",				quantity: TagUnitQuantity.TUQ_TIMESPAN,		    	singular: "year",								plural: "years",								abbrev: "yr",			conversion: 365.0 * 86400.0}], //non-leap year
	[TagUnit.TU_TIME,				{keyWord: "time",			quantity: TagUnitQuantity.TUQ_TIME,			    	singular: "microsecond since epoch",			plural: "microseconds since epoch",				abbrev: "time",			conversion: 1.0}],
	[TagUnit.TU_DEGREES_F,			{keyWord: "F",				quantity: TagUnitQuantity.TUQ_TEMPERATURE,			singular: "degree F",							plural: "degrees F",							abbrev: "F",			conversion: NaN}],	// special case
	[TagUnit.TU_DEGREES_C,			{keyWord: "C",				quantity: TagUnitQuantity.TUQ_TEMPERATURE,			singular: "degree C",							plural: "degrees C",							abbrev: "C",			conversion: NaN}],	// due to offset
	[TagUnit.TU_FEET_HEAD,			{keyWord: "ft_head", 		quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "foot head",							plural: "feet head",							abbrev: "ft head",		conversion: 1.0}],
	[TagUnit.TU_INCHES_HEAD,		{keyWord: "in_head", 		quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "inch head",							plural: "inches head",							abbrev: "in head",		conversion: 1.0 / 12.0}],
	[TagUnit.TU_METERS_HEAD,		{keyWord: "m_head",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "meter head",							plural: "meters head",							abbrev: "m head",		conversion: ft_m}],
	[TagUnit.TU_CMS_HEAD,			{keyWord: "cm_head", 		quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "centimeter head",					plural: "centimeters head",						abbrev: "cm head",		conversion: ft_m / 100.0}],
	[TagUnit.TU_PSI,				{keyWord: "psi",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "pound per square inch",				plural: "pounds per square inch",				abbrev: "psi",			conversion: ft_psi}],
	[TagUnit.TU_PASCALS,			{keyWord: "Pa",				quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "pascal",								plural: "pascals",								abbrev: "pascals",		conversion: ft_Pa}],
	[TagUnit.TU_KPA,				{keyWord: "kPa",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "kilopascal",							plural: "kilopascals",							abbrev: "kPa",			conversion: ft_Pa * 1.0e3}],
	[TagUnit.TU_BAR,				{keyWord: "bar",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "bar",								plural: "bars",									abbrev: "bar",			conversion: ft_Pa * 1.0e5}],
	[TagUnit.TU_OSI,				{keyWord: "osi",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "ounce per square inch",				plural: "ounces per square inch",				abbrev: "oz/in²",		conversion: ft_psi / 16.0}],
	[TagUnit.TU_MMS_HEAD,			{keyWord: "mm_head",		quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "millimeter head",					plural: "millimeters head",						abbrev: "mm head",		conversion: ft_m / 1.0e3}],
	[TagUnit.TU_MPA,				{keyWord: "MPa",			quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "megapascal",							plural: "megapascals",							abbrev: "MPa",			conversion: ft_Pa * 1.0e6}],
	[TagUnit.TU_MILES_HEAD,		    {keyWord: "mile_head",		quantity: TagUnitQuantity.TUQ_PRESSURE,		    	singular: "mile head",							plural: "miles head",							abbrev: "mi head",		conversion: 5280.0}],
	[TagUnit.TU_GALLONS,			{keyWord: "gal",			quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "gallon",								plural: "gallons",								abbrev: "gal",			conversion: cf_gal}],
	[TagUnit.TU_KG,				    {keyWord: "kgal",			quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "thousand gallons",					plural: "thousand gallons",						abbrev: "kgal",			conversion: cf_gal * 1.0e3}],
	[TagUnit.TU_MG,				    {keyWord: "MG",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "million gallons",					plural: "million gallons",						abbrev: "MG",			conversion: cf_gal * 1.0e6}],
	[TagUnit.TU_LITERS,			    {keyWord: "L",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "liter",								plural: "liters",								abbrev: "L",			conversion: gal_l * cf_gal}],
	[TagUnit.TU_CF,				    {keyWord: "cf",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "cubic foot",							plural: "cubic feet",							abbrev: "cf",			conversion: 1.0}],
	[TagUnit.TU_CM,				    {keyWord: "m3",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "cubic meter",						plural: "cubic meters",							abbrev: "m³",			conversion: cf_m3}],
	[TagUnit.TU_MEGALITERS,		    {keyWord: "Ml",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "megaliter",							plural: "megaliters",							abbrev: "Ml",			conversion: gal_l * cf_gal * 1.0e6}],
	[TagUnit.TU_MILLILITERS,		{keyWord: "ml",				quantity: TagUnitQuantity.TUQ_VOLUME,				singular: "milliliter",							plural: "milliliters",							abbrev: "ml",			conversion: gal_l * cf_gal / 1.0e3}],
	[TagUnit.TU_HZ,				    {keyWord: "Hz",				quantity: TagUnitQuantity.TUQ_FREQUENCY,			singular: "hertz",								plural: "hertz",								abbrev: "Hz",			conversion: 1.0}],		// Hz, unlike SI Hz, represents 2pi radians per second
	[TagUnit.TU_RPM,				{keyWord: "rpm",			quantity: TagUnitQuantity.TUQ_FREQUENCY,			singular: "revolution per minute",				plural: "revolutions per minute",				abbrev: "rpm",			conversion: 1.0/60.0}],
	[TagUnit.TU_PERCENT,			{keyWord: "pct",			quantity: TagUnitQuantity.TUQ_FRACTION,		    	singular: "percent",							plural: "percent",								abbrev: "%",			conversion: 1.0/100}],
	[TagUnit.TU_RATIO,				{keyWord: "ratio",			quantity: TagUnitQuantity.TUQ_FRACTION,		    	singular: ":1",									plural: ":1",									abbrev: ":1",			conversion: 1.0}],
	[TagUnit.TU_GPM,				{keyWord: "gpm",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "gallon per minute",					plural: "gallon per minute",					abbrev: "gpm",			conversion: 1.0}],
	[TagUnit.TU_MGD,				{keyWord: "MGD",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "million gallons per day",			plural: "million gallons per day",  			abbrev: "MGD",			conversion: 1.0e6/1440.0}],
	[TagUnit.TU_LPS,				{keyWord: "Lps",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "liters per second",					plural: "liters per second",					abbrev: "L/s",			conversion: gal_l * 60.0}],
	[TagUnit.TU_LPM,				{keyWord: "Lpm",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "liters per minute",					plural: "liters per minute",					abbrev: "L/m",			conversion: gal_l}],
	[TagUnit.TU_LPH,				{keyWord: "Lph",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "liters per hour",					plural: "liters per hour",						abbrev: "L/h",			conversion: gal_l / 60.0}],
	[TagUnit.TU_CFS,				{keyWord: "cfs",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic feet per second",				plural: "cubic feet per second",				abbrev: "cfs",			conversion: gal_cf * 60.0}],
	[TagUnit.TU_CFM,				{keyWord: "cfm",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic feet per minute",				plural: "cubic feet per minute",				abbrev: "cfm",			conversion: gal_cf}],
	[TagUnit.TU_CFH,				{keyWord: "cfh",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic feet per hour",				plural: "cubic feet per hour",					abbrev: "cfh",			conversion: gal_cf / 60.0}],
	[TagUnit.TU_SCFH,				{keyWord: "scfh",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "std cubic foot per hour",			plural: "std cubic foot per hour",				abbrev: "scfh",			conversion: gal_cf / 60.0}],
	[TagUnit.TU_MSCFD,				{keyWord: "mscfd",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "thousand std cubic foot per day",	plural: "thousand std cubic feet per day",		abbrev: "scfd",			conversion: gal_cf / (60.0 * 24.0) * 1000.0}],
	[TagUnit.TU_ML_PER_MIN,		    {keyWord: "mLm",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "milliliters per minute",				plural: "milliliters per minute",   			abbrev: "mL/m",			conversion: gal_l / 1000.0}],
	[TagUnit.TU_CM_PER_H,			{keyWord: "cmh",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic meters per hour",				plural: "cubic meters per hour",				abbrev: "m³/h",			conversion: gal_m3 / 60.0}],
	[TagUnit.TU_CM_PER_MIN,		    {keyWord: "cmm",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic meters per minute",			plural: "cubic meters per minute",  			abbrev: "m³/m",			conversion: gal_m3}],
	[TagUnit.TU_CM_PER_SEC,		    {keyWord: "cms",			quantity: TagUnitQuantity.TUQ_FLOWRATE,		    	singular: "cubic meters per second",			plural: "cubic meters per second",  			abbrev: "m³/s",			conversion: gal_m3 * 60}],
	[TagUnit.TU_ML_PER_DAY,			{keyWord: "MLd",			quantity: TagUnitQuantity.TUQ_FLOWRATE,				singular: "Megaliters per day",					plural: "Megaliters per day",  					abbrev: "ML/d",			conversion: gal_l /1440.0*1000*1000}],
	[TagUnit.TU_HP,				    {keyWord: "hp",				quantity: TagUnitQuantity.TUQ_POWER,				singular: "horsepower",							plural: "horsepower",							abbrev: "hp",			conversion: 1.0}],
	[TagUnit.TU_WATTS,				{keyWord: "W",				quantity: TagUnitQuantity.TUQ_POWER,				singular: "watt",								plural: "watts",								abbrev: "W",			conversion: hp_W}],
	[TagUnit.TU_KW,				    {keyWord: "kW",				quantity: TagUnitQuantity.TUQ_POWER,				singular: "kilowatt",							plural: "kilowatts",							abbrev: "kW",			conversion: hp_W * 1.0e3}],
	[TagUnit.TU_MW,				    {keyWord: "MW",				quantity: TagUnitQuantity.TUQ_POWER,				singular: "megawatt",							plural: "megawatts",							abbrev: "MW",			conversion: hp_W * 1.0e6}],
	[TagUnit.TU_KW_HR,				{keyWord: "kWh",			quantity: TagUnitQuantity.TUQ_ENERGY,				singular: "kilowatt hour",						plural: "kilowatt hours",						abbrev: "kWh",			conversion: 1.0}],
	[TagUnit.TU_MW_HR,				{keyWord: "MWh",			quantity: TagUnitQuantity.TUQ_ENERGY,				singular: "megawatt hour",						plural: "megawatt hours",						abbrev: "MWh",			conversion: 1.0e3}],
	[TagUnit.TU_KW_HR_PER_MG,		{keyWord: "kWhPerMG",		quantity: TagUnitQuantity.TUQ_SEC,					singular: "kilowatt hour per million gallons",	plural: "kilowatt hours per million gallons",	abbrev: "kWh/MG", 		conversion: 1.0}],
	[TagUnit.TU_KW_HR_PER_ML,		{keyWord: "kWhPerMl",		quantity: TagUnitQuantity.TUQ_SEC,					singular: "kilowatt hour per million liters", 	plural: "kilowatt hours per million liters", 	abbrev: "kWh/Ml", 		conversion: l_gal}],
	[TagUnit.TU_KW_HR_PER_CM,		{keyWord: "kWhPerCM",		quantity: TagUnitQuantity.TUQ_SEC,					singular: "kilowatt hour per cubic meter",	  	plural: "kilowatt hours per cubic meter",    	abbrev: "kWh/m³", 		conversion: l_gal * 1000.0}],
	[TagUnit.TU_VOLTS,				{keyWord: "V",				quantity: TagUnitQuantity.TUQ_VOLTAGE,				singular: "volt",								plural: "volts",								abbrev: "V",			conversion: 1.0}],
	[TagUnit.TU_KVOLTS,			    {keyWord: "kV",				quantity: TagUnitQuantity.TUQ_VOLTAGE,				singular: "kilovolt",							plural: "kilovolts",							abbrev: "kV",			conversion: 1.0e3}],
	[TagUnit.TU_MILLIVOLTS,		    {keyWord: "mV",				quantity: TagUnitQuantity.TUQ_VOLTAGE,				singular: "millivolt",							plural: "millivolts",							abbrev: "mV",			conversion: 1.0e-3}],
	[TagUnit.TU_AMPS,				{keyWord: "A",				quantity: TagUnitQuantity.TUQ_CURRENT,				singular: "amp",								plural: "amps",									abbrev: "A",			conversion: 1.0}],
	[TagUnit.TU_MILLIAMPS,			{keyWord: "mA",				quantity: TagUnitQuantity.TUQ_CURRENT,				singular: "milliamp",							plural: "milliamps",							abbrev: "mA",			conversion: 1.0e-3}],
	[TagUnit.TU_NTU,				{keyWord: "NTU",			quantity: TagUnitQuantity.TUQ_TURBIDITY,			singular: "ntu",								plural: "ntus",									abbrev: "NTU",			conversion: 1.0}],
	[TagUnit.TU_MG_PER_L,			{keyWord: "mgl",			quantity: TagUnitQuantity.TUQ_CONCENTRATION,		singular: "milligram per liter",				plural: "milligrams per liter",					abbrev: "mgl",			conversion: 1.0}],
	[TagUnit.TU_PPM,				{keyWord: "ppm",			quantity: TagUnitQuantity.TUQ_CONCENTRATION,		singular: "part per million",					plural: "parts per million",					abbrev: "ppm",			conversion: 1.0}],
	[TagUnit.TU_FT_PER_SEC,		    {keyWord: "fps",			quantity: TagUnitQuantity.TUQ_VELOCITY,		    	singular: "foot per second",					plural: "feet per second",						abbrev: "fps",			conversion: 1.0}],
	[TagUnit.TU_M_PER_SEC,			{keyWord: "mps",			quantity: TagUnitQuantity.TUQ_VELOCITY,		    	singular: "meter per second",					plural: "meter per second",						abbrev: "mps",			conversion: ft_m}],
	[TagUnit.TU_IN_PER_SEC,		    {keyWord: "ips",			quantity: TagUnitQuantity.TUQ_VELOCITY,		    	singular: "inch per second",					plural: "inch per second",						abbrev: "ips",			conversion: 1.0 / 12.0}],
	[TagUnit.TU_MM_PER_SEC,		    {keyWord: "mmps",			quantity: TagUnitQuantity.TUQ_VELOCITY,		    	singular: "millimeter per second",				plural: "millimeters per second",				abbrev: "mmps",			conversion: ft_m / 1000}],
	[TagUnit.TU_D_PER_KW_HR,		{keyWord: "dollarPerKwh",	quantity: TagUnitQuantity.TUQ_ENERGYCOST,			singular: "$ per kilowatt hour",				plural: "$ per kilowatt hour",					abbrev: "$/kWh",		conversion: NaN}],
	[TagUnit.TU_E_PER_KW_HR,		{keyWord: "euroPerKwh",		quantity: TagUnitQuantity.TUQ_ENERGYCOST,			singular: "EUR per kilowatt hour",				plural: "EUR per kilowatt hour",				abbrev: "€/kWh",		conversion: NaN}],
	[TagUnit.TU_P_PER_KW_HR,		{keyWord: "poundPerKwh",	quantity: TagUnitQuantity.TUQ_ENERGYCOST,			singular: "GBP per kilowatt hour",				plural: "GBP per kilowatt hour",				abbrev: "GBP/kWh",		conversion: NaN}],
	[TagUnit.TU_GPM_PER_FT,		    {keyWord: "gpmPerFt",		quantity: TagUnitQuantity.TUQ_SPECIFICCAPACITY,		singular: "gpm per foot",						plural: "gpm per foot",							abbrev: "gpm/ft",		conversion: 1.0}],
	[TagUnit.TU_VAR,				{keyWord: "VAR",			quantity: TagUnitQuantity.TUQ_REACTIVE_POWER,		singular: "volt-amp reactive",					plural: "volt-amps reactive",					abbrev: "VAR",			conversion: 1.0}],
	[TagUnit.TU_KVAR,				{keyWord: "kVAR",			quantity: TagUnitQuantity.TUQ_REACTIVE_POWER,		singular: "kilovolt-amp reactive",				plural: "kilovolts reactive",					abbrev: "kVAR",			conversion: 1.0 / 1e3}],
	[TagUnit.TU_VA,				    {keyWord: "VA",				quantity: TagUnitQuantity.TUQ_APPARENT_POWER,		singular: "volt-amp apparent",					plural: "volt-amps apparent",					abbrev: "VA", 			conversion: 1.0}],
	[TagUnit.TU_KVA,				{keyWord: "kVA",			quantity: TagUnitQuantity.TUQ_APPARENT_POWER,		singular: "kilovolt-amp apparent",				plural: "kilovolts apparent",					abbrev: "kVA",			conversion: 1.0 / 1e3}],
	[TagUnit.TU_DOLLARS,			{keyWord: "dollar",			quantity: TagUnitQuantity.TUQ_CURRENCY,		    	singular: "US dollar",							plural: "US dollars",							abbrev: "$",			conversion: NaN}],
	[TagUnit.TU_EUROS,				{keyWord: "euro",			quantity: TagUnitQuantity.TUQ_CURRENCY,		    	singular: "euro",								plural: "euros",								abbrev: "€",		 	conversion: NaN}],
	[TagUnit.TU_POUNDS,				{keyWord: "pound",			quantity: TagUnitQuantity.TUQ_CURRENCY,				singular: "pound",								plural: "pounds",								abbrev: "GBP",		 	conversion: NaN}],
	[TagUnit.TU_PPD,				{keyWord: "ppd",			quantity: TagUnitQuantity.TUQ_MASS_FLOWRATE,		singular: "pound per day",						plural: "pounds per day",						abbrev: "ppd",			conversion: 1.0}],
	[TagUnit.TU_GFD,				{keyWord: "gfd",			quantity: TagUnitQuantity.TUQ_FLUX,			    	singular: "gallon per sf per day",				plural: "gallons per sf per day",				abbrev: "gfd",			conversion: 1.0}],
	[TagUnit.TU_BPA,				{keyWord: "BPA",			quantity: TagUnitQuantity.TUQ_BPA,					singular: "Bisphenol A",						plural: "Bisphenol A",							abbrev: "BPA",			conversion: 1.0}],
	[TagUnit.TU_USIEMENS_PER_CM,	{keyWord: "uSPerCm",		quantity: TagUnitQuantity.TUQ_CONDUCTIVITY,	    	singular: "microsiemen per centimeter",			plural: "microsiemens per centimeter", 			abbrev: "µS/cm",		conversion: 1.0}],
	[TagUnit.TU_SF,				    {keyWord: "sf",				quantity: TagUnitQuantity.TUQ_AREA,			    	singular: "square foot",						plural: "square feet",							abbrev: "sf",			conversion: 1.0}],
	[TagUnit.TU_SM,				    {keyWord: "sm",				quantity: TagUnitQuantity.TUQ_AREA,			    	singular: "square meter",						plural: "square meters",						abbrev: "m²",			conversion: ft_m * ft_m}],
	[TagUnit.TU_DEGREES,			{keyWord: "deg",			quantity: TagUnitQuantity.TUQ_ANGLE,				singular: "degree",								plural: "degrees",								abbrev: "deg",			conversion: 1.0}],
	[TagUnit.TU_RADIANS,			{keyWord: "radians",		quantity: TagUnitQuantity.TUQ_ANGLE,				singular: "radian",								plural: "radians",								abbrev: "rad",			conversion: 180 / Math.PI}],
	[TagUnit.TU_GFD_PER_PSI, 		{keyWord: "gfdPerPsi", 		quantity: TagUnitQuantity.TUQ_SPECIFICFLUX, 		singular: "flux per pound per square inch", 	plural: "flux per pound per square inch", 		abbrev: "gfd/psi", 		conversion: 1.0}],
	[TagUnit.TU_D_PER_MG, 			{keyWord: "dollarPerMG", 	quantity: TagUnitQuantity.TUQ_COST_PER_VOLUME, 		singular: "$ per million gallons", 				plural: "$ per million gallon", 				abbrev: "$/MG", 		conversion: NaN}],
	[TagUnit.TU_E_PER_CM, 			{keyWord: "euroPerCM", 		quantity: TagUnitQuantity.TUQ_COST_PER_VOLUME, 		singular: "EUR per cubic meter", 				plural: "EUR per cubic meter", 					abbrev: "€/m³", 		conversion: NaN}],
	[TagUnit.TU_E_PER_ML, 			{keyWord: "euroPerML", 		quantity: TagUnitQuantity.TUQ_COST_PER_VOLUME, 		singular: "EUR per million liter", 				plural: "EUR per million liter", 				abbrev: "€/ML", 		conversion: NaN}],
	[TagUnit.TU_P_PER_CM, 			{keyWord: "poundPerCM", 	quantity: TagUnitQuantity.TUQ_COST_PER_VOLUME, 		singular: "pound per cubic meter", 				plural: "pound per cubic meter", 				abbrev: "GBP/m³", 		conversion: NaN}],
	[TagUnit.TU_P_PER_ML, 			{keyWord: "poundPerML", 	quantity: TagUnitQuantity.TUQ_COST_PER_VOLUME, 		singular: "pound per million liter", 			plural: "pound per million liter", 				abbrev: "GBP/ML", 		conversion: NaN}],
	[TagUnit.TU_INST_FLUX, 		 	{keyWord: "gPerSF", 		quantity: TagUnitQuantity.TUQ_INSTANTANEOUS_FLUX,	singular: "instantaneous flux", 				plural: "instantaneous flux", 					abbrev: "g/ft²", 		conversion: 1.0}],
]);

export const TagQuantityMap: Map<TagUnitOptions, TagUnitQuantity> = new Map([
    ['angle',               TagUnitQuantity.TUQ_ANGLE],
    ['apparent-power',      TagUnitQuantity.TUQ_APPARENT_POWER],
    ['area',                TagUnitQuantity.TUQ_AREA],
    ['bpa',                 TagUnitQuantity.TUQ_BPA],
    ['concentration',       TagUnitQuantity.TUQ_CONCENTRATION],
    ['conductivity',        TagUnitQuantity.TUQ_CONDUCTIVITY],
    ['cost-per-volume',		TagUnitQuantity.TUQ_COST_PER_VOLUME],
    ['currency',            TagUnitQuantity.TUQ_CURRENCY],
    ['current',             TagUnitQuantity.TUQ_CURRENT],
    ['energy',              TagUnitQuantity.TUQ_ENERGY],
    ['energy-cost',         TagUnitQuantity.TUQ_ENERGYCOST],
    ['flow-rate',           TagUnitQuantity.TUQ_FLOWRATE],
    ['flux',                TagUnitQuantity.TUQ_FLUX],
    ['fraction',            TagUnitQuantity.TUQ_FRACTION],
    ['frequency',           TagUnitQuantity.TUQ_FREQUENCY],
    ['length',              TagUnitQuantity.TUQ_LENGTH],
    ['mass',                TagUnitQuantity.TUQ_MASS],
    ['mass-flow-rate',      TagUnitQuantity.TUQ_MASS_FLOWRATE],
    ['power',               TagUnitQuantity.TUQ_POWER],
    ['pressure',            TagUnitQuantity.TUQ_PRESSURE],
    ['reactive-power',      TagUnitQuantity.TUQ_REACTIVE_POWER],
    ['specific-capacity',   TagUnitQuantity.TUQ_SPECIFICCAPACITY],
    ['specific-energy',     TagUnitQuantity.TUQ_SEC],
    ['specific-flux',       TagUnitQuantity.TUQ_SPECIFICFLUX],
    ['temperature',         TagUnitQuantity.TUQ_TEMPERATURE],
    ['time',                TagUnitQuantity.TUQ_TIME],
    ['time-span',           TagUnitQuantity.TUQ_TIMESPAN],
    ['turbidity',           TagUnitQuantity.TUQ_TURBIDITY],
    ['voltage',             TagUnitQuantity.TUQ_VOLTAGE],
    ['volume',              TagUnitQuantity.TUQ_VOLUME],
    ['weight',              TagUnitQuantity.TUQ_WEIGHT],
]);

// Map of SI units
const sIMap = new Map([
	[TagUnitQuantity.TUQ_FLOWRATE,  TagUnit.TU_LPS],
	[TagUnitQuantity.TUQ_PRESSURE,  TagUnit.TU_PASCALS],
	[TagUnitQuantity.TUQ_VOLUME,    TagUnit.TU_LITERS],
	[TagUnitQuantity.TUQ_SEC,   	TagUnit.TU_KW_HR_PER_ML],
	[TagUnitQuantity.TUQ_LENGTH,    TagUnit.TU_METERS],
]);

interface Rate {
	unit: TagUnit;
	time: TagUnit;
}

export const RateMap: Map<TagUnit, Rate> = new Map([
	[TagUnit.TU_GPM, { unit: TagUnit.TU_GALLONS, time: TagUnit.TU_MINUTES }]
]);

export const presetUnitMap: Map<string, Map<TagUnitQuantity, TagUnit>> = new Map([
	['US', new Map()],
	['SI', sIMap]
]);

export function convert(value: any, from: number, to: number, maxLineFrequency?: number) { // Value should be a number, from and to should be TagUnits
	if (from == to || value == null)	// no conversion necessary:
		return value;

	var fromQ = from & 0xFF00;
	var fromIndex = from & 0xff;
	var toQ = to & 0xFF00;
	var toIndex = to & 0xff;

	if (fromQ != toQ) {	// Invalid conversion!!
		if ((fromQ == TagUnitQuantity.TUQ_LENGTH && toQ == TagUnitQuantity.TUQ_PRESSURE) || (fromQ == TagUnitQuantity.TUQ_PRESSURE && toQ == TagUnitQuantity.TUQ_LENGTH)) {
			// The water exception:
			// Daniel, Dave and I talked about this, and we should allow a conversion between length and pressure, and
			// assume that length is water height. For example, if from is TU_FEET_HEAD and to is TU_FEET, then so the conversion
			// assuming 1 ft == 1 ft-water-head.
			// This notion of water height is so pervasive, that we need to be able to allow special conversion between length
			// and pressure:

			// This trick requires that all length units have corresponding pressure units with matching sub-indices:
			fromQ = TagUnitQuantity.TUQ_PRESSURE;		// treat both as pressure units and convert
			toQ = TagUnitQuantity.TUQ_PRESSURE;

			if (fromIndex == toIndex)	// from and to units are now the same:
				return value;
			// else fall through
		} else if ((from == TagUnit.TU_HZ && to == TagUnit.TU_PERCENT) || (from == TagUnit.TU_PERCENT && to == TagUnit.TU_HZ)) {
			if(maxLineFrequency === undefined || isNaN(maxLineFrequency))
				throw(new Error('Max line frequency is required to convert between hertz and percent'));
			if (from == TagUnit.TU_HZ)	// Hertz to %
				return value * 100 / maxLineFrequency!;
			else 						// % to Hertz
				return value / 100 * maxLineFrequency!;
		} else { // units cannot be converted:
			return NaN;
			throw(new Error(`We can't convert these units: '${UnitsMap.get(from)?.singular}' & '${UnitsMap.get(to)?.singular}'`));
		}
	}

	if (fromQ == TagUnitQuantity.TUQ_TEMPERATURE) {	// Special-case temperature conversions due to offsets:
		if (from == TagUnit.TU_DEGREES_F) {	// F to C:
			if (to !==TagUnit.TU_DEGREES_C)
				throw(new Error("We only have two temperatures units, right?"));
			return (value - 32.0) * (5.0 / 9.0);
		} else { // C to F:
			if (from != TagUnit.TU_DEGREES_C && to != TagUnit.TU_DEGREES_F)
			 	throw(new Error("We only have two temperatures units, right?"));
			return (value * 9.0 / 5.0) + 32.0;
		}
	}

	// Straightforward conversion:
	let toUnit = UnitsMap.get(to)!;
	if(isNaN(toUnit!.conversion))	// not an offset conversion
		throw(new Error("Bad Unit conversion"));
	let fromUnit = UnitsMap.get(from)!;
	if(isNaN(fromUnit!.conversion))	// not an offset conversion
		throw(new Error("Bad Unit conversion"));
	let conversion = toUnit.conversion / fromUnit.conversion;

	return value / conversion;
};