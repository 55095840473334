import Page  from "./page";
import { createElement } from "../elements";
import './mappage.css';
import ChartView from "../views/chartview";
import './chartpage.css'
import owner from "../../owner";
interface ChartPageProps {

}

export default class ChartPage extends Page {
    wrapper: HTMLElement;
    tagWrapper: HTMLElement;
    chartView: ChartView;
    constructor(parent: HTMLElement, props: ChartPageProps) {
        super(parent);
        this.wrapper = createElement('div', 'chart-page__wrapper', parent);
        this.chartView = new ChartView(owner.ldc).initialize(this.wrapper);
        owner.navBar.setTitle('Chart');
    }

    resize() {}

    setProps() {}

    destroy(): void {
        this.chartView.destroy();
        super.destroy();
    }
}