import SVGWidget from "./svgwidget";


//The ValueText driver connects a node's formatted value to an svg element with textContent inside.
//options:
//	data-se-node:"<node path>" determines the node that will be subscribed to
//	data-se-show-units:[t|true / f|false]    determines if units will be shown (optional, default false)
export default class ValueText extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

		this.showUnits = SVGWidget.readFlag(element,"seShowUnits",'false');
		this.devices = this.setupDevices([''],true);
		this.setupNodes(['']);
	}
	update(node){
		this.element.textContent = node.getFormattedText(this.showUnits);
	}
}
