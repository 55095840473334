import { createElement } from "../elements";
import './dropdown.css';
import owner from "../../owner";

export default class Dropdown {
    event: MouseEvent;
    options: string[];
    selectCallback: (selection: string)=>void;
    offsetX: number;
    offsetY: number;
    container: HTMLElement;
    scrollBlocker: (e: WheelEvent)=>void;
    cancel: (e: MouseEvent | TouchEvent)=>void;
    constructor(event: MouseEvent, options: string[], selectCallback: (selection: string)=>void, offsetX?: number, offsetY?: number) {
        
        if (owner.dropdown) owner.dropdown.destroy();
        owner.dropdown      = this;
        this.event          = event;
        this.options        = options;
        this.selectCallback = selectCallback;
        this.offsetX        = offsetX ?? 0;
        this.offsetY        = offsetY ?? 0;
        this.event.stopPropagation();

        this.container = createElement('div', 'dropdown__container');
        for (let i=0;i<this.options.length; i++) {
            let option = createElement('div', 'dropdown__option', this.container, this.options[i]);
            option.onclick = (e) => {
                e.stopPropagation();
                this.selectCallback(this.options[i]);
                this.destroy();
            }
        }
        let windowWidth = window.innerWidth;
        let transformOriginText = '';

        if (this.event) {
            if (this.event.pageX < 200) { // 1.
                this.container.style.left = this.event.pageX + 'px';                            //  1.____________2.
                transformOriginText = 'left';                                                   //   /            \
            }                                                                                   //  |   Option 1   |                                                                  
            else {       // 2.                                                                  //  |   Option 2   |
                this.container.style.right = window.innerWidth - this.event.pageX + 'px'                             //  |   Option 3   |
                transformOriginText = 'right';                                                  //   \____________/
            }                                                                                   //  1.            2.
            if (window.innerHeight - this.event.pageY < 200) {
                this.container.style.bottom = window.innerHeight - this.event.pageY + 'px';     
                transformOriginText += ' bottom';                                                //   /            \
            }                      
            else {                                                                              
                this.container.style.top = this.event.pageY + 'px';  
                transformOriginText += ' top';                                                //   /            \                                                    
            }
        }
        /*
        else if (this.offsetX) {
            if (this.offsetX < window.innerWidth / 2)
                this.container.classList.add('dropdown__right');
            this.container.style.left = this.offsetX + 'px';
        }
        else
            this.container.classList.remove('dropdown__right');

        if (this.offsetY) {
            if (this.offsetX < window.innerHeight / 2)
                this.container.classList.add('dropdown__up');
            else 
                this.container.style.top = (this.offsetY - this.container.clientWidth) + 'px'
        }
        */
        this.container.style.transformOrigin = transformOriginText;
        document.body.appendChild(this.container);
        this.cancel = (e) => {
            if (!this.container.contains(e.target as HTMLElement)){
                this.destroy()
            }
        }
        window.addEventListener('click', this.cancel, {passive: false});
        window.addEventListener('touchstart', this.cancel, {passive: false});

        this.scrollBlocker = (e) => {
            e.preventDefault()
        };
        window.addEventListener('wheel', this.scrollBlocker, {passive: false});
    }
    destroy() {
        this.container.removeChildren();
        if (this.container.parentElement === document.body)
            document.body.removeChild(this.container);

        window.removeEventListener('click', this.cancel);
        window.removeEventListener('touchstart', this.cancel);
        window.removeEventListener('wheel', this.scrollBlocker);
        window.onclick = null;
        owner.dropdown = undefined;
    }
}
