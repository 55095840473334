import { TagAttribute } from "../../../lib/tag";
import { RegisterWidget, Widget } from "../../../lib/widget";
import type { Tag, TagDefinition } from "../../../lib/tag";
import { Role } from "../../../../role";
import { PeriodColors } from "../utils";
import template from './ticker.html';

interface PeriodElement {
    transitionTime: number;
    element: HTMLDivElement;
    startTimeLabel: HTMLParagraphElement;
}

@RegisterWidget({ tag: 'control-ticker', displayName: 'Control Ticker' })
export class ControlTicker extends Widget {
    currentTimeElement: HTMLElement;
    wrapper: HTMLElement;
    intervalSeconds: number = 60 * 60 * 24; //24 hours
    blocks: PeriodElement[] = [];
    pendingRefresh: boolean = false;
    callbackID: number;
    intervalID: ReturnType<typeof setInterval>;
    periods: Tag[];
    refreshID: ReturnType<typeof setInterval>;
    @TagAttribute({
        displayName: 'DPO Folder',
        supportedTypes: ['folder'],
        supportedRoles: [Role.ROLE_DPO_FOLDER],
    }) dpoFolder: TagDefinition;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    enliven() {
        let dpo = this.dpoFolder.tag;
        this.callbackID = dpo.device.ldc.registerGraph(this);
        this.periods = dpo.findByRole(Role.ROLE_TLC_PERIOD);
        this.periods.forEach(period => {
            this.subscribeToTags(period.findByRole(Role.ROLE_SCHEDULE_YEAR))
            this.subscribeToTags(period.findByRole(Role.ROLE_SCHEDULE_MONTH))
            this.subscribeToTags(period.findByRole(Role.ROLE_SCHEDULE_DAY_OF_MONTH))
            this.subscribeToTags(period.findByRole(Role.ROLE_SCHEDULE_DAY_OF_WEEK))
            this.subscribeToTags(period.findByRole(Role.ROLE_SCHEDULE_TIME_OF_DAY))
        })
        this.wrapper = this.shadowRoot?.getElementById('periods')!;
        this.intervalID = setInterval(() =>this.updateBlocks(), 1000);
        this.refreshID = setInterval(() =>this.refreshSchedule(), 1000 * 60 * 10);
        this.currentTimeElement = this.shadowRoot?.getElementById('time')!;
    }

    onScheduleResponse(info: [{time: number, index: number}]) {
        for (let block of this.blocks) {
            block.element.parentElement?.removeChild(block.element);
        }
        this.blocks = [];
        for (let period of info) {
            let block = this.createBlock(period.time, this.periods[period.index].getDisplayName(), PeriodColors[period.index]);
            this.blocks.push(block);
        }
        this.updateBlocks();
        this.pendingRefresh = false;
    }

    refreshSchedule() {
        if (this.pendingRefresh)
            return;
        this.pendingRefresh = true;
        let startDate = new Date();
        let endDate = new Date(startDate.getTime() + this.intervalSeconds * 1000);
        let device = this.dpoFolder.tag.device;
        device.ldc.getSchedule(this.callbackID, device.id, endDate.getTime() / 1000);
    }

    updateBlocks() {
        for (let i=1; i< this.blocks.length; ++i) {
            let percent = (this.blocks[i].transitionTime - this.blocks[i-1].transitionTime) / this.intervalSeconds;
            this.blocks[i-1].element.style.width = percent * 100 + '%';
        }
        this.currentTimeElement.textContent = new Date().format('%HH:%mm:%ss');
    }

    update(tag: Tag): void {
        let period = tag.parent;
        if (!period.roles.has(Role.ROLE_TLC_PERIOD))
            throw (new Error('Invalid subscribed tag'));
        this.refreshSchedule();
    }

    createBlock(transitionTime: number, name: string, color: string): PeriodElement {
        let block = document.createElement('div');
        let colorDiv = document.createElement('div');
        let startTimeLabel = document.createElement('p');
        startTimeLabel.textContent =  new Date(transitionTime * 1000).format('%HH:%mm');
        let endTimeLabel = document.createElement('p');
        colorDiv.style.backgroundColor = color;
        colorDiv.textContent = name;
        colorDiv.classList.add('color');
        block.classList.add('period');
        block.appendChild(startTimeLabel);
        block.appendChild(endTimeLabel);
        block.appendChild(colorDiv);
        this.wrapper.appendChild(block);
        return {
            transitionTime: transitionTime,
            element: block,
            startTimeLabel: startTimeLabel,
        }
    }

    protected onDisconnect(): void {
        clearInterval(this.intervalID);
        clearInterval(this.refreshID);
    }
}