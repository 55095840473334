import { Attribute } from "../../lib/attributes";
import { TagAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './select.html'

/**
 * Base Select class
 */
@RegisterWidget({tag: 'select-widget', template: template, displayName: 'Select'})
export class SelectWidget extends Widget {
    buttonLimit: number = 12;
    isOverLimit: boolean = false;
    _value: number;
    onchange = ()=>{};
    _disabled: boolean = false;
    protected select: HTMLSelectElement;
    protected optionMap: Map<number, HTMLOptionElement> = new Map();

    get disabled() {
        return this._disabled
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.shadowRoot?.querySelectorAll('option').forEach(inputElement => inputElement.disabled = this._disabled);
    }

    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'One',
        2: 'Two',
        3: 'Three'
    };


    get value(): number {
        return this._value;
    }

    set value(v: number) {
        if (this.optionMap.has(v))
            this.select.value = v.toString();
        else
            return;
        this._value = v;
        this.onchange();
    }

    protected connectedCallback(): void {
        let entries = Object.entries(this.valueMap);
        this.select = this.shadowRoot?.getElementById('selector') as HTMLSelectElement;
        for (let [value, label] of entries) {
            let option = document.createElement('option');
            option.value = value.toString();
            option.textContent = label;
            this.optionMap.set(parseInt(value), option);
            this.select.appendChild(option);
        }
        this.select.onchange = () => this.value = parseInt(this.select.options[this.select.selectedIndex].value);
    }
}

@RegisterWidget({tag: 'tag-select', displayName: 'Select', section: 'Setpoint'})
export class TagSelect extends SelectWidget {
    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    enliven() {
        try {

            this.select.onchange = () => {
                let value = parseInt(this.select.options[this.select.selectedIndex].value);
                this.valueTag.tag.setPendingValue(value, this);
                this.value = value;
            }
            this.disabled = !this.valueTag.tag.isWriteable
        }
        catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
    }

    update(tag: Tag): void {
        this.value = tag.getValue(this);
    }
}

@RegisterWidget({tag: 'select-week-of-month', displayName: 'Week of Month Select', roles: ['NthWeek'], section: 'Setpoint'})
class WeekOfMonthSelect extends TagSelect {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'First',
        2: 'Second',
        3: 'Third',
        4: 'Fourth',
        5: 'Fifth',
        6: 'Sixth',
        7: 'Seventh',
        8: 'Eighth'
    };
}

@RegisterWidget({tag: 'select-month', displayName: 'Month Select', roles: ['ScheduleMonth'], section: 'Setpoint'})
class MonthSelect extends TagSelect {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
    };
}


@RegisterWidget({tag: 'radio-button-kubota-sub-state', displayName: 'MBR Level State', roles: ['Kubota_MBR_SubState'], section: 'Kubota'})
class MBRSubStateSelect extends TagSelect {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        10: 'Low Flow',
        11: 'Medium Flow',
        12: 'High Flow',
        13: 'Relax',
        14: 'DifCln',
        15: 'Relax - Waiting for UV',
        20: 'Sleep',
        21: 'Nap',
        22: 'Alarm Nap',
        30: 'CIP',
        99: 'Waiting for Power'
    };
}

