import './base64';
import { decodeString } from './base64';

/*
 * LiveData frame parser
 * Take, as input a base64-encoded string or a binary ArrayBuffer
 * Allow user to step through the frame, in the same way our c++ FrameParser works:
 */

// Define the Frame object constructor:
export default class FrameParser {
	length          : number
	marker          : number
	permission_level: number
	unused          : number
	client_zip      : number
	wv_id           : number
	sessionID       : number
	command         : number
	frame 			: DataView
	bytesLeft		: number
	ptr 			: number;
	unused_2		: number;
    constructor(data: string | ArrayBuffer) {
        if (typeof data == "string") {	// convert base64 string to ArrayBuffer:
            let response	= decodeString(data);
            this.frame		= response[0] as DataView;
            this.bytesLeft	= response[1] as number;
        } else { // assume that an ArrayBuffer is passed in:
            this.frame		= new DataView(data);	// Don't copy the buffer -- assume caller will not alter this.
            this.bytesLeft	= this.frame.byteLength;
        }
        this.ptr = 0;		// index pointing to next byte to read -- reset to zero

        // We have a frame, now parse the LiveData header:
        this.length				= this.pop_u32();
        this.marker				= this.pop_u16();
        this.permission_level	= this.pop_s8();
        this.unused				= this.pop_u8();
        this.client_zip			= this.pop_u32();
        this.wv_id				= this.pop_u32();
        this.unused_2			= this.pop_u32();
        this.command			= this.pop_u32();

    //	assert (this.length == this.ptr + 24);		// LiveData frame header is 24 bytes long
    }

	pop_u8()		{var result = this.frame.getUint8(this.ptr);		this.ptr++;	this.bytesLeft--;   return result;}
	pop_bool()	    {var result = this.frame.getUint8(this.ptr);		this.ptr++;	this.bytesLeft--;   return result ? true : false;}
	pop_s8()	    {var result = this.frame.getInt8(this.ptr);			this.ptr++;	this.bytesLeft--;   return result;}
	pop_u16()       {var result = this.frame.getUint16(this.ptr,true);	this.ptr+=2;this.bytesLeft-=2; 	return result;}
	pop_s16()       {var result = this.frame.getInt16(this.ptr,true);	this.ptr+=2;this.bytesLeft-=2;	return result;}
	pop_u32()	    {var result = this.frame.getUint32(this.ptr,true);	this.ptr+=4;this.bytesLeft-=4; 	return result;}
	pop_s32()	    {var result = this.frame.getInt32(this.ptr,true);	this.ptr+=4;this.bytesLeft-=4;  return result;}
	pop_f32()	    {var result = this.frame.getFloat32(this.ptr,true);	this.ptr+=4;this.bytesLeft-=4;  return result;}
	pop_f64()	    {var result = this.frame.getFloat64(this.ptr,true);	this.ptr+=8;this.bytesLeft-=8;  return result;}
	pop_u64()	    {
		// Note: pop_s64 implementation will be more complicated due to 2's complement negative number support.
		// Since v is an IEEE 8-byte float, only the first ~53 bits (size of mantissa) are available.
		var lo = this.pop_u32();		// low 32 bits
		var hi = this.pop_u32();		// high 32 bits
		return (hi * 4294967296) + lo;	// combined
	}

	_pop_VarIntGuts() {	// assumes properly-formed varint:
		var b = -1, lo = 0, hi = 0;
		for (var i = 0; b < 0; ++i) {
			b = this.pop_s8();		// Pop a byte off the frame
			if (i < 4)
				lo |= ((b & 0x7f) << (i*7));		// First 28 bits
			else
				hi |= ((b & 0x7f) << ((i-4)*7));	// Last 28 bits
		}
		return [hi,lo];	// combined
	}

	pop_UVarInt() {
		var a = this._pop_VarIntGuts();
		return (a[0] * 268435456) + a[1];
	}

	pop_SVarInt() {
		var a = this._pop_VarIntGuts();
		var aprime = (a[0]);
		var bprime = (a[1] >> 1) ^ -(a[1] & 1);
		return (aprime * 134217728) + bprime;	// convert from zigzag to two's-complement
	}

	skipVarInt() {
		while(this.pop_s8() < 0);
	}

	skip(bytes) {this.ptr+= bytes; this.bytesLeft-= bytes;}
	size() {return this.bytesLeft;}

	is_string_next() {
		// Returns whether a properly-formed string is next in the frame.
		// Tests:
		// 1. Are there at least 3 bytes (u_16 length and NULL terminator)
		// 2. Is the u_16 length legitimate?
		// 3. Is there a null terminator in the right spot?
		if (this.bytesLeft < 3)
			return false;	// not enough bytes for a string

		var len = this.frame.getUint16(this.ptr,true);

		if (len+1 > this.bytesLeft-2)	// +1 for null terminator, -2 for u16 len
			return false;	// len runs past end of frame data (len is not legitimate)

		if (this.frame.getUint8(this.ptr+2+len) != 0)		// no null terminator:
			return false;

		return true;	// next bytes can be safely interpreted as a null-terminated string
	}

	pop_string() {
		var result	= '';	// empty string
		var len		= this.pop_u16();
		if (len == 0xFFFF)	// All bits on
			len = this.pop_u32();

		// Very inefficient to create a bunch of strings, but I don't know of another way:
		for (var i = 0; i < len; i++)
            result += String.fromCharCode(this.pop_u8());
		this.pop_u8();	// pop unused null terminator
		return result;
	}

	pop_bytes(size: number) {
		let result	= '';	// empty string

		// Very inefficient to create a bunch of strings, but I don't know of another way:
		for (let i = 0; i < size; i++)
            result += String.fromCharCode(this.pop_u8());
		return result;
	}

	pop_buffer(size: number): ArrayBuffer {
		let buffer = new ArrayBuffer(size);
		let dataView = new DataView(buffer);
		for (let i=0;i<size;++i)
			dataView.setUint8(i, this.pop_u8());
		return buffer;
	}
};
