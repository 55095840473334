import { TagAttribute } from "../../../lib/tag";
import { RegisterWidget, Widget } from "../../../lib/widget";
import type { Tag, TagDefinition } from "../../../lib/tag";
import template from './pumpselector.html';
import { Role } from "../../../../role";

@RegisterWidget({tag: 'pump-select', template: template, displayName: 'Radio Button'})
export class PumpSelect extends Widget {
    protected select: HTMLSelectElement;
    @TagAttribute({displayName: 'Pump Folder', supportedRoles: [Role.ROLE_PUMP_BANK]}) pumpFolder: TagDefinition;
    @TagAttribute({displayName: 'Selected Pump'}) selectedPump: TagDefinition;

    protected connectedCallback(): void {
        this.select = this.shadowRoot?.getElementById('pump-select') as HTMLSelectElement;
    }

    protected enliven(): void {
        this.select.childNodes.forEach(child => this.select.removeChild(child));
        let pumps = this.pumpFolder.tag.findByRole(Role.ROLE_PUMP);
        for (let i=0;i<pumps.length; ++i) {
            let modelTag = pumps[i].findChildByRole(Role.ROLE_PUMP_MODEL);
            if (!modelTag)
                throw(new Error(`No model tag for pump: ${pumps[i].name}`));
            let option = document.createElement('option');
            option.textContent = modelTag.getValue();
            this.select.appendChild(option);
            if (pumps[i] === this.selectedPump.tag)
                this.select.selectedIndex = i;
        }

        let repairTag = this.selectedPump.tag.findChild('RepairCurve');
        this.subscribeToTag(repairTag!);
        this.select.onchange = () => {
            repairTag?.setPendingValue(this.select.selectedIndex, this);
        }
    }

    update(tag: Tag): void {
        this.select.selectedIndex = tag.getValue()
    }
}