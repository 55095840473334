import Icon from '../../../images/icons/name.svg';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './tagconfiguration.html';

@RegisterWidget({tag: 'tag-config', displayName: 'Tag Configuration', icon: Icon, section: 'Tag Information', template: template})
export class TagConfiguration extends Widget {
    @TagAttribute({displayName: 'Configurable Tag'}) configurableTag: TagDefinition;

    protected enliven(): void {

    }
}