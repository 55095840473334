import { TagUnit, convert, TagUnitQuantity } from './widgets/lib/tagunits';
import assert from './debug';
import { Device } from './device';
import FrameParser from './frameparser';
import LiveData from './livedata';
import {Node}   from './node';
import { Role } from './role';
import { Tag } from './widgets/lib/tag';

export interface PumpCurves {
	headPolynomial: number[];
	powerPolynomial: number[];
	npshrPolynomial: number[];
}

export interface PumpSnapshot {
	flows: number[];
	heads: number[];
	efficiencies: number[];
	powers: number[];
}

export interface PumpRecord {
	timestamp: number;
	curves: PumpCurves;
	snapShots: PumpSnapshot[];
}

export interface PumpTwin {
	records: PumpRecord[];
	factoryCurves: PumpCurves;
	factoryTimestamp: number;
	latestCurves: PumpCurves;
	latestTimestamp: number;
	bepFlow: number;
	shutoffHead: number;
	zeroHeadFlow: number;
	factoryBEPFlow: number;
	name: string;
}

export class PumpTwins {
	device: Device;
	isRequested: boolean = false;
	pumpSystem: Node;
	graphID: number;
	isComplete: boolean = false;
	flowConversion: number;
	headConversion: number;
	effConversion: number;
	maxSpeed: number;
	speedConversion: number;
	pumps: Node[];
	private _twins: Map<Tag, PumpTwin> = new Map();
	maxZeroHeadFlow: number = 0;
	maxShutoffHead: number = 0;
	nameMap: Map<string, PumpTwin> = new Map();
    constructor(device: Device) {
        this.device         = device;
        this.isComplete     = false;
    }

    populate() {
        if (!this.isRequested) {
            let rootNode 	= this.device.tree.nodes[0]!;
            this.pumpSystem = rootNode.findChildByRole(Role.ROLE_PUMP_BANK)!;	// Save a pointer to the pump system node

            if (this.pumpSystem) {
				this.graphID        = this.device.ldc.registerGraph(this)
                if (!this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW)) return;
                this.flowConversion	= convert(1, TagUnit.TU_GPM, this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW)!.units);
                this.headConversion	= convert(1, TagUnit.TU_FEET_HEAD, this.pumpSystem.findChildByRole(Role.ROLE_PUMP_HEAD)!.units);
                this.effConversion	= 1.8866337e-2 / this.flowConversion / this.headConversion;
                let maxSpeedNode	= this.pumpSystem.findChildByRole(Role.ROLE_MAX_SPEED_HZ)!;
				let maxSpeedValue 	= maxSpeedNode.getValue();
                this.maxSpeed		= convert(maxSpeedValue, maxSpeedNode.units, this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) ?? TagUnit.TU_HZ, maxSpeedValue);
                this.pumps		= this.pumpSystem.findByRole(Role.ROLE_PUMP);	// Find all the pump folder nodes
                if (this.pumps.length > 0) {
                    var end		= new Date().getTime() * 1000;		// Most recent pump curve requested
                    var start	= 0;	// Oldest pump curve requested
                    let config  = (1 << this.pumps.length) - 1;

                    var ldc = this.device.ldc;

                    ldc.fm.buildFrame(LiveData.WVC_PUMP_CURVES, this.device.id, this.graphID);
                    ldc.fm.push_u64(start);
                    ldc.fm.push_u64(end);
                    ldc.fm.push_u64(config);
                    ldc.send();
					this.isRequested = true;
                }
            }
			else {
				this.isComplete = true;
				this.device.onPumpTwinsComplete();
			}
        }
    }

    onPumpCurvesResponse(fp: FrameParser) {
        let start		= fp.pop_u64();	// The start time we asked for
		let end			= fp.pop_u64();	// The last time we asked for
		let config		= fp.pop_u64();	// The pumps we asked data of
		for (var i = 0; i < this.pumps.length; ++i) {
			if (!(config & (1 << i)))	// We didn't ask for this pump
				continue;				// Skip it

			let twin: Partial<PumpTwin> = {};
			twin.name = this.pumps[i].findChildByRole(Role.ROLE_PUMP_MODEL)?.getValue();
			let records: PumpRecord[] = [];
			let recordCount = fp.pop_u8();
			for (let j = 0; j < recordCount; ++j) {
				let record: Partial<PumpRecord> = {
					timestamp: fp.pop_u64(),
					curves: {
						headPolynomial: [],
						powerPolynomial: [],
						npshrPolynomial: []
					}
				};

				var headCurveTerms	= fp.pop_u8();
				for (let k = 0; k < headCurveTerms; ++k)
					record.curves!.headPolynomial.push(fp.pop_f64() * this.headConversion / Math.pow(this.flowConversion, k));

				var powerCurveTerms = fp.pop_u8();
				for (let k = 0; k < powerCurveTerms; ++k)
					record.curves!.powerPolynomial.push(fp.pop_f64() / Math.pow(this.flowConversion, k));

				if (start === 0 && end === 0) {;
					var npshrTerms = fp.pop_u8();
					for (let k = 0; k < npshrTerms; ++k)
					record.curves!.npshrPolynomial.push(fp.pop_f64() * this.headConversion / Math.pow(this.flowConversion, k));
				}

				var snapshotCount = fp.pop_u16();
				let snapShot: PumpSnapshot = {
					flows: [],
					heads: [],
					powers: [],
					efficiencies: []
				}
				for (let k = 0; k < snapshotCount; ++k) {
					let flow	= fp.pop_f32();	// Pump flow in GPM
					let head	= fp.pop_f32();	// Pump head in ft
					let power	= fp.pop_f32();	// Shaft power in kW
					let speed	= fp.pop_f32();	// Pump speed in Hz
					let ratio	= speed == 0 ? 1 : this.maxSpeed / speed;

					snapShot.flows.push(flow * this.flowConversion * ratio);				// Pro rate everything up to full speed
					snapShot.heads.push(head * this.headConversion * ratio * ratio);
					snapShot.powers.push(power * ratio * ratio * ratio);
					snapShot.efficiencies.push(snapShot.flows[k] * snapShot.heads[k] * this.effConversion / snapShot.powers[k]);
				}

				records.binsert(record, (record1, record2) => {return record1.timestamp - record2.timestamp});	// Keep the curves in order
            }
			let firstRecord 		= records[0];
			twin.factoryCurves 		= firstRecord.curves;
			twin.factoryTimestamp 	= firstRecord.timestamp;
			twin.factoryBEPFlow	= findBestEfficiencyFlow(twin.zeroHeadFlow!, twin.factoryCurves!.headPolynomial, twin.factoryCurves!.powerPolynomial);
									// This is the current pump curve
			let lastRecord = records[records.length - 1];
			twin.latestCurves 		= lastRecord.curves;
			twin.latestTimestamp 	= lastRecord.timestamp;
			var flowStep = 100*this.flowConversion;		// How much we jump to check and see if the head is negative
			var flow = flowStep;						// Start off with a small flow and keep jumping up until head gets negative
			while(twin.zeroHeadFlow === undefined) {	// Find the head at zero flow
				if (twin.latestCurves.headPolynomial.evaluatePolynomial(flow) <= 0)	// Found a head less than 0!
					twin.zeroHeadFlow = twin.latestCurves.headPolynomial.solvePolynomial(flow-flowStep, flow, 0, 0.5); // Get a little more accurate on zero head flow
				else									// Head was positive
					flow += flowStep;					// Keep looking at a bigger flow for the zero head flow
			}

			twin.shutoffHead 	= Math.max(twin.latestCurves!.headPolynomial.evaluatePolynomial(0), twin.factoryCurves ? twin.factoryCurves.headPolynomial.evaluatePolynomial(0) : 0);
			twin.bepFlow 		= findBestEfficiencyFlow(twin.zeroHeadFlow!, twin.latestCurves!.headPolynomial, twin.latestCurves!.powerPolynomial);

            this._twins.set(this.pumps[i], twin as PumpTwin);
        }

		for (var i = 0; i < this.pumps.length; ++i) {
			this.maxZeroHeadFlow 	= Math.max(this.maxZeroHeadFlow, this._twins.get(this.pumps[i])!.zeroHeadFlow);	// Take the max zero head flow
			this.maxShutoffHead 	= Math.max(this.maxShutoffHead, this._twins.get(this.pumps[i])!.shutoffHead);
		}

        this.isComplete = true;
        this.device.onPumpTwinsComplete();
        this.device.ldc.unregisterGraph(this.graphID);
    }

    getTwin(pumpNode: Tag): PumpTwin | null {
		assert(this.isComplete);
        let curves = this._twins.get(pumpNode)
        return curves ?? null;
    }

	setTwin(pumpNode: Node, twin: PumpTwin) {
		this._twins.set(pumpNode, twin);
	}

	getTwinByName(name: string): PumpTwin | null {
		return this.nameMap.get(name) ?? null;
	}

	getLatestCurves(pumpNode: Node): PumpCurves | null {
		assert(this.isComplete);
        let curves = this._twins.get(pumpNode)?.latestCurves;
        return curves ?? null;
    }

    getFactoryCurves(pumpNode: Node): PumpCurves | null {
		assert(this.isComplete);
        let curves = this._twins.get(pumpNode)?.factoryCurves;
        return curves ?? null;
    }

	getFactoryHeadCurve(pumpNode: Node): number[] | null {
        let curve = this._twins.get(pumpNode)?.factoryCurves.headPolynomial;
		return curve ?? null;
	}

	clear() {
		this.isComplete = false;
		this._twins.clear();
		this.maxShutoffHead = 0;
		this.maxZeroHeadFlow = 0;
	}
}

export function findBestEfficiencyFlow(zeroHeadFlow: number, headCurve: number[], powerCurve: number[]) {
	// Golden Segment search to find the maximum efficiency. No units are honored here.
	let resphi	= 2 - (1 + Math.sqrt(5)) / 2;
	let minFlow = 0;
	let maxFlow = zeroHeadFlow;
	let midFlow = maxFlow - resphi * (maxFlow - minFlow);

	let midEff	= midFlow * headCurve.evaluatePolynomial(midFlow) / powerCurve.evaluatePolynomial(midFlow);
	let newFlow: number;
	while (maxFlow - minFlow > 0.05) {
	    if (maxFlow - midFlow > midFlow - minFlow)
	    	newFlow = midFlow + resphi * (maxFlow - midFlow);
		else
		    newFlow = midFlow - resphi * (midFlow - minFlow);

	    let newEff = newFlow * headCurve.evaluatePolynomial(newFlow) / powerCurve.evaluatePolynomial(newFlow);
	    if (newEff > midEff) {	// We find a closer point to the maximum
	    	midEff = newEff;
		    if (maxFlow - midFlow > midFlow - minFlow) {
		    	minFlow = midFlow;
		    	midFlow = newFlow;
		    } else {
		    	maxFlow = midFlow;
		    	midFlow = newFlow;
		    }
	    } else {				// We just found a better edge point
		    if (maxFlow - midFlow > midFlow - minFlow)
		    	maxFlow = newFlow;
		    else
		    	minFlow = newFlow;
	    }
	}
	return midFlow;	// Return the flow that produces this efficiency
};