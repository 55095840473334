import assert from './debug';
// This file defines the MouseCapture object.

// MouseCapture constructor function:
// Attach a MouseCapture object to the element, and capture all mousemove, and mouseup messages at the this.element.ownerDocument level.
// Monitor mouseup at the element level to give feedback (through the 'clicked' attribute) that the mouse was released over
// the original element.
//
// MouseCapture object is invalid after the creator receives the mouseup event.
// User should 'delete' the mousecapture object upon receipt of the mouseup event.
// 
// Parameters:
// element(required):			DOM element that received the mousedown message
// mouseDownEvent(required):	the mousedown event received by 'element'
// mouseHandler(optional):		callback method to call for mousemove and mouseup messages
// If mouseHandler is not provided, we will simply call element.onmousedown, the element's mousedown handler.
// Attributes available to the user:
// mc.downX, mc.downY:		position of mouse down click, relative to the web page
// mc.x, mc.y:				current position of mouse, relative to web page
// mc.deltaX, mc.deltaY:	total change in mouse position from mouse down click position
// mc.dx, mc.dy:			incremental change in mouse position since most recent mousemove event
// mc.clicked:				(only available during mouseup evt) TRUE if mouse was reelased over 'element'

export default function MouseCapture(element, mouseDownEvent, mouseHandler) {
	assert (element);
	assert (element.onmousedown);
	// console.log('MouseCapture ctor.');

	// Store the target element:
	this.element = element;
	MouseCapture.element = element;
	MouseCapture.mc = this;
	
	// Use mouseHandler if given, otherwise use element's 'onmousedown' handler:
	this.handler = mouseHandler || element.onmousedown;
	
	if (mouseDownEvent.targetTouches && mouseDownEvent.targetTouches.length == 1) {
		mouseDownEvent.clientX = mouseDownEvent.targetTouches[0].clientX;
		mouseDownEvent.clientY = mouseDownEvent.targetTouches[0].clientY;
	}
	
	// Store the mousedown location:
	this.downX	= mouseDownEvent.clientX + this.element.getScrollLeft();	// page-relative coordinates where mouse was originally depressed
	this.downY	= mouseDownEvent.clientY + this.element.getScrollTop();
	this.x		= this.downX;								// page-relative current mouse position
	this.y		= this.downY;
	this.deltaX	= 0;										// change in mouse position from down position
	this.deltaY = 0;
	this.dx		= 0;
	this.dy		= 0;
	this.clicked = false;	// Set to 'true' if mouse was over 'element' when released
	
	if (mouseDownEvent.type == 'mousedown') {
		this.element.ownerDocument.addEventListener('mousemove', this._move, true);
		this.element.ownerDocument.addEventListener('mouseup', this._up, false);	// useCapture==false so element event listener will get a shot at 'mouseup'
		
		// Add element event listener to definitively determine if the mouse was released over 'element' (element may not be rectangular ala svg):
		element.addEventListener('mouseup', this._elementUp, true);
	} else {
		this.element.ownerDocument.addEventListener('touchmove', this._move, true);
		this.element.ownerDocument.addEventListener('touchend', this._up, false);	// useCapture==false so element event listener will get a shot at 'mouseup'
		
		// Add element event listener to definitively determine if the mouse was released over 'element' (element may not be rectangular ala svg):
		element.addEventListener('touchend', this._elementUp, true);
	}
	
	// Change cursor to grabby hand: 
	//FIXME: Eliminates the text-selector cursor, but doesn't display the move cursor
	this.element.ownerDocument.body.style.cursor = 'cursor:move !important;';
//	this.rulePos = this.element.ownerDocument.styleSheets[0].insertRule('*{ cursor: move !important;}',this.element.ownerDocument.styleSheets[0].cssRules.length);
	
	// Tell this.element.ownerDocument to not start selecting text:
	// TODO: Consider moving this to SEweb.js, since it is a global callback
	this.ossSaved = this.element.ownerDocument.onselectstart = function(){ return false; };
	
	// Don't propagate this event:
	mouseDownEvent.stopPropagation();
	
	return this;
};

// Prototype values for the MouseCapture object:
MouseCapture.prototype = {
	_move: function(evt) {
		var mc = MouseCapture.mc;
		
		// First, store previous x,y in dx,dy:
		mc.dx = mc.x;
		mc.dy = mc.y;
		
		if (evt.targetTouches && evt.targetTouches.length == 1) {
			evt.clientX = evt.targetTouches[0].clientX;
			evt.clientY = evt.targetTouches[0].clientY;
		}
				
		// Calculate new x,y:
		mc.x = evt.clientX + mc.element.getScrollLeft();
		mc.y = evt.clientY + mc.element.getScrollTop();
		
		// Compute dx,dy from delta b/w old and new x,y:
		mc.dx = mc.x - mc.dx;
		mc.dy = mc.y - mc.dy;
		
		// FIXME: haven't really figured out yet how to calculate page-relative coordinates. Right now, scrolling is 
		// not getting figured in. getScrollXxx() is not working. -pcs
//		console.log('clientY=' + evt.clientY + ' screenY=' + evt.screenY, ' scrollTop()=' + mc.element.getScrollTop(), 'getBoundingClientRect().top=', mc.element.getBoundingClientRect().top);
		
		// Store the pixel change from mousedown position:
		mc.deltaX = mc.x - mc.downX;
		mc.deltaY = mc.y - mc.downY;
		
		// Call element's handler with 'this' set to 'element':
		if (mc.dx != 0 || mc.dy != 0)	// suppress sequential identical mousemove events (and there are a lot of them with a mousepad)
			mc.handler.call(mc.element, evt);
		
		// Don't propagate this event:
		evt.stopPropagation();
	},
		
	_up: function(evt) {
		var mc = MouseCapture.mc;
		
		// Pass the mouseup message to the element's handler:
		mc.handler.call(mc.element, evt);
			
		// Don't propagate this event:
		evt.stopPropagation();
		
		mc.destroy(evt);
	},
	
	_elementUp: function(evt) {	// Mouse was released over the element that was registered with this Mouse Capture object:
		//console.log ('MouseCapture: Mouse button was released with mouse pointer over original element.');
		MouseCapture.mc.clicked = true;
	},
	
	destroy: function (evt) {
		var mc = MouseCapture.mc;
		
		if (evt.type == 'mouseup') {	// Unhook the element mouseup listener and this.element.ownerDocument listeners
			mc.element.removeEventListener('mouseup', mc._elementUp, true);
			this.element.ownerDocument.removeEventListener('mouseup', mc._up, false);
			this.element.ownerDocument.removeEventListener('mousemove', mc._move, true);
		} else {
			mc.element.removeEventListener('touchend', mc._elementUp, true);
			this.element.ownerDocument.removeEventListener('touchmove', mc._move, true);
			this.element.ownerDocument.removeEventListener('touchend', mc._up, false);
		}
		
		// Delete our cursor rules that we added onmousedown
		this.element.ownerDocument.body.style.cursor = '';
		this.element.ownerDocument.onselectstart = this.ossSaved;
		
		MouseCapture.mc = null;
		MouseCapture.element = null;
	},
		
	// Class-level variables:
	element: 	null,
	mc:			null
};
