class Localization {
    constructor() {
        this.index = 0;     // Index 0 is English
        map: {};
    }

    //	if (navigator.language == "en-US")
//		this.index = 0; //1;	// Garbage
//	else if (navigator.language == "fr-FR" || navigator.language == "fr")
//		this.index = 2;	// French
//	else if (navigator.language == "ES" || navigator.language == "es")
//		this.index = 3;	// Spanish
//	
//	this.add('Previous 24 Hours', 'Ya know, recent shit');
//	this.add('Flow', 'Water Speed');
//	this.add('Total Flow', 'Total Water Speed');
//	this.add('Specific Energy', 'Power to Water Ratio');
//	this.add('Station Efficiency', 'More and more stuff');
//	this.add('Pump Health Indexes', 'Huey');
//	this.add('Repair NPV', 'Luey');
//	this.add('Station Capacity', 'Duey');
//	this.add('Operation Mode', 'No more ducks');
//	this.add('UTC', 'CTU')
//	this.add('Local', 'Where you at');
//	this.add('Site', 'Where the box at');
//	this.add('System', 'GLOBAL');
//	this.add('Accounts', 'Peoples');
//	this.add('Sign Out', 'Bail out');
//	this.add('Enable', 'Energize');
//	this.add('Change', 'Alter the Deal');
//	this.add('Sign In', 'Access');
//	this.add('Change Password', 'Change Netflix password');
//	this.add('Enable Writes', 'Go for broke');
//	this.add('Lolo alarm', 'Way low');
//	this.add('Low alarm', 'Kinda low');
//	this.add('High alarm', 'Kinda high');
//	this.add('HiHi alarm', 'Way high');
//	this.add('True alarm', 'Alarm, yo');
//	this.add('False alarm', 'Seems inconsistent');
//	this.add('True event', 'Alarmish, yo');
//	this.add('False event', 'Seems ish');
//	this.add('Enabled', 'High on life');
//	this.add('Node', 'Tag');
//	this.add('Type', 'Ilk');
//	this.add('Setting', 'Characteristic');
//	this.add('Severity', 'How bad');
//	this.add('Message', 'Custom Entry');
//	this.add('Add alarm', 'Wake me up at 2 am');
//	this.add('Acknowledge', 'Cool beans');
//	this.add('Show', 'Don\'t hide');
//	this.add('All', 'Erething');
//	this.add('Active', 'Excitable');
//	this.add('Unacknowledged', 'Unremarked');
//	this.add('Activated', 'Time');
//	this.add('Duration', 'How long');
//	this.add('Tag', 'Node');
//	this.add('Condition', 'State');
//	this.add('Category', 'Kind');
//	this.add('Acknowledged', 'Okay');
//	this.add('Time', 'When');
//	this.add('Event', 'What');
//	this.add('Pump Repair Financial Factors', 'Fill this out PLZ');
//	this.add('Annual Discount Rate', 'Cost of Money');
//	this.add('Expected Pump Life', 'How fast you break shit');
//	this.add('Average Energy Cost', 'How much A/C costs');
//	this.add('Pump Repair Financial Analysis', 'The proof Kempner should fix pumps');
//	this.add('Pump Health', 'How broke');
//	this.add('Repair As', 'Mimic who');
//	this.add('Projected Energy Reduction', 'Dollah pah year');
//	this.add('Present Value of Energy Reduction', 'Future money today');
//	this.add('Repair Cost', 'How much to pull out the piece of shit');
//	this.add('Total Repair Cost', 'How much to pull out all the pieces of shit');
//	this.add('Net Present Value', 'Do you make money');
//	this.add('Return on Investment', 'Ratio of money made');
//	this.add('Internal Rate of Return', 'Weirdo metric that I dont get');
//	this.add('Recommendations', 'What you should do');
//	this.add('Recalculating', 'Working on it');
//	this.add('Startup Log', 'What had happened was');
//	this.add('This is where my log would go...If I had one', 'Test');
//	this.add('Sample warning', 'Test Warning');
//	this.add('Sample error', 'Test Error');
//	this.add('Starting program', 'Launching missles');
//	this.add('Modify Tags', 'Modify Nodes');
//	this.add('Pull the current tag map from the device', 'Download');
//	this.add('Push a new tag map to the device', 'Upload');
//	this.add('Pull tags', 'Download tags');
//	this.add('Maintain Volume', 'Volume de Maintain');
//	this.add('Volume So Far', 'Volume de So Far');
//	this.add('Filling Tank', 'Tank de Filling');
//	this.add('Min Flow', 'Flow de Min');
//	this.add('Max Flow', 'Flow de Max');
//	this.add('Level', 'Val de Level');
//	this.add('Min Level', 'Level de Min');
//	this.add('Max Level', 'Level de Max');
//	this.add('Maintain Range', 'Range de Maintain');
//	this.add('Submit', 'Go Supa Fast');
//	this.add('Year', 'Ano');
//	this.add('YTD', 'ATD');
//	this.add('Month', 'Enero');
//	this.add('MTD', 'ETD');
//	this.add('Day', 'Dei');
//	this.add('Clear All', 'Eviserate');
//	this.add('Remove Line', 'Evict');
//	this.add('Avg only', 'Just lines');
//	this.add('Min/Max', 'Shaded lines');
//	this.add('Minute', 'Minuto');
//	this.add('Hour', 'Hora');
//	this.add('Week', 'Siete Dei');
//	this.add('Custom', 'Customs');
//	this.add('Live', 'Moving');
//	this.add('Static', 'Boring');
//	this.add('red', 'rojo');
//	this.add('black', 'blacko');
//	this.add('blue', 'blueo');
//	this.add('green', 'greeno');
//	this.add('purple', 'purpleo');
//	this.add('cyan', 'cyano');
//	this.add('maroon', 'maroono');
//	this.add('gray', 'grayo');
//	this.add('lightgreen', 'lightgreeno');
//	this.add('orange', 'orangeo');
//	this.add('pink', 'pinko');
//	this.add('gold', 'goldo');
//	this.add('yellow', 'yellowo');
//	this.add('ON', 'agogo');
//	this.add('OFF', 'no go');
//	this.add('Search here', 'Does anybody use this');
//	this.add('Only show logged nodes', 'Only show interesting nodes');
//	this.add('second', '1 s');
//	this.add('minute', '60 s');
//	this.add('hour', '3600 s');
//	this.add('day', '86400 s');
//	this.add('days', 'deis');
//	this.add('resolution', 'revolution');
//	this.add('Export as CSV', 'Get her done');
//	this.add('Save Graph', 'I member');
//	this.add('Saved graphs', 'membered');
//	this.add('Run', 'Erun');
//	this.add('Stop', 'Estop');
//	this.add('Start', 'Estart');
//	this.add('Full', 'Efull');
//	this.add('Deadband', 'Dedbad');
//	this.add('Hand', 'Mano');
//	this.add('On', 'Going');
//	this.add('Off', 'Nope');
//	this.add('Auto', 'Mine');
//	this.add('Reset', 'Undo')
//	this.add('Re-Enable', 'Enable again')
//	this.add('DPO_HOA', 'Local start/stop');
//	this.add('Speed Control', 'Doing the VFDs');
//	this.add('On/Off Control', 'Doing the run');
//	this.add('Auto Speed Switch', 'Should be the here');
//	this.add('Manual', 'Man is ual');
//	this.add('Reference Speed', 'Doing speed');
//	this.add('Tested Pump Head', 'Test Head');
//	this.add('Tested Shaft Efficiency', 'Test Efficiency');
//	this.add('Factory Pump Head', 'Factory Head');
//	this.add('Factory Shaft Efficiency', 'Factory Efficiency');
//	this.add('Current Pump Head', 'Current Head');
//	this.add('Current Shaft Efficiency', 'Current Efficiency');
//	this.add('Historical Operation', 'What its been do');
//	this.add('Full Speed NPSHr', 'NPSHr');
//	this.add('Current NPSHr', 'Scaled NPSHr');
//	this.add('Both a user name and password are required', 'Fill out both things, dude');
//	this.add('Enter a valid 6 digit token', 'It\'s supposed to be 6 digits, genius');
//	this.add('Passwords do not match', 'Type the same thing twice');
//	this.add('Previous password cannot be used', 'No tag backs');
//	this.add('8 characters required: one letter, one number, and one symbol', 'Look, our password standards suck');
//	this.add('Invalid user name / password combination', 'No can do');
//	this.add('Logged out', 'You are the weakest link');
//	this.add('Logged in', 'Hackerman wins again');
//	this.add('Cannot connect to server', 'Whoville is gone, bro');
//	this.add('Connection lost to server', 'Whoville left us');
//	this.add('WebClient out of date. Please refresh page', 'Ctrl + shift + r');
//	this.add('Scan with an authenticator app or manually copy key to app and enter a valid token', 'Settin up 2 factor');
//	this.add('Password requires resetting', 'Gotta reset you');
//	this.add('Two-Factor Setup', 'Setting up Dos factories');
//	this.add('Two-Factor Login', 'Loggin in Dos factories');
//	this.add('Password Reset', 'Password Setre');
//	this.add('Password Changed', 'Time is on my side');
//	this.add('Change Failed', 'We suck as a unit');
//	this.add('Enter password', 'Enter PIN number');
//	this.add('Incorrect Password', 'Wrong PIN number');
//	this.add('Missing inputs', 'Need inputs');
//	this.add('Passwords must match', 'Really, dude');
//	this.add('Disconnected', 'Nope');
//	this.add('Unsupported browser. Please use Chrome or Safari', 'No Internet Explorer, grandpa');
//	this.add('password', 'PIN Number');
//	this.add('old password', 'Old SSN');
//	this.add('new password', 'New SSN');
//	this.add('repeat password', 'Repeat SSN');
//	this.add('login name', 'Pretend to be');
//	this.add('6-Digit Token', 'Token Black');
//	this.add('Station', 'SCADA');
//	this.add('Solver', 'SEC Map');
//	this.add('Pumps', 'Bombeos');
//	this.add('Assets', 'If Broke');
//	this.add('Results', 'If Moolah');
//	this.add('Analysis', 'Ovivo');
//	this.add('Export', 'Custom Graphs');
//	this.add('Alarms', 'Event History');
//	this.add('Alarm Config', 'Configure Alarms');
//	this.add('Reports', 'PDFs');
//	this.add('Config', 'Define tags');
//	this.add('Discharge Pressure', 'Over Pressure');
//	this.add('Suction Pressure', 'Under Pressure');
//	this.add('Pump Status', 'Look on the SCADA tab');
//	this.add('Current Specific Energy Map', 'This is what you are paying for');
//	this.add('Flow Deadband', 'Q DB');
//	this.add('Specific Energy Deadband', 'SEC DB');
//	this.add('Solution Age', 'It\'s Been');
//	this.add('Percent of Convex Hull', 'How Good');
//	this.add('Current Flow Range', 'Qmin to Qmax');
//	this.add('Min Suction Pressure', 'Min Never Used');
//	this.add('Max Suction Pressure', 'Max Never Used');
//	this.add('Min Discharge Pressure', 'We have to deliver water');
//	this.add('Max Discharge Pressure', 'But don\'t blow out valves');
//	this.add('Power Limit', 'Expensive, mutha fucka');
//	this.add('Source Tank Max Flow', 'Dont kill source tanks');
//	this.add('Target Tank Min Flow', 'Dont kill target tanks');
//	this.add('Power', 'kWage');
//	this.add('Freq', 'Spins');
//	this.add('BEP Flow', 'Magic Math');
//	this.add('Current Operating Point', 'You are here');
//	this.add('Recommended Operating Point', 'You could be here');
//	this.add('Available Points', 'Can do');
//	this.add('Available Solution', 'Should do');
//	this.add('Valid Points', 'Cant do');
//	this.add('Current Solution', 'Want to do');
//	this.add('Invalid Points', 'Never do');
//	this.add('Over Power Points', 'Expensive to do');
//	this.add('Measured Operating Point', 'Fudge Factor');
//	this.add('Selected Point', 'Blinky McBlink');
//	this.add('Constraints', 'Lines');
//	this.add('Summary', 'Overview');
//	this.add('Pump Data', 'Bombeo Data');
//	this.add('Pump Head', 'Head of Pump');
//	this.add('Shaft Efficiency', 'Efficiency of Pump');
//	this.add('Pump Flow', 'Flow of Pump');
//	this.add('History from', 'Xs from');
//	this.add('through', 'to');
//	this.add('to', 'through');
//	this.add('Time running outside POR', 'Evil time');
//	this.add('This pump only', 'Only him');
//	this.add('Apply affinity laws', 'Scale to full speed');
//	this.add('PHI', 'Health');
//	this.add('PHI of', 'Health of');
//	this.add('from', 'to');
//	this.add('From', 'To');
//	this.add('Shaft Power', 'Power of Shaft');
//	this.add('Motor Voltage', 'Volts of Motor');
//	this.add('Motor Current', 'Amps of Motor');
//	this.add('Frequency', 'Speed');
//	this.add('NPSHa', 'Available Back Pressure');
//	this.add('Percent BEP', 'BEP Ratio');
//	this.add('Starts', 'Start Total');
//	this.add('Total Run Time', 'Hour Meter');
//	this.add('Last Tested', 'Last Updated');
//	this.add('Pump Health Index', 'Index of Health');
//	this.add('Start Failed', 'Cant start');
//	this.add('Stop Failed', 'Cant stop');
//	this.add('Preferred Operating Range', 'POR');
//	this.add('Allowable Operating Range', 'AOR');
//	this.add('Pump Settings', 'Settings de Bombeo');
//	this.add('Use AOR', 'Use big range');
//	this.add('Frequency Range', 'Speed Range');
//	this.add('Text Expiration', 'Shaft Power limit');
//	this.add('Shaft Power Limit', 'No more than');
//	this.add('NPSHr Safety Factor', 'Back pressure factor');
//	this.add('Offline', 'Ded');
//	this.add('Start Delay Time', 'Off for at least');
//	this.add('Stop Delay Time', 'On for at least');
//	this.add('Start Fail Time', 'Give up start after');
//	this.add('Stop Fail Time', 'Give up start after');
//	this.add('Exercise', 'Workout');
//	this.add('when off for', 'when slept for');
//	this.add('Pump Test', 'Testing');
//	this.add('Test Configuration', 'How to');
//	this.add('Test Mode', 'How to Test');
//	this.add('Automated', 'Auto');
//	this.add('Operator-Guided', 'Dude in loop');
//	this.add('Test Pump', 'isTest');
//	this.add('Pre-Test Checklist', 'Before');
//	this.add('Test Controls', 'How to do');
//	this.add('Abort Test', 'Wanna get away');
//	this.add('Abort', 'Stop');
//	this.add('Collect Point', 'Grab');
//	this.add('End Test', 'Finish');
//	this.add('Test Status', 'And is does');
//	this.add('Test Progress', 'How far');
//	this.add('Estimated Time Remaining', 'Going for');
//	this.add('Post-Test Checklist', 'After');
//	this.add('Nothing here', 'Nada');
//	this.add('Using the last', 'Using');
//	this.add('hours of historical data, this analysis has concluded that the following repairs are in the owner\'s best economic interest', 'hours of stuff, you should do');
//	this.add('No repairs recommended', 'You good');
//	this.add('Volume','Sum of flow');
//	this.add('Energy','Sum of power');
//	this.add('Energy Reduction', 'Less Energy');
//	this.add('Percent Reduction', 'Less Percent');
//	this.add('Projected Reduction', 'Less Monies');
//	this.add('Energy Used', 'Sum of power');
//	this.add('Cost Reduction', 'Mooooney');
//	this.add('DPO Mode','Us Time');
//	this.add('Optimizing', 'Good');
//	this.add('Not Optimizing', 'Bad');
//	this.add('Today', 'Recent');
//	this.add('Username', 'Namo');
//	this.add('First Name', 'Primo');
//	this.add('Last Name', 'Lasto');
//	this.add('One-time Password', 'Single use pass');
//	this.add('Email', 'Spam Address');
//	this.add('Phone', 'Ununsed');
//	this.add('Access Restriction', 'Only can see');
//	this.add('Permission Level', 'Power Level');
//	this.add('Admin', 'Can See this');
//	this.add('Callouts', 'Texts in the night');
//	this.add('MFA Enabled','2 Factor');
//	this.add('MFA Reset','Reset 2 Factor');
//	this.add('User Account Management', 'Place you come to tell us you fired someone');
//	this.add('Callouts for', 'Automated texts to');
//	this.add('Severities between', 'Danger of');
//	this.add('After', 'If it has been');
//	this.add('minutes, call', 'weeks, notify');
//	this.add('Different user callouts', 'Otro namo');
//	this.add('No callouts configured', 'Aint no body');
//	this.add('and', 'und');
//	this.add('Please select a company', 'Select a company');
//	this.add('Requesting users', 'Getting peple');
//	this.add('Adding new user', 'Adding dude');
//	this.add('Successful', 'Disco');
//	this.add('Failed', 'Nogo, dude');
//	this.add('is already in use', 'is taken');
//	this.add('Changing', 'Modifying');
//	this.add('to get callouts from', 'to get texts from');
//	this.add('to not get callouts from', 'to not get texts from');
//	this.add('s callout intervals', 's text intervals');
//	this.add('s callout low severity to', 's text low severity to');
//	this.add('s callout high severity to', 's text high severity to');
//	this.add('s callout failover time to', 's text failover time to');
//	this.add('Making', 'Switching');
//	this.add('failover', 'FAIL');
//	this.add('s first name to', 's primo to');
//	this.add('s last name to', 's lasto to');
//	this.add('s password', 's PIN');
//	this.add('s email address to', 's spam filter to');
//	this.add('s phone number to', 's text number to');
//	this.add('Invalid permission level', 'Bad permission level');
//	this.add('s permission level to', 's power level to');
//	this.add('Enabling', 'Powering');
//	this.add('Disabling', 'Unpowering');
//	this.add('Resetting', 'Uncharging');
//	this.add('s account management privileges', 's fAdmin privileges');
//	this.add('s two-factor authentication key', 's 2F key');
//	this.add('Reset the two-factor key for', 'Reset the 2f key for');
//	this.add("will be prompted to setup a new two-factor key upon their next login", 'will be told to re-set up 2F');
//	this.add('Permanently enable two-factor authentication for', 'Lock on 2F for');
//	this.add('will be prompted to setup two-factor authentication upon their next login', 'will be told to set up 2F');
//	this.add('Enabling two-factor authentication for', 'Turning on 2F for');
//	this.add('s account', 's stuff');
//	this.add('able to view', 'able to see');
//	this.add('only', 'AND NO ONE ELSE');
//	this.add('all sites', 'erebody');
//	this.add('Found', 'Located');
//	this.add('accounts', 'dude-bros');
//	this.add('Invalid character', 'Bad character');
//	this.add('in new username', 'in potential account name');
//	this.add('Usernames must be at least 4 characters long', 'We need 4 characters, bro');
//	this.add('Invalid email address. Need text after the', 'INVALID! Need text after @ symbol');
//	this.add('Invalid email address. No periods adjacent to the', 'INVALID! No periods after @ symbol');
//	this.add('Invalid email address. No adjacent periods', 'INVALID! No adjacent periods');
//	this.add('Invalid email address. Unsupported character', 'INVALID! Bad character');
//	this.add('Invalid email address. @ is required', 'INVALID! Need @ symbol');
//	this.add('Invalid email address. No period after the', 'INVALID! Need a period after @ symbol');
//	this.add('Invalid phone number. Only numeric digits', 'INVALID! Only digits');
//	this.add('Invalid phone number. Should be 10 digits long', 'INVALID! Phones are 10 digits');
//	this.add('Invalid password. Password must be 8 characters long and are required to have one letter, one symbol, and one number', 'INVALID! We suck at passwords');
//	this.add('Wet Well Level', 'Poo Level');
//	this.add('High', 'Above');
//	this.add('Low', 'Below');
//	this.add('January', 'enero');
//	this.add('February', 'febrero');
//	this.add('March', 'marzo');
//	this.add('April', 'abril');
//	this.add('May', 'mayo');
//	this.add('June', 'junio');
//	this.add('July', 'julio');
//	this.add('August', 'agosto');
//	this.add('September', 'septiembre');
//	this.add('October', 'octubre');
//	this.add('November', 'noviembre');
//	this.add('December', 'diciembre');
//	this.add('Sunday', 'domingo');
//	this.add('Monday', 'lunes');
//	this.add('Tuesday', 'martes');
//	this.add('Wednesday', 'mi&#xE9;rcoles');
//	this.add('Thursday', 'jueves');
//	this.add('Friday', 'viernes');
//	this.add('Saturday', 's&#xE1;bado');
//	this.add('Alarm on', 'Fear fire foes on');
//	this.add('alarm deactivated after', 'went quiet after')
//	this.add('acknowledged', 'snoozed')
//	this.add('alarm after', 'alarm after sleeping for')
//	this.add('Another pump is trying to test already. Stop', 'Another dude is running a test. Stop')
//	this.add('from requesting a test before testing this pump', 'from testing to do this')
//	this.add('This pump can\'t be tested until it stops', 'This dude relies on that')
//	this.add('The DPO can\'t stop', 'The DPO has lost control of')
//	this.add('Ramping pump', 'Blowing up pump')
//	this.add('Waiting for the system to stablize', 'Twiddling thumbs')
//	this.add('Collecting a test data point', 'Doing math')
//	this.add('The DPO currently can\'t start the pump and the pump isn\'t running', 'The pump is dead')
//	this.add('This pump can\'t be tested right now', 'Cant test a dead pump')
//	this.add('The DPO doesn\'t have start control over this pump', 'Cant control this pump')
//	this.add('This pump can\'t be tested unless it\'s running', 'Need control to test, obviously')
//	this.add("Flip the 'Test Pump' switch to begin an operator-guided pump test, in which an operator will be responsible for varying this pump's flow (for instance, by clamping a discharge valve) and telling the DPO when to collect data points", 'Wanna manually test? Do it')
//	this.add('This pump is a candidate for automatic testing', 'This pump can be tested automatically if you werent a little bitch')
//	this.add('If the DPO is given control of the pump\'s speed, it can be automatically tested', 'If the DPO gets speed control, we could do auto stuff')
//	this.add('The DPO currently can\'t control this pump\'s speed', 'The DPO has lost this speed reins')
//	this.add('This pump can\'t be tested automatically right now', 'This pump cant be tested like magic right now')
//	this.add('The DPO can\'t control this pump\'s speed', 'This speed control knob doesnt exist')
//	this.add('This pump can\'t be tested automatically. This pump must be tested in Operator-Guided mode', 'This pump cant be tested like magic right now. You gotta do stuff')
//	this.add("Flip the 'Test Pump' switch to begin an automated pump test, in which the DPO will vary this pump's speed to adjust flow and automatically collect data points", 'Flip the test for magic times')
//	this.add('The DPO will start the pump test when all the items in the pre-test checklist are acknowledged', 'Pre checklist now')
//	this.add('Another pump is going through the pre-test checklist. Wait until that test is complete before trying to test this pump', 'Another pump is doing the pre test stuff')
//	this.add('Please wait for the operator-guided test to begin', 'Going through the motions')
//	this.add('Please wait for the automated test to begin', 'Motion of the ocean is beginning')
//	this.add('Another pump is about to begin a test. Wait until that test is complete before trying to test this pump', 'Hold your damn horses')
//	this.add('Testing this pump in automated mode', 'Motion has begun')
//	this.add('Testing this pump in operator-guided mode', 'You know what were doing, right')
//	this.add('Take the pump to its maximum flow', 'Go to warp, Mr. Sulu')
//	this.add('Lower the pump\'s speed to around', 'Lower speed to')
//	this.add('Lower the pump\'s flow to around', 'Lower flow to')
//	this.add("Flip the 'Collect Point' switch when you are satisfied with the flow", 'Hit the button to take a point')
//	this.add("Flip the 'End Test' switch when you are satisfied with the points you have taken. This will make the DPO fit new pump curves using all the datapoints you have collected", 'Hit the other button to end')
//	this.add("Collecting a test data point. The 'Collect Point' switch will reset when enough data has been collected", 'Mathin this bitch up')
//	this.add("Another pump is testing. Wait until that test is complete before trying to test this pump", 'Hold your horses a different way')
//	this.add("The DPO won't allow another pump test until all the items in the post-test checklist are acknowledged", 'Post checklist please')
//	this.add('Savings', 'Less Money Mo Problems');
//	this.add('Volume Today', 'Flow Integral');
//	this.add('Energy Today', 'Power Integral');
//	this.add('SEC Today', 'Integral Ratio');
//	this.add('Estimated Savings Today', 'Estimated Less Money');
//	this.add('External HOAs', 'Means of Start Control');
//	this.add('PLC Control', 'Otra control');
//	this.add('External Auto Speed Switches', 'Means of Speed Control');
//	this.add('DPO HOA', 'Local Mode');
//	this.add('Overall HOA', 'Total Mode');
//	this.add('Auto Start', 'Auto Estart');
//	this.add('Auto Speed Enabled', 'In the zone');
//	this.add('Manual Speed', 'Local Speed');
//	this.add('Auto Speed', 'Remote Speed');
//	this.add('f Speed Controlled', 'f DPO Vary');
//	this.add('f Start Controlled', 'f DPO Start');
//	this.add('Start Delay', 'Wait to start');
//	this.add('Stop Delay', 'Wait to stop');
//	this.add('Hour Meter', 'Total started');
//	this.add('Current Hour Meter', 'Since start/stop');
//	this.add('Started', 'Cranked');
//	this.add('Stopped', 'Smothered');
//	this.add('Start Fail', 'Oops');
//	this.add('Stop Fail', 'Poos');
//	this.add('VFDNominal Power', 'Nom Power de VFD');
//	this.add('Actual Speed', 'Really doin');
//	this.add('Input Power', 'Power de VFD');
//	this.add('At Speed', 'Stable');
//	this.add('Running', 'Spinning');
//	this.add('Faulted', 'Orange');
//	this.add('Fault Code', 'Orange code');
//	this.add('Fault Description', 'Orange letters');
//	this.add('Pump Starter', 'ignition switch');
//	this.add('Max Speed', 'Upper Speed Limit');
//	this.add('Min Speed', 'Lower Speed Limit');
//	this.add('Accel1', 'SpeedUpRate1');
//	this.add('Accel2', 'SpeedUpRate2');
//	this.add('Decel1', 'SlowDownRate1');
//	this.add('Decel2', 'SlowDownRate2');
//	this.add('Pump Make', 'Make');
//	this.add('Pump Model', 'Model');
//	this.add('Nominal Pump Power', 'Nom Power de Pump');
//	this.add('Nominal Motor Power', 'Nom Power de Motor');
//	this.add('Min Run Scope', 'Alternation Interval');
//	this.add('Min Run Time', 'Alternation Setpoint');
//	this.add('Recent Run Time', 'Alternation Value');
//	this.add('f Preferred', 'Wanna Alternate');
//	this.add('Pump Health Metric', 'P-H-I');
//	this.add('Test', 'Wanna Test');
//	this.add('Curve Timestamp', 'Last Tested');
//	this.add('Min Test Interval', 'Alarm on Old Test');
//	this.add('Min BEP Ratio', 'Min POR');
//	this.add('Max BEP Ratio', 'Max POR');
//	this.add('Min AOR', 'Min A+O_R');
//	this.add('Max AOR', 'Max A+O_R');
//	this.add('NPSH Safety Factor', 'Needin back pressure');
//	this.add('Lockout Range Min', 'Min Lockout');
//	this.add('Lockout Range Max', 'Max Lockout');
//	this.add('Base Load', 'VFD Usage');
//	this.add('Motor Power', 'Power of Motor');
//	this.add('Wear Cost', 'Cost of wear');
//	this.add('Repair PV', 'Repair is good');
//	this.add('Repair Curve', 'Which is which');
//	this.add('Test Out of Date', 'Alarm old curve');
//	this.add('Repair1', 'Fix1');
//	this.add('Repair2', 'Fix2');
//	this.add('Repair3', 'Fix3');
//	this.add('Repair4', 'Fix4');
//	this.add('Repair5', 'Fix5');
//	this.add('Cost', 'In for');
//	this.add('PV', 'Too lazy to type PV');
//	this.add('Head', 'Tete');
//	this.add('Outside POR', 'Doin evil');
//	this.add('Under NPSHr', 'Doin other evil');
//	this.add('System Curve State', 'SC State');
//	this.add('System Curve Timestamp', 'SC TS');
//	this.add('Max Speed Hz', 'Line Frequency');
//	this.add('SEC', 'SE no C');
//	this.add('System Model Timestamp', 'Last full solve');
//	this.add('Max Model Age', 'When to solve');
//	this.add('State', 'Etat');
//	this.add('Energy Cost', 'Dollar Per');
//	this.add('Suction Elevation', 'Suction height');
//	this.add('Discharge Elevation', 'Discharge height');
//	this.add('Total Power', 'Total wattage');
//	this.add('Rated Capacity', 'Theoretical Can pump');
//	this.add('Actual Capacity', 'Can Actually pump');
//	this.add('Worn Pump Cost', 'wearin energies');
//	this.add('Worn Pump Seconds', 'wearing time');
//	this.add('Interest Rate', 'Cost o Money');
//	this.add('Pump Life', 'PLife');
//	this.add('Calculating Repairs', 'Repetitive solve');
//	this.add('Water Power', 'Power de Water');
//	this.add('System Power', 'Power de System');
//	this.add('System Efficiency', 'Eff de System');
//	this.add('Setpoint', 'SP');
//	this.add('Feedback', 'FB');
//	this.add('Clamped', 'Lesser');
//	this.add('Power Meters', 'Multilins');
//	this.add('Meter Demands', 'Multilin settings');
//	this.add('Current Demand', 'Total KW');
//	this.add('Demand Limit', 'KW Allowed');
//	this.add('Limit Enabled', 'Limit in Use');
//	this.add('SEC Deadband', 'Delta SEC');
//	this.add('Current Config', 'Current Point');
//	this.add('Target Config', 'Target Point');
//	this.add('Ideal Config', 'Ideal Point');
//	this.add('Next Config', 'Next Point');
//	this.add('Aggregate Min', 'qMin');
//	this.add('Aggregate Max', 'qMax');
//	this.add('Manual Test Mode', 'Man Test');
//	this.add('Manual Test Collect Data', 'Man Collect');
//	this.add('Test Start Time', 'Tset Start');
//	this.add('Test Step Time', 'Tset Step');
//	this.add('Test Complete Time', 'Tset Complete');
//	this.add('Test Percent', 'Tset Percent');
//	this.add('Test State', 'Tset State');
//	this.add('Pretest Checklist Done', 'PreTset Done');
//	this.add('Post Checklist Done', 'PostTset Done');
//	this.add('Target Speed1', 'Going1');
//	this.add('Target Speed2', 'Going2');
//	this.add('Target Speed3', 'Going3');
//	this.add('Target Speed4', 'Going4');
//	this.add('Target Speed5', 'Going5');
//	this.add('Ideal Speed1', 'Wanna1');
//	this.add('Ideal Speed2', 'Wanna2');
//	this.add('Ideal Speed3', 'Wanna3');
//	this.add('Ideal Speed4', 'Wanna4');
//	this.add('Ideal Speed5', 'Wanna5');
//	this.add('Actions To Sync', 'HowFar');
//	this.add('Display Advisory', 'DisplayHowFar');
//	this.add('Advisory Speed Error', 'AdvisoryHz');
//	this.add('System Curve Constant', 'SC Constant');
//	this.add('System Curve Exponent', 'SC Exponent');
//	this.add('System Curve Min Flow', 'SC Min');
//	this.add('Flow Ramp Rate', 'Flow Delta');
//	this.add('Flow Ramp Setting', 'Flow Delta SP');
//	this.add('Off Convex Hull', 'Doing okay');
//	this.add('No Valid Points', 'Doing bad');
//	this.add('Constraint Conflict', 'Doing a confuse');
//	this.add('Filtered Flow', 'Streamline Flow');
//	this.add('Filtered Pressure', 'Streamline Pressure');
//	this.add('DPO In Control', 'DPO DOING');
//	this.add('DPO Heartbeat', 'DPO TALKING');
//	this.add('Address1', 'Slave1');
//	this.add('Address2', 'Slave2');
//	this.add('Address3', 'Slave3');
//	this.add('Address4', 'Slave4');
//	this.add('Address5', 'Slave5');
//	this.add('Error', 'Uh oh');
//	this.add('Jobs Failed', 'Silence back');
//	this.add('Jobs Retried', 'Dont believe silence');
//	this.add('Disconnect Alarm', 'Tell you when gone');
//
//	// TODO: Reports need similar logic (JS side only)
//	// TODO: Event text (the ones that come from the box), VFD faults, config log, LSG form
//	
//	// Convert date strings once up front
//	for (var i = 0; i < SE.monthStrings.length; ++i)
//		SE.monthStrings[i] = this.getLocalText(SE.monthStrings[i]);
//	for (var i = 0; i < SE.dayStrings.length; ++i)
//		SE.dayStrings[i] = this.getLocalText(SE.dayStrings[i]);
// map: {}

    add(english, garbage) {
        assert(this.findTextStart(english, true) == 0, 'String ' + english + ' needs trimming');
        assert(this.findTextEnd(english, false) == english.length, 'String ' + english +' needs trimming');
        assert(this.findTextStart(garbage, true) == 0, 'String ' + garbage + ' needs trimming');
        assert(this.findTextEnd(garbage, false) == garbage.length, 'String ' + garbage +' needs trimming');
        assert(this.map[english] === undefined, 'String ' + english + ' is already taken!');
        var values = [english, garbage];
        this.map[english] = values;
    }

    isText(code, fForward) {
        if (code == 40 || code == 91) 		// Open paranthesis or open bracket
            this.openCount += (fForward ? 1 : -1);
        else if (code == 41 || code == 93)	// Close paranthesis or close bracket
            this.openCount += (fForward ? -1 : 1);
        if (this.openCount > 0)
            return false;
        return ((65 <= code && code <= 90) || (97 <= code && code <= 122) || (48 <= code && code <= 57));
    }

    findTextStart(text) {
        this.openCount = 0;
        for (var start = 0; start < text.length; ++start)
            if (this.isText(text.charCodeAt(start), true))
                break;
        return start;
    }

    findTextEnd(text) {
        this.openCount = 0;
        for (var end = text.length; end >= 0; --end)
            if (this.isText(text.charCodeAt(end - 1), false))
                break;
        return end;
    }

    toLocal(text) {
        return text
    }

    getLocalText(text) {
        /*
        // Strip whitespace/punctuation on ends and add back into result
        var start = this.findTextStart(text);
        var end = this.findTextEnd(text);
        var toConvert = text.substring(start, end);
        var array = this.map[toConvert];

        if (array) {
            var converted = array[this.index];
            if (start > 0)
                converted = text.substring(0, start) + converted;
            if (end < text.length)
                converted += text.substring(end);
            return converted;
        }
        */
        return text;
    }

}

export default new Localization;
