import {Widget} from './widget';
import assert from './debug';
import {NodeQuality} from './node'
import * as elements from './elements';
import './valuedisplay.css';
import { Role } from './role';

// Converts an html element into a basic value text display and attaches it to a LiveData node:
//

// Element:
// This is the DOM element that will be converted to a text display:

// Properties:
// node:			(optional)			tag node (logical, numeric, or text)
// fShowUnits:		(default: false)	Show units after value
// redXMask:		(default: 0)		node quality flags for which to suppress the red x

// ValueDisplay inherits Widget:
export default class ValueDisplay extends Widget  {
    constructor(element, properties, fDebug) {
        super(element, properties);
        // 'element' is required:
        assert (element, 'element is a required property');

        // Create a span element to hold the text, so we don't wipe out the redX element when we write out a new value:
        this.element = element;
        this.fDebug = fDebug;
        this.registerAsWidget(this.element);	// register this element as a widget

        // 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
        // First step: Establish default properties:
        this.fShowUnits		= false;
        this.redXMask		= NodeQuality.NQ_OUT_OF_RANGE;	    // Do not show a red x for these quality errors
        this.outOfRange		= '';						// On out of range qualities, show this text

        // Second step: copy properties over to the bar graph object:
        this.copy(properties);

        // Third step: create internal properties:
        this.fInitialized	= false;
    }

    initialize() {
        this.fInitialized = true;	// Must set before calling node.subscribe()
        this.span	= elements.createElement ('div', 'value-display__value', this.element);
        if (this.width)
            this.span.style.width = this.width + 'px';

        // Connect node, and pass in the top element to receive the red x.
        if (this.node)
            this.node.subscribe(this, this.span, this.redXMask);
        return this;
    }

    update(node) {	// node value/quality changed:
        assert(node = this.node);
        assert (this.fInitialized);
        var valueText = ''
        if(node.roles.has(Role.ROLE_PUMP_AUTO_HOA_SWITCH) || node.roles.has(Role.ROLE_PUMP_SOFTWARE_HOA) || node.roles.has(Role.ROLE_PUMP_OVERALL_HOA)) {
            valueText = (node.quality == NodeQuality.NQ_OUT_OF_RANGE) ? this.outOfRange : this.hoaText(node.getFormattedText(false, this.units, this.digits));
        }
        else
        // update innerHTML with formattedText unless out of range or 0.0 Hz
            valueText = (node.quality == NodeQuality.NQ_OUT_OF_RANGE) ? this.outOfRange : node.getFormattedText(this.fShowUnits, this.units, this.digits, undefined, this.fPending);
        if (node.getValue(this.fPending) == 0 && this.zeroText)
            this.span.textContent = this.zeroText;
        else if (node.getValue(this.fPending) == 1 && this.oneText)
            this.span.textContent = this.oneText;
        else if((valueText == '0' || valueText == '0.0') && this.fBlankZeros) // don't show a zero value if fBlankZeros is set
            this.span.textContent = '';
        else if(valueText == '0.0 Hz') 	// show pumps on VFDs that are at 0.0 Hz as OFF
            this.span.textContent = 'OFF';
        else
            this.span.textContent = valueText;
    }

    hoaText(formattedText) {
        var convertedText = '';
        switch (formattedText) {
            case '1':
                convertedText = 'Hand'
                break;
            case '2':
                convertedText = 'Off'
                break;
            case '3':
                convertedText = 'Auto'
                break;
            default :
                convertedText = '?'
        }
        return convertedText
    }

    reinitialize() {
        if (this.node) {
            this.node.unsubscribe(this);
            this.node.subscribe(this, this.span, this.redXMask)
        }
    }

    destroy() {	// Undo pretty much everything we did in initialize() (Though we do leave some random members lying around):
        assert(this.fInitialized);
        if (this.node)
            this.node.unsubscribe(this);
        this.unregisterAsWidget();
    }
};
