import {createElement, createUniqueId} from '../elements';
import { DragDropGraph } from '../graph';
import LiveData from '../livedata';
import owner from '../../owner';
import './chartview_classic.css';
import View from './view';
import MoreIcon from '../images/icons/more.svg';
import CloseIcon from '../images/icons/cancel.svg';
import FullscreenIcon from '../images/icons/fullscreen.svg';
import { Widget } from '../widget';
import ViewModal from '../viewmodal';
import { LoggedFilter } from '../tagfilter.ts'
import Dialog from '../dialog';
import assert from '../debug';
import Loader from '../loader';
import TreeView, { TreeViewTypes } from './treeview';
import { Role } from '../role';

export default class ChartViewClassic extends View {
    constructor(device, ldc) {
		super();
        this.ldc		= ldc;
        this.device 	= device;
        this.rootNode  	= this.device.tree.nodes[0];
        this.pumpSys	= this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
        this.separator 	= (1.1).toLocaleString()[1] === ',' ? ';' : ',';
		this.options 	= {useGrouping: false};	// This is the option we pass to 'toLocaleString' so it doesn't use thousand place separators
		this.nodes		= [];
		this.fAxis 		= true // flag for managing axis display state
		this.fInitResp 	= true;
    };

	initialize(parent) {
		super.initialize(parent);
        this.wrapper		= createElement('div', 'chart-view-classic__page-wrapper',this.parent)
		this.graphWrapper		= createElement('div', 'chart-view-classic__graph-wrapper', this.wrapper);
		this.graphContainer		= createElement('div', 'chart-view-classic__graph-wrapper__graph-container', this.graphWrapper);
		var menuRow				= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__menu', this.graphContainer);
		this.controlRow			= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__menu__control-row', menuRow);
		this.controlRow.id 		= 'dygraph-controls';
		this.fullButton			= createElement('img', 'chart-view-classic__graph-wrapper__graph-container__full__icon', menuRow, undefined, {'src': FullscreenIcon});
		this.fullButton.onclick = () => this.fullscreenGraph();
		this.menuButton			= createElement('img', 'chart-view-classic__graph-wrapper__graph-container__menu__icon', menuRow, undefined, {'src': MoreIcon});
		this.menuButton.onclick = () => this.dropdownMenu(this.menuButton);
		this.graphRow			= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__graph-row', this.graphContainer);
		this.legendRow			= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__legend', this.graphContainer);
		//var wrapper = createElement('div', 'bootstrap', this.parent);	// Create a wrapper for the page
		//wrapper.style.height = "100%";
		//wrapper.style.width = "100%";
		//wrapper.style.position = "absolute";
		//wrapper.style.fontSize = "small";
		//var row = createElement('div', fMobile ? 'h-100' : 'row mx-4 py-3', wrapper);
		//var treeWrapper = createElement('div', fMobile ? 'h-100' : "col-3", this.tagTreeWrapper);				// This holds the search bar and the tree
		//avar treeDiv = createElement('div', fMobile ? 'h-100 col-12 d-inline-block treeDiv' : 'col-12 d-inline-block treeDiv', treeWrapper);

		//var opts		= createElement('div', 'form-inline', this.graphContainer);		// Create a list of options horizontally
		//this.startTime 	= this.createDateTimeInput(opts, 'exportDataStartTime');				// Start date/time of historical request
		//createElement('label', 'treeOptionsLabel mx-sm-3', opts, ' to ');	// A nice little text label between the start and end inputs
		//this.endTime 	= this.createDateTimeInput(opts, 'exportDataEndTime');					// End date/time of historical request

		var date = new Date();											// Current time
		//this.endTime.value = date.format('%yyyy-%MM-%ddT%HH:%mm');		// Initialize the end date/time to right now

		date.setDate(date.getDate() - 1);								// Go back one day
		//this.startTime.value = date.format('%yyyy-%MM-%ddT%HH:%mm');	// Initialize the start date/time to one day ago
		//this.startTime.onchange = this.endTime.onchange = this.onDateChange.bind(this);

		/*
		createElement('label', 'treeOptionsLabel mx-sm-3', opts, ' at ');
		this.interval = createElement('select', 'csvTabDate', opts);				// Create a drop down selector for the interval
		createElement('option', null, this.interval, 'second').interval = 1;		// An an option for second resolution
		createElement('option', null, this.interval, 'minute').interval = 60;	// An an option for minute resolution
		createElement('option', null, this.interval, 'hour').interval = 3600;	// An an option for hour resolution
		createElement('option', null, this.interval, 'day').interval = 86400;	// An an option for day resolution
		this.interval.selectedIndex = 1;											// Start out with the minute resolution selected
		createElement('label', 'treeOptionsLabel mx-sm-3', opts, ' resolution.');
		*/

		/*
		this.download	= createElement('input', 'treeButton', opts);	// Create the input
		this.download.type		= 'button';						// Make it a button
		this.download.value		= 'Export as CSV';				// Add the text as desired
		this.download.onclick	= this.downloadData.bind(this);	// Attach the call back
		this.download.disabled	= true;							// This button starts out disabled
		*/

		this.graphID	= this.ldc.registerGraph(this);	// Register for graph data
		this.utcOffset	= date.getTimezoneOffset()*60;	// Calculate the time zone offset in seconds once

		this.graphDiv = createElement('div', 'chart-view-classic__graph-wrapper__graph-container__graph-row__graph', this.graphRow);
		//this.graph.onNodesChanged	= this.onNodesChanged.bind(this);	// When a node is removed or added
		//this.graph.onDateChange		= this.onGraphChange.bind(this);	// When the graph's range changes

		this.ldc.getGraphs(this.graphID, this.device.id);

		this.resize();
		this.fInitialized = true;
		return this;
	}

	resize() {
		if (this.dropdown) this.dropdown.removeChildren();
		if (this.fFullScreen) {
			this.modal.removeChildren();
			document.body.removeChild(this.modal);
			this.fFullScreen = false;
		}
		if (window.innerWidth < 620 == this.fAxis) {
			this.fAxis = !this.fAxis;
			this.resize();
			return;
		}
		else if (!this.graph) {
			let options = {
				drawYAxis:this.fAxis,
				axesCanChange: true
			}
			this.graphDiv.removeChildren();
			let end = new Date();
			let start = new Date(new Date().getTime() - 86400000);
			this.graph = new DragDropGraph(this.ldc, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, start, end, true, true, true, this.controlRow, this.legendRow, options);
			this.nodes.forEach(node => {
				this.graph.addNode(node, true, undefined, undefined, true);
			})
		}
		else if(this.graph.graph){
			this.graph.resize(Math.floor(this.graphDiv.clientWidth), Math.floor(this.graphDiv.clientHeight));
		}
	}

	refresh() {
		if(this.graph && this.graph.graph)
		{
			this.graph._updateTimer();
		}
	}

	fullscreenGraph() {
		this.fFullScreen = true;
		this.modal 			= createElement('div', 'chart-view-classic__modal', document.body);
		this.modalContainer	= createElement('div', 'chart-view-classic__modal__container', this.modal);
		let modalMenu		= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__menu', this.modalContainer);
		this.modalControl	= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__menu__control-row', modalMenu);
		let modalButton 	= createElement('button', 'chart-view-classic__modal__button', modalMenu);
		let modalIcon		= createElement('img', 'chart-view-classic__graph-wrapper__graph-container__menu__icon', modalButton, undefined, {'src': CloseIcon});
		this.modalGraphRow	= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__graph-row', this.modalContainer);
		this.modalGraph 	= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__graph-row__graph', this.modalContainer);
		this.modalLegend	= createElement('div', 'chart-view-classic__graph-wrapper__graph-container__menu', this.modalContainer);
		let options = {drawXAxis:true,drawYAxis:true,fRotated:true};
		this.graph.destroy();
		let end = new Date();
		let start = new Date(new Date().getTime() - 86400000);
		this.graph = new DragDropGraph(this.ldc, this.modalGraph, this.modalGraph.clientWidth, this.modalGraph.clientHeight - 25, start, end, true, true, true, this.modalControl, this.modalLegend, options);
		this.nodes.forEach(node => {
			this.graph.addNode(node, true, undefined, undefined, true);
		});
		modalButton.onclick = () => {
			this.fFullScreen = false;
			this.graph.destroy();
			this.modal.removeChildren();
			document.body.removeChild(this.modal);
			this.resize();
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1], this.graph._calculateInterval(dateArray[0], dateArray[1]));
	}

	saveGraph(name) {
		var nodes = this.graph.deviceNodes[0].nodes;
		this.ldc.fm.buildFrame(LiveData.WVC_SAVE_GRAPH, this.device.id, this.graphID);
		this.ldc.fm.push_string(name);

		var sizeOffset = this.ldc.fm.getPosition();	// Size of all the gooey data to come needs to be fixed up at the end
		this.ldc.fm.push_u32(0);					// Placeholder
		var sizeStart = this.ldc.fm.size;			// Remember the size of the frame at this point

		var newNodes = [];

		this.ldc.fm.push_u8(nodes.length);
		for (var i = 0; i < nodes.length; ++i) {
			this.ldc.fm.push_string(nodes[i].path);
			this.ldc.fm.push_string(nodes[i].color);
			this.ldc.fm.push_u8(nodes[i].flags);
			newNodes.push({path: nodes[i].path, color: nodes[i].color, flags: nodes[i].flags});
		}

		var totalSize = this.ldc.fm.getPosition();		// Get the total size
		this.ldc.fm.setPosition(sizeOffset);			// Set position back to the size of the data
		this.ldc.fm.push_u32(totalSize - sizeStart);	// Put in the correct size of the project data
		this.ldc.fm.setPosition(totalSize);				// Reset position back to the full frame
		this.ldc.send();								// Send the message
		this.ldc.getGraphs(this.graphID, this.device.id);
		/*
		for (var i = 0; i < this.oldGraphs.options.length; ++i) {
			var option = this.oldGraphs.options[i];
			if(this.saveName.value === option.graph.name)  {
				option.graph.nodes = newNodes;
				return;	// Leave without creating a new option
			}
		}
		*/
		//createElement('option', null, this.oldGraphs, this.saveName.value).graph = this.graphs.back();
	}

	onGraphResponse(fp) {
		var count = fp.pop_u32();
		this.graphs = [];
		for (var i = 0; i < count; ++i) {
			var name = fp.pop_string();
			fp.pop_u32();
			var nodeCount = fp.pop_u8();
			var nodes = [];
			for (var j = 0; j < nodeCount; ++j) {
				var path = fp.pop_string();
				var color = fp.pop_string();
				nodes.push({path: path, color: color, flags: fp.pop_u8()});
			}
			this.graphs.push({name: name, nodes: nodes});
		}
		if (this.fInitResp) {
			this.fInitResp = false;
			if (this.graphs.length > 0)
				this.loadGraph(this.graphs[0])
		}
	}

	createDateTimeInput(wrapper, inputId) {								// Create a date/time input element and format it
		let dateLine		= createElement('input', 'treeDate datetime-local', wrapper);	// Start date of historical request
		dateLine.setAttribute('id', inputId);
		dateLine.type		= 'datetime-local';									// Date time local gives a date and time, but subtracts out from UTC, unfortunately
		dateLine.step		= 60;												// They aren't allowed to set below a minute
		dateLine.required	= true;												// No 'X' on the input that allows it to be cleared
		dateLine.onkeydown  = (e) => {
			let timeNow = new Date();
			timeNow = timeNow.format('%yyyy-%MM-%ddT%HH:%mm');

			// if keycode 38 "up" and endTime is the current time, ignore because time travel requires 88 mph
			if ((e.keyCode == 38 && timeNow !== this.endTime.value) || e.keyCode == 40) {	// Up arrow is 38, down arrow is 40.
				dateLine.oldValue = dateLine.value;
				dateLine.oldDate = dateLine.valueAsNumber;
				dateLine.sign = e.keyCode == 38 ? 1 : -1;
			}
		};

		return dateLine;														// Return the date/time input
	}

	fixTime(element) {
		if (element.oldValue) {	// Fix the damn arrow keys
			// both are of the form 2017-05-15T16:08
			if (isNaN(element.valueAsNumber)) {
				element.valueAsNumber = element.oldDate + (element.sign == 1 ? -86400000 : 86400000);
			}
			var offset = new Date().getTimezoneOffset()*60;
			var date = new Date(element.oldDate + offset*1000);
			if (element.oldValue.substr(0, 4) !== element.value.substr(0, 4))	// If they changed the year
				date.setFullYear(date.getFullYear() + element.sign);
			else if (element.oldValue.substr(5, 2) !== element.value.substr(5, 2))
				date.setMonth(date.getMonth() + element.sign);
			else if (element.oldValue.substr(8, 2) !== element.value.substr(8, 2))
				date.setDate(date.getDate() + element.sign);
			else if (element.oldValue.substr(11, 2) !== element.value.substr(11, 2))
				date.setHours(date.getHours() + element.sign);
			else
				date.setMinutes(date.getMinutes() + element.sign);

			// do not fix/update the time into the future
			let updatedTime = date.getTime() - offset*1000;
			let currentTime = new Date().getTime() - offset*1000;
			if(updatedTime <= currentTime) {
				element.valueAsNumber = updatedTime;
			}
			delete element.oldValue;
		}
	}

	onDateChange(e) {	// Whenever either of the date objects change
		this.fixTime(e.target);
		if(this.isValidDates(this.startTime.valueAsNumber, this.endTime.valueAsNumber)) {
			this.graph.updateWindow(this.startTime.valueAsNumber + this.utcOffset*1000, this.endTime.valueAsNumber + this.utcOffset*1000);	// Slide the graph
		}
	}

	/* start: start time in seconds since epoch
	 * end: end time in seconds since epoch
	 * mostly avoids all the asserts buried in the underlying code, mostly
	 */
	isValidDates(start, end) {
		return 	!(isNaN(start)) && !(isNaN(end)) && start > 1000000000 && end > 0 && end < new Date() && start !== end && start < end;
	}

	onGraphChange(start, end) {	// Whenever the graph moves
		if(this.isValidDates(start, end)) {
			let newStartString = new Date(start).format('%yyyy-%MM-%ddT%HH:%mm');
			let newEndString = new Date(end).format('%yyyy-%MM-%ddT%HH:%mm');
			let isChanged = !(newStartString === this.startTime.value && newEndString === this.endTime.value);
			if(isChanged) {
				this.startTime.value = new Date(start).format('%yyyy-%MM-%ddT%HH:%mm');
				this.endTime.value = new Date(end).format('%yyyy-%MM-%ddT%HH:%mm');
			}
		}
	}

	onNodesChanged(node) {	// Whenever the nodes on the graph change
		this.nodes.push(node);
		this.resize();
		//this.saveButton.disabled = this.download.disabled = this.graph.orderedNodes.length == 0;	// Update the download CSV button's enabled status
	}

	onGraphDataResponse(interval, data) {	// We got historical data back
		var csv = 'Time' + this.separator;		// Start out with a time column
		var names = data[0];	// Get the names out of the data
		var indexes = [];		// Keep track of how far we are through each column for speed
		for (var i = 0; i < names.length; ++i) {	// For each name
			var node = this.graph.orderedNodes[i];
			if (node.fMax)
				csv += 'Min ' + names[i] + this.separator;
			csv += names[i] + this.separator;	// Add it to the first line so each column has a heading
			indexes.push(0);
			if (node.fMax)
				csv += 'Max ' + names[i] + this.separator;
		}
		csv += '\n';			// Add a carriage return after the header

		var start = 1000*(Math.ceil(this.start / interval) * interval);
		var end = 1000*this.end;
		for (var time = start; time < end; time += interval*1000) {	// Go through the entire interval they requested at the interval they requested
			csv += new Date(time).format('%yyyy/%MM/%dd %HH:%mm:%ss') + this.separator;	// Each row starts with the time
			for (var i = 0; i < names.length; ++i) {
				var node = this.graph.orderedNodes[i];
				if (node.fMax)
					csv += this.getValue(data, time, indexes, i, -1, node);
				csv += this.getValue(data, time, indexes, i, 0, node);	// Add on the value to the row
				if (node.fMax)
					csv += this.getValue(data, time, indexes, i, 1, node);
			}
			csv += '\n';	// Carriage return at the end of each row
		}

		// Create an href element that will allow the user to download the data as a CSV
		var downloadLink = document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
		downloadLink.download = this.device.siteName.replace(/ /g, '_') + '.csv';		// File name to download as
		downloadLink.href = URL.createObjectURL(new Blob([csv], {type:'text/plain'}));	// Make a blob text file URL for the CSV
		downloadLink.click();							// Simulate clicking on the hyperlink
		document.body.removeChild(this.loaderContainer);
	}

	getValue(data, time, indexes, i, offset, graphNode) {
		var setTimes	= data[1+4*i];				// Get the time column
		var setData		= data[3+4*i + offset];		// Get the min, average, or max column

		if (time < setTimes[0])	// If this is before our first time
			return this.separator;			// No deal
		while(setTimes[indexes[i] + 1] < time && indexes[i] < setTimes.length - 2)	// Keep looking through the rows until we find the correct period
			++indexes[i];
		if (setData[indexes[i]] === null || setData[indexes[i] + 1] === null)	// If either side is a null, this guy is a null
			return this.separator;
		// Else interpolate between the values on either side
		var val = (setData[indexes[i]] + (setData[indexes[i] + 1] - setData[indexes[i]]) * (time - setTimes[indexes[i]]) / (setTimes[indexes[i]+1] - setTimes[indexes[i]]));
		return val.toLocaleString(undefined, this.options) + this.separator;
	}

	dropdownMenu(element) {
		this.dropdown 					= createElement('div', 'chart-view-classic__dropdown', this.parent);
		this.dropdown.onclick			= () => {
													this.dropdown.removeChildren();
													this.parent.removeChild(this.dropdown)
												};

		let dropdownContainer 		= createElement('div', 'chart-view-classic__dropdown__container', this.dropdown);
		let exportOption 			= createElement('div', 'chart-view-classic__dropdown__option', dropdownContainer, 'Export Data');
		let addDataOption 			= createElement('div', 'chart-view-classic__dropdown__option', dropdownContainer, 'Add Tags');
		let saveOption 				= createElement('div', 'chart-view-classic__dropdown__option', dropdownContainer, 'Save Graph');
		let loadOption 				= createElement('div', 'chart-view-classic__dropdown__option', dropdownContainer, 'Load Graph');

		exportOption.onclick 		= () => {
			this.exportModal = new ViewModal(new ExportView(this.graph, (start, end, interval)=>this.downloadData(start, end, interval)), {
				maxWidth: 			    '400px',
				maxHeight: 			    '300px',
				title:				    'Export Data',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)',
			})
		}


		let tagViewProperties 		= {
			folder: 			this.device.tree.nodes[0], 		// start at the root tag
			type: 				TreeViewTypes.TVT_MULTISELECT_ACCEPT,					// let the user select any number of tags
			selectedTags: 		this.nodes,						// tell the tagView if we already have tags selected
			acceptCallback: 	this.acceptCallback.bind(this),
			selectFilters: 		{
				andFilters: [new LoggedFilter(true, false)],
				orFilters: 	[]
			}
		}
		addDataOption.onclick 		= () => new ViewModal(new TreeView(tagViewProperties), {
            maxWidth: 			    '600px',
            title:				    'Add Tags',
            titleBackgroundColor: 	'var(--color-primary)',
            titleTextColor:			'var(--color-inverseOnSurface)',
        });
		let saveButton 				= {title:'Save',callback:(e) => this.saveGraph(e), color:'var(--color-green-6)'};
		let cancelButton 			= {title:'Cancel', color:'var(--color-red-6)'};
		saveOption.onclick 			= () => {
			if (this.nodes.length == 0) {
				new Dialog(document.body, {title: 'No Graphed Tags', body: 'Please add at least one tag before saving a new graph.'});
				return;
			}
			new Dialog(document.body, {
				title:'Save Graph',
				titleBackground: 'var(--color-primary)',
				titleColor: 'var(--color-inverseOnSurface)',
				body:'Please enter a name for this graph.',
				fText: true,
				buttons: [saveButton, cancelButton]})
		}

		loadOption.onclick			= () => {
			if (this.graphs.length == 0) {
				new Dialog(document.body, {title:'No Saved Graphs', body:'You do not have any saved graphs for this device.'});
				return;
			}
			let top = {
				children:[],
				getDisplayName:()=>{return 'Select a Graph'}
			}
			this.graphs.forEach(graph => {
				top.children.push({
					children:[],
					parent:top,
					getDisplayName:()=>{return graph.name}
				})
			})
			this.loadModal = new ViewModal(new LoadView(this.graphs, (graph) => {
				this.loadGraph(graph);
			}), {
				title: 					'Load Chart',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)',
				maxWidth:				'400px',
				maxHeight: 				'500px'
			});
		}
		let clearOption = createElement('div', 'chart-view-classic__dropdown__option', dropdownContainer, 'Clear Graph');
		clearOption.onclick = () => {
			this.nodes = [];
			this.graph._clearAll();
		}
		dropdownContainer.style.top 	= element.offsetTop + 'px' ;
		dropdownContainer.style.left 	= element.offsetLeft - dropdownContainer.clientWidth + 24 + 'px';
	}

	downloadData(start, end, interval) {
		if (this.graph.orderedNodes.length == 0 || isNaN(start) || isNaN(end))
			return;	// No nodes requested or invalid start/end time. No go
		this.loaderContainer = createElement('div', 'chart-view-classic__export__loader__container', document.body);
		let loader 			= new Loader(this.loaderContainer);
		end				= start + interval * Math.floor((end - start) / interval);	// Fix up the end to be an even interval away

		var pointCount = ((end - start) / interval) * this.graph.orderedNodes.length;
		if (start >= end || pointCount > 1E6) { 	// Limit of 1M points - will get disconnected by the server if we allow them to make this request
			new Dialog(document.body, {
                title: 'Error',
                body: 'You have exceeded the maximum amount of data you can request (1 million data points). Please reduce the requested resolution or narrow your timespan.'
            })
			this.loaderContainer.classList.add('hide');
			return;
		}

		var starts = [], ends = [];
		for (var i = 0; i < this.graph.orderedNodes.length; ++i) {
			starts.push(start);	// Add the node to the list of nodes we want data for
			ends.push(end);		// Only can get averages right now
		}
		this.start = Math.floor(start);	// Drop out any partial second component
		this.end = end;
		this.ldc.getGraphData(this.graphID, this.device.id, interval, starts, ends, this.graph.orderedNodes, true);
		if (this.modal)
			this.modal.destroy();
	}

	loadGraph(graph) {
		if (!this.graph)
			return
		this.graph._clearAll();
		this.nodes = [];
		for (let i = 0; i < graph.nodes.length; ++i) {
			let node = this.device.tree.findNode(graph.nodes[i].path);
			if (node) {
				this.graph.addNode(node, true, undefined, undefined, true);
				this.nodes.push(node);
			}
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1], this.graph._calculateInterval(dateArray[0], dateArray[1]));
		this.resize();
	}

	acceptCallback(tags) {
		this.graph.clear();
		for (let i=0;i<tags.length;i++) {
			this.nodes.push(tags[i]);
			this.graph.addNode(tags[i], true, undefined, undefined, true);
			if (!(this.nodes.includes(tags[i])))
				this.nodes.push(tags[i]);
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1]);
	}

	deselectCallback(tag) {
		this.graph._removeNode(tag);
		for(var i = 0; i < this.nodes.length; ++i) {
			if(this.nodes[i] === tag) {
				this.nodes.splice(i,1);	// Found the node to remove
				break;							// No need to look further
			}
		}
	}

	destroy() {
		if (this.graphID !== undefined)
			this.ldc.unregisterGraph(this.graphID);	// We no longer want any data from the LDC
		this.parent.destroyWidgets(true);			// Don't need to destroy our graphs. That will happen here automagically
		this.parent.removeChildren();				// Delete any DOM elements left over
	}
};

class ExportView extends View {
	constructor(graph, callback) {
		super();
		assert(graph, 'export view must be passed a graph parameter');
		this.graph 				= graph;
		this.callback 			= callback;
		this.intervalSeconds 	= [1,60,3600,86400];
	}

	initialize(parent) {
		super.initialize(parent);
		this.wrapper 	= createElement('div', 'export-view__wrapper', this.parent);
		let dateWrapper = createElement('div', 'export-view__date-wrapper', this.wrapper);
		let start		= createElement('div', 'export-view__date-container', dateWrapper)
		let startId 	= createUniqueId();
		let startLabel 	= createElement('label', 'export-view__date-label', start, 'Start Date', {'htmlFor':startId})
		this.startDate	= createElement('input', null, start, null, {'type':'datetime-local', 'id':startId})
		let end			= createElement('div', 'export-view__date-container', dateWrapper)
		let endId 		= createUniqueId();
		let endLabel 	= createElement('label', 'export-view__date-label', end, 'End Date', {'htmlFor':endId})
		this.endDate	= createElement('input', null, end, null, {'type':'datetime-local', 'id':endId});
		let interval	= createElement('div', 'export-view__date-container', dateWrapper);
		createElement('div',undefined, interval, 'at');
		this.interval 	= createElement('select', 'export-view__interval', interval);
		createElement('div', undefined, interval, 'resolution');
		let intervals	= ['second', 'minute', 'hour', 'day'];
		for (let i=0;i<intervals.length;i++) {
			createElement('option', null, this.interval, intervals[i])
		}

		console.log(this.interval.options[2])

		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		let date1 		= new Date(dateArray[0]);			// start date
		let date2 		= new Date(dateArray[1]);			// end date

		this.startDate.value 	= date1.toDatetimeLocal();
		this.endDate.value 		= date2.toDatetimeLocal();

		let resolution  = createElement('div', 'export-view__date-container');

		this.startDate.onchange = this.endDate.onchange = () => this.dateChange();

		let submit 	= createElement('div', 'se-button', this.wrapper, 'Download');
		submit.onclick = () => this.callback(new Date(this.startDate.value)/1000,new Date(this.endDate.value)/1000,this.intervalSeconds[this.interval.selectedIndex]);
	}

	dateChange() {
		let start 					= new Date(this.startDate.value);
		let end 					= new Date(this.endDate.value);
		let seconds					= (end - start) / 1000;
		let cutoff 					= 100000 // maximum number of timestamps we can ask for
		this.interval.selectedIndex = 0
		for (let i=0;i<this.interval.options.length - 1;i++) {
			this.interval.options[i].disabled = false;
			if (seconds / this.intervalSeconds[i] > cutoff) {
				this.interval.options[i].disabled = true
				this.interval.selectedIndex = i + 1;
			}
		}
	}
}

class LoadView extends View {
	constructor(graphs, callback) {
		super();
		this.graphs 		= graphs;
		this.callback 		= callback;
		this.graphChecks 	= [];
	}

	initialize(parent) {
		super.initialize(parent)
		this.wrapper = createElement('div', 'view__wrapper', this.parent);
		let column = createElement('div', 'load-chart-view__column', this.wrapper);
		for (let i=0;i<this.graphs.length;++i) {
			let row 			= createElement('div', 'load-chart-view__wrapper', column);
			let graphWrapper   	= createElement('div', 'se-checkbox menu-panel__audible', row);
            let graphID        	= createUniqueId();
            let graphCheck     	= createElement('input', undefined, graphWrapper, undefined, {type:'checkbox', 'id':graphID});
			this.graphChecks.push({
				box: graphCheck,
				graph: this.graphs[i]
			});
            graphCheck.onchange = () => {
				if (graphCheck.checked) {
					for (let box of this.graphChecks) {
						box.box.checked = false;
					}
					graphCheck.checked = true;
					this.acceptButton.disabled = false;
				}
				else {
					this.acceptButton.disabled = true;
				}
			};

            createElement('label', '', graphWrapper, '', {'htmlFor':graphID});
			createElement('div', '', row, this.graphs[i].name);
		}
		let buttonRow 		= createElement('div', 'load-chart-view__button-row', this.wrapper);
		this.acceptButton = createElement('div', 'se-button', buttonRow, 'Load Chart', {disabled: true});
		this.acceptButton.setAttribute('disabled', 'true');
		this.acceptButton.onclick = () => {
			for (let box of this.graphChecks) {
				if (box.box.checked) {
					this.callback(box.graph);
					break;
				}
			}
			this.modal && this.modal.destroy();
		}
		this.fInitialized 	=	true;
		return this;
	}
}

export class ListWidget extends Widget {
	constructor() {
		super();
	}
}
