import View from "./view";
import owner, { Routes } from '../../owner';
import { createElement } from '../elements';
import Dialog from "../dialog";
import FrameParser from "../frameparser";
import './devicetokenview.css'
import { create } from "domain";
import ViewModal from "../viewmodal";
import { BlobOptions } from "buffer";
import Add from '../images/icons/add.svg'


export default class DeviceTokenView extends View {
    parent: HTMLElement;
    serviceTag: string;
    id: number;
    createTokenButton: HTMLButtonElement;
    tokenList: HTMLElement;
    constructor(parent: HTMLElement, serviceTag: string) {
        super();
        this.parent = parent;
        this.serviceTag = serviceTag;
        this.id = owner.ldc.registerGraph(this);
    }

    initialize(): DeviceTokenView {

        this.wrapper = createElement('div', 'devicetoken__wrapper', this.parent);
        let addToken = createElement('button', 'se-button devicetoken__createbutton', this.wrapper, "+ Create token");

        const tokenLifetimeOptions: Map<string, number> = new Map<string, number>([
            ["1 Month", 2630000],
            ["3 Months", 2630000 * 3],
            ["1 Year", 31536000],
            ["2 Years", 31536000 * 2],
            ["3 Years", 31536000 * 3],
            ["4 Years", 31536000 * 4],
            ["5 Years", 31536000 * 5]
        ]);

        let optionElements: HTMLElement[] = [];
        for (let [option, _] of tokenLifetimeOptions)
            optionElements.push(createElement('option', undefined, undefined, option));

        addToken.onclick = () => {
            new Dialog(document.body, {
                title: 'Define token lifetime',
                body: 'Choose a token lifetime that suits your needs. After the lifetime has passed, the token will be invalid and you will be required to regenerate in order to continue authenticating with the device on a local network.',
                fSelect: true,
                options: optionElements,
                callback: (option: any) => {
                    owner.ldc.createLocalDeviceToken(this.id, this.serviceTag, tokenLifetimeOptions.get(option.value));
                }
            });
        }
        this.tokenList = createElement('div', 'devicetokenview__token-list', this.wrapper);

        owner.ldc.getLocalDeviceTokens(this.id, this.serviceTag); // Get information on existing tokens for this device

        this.fInitialized = true;
        return this;
    }

    onGetLocalDeviceTokenMetadata(fp: FrameParser) {
        this.tokenList.removeChildren(); // Clear out the existing list
        let numberOfTokens = fp.pop_u64();
        for (var i = 0; i < numberOfTokens; ++i) // Append the new list
            new DeviceTokenTile(this.tokenList, fp.pop_string(), fp.pop_string(), fp.pop_string(), fp.pop_string(), fp.pop_u32(), fp.pop_bool());
    }

    onCreateLocalDeviceToken(fp: FrameParser) {
        let newToken = fp.pop_string();
        if (newToken == '')
            new Dialog(document.body, {
                title: 'Error',
                body: 'Failed to generate a token for this device. The device may not be configured for access on it\'s local network. Please contact Specific Energy support if you\'re interested in doing so.'
            });
        else // We got a token, show it to them
            new CreatedTokenDisplay(newToken);
        owner.ldc.getLocalDeviceTokens(this.id, this.serviceTag); // Get an updated token list for this device
    }
}

class DeviceTokenTile {
    parent: HTMLElement;
    id: string;
    username: string;
    serviceTag: string;
    deviceKey: string;
    expirationTime: number;
    isRevoked: boolean;
    isExpired: boolean;
    graphID: number;
    tokenContainer: HTMLElement;
    statusItem: HTMLElement;
    revokeButton: HTMLButtonElement;

    constructor(parent: HTMLElement, id: string, username: string, serviceTag: string, deviceKey: string, expirationTime: number, isRevoked: boolean) {
        this.parent = parent;
        this.id = id;
        this.username = username;
        this.serviceTag = serviceTag;
        this.deviceKey = deviceKey;
        this.expirationTime = expirationTime;
        this.isRevoked = isRevoked;
        this.graphID = owner.ldc.registerGraph(this);
        // Current time in milliseconds is past the expiration (this may be an inaccurate indication of expiration if the users clock is wrong)
        this.isExpired = new Date().getTime() > this.expirationTime * 1000;

        this.tokenContainer = createElement('div', 'devicetokenview__token', this.parent);
        this.createTokenInfoElement(this.tokenContainer, "ID", this.id);
        this.createTokenInfoElement(this.tokenContainer, "Created by", this.username);
        this.createTokenInfoElement(this.tokenContainer, "Service tag", this.serviceTag);
        this.createTokenInfoElement(this.tokenContainer, "Device key", this.deviceKey);
        this.createTokenInfoElement(this.tokenContainer, "Expires", new Date(this.expirationTime * 1000).format('%yyyy/%MM/%dd %HH:%mm:%ss'));
        this.statusItem = this.createTokenInfoElement(this.tokenContainer, "Status", this.getTokenStatus());

        // Button to ask whoville to revoke this token
        this.revokeButton = createElement('button', 'se-button devicetoken__createbutton', this.tokenContainer, "Revoke", { disabled: (this.getTokenStatus() == "Valid") ? false : true });
        this.revokeButton.onclick = () => {
            new Dialog(document.body, {
                title: `Are you sure?`,
                titleBackground: 'var(--color-primary)',
                titleColor: 'var(--color-inverseOnSurface)',
                body: `Revoking the token will result in an inability to use it to log into ${this.deviceKey} after the device retrieves an updated revocation list from the server. This operation cannot be undone.`,
                buttons: [
                    {
                        title: 'Revoke',
                        color: owner.colors.hex('--color-red-6'),
                        callback: () => {
                            owner.ldc.revokeLocalDeviceToken(this.graphID, this.id, this.serviceTag);
                        },
                    },
                    {
                        title: 'Cancel'
                    },
                ]
            });
        }

        this.updateTokenVisualStatus();
    }

    createTokenInfoElement(parent: HTMLElement, title: string, content: string): HTMLElement {
        let info = createElement('div', 'devicetokenview__token-info', parent);
        createElement('strong', undefined, info, `${title}: `);
        return createElement('span', undefined, info, content);
    }

    updateTokenVisualStatus() {
        let status = this.getTokenStatus();
        if (status != "Valid") {
            this.tokenContainer.style.backgroundColor = "var(--color-red-a1)";
            this.revokeButton.disabled = true;
        }
        this.statusItem.textContent = status;
    }

    getTokenStatus(): string {
        if (this.isExpired) // Expiration trumps revocation so if a revoked token expires, just say it's expired
            return "Expired";
        else if (this.isRevoked) // Not past expiration and revoked
            return "Revoked";
        return "Valid"; // Still valid
    }

    onRevokeLocalDeviceToken(fp) {
        this.isRevoked = fp.pop_bool();
        this.updateTokenVisualStatus();
    }
}

class CreatedTokenDisplay {
    token: string;
    createdTokenDialog: Dialog;
    tokenDisplay: HTMLInputElement;
    copyButton: HTMLButtonElement;
    statusMessage: HTMLParagraphElement;

    constructor(token: string) {
        this.token = token;

        this.createdTokenDialog = new Dialog(document.body, {
            title: `Copy Token`,
            titleBackground: 'var(--color-primary)',
            titleColor: 'var(--color-inverseOnSurface)',
            body: 'We will NEVER display this token again. Be sure to save it somewhere safe such as a password manager prior to inputting it into the Local Device Explorer desktop application.',
        });

        this.tokenDisplay = createElement('input', 'devicetokenview__token-text', this.createdTokenDialog.bodyDiv, undefined, { type: 'text', value: this.token, readOnly: true });
        createElement('button', 'devicetokenview__copy-button', this.createdTokenDialog.bodyDiv, "Copy Token", { onclick: this.copyToken.bind(this) });
        this.statusMessage = createElement('p', undefined, this.createdTokenDialog.bodyDiv);
    }

    copyToken() {
        // Select the input element's content
        this.tokenDisplay.select();
        this.tokenDisplay.setSelectionRange(0, this.tokenDisplay.value.length); // For mobile devices - https://www.w3schools.com/howto/howto_js_copy_clipboard.asp

        // Copy the selected content to the clipboard
        navigator.clipboard.writeText(this.tokenDisplay.value);

        this.statusMessage.textContent = "Token copied to clipboard!";
    }
}
