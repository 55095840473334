import {createElement} from '../elements';

// An object used by svg's that have modal interfaces that pop up when provoked
// This itself is not an SVG or a widget. The modal get widgets added by whatever

export default class SVGModal {
    constructor(owner) {
        this.element = createElement("div", "modal fade", createElement("div", "bootstrap", document.body));
        this.element.setAttribute("backdrop", "true");
        this.element.id = "SVGWidgetModal";
        this.element.setAttribute("tabindex", "-1");
        this.element.setAttribute("role", "dialog");
        this.element.setAttribute("aria-labelledby", "exampleModalLabel");
        this.element.setAttribute("aria-hidden", "true");
        this.element.style.display = "none";

        var dialog = createElement("div", "modal-dialog", this.element);
        dialog.setAttribute("role", "document");
        dialog.style.height = "100%";
        dialog.style.margin = "0 auto";
        dialog.style.padding = "12px";
        var content = createElement("div", "modal-content h-100", dialog);

        var header = createElement("div", "modal-header", content);
        this.title = createElement("div", "modal-title", header);
        var closeButton = createElement("button", "close", header);
        closeButton.style.boxShadow = "none";
        closeButton.setAttribute("data-dismiss", "modal");
        createElement("span", undefined, closeButton, "x")

        this.body = createElement("div", "modal-body h-100", content);

        // this.footer = createElement("div", "modal-footer", content);
	}

	setContext(owner, title) {
        this.owner = owner;
        $(this.element).on('hidden.bs.modal', this.clear.bind(this));
        this.title.textContent = title;
        $(this.element).on('shown.bs.modal', this.owner.onModal.bind(owner));
	}

    clear() {
        this.body.destroyWidgets(true);	// First, detach all LiveData nodes from all DOM elements
        this.body.removeChildren();	// Second, delete all child DOM elements
        this.title.textContent = "";
        if(this.owner.clearModal)
		    this.owner.clearModal();
        this.owner = undefined;
        $(this.element).unbind();   // Unbind any event listeners waiting for the modal to be hidden or shown
    }
}
