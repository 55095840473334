import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;
import { RegisterWidget, Widget } from "./lib/widget";
import { TagSetAttribute } from "./lib/tag";
import type { TagDefinition, Tag } from './lib/tag';
import RadialGaugeIcon from '../images/icons/gauge.svg'
import { Attribute } from './lib/attributes';
import { type RangeSetting } from '../views/attributeeditorview';
import Cruncher, { CalculateInterval, CalculateIntervalFromDates } from '../cruncher';
import { createElement } from '../elements';
import { RadioButton } from './input/radio/radiobutton';

const colors = ['red', 'black', 'blue', 'green', 'purple', '#76B7B2', 'maroon', 'gray', 'lightgreen', 'orange', 'pink', '#e6194b', '#3cb44b', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
const updateInterval: number = 100;
const theBeginning: number = new Date(0).setFullYear(2012);
const graphLag = 0;
const template = /*html*/`
<style>
:host {
    display: block;
    height: 100%;
    width: 100%;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.radio {
    height: 24px;
    font-size: 0.75em;
}
.graph {
    flex: 1;
    width: 100%;
}
.control {
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
}
</style>
<div class="wrapper">
    <div class="control"></div>
    <div class="graph"></div>
</div>
`.trim()

@RegisterWidget({ tag: 'line-chart', displayName: 'Line Chart', icon: RadialGaugeIcon, section: 'Charts' })
export class LineChart extends Widget {
    tagMap: Map<string, number> = new Map() // map of tag absolute paths to series index
    gauge: echarts.ECharts;
    cruncher: Cruncher = new Cruncher();
    updateTimer: NodeJS.Timeout;
    liveRadio: RadioButton;
    range: number;
    isLive: boolean = true;
    interval: number;
    fLock: boolean = false;
    lastRequested: number = 0;

    @Attribute({ displayName: 'Make Interactive' }) isInteractive: boolean = true;
    @Attribute({ displayName: 'Date Selection' }) dateSelection: boolean = true;
    @Attribute({ displayName: 'Highlight on Hover' }) highlightHover: boolean = true;
    @Attribute({
        displayName: 'Default Range',
        typeModifier: 'select',
        typeConfig: {
            displayNames: ['Minute', 'Hour', 'Day', 'Week', 'Year'],
            values: ['60000', '3600000', '86400000', '604800000', '31540000000']
        }
    }) defaultRange: number = 86400000;
    @Attribute({ displayName: 'Show Legend' }) showLegend: boolean = true;

    @TagSetAttribute({
        displayName: 'Setpoint Tags', shouldSubscribe: true, requiresHistorical: true, attributes: [
            {
                id: 'color',
                displayName: 'Line Color',
                type: 'String',
                typeModifier: 'color'
            },
            {
                id: 'line-style',
                displayName: 'Line Style',
                type: 'String',
                typeModifier: 'select',
                typeConfig: {
                    displayNames: ['Solid', 'Dashed'],
                    values: ['solid', 'dashed']
                }
            },
            {
                id: 'smooth-line',
                displayName: 'Smooth Line',
                type: 'Boolean'
            },
            {
                id: 'line-width',
                type: 'Number',
                displayName: 'Line Width'
            },
            {
                id: 'fill',
                type: 'Boolean',
                displayName: 'Show Fill',
            },
            {
                id: 'fill-color',
                type: 'String',
                displayName: 'Fill Color',
                typeModifier: 'color'
            },
            {
                id: 'fill-opacity',
                type: 'Number',
                displayName: 'Fill Opacity',
            },
            {
                id: 'stack-id',
                type: 'String',
                displayName: 'Stack ID'
            }
        ]
    }) liveDataTags: TagDefinition[] = [];
    @Attribute({ displayName: 'Ranges', typeModifier: 'range' }) rangeSetting: RangeSetting = {
        minimum: 0,
        ranges: []
    };

    options: EChartsOption = {
        //title: {
        //  left: 'center',
        //  text: 'Large Ara Chart'
        //},
        //toolbox: {
        //  feature: {
        //    dataZoom: {
        //      yAxisIndex: 'none'
        //    },
        //    restore: {},
        //    saveAsImage: {}
        //  }
        //},
        grid: {
            top: 10,
        },
        xAxis: {
            type: 'time',
            boundaryGap: [0, 0],
            axisLabel: {
                hideOverlap: true
            }
        },
        yAxis: [],
        dataZoom: [
            {
                type: 'inside',
                start: 75,
                end: 100,
                filterMode: 'none'
            },
            {
                start: 0,
                end: 100
            }
        ],
        series: [
            {
                tooltip: {
                    show: false
                },
                name: 'Fake Data',
                type: 'line',
                symbol: 'none',
                data: [[new Date(theBeginning), NaN]]
            }
        ],
        animation: false
    };

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let graphRow = this.shadowRoot!.querySelector('.graph') as HTMLElement;
        let controlRow = this.shadowRoot!.querySelector('.control') as HTMLElement;
        let legendRow = this.shadowRoot!.querySelector('.legend') as HTMLElement;

        this.range = this.defaultRange;
        this.interval = CalculateInterval(this.range / 1000)

        if (this.dateSelection) {
            let radio = createElement('radio-button', 'radio', controlRow, '', { valueMap: { 60000: 'Mi', 3600000: 'H', 86400000: 'Day', 604800000: 'Mo', 31540000000: 'Year', 1000000000000: 'Custom' }, value: this.defaultRange });

            radio.onchange = () => {
                this.range = radio.value;
            }
        }
        if (this.isInteractive) {
            this.liveRadio = createElement('radio-button', 'radio', controlRow, '', { valueMap: { 0: 'Live', 1: 'Static' }, value: 0 });
            this.liveRadio.onchange = () => {
                if (this.liveRadio.value == 0)
                    this.updateTimer = setInterval(() => this.timerCallback(), updateInterval);
                else
                    clearTimeout(this.updateTimer);
            }
        }

        this.gauge = echarts.init(graphRow);
        this.options.yAxis = [];
        for (let i = 0; i < this.liveDataTags.length; ++i) {
            let tag = this.liveDataTags[i].tag;
            this.tagMap.set(tag.absolutePath.replace(':', ''), i + 1);
            let attributes = this.liveDataTags[i].attributes ?? {};
            this.options.series![i + 1] = {
                name: tag.getDisplayName(),
                type: 'line',
                symbol: 'none',
                smooth: attributes['smooth-line'] === 'true',
                stack: attributes['stack-id'],
                tooltip: {
                    show: true
                },
                //areaStyle: attributes['fill'] === 'true' ? {
                //    color: attributes['fill-color'] ?? colors[i],
                //    opacity: attributes['fill-opacity'] ?? 1,
                //} : undefined,
                lineStyle: {
                    color: attributes['color'] ?? colors[i],
                    type: attributes['line-style'] ?? 'solid',
                    width: attributes['line-width'] ?? 1
                },

                data: [],
                yAxisIndex: i,

            }
            this.options.yAxis!.push({
                name: `${tag.getDisplayName(true)}`,
                nameLocation: 'middle',
                nameGap: 45,
                nameTextStyle: {
                    color: colors[i]
                },
                type: 'value',
                boundaryGap: [0, '100%'],
                min: isNaN(tag.engMin) ? 0 : tag.engMin,
                max: isNaN(tag.engMax) ? 100 : tag.engMax,
                show: i < 2,
                axisLabel: {
                    color: colors[i]
                }
            })
        }

        let start = new Date(new Date().getTime() - this.range);
        let end = new Date();
        this.requestData(start, end);

        this.gauge.on('datazoom', () => {
            clearInterval(this.updateTimer)
            let option = this.gauge.getOption();
            let start = option.dataZoom![0].startValue
            let end = option.dataZoom![0].endValue;
            this.range = end - start;
            let now = new Date().getTime();
            this.options.series![0].data[1] = [now, NaN];
            this.options.dataZoom![0].start = (1 - ((now - start) / (now - theBeginning))) * 100;;
            this.options.dataZoom![0].end = (1 - ((now - end) / (now - theBeginning))) * 100;
            let interval = CalculateIntervalFromDates(new Date(start), new Date(end));
            this.liveRadio.value = 1;

            // If we are a new interval, blow away all the old data and grab some new points
            if (this.interval != interval) {
                this.interval = interval; //@ts-ignore
                for (let i = 1; i < this.options.series!.length; ++i) {
                    this.options.series![i].data = [];
                }
                this.requestData(new Date(start), new Date(end));
            }
            else { //@ts-ignore
                for (let i = 0; i < this.liveDataTags.length; ++i) {
                    let data = this.options.series![i+1].data;
                    if (!data[0])
                        this.requestData(new Date(start), new Date(end));
                    else if (start < data[0][0])
                        this.requestData(new Date(start), new Date(data[0][0]));
                    else if (end > data[data.length - 1][0])
                        this.requestData(new Date(data[data.length - 1][0]), new Date(end));
                }
            }
            this.gauge.setOption(this.options);
        })

        this.updateTimer = setInterval(() => this.timerCallback(), updateInterval);

        //this.options.series![0].min = this.valueTag.tag.engMin;
        //this.options.series![0].max = this.valueTag.tag.engMax;
        //this.options.series![0].axisLine.lineStyle.color = [];
        //for (let i=0;i<this.rangeSetting.ranges.length;++i) {
        //    let range = this.rangeSetting.ranges[i];
        //    let lastValue = i == 0 ? this.rangeSetting.minimum : this.rangeSetting.ranges[i-1].upperLimit;
        //    let pct = (range.upperLimit - this.valueTag.tag.engMin) / (this.valueTag.tag.engMax - this.valueTag.tag.engMin);
        //    this.options.series![0].axisLine.lineStyle.color.push([pct, range.value]);
        //
        //    //this.options.series![i] = {
        //    //    name: i,
        //    //    type: 'bar',
        //    //    color: range.value,
        //    //    emphasis: {
        //    //        focus: 'series'
        //    //    },
        //    //    barWidth: '100%',
        //    //    stack: stackID,
        //    //    data: [range.upperLimit - lastValue]
        //    //}
        //}
        //this.options.series![0].axisLine.lineStyle.color.push([1, '#ff1234']);

        this.gauge.setOption(this.options);
    }

    requestData(start: Date, end: Date) {
        if (this.fLock)
            return;
        this.lastRequested = end.getTime();
        this.fLock = true;
        this.cruncher.getData(start, end, this.liveDataTags.map(tagDef=>tagDef.tag), CalculateIntervalFromDates(start, end), (data) => {
            let names = data[0]!;
            data.shift();
            for (let i = 0; i < names.length; ++i) {
                let dataArray = this.options.series![this.tagMap.get(names[i])!].data;
                let timestamps = data[i * 4];
                let avgs = data[i*4 + 2];
                if (dataArray.length == 0) // No data yet, just dump in all our new data
                    dataArray.push(...timestamps.map((timestamp, index) => [timestamp, avgs[index]]));
                else {
                    let startValue = dataArray[0][0]; // first timestamp we already have
                    let endValue = dataArray[dataArray.length - 1][0]; // last timestamp we already have
                    let beforeIndex = 0;
                    for (let j=0;j<timestamps.length;++j) {
                        if (timestamps[j] >= startValue)
                            break;
                        beforeIndex++
                    }
                    let afterIndex = timestamps.length;
                    for (let j=timestamps.length -1;j>=0;--j) {
                        if (timestamps[j] <= endValue)
                            break;
                        afterIndex--;
                    }
                    dataArray.unshift(...timestamps.slice(0, beforeIndex).map((timestamp, index) => [timestamp, avgs.slice(0, beforeIndex)[index]]));
                    dataArray.push(...timestamps.slice(afterIndex).map((timestamp, index) => [timestamp, avgs.slice(afterIndex)[index]]));
                }
                this.options.series![this.tagMap.get(names[i])!].data = dataArray;
            }

            this.fLock = false;
            this.gauge.setOption(this.options);
        });
    }

    timerCallback() {
        //this.options.series![0].data.push([new Date(), this.options.series![0].data[this.options.series![0].data.length - 1][1] + (Math.random() * 2 - 1) *5]);
        let option = this.gauge.getOption();
        let start = option.dataZoom![0].startValue
        let end = option.dataZoom![0].endValue;
        let now = new Date();
        this.options.series![0].data[1] = [now, NaN];

        if (this.isLive) {
            this.options.dataZoom![0].start = (1 - (this.range / (now.getTime() - theBeginning))) * 100;
            this.options.dataZoom![0].end = (1 - (graphLag / (now.getTime() - theBeginning))) * 100;
        }
        else {
            this.options.dataZoom![0].start = ((start - theBeginning) / (now.getTime() - theBeginning)) * 100;
            this.options.dataZoom![0].end = ((end - theBeginning) / (now.getTime() - theBeginning)) * 100;
        }
        //for (let i=0;i<this.liveDataTags.length;++i) {
        //    this.options.series![i+1].data.push([new Date(), this.liveDataTags[i].tag.getValue()]);
        //}
        this.gauge.setOption(this.options);
    }

    update(tag: Tag): void {
        //let seriesIndex = this.liveDataTags.map(e=>e.tag).indexOf(tag) + 1;
        //if (seriesIndex == 0)
        //    return;
        //
        //this.options.series![seriesIndex].data.push([new Date(), tag.getValue()]);
        //if (tag === this.valueTag.tag) {
        //    let level = this.valueTag.tag.valueText;
        //    let name = `${tag.getDisplayName(false)}\n${level} ${this.valueTag.tag.unitsText}`;
        //    this.options.series![0].markLine = {
        //        data: [{
        //            lineStyle: {
        //                width: 2,
        //                type: 'solid',
        //                color: 'black'
        //            },
        //            label: {
        //                backgroundColor: 'black',
        //                padding: 4,
        //                color: 'white',
        //                borderWidth: 2,
        //                borderRadius: 5,
        //                formatter: name,
        //                position: 'end',
        //                fontFamily: 'Source Sans Pro'
        //            },
        //            symbol: 'none',
        //            yAxis: level,
        //        }]
        //    }
        //    this.options.grid![0].right = name.length * 6;
        //}

        //this.gauge.setOption(this.options);
    }
}