import { createElement } from "../elements";
import { DragDropGraph } from "../graph";
import Card from "./card";
import owner from "../../owner";
import './chartcard.css';
import { LoggedFilter, RoleFilter } from "../tagfilter";
import {TagSocket, SocketList } from "./tagsocket__classic.js";

export default class ChartCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 800,
                height: 600,
                minWidth: 300,
                minHeight: 200,
                type: 'chart',
            }
        }
        super(parent, editor, props);
        this.element.controlRow = createElement('div', 'editor-classic__chart__controls', this.element);
        this.element.graphRow = createElement('div', 'editor-classic__chart__graph', this.element)
        this.element.legendRow = createElement('div', 'editor-classic__chart__legend', this.element);
        this.element.titleBox.value = this.name ? this.name : 'New Chart';
        this.element.titleBox.oninput();
        this.element.socketList = new SocketList([
            new TagSocket('Live Data Tags', 'Clever description here', ['name', 'color'], [new LoggedFilter(true, false)], undefined, true),
        ], this);

        if (this.tags) {
            this.element.socketList.populate(this.tags);
        }

        else {
            this.tags = [];
            this.element.socketList.modify();
        }

        let tagOption       = createElement('div', 'card__dropdown__option', undefined, 'Edit Tags');
        tagOption.onclick   = () => {
            this.element.socketList.modify();
        }
        this.element.settingsList.push(tagOption)
    }

    rebuild() {
        this.resizeCallback();
    }

    onSettingsClicked() {
        this.element.socketList.modify();
    }

    resizeCallback() {
        if (this.element.graph)
            this.element.graph.destroy();
        var end = new Date(new Date().setMilliseconds(0));
        var start = new Date(new Date().setDate(end.getDate() - 1));
        this.element.graph = new DragDropGraph(owner.ldc, this.element.graphRow, this.element.graphRow.clientWidth - 25, this.element.graphRow.clientHeight - 25, start, end, false, true, true, this.element.controlRow, this.element.legendRow, {fGroupCommonUnits:true});
        this.element.socketList.sockets.get('Live Data Tags').tags.forEach(tag => {
            if (!tag.fBadStatus)
                this.element.graph.addNode(this.resolveTag(tag), true, tag.name, tag.color, true);
        })
		this.element.graph.requestDataForAllDevices(start, end, this.element.graph._calculateInterval(start, end));
    }
}