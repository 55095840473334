// This method will be stripped from the final production code. The entire assert line gets stripped out,
// so please make sure the line of code with your assert can be removed without sacrificing functionality!
export default function assert(test: any, message?: string) {
	if (!test) {	// assertion failed:
		let errorMessage = `Assert Failed: ${message ?? 'No error message provided'}`;
		console.log(errorMessage);
		console.trace();
		debugger;	// halt execution and pull up the debugger
		throw(new Error(errorMessage));
	}
};