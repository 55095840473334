import owner from '../../owner';
import { createElement, getHumanReadableTime } from '../elements';
import './alarmsnackbar.css';
import CloseIcon from '../images/icons/close.svg'
import AlarmIcon from '../images/icons/alarms.svg';
import AlarmSound from '../sounds/alarm.mp3'
import assert from '../debug';
import { Alarm } from '../alarm';
import { Device } from '../device';

interface Snackbar {
	element: HTMLElement;
	closeTimeoutID: NodeJS.Timeout;
}

export default class AlarmSnackBar {
	parent: HTMLElement;
	wrapper: HTMLElement;
	snacks: Snackbar[];
	lastTime: number;
	dismissTime: number = 15000;
	alarm: Alarm;
	audio: HTMLAudioElement;
    constructor(parent) {
        this.parent 		= parent;
        this.wrapper 		= createElement('div', 'alarm-snackbar__wrapper', this.parent);
		this.snacks 		= [];
		this.lastTime		= Date.now() * 1000;
		for (var i = 0; i < owner.ldc.devices.array.length; ++i)
			owner.ldc.devices.array[i].alarms.registerCallback(this);
    }

    onAlarmAdded(alarm: Alarm) {

	}
  	onAlarmChanged() {
    }
  	onAlarmRemoved() {}
  	onHistorical() {}
  	endAlarmCommand(device: Device) {
		//if (window.innerWidth > 620) TODO: bring this back when we have the toolbar
		//	return;
		for (let i=0;i<device.alarms.alarms.length;i++) {
			let alarm = device.alarms.alarms[i];
			if (alarm.isActive())
			this.reportAlarm(alarm)
		}
		/*
		let alarm = {severity: -1};
		let total = 0;
		for (let i = 0; i < this.devices.length; i++) {
			console.log(this.devices[i])
			for (let j = 0; j < this.devices[i].alarms.alarms.length; j++) {
				let a = this.devices[i].alarms.alarms[j];
				console.log(a)
				if (a.isActive() && (a.tsActivated > alarm.tsActivated)) {
					console.log(a)
					alarm = a;
				}
			}
			total += this.devices[i].alarms.active;
		}
		if(total == 0)
			this.reportNominal();
		else {
			assert(alarm.severity > -1);
			this.reportAlarm(alarm);
		}
		*/
    }

    reportAlarm(alarm: Alarm) {
		if (owner.fSuppressAlarms)
			return;
		if (this.alarm != alarm && alarm.tsActivated > this.lastTime) {
			this.alarm = alarm;
			let container 	= createElement('div', 'alarm-snackbar__container', this.wrapper);
			let content 	= createElement('div', 'alarm-snackbar__content', container);
			let text   		= createElement('div', 'alarm-snackbar__text', content);
			let close  		= createElement('img', 'alarm-snackbar__close', content, undefined, {'src':CloseIcon});
			let bar 		= createElement('div', 'alarm-snackbar__bar', container)
			this.lastTime = alarm.tsActivated;
			text.classList.add('alarm-header-animated');
			container.classList.remove("alarm-header-nominal");
			const tag = alarm.tag[0] === '/' ? alarm.tag.substr(1) : alarm.tag;
			text.textContent = alarm.message + ' (' + tag + ' at ' + alarm.alarmSet.device.siteName + ', '
											+ getHumanReadableTime(alarm.tsActivated, 'for ', '') +')';
			container.classList.add('open');
			if (this.audio) {
				this.audio.pause();
				this.audio.currentTime = 0;
			}
			let snackbar = {
				element: container,
				closeTimeoutID: setTimeout(()=>this.closeNotification(snackbar), this.dismissTime)
			}
			close.onclick 	= (e) => {
				e.stopPropagation();
				if (this.audio) {
					this.audio.pause();
					this.audio.currentTime = 0;
				}
				this.closeNotification(snackbar);
			}
			container.onclick = () => {
				for (let i=0;i<this.snacks.length;i++) {
					this.closeNotification(this.snacks[i]);
				}
				owner.alarmPanel.open();
			}
			if (owner.fAudible) {
				this.audio = new Audio(AlarmSound);
				this.audio.play();
			};
			requestAnimationFrame(()=>{
				bar.style.width = '100%';
				this.snacks.push(snackbar);
				for (let i=0;i<this.snacks.length;i++) {
					this.snacks[i].element.style.bottom = (this.snacks.length - i) * 42 + 'px';
				}
			});
		}
    }

	closeNotification(snackbar: Snackbar) {
		clearTimeout(snackbar.closeTimeoutID);
		snackbar.element.style.bottom = '-36px';
		setTimeout(()=>{
			this.snacks.splice(this.snacks.indexOf(snackbar), 1);
			this.wrapper.removeChild(snackbar.element);
		}, 500);
	}
}
