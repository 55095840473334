import View from "./view";
import owner from "../../owner";
import {createElement} from '../elements';
import { FormElement } from "../formelements";
import './formview.css';
import { WritesEnabler } from "../dialog";

export default class FormView extends View {
    constructor(device, ldc) {
        super();
        this.ldc		= ldc;		// Get a pointer to the LiveDataClient
        this.device		= device;
    };

	initialize(parent) {	// Called when the tab is brought up for the first time
		super.initialize(parent);
		this.wrapper = createElement('div', 'FormTabWrapper', this.parent);
		this.id = this.ldc.registerGraph(this);					// ID so we can register for live data commands
		this.ldc.getForm(this.device.id, this.id);
		this.fInitialized = true;
		return this;
	}

	onFormReceived(fp) {
		this.fp = fp;

		this.formWrapper = createElement('div', 'FormWrapper', this.wrapper);
		var buttonWrapper = createElement('div', 'FormButtonWrapper', this.wrapper);

		this.tab = createElement('div', 'Form', this.formWrapper);	// Wrapper for the whole tab

		this.submitButton 			= createElement('input', 'FormButton SubmitButton', buttonWrapper);
		this.submitButton.onclick 	= this.onSubmit.bind(this);
		this.submitButton.type 		= 'button';
		this.submitButton.value 	= 'Submit';
		this.submitButton.disabled 	= !this.ldc.user.fTagConfig;

		this.cancelButton 			= createElement('input', 'FormButton CancelButton', buttonWrapper);
		this.cancelButton.onclick 	= this.onCancel.bind(this);
		this.cancelButton.type 		= 'button';
		this.cancelButton.value 	= 'Cancel';

		this.root = new FormElement(null, fp, this.tab, this.submitButton, this.cancelButton);
		this.root.popAttributes(fp);

		this.resize();
	}

	resize() {										// Called with the window resizes
		if (!this.fShown || !this.fInitialized)
			return;
		var height;
		if(window.innerWidth > 768)
			height = this.formWrapper.stretchHeight(150, 64);	// Stretch the table wrapper
		else
			height = this.formWrapper.stretchHeight(150, 64);
			this.formWrapper.setHeight(height);					// Set both tables to a little less than half height
	}

	onSubmit() {
		new WritesEnabler(()=>{ // Only Submit if Writes are Enabled
			if(!window.confirm('Are you sure you want to submit these changes?'))
				return;
			this.ldc.submitAttributes(this.device.id, this.id, this.root);
			this.submitButton.disabled = true;
			this.cancelButton.disabled = true;
		});
	}

	onCancel() {
		if(!window.confirm('Are you sure you want to discard these changes?'))
			return;
		this.resetValues(this.root);
		this.submitButton.disabled = true;
		this.cancelButton.disabled = true;
	}

	resetValues(parent) {
		for(var i = 0; i < parent.children.length; ++i)
			if(parent.children[i].children.length > 0)
				this.resetValues(parent.children[i]);
			else {
				parent.children[i].update(parent.children[i].value);
			}
	}

	onSubmitAttributesResponse(fp) {
		this.root.popAttributes(fp);
	}

	destroy() {
		if (this.id)							// If we registered for graphs
			this.ldc.unregisterGraph(this.id);	// Remove the graph ID we created
		this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}
};
