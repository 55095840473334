import { createElement } from "../elements";
import { Node } from "../node";
import View from "./view";
import './taggerview.css';
import { Role } from "../role";
import { Tag, TagDefinition } from "../widgets/lib/tag";

const GraphColors = [
    '#FF7F0E',
    '#1F77B4',
    '#2CA02C',
    '#D62728',
    '#9467BD',
    '#E377C2',
    '#17BECF',
    '#BCBD22',
    '#8C564B',
    '#e6194b',
    '#3cb44b',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#000000'
]
export default class TaggerView extends View {
    rootNode: Node;
    constructor(rootNode: Node) {
        super();
        this.rootNode = rootNode;
    }
    initialize(parent: HTMLElement): TaggerView {
        this.wrapper = createElement('div', 'tagger-view', parent);
        let graphWrapper = createElement('div', 'tagger-view__graph-wrapper', this.wrapper);
        let specialTags = this.rootNode.findByRole(Role.ROLE_GRAPH_VALUE);
        let names: string[] = [];
        specialTags.forEach((tag, index) => {
            let tagName = tag.getDisplayName();
            let parent = tag.parent
            while(parent && specialTags.filter(tag => tag.getDisplayName() === tagName).length > 1) {
                tagName = parent.getDisplayName() + '/' + tagName;
                parent = parent.parent;
            }
            names[index] = tagName;
        })
        console.log(names)
        let graphedTags: TagDefinition[] = [];
        let lineGraph = createElement('line-graph', 'tagger-view__line-graph', graphWrapper, '', {
            defaultRange: 3600000,
            showLegend: true,
            highlightHover: true,
            liveValueTags: []
        });
        let gaugeColumn = createElement('div', 'tagger-view__gauge-column', this.wrapper);
        for (let i=0; i<specialTags.length; ++i) {
            let tag = specialTags[i];
            if (tag.isLogged) {
                let color = GraphColors[i];
                lineGraph.liveValueTags.push({tag: tag, attributes: {
                    name: names[i],
                    color: color
                }});
                let gauge = createElement('tag-badge', 'tagger-view__tag-badge', gaugeColumn, '', {statusTag: {tag: tag}, backgroundColor: color, overrideName: names[i], showUnits: true});
                gauge.onclick = () => {
                    gauge.classList.toggle('tagger-view__fade');
                    lineGraph.graph._toggleVisiblity(tag);
                }
            }
            else
                createElement('tag-badge', 'tagger-view__tag-badge', gaugeColumn, '', {statusTag: {tag:  tag}, backgroundColor: 'black'});

        }

        this.fInitialized = true;
        return this;
    }
}