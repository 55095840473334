import Alarm            from './svgwidgets/alarm';
import BarGraphBox      from './svgwidgets/bargraphbox';
import Blower           from './svgwidgets/blower';
import BooleanCount     from './svgwidgets/booleancount';
import Button           from './svgwidgets/button';
import ConditionalClass from './svgwidgets/conditionalclass';
import ConditionalStyle from './svgwidgets/conditionalstyle';
import GraphBox         from './svgwidgets/graphbox';
import LerpAttributes   from './svgwidgets/lerpattributes';
import Modal            from './svgwidgets/modal';
import Option           from './svgwidgets/option';
import TemplateText     from './svgwidgets/templatetext';
import ThresholdStyle   from './svgwidgets/thresholdstyle';
import ValueText        from './svgwidgets/valuetext';
import ValueValve       from './svgwidgets/valuevalve';
import Valve            from './svgwidgets/valve';
import WriteButton      from './svgwidgets/writebutton';
import WriteHOA         from './svgwidgets/writehoa';
import WriteSpinner     from './svgwidgets/writespinner';

import SVGWidget        from './svgwidgets/svgwidget';

//An enlivener is a factory for SVGWidgets.
export default class Enlivener {
	//An enlivener is created with the node root that it will always resolve node paths relative to. This may need to be changed if the client wants multiple plants'data in a single SVG element.
	constructor(nodeRoot){
        this.nodeRoot = nodeRoot;

		this.SVGWidgets = {    
            'value-text':           ValueText,
            'value-valve':          ValueValve,
            'conditional-class':    ConditionalClass,
            'bar-graph-box':        BarGraphBox,
            'graph-box':            GraphBox,
            'conditional-style':    ConditionalStyle,
            'threshold-style':      ThresholdStyle,
            'template-text':        TemplateText,
            'option':               Option,
            'button':               Button,
            'write-spinner':        WriteSpinner,
            'write-hoa':            WriteHOA,
            'write-button':         WriteButton,
            'boolean-count':        BooleanCount,
            'lerp-attributes':      LerpAttributes,
            'alarm-box':            Alarm,
            'modal':                Modal,
            'blower':               Blower,
            'valve':                Valve
        }
        this.attachedElements = [];
	}

	//enliven() will search an SVGElement for elements bearing data-se-lens attributes,
	//and attach the appropriate SVGWidgets to them.
    //note: enliven calls itself recursively
	enliven(svgElement,localRoot,environment){

        //let us concat to environment variables that may later be used in path resolution
        var prefix = 'seCon_';
        if (!environment) environment = {};
        for (let name in svgElement.dataset){
            if (name.substr(0,prefix.length) === prefix){
                if (!environment[name.substr(prefix.length)]) {
                    environment[name.substr(prefix.length)] = '';
                }
                environment[name.substr(prefix.length)] += svgElement.dataset[name]; //do the concat
            }
        }
        svgElement._environment = environment; //sneak it in to the element

        //let us "cd" around the node tree as we enter groups and other svg nodes that can have children
        //NOTE: environment happens before folder because folder commands might want to use the environment.
        if (localRoot === undefined) localRoot = this.nodeRoot;
        if (svgElement.dataset.seFolder) localRoot = SVGWidget.readAndResolveNodePath(svgElement,localRoot,'seFolder');

		//Enliven this element if it bears an se-driver attribute.
        if (svgElement.dataset.seLens){
            var requestedSVGWidgetName = svgElement.dataset.seLens;
            var svgwidget = this.SVGWidgets[requestedSVGWidgetName];

            if (!svgwidget){ //error condition: driver name is not the name of an actual driver
                console.log("Element", svgElement, "requested unregistered lens",requestedSVGWidgetName);
            } else {
                svgwidget.attach(localRoot,svgElement);
            }
        }
        //recursively call enliven on all children
        for (var i=0;i<svgElement.children.length;++i){
            var childEnv = {};
            Object.assign(childEnv,environment); //make a copy of environment so that env vars don't propagate back up the tree
            this.enliven(svgElement.children[i],localRoot,childEnv);
        }
	}
}
