import owner from "../owner";
import { RateMap, TagUnit, convert } from "./widgets/lib/tagunits";
import { HDRequest } from "./graph";
import { Tag } from './widgets/lib/tag'

interface DataRequest {
    start: Date;
    end: Date;
    tags: Tag[];
    interval: DataInterval;
}

export type HistoricalData = [string[], ...Array<number[]>];

export type DataInterval = 1 | 10 | 60 | 600 | 3600 | 21600 | 86400;

export const Intervals: DataInterval[] = [1, 10, 60, 600, 3600, 21600, 86400];
export function CalculateIntervalFromDates(start: Date, end: Date): DataInterval {
    let seconds = (end.getTime() - start.getTime()) / 1000;			// Number of seconds we are currently viewing
    return CalculateInterval(seconds);
}

export function CalculateInterval(seconds: number): DataInterval {
    for (let index = 0; index < Intervals.length - 1; ++index) {
        let points = seconds / Intervals[index];					// Number of data points displayed should we choose this interval
        if (points < 2000)											// Select the first interval that will load up fewer than x000 data points
            return Intervals[index];
    }
    return Intervals.back();
}

/**
 * The Cruncher class abstracts the data manipulation required to perform operations on time-series data
 * @param  {number[][]} data
 */
export default class Cruncher {
    graphID:            number;
    callbacks:          { (data: HistoricalData) : void }[] = [];
    fPendingResponse:   boolean = false;
    pendingRequests:    DataRequest[] = [];
    pendingDeviceIDs:   Set<number> = new Set();
    pendingData:        HistoricalData | null;
    orderedNodes:       Tag[];
    constructor() {
        this.graphID = owner.ldc.registerGraph(this);
    }
    /**
     * Get the average value of a tag over the provided time period
     * @param  {Date} start
     * @param  {Date} end
     * @param  {Tag} tag
     * @param  {number} interval
     * @param  {(value:number)=>void} callback
     */
    getAverage(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        this.callbacks.push((data)=> {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            let value       = 0;
            let nullDeltas  = 0;
            let dT          = 0;
            for (let i=1;i<data[1].length;++i) {
                if (data[3][i-1] == null) {
                    nullDeltas += data[1][i] - data[1][i - 1]
                    continue;
                }
                value   += data[3][i - 1] * (data[1][i] - data[1][i - 1]);
                dT      += data[1][i] - data[1][i - 1]
            }
            value = value / dT;
            callback(value, confidence);
        })
        this.requestData(start, end, [tag], interval);
    }

    getMinimum(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        this.callbacks.push((data)=> {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            callback(Math.min(...data[2]), confidence);
        })
        this.requestData(start, end, [tag], interval);
    }

    getMaximum(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        this.callbacks.push((data)=> {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            callback(Math.max(...data[4]), confidence);
        })
        this.requestData(start, end, [tag], interval);
    }

    getTotalFromRate(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        if (!RateMap.has(tag.units))
            return;
        this.callbacks.push((data)=> {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            let value       = 0;
            let nullDeltas  = 0;
            let dT          = 0;
            for (let i=1;i<data[1].length;++i) {
                if (data[3][i-1] == null) {
                    nullDeltas += data[1][i] - data[1][i - 1]
                    continue;
                }
                value   += data[3][i - 1] * (data[1][i] - data[1][i - 1]);
                dT      += data[1][i] - data[1][i - 1]
            }
            let total = value / dT * convert(dT / 1000, TagUnit.TU_SECONDS, RateMap.get(tag.units)!.time);
            callback(total, confidence);
        })
        this.requestData(start, end, [tag], interval);
    }

    getData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        this.callbacks.push((data)=>callback(data));
        this.requestData(start, end, tags, interval);
    }

    getTrimmedData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        this.callbacks.push((data)=> {
            this._getTrimmedData(start, end, data);
            callback(data);
        })
        this.requestData(start, end, tags, interval);
    }

    getFormattedData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        this.callbacks.push(data => {
            this._getTrimmedData(start, end, data);
            let formattedData: HistoricalData = [data[0]];
            for (let i=1;i<data.length;i+=4) {
                let nodeIndex = (i-1) / 4;
                let tag = this.orderedNodes[nodeIndex];
                formattedData.push([this.getFormattedValue(data[i][0] as number, tag)],[this.getFormattedValue(data[i+1][0] as number, tag)],[this.getFormattedValue(data[i+2][0] as number, tag)],[this.getFormattedValue(data[i+3][0] as number, tag)]);
                for (let j=1;j<data[i].length;++j) {
                    let deltaT          = data[i][j] as number - (data[i][j-1] as number);
                    let intervalCount   = deltaT / (interval * 1000) - 1; // @ts-ignore
                    formattedData[i].push(...Array(intervalCount).fill(null).map((_, k)=>data[i][j-1] + (k+1)*interval*1000), data[i][j]); // @ts-ignore
                    formattedData[i+1].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+1][j-1] as number, tag)), this.getFormattedValue(data[i+1][j] as number, tag));// @ts-ignore
                    formattedData[i+2].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+2][j-1] as number, tag)), this.getFormattedValue(data[i+2][j] as number, tag));// @ts-ignore
                    formattedData[i+3].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+3][j-1] as number, tag)), this.getFormattedValue(data[i+3][j] as number, tag));
                }
            }
            callback(formattedData);
        });
        this.requestData(start, end, tags, interval);
    }

    getFormattedValue(value: number, node: Tag) {
        return parseFloat(node.getFormattedTextFromValue(value, false));
    }

    getConfidence(data: HistoricalData) {
        let nullDeltas = 0;
        for (let i=1;i<data[1].length;++i) {
            if (data[3][i-1] == null)
                nullDeltas += data[1][i] - data[1][i - 1]
        }
        return 1 - (nullDeltas / (data[1][data[1].length - 1] - data[1][0]))
    }

    getIntervalData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        this.callbacks.push((data)=> {
            debugger;

            let intervalData: HistoricalData = [data[0]];           // start with our name array and an empty timestamp array
            for (let i=1;i<data.length;i+=4) {
                intervalData.push([data[i][0] as number],[data[i+1][0] as number],[data[i+2][0] as number],[data[i+3][0] as number]);
                for (let j=1;j<data[i].length;++j) {
                    let deltaT          = data[i][j] as number - (data[i][j-1] as number);
                    let intervalCount   = deltaT / (interval * 1000); // @ts-ignore
                    intervalData[i].push(...Array(intervalCount).fill(null).map((_, k)=>data[i][j-1] + (k+1)*interval*1000)); // @ts-ignore
                    intervalData[i+1].push(...Array(intervalCount).fill(data[i+1][j] as number));// @ts-ignore
                    intervalData[i+2].push(...Array(intervalCount).fill(data[i+2][j] as number));// @ts-ignore
                    intervalData[i+3].push(...Array(intervalCount).fill(data[i+3][j] as number));
                }
            }
            callback(intervalData);
        })
        this.requestData(start, end, tags, interval);
    }


    /**
     * Trim the input data to the requested time period
     * @param  {Date} start
     * @param  {Date} end
     * @param  {number} interval
     * @param  {number[][]} data
     */
    _getTrimmedData(start: Date, end: Date, data: HistoricalData) {
        for (let i=1;i<data.length;i+=4) {
            let startIndex  = 0;
            let startTime   = start.getTime();
            let endTime     = end.getTime();
            for (startIndex;startIndex<data[i].length - 1;++startIndex) {   // iterate over our timestamps
                if (data[i][startIndex + 1] as number > startTime)         // if our timestamp is in our range of interest - break
                    break;
            }
            let endIndex = data[i].length - 1;
            for (endIndex;endIndex>0;--endIndex) {
                if (data[i][endIndex] as number < endTime)
                    break;
            }
            let validPoints = endIndex - startIndex;
            let endFluff    = data[i].length - validPoints - startIndex;
            let startPoint  = {
                min:        data[i+1][startIndex] as number,
                average:    data[i+2][startIndex] as number,
                max:        data[i+3][startIndex] as number
            }
            let endPoint    = {
                min:        data[i+1][endIndex] as number,
                average:    data[i+2][endIndex] as number,
                max:        data[i+3][endIndex] as number
            }
            for (let j=i;j<i+4;j+=4) {
                data[j].splice(0, startIndex + 1, startTime); // remove the fluff at the beginning of the array
                data[j+1].splice(0, startIndex + 1, startPoint.min);
                data[j+2].splice(0, startIndex + 1, startPoint.average);
                data[j+3].splice(0, startIndex + 1, startPoint.max);

                data[j].splice(validPoints, endFluff + 1, endTime); // remove the fluff at the end of the array
                data[j+1].splice(validPoints, endFluff + 1, endPoint.min);
                data[j+2].splice(validPoints, endFluff + 1, endPoint.average);
                data[j+3].splice(validPoints, endFluff + 1, endPoint.max);
            }
        }
    }

    requestData(start: Date, end: Date, tags: Tag[], interval: DataInterval) {
        if (this.fPendingResponse) {
            this.pendingRequests.push({start: start, end: end, tags: tags, interval: interval});
            return;
        }
        this.pendingDeviceIDs.clear();
        this.pendingData = null;
        let deviceTagMap = new Map();
        tags.forEach(tag => {
            let device = tag.device;
            if (!deviceTagMap.has(device)) {
                deviceTagMap.set(device, []);
                this.pendingDeviceIDs.add(device.id);
            }
            deviceTagMap.get(device).push({node: tag, path: tag.deviceRelativePath});
        });
        for (let [device, deviceTags] of deviceTagMap) {
            let starts          = new Array(deviceTags.length);
            let ends            = new Array(deviceTags.length);
            starts.fill(start.getTime() / 1000)
            ends.fill(end.getTime() / 1000);
            device.request = new HDRequest(start, end, interval, deviceTags);	// Add this request to our list of requested
            owner.ldc.getGraphData(this.graphID, device.id, interval, starts, ends, deviceTags, true);
        }
    }

    onGraphDataResponse(interval: DataInterval, data: HistoricalData, devID: number) {	// We got historical data back
        if (this.pendingData == null)
            this.pendingData = data;
        else {
            let titles = data.shift()! as string[];
            this.pendingData[0].push(...titles);
            this.pendingData.push(...data);
        }
        this.pendingDeviceIDs.delete(devID);
        if (this.pendingDeviceIDs.size > 0)
            return;
        //@ts-ignore
        this.orderedNodes = this.pendingData[0].map(nodeName => {
            if (typeof nodeName === 'string') {
                return this._getNodeFromAbsolutePath(nodeName);
            }
        })
        let callback = this.callbacks.shift();
        if (callback)
            callback(this.pendingData as HistoricalData);
        this.pendingData = null;
        if (this.pendingRequests.length > 0) {
            let req = this.pendingRequests.shift()!
            this.requestData(req.start, req.end, req.tags, req.interval)
        }
        else
            this.fPendingResponse = false;
    }

    _getNodeFromAbsolutePath(nodeName: string): Tag {
        let slashIndex  = nodeName.indexOf('/');
        let key         = nodeName.substring(0, nodeName.indexOf('/'));
        let path        = nodeName.substring(slashIndex);
        let dev         = owner.ldc.devices.getByKey(key);
        let Tag        = dev?.tree.findNode(path)!;
        return Tag;
    }

    destroy() {
        owner.ldc.unregisterGraph(this.graphID);
    }
}