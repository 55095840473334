import SVGWidget from "./svgwidget";
import {createElement} from '../elements';
import LiveDataClient from "../livedataclient";
import { GenericGraph } from "../graph";


//The graph-box lens will cover an SVG rectangle with a bar graph.
//It will be parented to the nearest HTML parent-of-a-parent etc. node.
//
//REQUIRED ATTRIBUTES:
//	data-se-node:"<node path>"
//      ^Assign a node.
//
//APPEARANCE ATTRIBUTES:
//  data-se-color:"<green, red, etc.>"  |   default: graph will choose
//      ^Set the color of the plotted line.
//
//  data-se-display-name:"<some name>"  |   default: node's disp. name
//      ^Set the name that will be displayed for the node.
//
//
//GRAPH-WIDE ATTRIBUTES:
//  data-se-interactive:<true, false>       |   default: true
//      ^Allows the user to pan and zoom around time.
//
//  data-se-draw-legend:<true,false>        |   default: true
//      ^Determines if a legend should be drawn.
//
//  data-se-time-range:<number of seconds>  |   default: 24*3600s, a day
//      ^Determines how many seconds back in the past the graph
//         should display when it first starts up.
//
//	data-se-simplify:<true,false>			|	default: false
//		^Determines if the axis should be included.
//
//Multi-Node Support:
//  The graph-box lens is able to display multiple nodes at once.
//  It's most easily shown by example,
//
//      data-se-node:"Influent",
//      data-se-color:"green",
//
//      data-se-node_1:"Pump1",
//      data-se-color_1:"red",
//      data-se-display-name_1:"Special Pump",
//
//      data-se-node_2:"Pump2"
//
//  The above set of attributes describes a graph displaying three
//    nodes, one green, one red, and the last an indeterminate color.
//  Pump1 (postfixed by _1) will appear as "Special Pump" in the legend.
//
//	Ideally, one should be able to click on the graphs to take you to a
//	more in depth graph with more nodes and stuff. This issue depends very
//	heavily on fortnite. The reason is that it would behave differently on
//	mobile devices and on desktops. I was thinking that on mobile, it
//	would pop up a full size graph and force rotation to landscape. On
//	desktop, it might do the same, or there might be a dedicated place
//	for the expanded graph that defaults to a location.

export default class GraphBox extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

		this.parent = SVGWidget.findFirstHTMLParent(this.element);

		this.devices = this.setupDevices([''],true);
		let that = this;
		this.count = 0;
		this.treeId = setInterval(this.checkTreesComplete.bind(this), 250);
	}

	checkTreesComplete () {
		if (this.treesComplete(this.devices) || ++this.count > 10) {
			this.readSettings();
			this.setup();
			clearInterval(this.treeId);
			this.count = 0;
		}
	}

	//Reads and stores attribute settings.
	readSettings(){
        //Node, color and display name are per-node attributes.
        //They are distinguished in graphNodes by their key - the
        //  after-underscore name postfix. Secretly, we don't really
        //  care about the underscore, as data-se-node1 seems like
        //  a perfectly fine way to write node 1.

		this.setupNodes([''],true); //don't subscribe

		this.graphNodes = {}
		var prefix = "seNode";
		for (var nodeID in this.nodes){
            this.graphNodes[nodeID] = this.readNodeSettings(nodeID);
		}

        //Read the attributes that are only set once per graph.
        this.interactive = SVGWidget.readFlag(this.element, "seInteractive",'true'); //default true: interactive
        this.legend = SVGWidget.readFlag(this.element, "seDrawLegend",'true'); //default true: draw legend
		this.simplify = SVGWidget.readFlag(this.element, "seSimplify", 'false');	//default false: draw the axis

        //default one day, the user should type a number of seconds
        this.timeRange = SVGWidget.readNumber(this.element, "seTimeRange",(3600*24).toString()) * 1000; //convert ms->s
	}

    //Reads the settings related to a single node.
	readNodeSettings(nodeID){
        //We find the node outside of the object creation block
        //  because we use it twice - the second time for the
        //  displayname default.
        var node = this.nodes[nodeID];

		return {
		gb: this,
		nID: nodeID,
        get node(){
			return this.gb.nodes[this.nID];
		},

        color:
          SVGWidget.readAttribute(this.element, 'seColor'+nodeID,'DEFAULT'),
        displayname:
          SVGWidget.readAttribute(this.element, 'seDisplayName'+nodeID,node.name),
        };
	}

    setup(){
        //Find the nearest HTML node above:


		//////////////////////////
		//Load graph location/size
        //Note: We can't use the helper function because


		var elemRect = this.element.getBoundingClientRect();
		console.log(elemRect)
        var parentRect = this.parent.getBoundingClientRect();
        var rect = {
                top: elemRect.top - parentRect.top,
                left: elemRect.left - parentRect.left,
                height: elemRect.height,
                width: elemRect.width
        }

		var end			= new Date();							// Current time
		var endTime 	= end.getTime();
		var start		= new Date(endTime - this.timeRange);	// time-range miliseconds before now
		var startTime	= start.getTime();


		this.graphContainer = createElement('div',null,this.parent);

		var ldcRef = this.nodeRoot instanceof LiveDataClient ? this.nodeRoot : this.nodeRoot.tree.device.ldc;
		this.graph = new GenericGraph(ldcRef, this.graphContainer, rect.width, rect.height, start, end, true, undefined, undefined, (this.simplify) ?{xLabelHeight: 0, xAxisLabelHeight:0, yAxisLabelWidth: 0, drawXAxis: false, drawYAxis:false, yLabelWidth: 0, titleHeight:0,} : {});
	    this.graphContainer.style.position = 'absolute'; //positon graph at element
	    this.graphContainer.style.top = rect.top.toString()+"px"
	    this.graphContainer.style.left = rect.left.toString()+"px"

		if (this.interactive) this.graph.makeInteractive(true);	// Let them pan and zoom and such
		if (this.legend)      this.graph.createLegend();			// Make a legend happen
		this.graph.axesCanChange();			// Axis can change between attached nodes

        //attach nodes
        for (var id in this.graphNodes){
            var n = this.graphNodes[id];
            this.graph.addNode(n.node, true, (this.simplify ? " " : n.displayname) , (n.color === "DEFAULT" ? undefined : n.color), true);
        }
		let interval = this.graph._calculateInterval(startTime, endTime);
		if (interval <= 0)
			interval = 1;
		// Now request data for all added nodes (which should just be what we attached above)
		this.graph.requestDataForAllDevices(startTime, endTime, interval, 0);
    }

    //Special destroy method - because we attach ourselves to strange
    //   places in the dom.
    destroy(){
			if(this.graph)
				this.graph.destroy();
      for (var i=0;i<this.nodesSubscribedTo.length;i++){
				this.nodesSubscribedTo[i].unsubscribe(this);
			}
			this.unregisterAsWidget();
	    this.graphNodes = null;
    }
}
