import './maintenanceview.css';
import assert from '../debug';
import { createElement } from '../elements';
import { Device } from '../device';
import { PumpBank } from '../pumpbank';
import { GenericGraph } from '../graph';
import { NodeQuality, Node } from '../node';
import owner from '../../owner';
import View from './view';
import { createSection } from './tagConfigurationView';
import TreeView, { TreeViewTypes } from './treeview';
import { WetWellGraph } from './liftstationoverview';
import FloatIcon from '../images/icons/measure.svg';
import SnoreIcon from '../images/icons/sleep.svg';
import ScourIcon from '../images/icons/flow.svg';
import InfoIcon from '../images/icons/info.svg';
import OdorIcon from '../images/icons/smell.svg';
import ReverseIcon from '../images/icons/reverse.svg';
import { Role } from '../role';
import { CreateWidget } from '../widgets/lib/createwidget';
import { Tag } from '../widgets/lib/tag';
import Dialog from '../dialog';

export default class MaintenanceView extends View {
    fLandscape     : boolean = window.innerHeight < 420;
    device         : Device | null  = owner.selectedDevice;
    rootNode       : Node;
    graph          : GenericGraph | undefined  = undefined;
    wetWellDiv     : HTMLDivElement;
    liftStation    : Node;
    resizeCallback : ()=>void;
	constructor(device: Device) {
        super();
        assert(device);
        assert(device.isTreeComplete(), 'Device must already have a complete node tree');
        this.device      = device;
		this.rootNode   = this.device.tree.nodes[0]!;
        this.liftStation = this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK) ?? this.rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER)[0];
    };

	/**
	 * Initialize the view elements
	 * @returns this
	 */
	initialize(parent: HTMLElement): this {
        super.initialize(parent);
        this.wrapper = createElement('div', 'maintenance__page-wrapper', this.parent);
        this.wetWellDiv = createElement('div', 'maintenance__wet-well', this.wrapper);  // bargraph for liftstation wet well
        let settingColumn = createElement('div', 'maintenance__setting-column', this.wrapper);

        this.resizeCallback = () => this.resize();
        let statusRow = createElement('div', 'maintenance__status', settingColumn);
        let settingsRow = createElement('div', 'maintenance__settings', settingColumn);

        let maintenanceNode = this.rootNode.findChildByRole('Maintenance');
        let pumpBank = this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK);

        let scourNode = maintenanceNode!.findChildByRole('ForceMainScour');
        let floatTestNode = maintenanceNode!.findChildByRole('FloatTest');
        let snoreNode = maintenanceNode!.findChildByRole('PumpSnore');
        let odorControlNode = maintenanceNode!.findChildByRole('OdorControl');

        if(floatTestNode)
            this.createMaintenanceTask(settingsRow, floatTestNode, FloatIcon, 'A Float Test will verify that both the high and low floats are functioning properly and adjust their levels based on the level transducer reading when each float trips. This will bypass the normal start and stop levels until the test is complete. The Highest Search Level is the level at which if the high float has not yet been found, the test will exit, and normal operation will resume.');

        if(snoreNode)
            this.createMaintenanceTask(settingsRow, snoreNode, SnoreIcon, 'Pump Snoring will draw the wet well down below the stop level and low float to the inlet level of the pumps. This helps clear floatable material from and reduces the need for frequent manual clearing of the wet well. The snore time is the amount of time the Lift Station Guardian will allow the pumps to operate below the low float, so this time should be set to include the time it takes to draw down between the low float and the pump inlet as well as the time it takes for the pump to sufficiently snore.');

        if(scourNode)
            this.createMaintenanceTask(settingsRow, scourNode, ScourIcon, 'Force Main Scouring is the process of pushing a high velocity of water through the force main to ensure that material is conveying down the collection system effectively. The Lift Station Guardian will achieve the Desired Flow Rate for a certain amount of time every day (the Daily Scour Duration Goal). These parameters are specific to the geometry of the force main the requirements set by regulatory agencies.');

        if(odorControlNode)
            this.createMaintenanceTask(settingsRow, odorControlNode, OdorIcon, 'Odor Control ensures that the wet well is routinely cleared in order to reduce the chance of the wet well going septic and generating excessive Hydrogen Sulfide. The Interval is the maximum number of minutes the station will sit idly without running a pump. When the station has been idle for the Interval duration (meaning the station has yet to reach the Start Level), Odor Control will kick in and draw the station down to the Stop Level.');

        let pump1 = pumpBank?.findChild('Pump1') //TODO: Make more smarter for each pump
        let declog = pump1?.findChild('StartupReversingEnabled')
        if (declog) {
            let container = createElement('div', 'maintenance__section', settingsRow, '', {});
            let titleRow = createElement('hmi-container', 'maintenance__section__title-row', container, '', {
                classToggleTag: [
                {
                    tag: declog!,
                    attributes: { //@ts-ignore
                        'on-class': 'maintenance__section__enabled'
                    }
                }]
            });
            createElement('img', 'maintenance__section__icon', titleRow, '', {src: ReverseIcon});
            createElement('div', 'maintenance__section__title', titleRow, 'De-Clogging');
            let infoButton = createElement('button', 'maintenance__info-button', titleRow);
            createElement('img', 'maintenance__info-button__icon', infoButton, '', {src: InfoIcon});
            let info =
            infoButton.title = `De-Clogging is a preventative measure to routinely clear the impeller of debris. Every time a pump starts when this feature is enabled, the pump will run in reverse for the Reverse Duration to clear the impeller before continuing in the forward direction. This process stops the gradual buildup of stuck materials in the impeller, reduces the need for emergency maintenance, improves the reliability of the station, and extends the life of the pumps. Check with your pump supplier before enabling this feature to ensure that your pump is capable of running in reverse.`;
            infoButton.onclick = () => {
                let dialogProperties = {
                    title: 'De-Clogging',
                    body:  info,
                    titleBackground: 'var(--color-primary)',
                    titleColor: 'var(--color-inverseOnSurface)',
                    //buttons: [
                    //    {   // green button that creates a new dashboard when clicked
                    //        title:'Create HMI',
                    //        color: owner.colors.hex('--color-primary')
                    //    },
                    //    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                    //        title:'Cancel',
                    //        callback:() => window.history.back(),
                    //        color: owner.colors.hex('--color-error')
                    //    }
                    //]
                }
                new Dialog(document.body, dialogProperties);
            }
            //createElement('tag-description', 'maintenance__description', container, '', {descriptionTag: {tag: taskFolder}});

            //let descriptionRow = createElement('hmi-container', 'maintenance__section__description-row', container, '', {
            //    classToggleTag: [
            //    {
            //        tag: taskTag!,
            //        attributes: { //@ts-ignore
            //            'off-class': 'hide'
            //        }
            //    }]
            //});
            //descriptionRow.appendChild(this.createSettingRow(stateDescription!));

            let settings = createElement('div', 'maintenance__section__settings', container);
            settings.appendChild(this.createSettingRow(declog!));
            settings.appendChild(this.createSettingRow(pump1?.findChild('ReverseDuration')!));
        }
        this.fInitialized = true;
        this.resize();
        return this;
    }

    createMaintenanceTask(parentElement: HTMLElement, taskFolder: Node, icon: string, info: string) {
        let taskTag = taskFolder.findChildByRole('Active');
        let enabledTag = taskFolder.findChildByRole('Enabled');
        let doNowTag = taskFolder.findChildByRole('Do Now');
        let cancelTag = taskFolder.findChildByRole('Cancel');
        let stateDescription = taskFolder.findChildByRole('StateDescription');
        let container = createElement('hmi-container', 'maintenance__section', parentElement, '', {
            classToggleTag: [{
                tag: taskTag!,
                attributes: { //@ts-ignore
                    'on-class': 'maintenance__section__active'
                }
            },
            {
                tag: enabledTag!,
                attributes: { //@ts-ignore
                    'on-class': 'maintenance__section__enabled'
                }
            }]
        });
        let titleRow = createElement('hmi-container', 'maintenance__section__title-row', container, '', {
            classToggleTag: [
            {
                tag: enabledTag!,
                attributes: { //@ts-ignore
                    'on-class': 'maintenance__section__enabled'
                }
            }]
        });
        createElement('img', 'maintenance__section__icon', titleRow, '', {src: icon});
        createElement('tag-name', 'maintenance__section__title', titleRow, '', {nameTag: {tag: taskFolder}, formatName: true});
        createElement('tag-push-button', 'maintenance__do-now', titleRow, '', {buttonTag: {tag: doNowTag!}, buttonText: 'Queue Maintenance', pressedValue: 1});
        createElement('tag-push-button', 'maintenance__cancel', titleRow, '', {buttonTag: {tag: cancelTag!}, borderColor: 'var(--color-red-6)', textColor: '', pressedValue: 1});
        let infoButton = createElement('button', 'maintenance__info-button', titleRow);
        createElement('img', 'maintenance__info-button__icon', infoButton, '', {src: InfoIcon});
        infoButton.title = info;
        infoButton.onclick = () => {
            let dialogProperties = {
                title: taskFolder.getDisplayName(),
                body:  info,
                titleBackground: 'var(--color-primary)',
                titleColor: 'var(--color-inverseOnSurface)',
                //buttons: [
                //    {   // green button that creates a new dashboard when clicked
                //        title:'Create HMI',
                //        color: owner.colors.hex('--color-primary')
                //    },
                //    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                //        title:'Cancel',
                //        callback:() => window.history.back(),
                //        color: owner.colors.hex('--color-error')
                //    }
                //]
            }
            new Dialog(document.body, dialogProperties);
        }
        createElement('tag-description', 'maintenance__description', container, '', {descriptionTag: {tag: taskFolder}});

        let descriptionRow = createElement('hmi-container', 'maintenance__section__description-row', container, '', {
            classToggleTag: [
            {
                tag: taskTag!,
                attributes: { //@ts-ignore
                    'off-class': 'hide'
                }
            }]
        });
        descriptionRow.appendChild(this.createSettingRow(stateDescription!));

        let settings = createElement('div', 'maintenance__section__settings', container);
        settings.appendChild(this.createSettingRow(enabledTag!));
        let settingTags = taskFolder.findChildrenByRole(Role.ROLE_MAINTENANCE_SETTING);
        for (let tag of settingTags) {
            settings.appendChild(this.createSettingRow(tag));
        }
    }

    createSettingRow(tag: Tag): HTMLElement {
        let row = createElement('div', 'maintenance__section__row');
        createElement('tag-name', 'maintenance__section__row__title', row, '', {nameTag: {tag: tag}, formatName: true, showUnits: true});
        createElement('div', 'maintenance__section__row__widget', row).appendChild(CreateWidget(tag))
        return row;
    }

    resize(): void {
        if (this.wrapper.clientHeight == 0 || !this.fInitialized)
            return;

		this.wetWellDiv.destroyWidgets(true);
        this.wetWellDiv.removeChildren();
        let element = createElement('div', 'lift-station__parameter-wrapper__tank-container__tanks', this.wetWellDiv);
		new WetWellGraph(this.liftStation, element, element.clientWidth - 20, element.clientHeight - 20);
	}
}
