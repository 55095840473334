/**
 * Router Class
 *
 * Site navigation is handled by the Router class. For navigation purposes, we establish a heirarchy:
 *
 * -> Application
 *      -> Page
 *          -> View
 *
 * Views can change within pages, and pages can change within the same application window
 * View changes are routed through the router and passed to the page
 * Page changes are handled by the Router class
 *
 * A view or page change is generally triggered by changing the window location
 * A specific window location can be looked up using the getRouteByName method
 *
 * To change the page without changing the window location (ie for a 404 page) we use the navigate() method
 *
 */

export interface RouteInfo {
	displayName: 	        string;
	routeChangeCallback: 	(props: {[key: string]: string}) => void;
    propsChangeCallback?:   (props: {[key: string]: string}) => void
	icon: 			        string;
}

export class Router {
    private origin: string;
    entry: string;         // url that user used to enter the site
    routeChangedCallback: (routeName: string, properties: {[key: string]: string})=>void;
    invalidRouteChangedCallback: () => void;
    constructor(routeChangedCallback: (routeName: string, properties: {[key: string]: string})=>void) {
        this.routeChangedCallback = routeChangedCallback;
        window.addEventListener('hashchange', () => {
            this.navigate(location.hash);
        }); //add listener for when our search bar changes
        if (location.hash == '')
            location.hash = '/'        // make sure we always start with a hash
        this.entry  = location.hash;    // pathname when user entered site
        this.origin = location.origin;
    }

    navigate(path: string) {
        this.routeChangedCallback(...getRouteAndProperties(path));
    }
};

export function getHash(routeName: string, props: {[key: string]: string} = {}) {
    let hash = `#/${routeName}/`;
    for (let [key, value] of Object.entries(props)) {
        hash += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
    return hash;
}

/**
 * Accepts a url and returns the route name and a properties object
 * @param path
 */
export function getRouteAndProperties(path: string, overrideProps: {[key: string]: string} = {}): [string, {[key: string]: string}] {
    let hash = path.split('#/');
    let splitPath = hash[1].split('/');
    let routeName = splitPath[0];
    let props = {};
    if (splitPath[1]) {
        let params = splitPath[1].split('&');
        params.forEach(param => {
            let keyValue = param.split('=');
            props[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1]);
        })
    }
    return [routeName, {...props, ...overrideProps}]
}