import Icon from '../../../images/icons/name.svg';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './tagname.html';

@RegisterWidget({tag: 'tag-name', displayName: 'Tag Name', icon: Icon, section: 'Tag Information', template: template})
export class TagName extends Widget {
    nameElement: HTMLElement;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;
    @Attribute({displayName: 'Format Name'}) formatName: boolean = false;
    @Attribute({
        displayName: 'Hover Behavior',
        typeModifier: 'select',
        typeConfig: {
            displayNames: ['None', 'Name', 'Description', 'Full Path'],
            values: ['none', 'name', 'description', 'path']
        }
    }) hoverBehavior: string = 'description';
    @TagAttribute({displayName: 'Named Tag'}) nameTag: TagDefinition;

    protected connectedCallback(): void {
        this.nameElement = this.shadowRoot?.getElementById('text')!;

    }

    protected enliven(): void {
        let name = this.nameTag.tag.getDisplayName(this.showUnits, this.formatName);
        switch (this.hoverBehavior) {
            case 'none':
                this.title = '';
            break;
            case 'description':
                this.title = this.nameTag.tag.description ?? '';
            break;
            case 'name':
                this.title = name;
            break;
            case 'path':
                this.title = this.nameTag.tag.absolutePath
            break;
            default:
                throw(new Error('Invalid hover behavior provided'));
        }
        this.nameElement.textContent = this.nameTag.tag.getDisplayName(this.showUnits, this.formatName);
    }
}