import {createElement, createUniqueId} from '../elements';
import './switch.css';

export default class Switch {
    switchCheckbox: HTMLInputElement;
    switchLabel: HTMLLabelElement;
    id: string;
    constructor(parent: HTMLElement, state: boolean = false, color: string = 'var(--color-primary)', func?: (state:boolean)=>void) {
        this.switchCheckbox = createElement('input', 'switch__checkbox', parent);
        this.switchLabel    = createElement('label', 'switch__label', parent);

        this.switchCheckbox.setAttribute('type','checkbox');
        this.id = createUniqueId();
        this.switchCheckbox.setAttribute('id',this.id);
        this.switchCheckbox.onclick = () => this.toggle(func);
        this.switchLabel.setAttribute('for',this.id);
        this.state  = state;
        this.color = color;
        this.switchLabel.style.backgroundColor = color;
    };

    set disabled(fDisabled: boolean) {
        this.switchCheckbox.disabled = fDisabled;
        if (fDisabled) {
            this.switchLabel.classList.add('switch__disabled');
        }
        else
            this.switchLabel.classList.remove('switch__disabled');
    }

    set state(fState) {
        this.switchCheckbox.checked = fState;
    }

    get state() {
        return this.switchCheckbox.checked;
    }

    set color(color: string) {
        this.switchLabel.style.color = color;
    }

    toggle(func) {
		this.state = this.switchCheckbox.checked;
        if (func) func(this.state);
    }
};