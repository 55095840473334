import LiveData from './livedata';
import assert from './debug';
import { Device } from './device';
import LiveDataClient from './livedataclient';
import FrameParser from './frameparser';
import { Node, NodeTree } from './node';
import { Treeable } from './views/treeview';
import {WritesEnabler} from './dialog';

export enum AlarmTypes {
	EVENT 			= 0,
	ACTIVATED 		= 1,
	DEACTIVATED 	= 2,
	ACKNOWLEDGED 	= 3,
	STARTUP 		= 4
}

export enum AlarmConditions {
	DISCRETE 	= 0,
	VERY_LOW 	= 1,
	LOW 		= 2,
	HIGH 		= 3,
	VERY_HIGH 	= 4,
	TRUE_ALARM 	= 5,
	FALSE_ALARM = 6,
    TRUE_EVENT 	= 7,
	FALSE_EVENT = 8,
	EQUAL 		= 9,
	NEQUAL 		= 10
}

export const AlarmTypeNames: Map<AlarmTypes, string> = new Map([
	[AlarmTypes.EVENT, 'Event'],
	[AlarmTypes.ACTIVATED, 'Alarm Activation'],
	[AlarmTypes.DEACTIVATED, 'Alarm Deactivation'],
	[AlarmTypes.ACKNOWLEDGED, 'Alarm Acknowledgement'],
	[AlarmTypes.STARTUP, 'Site Startup']
])

export const TypeText: Map<AlarmConditions, string> = new Map();
TypeText.set(AlarmConditions.DISCRETE, "");
TypeText.set(AlarmConditions.VERY_LOW, "LL");
TypeText.set(AlarmConditions.LOW, "L");
TypeText.set(AlarmConditions.HIGH, "H");
TypeText.set(AlarmConditions.VERY_HIGH, "HH");
TypeText.set(AlarmConditions.TRUE_ALARM, "T");
TypeText.set(AlarmConditions.FALSE_ALARM, "F");
TypeText.set(AlarmConditions.EQUAL, "");
TypeText.set(AlarmConditions.NEQUAL, "");

export const AlarmTypeText: Map<AlarmConditions, string> = new Map([
	[AlarmConditions.DISCRETE, 		''],
	[AlarmConditions.VERY_LOW, 		'Lolo alarm'],
	[AlarmConditions.LOW,  			'Low alarm'],
	[AlarmConditions.HIGH, 			'High alarm'],
	[AlarmConditions.VERY_HIGH, 	'Hihi alarm'],
	[AlarmConditions.TRUE_ALARM, 	'True alarm'],
	[AlarmConditions.FALSE_ALARM, 	'False alarm'],
	[AlarmConditions.TRUE_EVENT, 	'True event'],
	[AlarmConditions.FALSE_EVENT,	'False event'],
	[AlarmConditions.EQUAL,			'Equal Alarm'],
	[AlarmConditions.NEQUAL,		'Not Equal Alarm']
]);

export const AlarmConditionDescription: Map<AlarmConditions, string> = new Map([
	[AlarmConditions.DISCRETE, 		''],
	[AlarmConditions.VERY_LOW, 		'is lower than'],
	[AlarmConditions.LOW,  			'is lower than'],
	[AlarmConditions.HIGH, 			'is higher than'],
	[AlarmConditions.VERY_HIGH, 	'is higher than'],
	[AlarmConditions.TRUE_ALARM, 	'is true'],
	[AlarmConditions.FALSE_ALARM, 	'is false'],
	[AlarmConditions.TRUE_EVENT, 	'is true'],
	[AlarmConditions.FALSE_EVENT,	'is false'],
	[AlarmConditions.EQUAL,			'is equal to'],
	[AlarmConditions.NEQUAL,		'is not equal to']
])

export interface IAlarmResponder {
	onAlarmAdded: (alarm: Alarm, device: Device) => void;
	onAlarmChanged: (alarm: Alarm, fActivated: boolean, device: Device) => void;
	onAlarmRemoved: (alarm: Alarm, device: Device) => void;
	endAlarmCommand: (device: Device) => void;
	onHistorical: (ordered: Alarm[], latestTime: number, fCurrent: boolean, fBefore: boolean, fUnprompted: boolean) => void;
}

// This file defines the Alarm and AlarmSet objects:
export class Alarm {
	alarmSet: 			AlarmSet;
	tsActivated: 		number;
	activated: 			number | null = null;
	type: 				AlarmTypes | null = null;
	time: 				number | null = null;
	fActive: 			boolean | null = null;
	fUnacknowledged:	boolean | null = null;
	fAlarm: 			boolean | null = null;
	tsAcknowledged: 	number[] = [];
	userNames: 			string[] = [];
	messages: 			string[] = [];
	message: 			string | null = null;
	condition: 			AlarmConditions | null = null;
	setting: 			number | null = null;
	userName: 			string | null = null;
	tag: 				string;
	node: 				Node | null = null;
	category: 			string;
	severity: 			number;
	tsDeactivated: 		number;
	limit: 				number;
	ackMessage:     	string | null = null;
	activatedMessage: 	string | null = null;
    constructor(timestamp: number, alarmSet: AlarmSet) {
		this.alarmSet			= alarmSet;
        this.tsActivated		= timestamp;
		this.time 				= timestamp;
    }

	isActive()          {return this.fActive;}
	isUnacknowledged()  {return this.fUnacknowledged;}
	isAlarm()           {return this.fAlarm;}	// returns false if event
    isAnalog()          {return Alarm.isAnalogCondition(this.condition)}

	comparator(al1: Alarm, al2: Alarm) {	// Used by ordered array in AlarmSet
		return (al1.tsActivated - al2.tsActivated);
    }

	static isAnalogCondition(condition: number | null): boolean {
		return condition == AlarmConditions.VERY_LOW	||
		condition == AlarmConditions.LOW		||
		condition == AlarmConditions.HIGH		||
		condition == AlarmConditions.VERY_HIGH	||
		condition == AlarmConditions.EQUAL		||
		condition == AlarmConditions.NEQUAL;
	}

    static conditionText = ["", "Lolo ", "Lo ", "Hi ", "HiHi ", "", "", "", "", "=", "!="];	// SCADA display convention
};

// Ordered set of alarms, sorted by alarm.activeTime:
// Note: I tried at first to use a javascript object instead of an array, and ran into all sorts of problems. -pcs
export class AlarmSet {
	device			: Device;
	ldc				: LiveDataClient;
	alarms			: Alarm[] = [];
	active			: number = 0;
	unacknowledged  : number = 0;
	unackActive		: number = 0;
	callbacks		: IAlarmResponder[] = [];
	mostRecent		: number = 0;
	fAlarmsUpdated	: boolean;
    constructor(device: Device, ldc: LiveDataClient) {
        this.device			= device;
        this.ldc			= ldc;
    }

	subscribe() {
		this.ldc.fm.buildFrame(LiveData.WVC_EVENT_COMMAND);
		this.ldc.fm.push_u8(LiveData.EVENT_SUBSCRIBE_ACTIVE);
		this.ldc.fm.push_u32(this.device.id);					// push the device id
		this.ldc.send();
	}

	requestHistorical(time: number, fBefore: boolean, count: number, fEvents: boolean = true, fAlarms: boolean = true, username: string = '', tagName: string = '', fFilterSeverity: boolean = false, minSeverity: number = 0, maxSeverity: number = 1000) {			// Request historical event data
		this.ldc.fm.buildFrame(LiveData.WVC_EVENT_COMMAND, this.device.id);
		this.ldc.fm.push_u8(LiveData.EVENT_GET_HISTORICAL);	// Event subcommand
		this.ldc.fm.push_u64(time);								// Pass in whatever timestamp we were given
		this.ldc.fm.push_u16(count);							// Count of events they want
		this.ldc.fm.push_u8(fBefore ? 1 : 0);					// If we want more recent or older events
		this.ldc.fm.push_u8(fEvents ? 1 : 0);					// If we want non-alarm events
		this.ldc.fm.push_u8(fAlarms ? 1 : 0);					// If we want alarm events
		this.ldc.fm.push_string(username);						// Filter by a specific username
		this.ldc.fm.push_string(tagName);						// Filter by a specific tag name
		this.ldc.fm.push_u16(minSeverity);
		this.ldc.fm.push_u16(maxSeverity);
		this.ldc.send();										// Send the frame
	}

	processHistorical(fp: FrameParser) {	// We have requested events and have gotten a response
		let ordered: Alarm[] = [];				// Array to hold all the events we've gotten back
		let fBefore = fp.pop_u8();		// So we know the order of events, this are echoed back
		let count = fp.pop_u16();		// Count of historical alarms/events appended
		for (let i = 0; i < count; ++i) {	// For each event that was given to us
			let event = new Alarm(fp.pop_u64(), this);
			event.type 		=	fp.pop_u8();			// Type of event (activated, acknowledged, etc)
			event.message 	= 	fp.pop_string();		// Event message
			event.tag 		= 	fp.pop_string();		// Pop tag name
			event.condition = 	fp.pop_u8();			// Pop AlarmEvent type (lolo, lo, hi, etc)
			event.setting 	= 	null
			if (event.type == AlarmTypes.ACTIVATED) {	// If this was an activated event
				event.setting 		= fp.pop_f64();			// Pop alarm setting
			} else if (event.type == AlarmTypes.DEACTIVATED || event.type == AlarmTypes.ACKNOWLEDGED) {	// If this was an deactivated or acknowledged event
				event.activated		= fp.pop_u64();			// Pop timestamp of activation
				event.condition 	= fp.pop_u8();
				event.tag 			= fp.pop_string();
				if (event.type == AlarmTypes.ACKNOWLEDGED)	// Acknowledged events also get a username
					event.userName	= fp.pop_string();
			}
			ordered.push(event);		// Add the event object to the array
		}
		var fCurrent = false, latestTime = 0;		// Default to not current and no latest time if no events came back
		if (count > 0) {	// If events came back
			latestTime = fBefore == 1 ? ordered.front().time : ordered.back().time;	// Check biggest timestamp we got
			if (latestTime >= this.mostRecent) {	// If it is the most recent timestamp we have seen
				this.mostRecent = latestTime;		// Save it so we know when we are current (no more events to query)
				fCurrent = true;					// We are current right now
			}
		}

		for (var i = 0; i < this.callbacks.length; ++i)	// Call anybody who cares about alarms
			this.callbacks[i].onHistorical(ordered, latestTime, fCurrent, fBefore == 1, fBefore == 2);
	}

	registerCallback(callback: IAlarmResponder) {	// Add an object to the array of objects to call when interesting things happen
		assert(callback.onAlarmAdded, 	"Objects that want to be called back must have an onAlarmAdded method!");
		assert(callback.onAlarmChanged, "Objects that want to be called back must have an onAlarmChanged method!");
		assert(callback.onAlarmRemoved, "Objects that want to be called back must have an onAlarmRemoved method!");
		assert(callback.endAlarmCommand, "Objects that want to be called back must have an endAlarmCommand method!");
		assert(callback.onHistorical, "Objects that want to be called back must have an onHistorical method!");
		assert(this.callbacks.indexOf(callback) < 0, "Callback object shouldn't be registered already.");
		this.callbacks.push(callback);
		for (var i = 0; i < this.alarms.length; ++i) {
			callback.onAlarmAdded(this.alarms[i], this.device);		// Tell this guy about all the existing alarms
			callback.onAlarmChanged(this.alarms[i], true, this.device);
		}
		callback.endAlarmCommand(this.device);
	}

	removeCallback(callback: IAlarmResponder) {	// Remove a registered callback
		var index = this.callbacks.indexOf(callback);
		if (index > -1)
			this.callbacks.splice(index, 1);
	}

	popMetadata(fp: FrameParser, alarm: Alarm) {
		var needed = fp.pop_u8();
		if (needed & 0x01) {	// Alarm activation data:
			alarm.type		= fp.pop_u8();
			alarm.fAlarm	= (alarm.type != AlarmTypes.EVENT);
			alarm.tag		= fp.pop_string();
			alarm.node		= this.device.tree.findNode(alarm.tag)!;
			if (alarm.node)
				alarm.node.alarms.binsert(alarm);
			alarm.condition	= fp.pop_u8();
			alarm.category	= fp.pop_string();
			alarm.message	= fp.pop_string();
			alarm.severity	= fp.pop_u16();
			if (alarm.isAnalog())	// analog alarm limit in effect at time of alarm:
				alarm.limit = fp.pop_f64();
			alarm.fActive	= alarm.fAlarm && alarm.tsDeactivated === undefined;	// TODO: If we fix the protocol so its added then removed, this tsDeactivated check can go away
			alarm.fUnacknowledged = alarm.severity >= 100;	// If this has low severity, it doesn't need to be acknowledged
			if (alarm.isActive() && alarm.isUnacknowledged())
				++this.unackActive;
		}
		if (needed & 0x02) {	// Alarm acknowledgement data:
			var ackCount = fp.pop_u16();	// Count of messages appended
			for (var i = 0; i < ackCount; ++i) {		// For each message
				alarm.tsAcknowledged.push(fp.pop_u64());
				alarm.userNames.push(fp.pop_string());	// user who acknowledged alarm
				alarm.messages.push(fp.pop_string());
			}
			alarm.fUnacknowledged = false;	// no longer unacknowledged
			if (alarm.isActive())
				--this.unackActive;
		}
		if (needed & 0x04) {	// Alarm deactivation data:
			alarm.tsDeactivated	= fp.pop_u64();
			alarm.fActive		= false;			// no longer active
			if (alarm.isUnacknowledged())
				--this.unackActive;
		}
		for (var i = 0; i < this.callbacks.length; ++i)		// Tell any subscribers this alarm has changed
			this.callbacks[i].onAlarmChanged(alarm, (needed & 0x01) != 0, this.device);
		if (alarm.node)
			alarm.node._updateAlarmSubscribers(alarm, (needed & 0x01) != 0, true, false);
		if (!alarm.isActive() && !alarm.isUnacknowledged()) // An alarm may be removed from the set if it is not active and not unacknowledged.
			this.erase(alarm.tsActivated);					// Remove completed alarm from collection
	}

	acknowledge(alarmArray: Alarm[]) {	// Acknowledge an array of alarms
		assert(Array.isArray(alarmArray), "acknowledge needs an array of alarms");
		assert(alarmArray.length > 0, "acknowledge needs at least one alarm to acknowledge");
		this.ldc.fm.buildFrame(LiveData.WVC_EVENT_COMMAND, this.device.id);
		this.ldc.fm.push_u8(LiveData.EVENT_ACKNOWLEDGE);
		this.ldc.fm.push_string(this.ldc.user.username);
		this.ldc.fm.push_u16(alarmArray.length);
		for (var i = 0; i < alarmArray.length; ++i) {
			this.ldc.fm.push_u64(alarmArray[i].tsActivated);	// Send all the activated timestamps
			if (this.fAlarmsUpdated)
				this.ldc.fm.push_string(alarmArray[i].ackMessage ?? "");
		}
		this.ldc.send();
	}

	insert(timestamp: number): Alarm {				// Using C++ STL method terms for a set
		//if (!(alarm instanceof Alarm))	// Assume they passed us the active timestamp:
		let alarm = new Alarm(timestamp, this);	// Create a new alarm with passed-in active timestamp

		let index = this.alarms.bsearch(alarm);	// search for existing alarm with same timestamp
		if (index < 0) {	// not found:
			this.alarms.binsert(alarm);	// insert new alarm
			for (var i = 0; i < this.callbacks.length; ++i)
				this.callbacks[i].onAlarmAdded(alarm, this.device);
			return alarm;				// and return it
		} else
			return this.alarms[index];	// return existing alarm
	}

	find(timestamp: number) {	// Returns alarm with matching tsActivated, or 'undefined' no matching alarm found
		let alarm = this.alarms[this.alarms.bsearch(new Alarm(timestamp, this))];
		assert(alarm, `Invalid alarm timestamp: ${timestamp} at '${this.device.siteName}'`)
		return this.alarms[this.alarms.bsearch(new Alarm(timestamp, this))];	// Also needs the comparator
	}

	findConfigured(alarm: Alarm) {
		return this.device.configuredAlarms.configured.find(configuredAlarm => configuredAlarm.node?.getDeviceRelativePath() === alarm.tag && configuredAlarm.type == alarm.condition)
	}

	erase(timestamp: number) {	// Remove alarm with matching timestamp
		var index = this.alarms.bsearch(new Alarm(timestamp, this));
		if (index >= 0)	// found the alarm
			this._eraseHelper(this.alarms.splice(index, 1)[0]);
	}

	clear() {
		while (this.alarms.length) {
			var alarm = this.alarms.pop()!;
			alarm.fActive = alarm.fUnacknowledged = false;
			alarm.node = null;
			this._eraseHelper(alarm);
		}
		this.active = this.unacknowledged = this.unackActive = 0;
		this.device.configuredAlarms.clear();					// Update a configured alarm
	}

	_eraseHelper(alarm: Alarm) {	// Does all the common stuff between erase and clear to clean up an alarm
		if (alarm.node) {	// If the alarm had a node associated with it
			var index = alarm.node.alarms.bsearch(alarm);
			alarm.node.alarms.splice(index, 1);
			alarm.node._updateAlarmSubscribers(alarm, false, false, true);
		}
		for (var i = 0; i < this.callbacks.length; ++i)
			this.callbacks[i].onAlarmRemoved(alarm, this.device);
	}

	size() {
		return this.alarms.length;
	}

	onEventCommand(fp: FrameParser) {	// Process event command from Whoville:
		var cmd = fp.pop_u8();
		switch (cmd) {
			case LiveData.EVTCMD_NEW_METADATA_ALL:
				this.fAlarmsUpdated = true;	// TODO: Temporary upgrade stuff. Remove this when everybody is current
				this.clear();	// Receiving all alarms, so clear out all existing alarms
				// fall through

			case LiveData.EVTCMD_NEW_METADATA:
				for (var i = 0, count = fp.pop_u32(); i < count; ++i) {	// Remove active alarms
					// TODO: This is now an insert rather than a fix me because an alarm can be activated and
					// deactivated in the same message if it happens quickly. In this case, we get deactived followed by activated
//					this.popMetadata(fp, this.find(fp.pop_u64()));
					this.popMetadata(fp, this.insert(fp.pop_u64()));
					--this.active;			// update active count
				}

				for (var i = 0, count = fp.pop_u32(); i < count; ++i) {	// Add active alarms
					this.popMetadata(fp, this.insert(fp.pop_u64()));
					++this.active;			// update active count
				}

				for (var i = 0, count = fp.pop_u32(); i < count; ++i) {	// Remove unacknowledged alarms
					this.popMetadata(fp, this.find(fp.pop_u64()));
					--this.unacknowledged;	// update unacknowledged count
				}

				for (var i = 0, count = fp.pop_u32(); i < count; ++i) {	// Add unacknowledged alarms
					this.popMetadata(fp, this.insert(fp.pop_u64()));
					++this.unacknowledged;	// update unacknowledged count
				}
			break;

			case LiveData.EVENT_GET_CONFIGURED:
				this.device.configuredAlarms.onConfiguredResponse(fp, this.device.tree);	// Create a set of configured alarms
				return;	// Don't call endAlarmCommand();

			case LiveData.EVENT_ALARM_UPDATED:
				this.device.configuredAlarms.update(fp, this.device.tree);					// Update a configured alarm
				return;	// Don't call endAlarmCommand();

			case LiveData.EVENT_GET_HISTORICAL:
				this.processHistorical(fp);
			break;

			default:
				assert (false, 'unhandled event subcommand');
		}
		for (var i = 0; i < this.callbacks.length; ++i)
			this.callbacks[i].endAlarmCommand(this.device);	// Tell callbacks this is the end of the event message
	}
};

// This class holds an alarm that is configured to create event on a system
export class ConfiguredAlarm implements Treeable {
	node: Node | null;
	type: AlarmConditions | null;
	setting: number;
	severity: number;
	category: string;
	message: string;
	fDisabled: boolean;
	nodePath: string;
	onDelay: number;
	offDelay: number;
	fAlarm: boolean;
	limit: number;
	fActive: boolean;
	treeChildren: Treeable[] = [];
    constructor(node?: Node, type?: AlarmConditions) {
        this.node = node ?? null;		// Node this alarm is active on
        this.type = type ?? null;		// Type of alarm (lolo, lo, hi, etc)
    }

	get absolutePath(): string | undefined {
		return this.node?.absolutePath;
	}

	get name(): string {
		return this.getDisplayName();
	}

	getDisplayName(fUnits?: boolean | undefined) : string {
		return `${this.node?.getDeviceRelativePath()} ${AlarmTypeText.get(this.type!)!}`;
	};

	parse(fp: FrameParser, tree: NodeTree, list: ConfiguredAlarms) {
		this.node = tree.nodes[fp.pop_u32()]!;	// Find our node from the Node ID that came across
		this.type = fp.pop_u8();				// AlarmEvent type
		this.update(fp, list);					// Fill out the rest of our parameters
		this.node.configured[this.type] = this;	// Give the node a reference to us
	}

	update(fp: FrameParser, list: ConfiguredAlarms) {	// Fill out our guts from the frame parser
		this.setting	= fp.pop_f64();			// Setting or limit
		this.severity	= fp.pop_u16();			// Severity (>=200 is alarm)
		this.category	= fp.pop_string();		// Category
		this.message	= fp.pop_string();		// Message
		this.fDisabled	= fp.pop_u8() > 0;		// fDisabled
		if (list.device.alarms.fAlarmsUpdated) {
			this.onDelay	= fp.pop_u32();
			this.offDelay	= fp.pop_u32();
		}
	}

	isNumeric() {						// Convenience function to say whether we require a setting or not
		return	this.type == AlarmConditions.VERY_LOW	||
				this.type == AlarmConditions.LOW		||
				this.type == AlarmConditions.HIGH		||
				this.type == AlarmConditions.VERY_HIGH	||
				this.type == AlarmConditions.EQUAL		||
				this.type == AlarmConditions.NEQUAL;
	}

	getType() {
		return TypeText.get(this.type!)!;
	}
};

export interface IConfiguredResponder {
	onAlarmsConfigured: (configured: ConfiguredAlarm[])=>void;
	onAlarmConfigured: 	(configured: ConfiguredAlarm, fNew: boolean)=>void;
}

// This class is a container class for a Device's set of ConfiguredAlarms
export class ConfiguredAlarms {	// Pretty much an empty shell until requestConfigured is called
	device: Device;
	ldc: LiveDataClient;
	configured: ConfiguredAlarm[];
	callbacks: IConfiguredResponder[];
	isInitialized: boolean = false;
    constructor(ldc: LiveDataClient, device: Device) {
        this.ldc		= ldc;						// Live data client for building frames
        this.device		= device;					// Device ID for WV routing
        this.callbacks	= [];						// Empty array of potential callbacks
        this.configured = [];
    }

	request() {
		assert(this.configured.length == 0);
		this.ldc.fm.buildFrame(LiveData.WVC_EVENT_COMMAND, this.device.id);	// Build an event frame
		this.ldc.fm.push_u8(LiveData.EVENT_GET_CONFIGURED);		// Say we want the configured alarms
		this.ldc.send();											// Send the frame
	}

	registerCallback(callback: IConfiguredResponder) {
		assert(callback.onAlarmConfigured, "ConfiguredAlarms callback objects need to have an onAlarmConfigured method!");
		assert(this.callbacks.indexOf(callback) < 0, "Callback object shouldn't be registered already!");
		this.callbacks.push(callback);			// Add the callback to our list of callbacks
		callback.onAlarmsConfigured(this.configured);		// Tell this guy about all the existing alarms
	}

	unregisterCallback(callback) {
		var index = this.callbacks.indexOf(callback);	// Find index of callback
		if (index > -1)									// If we found the callback
			this.callbacks.splice(index, 1);			// Remove it from our array
	}

	clear() {					// Put our state back to like we were just constructed
		this.configured.length = 0;		// No longer know if these exist
	}

	onConfiguredResponse(fp: FrameParser, tree: NodeTree) {
		let count = fp.pop_u32();				// Number of configured alarms appended
		for (let i = 0; i < count; ++i)	 {		// For each alarm in the frame
			let alarm = new ConfiguredAlarm();	// Create a new alarm
			alarm.parse(fp, tree, this);		// Fill out its guts
			this.configured.push(alarm);		// Add the new alarm to our vector
		}
		this.isInitialized = true;
		this.notifyConfiguredResponse();		// Tell everyone about the new alarms
	}

	update(fp: FrameParser, tree: NodeTree) {	// We got a frame in and need to update one of our configured alarms
		if (this.configured === undefined || !tree.isComplete()) {	// If we haven't gotten our base alarms back yet or we don't have a full node tree
			fp.skip(fp.size());					// Ignore this whole message
			return;
		}
		let node = tree.nodes[fp.pop_u32()]!;	// Find our node from the Node ID that came across
		let type = fp.pop_u8();
		let alarm = node.configured[type];
		let fCreated = false;
		if (alarm === undefined) {
			fCreated = true;
			alarm = new ConfiguredAlarm(node, type);	// Couldn't find the alarm existing already, so create a new alarm
			this.configured.push(alarm);					// Add the new alarm to our vector
			node.configured[type] = alarm;					// Give the node a reference to us
		}

		alarm.update(fp, this);					// Give the alarm its guts
		this.notifyCallbacks(alarm, fCreated);	// Callback on new alarm
	}

	notifyCallbacks(alarm: ConfiguredAlarm, fNew: boolean) {
		alarm.node?._updateConfiguredAlarmSubscribers(alarm, fNew);
		for (let i = 0; i < this.callbacks.length; ++i)			// Call all callbacks
			this.callbacks[i].onAlarmConfigured(alarm, fNew);	// Let them know if the alarm is new or not
	}

	notifyConfiguredResponse() {
		this.configured.forEach(configured => this.notifyCallbacks(configured, false));
		for (let i = 0; i < this.callbacks.length; ++i)			// Call all callbacks
			this.callbacks[i].onAlarmsConfigured(this.configured);	// Let them know if the alarm is new or not
	}

	addAlarmConfiguration(node: Node, type: AlarmConditions, setting: number, severity: number, category: string, message: string = '', delayOn: number = 0, delayOff: number = 0) {
		this.pushAlarmGuts(LiveData.EVENT_CREATE_ALARM, node, type, setting, severity, category, message, delayOn, delayOff);
		this.ldc.send();	// Send the frame
	}

	modifyAlarmConfiguration(node: Node, type: AlarmConditions, setting: number, severity: number, category: string, message: string, delayOn: number, delayOff: number, fDisabled: boolean, optionalCancelCallback?: Function /*Optionally get notified of a user cancelling modifications to rollback any UI changes*/) {
		new WritesEnabler(()=>{
			this.pushAlarmGuts(LiveData.EVENT_MODIFY_ALARM, node, type, setting, severity, category, message, delayOn, delayOff);
			this.ldc.fm.push_u8(fDisabled ? 1 : 0);	// Modify configuration also gets a fDisabled flag
			this.ldc.send();						// Send the frame
		}, ()=>{if(optionalCancelCallback){optionalCancelCallback();}});
	}

	pushAlarmGuts(command, node, type, setting, severity, category, message, delayOn, delayOff) {
		this.ldc.fm.buildFrame(LiveData.WVC_EVENT_COMMAND, this.device.id);	// Build an event frame
		this.ldc.fm.push_u8(command);			// Push the appropriate command
		this.ldc.fm.push_u32(node.id);			// ID of the Node we want this alarm on
		this.ldc.fm.push_u8(type);				// AlarmEvent type
		this.ldc.fm.push_f64(setting);
		this.ldc.fm.push_u16(severity);
		this.ldc.fm.push_string(category);
		this.ldc.fm.push_string(message);
		if (this.device.alarms.fAlarmsUpdated) {
			this.ldc.fm.push_u32(delayOn);
			this.ldc.fm.push_u32(delayOff);
		}
	}
};

export class AlarmSettings {
	node: Node;
	type: number;
	setting: number;
	severity: number;
	category: string;
	message: string;
	onDelay: number;
	offDelay: number;
	fDisabled: boolean;
	constructor (node, type, setting, severity, category, message, onDelay, offDelay, fDisabled) {
		this.node = node;
		this.type = type;
		this.setting = setting;
		this.severity = severity;
		this.category = category;
		this.message = message;
		this.onDelay = onDelay;
		this.offDelay = offDelay;
		this.fDisabled = fDisabled;
	}
	static cloneSettings(alarm) {	// Clone settings from an existing alarm
		return new AlarmSettings(alarm.node, alarm.type, alarm.setting, alarm.severity, alarm.category, alarm.message, alarm.onDelay, alarm.offDelay, alarm.fDisabled);
	}

	imbueSettings(alarm) {	// Imbue an alarm with these settings
		alarm.node = this.node;
		alarm.type = this.type;
		alarm.setting = this.setting;
		alarm.severity = this.severity;
		alarm.category = this.category;
		alarm.message = this.message;
		alarm.onDelay = this.onDelay;
		alarm.offDelay = this.offDelay;
		alarm.fDisabled = this.fDisabled;
	}
}