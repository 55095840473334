import View from './view';
import { createElement } from '../elements';
import './disconnectedview.css';
import Loader from '../loader';

export class DisconnectedView extends View {
    constructor() {
        super();
    }

    initialize(parent: HTMLElement): DisconnectedView {
        super.initialize(parent);
        this.wrapper = createElement('div', 'disconnected-view__wrapper', this.parent)
        createElement('div', 'disconnected-view__text', this.wrapper, 'Attempting to reconnect you to live data...');
        new Loader(this.wrapper);
        this.fInitialized = true;
        return this;
    }
}