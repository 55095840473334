import {createElement} from './elements';
import ArrowBackIcon from './images/icons/arrow_back.svg';
import CancelIcon from './images/icons/cancel.svg'
import owner from '../owner';
import './viewmodal.css';
import assert from './debug';
import View from './views/view';

export interface ViewModalProperties {
    parent?:                HTMLElement;// HTMLElement to add the viewmodal to. Defaults to document body
    minWidth?:              string;     // modal container minimum width style setting
    maxWidth?:              string;     // modal container maximum width style setting
    minHeight?:             string;     // modal container minimum height style setting
    maxHeight?:             string;     // modal container maximum height style setting
    title?:                 string;     // title text
    titleBackgroundColor?:  string;     // title bar background color
    titleTextColor?:        string;     // title text color
    fAnimated?:             boolean;    // flag for whether or not we want an animation on instantiation
    closeCallback?:         ()=>boolean;   // called just before viewmodal is destroyed
    buttons?:               ButtonProperties[];
    fUncloseable?:          boolean;
}

export interface ButtonProperties {
    title:              string;         // required button title text
    titleTextColor?:    string;         // button text color
    backgroundColor?:   string;         // button background color
    borderColor?:       string;         // button border text
    callback?:          ()=>boolean;    // button click callback
}
/**
 *
 *
 * @export
 * @class ViewModal
 */
export default class ViewModal<T extends View> {
    parent:         HTMLElement = document.body;    // HTMLElement to add the modal to. defaults to document body                             // view to initialize in our modal
    content:        HTMLDivElement;
    titleDiv:       HTMLDivElement;
    props:          ViewModalProperties;
    container:      HTMLDivElement;
    wrapper:        HTMLDivElement;
    buttons:        HTMLElement[] = [];
    view:           T;
    /**
     * Creates an instance of ViewModal.
     * @param {typeof View} view
     * @param {ViewModalProperties} props
     * @memberof ViewModal
     */
    constructor(view: T, props: ViewModalProperties) {
        assert(view, 'Modal must be given a view on instantiation');
        owner.modal         = this;
        this.props          = props ?? {};

        this.container      = createElement('div', 'modal__container', this.parent);
        createElement('div', 'modal__screen-blur', this.container);
        this.wrapper        = createElement('div', 'modal__wrapper ' + (this.props.fAnimated? 'modal__animated' : ''), this.container);
        if (this.props.title) {
            this.titleDiv                       = createElement('div', 'modal__title', this.wrapper, this.props.title);
            this.titleDiv.style.color           = this.props.titleTextColor         ?? '';
            this.titleDiv.style.backgroundColor = this.props.titleBackgroundColor   ?? '';
        }

        this.content        = createElement('div', 'modal__content', this.wrapper);
        this.wrapper.style.maxWidth     = this.props.maxWidth   ?? '';
        this.wrapper.style.maxHeight    = this.props.maxHeight  ?? '';
        this.wrapper.style.minWidth     = this.props.minWidth   ?? '';
        this.wrapper.style.minHeight    = this.props.minHeight  ?? '';

        this.view        = view;
        this.view.parent = this.content;
        this.view.modal  = this;

        if ((!this.props.buttons || this.props.buttons.length < 1) && !this.props.fUncloseable) {
            let close          = createElement('img', 'modal__close', this.wrapper, undefined, {src: CancelIcon});
            close.onclick  = () => this.destroy();
        }

        else if (this.props.buttons) {
            let buttonRow  = createElement('div', 'dailog__button-row', this.wrapper)
            for(let i=0;i<this.props.buttons.length;i++) {
                let button = this.props.buttons[i];
                let newButton = createElement('button', 'se-button', buttonRow, button.title? button.title:'Default');
                newButton.style.border          = '2px solid ' + button.borderColor;
                newButton.style.backgroundColor = button.backgroundColor ?? '';
                newButton.style.color           = button.titleTextColor  ?? '';
                newButton.onclick = () => {
                    if(!button.callback || button.callback())
                        this.destroy();
                }
                this.buttons.push(newButton)
            }
        }

        this.view.initialize(this.content);
    }

    resize() { //TODO: remove max height and width when we go to mobile?
        if (window.innerWidth < 620)
            this.content.style.maxHeight = '';
    }

    destroy() {
        if (this.props.closeCallback)
            this.props.closeCallback()
        this.view.destroy();
        this.container.destroyWidgets();
        this.container.removeChildren();
        this.parent.removeChild(this.container);

        owner.modal = null;
        return;
    }
}