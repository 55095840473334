import FrameMaker from './framemaker';
import User, { PermissionGroup, SMSSubscriptionStatus } from './user';
import LiveData from './livedata';
import { Device, DeviceList, parseDeviceFromFrame } from './device';
import assert from './debug';
import { Node, NodeFlags, NodeOperand, NodeSubscriber, NodeTree, ParseNodeFromFrame, UserNodeFlags, serializeRolesToCSV } from './node';
import FrameParser from './frameparser';
import { createElement } from './elements';
import owner, { Routes } from '../owner';
import ViewModal from './viewmodal';
import { deploySettings, betaSettings, localSettings, insecureLocal, testSettings } from '../deploymentspecificsettings';
import { GraphNode } from './graph';
import { FormElement } from './formelements';
import Dialog, { WritesEnabler } from './dialog';
import PathRestrictedStorage from './pathrestrictedstorage';
import { DisconnectedView } from './views/disconnectedview';
import { TagQuality } from './widgets/lib/tag';
import { Role } from './role';


import * as Sentry from "@sentry/browser";
import LoginPage from './pages/loginpage';
import { convert } from './widgets/lib/tagunits';
import { getHash } from './router/router';
import LiveDataJob from './livedatajob';

export enum LoginStatus {
	//Static variables (0-3 match WVC_LOGIN command status):
	INVALID_PASSWORD = 0,		// Invalid username/password combination submitted to server
	LOGGED_OUT = 1,		// User is logged out of the server
	LOGGED_IN = 2,		// User is logged in to the server
	CONNECT_FAILED = 3,		// Failed to connect to server
	CONNECTION_LOST = 4,		// Connection to server lost
	OUT_OF_DATE = 5,		// Our javascript is old
	TWOFACTOR_INIT = 6,		// User is creating a 2fa token
	TWOFACTOR_VERIFY = 7,		// User is undergoing 2fa
	PASSWORD_RESET = 8,		// User is resetting an expired password
	LOGGED_IN_WITH_WEAK_PASSWORD = 9,		// *Not a WVC_LOGIN command - a client-side only status that signals the client's password does not meet current security requirements and needs changing
	FORGOT_PASSWORD = 10,		// *Not a WVC_LOGIN command - user is submitting their username to request a password reset link be emailed to them
	FORGOT_PASSWORD_RESET = 11,		// *Not a WVC_LOGIN command - user is submitting a password reset token and a new, proposed password
	FORGOT_PASSWORD_RESET_SUCCESS = 12,		// *Not a WVC_LOGIN command - user successfully reset their password with a token
	TIMED_OUT = 13, 		// No response from whoville for a predetermined amount of time
	RECONNECTING = 14
}

enum DataType {
	MIN = 0,
	AVG = 1,
	MAX = 2
}

enum NotificationMethod {
	SMS = 0,
	EMAIL = 1,
}

export const NotificationMethodText: Map<NotificationMethod, string> = new Map([
	[NotificationMethod.SMS, "SMS Text"],
	[NotificationMethod.EMAIL, "Email"]
])

// User status messages corresponding to user status:
export const LoginStatusText: string[] = [
	'Invalid user name / password combination.',
	'Logged out.',
	'Logged in.',
	'Cannot connect to server.',
	'Connection lost to server.',
	'WebClient out of date. Please refresh page.',
	'Scan with an authenticator app or manually copy key to app and enter a valid token',
	'Enter a valid 6 digit token',
	'Password requires resetting',
	'Password requires resetting',
	'',
	''
];

export interface CalloutInterval {
	day: number;
	start: number;
	end: number;
}

export interface Company {
	name: string;
	key: string;
}

export interface UserInfo {
	userName?: string;				// Give each user's name
	firstName?: string;				// Give each user's first name
	lastName?: string;				// Give each user's last name
	email?: string;				// Give each user's email address
	phone?: string;				// Give each user's phone number
	countryCode?: number;
	permissions?: PermissionGroup[];
	companyKey?: string;
	fEnabled?: boolean
	fWizard?: boolean
	fTagConfig?: boolean
	fDevConfig?: boolean
	fAdmin?: boolean
	callouts?: string;
	failover?: string;
	failMinutes?: number;
	lowSev?: number;
	highSev?: number;
	intervals?: CalloutInterval[];
	fTwoFactorEnabled?: boolean;				// Give each user's two-factor status
	lastLogin?: number;				// Give each user's time of last Login
	loginCount?: number;				// Give each user's login count
	smsSubscriptionStatus?: SMSSubscriptionStatus;
	notificationMethod?: NotificationMethod;
}

export interface GroupInfo {
	name: string;
	devices: string[];
	children: GroupInfo[];
	parentName: string;
	parent: GroupInfo | undefined;
}

export class LoginMessage {
	username: string;
	password: string;
	TFtoken: string; // 6 digit TOTP token
	freshTFkey: string; // 2FA TOTP seed that's used if we're setting up MFA
	newPassword: string; // Used if logging in with a temporary password
	TFcookie: string;  // Used to satisfy TOTP for 30 days
	fRemember: boolean = false; // User intends to persist a TFcookie on this machine
	constructor(username: string, password: string, TFtoken: string, freshTFkey: string, newPassword: string, TFcookie: string, fRemember: boolean = false) {
		this.username = username;
		this.password = password;
		this.TFtoken = TFtoken;
		this.freshTFkey = freshTFkey;
		this.newPassword = newPassword;
		this.TFcookie = TFcookie;
		this.fRemember = fRemember;
	}
}

// This file defines the LiveDataClient object.
// A LiveDataClient object keeps the device list current.
// To communicate with a device, get your hands on a Device object, and go take a look at
// Device.js.
// It will also support user account maintenance commands.

declare var grecaptcha: any; // Grecaptcha is defined by remote Google ReCAPTCHA js

export default class LiveDataClient {
	pointCount: number;
	owner: typeof owner;
	status: LoginStatus;
	pathStorage: PathRestrictedStorage
	jwtRefresh: string;
	jwtAuth: string;
	freshTwoFACookie: string;
	fUsedPasswordLogin: boolean = false;
	freshTFKey: string;
	TFCookie: string;
	user: User;
	fm: FrameMaker;
	devices: DeviceList;
	customFiles: string[];
	graphs: any[];
	address: string;
	socket: WebSocket | null = null;
	fBinarySupport: boolean;
	fOpening: boolean;
	pdfCaller: any;
	pendingTempCallbacks: number[] = [];
	authTokenName: string = "auth";
	refreshTokenName: string = "refresh";
	authenticationServer: string;
	refreshToken: any;
	pendingFileUpload: File | undefined;
	pendingUploadCallback: (url: string, uuid: string) => void;
	graphID: number;
	disconnectedModal: ViewModal<DisconnectedView> | null;
	connectionInterval: NodeJS.Timeout;
	pingTimeout: NodeJS.Timeout;
	fMessageReceived: boolean = false;
	performCaptcha: boolean = true;
	recaptchaSiteKey: string = "6LfGrIQkAAAAAHmlbJwaN9Zh6dc2aqwQLJyDaZfp"; // Public (not sensitive) reCAPTCHA site key
	recaptchav3js: any;
	pendingLoginMessage: LoginMessage; // Login message that hasn't been sent yet (waiting on captcha verification)
	constructor() {
		this.owner = owner;
		this.pathStorage = new PathRestrictedStorage();
		this.user = new User(this);
		this.fm = new FrameMaker();
		this.devices = new DeviceList();

		this.customFiles = [];
		this.graphs = [];	// Empty array where historical data clients will register themselves for callback
		this.graphID = this.registerGraph(this);

		switch (process.env.TARGET) {
			case 'beta':
				this.address = betaSettings.address;
				this.authenticationServer = betaSettings.authentication;
				this.owner.applicationContext = betaSettings.applicationContext;
				this.performCaptcha = betaSettings.performCaptcha;
				break;
			case 'local':
				this.address = localSettings.address;
				this.authenticationServer = localSettings.authentication;
				this.owner.applicationContext = localSettings.applicationContext;
				this.performCaptcha = localSettings.performCaptcha;
				break;
			case 'localTest':
				this.address = insecureLocal.address;
				this.authenticationServer = insecureLocal.authentication;
				this.owner.applicationContext = insecureLocal.applicationContext;
				this.performCaptcha = insecureLocal.performCaptcha;
				break;
			case 'test':
				this.address = testSettings.address;
				this.authenticationServer = testSettings.authentication;
				this.owner.applicationContext = testSettings.applicationContext;
				this.performCaptcha = testSettings.performCaptcha;
				break;
			case 'whoville':
				this.address = deploySettings.address;
				this.authenticationServer = deploySettings.authentication;
				this.owner.applicationContext = deploySettings.applicationContext;
				this.performCaptcha = deploySettings.performCaptcha;
				break;
			default:
				assert(false); // Unknown target environment
				break;
		}

		// Assert that all required "pure virtual" methods are defined:
		// Note: Not all of these methods may be required if the LiveDataClient user is careful not to call
		// ldc methods that elicit corresponding callback responses. For example, if the user does not call
		// this.getDeviceInfo(), then owner.onGetDeviceInfo() is unnecessary.
		assert(this.owner.onConnectionStatusChange);// void onConnectionStatusChange(status)		// status == Login.LOGGED_IN, etc.
		//assert(this.owner.onPasswordChange);		// void onPasswordChange(result)				// If password change succeeded
		assert(this.owner.onGetDeviceInfo);			// void onGetDeviceInfo()						// get result from ldc.devices
		//	assert(this.owner.onTagChange);		 		// void onTagChange(Node node)					// tag value/quality changed
		//	assert(this.owner.onEventCommand);			// void onEventCommand()						// WV sent us alarm/event subscriber info
		assert(this.owner.onJobComplete); 			// void onJobComplete(Device device, int jobID)	// particular job completed

		this.recaptchav3js = new Promise<Event | undefined>((resolve, reject) => {
			if (this.performCaptcha) {
				const script = document.createElement('script');
				document.body.appendChild(script);
				script.onload = resolve;
				script.onerror = () => reject('Error in google recaptcha');
				script.async = true;
				script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`;
			}
			else	// No need to actually load the reCAPTCHA JS if we aren't performing a captcha
				resolve(undefined);
		});

		this.recaptchav3js.then(() => {
			// Only attempt to auto-login after recaptcha JS has loaded
			this.attemptingJWTlogin();
		}, // reCAPTCHA js loaded
			() => {	// reCAPTCHA js failed to load
				assert(false, "Failed to load reCAPTCHA js!");
			}).catch(e => console.error(e));
	};

	initializeSocket(onOpenCallback, server = this.authenticationServer) {
		// Create the connection to the Specific Energy Whoville server:
		this.fOpening = true;
		this.socket = new WebSocket(server);
		this.socket.binaryType = 'arraybuffer';	// instead of 'blob'
		this.fBinarySupport = true;				// Assume, for now, that binary messages are supported

		// Attach our handlers to the socket:
		this.socket.onopen = onOpenCallback;
		this.socket.onclose = (e: CloseEvent) => this.onclose(e);
		this.socket.onerror = (e: Event) => this.onerror(e);
		this.socket.onmessage = (e: MessageEvent) => this.onmessage(e);
	}

	verifyCaptcha(captchaSolution) {
		assert(this.status != LoginStatus.LOGGED_IN);
		this.fm = new FrameMaker();
		this.fm.buildFrame(LiveData.WVC_IS_HUMAN, 777, 0);			// Build the login frame
		this.fm.push_string(captchaSolution);		// Add on the solution
		this.socket?.send(this.fm.toArrayBuffer());	// Transmit the frame
	}

	onCaptchaVerify(fp) {
		var result = fp.pop_u8();
		if (result) {
			// console.log("This is a verified human.");
		}
		else {
			// console.log("Could not verify that this user is human.");
		}

		/*
			TODO: don't bother sending the login buffer once we determine the appropriate action to take on the back-end.
			We may decide to show a dialog saying suspicious activity from your browser was detected... and disallow logging
			in or we may prompt for a v2 captcha. Until we actually enforce this, always send the login message.
		*/
		this.sendLoginMessage(this.pendingLoginMessage);
	}

	logIn(loginInfo: LoginMessage) {
		assert(this.status != LoginStatus.LOGGED_IN);
		// Create the connection to the Specific Energy Whoville server:
		if (loginInfo.fRemember) {	// Set the user's two factor cookie if they have elected to be remembered
			this.pathStorage.setItem("__twofa" + loginInfo.username.hashCode(), this.freshTwoFACookie);
			loginInfo.TFcookie = this.freshTwoFACookie;
		}
		this.initializeSocket(() => this.onLoginSocketOpen(loginInfo), this.authenticationServer);
	}

	logOut() {
		this.pathStorage.getItem(this.authTokenName).then(async (authToken) => {
			if (this.status == LoginStatus.LOGGED_IN && this.socket?.readyState == 1 && authToken) {	// If a user is actually logged in
				this.fm.buildFrame(LiveData.WVC_LOGOUT);		// Build the log out frame
				this.fm.push_u8(0x01);							// Append the primary flag
				this.fm.push_string(authToken);					// Ask the server to revoke our auth token if we have one
				this.pathStorage.deleteItem(this.authTokenName);	// Delete it
				this.send();											// Transmit the frame
			}
			this.status = LoginStatus.LOGGED_OUT;	// Set status to logged out

			this.cleanup(true); // close socket

			this.revokeTokensDuringLogout();
			owner.onConnectionStatusChange(LoginStatus.LOGGED_OUT);	// No refresh token to revoke, make the status known

			if (owner.fApp)	//@ts-ignore If we're running in an ios app, tell the app the user is logged out
				webkit.messageHandlers.callback.postMessage({ message: 'loggedOut' });
		}).catch(e => console.error(e));
	}

	sendLoginMessage(loginMessage: LoginMessage) {
		this.fUsedPasswordLogin = true; // Note that they logged in with a password
		// Try to log in the user:
		if (loginMessage.TFcookie == null || loginMessage.TFcookie == "undefined")
			loginMessage.TFcookie = "";
		this.fm = new FrameMaker();
		this.fm.buildFrame(102, 777, 0);				// Build the login frame
		this.fm.push_string(loginMessage.username);						// Add on the username
		this.fm.push_string(loginMessage.password);						// Append the plain text password
		this.fm.push_u32(parseInt(loginMessage.TFtoken, 10));			// Append the 2fa token
		this.fm.push_string(loginMessage.freshTFkey);					// Append the fresh two factor auth key (base32 encoded)
		this.fm.push_string(loginMessage.newPassword);					// Append the new plain text password
		this.fm.push_string(loginMessage.TFcookie);						// Append the two factor cookie
		this.fm.push_u8(0x01);								// Append the primary flag
		this.fm.push_u32(owner.applicationContext);		// Append application context

		this.socket?.send(this.fm.toArrayBuffer());	// Transmit the frame
	}

	async onLoginSocketOpen(loginMessage: LoginMessage) {		// WebSocket callback
		this.fOpening = false;

		if (!this.performCaptcha) {
			this.sendLoginMessage(loginMessage);
		}
		else {
			await this.recaptchav3js; // Ensure the Google's reCAPTCHAv3 js is loaded before trying to use the grecaptcha object
			assert(grecaptcha);
			grecaptcha.ready(() => {						// recaptcha javascript is ready
				grecaptcha.execute(this.recaptchaSiteKey, { action: 'submit' }).then((token) => {	// Silently solve a challenge
					this.pendingLoginMessage = loginMessage;
					this.verifyCaptcha(token);	// Verify the token with our backend and a login message will be sent when we receive a response
				}).catch(reason => { new Error(reason ?? 'Null error from google recaptcha') });
			});
		}
	}

	onclose(closeEvent: CloseEvent) {	// WebSocket callback
		if (closeEvent) {
			console.log('this.onclose -- ' + closeEvent.code);
		}
		if (this.fOpening)
			this.status = LoginStatus.CONNECT_FAILED;
		else if (this.status == LoginStatus.LOGGED_IN)
			this.status = LoginStatus.CONNECTION_LOST;
		this.owner.onConnectionStatusChange(this.status);
		if (this.status == LoginStatus.CONNECT_FAILED || this.status == LoginStatus.CONNECTION_LOST)
			this.refreshSocket();
	}

	onerror(e: Event) {	// WebSocket callback
		//console.log ('this.onerror');
		//assert (false, 'socket.onerror called. Why?? ' + e);

		// Just do the same thing as if 'close' were called
		//this.socket?.onclose && this.socket?.onclose(e as CloseEvent);
	}

	onSetAuthJWT() {
		// We just set a new JWT auth token after successful authentication
		if (this.authenticationServer === this.address && this.status === LoginStatus.LOGGED_IN) {
			this.owner.onConnectionStatusChange(this.status);	// Same server, notify result and keep using this socket
		}
		else {
			this.cleanup(true);										// Close the socket to the authentication server
			this.attemptingJWTlogin();								// Use the JWT to log in to our application server
		}
	}

	attemptingJWTlogin() {
		assert(this.socket == null, 'no socket should exist');
		this.pathStorage.getItem(this.authTokenName).then((token) => {
			if (token) {	// We found an auth token in pathRestrictedStorage
				if (!owner.fInitialized) {
					owner.splash.style.display = '';
					owner.connectingMessage.style.display = '';
				}
				this.jwtAuth = token;
				this.initializeSocket(this.onJWTauthSocketOpen.bind(this), this.address); // Attach our handlers to the socket:
			}
			else if (process.env.TARGET == 'localDevice')
				new Dialog(document.body, {
					title: 'Error',
					body: 'An error occurred while attempting to read the token saved for this device. Please close this window and try again or try reimporting your device token.'
				});
			else
				this.attemptingJWTrefresh();
		});
	}

	attemptingJWTrefresh() {
		assert(this.socket == null, 'no socket should exist');
		this.pathStorage.getItem(this.refreshTokenName).then((token) => {
			if (token) {	// We found an auth token in pathRestrictedStorage
				if (!owner.fInitialized) {
					owner.splash.style.display = '';
					owner.connectingMessage.style.display = '';
				}
				this.jwtRefresh = token;
				this.initializeSocket(this.onJWTrefreshSocketOpen.bind(this)); // Attach our handlers to the authentication socket:
			}
			else {	// Hide the loading indicator
				owner.splash.style.display = 'none';			// Hide the ripply loader thing
				owner.connectingMessage.style.display = 'none';	// Hide the connecting message
				owner.ldc.user.passKeys.loginButton.activateConditionalUI();
			}
		});
	}

	onJWTauthSocketOpen() {
		this.fOpening = false;
		this.fm.buildFrame(LiveData.WVC_JWT_LOGIN, 1);
		this.fm.push_string(this.jwtAuth);						// Add on the auth JWT
		this.send();
	}

	onJWTrefreshSocketOpen() {
		this.fOpening = false;
		this.fm.buildFrame(LiveData.WVC_JWT_REFRESH, 1);
		this.fm.push_string(this.jwtRefresh);
		this.send();
	}

	revokeToken(token: string | undefined) {
		if (token != undefined) {
			this.fm.buildFrame(LiveData.WVC_JWT_REVOKE, this.user.sessionID);
			this.fm.push_string(token);
			this.send();
		}
	}

	revokeTokensDuringLogout() {
		assert(this.socket == null, 'no socket should exist');

		this.initializeSocket(async () => {
			// Revoke tokens with the server
			this.revokeToken(await owner.ldc.pathStorage.getItem(this.refreshTokenName));
			this.revokeToken(await owner.ldc.pathStorage.getItem(WritesEnabler.pathStorageName));

			// Delete them from client storage
			await owner.ldc.pathStorage.deleteItem(this.refreshTokenName);
			await owner.ldc.pathStorage.deleteItem(WritesEnabler.pathStorageName);

			location.reload(); // These are the last things we do during the logout process
		}, this.authenticationServer);
	}

	getJavascriptCRC() {
		this.fm.buildFrame(LiveData.WVC_GET_JAVASCRIPT_CRC);
		this.send();
	}

	onmessage(e: MessageEvent) {	// WebSocket callback
		let fp = new FrameParser(e.data);		// Parse the frame header

		if (fp.marker != 0xab13)					// bad marker
			return;

		if (fp.command & LiveData.LDC_PROTOCOL_ERROR) {
			Sentry.captureException(new Error(`Protocol Exception. Command: ${fp.command & 0x000FFFF}`));
			location.hash = getHash(Routes.Home);
			location.reload();
		}

		this.fMessageReceived = true;

		// Call the appropriate command processor:
		switch (fp.command) {
			case LiveData.WVCR_LOGIN:									// Login response:
				this.onLogInResponse(fp);
				//owner.onConnectionStatusChange(this.status);
				break;

			case LiveData.WVCR_GET_JAVASCRIPT_CRC:						// No longer needed
				break;

			case LiveData.WVCR_CHANGE_PASSWORD:
				let result = this.user.onChangePasswordResponse(fp);		// Let user process the password change response
				owner.onPasswordChangeResult(result);						// notify owner of password change result
				break;
			case LiveData.WVCR_RESET_PASSWORD:
				let resetResult = fp.pop_u8();
				var title = 'Invalid Token';
				var body = 'Reset token is expired or invalid. Please request another token.'
				if (resetResult) {
					title = 'Success';
					body = 'Password successfully modified';
					this.status = LoginStatus.FORGOT_PASSWORD_RESET_SUCCESS;
					owner.onConnectionStatusChange(LoginStatus.FORGOT_PASSWORD_RESET_SUCCESS);	// Let the login page know what happened
				}
				else {
					this.status = LoginStatus.FORGOT_PASSWORD;
					owner.onConnectionStatusChange(LoginStatus.FORGOT_PASSWORD);
				}
				new Dialog(document.body, {	// Let the user know what happened
					title: title,
					body: body,
					buttons: [
						{
							title: 'Ok',
							callback: () => {
								let defaultPath = getHash(Routes.Home);
								location.hash = defaultPath;
								owner.router.entry = defaultPath;
							},
						}
					]
				});
				break;

			case LiveData.WVCR_JWT_LOGIN: {
				let result = fp.pop_u8();
				if (result) {
					if (this.disconnectedModal) {
						this.disconnectedModal.destroy();
						this.disconnectedModal = null;
					}
					let tokenPayload = JSON.parse(window.atob(this.jwtAuth.split('.')[1]));
					this.user.sessionID = fp.sessionID;
					this.user.username = tokenPayload["user"];
					this.user.fullName = tokenPayload["full_name"];
					this.user.firstName = this.user.fullName.split(' ')[0];
					this.user.lastName = this.user.fullName.split(' ')[1];
					this.user.companyName = tokenPayload["company_name"];
					this.user.companyKey = tokenPayload["company_key"];
					this.user.permissions = [];
					tokenPayload["permissions"].forEach(permission => {
						this.user.permissions.push({ group: permission["group"], fWrites: permission["fWrites"] });
					});
					this.user.fWizard = tokenPayload["fWizard"];
					this.user.fTagConfig = tokenPayload["fTagConfig"];
					this.user.fDevConfig = tokenPayload["fDeviceConfig"];
					this.user.fAdmin = tokenPayload["fAdmin"];
					this.user.verifiedUntil = 0; // Reset our accounting of their verified (write-mode) status in case they had writes enabled when they were last connected
					this.status = LoginStatus.LOGGED_IN;
					this.owner.onConnectionStatusChange(LoginStatus.LOGGED_IN);	// notify owner of login result
					clearInterval(this.connectionInterval);
					this.connectionInterval = setInterval(() => this.checkConnection(), 5000);
				}
				else {
					this.pathStorage.deleteItem(this.authTokenName);	// We supplied an invalid auth JWT, delete it
					this.cleanup(true);
					if (process.env.TARGET == 'localDevice') { // Local whovilles have no concept of refresh tokens, the auth token either worked or they need to go get a new one
						new Dialog(document.body, {
							title: 'Invalid device token',
							body: 'The token you have saved for this device was rejected by the device. It could be that the token has been revoked, expired, or is otherwise invalid. Please regenerate a new token using the https://dashboard.specificenergy.com application.'
						})
					}
					else // Otherwise, maybe we can refresh it
						this.attemptingJWTrefresh();							// Attempt to refresh it
				}
			}
				break;
			case LiveData.WVCR_JWT_REFRESH: {
				let result = fp.pop_u8();
				if (result) {
					this.jwtAuth = fp.pop_string();
					this.pathStorage.setItem(this.authTokenName, this.jwtAuth).then(() => { this.onSetAuthJWT() });
				}
				else {	// Auth and refresh token were invalid
					owner.splash.style.display = 'none';			// Hide the ripply loader thing
					owner.connectingMessage.style.display = 'none';	// Hide the connecting message
					this.pathStorage.deleteItem(this.refreshTokenName); // We supplied an invalid refresh token, delete it
					owner.onConnectionStatusChange(LoginStatus.LOGGED_OUT);
					location.reload();
				}
			}
				break;
			case LiveData.WVCR_VERIFY_USER:
				var graph: any = this.hasGraph(fp);
				if (graph) {
					graph.onVerifyUserByPassword(fp);
				}
				break;

			case LiveData.WVCR_VERIFY_USER_BY_TOKEN:
				var graph: any = this.hasGraph(fp);
				if (graph) {
					graph.onVerifyUserByToken(fp);
				}
				break;

			case LiveData.WVCR_GET_DEVICE_INFO:			// Get list of devices visible to this user response:
			case LiveData.WVCR_GET_DEVICE_INFO_V2:
				this.onGetDeviceInfoResponse(fp);
				break;

			case LiveData.WVC_CONNECTED:				// Device just connected
			case LiveData.WVC_DISCONNECTED:			// Device just disconnected
				this.onDeviceStatusChange(fp);			// These are unsolicited messages from Whoville, and require no respon
				break;

			case LiveData.LDCR_GET_NODE_CHILDREN: {		// Got metadata for some node children:
				var device = this.devices.get(fp.wv_id)!;

				assert(device);	// found device for which this message is destined
				device.tree.onGetNodeChildrenResponse(fp);
			}
				break;

			case LiveData.LDCR_ADD_BASELINE:
				console.log('New baseline was added: ' + (fp.pop_bool() ? 'true' : 'false'));
				break;

			case LiveData.LDC_TAG_UPDATE: {
				var device = this.devices.get(fp.wv_id)!;
				device.job.onTagUpdate(fp);
			}
				break;

			case LiveData.LDCR_SCHEDULE_REQUEST: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				var count = fp.pop_u32();
				var controls: any[] = [];
				for (var i = 0; i < count; ++i) {
					var time = fp.pop_u32();
					var index = fp.pop_u8();
					controls.push({ time: time, index: index });
				}
				graph.onScheduleResponse(controls);
			}
				break;

			case LiveData.LDCR_GET_CONFIG_FILE: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				var blob = new Blob([fp.frame.buffer.slice(24)], { type: 'application/vnd.ms-excel' });	// Create a new Excel blob
				graph.onConfigFile(blob);	// Give whoever asked for it the report data
				fp.skip(fp.size());
			}
				break;

			case LiveData.LDCR_SET_CONFIG_FILE: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				graph.onConfigFileSet();	// Tell them we set the file
			}
				break;

			case LiveData.LDCR_SUBMIT_TAG_CONFIG: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				graph.onSubmitTagConfigResponse(fp);	// Tell them we set the file
			}
				break;

			case LiveData.WVCR_PING: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				graph.onPing();	// Tell them we got data back
			}
				break;

			case LiveData.WVCR_GET_CUSTOM_FILES: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				var key = fp.pop_string();	// Key of the file
				var name = fp.pop_string();	// Name of the file
				var size = fp.pop_u32();	// Size of the file
				var array = new Uint8Array(fp.frame.buffer.slice(fp.ptr, fp.ptr + size));	// Pop out the file into a seperate array
				var div = createElement('div');							// Orphan div to hold the SVG

				// We can't use .apply on large files - pushing every
				// byte in as an argument will blow the stack!
				//			div.innerHTML = String.fromCharCode.apply(String, array);
				// FIXME: This seems super expensive. Time it
				var characters: string[] = [];
				for (var j = 0; j < array.length; ++j)
					characters.push(String.fromCharCode(array[j]));

				div.innerHTML = characters.join('');						// Join the characters
				fp.skip(size);												// Skip the file we just pooped

				graph.onCustomFileReceived(key, name, div);							// Give them the div
			}
				break;

			case LiveData.WVCR_GET_LOG_FILE: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				var log: any[] = [];
				var count = fp.pop_u32();
				for (var i = 0; i < count; ++i) {
					var entry = {
						type: fp.pop_u8(),
						text: fp.pop_string()
					};
					log.push(entry);
				}
				graph.onLogReceived(log);
			}
				break;

			case LiveData.WVCR_CREATE_DEVICE_TOKEN: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				graph.onCreateLocalDeviceToken(fp);	// Hand them the created token
			}
				break;

			case LiveData.WVCR_GET_DEVICE_TOKEN_METADATA: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				graph.onGetLocalDeviceTokenMetadata(fp);	// Tell them we got the tokens
			}
				break;

			case LiveData.WVCR_REVOKE_DEVICE_TOKEN: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;

				graph.onRevokeLocalDeviceToken(fp);	// Tell them if we revoked the token
			}
				break;

			case LiveData.WVCR_GET_USER_PREFS: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				graph.onUserPreferencesReceived(fp);
			}
				break;

			case LiveData.LDCR_GET_FORM: {
				var graph: any = this.hasGraph(fp);
				if (graph)
					graph.onFormReceived(fp);
			}
				break;

			case LiveData.LDCR_GET_DRIVER_FORM: {
				var graph: any = this.hasGraph(fp);
				if (graph)
					graph.onDriverFormReceived(fp);
			}
				break;

			case LiveData.LDCR_GET_DRIVER_ATTRIBUTES: {
				var graph: any = this.hasGraph(fp);
				if (graph)
					graph.onDriverAttributesReceived(fp);
			}
				break;

			case LiveData.LDCR_GET_DRIVER_FORMS: {
				var graph = this.hasGraph(fp);
				if (graph)
					graph.onDriverFormsReceived(fp);
			}
				break;

			case LiveData.LDCR_SUBMIT_ATTRIBUTES: {
				var graph: any = this.hasGraph(fp);
				if (graph)
					graph.onSubmitAttributesResponse(fp);
			}
				break;

			case LiveData.LDCR_SUBMIT_DRIVER_ATTRIBUTES: {
				var graph = this.hasGraph(fp);
				if (graph)
					graph.onSubmitDriverAttributesResponse(fp);
			}
				break;

			case LiveData.LDCR_GET_DRIVERS: {
				var graph = this.hasGraph(fp);
				if (graph)
					graph.onGetDriversResponse(fp);
			}
				break;

			case LiveData.LDCR_SUBMIT_NODE_ATTRIBUTES: {
				var graph = this.hasGraph(fp);
				if (graph)
					graph.onSubmitNodeAttributesResponse(fp);
			}
				break;
			case LiveData.LDCR_SUBMIT_TAG_CONFIG: {
				let graph = this.hasGraph(fp);
				if (graph)
					graph.onSubmitTagConfig(fp);
			}
				break;
			case LiveData.LDCR_DELETE_NODE: {
				var graph = this.hasGraph(fp);
				if (graph)
					graph.onNodeDeleted(fp);
			}
				break;
			case LiveData.WVCR_GRAPH_QUERY: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				var interval = fp.pop_u32();	// Interval between data points
				var count = fp.pop_u16();	// Number of tags requested
				var dev = this.devices.get(fp.wv_id)!;

				// Extract all of the node names (in the order data is attached for them) followed by the data
				var data: Array<Array<number | string | null> | null> = [[]];		// Empty array to start with (with empty label array in the first index)
				for (var i = 0; i < count; ++i) {	// For each node
					assert(fp.is_string_next(), "String was not next while extracting node names");
					let deviceRelativePath = fp.pop_string();
					var name = dev.key + deviceRelativePath;
					let node = dev.tree.findNode(deviceRelativePath);
					data[0]!.push(name);	// Add the name to the list of names
					var lastCrate = graph.crateMap[name];
					if (graph._ignoreCrates[i])
						lastCrate = null;

					var dates: number[] = [], avgs: number[] = [], mins: number[] = [], maxs: number[] = [];	// Extract and store data in the SE data array format (see dygraph.js for more info)
					var crateCount = fp.pop_u16();
					for (var j = 0; j < crateCount; ++j) {
						var cn = fp.pop_u64();
						fp.skip(4);	// Skip crate header start partial seconds
						var time = fp.pop_u32();
						fp.skip(4);	// Skip crate header end partial seconds
						var end = fp.pop_u32();
						var sealed = fp.pop_bool();
						var size = fp.pop_u16();
						var nextCrate = fp.size() - size;

						var boxTime, n, min, max, avg, conversion = 1, resolution = 0.01;
						if (lastCrate && lastCrate.cn == cn && lastCrate.interval == interval) {
							n = lastCrate.n;
							min = lastCrate.min;
							max = lastCrate.max;
							avg = lastCrate.avg;
							boxTime = lastCrate.boxTime;
							size = size + lastCrate.size;
							resolution = lastCrate.resolution;
							conversion = lastCrate.conversion;
							// We are in MID BOX HERE. If there's more data, get the next box.
							time = fp.size() > nextCrate ? lastCrate.boxTime + fp.pop_UVarInt() : end + interval;	// Calculate first second NOT in the box
							dates.push((time - interval) * 1000);	// Add a ghost point at the end of the interval in the box
							mins.push(min);
							maxs.push(max);
							avgs.push(avg);
						}

						while (fp.size() > nextCrate) {
							var box = fp.pop_u8();	// Get the next box
							switch (box)	// React to data after the box
							{
								case LiveData.BoxResolution:
									resolution = fp.pop_f64();	// Data resolution of the crate after this
									break;

								case LiveData.BoxUnits:
									conversion = convert(1, fp.pop_u16(), node!.units, node?.tree.findNodesByRole(Role.ROLE_MAX_SPEED_HZ)[0]?.getValue());
									break;

								case LiveData.BoxTrue:
								case LiveData.BoxFalse:
									n = 1;
									min = max = avg = box == LiveData.BoxTrue ? 1 : 0;	// If the box is true type, sum is 1
									break;

								case LiveData.BoxFullBoolean:
									n = avg = 0;					// Reset so the delta boolean below will add in full value
								// no break:

								case LiveData.BoxDeltaBoolean:
									{
										var sum: number = n * avg;						// Calculate the sum
										n += fp.pop_SVarInt();				// Get the delta count
										avg = (sum + fp.pop_SVarInt()) / n;		// Adjust the sum, then recalculate the average
										min = avg == 1 ? 1 : 0;
										max = avg == 0 ? 0 : 1;
									}
									break;

								case LiveData.BoxFullNumeric:
								case LiveData.BoxFullRange:
									n = min = max = avg = 0;	// Reset all the variables, then add in delta to each variable
								// no break:

								case LiveData.BoxDeltaNumeric:
								case LiveData.BoxDeltaRange:
									if (box >= LiveData.BoxFullRange) {		// Full or delta range boxes have 4 varints before the time delta
										assert(box == LiveData.BoxFullRange || box == LiveData.BoxDeltaRange);		// Better be a range box if you are here
										n += fp.pop_SVarInt();				// Get delta count
										min += conversion * fp.pop_SVarInt() * resolution;	// Get delta min
										max += conversion * fp.pop_SVarInt() * resolution;	// Get delta max
									}
									avg += conversion * fp.pop_SVarInt() * resolution;		// Get delta average
									if (box <= LiveData.BoxDeltaNumeric) {	// If this is one of the second log points
										assert(box == LiveData.BoxFullNumeric || box == LiveData.BoxDeltaNumeric);	// Better be a numeric box if you are here
										min = max = avg;						// Min and max are just the sum
									}
									break;

								case LiveData.BoxGap:
									n = min = max = avg = null;		// Null out our data
									break;

								default:	// Here lies: Box::None
									assert(false, `Invalid box: '${box}', name: '${name.substring(0, 100)}', crate: ${cn}`);
									break;
							}
							if (box > LiveData.BoxUnits) {	// If this is a box that closes

								dates.push(time * 1000);			// Add this box's start to the data
								mins.push(min);
								maxs.push(max);
								avgs.push(avg);

								boxTime = time;
								var boxEnd = fp.size() > nextCrate ? time + fp.pop_UVarInt() : end + interval;	// Calculate first second NOT in the box
								if (boxEnd - time > interval) {		// If there's more than one point encoded in the box
									dates.push((boxEnd - interval) * 1000);	// Add a ghost point just before the end of the interval
									mins.push(min);
									maxs.push(max);
									avgs.push(avg);
								}
								time = boxEnd;	// Update our running time stamp for the crate
							}
						}

						if (!sealed && !graph._ignoreCrates[i]) {	// If we weren't sealed and we weren't told explicitly not to, save the crate for next time
							graph.crateMap[name] = {
								cn: cn,
								time: time,
								size: size,
								interval: interval,
								resolution: resolution,
								boxTime: boxTime,
								n: n,
								min: min,
								max: max,
								avg: avg,
								conversion: conversion
							};
						}
					}
					data.push(dates, mins, avgs, maxs);	// We have filled up all the data for this line. Add our arrays to our aggregate data array
				}
				var graphNodes: GraphNode[] = graph._reqNodes.get(fp.wv_id);
				for (var i = 0; i < graphNodes.length; ++i) {
					if (graphNodes[i].node.flags & NodeFlags.NF_DERIVED) {	// Need to fix up the data for this node
						// First, figure out how many nodes this guy depends on
						var count: number = 0;
						var ops = graphNodes[i].node.operations;
						for (var j = 0; j < ops.length; ++j) {
							if (ops[j].node !== undefined)
								++count;
						}
						// Now, compute each point
						let start = graphNodes[i]._start * 1000;
						let end: number = graphNodes[i]._end * 1000;
						let dates: number[] = [];
						let derived: (number | null)[][] = [[], [], []];
						var indexes = new Array(data[0]!.length).fill(0);		// Indexes of how far we've iterated through each data set
						// Fix up the data array, removing the old data and adding our new
						data[0]!.splice(i, count, dev.key + graphNodes[i].path);
						for (var time: number = start; time <= end; time += interval * 1000) {
							dates.push(time);
							for (let k = 0; k < 3; ++k) {
								let value: number | null = 0;
								let lineIndex = 0;
								for (var j = 0; j < ops.length; ++j) {
									var newVal = ops[j].constant;
									if (ops[j].node !== undefined) {
										if (ops[j].conversion === undefined) {
											let n: Node = dev.tree.getNode(ops[j].node)!;
											ops[j].conversion = convert(1, n.units, n.cacheUnits);
										}
										newVal = this.getValueAtTime(time, i + lineIndex++, data as number[][], indexes, k)! * ops[j].conversion;
										if (newVal < ops[j].min || ops[j].max < newVal)
											newVal = null;
									}

									if (newVal === null || (ops[j].op == NodeOperand.DERIVED_DIVIDE && newVal == 0)) {
										value = null;
										break;
									}
									switch (ops[j].op) {
										case NodeOperand.DERIVED_ADD: value += newVal; break;
										case NodeOperand.DERIVED_SUBTRACT: value -= newVal; break;
										case NodeOperand.DERIVED_MULTIPLY: value *= newVal; break;
										case NodeOperand.DERIVED_DIVIDE: value /= newVal; break;
									}
								}
								derived[k].push(convert(value, graphNodes[i].node.cacheUnits, graphNodes[i].node.units));
							}
						}
						// Fix up the data array, removing the old data and adding our new
						data.splice(i * 4 + 1, count * 4, dates, ...derived);
					}
				}
				graph.onGraphDataResponse(interval, data, fp.wv_id);	// Give whoever asked for it the graph data
			}
				break;

			case LiveData.WVCR_GET_TIME_ZONE: {
				var name = fp.pop_string();	// Olsen time zone name
				var transitions: any[] = [];		// To hold all the transitions we get back
				var count = fp.pop_u32();	// Count of transitions in the frame
				for (var i = 0; i < count; ++i) {
					var transition = {
						utcFront: fp.pop_u64(),
						offset: fp.pop_s32(),
						isDST: fp.pop_u8() > 0,
						utcBack: fp.pop_u64(),
						localBack: fp.pop_u64(),
						abbr: fp.pop_string(),
					};
					transitions.push(transition);
				}
				owner.timeZone.addTimeZone(name, transitions);
			}
				break;
			case LiveData.WVCR_TRIAL_QUERY:
			case LiveData.LDCR_REGIME_CURVES:
			case LiveData.LDCR_POINT_REQUEST:
			case LiveData.WVCR_PUMP_CURVES:
			case LiveData.WVCR_SNAPSHOT_QUERY:
			case LiveData.WVCR_GET_GRAPHS:
			case LiveData.WVCR_CREATE_DASHBOARD:
			case LiveData.WVCR_MODIFY_DASHBOARD:
			case LiveData.WVCR_GET_DASHBOARD:
			case LiveData.WVCR_GET_DASHBOARDS:
			case LiveData.WVCR_DELETE_DASHBOARD:
			case LiveData.LDCR_TEST_CHECKLISTS:	// FIXME: Put all of the parsing in this file
			case LiveData.WVCR_ACCOUNT_MANAGEMENT:
			case LiveData.WVCR_USERS:
			case LiveData.WVCR_COMPANIES:
			case LiveData.WVCR_GET_GROUPS:
			case LiveData.WVCR_CREATE_DEVICE_KEY:
			case LiveData.WVCR_GET_USER_LIST:
			case LiveData.WVCR_GET_UPLOAD_URL:
			case LiveData.WVCR_GET_DOWNLOAD_URL:
			case LiveData.WVCR_GET_IMAGES:
			case LiveData.WVCR_GET_PENDING_REPORTS:
			case LiveData.WVCR_GET_USER_REPORTS:
				var graph = this.hasGraph(fp);
				if (graph) {
					if (fp.command == LiveData.WVCR_TRIAL_QUERY)
						graph.onTrialDataResponse(fp);
					else if (fp.command == LiveData.LDCR_REGIME_CURVES)
						graph.onRegimeCurvesResponse(fp);
					else if (fp.command == LiveData.LDCR_POINT_REQUEST)
						graph.onPointResponse(fp);
					else if (fp.command == LiveData.WVCR_PUMP_CURVES)
						graph.onPumpCurvesResponse(fp);
					else if (fp.command == LiveData.WVCR_SNAPSHOT_QUERY)
						graph.onSnapshotDataResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_GRAPHS)
						graph.onGraphResponse(fp);
					else if (fp.command == LiveData.WVCR_CREATE_DASHBOARD) {
						graph.onCreateDashboardResponse(fp);
						owner.refreshDashboards();
					} else if (fp.command == LiveData.WVCR_MODIFY_DASHBOARD)
						graph.onModifyDashboardResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_DASHBOARD)
						graph.onDashboardResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_DASHBOARDS)
						graph.onDashboardsResponse(fp);
					else if (fp.command == LiveData.WVCR_DELETE_DASHBOARD)
						graph.onDeleteDashboardResponse(fp);
					else if (fp.command == LiveData.WVCR_ACCOUNT_MANAGEMENT)
						this.onAccountManagementResponse(fp);
					else if (fp.command == LiveData.WVCR_USERS)
						this.onGetCompanyUsersResponse(fp);
					else if (fp.command == LiveData.WVCR_COMPANIES)
						this.onGetCompaniesResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_GROUPS)
						this.onGetGroupsResponse(fp);
					else if (fp.command == LiveData.WVCR_CREATE_DEVICE_KEY)
						graph.onCreateDeviceKey(fp);
					else if (fp.command == LiveData.WVCR_GET_USER_LIST)
						graph.onGetUserListResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_DOWNLOAD_URL)
						graph.onGetDownloadURLResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_IMAGES)
						graph.onGetImagesResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_PENDING_REPORTS)
						graph.onGetPendingReports(fp);
					else if (fp.command == LiveData.WVCR_GET_UPLOAD_URL)
						this.onGetUploadURLResponse(fp);
					else if (fp.command == LiveData.WVCR_GET_USER_REPORTS)
						graph.onDashboardReportListResponse(fp);
					else
						graph.onTestChecklists(fp);
				}
				break;

			case LiveData.WVCR_GET_REPORT_LIST: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				var siteReports: any[] = [];
				var count = fp.pop_u16();		// Count of site specific reports
				for (var i = 0; i < count; ++i) {	// For each report
					var year = fp.pop_u16();		// Extract the year
					var month = fp.pop_u8();		// Extract the month
					var day = fp.pop_u8();		// Extract the day
					siteReports.push({ month: month, year: year, day: day });
				}
				var systemReports: any[] = [];
				var count = fp.pop_u16();		// Count of site specific reports
				for (var i = 0; i < count; ++i) {	// For each report
					var year = fp.pop_u16();		// Extract the year
					var month = fp.pop_u8();		// Extract the month
					var day = fp.pop_u8();		// Extract the day
					systemReports.push({ month: month, year: year, day: day });
				}
				graph.onReportListData(siteReports, systemReports);	// Give whoever asked for it the report data
			}
				break;

			case LiveData.WVCR_GET_REPORT: {
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				var year = fp.pop_u16();	// Extract the data about this report
				var month = fp.pop_u8();
				var day = fp.pop_u8();
				var fSystem = fp.pop_u8() == 1;
				var blob = new Blob([fp.frame.buffer], { type: 'application/pdf' });	// Create a new PDF blob
				graph.onReportData(year, month, day, fSystem, blob);	// Give whoever asked for it the report data
				fp.skip(fp.size());				// Skip the frames so the LDC doesn't complain
			}
				break;

			case LiveData.WVC_EVENT_COMMAND:		// Whoville is sending us subscriber info regarding alarms/events for devices:
			case LiveData.WVCR_EVENT_COMMAND:	// Whoville is sending us a response
				this.onEventCommand(fp);
				break;
			case LiveData.WVCR_VERIFY_SERVICE_TAG:
				var graph = this.hasGraph(fp);
				if (!graph)
					break;
				let response: number = fp.pop_u8();
				graph.onConnectServiceTagResponse(response);
				break;
			case LiveData.WVCR_GET_SERVICE_TAGS:		// Whoville returned service tag info
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				let tags: string[] = [], keys: string[] = [], status: number[] = [];
				var count = fp.pop_u32();
				for (var i = 0; i < count; ++i) {
					tags.push(fp.pop_string());
					keys.push(fp.pop_string());
					status.push(fp.pop_u8());
				}
				graph.onServiceTagsResponse(tags, keys, status);
				break;

			case LiveData.WVCR_ASSIGN_DEVICE_KEY:
				var graph: any = this.hasGraph(fp);
				if (!graph)
					break;
				graph.onKeyAssigned(fp.pop_u8());
				break;
			case LiveData.WVC_NODE_CHANGED: {
				var device = this.devices.get(fp.wv_id)!;		// Get the device
				var parent = device.tree.nodes[fp.pop_u32()];	// Get the parent node by ID
				var oldNodeCount = device.tree.nodes.length;	// Remember the old number of nodes
				fp.pop_u16(); 									// Legacy node type
				var node = new Node(parent, ParseNodeFromFrame(fp));				// Create the node
				if (device.tree.nodes.length > oldNodeCount) {	// If node count changed, node was added FIXME: HACKY
					if ((node.flags & NodeFlags.NF_TEMPORARY) != 0) {							// If our node is temporary
						let callbackTarget = this.graphs[this.pendingTempCallbacks.shift()!];	// Find the callback that is waiting for the node
						if (!callbackTarget)
							return;
						callbackTarget.onTemporaryNodeReceived(node);
					}
					else {
						owner.currentPage.onNodeAdded(node);	// Let the current page know that we added a node
					}
				}
				else {
					if ((node.flags & NodeFlags.NF_TEMPORARY) != 0) {							// If our node is temporary
						let callbackTarget = this.graphs[this.pendingTempCallbacks.shift()!];	// Find the callback that is waiting for the node
					}
					owner.currentPage.onNodeChanged(node);		// Let the current page know that we changed a node
				}
				node.subscribers.forEach((subscriber, element) => element.update(node));
			}
				break;
			case LiveData.WVC_NODE_REMOVED: {
				let device = this.devices.get(fp.wv_id)!;		// Get the device
				let id = fp.pop_u32();							// Node id
				let node = device.tree.nodes[id];				// Get the node
				if (node)
					this.removeNode(node);
			};
				break;
			case LiveData.WVCR_WHAT_IF:
				let subCommand = fp.pop_u8();
				var graph = this.hasGraph(fp);
				switch (subCommand) {
					case LiveData.WHAT_IF_GET_PROJECTS:
						if (graph)
							graph.onGetModels(fp);
						break;
					case LiveData.WHAT_IF_CREATE_PROJECT:
						if (graph)
							graph.onCreateNewModel(fp);
						break;
					case LiveData.WHAT_IF_SAVE_PROJECT:
						if (graph)
							graph.onSave(fp);
						break;
					case LiveData.WHAT_IF_LOAD_PROJECT:
						if (graph)
							graph.onLoadModel(fp);
						break;
					case LiveData.WHAT_IF_DOWNLOAD_PROJECT:
						if (graph)
							graph.onDownloadModel(fp);
						break;
					case LiveData.WHAT_IF_DELETE_PROJECT:
						if (graph)
							graph.onDeleteModel(fp);
						break;
					case LiveData.WHAT_IF_SOLVE_SNAPSHOT:
						if (graph)
							graph.onSolveSnapshot(fp);
						break;
					case LiveData.WHAT_IF_SOLVE_EXTENDED_SIMULATION:
						if (graph)
							graph.onSolveModel(fp);
						break;
					case LiveData.WHAT_IF_MODIFY_OUTSIDE_ORGANIZATION_ACCESS:
						if (graph)
							graph.onModifyOutsideOrganizationAccess(fp);
						break;
					default:
						break;
				}
				break;

			case LiveData.WVCR_POST_HUBSPOT_FORM:
				var graph = this.hasGraph(fp);
				if (!graph)
					break;
				graph.onHubspotTicket(fp);
				break;

			case LiveData.WVCR_IS_HUMAN:
				this.onCaptchaVerify(fp);
				break;

			case LiveData.WVCR_PASSKEY:
				var graph = this.hasGraph(fp);
				if (!graph)
					break;
				graph.onPasskeyCommand(fp);
				break;

			case 3221225473:
				debugger;
			default:
				assert(false, 'Invalid command:' + fp.command);
				break;
		}
		assert(fp.size() == 0, `${fp.size()} leftover bytes in command: ${fp.command - 0x80000000} or ${fp.command}`);
	}

	removeNode(node: Node) {
		owner.currentPage.onNodeRemoved(node);
		for (let sub of node.subscribers)
			sub.onNodeRemoved(node);
		let treeIndex: number = node.tree.nodes.indexOf(node); // remove the node from its parent
		assert(treeIndex != -1);
		assert(node.id == treeIndex)
		node.tree.nodes[treeIndex] = undefined;

		assert(node.children === undefined || node.children.length == 0);
		assert(node.parent);
		let index: number = node.parent.children.indexOf(node); // remove the node from its parent
		assert(index != -1);
		node.parent.children.splice(index, 1);
		node.destroy();
	}

	getValueAtTime(time: number, i: number, data: number[][], indexes: number[], type: DataType = DataType.AVG) {
		var setTimes = data[1 + 4 * i];		// Get the time column
		var setData = data[2 + 4 * i + type];	// Get the min, avg, or max column based on the provided type
		if (setTimes === undefined)			// Data set does not match graph's requested nodes. Graph has requested a new set of data
			return null;
		if (time < setTimes[0])	// If this is before our first time
			return null;		// No deal
		while (setTimes[indexes[i] + 1] < time && indexes[i] < setTimes.length - 2)	// Keep looking through the rows until we find the correct period
			++indexes[i];
		if (setData.length < 2 || setData[indexes[i]] === null || setData[indexes[i] + 1] === null)	// If either side is a null, this guy is a null
			return null;
		else								// Else interpolate between the values on either side
			return setData[indexes[i]] + (setData[indexes[i] + 1] - setData[indexes[i]]) * (time - setTimes[indexes[i]]) / (setTimes[indexes[i] + 1] - setTimes[indexes[i]]);
	}

	hasGraph(fp: FrameParser): any {
		var graph = this.graphs[fp.client_zip];
		if (graph)			// Found the graph
			return graph;	// Return that graph

		// No graph
		fp.skip(fp.size());	// We aren't gonna use this frame any more
		return null;
	}

	extractPolynomial(fp: FrameParser) {
		var terms: any[] = [];
		var count = fp.pop_u8();
		for (var i = 0; i < count; ++i)
			terms.push(fp.pop_f64());
		return terms;
	}

	checkConnection() {
		if (this.fMessageReceived) {
			this.fMessageReceived = false;
		}
		else {
			clearInterval(this.connectionInterval);
			this.pingTimeout = setTimeout(() => this.refreshSocket(), 5000);
			this.ping(this.graphID);
		}
	}

	onPing() {
		clearTimeout(this.pingTimeout);
		this.connectionInterval = setInterval(() => this.checkConnection(), 5000);
	}

	refreshSocket() {
		this.status = LoginStatus.RECONNECTING;
		if (!(this.owner.currentPage instanceof LoginPage) && this.disconnectedModal == null)
			this.disconnectedModal = new ViewModal(new DisconnectedView(), {
				title: 'Reconnecting',
				maxHeight: '240px',
				maxWidth: '400px',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				fUncloseable: true
			});
		this.devices.array.forEach(device => {
			device.tree.nodes.forEach(node => {
				if (!node || node.fPsuedo)
					return;
				if (node.subscribers.size > 0) {
					node.fQualityChanged = true;
					node.quality = TagQuality.TQ_DISCONNECTED;
					node._updateSubscribers(false);
				}
			})
		});

		this.cleanup(true);
		this.pathStorage.getItem(this.authTokenName).then((token) => {
			if (token) {
				this.attemptingJWTlogin();
			}
			else if (!(this.owner.currentPage instanceof LoginPage)) {
				location.reload();// Reload the page so they get bumped to login page
			}
		})
	}

	isLoggedIn() {
		return this.status == LoginStatus.LOGGED_IN;
	}

	isAdmin() {
		return this.user.fAdmin;
	}

	isPowerUser() {
		return this.user.fWizard && this.user.companyKey.length == 0;	// Only a power user with the wizard flag and SE company key
	}

	isSEEmployee() {
		return this.user.companyKey.length == 0;
	}

	cleanup(fCloseSocket: boolean) {
		// If WebSocket exists, close and delete it:
		if (fCloseSocket && this.socket) {
			// This socket will not close when we ask it to. It might wait a little while before disconnecting.
			// If we don't kill the callbacks, it might give us a callback later and disconnect a new, connected
			// socket. TODO: Find a better way to make the socket close. Maybe checking socket status? When we are
			// confident on how sockets disconnect, just make the onclose function null, too.
			this.socket.onclose = null;
			this.socket.onerror = null;
			this.socket.onopen = null;
			this.socket.onmessage = null;
			this.socket.close();
		}
		this.socket = null;
	}

	send() {	// send FrameMaker frame 'this.fm' through the WebSocket to Whoville:
		this.sendFrame(this.fm);
	}

	sendFrame(fm: FrameMaker) {	// send FrameMaker frame 'this.fm' through the WebSocket to Whoville:
		if (!this.socket || this.socket.readyState != 1) {
			//assert(false);
			return;
		}
		assert(this.socket.readyState == 1);	// OPEN

		if (this.fBinarySupport)
			this.socket.send(fm.toArrayBuffer());
		else
			this.socket.send(fm.toString());
		return true;	// Now all the send methods can return this method to return true
	}

	changePassword(oldPassword: string, newPassword: string) {  // Change password of logged-in user.
		assert(newPassword != undefined && newPassword != '', "new password should be defined");
		assert(oldPassword != undefined && oldPassword != '', "old password should be defined");
		assert(this.isLoggedIn(), "Trying to change the password of a user who isn't logged in!");
		this.user.changePassword(oldPassword, newPassword);
	}

	forgotPassword(username: string) {
		if (!this.socket)	// Make a socket for this if we need to
			this.initializeSocket(this.onForgotPasswordSocketOpen.bind(this, username), this.address);
		else
			this.onForgotPasswordSocketOpen(username);
	}

	onForgotPasswordSocketOpen(username: string) {
		this.fOpening = false;
		this.fm.buildFrame(LiveData.WVC_FORGOT_PASSWORD, 1);
		this.fm.push_string(username);
		this.send();
	}

	forgotPasswordReset(resetToken: string, newPassword: string) {
		if (!this.socket)	// Make a socket for this if we need to
			this.initializeSocket(this.onForgotPasswordResetSocketOpen.bind(this, resetToken, newPassword), this.authenticationServer);
		else
			this.onForgotPasswordResetSocketOpen(resetToken, newPassword);
	}

	onForgotPasswordResetSocketOpen(resetToken: string, newPassword: string) {
		this.fOpening = false;
		this.fm.buildFrame(LiveData.WVC_RESET_PASSWORD, this.user.sessionID);
		this.fm.push_string(resetToken);
		this.fm.push_string(newPassword);
		return this.send();
	}

	verifyUser(password: string, lifeSpan: number, graphID: number) {
		assert(password != undefined && password != '', "password should be defined");
		assert(this.isLoggedIn(), "Trying to change the password of a user who isn't logged in!");
		this.fm.buildFrame(LiveData.WVC_VERIFY_USER, undefined, graphID);
		this.fm.push_string(this.user.username);	// Append the username
		this.fm.push_string(password);		// Append the password hash after the username.
		this.fm.push_u16(lifeSpan); // The backend caps this at 2hrs in seconds
		this.send();
	}

	verifyUserByToken(token: string, graphID: number) {
		this.fm.buildFrame(LiveData.WVC_VERIFY_USER_BY_TOKEN, undefined, graphID);
		this.fm.push_string(token);
		this.send();
	}

	getDeviceInfo(key?: string) { // Request list of all devices this user can see:
		assert(this.isLoggedIn());
		this.fm.buildFrame(LiveData.WVC_GET_DEVICE_INFO_V2);
		this.fm.push_string(key ? key : '');	// Empty prefix -> get all devices
		this.send();
	}

	onLogInResponse(fp: FrameParser) {
		this.status = fp.pop_u8();
		switch (this.status) {	// WVC_LOGIN result:
			case LoginStatus.LOGGED_IN:
				this.user.sessionID = fp.sessionID;		// Save user session id from frame
				this.user.username = fp.pop_string();
				this.user.fullName = fp.pop_string();
				this.user.firstName = this.user.fullName.split(' ')[0];
				this.user.lastName = this.user.fullName.split(' ')[1];
				this.user.companyName = fp.pop_string();
				this.user.companyKey = fp.pop_string();
				var permissionCount = fp.pop_u16();
				this.user.permissions = [];
				for (var i = 0; i < permissionCount; ++i) {
					var group = fp.pop_string();
					var fWrites = fp.pop_bool();
					this.user.permissions.push({ group: group, fWrites: fWrites });
				}
				this.user.fWizard = fp.pop_bool();
				this.user.fTagConfig = fp.pop_bool();
				this.user.fDevConfig = fp.pop_bool();
				this.user.fAdmin = fp.pop_bool();
				this.user.resetPassword = fp.pop_bool();

				this.jwtRefresh = fp.pop_string();
				this.jwtAuth = fp.pop_string();
				// Set up an interval to check that we still have an active socket
				clearInterval(this.connectionInterval);
				this.connectionInterval = setInterval(() => this.checkConnection(), 5000);

				this.pathStorage.setItem(this.refreshTokenName, this.jwtRefresh);
				this.pathStorage.setItem(this.authTokenName, this.jwtAuth).then(() => this.onSetAuthJWT());
				this.owner.ldc.user.passKeys.abortPendingPasskeyOperations();
				break;
			case LoginStatus.TWOFACTOR_INIT:
				this.freshTFKey = fp.pop_string();
				owner.onConnectionStatusChange(this.status, this.freshTFKey);
				break;
			case LoginStatus.PASSWORD_RESET: // Password is expired and requires changing
				owner.onConnectionStatusChange(this.status);
				break;
			case LoginStatus.TWOFACTOR_VERIFY:
				this.freshTwoFACookie = fp.pop_string();
				owner.onConnectionStatusChange(this.status, this.freshTFKey, this.freshTwoFACookie);
				break;
			case LoginStatus.LOGGED_OUT:
				owner.onConnectionStatusChange(this.status);
				location.reload();
				break;
			case LoginStatus.INVALID_PASSWORD:
				owner.onConnectionStatusChange(this.status);
				break;
			default:
				assert(false);
				break;
		}
	}

	onGetDeviceInfoResponse(fp: FrameParser) {	// process server response to getDeviceInfo() request:
		assert(fp.command == LiveData.WVCR_GET_DEVICE_INFO || fp.command == LiveData.WVCR_GET_DEVICE_INFO_V2);
		var deviceCount = fp.pop_u32();
		for (var i = 0; i < deviceCount; ++i) {			// deserialize all devices visible to this user:
			let device = new Device(this, parseDeviceFromFrame(fp));
			let oldDevice = this.devices.getByKey(device.key);

			if (oldDevice) {
				oldDevice.connected = device.connected;
			}
			else {
				device.initialize();
				this.devices.push(device);	// store each new device in devices collection
			}
			//@ts-ignore
			if (typeof window.isReportedDevice !== 'undefined') {
				//@ts-ignore
				if (!window.isReportedDevice(device.key))
					continue;
			}
		}

		// Add all of the company level custom files that this user can see
		var fileCount = fp.pop_u16();
		for (var i = 0; i < fileCount; ++i)
			this.customFiles.push(fp.pop_string());
		this.owner.onGetDeviceInfo();
	}

	migrateDevice(fromDevice: Device, toDevice: Device) {

	}

	onDeviceStatusChange(fp: FrameParser) {
		// Note: the protocol documents state that these commands can have multiple device ids in the
		// message payload, but Whoville currently only sends one-at-a-time. So, for now, just
		// process a single id.
		var fConnected = (fp.command == LiveData.WVC_CONNECTED);
		var deviceID = fp.pop_u32();
		var device = this.devices.get(deviceID);

		assert(device, 'Device id from WV should match one of our devices');

		if (device && (device.connected != fConnected)) { 	// match and connection status changed:
			if (!fConnected) {								// If this device is now no longer connected
				//				device.alarms.clear();						// Clear all alarms
				//				device.configuredAlarms.clear();			// Clear all configured alarms
				device.tree.setDisconnected();				// Set bad quality on every tag
				device.connected = fConnected;
			} else {
				device.connected = fConnected;
			}
		}
	}

	onEventCommand(fp: FrameParser) {	// WV is sending event info to one or more devices:
		var device = this.devices.get(fp.wv_id);
		assert(device, 'Device should exist');		// WTF
		if (device)	// Process remainder of frame bytes:
			device.alarms.onEventCommand(fp);
	}


	//FIXME: make this method type safe
	getGraphData(clientID: number, deviceID: number, interval: number, starts: number[], ends: number[], graphNodes: GraphNode[], fNewCrates: boolean) {
		if (!this.isLoggedIn())
			return;
		assert(interval > 0, "Invalid historical request data.");
		assert(graphNodes.length === starts.length, "Invalid node count.");
		assert(graphNodes.length === ends.length, "Invalid node count.");
		if (fNewCrates)										//@ts-ignore
			this.graphs[clientID].crateMap = {}; 			//@ts-ignore
		if (this.graphs[clientID]._reqNodes === undefined) 	//@ts-ignore

			this.graphs[clientID]._reqNodes = new Map(); 			//@ts-ignore
		this.graphs[clientID]._reqNodes.set(deviceID, graphNodes); 	//@ts-ignore
		this.graphs[clientID]._ignoreCrates = [];
		this.fm.buildFrame(LiveData.WVC_GRAPH_QUERY, deviceID, clientID);
		this.fm.push_u32(interval);	// Append interval between data
		var count = 0;
		var countOffset = this.fm.getPosition();
		this.fm.push_u16(count);	// Place holder for the amount of tags we are requesting
		for (var i = 0; i < graphNodes.length; ++i) {
			if (graphNodes[i].node.flags & NodeFlags.NF_DERIVED) {
				var ops = graphNodes[i].node.operations;
				graphNodes[i]._start = starts[i];
				graphNodes[i]._end = ends[i];

				for (var j = 0; j < ops.length; ++j) {
					if (ops[j].node !== undefined) {
						++count;
						this.fm.push_u32(starts[i]);	// Append interval start time
						this.fm.push_u32(ends[i]);		// Append interval end time
						var node = this.devices.get(deviceID)!.tree.getNode(ops[j].node)!;
						this.fm.push_string(node.getDeviceRelativePath());	// Append each node's full path name
						this.fm.push_u64(0);			// No old crates allowed
						this.fm.push_u16(0);								//@ts-ignore
						this.graphs[clientID]._ignoreCrates.push(true);
					}
				}
			} else {
				++count;
				this.fm.push_u32(starts[i]);		// Append interval start time
				this.fm.push_u32(ends[i]);			// Append interval end time
				this.fm.push_string(graphNodes[i].path);	//@ts-ignore // Append each node's full path name
				var oldCrate = this.graphs[clientID].crateMap[graphNodes[i].name];
				this.fm.push_u64(oldCrate ? oldCrate.cn : 0);
				this.fm.push_u16(oldCrate ? oldCrate.size : 0); //@ts-ignore
				this.graphs[clientID]._ignoreCrates.push(false);
			}
		}
		var lastPosition = this.fm.getPosition();	// Record frame so we can zoom back to the end
		this.fm.setPosition(countOffset);			// Move back to where the count lives
		this.fm.push_u16(count);					// Update the count
		this.fm.setPosition(lastPosition);			// Go back to the end of the frame
		this.send();
	}

	getTrialData(clientID: number, deviceID: number, start: number, end: number, fBaseline: boolean, trialFlags: number, savingsFlags: number) {
		assert(this.isLoggedIn(), "User not logged in!");
		assert(end > start, "Invalid trial data request.");
		assert((trialFlags > 0) || (savingsFlags > 0), "No trial data requested.");

		this.fm.buildFrame(LiveData.WVC_TRIAL_QUERY, deviceID, clientID);
		this.fm.push_u32(start);				// Append interval start time
		this.fm.push_u32(end);					// Append interval end time
		this.fm.push_u8(fBaseline ? 1 : 0);		// Append if we want a baseline
		this.fm.push_u8(trialFlags);			// Append the flags we want
		this.fm.push_u8(savingsFlags);			// Append the flags we want
		this.send();
	}

	getRegimeCurves(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.LDC_REGIME_CURVES, deviceID, clientID);
		this.send();
	}

	getPointData(clientID: number, deviceID: number, fBySpeed: boolean, regime: number, fAuto: boolean, speeds: Node[] | null, convert: number | null, pointIndex: number | null) {
		assert(deviceID != 777);
		this.fm.buildFrame(LiveData.LDC_POINT_REQUEST, deviceID, clientID);
		this.fm.push_u8(fBySpeed ? 1 : 0);
		this.fm.push_u8(fAuto ? 1 : 0);
		this.fm.push_u16(regime);
		if (fBySpeed)
			for (var i = 0; i < speeds!.length; ++i)
				this.fm.push_f32(speeds![i].getValue() / convert!);
		else
			this.fm.push_u16(pointIndex);
		this.send();
	}

	getPumpCurves(clientID: number, deviceID: number, start: number, end: number, config: number) {
		this.fm.buildFrame(LiveData.WVC_PUMP_CURVES, deviceID, clientID);
		this.fm.push_u64(start);
		this.fm.push_u64(end);
		this.fm.push_u64(config);
		this.send();
	}

	getSnapshotData(clientID: number, deviceID: number, startTime: number, endTime: number) {
		this.fm.buildFrame(LiveData.WVC_SNAPSHOT_QUERY, deviceID, clientID);
		this.fm.push_u64(startTime);
		this.fm.push_u64(endTime);
		this.send();
	}

	getReportList(clientID: number, deviceID: number, caller: any) {
		this.pdfCaller = caller;
		this.fm.buildFrame(LiveData.WVC_GET_REPORT_LIST, deviceID, clientID);
		this.send();
	}

	getReport(clientID: number, deviceID: number, year: number, month: number, day: number, fSystem: boolean) {
		this.fm.buildFrame(LiveData.WVC_GET_REPORT, deviceID, clientID);
		this.fm.push_u16(year);
		this.fm.push_u8(month);
		this.fm.push_u8(day);
		this.fm.push_u8(fSystem ? 1 : 0);
		this.send();
	}

	getTestChecklists(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.LDC_TEST_CHECKLISTS, deviceID, clientID);
		this.send();
	}

	getSchedule(clientID: number, deviceID: number, endTime: number) {
		this.fm.buildFrame(LiveData.LDC_SCHEDULE_REQUEST, deviceID, clientID);
		this.fm.push_u32(endTime);
		this.send();
	}

	getGraphs(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.WVC_GET_GRAPHS, deviceID, clientID);
		this.send();
	}

	getUserPreferences(clientID: number) {
		this.fm.buildFrame(LiveData.WVC_GET_USER_PREFS, undefined, clientID);
		this.send();
	}

	setUserPreferences(clientID: number, preferences: string) {
		this.fm.buildFrame(LiveData.WVC_SET_USER_PREFS, undefined, clientID);
		this.fm.push_string(preferences);
		this.send();
	}

	getDashboards(clientID: number, companyKey: string) {
		this.fm.buildFrame(LiveData.WVC_GET_DASHBOARDS, undefined, clientID);
		this.fm.push_string(companyKey)
		this.send();
	}

	registerGraph(client: any) {
		var clientID = this.graphs.length;	// Set the clientID as the new graph index
		this.graphs.push(client);			// Add the client pointer on the end of the array
		return clientID;					// Return their new clientID
	}

	unregisterGraph(clientID: number) {
		assert(this.graphs[clientID], "No client registered at the client ID");
		this.graphs[clientID] = null;		// Remove the reference to the graph
		// TODO: Should really clean up the this.graphs array here. That way its size never balloons off forever
	}

	getTimeZone(timeZone: string) {
		this.fm.buildFrame(LiveData.WVC_GET_TIME_ZONE);
		this.fm.push_string(timeZone);
		this.send();
	}

	ping(clientID: number) {
		this.fm.buildFrame(LiveData.WVC_PING, undefined, clientID);
		this.send();
	}

	getCustomFile(clientID: number, key: string, fileName: string) {
		this.fm.buildFrame(LiveData.WVC_GET_CUSTOM_FILES, undefined, clientID);
		this.fm.push_string(key);		// Push the device or company so that the backend knows which file we're looking for
		this.fm.push_string(fileName);	// Push the name of the file we're looking for
		this.send();
	}

	getConfigFile(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.LDC_GET_CONFIG_FILE, deviceID, clientID);
		this.send();
	}

	setConfigFile(clientID: number, deviceID: number, file: string, fileSize: number) {
		if (!this.user.canModifyTags())
			return false;
		this.fm.buildFrame(LiveData.LDC_SET_CONFIG_FILE, deviceID, clientID);
		this.fm.push_bytes(file, fileSize);
		this.send();
	}

	getStartupLog(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.WVC_GET_LOG_FILE, deviceID, clientID);
		this.send();
	}

	getForm(deviceID: number, clientID: number) {
		this.fm.buildFrame(LiveData.LDC_GET_FORM, deviceID, clientID);
		this.send();
	}

	getDriverForms(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.LDC_GET_DRIVER_FORMS, deviceID, clientID);
		this.send();
	}

	getDrivers(clientID: number, deviceID: number) {
		this.fm.buildFrame(LiveData.LDC_GET_DRIVERS, deviceID, clientID);
		this.send();
	}

	submitAttributes(deviceID: number, clientID: number, form: FormElement) {
		if (!this.user.canModifyTags())
			return false;
		this.fm.buildFrame(LiveData.LDC_SUBMIT_ATTRIBUTES, deviceID, clientID);
		form.push(this.fm);
		this.send();
	}

	submitDriverAttributes(deviceID: number, clientID: number, driverID: string, driverName: string, form: FormElement) {
		if (!this.user.canModifyTags())
			return false;
		new WritesEnabler(() => {
			this.fm.buildFrame(LiveData.LDC_SUBMIT_DRIVER_ATTRIBUTES, deviceID, clientID);
			this.fm.push_string(driverID);
			this.fm.push_string(driverName);
			form.push(this.fm);
			this.send();
		});
	}

	// Here starts all the account management stuff
	getServiceTags(clientID: number, companyKey: string) {
		this.fm.buildFrame(LiveData.WVC_GET_SERVICE_TAGS, 0, clientID);
		this.fm.push_string(this.isPowerUser() ? companyKey : this.user.companyKey);
		return this.send();			// Send off the telegram
	}

	getLocalDeviceTokens(clientID: number, serviceTag: string) {
		this.fm.buildFrame(LiveData.WVC_GET_DEVICE_TOKEN_METADATA, 0, clientID);
		this.fm.push_string(serviceTag);
		return this.send();			// Send off the telegram
	}

	createLocalDeviceToken(clientID: number, serviceTag: string, expireInSecondsFromNow: number | undefined) {
		new WritesEnabler(() => {
			this.fm.buildFrame(LiveData.WVC_CREATE_DEVICE_TOKEN, 0, clientID);
			this.fm.push_string(serviceTag);
			this.fm.push_u64(expireInSecondsFromNow ? expireInSecondsFromNow : 2630000); // Default to 1 month if duration is not specified
			return this.send();			// Send off the telegram
		});
	}

	revokeLocalDeviceToken(clientID: number, tokenID: string, serviceTag: string) {
		new WritesEnabler(() => {
			this.fm.buildFrame(LiveData.WVC_REVOKE_DEVICE_TOKEN, 0, clientID);
			this.fm.push_string(serviceTag);
			this.fm.push_string(tokenID);
			return this.send();			// Send off the telegram
		});
	}

	assignSiteKey(clientID: number, serviceTag: string, deviceKey: string) {
		this.fm.buildFrame(LiveData.WVC_ASSIGN_DEVICE_KEY, 0, clientID);
		this.fm.push_string(serviceTag);
		this.fm.push_string(deviceKey);
		return this.send();			// Send off the telegram
	}

	createDeviceKey(clientID: number, serviceTag: string, siteName: string, timeZone: string) {
		this.fm.buildFrame(LiveData.WVC_CREATE_DEVICE_KEY, 0, clientID);
		this.fm.push_string(serviceTag);
		this.fm.push_string(siteName);
		this.fm.push_string(timeZone);
		return this.send();
	}

	getCompanies(clientID: number) {		// Get a list of all the companies
		if (!this.isPowerUser())	// Have to be a power user to use this method
			return false;			// Not sending the message
		this.fm.buildFrame(LiveData.WVC_COMPANIES, 0, clientID);
		return this.send();			// This is a pretty simple message, huh?
	}

	getCompanyUsers(clientID: number, companyKey: string) {	// Get a list of all the users for a company & their attributes
		if (!this.isAdmin())				// Have to be at least an admin to use this method
			return false;					// Have to be able to get the users for your company
		this.fm.buildFrame(LiveData.WVC_USERS, 0, clientID);
		this.fm.push_string(companyKey);	// Add the company key they wanted users for
		return this.send();					// Send off the telegram
	}

	getUserList(clientID: number, companyKey: string) {		// Get a list of all the users for a company
		this.fm.buildFrame(LiveData.WVC_GET_USER_LIST, 0, clientID);
		this.fm.push_string(companyKey);	// Add the company key they wanted users for
		return this.send();					// Send off the telegram
	}

	getGroups(clientID: number, companyKey: string) {	// Get a list of all the users for a company
		this.fm.buildFrame(LiveData.WVC_GET_GROUPS, 0, clientID);
		this.fm.push_string(companyKey);	// Add the company key they wanted users for
		return this.send();					// Send off the telegram
	}

	createUser(clientID: number, username: string, companyKey: string, firstName: string, lastName: string, email: string, phone: string, countryCode: number, permissions: PermissionGroup[], fEnabled: boolean, fWizard: boolean, fTagConfig: boolean, fDevConfig: boolean, fAdmin: boolean) {
		if (!this.isPowerUser()) {	// Power user's have permission to do just about anything
			if (!this.isAdmin())	// Have to be an admin to create a user for your company
				return false;
			if (this.user.companyKey != companyKey)		// Normal admins can only create users for their company
				return false;
		}
		if (!username)	// Username is required to create a user
			return false;

		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_ADD_USER);		// Push what account management command we are doing
		this.fm.push_string(username);						// Add in the new user name
		this.fm.push_string(companyKey);					// Company key must be provided
		this.fm.push_string(firstName);						// First Name must be provided
		this.fm.push_string(lastName);						// Last Name must be provided
		this.fm.push_string(email);							// Email must be provided
		this.fm.push_string(phone);							// Phone must be provided
		this.fm.push_u16(countryCode);
		if (permissions !== null && permissions !== undefined) {	// They provided a new group
			this.fm.push_u16(permissions.length);
			for (var i = 0; i < permissions.length; ++i) {
				this.fm.push_string(permissions[i].group!);
				this.fm.push_u8(permissions[i].fWrites);
			}
		}
		this.fm.push_u8(fEnabled);							// Admin status must be provided
		this.fm.push_u8(fWizard);							// Wizard Status
		this.fm.push_u8(fTagConfig);						// Tag Configuration
		this.fm.push_u8(fDevConfig);						// Device Configuration
		this.fm.push_u8(fAdmin);							// Admin status must be provided
		return this.send();
	}

	sendPasswordResetEmail(clientID: number, username: string) {
		if (!username)
			return false;

		this.fm.buildFrame(LiveData.WVC_FORGOT_PASSWORD, 0, clientID);
		this.fm.push_string(username);						// Username we're triggering the email for
		return this.send();									// Sent (no response expected)
	}

	deleteUser(clientID: number, username: string) {
		if (!(this.isPowerUser() || this.isAdmin()) || typeof username === 'undefined')	// Power user's have permission to do just about anything
			return false;
		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_DELETE_USER);	// Push what account management command we are doing
		this.fm.push_string(username);						// Add in the user name
		return this.send();
	}

	createGroup(clientID: number, group: string, companyKey: string, parent: string) {
		if (!this.isPowerUser()) {	// Power user's have permission to do just about anything
			if (!this.isAdmin() || companyKey != this.user.companyKey)
				return false;
		}
		if (! /^[a-zA-Z0-9\s]+$/.test(group))
			return false;

		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_CREATE_GROUP);	// Push what account management command we are doing
		this.fm.push_string(group);
		this.fm.push_string(companyKey);
		this.fm.push_string(parent);
		return this.send();
	}


	deleteGroup(clientID: number, group: string, companyKey: string) {
		if (!this.isPowerUser()) {	// Power user's have permission to do just about anything
			if (!this.isAdmin() || companyKey != this.user.companyKey)
				return false;
		}
		if (! /^[a-zA-Z0-9]+$/.test(group))
			return false;

		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_DELETE_GROUP);	// Push what account management command we are doing
		this.fm.push_string(group);
		this.fm.push_string(companyKey);
		return this.send();
	}

	modifyGroupDevices(clientID: number, group: string, companyKey: string, sites: string[]) {
		if (!this.isPowerUser()) {	// Power user's have permission to do just about anything
			if (!this.isAdmin() || companyKey != this.user.companyKey)
				return false;
		}
		if (! /^[a-zA-Z0-9 ]+$/.test(group))
			return false;

		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_GROUP_DEVICES);	// Push what account management command we are doing
		this.fm.push_string(group);
		this.fm.push_string(companyKey);
		this.fm.push_u16(sites.length);
		for (var i = 0; i < sites.length; ++i)
			this.fm.push_string(sites[i]);
		return this.send();
	}
	/**
	 * @param  {} clientID
	 * @param  {} username
	 * @param  {} password
	 * @param  {} first
	 * @param  {} last
	 * @param  {} email
	 * @param  {} phone
	 * @param  {} permissions
	 * @param  {} fEnabled
	 * @param  {} fWizard
	 * @param  {} fTagConfig
	 * @param  {} fDevConfig
	 * @param  {} fAdmin
	 * @param  {} callouts
	 * @param  {} intervals
	 * @param  {} lowSev
	 * @param  {} highSev
	 * @param  {} fail
	 * @param  {} failTime
	 * @param  {} fEnableTwoFactor
	 */
	updateUserInfo(clientID: number, username: string, first: string, last: string, email: string, phone: string, countryCode: string, permissions: PermissionGroup[], fEnabled: boolean, fWizard: boolean, fTagConfig: boolean, fDevConfig: boolean, fAdmin: boolean, callouts: string, intervals: CalloutInterval[], lowSev: number, highSev: number, fail: string, failTime: number, fEnableTwoFactor: boolean, notificationMethod: NotificationMethod | undefined) {
		if (!this.isAdmin())	// Have to be an admin to modify stuff
			return false;

		if (!username)	// Have to have the username of the user to modify
			return false;
		this.fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT, 0, clientID);
		this.fm.push_u16(LiveData.ACCOUNT_MODIFY_USER);	// Push what account management command we are doing

		var flagOffset = this.fm.getPosition();	// Get the current offset so we can update the flags at the end of the method
		var flags = 0;							// Start off with no modification flags
		this.fm.push_u16(flags);				// Reserve space in the frame for the flags
		this.fm.push_string(username);			// Add in the user to modify

		if (first) {							// They provided a new first name
			flags += LiveData.USER_FIRST;		// Set the flag
			this.fm.push_string(first);
		}
		if (last) {								// They provided a new last name
			flags += LiveData.USER_LAST;		// Set the flag
			this.fm.push_string(last);
		}
		if (fEnabled !== null && fEnabled != undefined) {	// They provided a new enabled setting
			flags += LiveData.USER_ENABLED;		// Set the flag
			this.fm.push_u8(fEnabled ? 1 : 0);
		}
		if (email) {							// They provided a new email address
			flags += LiveData.USER_EMAIL;		// Set the flag
			this.fm.push_string(email);
		}
		if (phone !== null && phone !== undefined) { // New phone number
			flags += LiveData.USER_PHONE;
			this.fm.push_string(phone);
			this.fm.push_u16(countryCode);
		}
		if (permissions !== null && permissions !== undefined) {	// They provided a new group
			flags += LiveData.USER_PERMISSIONS;	// Set the flag
			this.fm.push_u16(permissions.length);
			for (var i = 0; i < permissions.length; ++i) {
				this.fm.push_string(permissions[i].group!);
				this.fm.push_u8(permissions[i].fWrites);
			}
		}
		if (fWizard !== null && fWizard !== undefined) {	// They provided new admin parameters
			flags += LiveData.USER_WIZARD;	// Set the flag
			this.fm.push_u8(fWizard ? 1 : 0);
		}
		if (fTagConfig !== null && fTagConfig !== undefined) {	// They provided new admin parameters
			flags += LiveData.USER_TAG_CONFIG;	// Set the flag
			this.fm.push_u8(fTagConfig ? 1 : 0);
		}
		if (fDevConfig !== null && fDevConfig !== undefined) {	// They provided new admin parameters
			flags += LiveData.USER_DEV_CONFIG;	// Set the flag
			this.fm.push_u8(fDevConfig ? 1 : 0);
		}
		if (fAdmin !== null && fAdmin !== undefined) {	// They provided new admin parameters
			flags += LiveData.USER_ADMIN;	// Set the flag
			this.fm.push_u8(fAdmin ? 1 : 0);
		}
		if (callouts !== null && callouts !== undefined && intervals !== null && intervals !== undefined &&
			lowSev !== null && lowSev !== undefined && highSev !== null && highSev !== undefined &&
			fail !== null && fail !== undefined && failTime !== null && failTime !== undefined && notificationMethod !== undefined) {
			flags += LiveData.USER_CALLOUTS;	// Set the flag
			this.fm.push_string(callouts);
			this.fm.push_string(fail);
			this.fm.push_u8(failTime);
			this.fm.push_u16(lowSev);
			this.fm.push_u16(highSev);
			this.fm.push_u8(notificationMethod);
			this.fm.push_u16(intervals.length);
			for (var i = 0; i < intervals.length; ++i) {
				this.fm.push_u8(intervals[i].day);
				this.fm.push_u16(intervals[i].start);
				this.fm.push_u16(intervals[i].end);
			}

		}
		if (fEnableTwoFactor == true) { 						// They are making a change to a user's two factor settings
			flags += LiveData.USER_TWOFACTOR;				// Set the flag
			this.fm.push_u8(fEnableTwoFactor ? 1 : 0);
		}
		assert(flags > 0, "Not actually modifying a user.");	// Else why did you call us?
		// Update our flags in the frame
		var lastPosition = this.fm.getPosition();	// Record frame so we can zoom back to the end
		this.fm.setPosition(flagOffset);			// Move back to where the flags are
		this.fm.push_u16(flags);					// Update the flags
		this.fm.setPosition(lastPosition);			// Go back to the end of the frame
		return this.send();
	}

	addBaseline(sitekey: string, type: number, a: number, b: number, c: number) {
		if (!this.isPowerUser() || (a == 0 && b == 0 && c == 0))
			return false;

		for (var i = 0; i < this.devices.size(); ++i) {
			var device = this.devices.array[i];
			if (!device || device.key !== sitekey)
				continue;

			var pumpSystem = device.tree.nodes[0]!.findChildByRole(Role.ROLE_PUMP_BANK);
			if (!pumpSystem)
				return false;

			this.fm.buildFrame(LiveData.LDC_ADD_BASELINE, device.id, 0);
			this.fm.push_string(pumpSystem.getDeviceRelativePath());			// Append pump system name
			this.fm.push_u32(new Date().getTime() / 1000);	// Append the time the curve becomes active
			this.fm.push_u8(type);
			this.fm.push_f64(a);							// Append the three curve coefficients
			this.fm.push_f64(b);
			this.fm.push_f64(c);
			return this.send();
		}
		return false;
	}

	onAccountManagementResponse(fp: FrameParser) {
		var graph: any = this.hasGraph(fp);
		if (!graph)
			return;
		var command = fp.pop_u16();		// This is the account managament command that whoville responded to
		var fResult = fp.pop_u8();		// Whether or not the change was made
		graph.onAccountsManaged(command, fResult);	// Tell the owner what happened
	}

	onGetCompaniesResponse(fp: FrameParser) {
		var graph: any = this.hasGraph(fp);
		if (!graph)
			return;
		var companies: any[] = [];			// Array to hold all of our companies
		var count = fp.pop_u16();	// How many companies are attached
		for (var i = 0; i < count; ++i) {
			var company = {					// Create a new company object
				name: fp.pop_string(),	// Give each company a name
				key: fp.pop_string(),	// Give each company a key
			};
			companies.push(company);			// Add each company to the array
		}
		graph.onCompaniesReceived(companies);	// Tell the owner what happened
	}

	onGetCompanyUsersResponse(fp: FrameParser) {
		var graph: any = this.hasGraph(fp);
		assert(graph.onUsersReceived, 'Object that registered to receive company users has no onUsersReceived method');
		if (!graph)
			return;
		var users: any[] = [];	// Array to hold all of the users for the company
		var count = fp.pop_u16();	// How many users are attached
		for (var i = 0; i < count; ++i) {
			var user: UserInfo = {};										// Create a new user object
			user.userName = fp.pop_string();				// Give each user's name
			user.firstName = fp.pop_string();				// Give each user's first name
			user.lastName = fp.pop_string();				// Give each user's last name
			user.email = fp.pop_string();				// Give each user's email address
			user.phone = fp.pop_string();				// Give each user's phone number
			user.countryCode = fp.pop_u16();					// Give each user's country code
			var permissionCount = fp.pop_u16();
			user.permissions = [];
			for (var j = 0; j < permissionCount; ++j) {
				var group = fp.pop_string();
				var fWrites = fp.pop_bool();
				user.permissions.push({ group: group, fWrites: fWrites });
			}
			user.fEnabled = fp.pop_u8() ? true : false;	// Give each user's enabled status
			user.fWizard = fp.pop_u8() ? true : false;
			user.fTagConfig = fp.pop_u8() ? true : false;
			user.fDevConfig = fp.pop_u8() ? true : false;
			user.fAdmin = fp.pop_u8() ? true : false;	// Give each user's admin status
			user.callouts = fp.pop_string();
			user.failover = fp.pop_string();
			user.failMinutes = fp.pop_u8();
			user.lowSev = fp.pop_u16();
			user.highSev = fp.pop_u16();

			var intervalCount = fp.pop_u16();
			user.intervals = [];
			for (var j = 0; j < intervalCount; ++j) {
				var interval: CalloutInterval = {
					day: fp.pop_u8(),
					start: fp.pop_u16(),
					end: fp.pop_u16()
				}
				user.intervals.push(interval);
			}
			user.fTwoFactorEnabled = fp.pop_u8() ? true : false;	// Give each user's two-factor status
			user.lastLogin = fp.pop_u64();					// Give each user's time of last Login
			user.loginCount = fp.pop_u64();					// Give each user's login count
			user.smsSubscriptionStatus = fp.pop_u8();
			user.notificationMethod = fp.pop_u8();

			users.push(user);		// Add the user object to the array
		}
		graph.onUsersReceived(users);	// Tell the owner what happened
	};

	isChildGroupOf(parentGroup: GroupInfo, childGroup: GroupInfo): boolean {
		if (childGroup.name === "")
			return false;
		if (parentGroup == childGroup || childGroup.parent == undefined)
			return false
		else if (childGroup.parent == parentGroup)
			return true;
		else {
			return this.isChildGroupOf(parentGroup, childGroup.parent)
		}
	};

	sendNodeAttributeFrame(clientID: number, deviceID: number, parentID: number, id: number, name: string, fFolder: boolean, engMin?: number, engMax?: number, rawMin?: number, rawMax?: number, resolution?: number, units?: number, fWriteable?: boolean, fLogged?: boolean, roles?: Set<string>, driver?: string, sourceID?: string, sourceString?: string, remotePath?: string, description?: string, fTemporary?: boolean) {
		this.fm.buildFrame(LiveData.LDC_SUBMIT_NODE_ATTRIBUTES, deviceID, clientID);
		this.fm.push_u32(parentID);
		this.fm.push_u32(id);
		this.fm.push_string(name);
		this.fm.push_u8(fFolder ? 1 : 0);
		this.fm.push_u16(units != undefined ? units : 0);
		let flagOffset = this.fm.getPosition();	// Get the current offset so we can update the flags at the end of the method
		let flags = 0;							// Start off with no modification flags
		this.fm.push_u32(flags);				// Reserve space in the frame for the flags
		flags += NodeFlags.NF_USER;
		let userFlagOffset = this.fm.getPosition();	// Get the current offset so we can update the flags at the end of the method
		let userFlags = 0;							// Start off with no modification flags
		this.fm.push_u32(userFlags);				// Reserve space in the frame for the flags
		if (engMin !== undefined && engMax !== undefined && !isNaN(engMin) && !isNaN(engMax)) {
			flags += NodeFlags.NF_RANGE;		// Set the flag
			this.fm.push_f64(engMin);
			this.fm.push_f64(engMax);
			if (rawMin !== undefined && rawMax !== undefined && !isNaN(rawMin) && !isNaN(rawMax)) {
				flags += NodeFlags.NF_SCALING;
				this.fm.push_f64(rawMin);
				this.fm.push_f64(rawMax);
			}
		}
		if (resolution !== undefined && !isNaN(resolution)) {
			flags += NodeFlags.NF_RESOLUTION;	// Set the flag
			this.fm.push_f64(resolution);
		}
		if (fWriteable !== undefined) {
			flags += NodeFlags.NF_WRITE;	// Set the flag
			this.fm.push_u8(fWriteable ? 1 : 0);
		}
		if (fLogged !== undefined) {
			flags += NodeFlags.NF_LOG;		// Set the flag
			this.fm.push_u8(fLogged ? 1 : 0);
		}
		if (roles !== undefined) {
			flags += NodeFlags.NF_ROLE;
			this.fm.push_string(serializeRolesToCSV(roles));
		}
		if (driver !== undefined && sourceString !== undefined) {
			userFlags += UserNodeFlags.UNF_DRIVER;
			this.fm.push_string(driver);
			this.fm.push_string(sourceString);
		}
		if (sourceID !== undefined) {
			userFlags += UserNodeFlags.UNF_TYPE;
			this.fm.push_string(sourceID);
		}
		if (remotePath !== undefined && remotePath !== '') {
			userFlags += UserNodeFlags.UNF_LINKED;
			this.fm.push_string(remotePath);
		}
		if (description !== undefined && description !== '') {
			userFlags += UserNodeFlags.UNF_DESCRIPTION;
			this.fm.push_string(description);
		}
		if (fTemporary) {
			flags += NodeFlags.NF_TEMPORARY
			this.pendingTempCallbacks.push(clientID);
		}
		// Update our flags in the frame
		var lastPosition = this.fm.getPosition();	// Record frame so we can zoom back to the end
		this.fm.setPosition(flagOffset);			// Move back to where the flags are
		this.fm.push_u32(flags);					// Update the flags
		this.fm.setPosition(userFlagOffset);
		this.fm.push_u32(userFlags);
		this.fm.setPosition(lastPosition);			// Go back to the end of the frame
		return this.send()
	}

	submitNodeAttributes(clientID: number, deviceID: number, parentID: number, id: number, name: string, fFolder: boolean, engMin?: number, engMax?: number, rawMin?: number, rawMax?: number, resolution?: number, units?: number, fWriteable?: boolean, fLogged?: boolean, roles?: Set<string>, driver?: string, sourceID?: string, sourceString?: string, remotePath?: string, description?: string, fTemporary?: boolean) {
		if (!fTemporary)
			new WritesEnabler(() => { this.sendNodeAttributeFrame(clientID, deviceID, parentID, id, name, fFolder, engMin, engMax, rawMin, rawMax, resolution, units, fWriteable, fLogged, roles, driver, sourceID, sourceString, remotePath, description, fTemporary); });
		else
			this.sendNodeAttributeFrame(clientID, deviceID, parentID, id, name, fFolder, engMin, engMax, rawMin, rawMax, resolution, units, fWriteable, fLogged, roles, driver, sourceID, sourceString, remotePath, description, fTemporary);
	}

	submitRemoveNodeRequest(clientID: number, deviceID: number, id: number) {
		new WritesEnabler(() => {
			this.fm.buildFrame(LiveData.LDC_DELETE_NODE, deviceID, clientID);
			this.fm.push_u32(id);
			this.send();
		});
	}

	getSubbedNodeCount(): number {
		let subbed = 0;
		for (let device of this.devices.array) {
			if (device.isTreeComplete())
				device.tree.nodes.forEach(node => subbed += node?.subscribers?.size ?? 0);
		}
		return subbed;
	}

	onGetGroupsResponse(fp: FrameParser) {
		let graph: any = this.hasGraph(fp);
		if (!graph)
			return false;
		let groups: GroupInfo[] = [];
		groups.push({
			name: '',
			devices: [],
			children: [],
			parentName: "",
			parent: undefined
		})
		var count = fp.pop_u16();	// How many groups are attached
		for (let i = 0; i < count; ++i) {
			var group: GroupInfo = {
				name: fp.pop_string(),
				devices: [],
				children: [],
				parentName: "",
				parent: undefined
			};
			var devCount = fp.pop_u16();
			for (var j = 0; j < devCount; ++j) {
				var key = fp.pop_string();
				group.devices.push(key);
			}
			group.parentName = fp.pop_string();
			groups.push(group);		// Add the user object to the array
		}
		for (let i = 0; i < groups.length; i++) {
			groups[i].parent = groups.find((group) => group.name == groups[i].parentName && group !== groups[i]) // find our parent group
			groups[i].children = groups.filter((group) => { return this.isChildGroupOf(groups[i], group) })
		}
		graph.onGetGroupsResponse(groups);
	};

	////////////////////////////////////////////////////////////////////
	//
	//                        NODE PATH RESOLVER
	//                 exposed to users in the enlivener
	//
	//ResolvePath(initial,path,[environment])
	//Traverses devices, nodes, etc. to resolve a full node path.
	//  A starting node must always be provided: relative paths
	//   will be evaluted relative to it, while
	//
	// GENERAL PATTERN: (and relative paths)
	//  A filepath is a sequence of slash-separated instructions
	//  read left to right, guiding a traversal of the node tree.
	//  The traversal always starts on the node that ResolveNodePath
	//  is called on, although it can be instructed to jump to
	//  The most basic instruction is,
	//
	//    ''             -> null:                (a special case)
	//    '/'            -> returns initial:     (the empty path)
	//    'Pumps'        -> {initial}/Pumps
	//    '/Pumps/'      -> {initial}/Pumps:(extra slashed ignored)
	//    '/Pumps/1/HOA' -> {initial}/Pumps/1/HOA
	//
	//  If you want, you can also jump to a node's parent:
	//  this can be useful if for some reason your initial
	//  is set to, say, the sibling of the node you want.
	//   (users want this control because startingNode is often
	//    set by some convenience logic in the javascript.)
	//
	//    '../Station'   -> sibling Station:    (nav to sibling node)
	//    '../../'       -> grandparent node:   (nav to grandparent)
	//
	// ABSOLUTE PATHS:
	//  In addition to jumping to children, the path resolver can also
	//  jump straight to the LDC and resolve from there. In the tree
	//  imagined by the resolver, the LDC is root and has devices as
	//  children. The command that jumps to the LDC is '~':
	//
	//    '~'            -> initial.ldc:         (the actual ldc object)
	//    '~/DEVICE.KEY' -> root node of device: (finds device by key)
	//'~/OVOVO.OH/pumps' -> pumps folder:        (pumps folder of dvce.)
	//'Pumps/~/OVOVO.OH' -> root node of OVIVO:  (you can put ~ inside)
	//
	//  The case where ~ appears in the middle of the path (as in the
	//  final example above) is supported because some user code may
	//  want to blindly concat strings without depriving downstream
	//  path specifiers of the ability to write absolute paths.
	//  (see environment variables for a case where this is likely)
	//
	// ENVIRONMENT VARIABLES:
	//  ResolvePath may be called with an environment object,
	//  which can specify names and values for text templating
	//  before path evaluation.
	//
	//  Given, environment = {'mbr':'A'}:
	//    'Custom/Mbr{mbr}_Flux' -> Custom/MbrA_Flux
	//  Given, environment = {'foo':'bar/~/baz'}:
	//    '1/2{foo}'             -> 1/2bar/~/baz
	//
	//  Justification for env vars:
	//   Oftentimes, users will find that their node tree is not
	//   well-designed enough for features like 'initial' to handle
	//   all of the de-duplication that they would want. For example,
	//   legacy systems may prepend or append train numbers to tag names
	//   instead of organizing them into train folders.
	//
	//   For this reason, we support passing in an 'environment' object
	//   containing the names and values of these templating strings.
	//
	//  As it is treversing, this function will treat LDC as a part of the tree
	//  TODO: It'd be cool if this were recursive one day
	resolvePath(initial: any, path: string, environment: any, fDeviceLocate: boolean) {
		//Not recursive - it traverses many classes that I don't
		// want to have to modify. Note that it need not live
		// inside of LiveDataClient, and could probably be marked
		// static and put anywhere.
		//If given an environment object, start off
		// by doing all of the string replacements.

		if (environment) {
			for (var key in environment) {
				path = path.replace(new RegExp('{' + key + '}', 'g')
					, environment[key]);
			}
		}

		//Split the path into a list of well-trimmed commands.
		//Also, ignore empty commands. Why not?
		//  '  a/b /// c/' -> ['a','b','c']

		let pathString = path; //keep a ref to the string for error messages

		let pathArray: string[] = pathString.split('/')
			.map(function (str: string) { return str.trim(); }) //trim whitespace
			.filter(function (str: string) { return !!str; });  //drop ''

		return this.parseNodePath(pathArray, initial, fDeviceLocate);
	}

	parseNodePath(path: string[], initial: any, fDeviceLocate: boolean) {
		//I'm using if-else instead of switch b/c I don't like break
		//'~' jumps to root (ldc)
		//Iterate through the path and resolve it.
		let current = initial;
		for (var i = 0; i < path.length; i++) {

			if (path[i] === '~') {
				if (initial instanceof Node)
					current = initial.tree.device.ldc; //node->ldc
				else if (initial instanceof LiveDataClient)
					current = initial; //we started on an ldc
				else {
					assert(false, "'~': Path resolver initial not node or ldc!");
				}
			}

			//'..' navigates to parent (ldc or another node)
			else if (path[i] === '..') {
				if (current instanceof LiveDataClient) {
					current = current; //'..' goes nowhere on root
				} else if (current instanceof Node) {
					if (current.parent)
						current = current.parent; //normal node
					else
						current = current.tree.device.ldc;  //node->ldc
				} else {
					assert(false, "Path resolver navigated to unexpected object.");
				}
			}

			//Anything else will be taken as a node or device name
			// (depending on if current is a node or an ldc)
			else {
				let child: Node | null = null;
				if (current instanceof Node)
					child = current.findChild(path[i]); //node->node
				else if (current instanceof LiveDataClient) {
					var device;
					for (var j = 0; j < current.devices.array.length; ++j) {
						if (current.devices.array[j].key !== path[i])
							continue;
						device = current.devices.array[j];
						break;
					}
					if (!device) {
						console.log("ERROR: While traversing node path,", path, "attempted to find nonexistent device:", path[i], "which we were told was a child of:", current);
						return null;
					}
					if (fDeviceLocate)
						return device;
					child = device.tree.nodes[0];//ldc->node
				}
				if (child)
					current = child;
				else {
					console.log("ERROR: While traversing node path,", path, "attempted to find nonexistent node:", path[i], "which we were told was a child of:", current);
					return null;
				}
			}
		}
		return current;
	}

	uploadFile(file: File, path: string, callback: (url: string, uuid: string) => void) {
		this.pendingFileUpload = file;
		this.pendingUploadCallback = callback;
		//TODO: Show a nice loader animation here?
		this.getUploadURL(file, path, this.graphID)
	}

	getUploadURL(file: File, path: string, graphID: number) {
		if (file.size > 10000000) {	// They aren't allowed to upload files greater than 10MB (this is enforced by the signed URLs returned)
			new Dialog(document.body, {
				title: 'Error',
				body: `Your file [${file.size / 1000000}MB] is too large. The max upload size is 10MB.`
			});
		}
		else {
			this.fm.buildFrame(LiveData.WVC_GET_UPLOAD_URL, undefined, graphID);
			this.fm.push_string(file.name);
			this.fm.push_string(file.type);
			this.fm.push_string(path);
			this.fm.push_string(owner.ldc.user.companyKey);
			this.fm.push_u64(file.size);
			this.send();										// Send the frame
		}
	}

	onGetUploadURLResponse(fp: FrameParser) {
		if (!this.pendingFileUpload)
			return;
		let success = fp.pop_u8();
		if (success) {
			let url = fp.pop_string();
			let uuid = fp.pop_string();
			var xhr = new XMLHttpRequest();
			xhr.open("PUT", url);

			xhr.setRequestHeader("Content-Type", this.pendingFileUpload.type);
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					this.confirmUpload(uuid, xhr.status == 200);
					if (xhr.status != 200)
						new Dialog(document.body, {
							title: 'Error',
							body: 'There was an error while trying to upload a file. Please try again.'
						});
					else
						this.pendingUploadCallback(url, uuid);
				}
			};

			xhr.send(this.pendingFileUpload);
			this.pendingFileUpload = undefined
		}
	}

	confirmUpload(uuid: string, status: boolean) {
		owner.ldc.fm.buildFrame(LiveData.WVC_CONFIRM_UPLOAD);
		owner.ldc.fm.push_string(uuid);
		owner.ldc.fm.push_u8(status ? 1 : 0);
		owner.ldc.send();
	}
};
