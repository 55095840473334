import SVGWidget from './svgwidget';

// The Boolean Count lens iterates over the multiple nodes it is 
//  attached to, counting the number of truthy values and displaying
//  the number in the textContent of its element.
export default class BooleanCount extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);
		
		this.setupNodes();
	}
	update(node){
		let count = 0;
        for (var name in this.nodes){
            if (this.nodes[name].getValue())
                count++;
        }
        this.element.textContent = count;
	}
}
