import Icon from '../../../images/icons/name.svg';
import type { Tag } from '../../lib/tag'
import { TagSetAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './container.html';

@RegisterWidget({tag: 'hmi-container', template: template, displayName: 'Container', icon: Icon, section: 'Layout'})
export class Container extends Widget {
    classMap: Map<Tag, [string, string]> = new Map();
    @TagSetAttribute({displayName: 'Class Toggle', isOptional: true, shouldSubscribe: true, attributes: [
        {id: 'on-class', type: 'String', displayName: 'On Class'},
        {id: 'off-class', type: 'String', displayName: 'Off Class'},
    ]}) classToggleTag: TagDefinition[] = [];


    protected enliven(): void {
        this.classMap.clear();
        for (let tagDef of this.classToggleTag) {
            this.classMap.set(tagDef.tag, [tagDef.attributes!['on-class'], tagDef.attributes!['off-class']]);
        }
    }

    update(tag: Tag): void {
        if (this.classMap.has(tag))
            this.classList.toggle(this.classMap.get(tag)![0], tag.getValue() == 1);
            this.classList.toggle(this.classMap.get(tag)![1], tag.getValue() == 0);

    }
}