import View from "./view";
import TankGraph from "../tankgraph";
import { createElement } from "../elements";
import './tankview.css';
import CycledTankGraph from "../cycledtankgraph";

/**
 * View wrapper class for the tank graph widgets. Lets us easily put tank graphs in View Modals.
 */
export default class TankView extends View {
    constructor(tank, fCycled, fTarget, fWetWell, options) {
        super();
        this.tank       = tank;
        this.fCycled    = fCycled;
        this.fTarget    = fTarget;
        this.fWetWell   = fWetWell;

        // default options
        this.fTitle     = true;
        this.copy(options);
    }
    initialize(parent) {
        super.initialize(parent);
        let container       = createElement('div', 'tank-view__container', this.parent);
        if (this.fTitle)
            this.title      = createElement('div', 'tank-view__title', container, this.tank.getDisplayName());

        this.graphContainer = createElement('div', 'tank-view__graph-container', container);
        this.description    = createElement('div', 'tank-view__description', container); //TODO: create description of what's going on with this tank
        container.onresize  = () => this.resize();

        if (this.fCycled) {
            this.graph      = new CycledTankGraph(this.graphContainer, this.tank)
        }
        else
            this.graph      = new TankGraph(this.graphContainer, this.tank, this.fTarget, this.fWetWell, {fPending: this.fPending, fShuttles: this.fShuttles}).initialize();

        this.fInitialized = true;
    }

    resize() {
        this.graph.destroy();
        this.graphContainer.removeChildren();
        this.width  = this.graphContainer.clientWidth;
        this.height = this.graphContainer.clientHeight;
        if (this.fCycled)
            this.graph      = new CycledTankGraph(this.graphContainer, this.tank)
        else
            this.graph      = new TankGraph(this.graphContainer, this.tank, this.fTarget, this.fWetWell,{fPending: this.fPending, fShuttles: this.fShuttles}).initialize();
    }

}