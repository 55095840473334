import { RegisterWidget, Widget } from "../../../lib/widget";
import { TagAttribute } from "../../../lib/tag";
import type { Tag, TagDefinition } from "../../../lib/tag";
import { Role } from "../../../../role";
import template from './pumpauto.html';

const ValueID = 'val';
const autoString	= 'DPO';
const manualString	= 'Manual';

@RegisterWidget({tag: 'pump-auto', displayName: 'Pump Auto', template: template})
export class PumpAuto extends Widget {
    valueElement: HTMLElement;
    hoa: Tag;
    fAutoSpeed: Tag;
    @TagAttribute({
        displayName: 'Pump Tag',
        supportedRoles: [Role.ROLE_PUMP],
    }) pumpTag: TagDefinition;

    protected enliven(): void {
        this.valueElement = this.shadowRoot!.getElementById(ValueID)!;

        // We find these nodes because software HOA implies fStartControlled and fAutoSpeedSwitch
        // implies fSpeedControlled. We don't subscribe to these nodes. We have to check for these
        // nodes because the overallHOA and such always exist, but don't tell the full story
        let pumpNode = this.pumpTag.tag;
        var softwareHOA		= pumpNode.findChildByRole(Role.ROLE_PUMP_SOFTWARE_HOA);
        var speedSwitch		= pumpNode.findChildByRole(Role.ROLE_AUTO_SPEED_SWITCH);

        // We subscribe to these nodes, but only if we need to
        if (softwareHOA) {
            this.hoa		= pumpNode.findChildByRole(Role.ROLE_PUMP_OVERALL_HOA)!;
            this.subscribeToTag(this.hoa);
        }
        if (speedSwitch) {
            this.fAutoSpeed	= pumpNode.findChildByRole(Role.ROLE_AUTO_SPEED_CONTROLLABLE)!;
            this.subscribeToTag(this.fAutoSpeed);
        }
        this.valueElement = this.shadowRoot!.getElementById(ValueID)!;
    }

    update(tag: Tag) {
        var fAuto = true;	// Assume auto until proven otherwise
        if (this.hoa && this.hoa.getValue() != 3)	// HOA_AUTO = 3
            fAuto = false;
        else if (this.fAutoSpeed && !this.fAutoSpeed.getValue())
            fAuto = false;

        this.valueElement.textContent = fAuto ? autoString : manualString;
    }
}