import owner from "../owner"
import { createElement, createUniqueId } from "./elements"
import CancelIcon from './images/icons/cancel.svg'
import Logo from './images/logo-white.png'
import './helper.css';

interface HelperTip {
    title         : string;
    body          : HTMLElement | string;
    element?      : HTMLElement;
    onAdvance?    : ()=>void;
    selector?     : string;
    clickCallback?: ()=>void;
    fTransition   : boolean;
}

export default class Helper {
    name:           string;
    tips:           HelperTip[];
    indicators:     HTMLElement[] = [];
    index:          number = 0;
    fForwardOnly:   boolean;
    clickCatcher:   HTMLElement;        // Background div to catch clicks outside the helper box
    hole:           HTMLElement;        // Div that highlights the element we are trying to emphasize
    container:      HTMLElement;        // Overall dialogue container
    title:          HTMLElement;        // Title text element
    body:           HTMLElement;        // Body text element
    rememberCheck:  HTMLInputElement;   // Checkbox to toggle whether or not we want to remember
    backButton:     HTMLButtonElement;
    nextButton:     HTMLButtonElement;
    close:          HTMLImageElement;
    logoContainer:  HTMLElement;
    fInitialized:   boolean = false;
    exitCallback:   (fCompleted: boolean)=>void;
    fCompleted:     boolean = false;
    constructor(name: string, tips: HelperTip[], fForwardOnly: boolean = false, exitCallback: (fCompleted: boolean)=>void) {
        this.name         = name;
        this.tips         = tips;
        this.fForwardOnly = fForwardOnly;
        this.exitCallback = exitCallback;
    }

    initialize() {
        if (owner.helper && owner.helper.fInitialized) 
            owner.helper.destroy();
        this.clickCatcher       = createElement('div', 'helper__click', document.body);
        this.hole               = createElement('div', 'helper__hole', document.body);
        this.container          = createElement('div', 'helper__container', document.body);
        this.logoContainer      = createElement('div', 'helper__logo-container', this.container)
        createElement('img', 'helper__logo', this.logoContainer, '', {'src':Logo});
        this.title              = createElement('div', 'helper__title', this.container, this.tips[0].title);
        this.body               = createElement('div', 'helper__body', this.container);

        let buttonRow           = createElement('div', 'helper__button-row', this.container)
        this.backButton         = createElement('button', 'se-button ' + (this.fForwardOnly? 'hide' : ''), buttonRow, 'Back')
        this.backButton.onclick = () => this.advance(--this.index);
        
        this.nextButton         = createElement('button', 'se-button', buttonRow, 'Next')
        this.nextButton.onclick = () => this.advance(++this.index);

        this.close              = createElement('img', 'modal__close', this.container, undefined, {src: CancelIcon});
        this.close.onclick      = () => this.destroy(); 

        window.onresize = () => {
            if (this.tips[this.index])
            this.advance(this.index)
        }

        let indexRow        = createElement('div', 'carousel-view__indicator-row', this.container);
        for (let i=0; i<this.tips.length; i++) {
            let indicator = createElement('div', 'carousel-view__indicator', indexRow);
            this.indicators.push(indicator);
        }
        this.indicators[0].classList.add('carousel-view__selected');
        this.clickCatcher.addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
        })
        this.advance(this.index);

        owner.helper = this;
        this.fInitialized = true;
        return this;
    }

    advance(index: number) {
        this.nextButton.onclick = () => this.advance(++this.index);
        this.hole.style.cursor = "auto";
        if (this.index > 0)
            this.logoContainer.classList.add('hide');
        else 
            this.logoContainer.classList.remove('hide');
        if (index == this.tips.length) {
            --this.index; 
            return;
        }
        this.nextButton.classList.remove('helper__button__disabled')

        let tip         = this.tips[index];
        if (tip.onAdvance) 
            tip.onAdvance();
        if (tip.clickCallback) {
            this.nextButton.onclick = () => {
                tip.clickCallback && tip.clickCallback()
                this.advance(++this.index);
            }

            this.hole.style.cursor = "pointer";
            this.hole.onclick = () => {
                tip.clickCallback && tip.clickCallback();
                this.advance(++this.index);
            };
        }
        else this.hole.onclick = ()=>{};
        
        let selector    = tip.selector;
        let element     = selector ? document.querySelector(selector) : tip.element;

        if (element && element instanceof HTMLElement) {
            if (this.tips[this.index].fTransition)
                window.addEventListener('transitionend', () => this.determineXY(element as HTMLElement), {'once':true})
            else 
                this.determineXY(element as HTMLElement);
        }
        else {
            this.hole.style.top         = window.innerHeight / 2 + 'px' // Y
            this.hole.style.left        = window.innerWidth / 2 + 'px' // X
            this.hole.style.width       = '1px';
            this.hole.style.height      = '1px';
            this.container.style.left   = Math.max(window.innerWidth / 2 - this.container.clientWidth / 2, 0) + 'px'
            this.container.style.top    = Math.max(window.innerHeight / 2 - this.container.clientHeight / 2, 0) + 'px'
        }

        this.hole.style.transition      = 'all 0.3s ease-in-out';
        this.container.style.transition = 'all 0.3s ease-in-out';
        this.title.textContent          = tip.title;
        this.body.removeChildren();
        if (tip.body instanceof HTMLElement)
            this.body.appendChild(tip.body);
        else 
            this.body.textContent = tip.body;

        for (let i=0;i<this.indicators.length;i++) {
            this.indicators[i].classList.remove('carousel-view__selected');
        }
        this.indicators[this.index].classList.add('carousel-view__selected');
        if (index == this.tips.length - 1) {
            this.nextButton.innerHTML   = 'Finish';
            this.nextButton.onclick     = () => {
                this.fCompleted = true;
                this.destroy();
            }
        }
        if (index == 0) this.backButton.classList.add('helper__button__disabled')
        else this.backButton.classList.remove('helper__button__disabled')
    }

    determineXY(element: HTMLElement) {
        this.scrollToShow(element);
        window.requestAnimationFrame(() =>{
            let rect: DOMRect   = element.getBoundingClientRect();
            this.determineExtents(element, rect)
            window.requestAnimationFrame(() => {
                if (window.innerWidth - rect.left - rect.width > this.container.clientWidth + 30) {
                    this.container.style.left = rect.left + rect.width + 15 + 'px'
                    this.container.style.top = Math.max(rect.top + rect.height / 2 - this.container.clientHeight / 2, 0) + 'px';
                }
                else if (rect.left > this.container.clientWidth + 30) {
                    this.container.style.left = rect.left -this.container.clientWidth - 30 + 'px'
                    this.container.style.top = Math.max(rect.top + rect.height / 2 - this.container.clientHeight / 2, 0) + 'px';
                }
                else {
                    this.container.style.left = rect.left + rect.width / 2 - this.container.clientWidth / 2 + 'px'
                }
            })
            return rect;
        })
    }

    resize() {
        this.advance(this.index)
    }

    scrollToShow(element: HTMLElement) {
        if (element.parentElement) {
            if (element.parentElement.getBoundingClientRect().bottom < element.getBoundingClientRect().bottom || element.parentElement.getBoundingClientRect().top > element.getBoundingClientRect().top) {
                element.parentElement.scrollTop = element.offsetTop;
            }
            this.scrollToShow(element.parentElement);
        }
    }

    determineExtents(element, rect) {
        if (element.parentNode && element.parentNode != document) {
            if (rect.top + rect.height > element.parentNode.getBoundingClientRect().top + element.parentNode.clientHeight)
                rect.height = element.parentNode.getBoundingClientRect().top + element.parentNode.clientHeight - rect.top + 'px';
            if (rect.left + rect.width > element.parentNode.getBoundingClientRect().left + element.parentNode.clientWidth)
                rect.width = element.parentNode.getBoundingClientRect().left + element.parentNode.clientWidth - rect.left + 'px';
            this.determineExtents(element.parentNode, rect)
        }
        else {
            this.hole.style.top     = rect.top + 'px';
            this.hole.style.left    = rect.left + 'px';
            this.hole.style.height  = rect.height + 'px';
            this.hole.style.width   = rect.width + 'px';
        }
    }
    
    destroy() {
        if (this.rememberCheck && this.rememberCheck.checked) {
            //TODO: Do the localstorage stuff
            /*
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 1000*86400;
            now.setTime(expireTime);
            document.cookie = this.cookie+'=true'+';expires='+now.toUTCString();
            */
        }
        document.body.removeChild(this.hole);
        document.body.removeChild(this.container);
        document.body.removeChild(this.clickCatcher);
        if (this.exitCallback)
            this.exitCallback(this.fCompleted);
        delete owner.helper;
    }
}