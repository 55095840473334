import assert from './debug';
// Create a color object from a CSS color string, breaking the string into r,g,b, and a:
// Legal values for 'value:
// rgb(100,100,100)
// rgb(50%, 33%, 0)
// rgba(100,100,100,0.5)
// rgba(33%,67%,100%,.25)
// hsl(255,50%,50%)
// hsla(1, 50%, 0, .995)
// #010203
// #123
// Any standard/extended CSS color name
// 'transparent'
// existing color object

export class Color  {
    constructor(value) {
        var rgb;
        
        if (value instanceof Color) {	// User passed in a color object, so copy it:
            this.rgba = [value.rgba[0], value.rgba[1], value.rgba[2], value.rgba[3]];
            return;
        }
        
        // Default is black (opaque) if value is not a valid color:
        this.rgba = [0, 0, 0, 1.0];	// red, green, black, opacity (0.0 = fully transparent, 1.0 = fully opaque)
        
        if (typeof (value) != 'string')
            return;
        
        // Strip all whitespace from the string to simplify regex matches:
        value = value.replace (/\s/g, '');	// Match all (g) occurrences of whitespace (\s) and replace each with ''
        
        // First, try to match rgb|hsl:
        rgb = value.match(/(rgb|hsl)\(([^,%]*)(%?),([^,%]*)(%?),([^,%]*)(%?)\)/);
        if (rgb == null) // Next, try to match rgba|hsla:
            rgb = value.match(/(rgba|hsla)\(([^,%]*)(%?),([^,%]*)(%?),([^,%]*)(%?),([^,]*)\)/);
        if (rgb != null) {	// Convert rgb(a) values:
            assert (rgb.length == 8 || rgb.length == 9);	// returns 'full match', 'rgb|hsl', 1st#, 1st%, 2nd#, 2nd%, 3rd#, 3rd%, alpha
            for (var i = 0; i < 3; ++i) {	// Convert rgb/hsl values:
                this.rgba[i] = Math.round(Math.max(0, Math.min(255, ((rgb[i*2+3] == '%') ? (parseFloat(rgb[i*2+2])*2.55) : parseInt(rgb[i*2+2], 10)))));
            }
            if (rgb.length == 9) // Convert alpha (opacity) (0.0 to 1.0):
                this.rgba[3] = Math.max(0.0, Math.min(1.0, parseFloat(rgb[8])));
            
            if(rgb[1][0] == 'h') {	// hue, saturation, lightness instead of rgb:
                // Convert from hsl to rgb:
                var converted = _hslToRgb(this.rgba[0]/255, this.rgba[1]/255, this.rgba[2]/255);
                this.rgba[0] = converted[0];
                this.rgba[1] = converted[1];
                this.rgba[2] = converted[2];
            }
            return;	// successfully converted rgb, rgba, hsl, or hsla
        }
        
        // Try to match hex color (no opacity/alpha):
        rgb = value.match(/#(..)(..)(..)/); // six-digit hex:
        if (rgb == null) { // try to match 3-digit hex:
            rgb = value.match(/#(.)(.)(.)/); // three-digit hex:
            
            if (rgb != null) {	// double up each of the three characters to convert 3-digit hex to 6-digit hex:
                for (i = 1; i < 4; ++i)
                    rgb[i] += rgb[i];
            }
        }
        
        if (rgb != null) {	// Six-digit hex:
            assert (rgb.length == 4, 'full match + r + g + b');
            assert (this.rgba[3] == 1.0, 'opacity must be 1.0 for hex color');	// no way to support opacity (yet) in standard hex formats
            
            // Convert hex to rgb:
            for (i = 0; i < 3; ++i)
                this.rgba[i] = parseInt(rgb[i+1], 16);
            
            return;	// successfully converted from hex color notation
        }
        
        // Try to match a color name:
        var colorSpec = _colors[value];
        if (colorSpec !== undefined)	// match found, so create a new Color object and return it.
            return new Color(colorSpec);
    };

	lighten(ratio) {	// return a lighter color by the ratio (e.g. 0.1 for 10%)
		assert (ratio >= 0.0);
		assert (ratio <= 1.0);
		var retVal = new Color();

		for (var i = 0; i < 3; ++i)	// lighten r g b by ratio:
			retVal.rgba[i] = Math.round(Math.max(0, Math.min(255, this.rgba[i] + (255 * ratio))));
		
		retVal.rgba[3] = this.rgba[3];	// Copy alpha
		return retVal;
	}
	
	darken(ratio) { // darken the color by a ratio (e.g. 0.25 for 25%)
		assert (ratio >= 0.0);
		assert (ratio <= 1.0);
		var retVal = new Color();

		for (var i = 0; i < 3; ++i) // darken r g b by ratio:
			retVal.rgba[i] = Math.round(Math.max(0, Math.min(255, this.rgba[i] - ((255 * ratio)))));
		
		retVal.rgba[3] = this.rgba[3];	// Copy alpha
		return retVal;
	}
	
	toString() {	// return rgba(#,#,#,#)
		if (this.rgba[3] == 1.0)
			return 'rgb('+this.rgba[0]+','+this.rgba[1]+','+this.rgba[2]+')';
		else
			return 'rgba('+this.rgba[0]+','+this.rgba[1]+','+this.rgba[2]+','+this.rgba[3]+')';
	}
};

export function _hslToRgb(h, s, l){
    var r, g, b;
    if(s == 0){
        r = g = b = l; // achromatic
    }else{
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = _hueToRgb(p, q, h + 1/3);
        g = _hueToRgb(p, q, h);
        b = _hueToRgb(p, q, h - 1/3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export function _hueToRgb(p, q, t) {
    if(t < 0) t += 1;
    if(t > 1) t -= 1;
    if(t < 1/6) return p + (q - p) * 6 * t;
    if(t < 1/2) return q;
    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
    return p;
};

export var _colors = {
	aliceblue:			'#F0F8FF',
	antiquewhite:		'#FAEBD7',
	aqua:				'#00FFFF',
	aquamarine:			'#7FFFD4',
	azure:				'#F0FFFF',
	beige:				'#F5F5DC',
	bisque:				'#FFE4C4',
	black:				'#000000',
	blanchedalmond:		'#FFEBCD',
	blue:				'#0000FF',
	blueviolet:			'#8A2BE2',
	brown:				'#A52A2A',
	burlywood:			'#DEB887',
	cadetblue:			'#5F9EA0',
	chartreuse:			'#7FFF00',
	chocolate:			'#D2691E',
	coral:				'#FF7F50',
	cornflowerblue:		'#6495ED',
	cornsilk:			'#FFF8DC',
	crimson:			'#DC143C',
	cyan:				'#00FFFF',
	darkblue:			'#00008B',
	darkcyan:			'#008B8B',
	darkgoldenrod:		'#B8860B',
	darkgray:			'#A9A9A9',
	darkgreen:			'#006400',
	darkgrey:			'#A9A9A9',
	darkkhaki:			'#BDB76B',
	darkmagenta:		'#8B008B',
	darkolivegreen:		'#556B2F',
	darkorange:			'#FF8C00',
	darkorchid:			'#9932CC',
	darkred:			'#8B0000',
	darksalmon:			'#E9967A',
	darkseagreen:		'#8FBC8F',
	darkslateblue:		'#483D8B',
	darkslategray:		'#2F4F4F',
	darkslategrey:		'#2F4F4F',
	darkturquoise:		'#00CED1',
	darkviolet:			'#9400D3',
	deeppink:			'#FF1493',
	deepskyblue:		'#00BFFF',
	dimgray:			'#696969',
	dimgrey:			'#696969',
	dodgerblue:			'#1E90FF',
	firebrick:			'#B22222',
	floralwhite:		'#FFFAF0',
	forestgreen:		'#228B22',
	fuchsia:			'#FF00FF',
	gainsboro:			'#DCDCDC',
	ghostwhite:			'#F8F8FF',
	gold:				'#FFD700',
	goldenrod:			'#DAA520',
	gray:				'#808080',
	green:				'#008000',
	greenyellow:		'#ADFF2F',
	grey:				'#808080',
	honeydew:			'#F0FFF0',
	hotpink:			'#FF69B4',
	indianred:			'#CD5C5C',
	indigo:				'#4B0082',
	ivory:				'#FFFFF0',
	khaki:				'#F0E68C',
	lavender:			'#E6E6FA',
	lavenderblush:		'#FFF0F5',
	lawngreen:			'#7CFC00',
	lemonchiffon:		'#FFFACD',
	lightblue:			'#ADD8E6',
	lightcoral:			'#F08080',
	lightcyan:			'#E0FFFF',
	lightgoldenrodyellow:'#FAFAD2',
	lightgray:			'#D3D3D3',
	lightgreen:			'#90EE90',
	lightgrey:			'#D3D3D3',
	lightpink:			'#FFB6C1',
	lightsalmon:		'#FFA07A',
	lightseagreen:		'#20B2AA',
	lightskyblue:		'#87CEFA',
	lightslategray:		'#778899',
	lightslategrey:		'#778899',
	lightsteelblue:		'#B0C4DE',
	lightyellow:		'#FFFFE0',
	lime:				'#00FF00',
	limegreen:			'#32CD32',
	linen:				'#FAF0E6',
	magenta:			'#FF00FF',
	maroon:				'#800000',
	mediumaquamarine:	'#66CDAA',
	mediumblue:			'#0000CD',
	mediumorchid:		'#BA55D3',
	mediumpurple:		'#9370DB',
	mediumseagreen:		'#3CB371',
	mediumslateblue:	'#7B68EE',
	mediumspringgreen:	'#00FA9A',
	mediumturquoise:	'#48D1CC',
	mediumvioletred:	'#C71585',
	midnightblue:		'#191970',
	mintcream:			'#F5FFFA',
	mistyrose:			'#FFE4E1',
	moccasin:			'#FFE4B5',
	navajowhite:		'#FFDEAD',
	navy:				'#000080',
	oldlace:			'#FDF5E6',
	olive:				'#808000',
	olivedrab:			'#6B8E23',
	orange:				'#FFA500',
	orangered:			'#FF4500',
	orchid:				'#DA70D6',
	palegoldenrod:		'#EEE8AA',
	palegreen:			'#98FB98',
	paleturquoise:		'#AFEEEE',
	palevioletred:		'#DB7093',
	papayawhip:			'#FFEFD5',
	peachpuff:			'#FFDAB9',
	peru:				'#CD853F',
	pink:				'#FFC0CB',
	plum:				'#DDA0DD',
	powderblue:			'#B0E0E6',
	purple:				'#800080',
	red:				'#FF0000',
	rosybrown:			'#BC8F8F',
	royalblue:			'#4169E1',
	saddlebrown:		'#8B4513',
	salmon:				'#FA8072',
	sandybrown:			'#F4A460',
	seagreen:			'#2E8B57',
	seashell:			'#FFF5EE',
	sienna:				'#A0522D',
	silver:				'#C0C0C0',
	skyblue:			'#87CEEB',
	slateblue:			'#6A5ACD',
	slategray:			'#708090',
	slategrey:			'#708090',
	snow:				'#FFFAFA',
	springgreen:		'#00FF7F',
	steelblue:			'#4682B4',
	tan:				'#D2B48C',
	teal:				'#008080',
	thistle:			'#D8BFD8',
	tomato:				'#FF6347',
	transparent:		'rgba(0,0,0,0)',
	turquoise:			'#40E0D0',
	violet:				'#EE82EE',
	wheat:				'#F5DEB3',
	white:				'#FFFFFF',
	whitesmoke:			'#F5F5F5',
	yellow:				'#FFFF00',
	yellowgreen:		'#9ACD32'
};

//SE._testColor = function () {
//	
//	var colors = [' rgb ( 0 , 1 , 2 ) ',	// rgb(0,1,2)
//	              'rgb(0,1,2,3)',			// rgb(0,0,0)	// invalid
//	              'rgba(0,1,2,3)',			// rgb(0,1,2)
//	              'hsla(100%,50%,10%,.25)',	// rgb(?,?,?,0.25)
//	              '#FF00FF',				// rgb(255,0,255)
//	              '#012',					// rgb(0,17,34)
//	              '#',						// rgb(0,0,0) // invalid
//	              'whitesmoke',
//	              'transparent'
//	              ];
//	
//	var indiv = ['white',
//	             'black',
//	             'red'
//	             ];
//	
//	for (var i = 0; i < colors.length; ++i)
//		console.log ('Test ' + i + ': ' + colors[i] + ' = ' + new SE.Color(colors[i]).toString());
//
//	console.log ('Test ' + (colors.length + 0) + ': ' + indiv[0] + ' = ' + new SE.Color(indiv[0]).darken(0.1).toString());
//	console.log ('Test ' + (colors.length + 1) + ': ' + indiv[1] + ' = ' + new SE.Color(indiv[1]).lighten(0.1).toString());
//	console.log ('Test ' + (colors.length + 2) + ': ' + indiv[2] + ' = ' + new SE.Color(indiv[2]).darken(0.5).toString());
//	console.log ('Test ' + (colors.length + 3) + ': ' + indiv[2] + ' = ' + new SE.Color(indiv[2]).lighten(0.49).toString());
//};
