import { createElement } from "./elements";
import './loader.css';

export default class Loader {
    parent: HTMLElement;
    loader: HTMLElement;
    constructor(parent: HTMLElement) {
        this.parent = parent;
        this.loader = createElement('div', 'ball-scale-ripple-multiple', this.parent)
        createElement('div', '', this.loader);
        createElement('div', '', this.loader);
        createElement('div', '', this.loader);
    }

    hide() {
        this.loader.classList.add('hide');
    };

    show() {
        this.loader.classList.remove('hide');
    }

    destroy() {
        this.parent.removeChild(this.loader)
    }
}