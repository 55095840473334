import LogoutIcon       from '../images/icons/logout.svg';
import AccountsIcon     from '../images/icons/accounts.svg';
import AlarmIcon        from '../images/icons/alarms.svg';
import MuteIcon         from '../images/icons/mute.svg';
import AudibleIcon      from '../images/icons/audible.svg';
import AlarmSound       from '../sounds/alarm.mp3';
import UnitsIcon        from '../images/icons/measure.svg';
import ConnectedIcon    from '../images/icons/socket_connected.svg';
import DisconnectedIcon from '../images/icons/socket_disconnected.svg';

import DownloadIcon  from '../images/icons/download.svg';

import { createElement, getHumanReadableTime } from "../elements";
import './toolbar.css';
import owner, { Routes } from '../../owner';
import Dropdown from './dropdown';
import { Device } from '../device';
import { Alarm } from '../alarm';
import { LogoutMessageData } from './logouttimer';
import Clock from './clock';

export default class Toolbar {
    wrapper: HTMLElement;
    bar: HTMLElement;
    leftList: HTMLElement;
    rightList: HTMLElement;
    tools: HTMLElement[];
    logoutText: HTMLElement;
    alarmText: HTMLElement;
    devices: Device[];
    alarm: Alarm;
    lastTime: number = 0;
    audio: HTMLAudioElement;
    sortedAlarms: Alarm[];
    alarmContainer: HTMLElement;
    subbedCount: HTMLElement;
    alarmCount: HTMLElement;
    socketIcon: HTMLImageElement;
    constructor(parent: HTMLElement) {
        this.wrapper        = createElement('div', 'toolbar__wrapper', parent);
        this.bar            = createElement('div', 'toolbar__bar', this.wrapper);
        this.tools 			= [];
        let logoutContainer = createElement('div', 'toolbar__button', this.bar);
        createElement('img', 'toolbar__button__icon', logoutContainer, undefined, {'src':LogoutIcon});
        this.logoutText = createElement('div', 'toolbar__button__text', logoutContainer, 'Logout in ');

        logoutContainer.onclick = (e: MouseEvent) => new Dropdown(e, ['Logout', 'Disable Autologout'], (selection: string)=>{
            switch(selection) {
                case 'Logout':
                    owner.ldc.logOut();
                break;
                case 'Disable Autologout':
                    owner.logoutTimer.stop();
            }
        })
        owner.logoutTimer.register(this, this.updateLogout);

        this.alarmText          = createElement('div', 'toolbar__alarm', this.bar);
		// Right-side toolbar items
		let rightToolsContainer = createElement('div', 'toolbar__bar__right-tools', this.bar);

        let timeContainer       = createElement('div', 'toolbar__button', rightToolsContainer);
        let time                = createElement('div', 'toolbar__button__text', timeContainer);
        let timeZone            = createElement('div', 'toolbar__button__text', timeContainer);
        new Clock(time, undefined, timeZone);
		let alarmContainer   	= createElement('div', 'toolbar__button', rightToolsContainer);
		alarmContainer.title 	= 'Alarms';
        createElement('img', 'toolbar__button__icon', alarmContainer, undefined, {'src': AlarmIcon});
		this.alarmCount = createElement('div', 'toolbar__button__text', alarmContainer, '');
        alarmContainer.onclick 	= () => {
			owner.alarmPanel.isOpen ? owner.alarmPanel.close() : owner.alarmPanel.open();
        }

        owner.alarmManager.registerForAlarmChanges(this);

        let muteContainer   = createElement('div', 'toolbar__button', rightToolsContainer);
		muteContainer.title = 'Mute';
        let muteIcon        = createElement('img', 'toolbar__button__icon', muteContainer, undefined, {'src': owner.fAudible ? AudibleIcon : MuteIcon});
        muteContainer.onclick = () => {
            owner.fAudible  = !owner.fAudible;
            muteIcon.src    = owner.fAudible ? AudibleIcon : MuteIcon
        }

        let unitsContainer   = createElement('div', 'toolbar__button', rightToolsContainer);
		unitsContainer.title = 'Units';
        let unitsIcon        = createElement('img', 'toolbar__button__icon', unitsContainer, undefined, {'src': UnitsIcon});
        unitsContainer.onclick = (e: MouseEvent) => new Dropdown(e, ['SI', 'US'], (selection: string)=>{
            owner.onUnitSelectionChanged(selection);
        })

        //let socketContainer     = createElement('div', 'toolbar__button', rightToolsContainer);
		//socketContainer.title   = 'Socket';
        //this.socketIcon         = createElement('img', 'toolbar__button__icon', socketContainer, undefined, {'src': ConnectedIcon});
        //let closeEvent          = new CloseEvent('close');
        //socketContainer.onclick = () => {
        //    //owner.ldc.cleanup(true);
        //    owner.ldc.socket?.close();
        //    owner.ldc.socket?.dispatchEvent(closeEvent);
        //    delete owner.ldc.socket;
        //}

        //if (owner.ldc.isPowerUser()) {
        //    let subbedContainer     = createElement('div', 'toolbar__button', rightToolsContainer);
		//	subbedContainer.title 	= '';
        //    let subbedIcon          = createElement('img', 'toolbar__button__icon', subbedContainer, undefined, {'src': DownloadIcon});
        //    this.subbedCount        = createElement('div', 'toolbar__button__text', subbedContainer)
        //}
//
        //if (owner.ldc.user.fWizard)
        //    this.createTool('Admin', AccountsIcon, owner.router.getRoute(Routes.Settings)!.getHash(), true);

        //this.createTool('Explorer', SearchIcon, )

        //this.createTool('Map', MapIcon, )

        //this.createTool('Dashboards', DashboardIcon, undefined)

        //this.createTool('Settings', SettingsIcon, undefined, true)

    }

    setSocketStatus(status: boolean) {
        if (this.socketIcon)
            this.socketIcon.src = status ? ConnectedIcon : DisconnectedIcon
    }

    onAlarmChanged() {
        this.sortedAlarms = Array.from(owner.alarmManager.activeAlarms);
        this.sortedAlarms.sort((a, b)=> a.tsActivated > b.tsActivated ? 1 : -1);
        if (this.sortedAlarms.length > 0) {
            this.bar.setAttribute('state', 'alarm');
            let alarm = this.sortedAlarms[this.sortedAlarms.length - 1];
            this.reportAlarm(alarm);
            this.alarmText.onclick = () => {
                owner.alarmPanel.isOpen ? owner.alarmPanel.close() : owner.alarmPanel.open();
                owner.alarmPanel.alarmSummaries.show(alarm.alarmSet.device)
            }
        }
        else {
            this.bar.setAttribute('state', '');
            this.alarmText.textContent = '';
        }
        this.alarmCount.textContent = owner.alarmManager.activeAlarmCount.toString();
    }

    updateLogout(data: LogoutMessageData) {
        switch (data.message) {
            case 'tick':
                this.logoutText.textContent = `Logout in ${owner.logoutTimer.timeLeft}`;
                break;
            case 'stop':
                this.logoutText.textContent = 'Autologout Disabled';
                break;
        }
    }

    reportAlarm(alarm: Alarm) {
        this.bar.setAttribute('state', 'alarm');

		if (this.alarm != alarm && alarm.tsActivated > this.lastTime) {
			this.alarm      = alarm;
			this.lastTime   = alarm.tsActivated;

			const tag = alarm.tag![0] === '/' ? alarm.tag!.substr(1) : alarm.tag;
			this.alarmText.textContent = alarm.message + ' (' + tag + ' at ' + alarm.alarmSet.device.siteName + ', '
											+ getHumanReadableTime(alarm.tsActivated, 'for ', '') +')';

			if (this.audio) {
				this.audio.pause();
				this.audio.currentTime = 0;
			}
            /*
			close.onclick 	= (e) => {
				e.stopPropagation();
				if (this.audio) {
					this.audio.pause();
					this.audio.currentTime = 0;
				}
				this.closeNotification(container)
			}
			container.onclick = () => {
				for (let i=0;i<this.snacks.length;i++) {
					this.closeNotification(this.snacks[i]);
				}
				owner.alarmPanel.open();
			}
            */
			if (owner.fAudible) {
				this.audio = new Audio(AlarmSound);
				this.audio.play();
			};
            /*
			requestAnimationFrame(()=>{
				bar.style.width = '100%';
				this.snacks.push(container);
				for (let i=0;i<this.snacks.length;i++) {
					this.snacks[i].style.bottom = (this.snacks.length - i) * 42 + 'px';
				}
				setTimeout(()=>this.closeNotification(container), 15000);
			});
            */
		}
    }

    createTool(name: string, icon: string, href: string, fBottom: boolean) {
        let tool = createElement('div', 'toolbar__tool', fBottom ? this.rightList : this.leftList);
        createElement('div', 'toolbar__tool__selected', tool);
        let iconWrapper = createElement('div', 'toolbar__tool__wrapper', tool)
        let iconElement = createElement('img', 'toolbar__tool__icon', iconWrapper, undefined, { 'src': icon });
        let nameElement = createElement('div', 'toolbar__tool__name', iconWrapper, name);
        tool.onclick = () => window.location.hash = href;

        /*
        tool.enterListener = () => {
            this.tools.forEach(tool => tool.panel.classList.add('hide'))
            if (!tool.view.fInitialized)
                tool.view.initialize();
            tool.panel.classList.remove('hide');
            this.wrapper.style.width = '400px';
            tool.panel.leaveListener = null;
            tool.panel.enterListener = () => {
                //tool.removeEventListener('mouseenter', tool.enterListener)
                tool.panel.addEventListener('mouseleave', tool.panel.leaveListener);
            }
            tool.panel.leaveListener = () => {
                console.log('leave')
                //tool.removeEventListener('mouseenter', tool.enterListener);
                tool.panel.classList.add('hide');
                this.wrapper.style.width = 'var(--toolbar-width)';
            }
            tool.panel.addEventListener('mouseenter', tool.panel.enterListener);
        }


        tool.addEventListener('mouseenter', tool.enterListener);
        */
        /*
        tool.panel.enterListener = () => {
            tool.removeEventListener('mouseenter', tool.enterListener)
        }
        tool.panel.enterListener('mouseenter', tool.enterListener);
        tool.panel.addEventListener('mouseleave', () => {
            this.wrapper.style.width = 'var(--toolbar-width)'
        })
        tool.onclick = () => {
            this.wrapper.style.overflow = 'visible';
        }
        */
        this.tools.push(tool);
    }
}