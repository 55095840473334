export const Role = {
    ROLE_UNDEFINED: "",
    // Top-level Roles
    ROLE_CUSTOM_FOLDER: "CustomFolder",	// site-specific nodes that should be displayed in the Custom tab
    ROLE_SYSTEM_FOLDER: "SystemFolder",
    ROLE_CONFIG_ERRORS: "ConfigErrors",
    // PumpSystem Roles
    ROLE_PUMP_BANK: 'PumpBank',
    ROLE_MAX_SPEED_HZ: 'MaxSpeedHz',
    ROLE_TOTAL_FLOW: 'TotalFlow',
    ROLE_TOTAL_POWER: 'TotalPower',
    ROLE_SEC: 'SEC',
    ROLE_DISCHARGE_PRESSURE: 'DischargePressure',
    ROLE_SUCTION_PRESSURE: 'SuctionPressure',
    ROLE_SOURCE_TANK_LEVEL: 'SourceTankLevel',
    ROLE_TARGET_TANK_LEVEL: 'TargetTankLevel',
    ROLE_SOURCE_TANK_ELEVATION: 'SourceTankElevation',
    ROLE_TARGET_TANK_ELEVATION: 'TargetTankElevation',
    ROLE_PUMP_HEAD: 'PumpHead',
    ROLE_HAS_POWER: 'SystemHasPower',
    ROLE_SYSTEM_MODEL_TIMESTAMP: 'SystemModelTimestamp',
    ROLE_STATION_EFFICIENCY: 'StationEfficiency',
    ROLE_RATED_CAPACITY: 'RatedCapacity',
    ROLE_ACTUAL_CAPACITY: 'ActualCapacity',
    ROLE_WORN_PUMP_COST: 'WornPumpCost',
    ROLE_WORN_PUMP_SECONDS: 'WornPumpSeconds',
    ROLE_ENERGY_COST: 'EnergyCost',
    ROLE_NEGLIGIBLE_FLOW: 'NegligibleFlow',
    ROLE_SYSTEM_CURVE_STATE: 'SystemCurveState',
    // Savings Folder roles
    ROLE_SAVINGS_FOLDER: "SavingsFolder",
    ROLE_VOLUME_TODAY: "VolumeToday",
    ROLE_ENERGY_TODAY: "EnergyToday",
    ROLE_SEC_TODAY: "SECToday",
    ROLE_SAVINGS_TODAY: "SavingsToday",
    // Pump Roles
    ROLE_PUMP: 'Pump',
    ROLE_PUMP_START_CONTROLLED: 'fStartControlled',
    ROLE_PUMP_SPEED_CONTROLLED: "fSpeedControlled",
    ROLE_PUMP_EXTERNAL_HOAS: 'ExternalHOAs',
    ROLE_PUMP_AUTO_HOA_SWITCH: 'AutoHOASwitch',
    ROLE_PUMP_EXTERNAL_ASE_SWITCHES: "ExternalASE_Switches",
    ROLE_PUMP_SOFTWARE_HOA: 'SoftwareHOA',
    ROLE_PUMP_AUTO_START: 'AutoStart',
    ROLE_PUMP_MAKE: 'PumpMake',
    ROLE_PUMP_MODEL: 'PumpModel',
    ROLE_PUMP_NOMINAL_POWER: 'PumpNominalPower',
    ROLE_PUMP_TOTAL_RUN_TIME: 'RunTime',
    ROLE_PUMP_CURRENT_RUN_TIME: 'CurrentRunTime',
    ROLE_PUMP_STARTS: 'Starts',
    ROLE_PUMP_START_TIME: 'StartTime',
    ROLE_PUMP_STOP_TIME: 'StopTime',
    ROLE_BOOL_STARTED: 'fStarted',
    ROLE_BOOL_RUNNING: 'fRunning',
    ROLE_BOOL_FAULTED: 'fFaulted',
    ROLE_FAULT_CODE: 'FaultCode',
    ROLE_FAULT_DESCRIPTION: 'FaultDescription',
    ROLE_PUMP_TEST: 'Test',
    ROLE_PUMP_OVERALL_HOA: 'OverallHOA',
    ROLE_PUMP_HEALTH_METRIC: 'HealthMetric',
    ROLE_PUMP_MIN_BEP_RATIO: 'MinBepRatio',
    ROLE_PUMP_MAX_BEP_RATIO: 'MaxBepRatio',
    ROLE_PUMP_MIN_AOR: 'MinAOR',
    ROLE_PUMP_MAX_AOR: 'MaxAOR',
    ROLE_PUMP_OFFLINE: 'fOffline',
    // System Model Roles
    ROLE_STATIC_TIME_CONSTANT: 'StaticTimeConstant',
    // TLC ROLES
    ROLE_TLC_FOLDER: 'TLC_Folder',
    ROLE_DPO_FOLDER: 'DPO_Folder',
    ROLE_DPO_IN_CONTROL: 'DPO_inControl',
    ROLE_TLC_PERIOD: 'TLC_Period',
    ROLE_TLC_POWER_METER_FOLDER: 'TLC_PowerMeterFolder',
    ROLE_TLC_SOURCE_TANK_FOLDER: 'TLC_SourceTankFolder',
    ROLE_TLC_TARGET_TANK_FOLDER: 'TLC_TargetTankFolder',
    ROLE_TLC_CONSTRAINT_FOLDER: 'TLC_ConstraintFolder',
    ROLE_TLC_POWER_METER: 'TLC_PowerMeter',
    ROLE_TLC_SOURCE_TANK: 'TLC_SourceTank',
    ROLE_TLC_TARGET_TANK: 'TLC_TargetTank',
    ROLE_TLC_TANK_ONLINE: 'TLC_Tank_Online',			// Tells whether the tank is online. Offline means to ignore the tank (e.g., it's down for maintenance).
    ROLE_TLC_TANK_LEVEL: 'TLC_Tank_Level',
    ROLE_TLC_CONSTRAINT_MIN: 'TLC_ConstraintMin',			// Folder for a single minimum export constraint
    ROLE_TLC_CONSTRAINT_MAX: 'TLC_ConstraintMax',			// Folder for a single maximum export constraint
    ROLE_TLC_CONSTRAINT_RANGE: 'TLC_ConstraintRange',		// Folder that produces a min and a max export constraint
    ROLE_TLC_CONSTRAINT_PV: 'TLC_ConstraintPV',			// Process variable
    ROLE_TLC_CONSTRAINT_SP: 'TLC_ConstraintSP',			// Setpoint
    ROLE_TLC_CONSTRAINT_DB: 'TLC_ConstraintDB',			// Deadband
    ROLE_TLC_CONSTRAINT_FEEDBACK: 'TLC_ConstraintFeedback',		// Feedback (value converted to flow if PV is a pressure)
    ROLE_TLC_CONSTRAINT_ENABLED: 'TLC_ConstraintEnabled',		// Constraint is enabled
    ROLE_TLC_CONSTRAINT_ONLINE: 'TLC_ConstraintOnline',		// Constraint is online
    ROLE_TLC_ENABLE_HEARTBEAT: 'TLC_EnableHeartbeat',		// Set false to test fail-over to customer's PLC.
    ROLE_TLC_TANK_STOP_LEVEL: 'TLC_Tank_Stop_Level',		// Tank Stop flowing level
    ROLE_TLC_TANK_START_LEVEL: 'TLC_Tank_Start_Level',		// Tank Start flowing level
    ROLE_TLC_TANK_FULLFLOW_LEVEL: 'TLC_Tank_FullFlow_Level',	// Tank Full flow level
    ROLE_TLC_TANK_STOP_FLOW: 'TLC_Tank_Stop_Flow',			// Tank Stop Flow
    ROLE_TLC_TANK_FULL_FLOW: 'TLC_Tank_Full_Flow',			// Tank Full Flow
    ROLE_TLC_TANK_DEADBAND: 'TLC_Tank_Deadband',			// Tank Deadband
    ROLE_TLC_TANK_MIN_FLOW: 'TLC_Tank_Min_Flow',			// Tank Min Flow
    ROLE_TLC_TANK_MAX_FLOW: 'TLC_Tank_Max_Flow',			// Tank Max Flow
    ROLE_TLC_TANK_HIGH_ALARM_LEVEL: 'TLC_Tank_High_Alarm_Level',
    ROLE_TLC_TANK_LOW_ALARM_LEVEL: 'TLC_Tank_Low_Alarm_Level',
    ROLE_TLC_TANK_HIGH_ALARM: 'TLC_Tank_High_Alarm',
    ROLE_TLC_TANK_LOW_ALARM: 'TLC_Tank_Low_Alarm',
    ROLE_TLC_CURRENT_CONFIG: 'TLC_CurrentConfig',
    ROLE_TLC_QMIN: 'TLC_QMin',
    ROLE_TLC_QMAX: 'TLC_QMax',
    ROLE_TLC_MANUAL_TEST_MODE: 'TLC_ManualTestMode',
    ROLE_TLC_MANUAL_TEST_COLLECT_DATA: 'TLC_CollectData',
    ROLE_TLC_MANUAL_TEST_END_TEST: 'TLC_EndTest',
    ROLE_TLC_TEST_START_TIME: 'TLC_TestStart',
    ROLE_TLC_TEST_STEP_TIME: 'TLC_TestStep',
    ROLE_TLC_TEST_COMPLETE_TIME: 'TLC_TestComplete',
    ROLE_TLC_TEST_PERCENT: 'TLC_TestPercent',
    ROLE_TLC_TEST_STATE: 'TLC_TestState',
    ROLE_TLC_PRE_CHECKLIST_DONE: 'TLC_PreChecklist',
    ROLE_TLC_POST_CHECKLIST_DONE: 'TLC_PostChecklist',
    ROLE_TLC_TARGET_SPEED: 'TLC_TargetSpeed',
    ROLE_TLC_IDEAL_SPEED: 'TLC_IdealSpeed',
    ROLE_TLC_SYNC_ACTIONS: 'TLC_SyncActions',
    ROLE_TLC_DISPLAY_ADVISORY: 'TLC_DisplayAdvisory',
    ROLE_TLC_FORCING_SYSTEM_FIT: 'TLC_ForcingSystemFit',
    ROLE_TLC_CONSTRAINT_ROC_FOLDER: 'TLC_ConstraintROCFolder',	// Folder containing export constraint folders
    ROLE_TLC_CONSTRAINT_ROC: 'TLC_ConstraintROC',			// Folder for a single export constraint
    ROLE_TLC_CONSTRAINT_ROC_PV: 'TLC_ConstraintROCPV',		// Process variable
    ROLE_TLC_CONSTRAINT_ROC_MAX_ROC: 'TLC_ConstraintROCMaxROC',	// Maximum rate of change in PV units per second
    ROLE_TLC_CONSTRAINT_ROC_FORWARD: 'TLC_ConstraintROCForward',	// If true, an increase in speed causes an increase in the PV
    ROLE_TLC_CONSTRAINT_ROC_ENABLED: 'TLC_ConstraintROCEnabled',	// Constraint is enabled
    ROLE_TLC_CONSTRAINT_ROC_LIMIT_UP: 'TLC_ConstraintROCLimitUp',	// Constraint is currently limiting increases in VFD speed
    ROLE_TLC_CONSTRAINT_ROC_LIMIT_DOWN: 'TLC_ConstraintROCLimitDown',	// Constraint is currently limiting decreases in VFD speed
    ROLE_TLC_CONSTRAINT_ROC_PV_LAG: 'TLC_ConstraintROCPVLag',		// State variable that lags the PV. Until it catches up, ramping is disabled.
    // CycleTank roles
    ROLE_CYCLE_TANK_MAX_LEVEL: 'CycleMaxLevel',
    ROLE_CYCLE_TANK_MIN_LEVEL: 'CycleMinLevel',
    ROLE_CYCLE_TANK_MIN_RANGE: 'CycleMinRange',
    ROLE_CYCLE_TANK_MIN_VOLUME: 'CycleMinVolume',
    ROLE_CYCLE_TANK_VOLUME_SO_FAR: 'CycleVolSoFar',
    // VFD roles
    ROLE_VFD: 'VFD',
    ROLE_AUTO_SPEED_CONTROLLABLE: 'AutoSpeedControl',
    ROLE_AUTO_SPEED_SWITCH: 'AutoSpeedSwitch',
    ROLE_AUTO_SPEED: 'AutoSpeed',
    ROLE_MAN_SPEED: 'ManSpeed',
    ROLE_REF_SPEED: 'ReferenceSpeed',
    ROLE_ACT_SPEED: 'ActualSpeed',
    ROLE_MIN_SPEED: 'MinSpeed',
    ROLE_MAX_SPEED: 'MaxSpeed',
    ROLE_BOOL_AT_SPEED: 'fAtSpeed',
    ROLE_ACCEL: 'Accel',
    ROLE_DECEL: 'Decel',
    ROLE_ACCEL2: 'Accel2',
    ROLE_DECEL2: 'Decel2',
    ROLE_CURRENT: 'Current',
    ROLE_VOLTAGE: 'Voltage',
    ROLE_INPUT_POWER: 'InputPower',
    ROLE_MOTOR_POWER: 'MotorPower',
    ROLE_SHAFT_POWER: 'ShaftPower',
    ROLE_FLOW: 'Flow',
    ROLE_ALTERNATOR_FOLDER: 'Alternator',
    ROLE_START_RULES_FOLDER: 'StartRules',
    ROLE_LOCKOUT_RULES_FOLDER: 'LockoutRules',
    ROLE_START_LEVEL: 'StartLevel',
    ROLE_STOP_LEVEL: 'StopLevel',
    ROLE_LEVEL_ENABLED: 'LevelEnabled',
    ROLE_START_INTERVAL: 'StartInterval',
    ROLE_STOP_INTERVAL: 'StopInterval',
    ROLE_MAX_PUMPS: 'MaxPumps',
    // PumpSystem ModelSnapshot role
    ROLE_MODEL_PUMPSYSTEM: 'ModelPumpSystem',
    ROLE_MODEL_TOTAL_FLOW: 'ModelTotalFlow',
    ROLE_MODEL_DISCHARGE: 'ModelDischarge',
    ROLE_MODEL_SUCTION: 'ModelSuction',
    ROLE_MODEL_PUMP: 'ModelPump',
    ROLE_MODEL_PUMP_FLOW: 'ModelPumpFlow',
    ROLE_MODEL_PUMP_HEAD: 'ModelPumpHead',
    ROLE_MODEL_PUMP_NPSHA: 'ModelPumpNPSHa',
    ROLE_WET_WELL_LEVEL: 'WetWellLevel',
    ROLE_WET_WELL_DEPTH: 'WetWellDepth',
    ROLE_FORCE_MAIN_DIAMETER: 'ForceMainDiameter',
    ROLE_HIGH_FLOAT_INDICATOR: 'HighFloatIndicator',
    ROLE_LOW_FLOAT_INDICATOR: 'LowFloatIndicator',
    ROLE_HIGH_FLOAT_LEVEL: 'HighFloatLevel',
    ROLE_LOW_FLOAT_LEVEL: 'LowFloatLevel',
    ROLE_INFLOW: 'Inflow',
    ROLE_OUTFLOW: 'Outflow',
    ROLE_LEVEL_MISMATCH: 'LevelMismatch',
    ROLE_LEVEL_MISMATCH_TOLERANCE: 'LevelMismatchTolerance',
    ROLE_OVERRIDE: 'Override',
    ROLE_LS_MAINT_START: 'LiftStationMaintenanceStart',
    ROLE_LS_MAINT_AUTO: 'LiftStationMaintenanceAuto',
    ROLE_LS_MAINT_TIME: 'LiftStationMaintenanceTime',
    ROLE_LS_MAINT_State: 'LiftStationMaintenanceState',
    ROLE_FLARE_FOLDER: 'Flare',
    ROLE_CHLORINE_RESIDUAL: 'ChlorineResidual',
    ROLE_IFS_PUMP: 'IFS_PUMP',
    ROLE_VERSION: 'Version',
    ROLE_TOP_LEVEL: 'TopLevel',
    ROLE_GRAPH_VALUE: 'GraphValue',
    ROLE_PUMP_HEALTH_METRIC_INPUT: 'HealthMetricInput',
    ROLE_RESET_FAULT: 'ResetFault',
    ROLE_MAINTENANCE_FOLDER: "Maintenance",
    ROLE_FLOAT_TEST_FOLDER: "FloatTest",
    ROLE_FORCE_MAIN_SCOUR_FOLDER: "ForceMainScour",
    ROLE_SNORE_FOLDER: "PumpSnore",
    ROLE_MAINTENANCE_TASK_FREQUENCY: "Frequency",
    ROLE_MAINTENANCE_TASK_USER_START: "Do Now",
    ROLE_MAINTENANCE_TASK_USER_CANCEL: "Cancel",
    ROLE_MAINTENANCE_TASK_TS_LAST: "LastExecuted",
    ROLE_MAINTENANCE_TASK_TS_NEXT: "NextExecution",
    ROLE_MAINTENANCE_TASK_WANTS_TO_EXECUTE: "WantsToExecute",
    ROLE_MAINTENANCE_TASK_COMPLETE: "Complete",
    ROLE_MAINTENANCE_TASK_QUEUED: "Queued",
    ROLE_MAINTENANCE_TASK_ACTIVE: "Active",
    ROLE_MAINTENANCE_TASK_ENABLED: "Enabled",
    ROLE_MAINTENANCE_TASK_STATE: "State",
    ROLE_MAINTENANCE_TASK_STATE_DESC: "StateDescription",
    ROLE_FLOAT_TEST_LOW_TIMEOUT: "LowTimeout",
    ROLE_FLOAT_TEST_HIGH_TIMEOUT: "HighTimeout",
    ROLE_FLOAT_TEST_MAX_LEVEL: "MaxLevel",
    ROLE_SCOUR_FLOW_RATE: "ScourFlowRate",
    ROLE_SCOUR_DAILY_SECONDS: "ScourDailySeconds",
    ROLE_SNORE_REQUEST_PLC: "PLC_SnoreRequest",
    ROLE_SNORE_DURATION: "SnoreDuration",
    ROLE_MAINTENANCE_SETTING: "MaintenanceSetting",
    ROLE_SCHEDULE_YEAR : "ScheduleYear",
    ROLE_SCHEDULE_MONTH : "ScheduleMonth",
    ROLE_SCHEDULE_DAY_OF_MONTH : "ScheduleDayOfMonth",
    ROLE_SCHEDULE_DAY_OF_WEEK : "ScheduleDayOfWeek",
    ROLE_SCHEDULE_TIME_OF_DAY : "ScheduleTimeOfDay",
    ROLE_PSC_CONTROLLING_LEVEL :"ControllingLevel",
    ROLE_BAR_GRAPH : "BarGraph",
    ROLE_OVERVIEW_GAUGE : "OverviewGauge"
}