import {Widget} from "./widget";
import createSVGElement from './svgelements'
import {createUniqueId, createElement} from './elements';
import {NodeQuality} from './node';
import Scale from './scale';
import assert from './debug';
import MinMax from "./minmax";
import { Shuttle } from "./shuttle";
import Handle from './handle';
import './responsivebargraph.css';

// Converts an html element into a bar graph.
// Supports CSS styling for its bargraph-frame, bargraph-background, and bargraph-bar elements.
// Inherits rounded corners from its parent element, if the parent is an HTML DOM element (not SVG)
// You have the option of eliminating the frame (stroke:none), and/or making the background transparent (fill:none).
// You can add a scale, shuttles and handles:
// Specify scale location as a bar graph property
// Programatically add multiple shuttles and handles by calling addShuttle() and addHandle()
// Programatically add a scale by calling addScale()

// BarGraph no longer has to have a node. If you want to control it directly, simply set minimum, maximum, and
// resolution properties in the constructor, and call setValue() to change the bargraph value.

// BarGraph usage:
// element:			(required)		DOM element that will be converted to a bargraph. Will be resized as needed.
// properties:		(optional)
//	node:			(default: none)	node from which bar gets its current value
//  width:			(default=25):	width of bar in CSS pixels, not including the frame
//  height:			(default=200):	height of bar in CSS pixels, not including frame
//  fVertical		(default=true):	true: bar is vertical, false: bar is horizontal
//  scalePosition	(default=none): position of scale relative to bar ('left', 'top', 'right', or 'bottom')
//	minMaxPosition	(default=none): position of bar min and max labels ('left', 'top', 'right', or 'bottom')
//	minimum/maximum	(default: use node range)

// BarGraph inherits Widget:
export default class ResponsiveBarGraph extends Widget {
    constructor(element, properties) {
        super();
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element = createElement('div', 'responsive-bar-graph', element);

        this.registerAsWidget(this.element);

        // 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
        // First step: Establish default properties:
        this.fVertical				= true;		// Draw bar vertically
        this.redXMask				= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)

        // Second step: copy properties over to the bar graph object:
        this.copy(properties);

        this.fInitialized	= false;
    }

    initialize() {
        this.container  = createElement('div', 'responsive-bar-graph__container', this.element);
        this.level      = createElement('div', 'responsive-bar-graph__level', this.container);

        if (this.fVertical)
            this.level.style.width = '100%';
        else
            this.level.style.height = '100%';

        this._determineMinMaxRes ();		// determine minimum, maximum, resolution

        if (this.minimum)
            this.setValue(this.minimum);

        else if (this.value)
            this.setValue(this.value)
        else
            this.setValue(this.minimum);

        if (this.fillColor) {
            this.container.style.backgroundColor = this.fillColor;
        }

        if (this.color) {
            this.level.style.backgroundColor = this.color;
        }

        if (this.ranges) {
            this.updateColors();
        }
        this.fInitialized = true;

        if (this.node)
            this.node.subscribe(this);

        if(this.lowLevel)
            this.lowLevel.subscribe(this, this.lowLimit, this.redXMask);

        if(this.highLevel)
            this.highLevel.subscribe(this, this.highLimit, this.redXMask);

        return(this);
    }

    update(node) {	// Called by the job whenever node value/quality changes:
        assert (this.fInitialized);
        switch (node) {
            case this.node:
                if (node.quality != NodeQuality.NQ_GOOD)	// bad quality:
                return;
                assert (!isNaN(node.getValue()));
                this.setValue(node.getValue());
                if (this.ranges) this.updateColors();
                break;
            case this.lowLevel:
                //requestAnimationFrame(() => {
                    if (!this.lowLimit) this.lowLimit = createElement('div', 'responsive-bar-graph__low-level', this.container)
                    if (this.fVertical)
                        this.lowLimit.style.height = (this.lowLevel.getValue() - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
                    else
                        this.lowLimit.style.width = (this.lowLevel.getValue() - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
                //});
                break;
            case this.highLevel:
                //requestAnimationFrame(() => {
                    if (!this.highLimit) this.highLimit = createElement('div', 'responsive-bar-graph__high-level', this.container)
                    this.maximum = this.highLevel.getValue() * 1.1;
                    if (this.fVertical)
                        this.highLimit.style.height = (this.maximum - this.highLevel.getValue() - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
                    else
                        this.highLimit.style.width = (this.maximum - this.highLevel.getValue() - this.minimum) / (this.maximum - this.minimum) * 100 + '%';

                //});
                break;
            default:
                break;
        }
    };

    set levelColor(color) {
        if (this.fInitialized)
            this.level.style.backgroundColor = color;
    }

    set backgroundColor(color) {
        if (this.fInitialized)
            this.container.style.backgroundColor = color;
    }

    getValue() {	// No need for bar graph to have a node. You can call this directly instead:
        var pixels = this.fVertical ? this.barRect.top + this.length - this.bar.y.baseVal.value : this.bar.x.baseVal.value - this.barRect.left + this.length;
        return (this.maximum - this.minimum) * pixels / this.length + this.minimum;
    };

    setValue(value) {	// No need for bar graph to have a node. You can call this directly instead:
        this.value = value;
        if (this.fVertical) {
            this.level.style.height = (value - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
        }
        else this.level.style.width = (value - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
    };

    addFloat(value) {
        let float = createElement('div', 'responsive-bar-graph__low-level', this.container)
        if (this.fVertical)
            float.style.height = (value - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
        else
            float.style.width = (value - this.minimum) / (this.maximum - this.minimum) * 100 + '%';
    }

    // Internal methods:
    _determineMinMaxRes() {
        // Determine bar minimum and maximum range in engineering units:
        // If user defined 'minimum' and 'maximum' properties already, then honor them regardless of node range.
        // Default is 0 to 100 with resolution of 1, if no value node defined:
        if (this.minimum === undefined)
            this.minimum = this.node && this.node.engMin ? this.node.engMin : 0;
        if (this.maximum === undefined)
            if (this.highLevel)
                this.maximum = this.highLevel.getValue() * 1.1;
            else
                this.maximum = this.node && this.node.engMax  ? this.node.engMax : 100;
        if (this.resolution === undefined)
            this.resolution = this.node && this.node.resolution  ? this.node.resolution : 1;
    };

    updateColors() {
        assert(this.ranges, 'updateColors method requires color ranges to be defined');
        this.levelColor = this.ranges[1][0];
        for (let i=0;i<this.ranges[0].length;i++) {
            if (this.node.getValue() > this.ranges[0][i])
                continue;
            else {
                this.levelColor = this.ranges[1][i - 1];
                break;
            }
        }
    }

    destroy() {
        if (this.node) this.node.unsubscribe(this);
        if (this.lowLevel) this.lowLevel.unsubscribe(this);
        if (this.highLevel) this.highLevel.unsubscribe(this);
        this.unregisterAsWidget();
    }
}