import './rangeslider.css';
import assert 				from './debug';
import { createElement } 	from './elements';

export default class SimpleSlider
{
	element     : HTMLElement;
	minimum     : number;
	maximum     : number;
	lowValue    : number;
	highValue   : number;
	resolution  : number;
	callback    : Function;
	minRange    : number  = 0;                        // No minimum range
	rangeColor  : string  = 'var(--color-orange-3)';
	fInitialized: boolean = false;

	wrapper   : HTMLElement;
	minSpinner: HTMLInputElement;
	maxSpinner: HTMLInputElement;
	container : HTMLElement;
	bar       : HTMLElement;
	range     : HTMLElement;
	minSlider : HTMLInputElement;
	maxSlider : HTMLInputElement;
	leftThumb : HTMLElement;
	rightThumb: HTMLElement;

    constructor(element: HTMLElement, min: number, max: number, resolution: number, properties?: {}, callback?: Function)
	{
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');

        this.element    = element;
        this.minimum    = min;
        this.maximum    = max;
        this.lowValue   = this.minimum;
        this.highValue  = this.maximum;
        this.resolution = resolution;
		if (callback) this.callback = callback;
		Object.assign(this, properties);
		
		this.initialize();
		return this;
    }

    initialize(): void
	{
        this.wrapper    = createElement('div', 'range-slider__wrapper', this.element);
        this.minSpinner = createElement('input', 'spinner range-slider__spinner', this.wrapper, undefined, {type: 'number'});
        this.maxSpinner = createElement('input', 'spinner range-slider__spinner', this.wrapper, undefined, {type: 'number'});
        this.container  = createElement('div', 'range-slider__container', this.wrapper);
   
        this.bar        = createElement('div', 'range-slider__bar', this.container);
        this.range      = createElement('div', 'range-slider__range', this.container);
        this.minSlider  = createElement('input', 'range-slider__slider', this.container, undefined, {type: 'range'});
        this.maxSlider  = createElement('input', 'range-slider__slider', this.container, undefined, {type: 'range'});
        this.leftThumb  = createElement('div', 'range-slider__thumb range-slider__left', this.container);
        this.rightThumb = createElement('div', 'range-slider__thumb range-slider__right', this.container);

        this.minSlider.oninput = () => {
			this.minSlider.valueAsNumber = Math.min(this.minSlider.valueAsNumber, this.maxSlider.valueAsNumber - this.minRange);
			this.minSpinner.value        = this.minSlider.value;
            let percent                  = (this.minSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
			this.leftThumb.style.left    = percent + '%';
			this.range.style.left        = percent + '%';
			this.minSpinner.style.left   = Math.min(this.range.offsetLeft, this.maxSpinner.offsetLeft - 105) + 'px';
        }
        this.minSlider.onchange = () => {
            let percent                = (this.minSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
			this.leftThumb.style.left  = percent + '%';
			this.range.style.left      = percent + '%';
			this.minSpinner.style.left = Math.min(this.range.offsetLeft, this.maxSpinner.offsetLeft - 105) + 'px';
			this.lowValue              = this.minSlider.valueAsNumber;
			if (this.callback) this.callback([this.lowValue, this.highValue]);
        }
        this.maxSlider.oninput = () => {
            let minValue                 = this.minSlider.valueAsNumber;
            let maxValue                 = this.maxSlider.valueAsNumber;
			this.maxSlider.valueAsNumber = Math.max(minValue + this.minRange, maxValue);
			this.maxSpinner.value        = this.maxSlider.value;

            let percent                 = (this.maxSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
			this.rightThumb.style.right = (100 - percent) + '%';
			this.range.style.right      = (100 - percent) + '%';
			this.maxSpinner.style.left  = Math.max(this.range.offsetLeft + this.range.clientWidth - 80, this.minSpinner.offsetLeft - 20) + 'px';
        }
    	this.maxSlider.onchange = () => {
            let percent                 = (this.maxSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
			this.rightThumb.style.right = (100 - percent) + '%';
			this.range.style.right      = (100 - percent) + '%';
			this.maxSpinner.style.left  = Math.max(this.range.offsetLeft + this.range.clientWidth - 80, this.minSpinner.offsetLeft - 20) + 'px';
			this.highValue              = this.maxSlider.valueAsNumber;
			if (this.callback) this.callback([this.lowValue, this.highValue]);
        }

        this.minSpinner.onchange = (e) => {
            this.minSpinner.valueAsNumber = Math.min(this.minSpinner.valueAsNumber, this.maxSlider.valueAsNumber - this.minRange);
            this.minSpinner.valueAsNumber = Math.max(this.minSpinner.valueAsNumber, this.minimum);
            this.minSlider.value          = this.minSpinner.value;
            this.minSlider.onchange && this.minSlider.onchange(e);
        }
        this.maxSpinner.onchange = (e) => {
            this.maxSpinner.valueAsNumber = Math.max(this.minSlider.valueAsNumber + this.minRange, this.maxSpinner.valueAsNumber);
            this.maxSpinner.valueAsNumber = Math.min(this.maxSpinner.valueAsNumber, this.maximum);
            this.maxSlider.value          = this.maxSpinner.value;
            this.maxSlider.onchange && this.maxSlider.onchange(e);
        }

        this.minSlider.onmousedown = () => this.minSpinner.classList.add("range-slider__mousedown");
        this.minSlider.onmouseup   = () => this.minSpinner.classList.remove("range-slider__mousedown");

        this.maxSlider.onmousedown = () => this.maxSpinner.classList.add("range-slider__mousedown");
        this.maxSlider.onmouseup   = () => this.maxSpinner.classList.remove("range-slider__mousedown");
       
        this.fInitialized = true;

        this.updateSliders();
    }

    updateSliders(): void
	{
		this.maxSlider.max          = `${this.maximum}`;
		this.maxSlider.min          = `${this.minimum}`;
		this.minSlider.min          = `${this.minimum}`;
		this.minSlider.max          = `${this.maximum}`;
		this.minSlider.value        = `${this.lowValue}`;
		this.maxSlider.value        = `${this.highValue}`;
        let percent                 = (this.minSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
		this.leftThumb.style.left   = percent + '%';
		this.range.style.left       = percent + '%';
		percent                     = (this.maxSlider.valueAsNumber - this.minimum) / (this.maximum - this.minimum) * 100;
		this.rightThumb.style.right = (100 - percent) + '%';
		this.range.style.right      = (100 - percent) + '%';
		this.minSpinner.value       = this.minSlider.value;
		this.maxSpinner.value       = this.maxSlider.value;
        requestAnimationFrame(() => {
            this.minSpinner.style.left = this.range.offsetLeft + 'px';
            this.maxSpinner.style.left = Math.max(this.range.offsetLeft + this.range.clientWidth - 80, this.minSpinner.offsetLeft - 20) + 'px';
        });

        this.maxSlider.max   = `${this.maximum}`;
        this.maxSlider.min   = `${this.minimum}`;
        this.minSlider.max   = `${this.maximum}`;
        this.minSlider.min   = `${this.minimum}`;
        this.minSpinner.max  = `${this.maximum}`;
        this.minSpinner.min  = `${this.minimum}`;
        this.maxSpinner.max  = `${this.maximum}`;
        this.maxSpinner.min  = `${this.minimum}`;
        this.maxSlider.step  = `${this.resolution}`;
        this.minSlider.step  = `${this.resolution}`;
        this.minSpinner.step = `${this.resolution}`;
        this.maxSpinner.step = `${this.resolution}`;
    }
}