import { Node as Tag } from "../../../node";
import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute, ColorAttribute } from '../../lib/attributes';
import SwitchIcon from '../../../images/icons/switch.svg';
import { TagAttribute } from "../../lib/tag";
import type { TagDefinition } from "../../lib/tag";
import template from './slider.html';

@RegisterWidget({tag: 'se-slider', displayName: 'Slider', template: template, icon: SwitchIcon, section: 'Input'})
export class Slider extends Widget {
    protected valueElement: HTMLDivElement;
    slider: HTMLInputElement;
    protected _value: number = 0;
    onchange: ()=>void = ()=>{};
    oninput: ()=>void = ()=>{};
    @Attribute({displayName: 'Background Color'}) backgroundColor: ColorAttribute = new ColorAttribute('black');
    @Attribute({displayName: 'Accent Color'}) accentColor: ColorAttribute = new ColorAttribute('blue');

    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;
    @Attribute({displayName: 'Min'}) min : number;
    @Attribute({displayName: 'Max'}) max : number;
    @Attribute({displayName: 'Step'}) step : number;

    protected connectedCallback(): void {
        this.slider = this.shadowRoot?.getElementById('slider') as HTMLInputElement;
        this.slider.oninput = () => {
            this._value = parseFloat(this.slider.value);
            this.onSliderInput()
            this.oninput();
        }
        this.slider.onchange = () => this.onchange()
        this.style.setProperty('--background-color', this.backgroundColor.color);
        this.style.setProperty('--accent-color', this.accentColor.color);
    }

    protected onSliderInput() {}

    set value(v: number) {
        this._value = v;
        this.slider.value = v.toString();
    }

    get value() {
        return this._value;
    }
}

@RegisterWidget({tag: 'se-tag-slider', displayName: 'Tag Slider', icon: SwitchIcon, section: 'Input'})
export class TagSlider extends Slider {
    @TagAttribute({
        displayName: 'Value Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['numeric']
    }) valueTag: TagDefinition;

    protected onSliderInput() {
        this.valueTag.tag.setPendingValue(this.value, this);
    }

    protected enliven(): void {
        this.slider.disabled = this.disabled;
        this.slider.min = (this.min ?? this.valueTag.tag.engMin)?.toString();
        this.slider.max = (this.max ?? this.valueTag.tag.engMax)?.toString();
        this.slider.step = (this.step ?? this.valueTag.tag.resolution)?.toString();
    }

    update(tag: Tag): void {
        this.value = this.valueTag.tag.getValue();
    }
}

