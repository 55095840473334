import SVGWidget from "./svgwidget";

// TODO: Document, please
export default class WriteButton extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

        this.setupNodes([''],true);

        if (!this.nodes['']) return; //abort
        if (!this.nodes[''].isWriteable){
            this.postError("This isn't a writeable node!");
            return;
        }
        var value = Number.parseInt(SVGWidget.readAttribute(this.element,"seValue"));

        let node = this.nodes[''];
        this.element._OLDonclick = this.element.onclick;
        this.element.onclick = function (event){
            node.setUnscaledValue(value);
            if (element._OLDonclick) element._OLDonclick(event);
        }
	}
}
