import './finderpage.css';
import {createElement} from '../elements';
import owner, { Routes } from '../../owner';
import Page from './page';
import TreeView, { GenericTreeable, TreeViewProperties, TreeViewTypes, Treeable } from '../views/treeview';
import { Node } from '../node';
import { LoggedFilter } from '../tagfilter';
import DeviceView from '../views/deviceview';
import { getHash } from '../router/router';

import BackArrow from '../images/icons/arrow_back.svg';

interface FinderProps {
    path: string;
    tab: string;
    index: string;
}
export default class FinderPage extends Page {
    background:         HTMLCanvasElement;
    treeView:           TreeView;
    configContainer:    HTMLElement;
    deviceView:         DeviceView;
    selectedTag:        Treeable;
    props:              FinderProps;
    tagWrapper:         HTMLElement;
    currentElement:      HTMLElement;
    resizeListener: () => void;
    downListener: (e: MouseEvent) => void;
    upListener: (e: MouseEvent) => void;
    moveListener: (e: MouseEvent) => void;
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent);
        this.props          = JSON.parse(JSON.stringify(props)) as FinderProps;
        this.parent         = parent;
        let finderContainer = createElement('div', 'finder__container', parent);
        this.tagWrapper         = createElement('div','finder__tag-view',finderContainer);
        let dragAndConfig = createElement('div', 'finder__drag-and-finder', finderContainer)
        let dragBar = createElement('div', 'finder__tag-drag', dragAndConfig);
        createElement('div', 'finder__tag-drag__bar', dragBar);
        this.moveListener = (e) => {
            let width = e.pageX - this.tagWrapper.getBoundingClientRect().left;
            (<HTMLElement>document.querySelector(':root')!).style.setProperty('--sidebar-width', width.toString() + 'px')
        }
        this.downListener = (e) => {
            document.addEventListener('mousemove', this.moveListener);
            this.upListener = () => {
                document.removeEventListener('mousemove', this.moveListener);
                document.removeEventListener('mouseup', this.upListener);
                dispatchEvent(new Event('resize'));
            };
            document.addEventListener('mouseup', this.upListener);
        }
        dragBar.addEventListener('mousedown', this.downListener);
        let config = createElement('div', 'finder__config-container', dragAndConfig);
        let topBar = createElement('div', 'finder__config__top-bar', config);
        let backWrapper = createElement('div', 'finder__back-wrapper', topBar);
        createElement('img', 'finder__back-arrow', backWrapper, '', {src: BackArrow});
        createElement('div', 'finder__back-text', backWrapper, 'Back');
        backWrapper.onclick = () => {
            this.tagWrapper.style.display = '';
        }
        this.configContainer    = createElement('div', 'finder__config-view', config);
        owner.navBar.setTitle('Tags');
        this.buildTreeView();
        this.onPathChanged(this.props.path, true);
        this.resizeListener = () => this.resize();
        window.addEventListener('resize', this.resizeListener);
    }

    onTagClicked(tag: Treeable) {
        if (tag === this.selectedTag)
            return;
        else if (tag instanceof Node && tag !== tag.device.tree.nodes[0])
            return;
        else if (tag instanceof Node)
            location.hash = getHash(Routes.Finder, {path: tag.device.key + ':' + tag.deviceRelativePath.slice(1).split('/').join('.')});
        else if (tag instanceof GenericTreeable && !(tag.parent instanceof Node))
            return;
        else if (tag instanceof GenericTreeable && tag.parent instanceof Node){
            location.hash = getHash(Routes.Finder, {path: `${tag.parent.device.key}:${tag.parent.deviceRelativePath.slice(1).split('/').join('.')}@${tag.name}`});
        }
        this.selectedTag = tag;
    }

    onCompanySelected() {
        this.buildTreeView();
    }

    buildTreeView() {
        if (this.treeView)
            this.treeView.destroy();
        this.tagWrapper.removeChildren();
        let tagViewProperties: TreeViewProperties = {
			type: 				    TreeViewTypes.TVT_FINDER,					    // let the user select any number of tags
            clickCallback:          (tag: Treeable) => this.onTagClicked(tag),
            supportsDragging:       true,
            searchFilters:          {
                andFilters: [new LoggedFilter(false, true)],
                orFilters:  []
            }
		}
        this.treeView = new TreeView(tagViewProperties).initialize(this.tagWrapper);
    }

    setProps(newProps: {[key: string]: string}): void {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'workDir':
                        this.onWorkingDirectoryChanged(value);
                        break;
                    case 'path':
                        this.onPathChanged(value);
                    break;
                    case 'tab':
                        this.onTabChanged(value);
                        break;
                    case 'dashboard':
                        this.onDashboardSelected(value)
                    default:

                        break;
                }
            }
        }
    }

    onDashboardSelected(dashboard: string) {
        let device = (this.selectedTag.parent as Node).device;
        if (device && (this.selectedTag.parent as Node).device.dashboards.has(dashboard)) {
            (this.selectedTag.parent as Node).device.dashboards.get(dashboard)?.id
        }

    }

    onPathChanged(path: string, scroll: boolean = false) {
        if (!path)
            return;
        let pathParts = path.split(':');
        let deviceKey = pathParts[0];
        if (!deviceKey) //TODO: Handle this
            return;
        let device = owner.ldc.devices.getByKey(deviceKey)!;
        if (!device)
            return;
        if (device.connected || device.cachedTree) {
            device.requestNodeTree(() => {
                let tagPath = '/' + pathParts[1].split('.').join('/');
                let tag = device.tree.findNode(tagPath)!;
                if (this.treeView.fSmall)
                    this.tagWrapper.style.display = 'none';
                this.treeView.clickTag(tag, scroll);
                this.tagClickedResponse(tag);
            })
        }
    }

    onWorkingDirectoryChanged(wd: string) {

    }

    onWidgetSelected() {

    }

    onTabChanged(tab: string) {
        if (!tab)
            return;
        this.currentElement && this.currentElement.setAttribute('tab', tab)
    }

    tagClickedResponse(tag: Treeable) {
        if (tag instanceof GenericTreeable) {
            this.configContainer.destroyWidgets(true);
            this.configContainer.removeChildren();
            this.configContainer.appendChild(tag.buildExtendedWidget());
        }
        else if (tag instanceof Node && tag === tag.tree.nodes[0]) { // If this is the root node
            this.configContainer.destroyWidgets(true);
            this.configContainer.removeChildren();
            createElement('device-widget', '', this.configContainer, '', {rootTag: {tag: tag}});
        }
        else if (tag instanceof Node) {
            //createElement('tag-config', '', this.configContainer, '', {configurableTag: {tag: tag}});
        }

    }

    resize() {
        this.treeView.resize();
    }

    destroy(): void {
        window.removeEventListener('resize', this.resizeListener);
        this.treeView.destroy();
        super.destroy();
    }
}