import { TagAttribute, type TagDefinition } from "./lib/tag";
import { RegisterWidget, Widget } from "./lib/widget";
import { CreateWidget } from "./lib/createwidget";

const template = /*html*/`
<style>
    :host {
        display: block;
        min-width: 360px;
    }
    #wrapper {
        width: 100%;
        display: block;
        container-type: inline-size;
    }
    p {
        margin: 0;
    }
    .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        min-height: 42px;
        gap: 5px;
    }
    @container (max-width: 320px) {
        #row {
            transform-origin: left;
            transform: scale(0.75)
        }
    }
</style>
<div id="wrapper">
    <div id="topRow" class="row"></div>
    <div id="bottomRow" class="row"></div>
</div>
`.trim();

@RegisterWidget({tag:'weekly-schedule', roles:['WeeklySchedule'], displayName: 'Weekly Schedule'})
export class WeeklySchedule extends Widget {
    valueElement: HTMLInputElement;
    @TagAttribute({
        displayName: 'Schedule Folder',
        supportedTypes: ['folder']
    }) scheduleFolder: TagDefinition;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void
    {
        let folder = this.scheduleFolder.tag; // Folder with a very specific set of child tags
        let nthWeek = folder.findChildByRole("NthWeek")!;
        let dayOfWeek = folder.findChildByRole("DayOfWeek")!;
        let startSecond = folder.findChildByRole("StartSecondOfDay")!;
        let endSecond = folder.findChildByRole("EndSecondOfDay")!;
        if (!nthWeek || !dayOfWeek || ! startSecond || !endSecond)
            throw(new Error('Weekly Schedule Folder does not have all necessary child tags'));

        let topRow = this.shadowRoot?.getElementById('topRow')! as HTMLDivElement;
        let text = document.createElement('p');
        text.innerText = 'Every';
        topRow.appendChild(text);
        topRow.appendChild(CreateWidget(nthWeek));
        topRow.appendChild(CreateWidget(dayOfWeek));

        let bottomRow = this.shadowRoot?.getElementById('bottomRow')! as HTMLDivElement;
        text = document.createElement('p');
        text.innerText = 'Between';
        bottomRow.appendChild(text);
        bottomRow.appendChild(CreateWidget(startSecond));
        text = document.createElement('p');
        text.innerText = 'and';
        bottomRow.appendChild(text);
        bottomRow.appendChild(CreateWidget(endSecond));
    }
}