import owner from '../../owner';
import Page from './page';
import createLoader, { createElement, createUniqueId } from '../elements';
import TextInput from '../components/textinput';
import Logo from '../images/logo-color.png';
import CheckIcon from '../images/icons/check.svg';
import XIcon from '../images/icons/close.svg';
import DisconnectIcon from '../images/icons/disconnected.svg'
import help from '../images/icons/help.svg';
import FaceIDIcon from '../images/icons/faceid.svg';
import TouchIDIcon from '../images/icons/touchid.svg';
import './loginpage.css';
import Loader from '../loader';
import Localization from '../localization';
import LiveDataClient, { LoginStatus, LoginStatusText, LoginMessage } from '../livedataclient';
import { deploySettings, localSettings, betaSettings, insecureLocal } from '../../deploymentspecificsettings';
import Switch from '../components/switch';
import PasswordSecurityChecker from '../passwordsecuritychecker';
import Dialog from '../dialog';
import { PasskeyLoginButton } from '../passkeys';
import QRCodeStyling from "qr-code-styling";
import { ReleaseNotes } from '../../releasenotes';

export default class LoginPage extends Page {
    constructor(parent, props) {
        super(parent, props);
        this.SubmitFunction = {
            LOGIN: 0,
            CHANGE_PASSWORD: 1,
            FORGOT_PASSWORD: 2,
            FORGOT_PASSWORD_RESET: 3
        }

        this.props = props;
        this.submitButtonFunction = this.SubmitFunction.LOGIN; // Default functionality is login

        var flexWrapper = createElement('div', 'login__flex-wrapper', parent)
        this.container = createElement('div', 'login__container', flexWrapper)
        this.logo = createElement('img', 'login__logo', this.container, undefined, { 'src': Logo });
        this.loginBox = createElement('div', 'login__box', this.container);

        var form = createElement('form', 'login__input-container', this.loginBox);
        var actions = createElement('div', 'login__actions', this.loginBox);
        this.copytwofatext = createElement('input', 'hide', form, undefined, { 'type': 'text' });
        this.copytwofa = createElement('input', 'login__two-factor__copy hide', form, null, { 'type': 'button', 'value': 'Copy Key/Manual Entry' });

        let versionText = process.env.VERSION;

        this.qrcode = createElement('img', 'login__qrcode hide', actions);
        let version = createElement('div', 'login__version', flexWrapper, `v${versionText}`);
        createElement('div', 'login__version__text', version);

        if (ReleaseNotes[versionText]) {
            version.classList.add('shiny');
            version.textContent = version.textContent + '✧˖°';
            version.onclick = () => {
                let modal = createElement('hmi-modal', 'login__version__modal', document.body, '', {titleBackgroundColor: 'var(--color-primary)',  titleText: `v${versionText}`, preferredWidth: '800px', preferredHeight: '800px'});
                let noteWrapper = createElement('div', 'login__version__modal__wrapper', modal);
                noteWrapper.innerHTML = ReleaseNotes[versionText];
            }
        }

        let loaderContainer = createElement('div', 'login__loader', actions)
        this.loader = new Loader(loaderContainer);
        this.loader.hide();

        // Passkey box
        let passkeyDivider = createElement('section', 'login__divider-container', this.container);
        createElement('div', 'login__divider-line', passkeyDivider);
        createElement('div', 'login__divider-text', passkeyDivider, "or");
        createElement('div', 'login__divider-line', passkeyDivider);

        let passkeyButton = new PasskeyLoginButton(this.container, true);

        if (typeof webkit !== 'undefined' && webkit.messageHandlers.callback) {
            passkeyDivider.classList.add('hide');
            passkeyButton.button.classList.add('hide');
        }

        this.passwordChangeRedirect = false;    // Keep track of whether the user needs to change their password based on client-side security checks
        this.hasBeenPromptedToUpdatePassword = false;    // Keep track of the whether the user has been prompted
        this.passwordSecurityChecker = new PasswordSecurityChecker();
        this.user = new TextInput(form, 'Username', 'var(--color-secondary)', {
            'autocomplete': 'username webauthn',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'background': 'true'
        });
        this.pwd = new TextInput(form, 'Password', 'var(--color-secondary)', {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'type': 'password',
            'background': 'true'
        })
        this.pwdReset = new TextInput(form, 'New Password', 'var(--color-error)', {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'type': 'password',
            'background': 'true'
        });
        this.pwdDuplicate = new TextInput(form, 'Retype Password', 'var(--color-primary)', {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'type': 'password',
            'background': 'true'
        });
        this.twofatoken = new TextInput(form, 'Two-Factor Code', 'var(--color-primary)', {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'type': 'number',
            'background': 'true',
            'pattern': '[0-9]*',
        });

        var id = createUniqueId();

        this.rememberDiv = createElement('div', 'login__remember se-checkbox hide', actions)
        this.remember = createElement('input', null, this.rememberDiv, null, { 'type': 'checkbox', 'id': id });
        this.rememberLabel = createElement('label', null, this.rememberDiv, 'Trust this device for 30 days', { 'htmlFor': id });
        this.submit = createElement('input', 'se-button login__button', actions, null, { type: 'button', 'value': 'LOGIN' });
        this.bioIcons = {
            'faceid': FaceIDIcon,
            'touchid': TouchIDIcon,
        }
        this.biometric = createElement('div', 'se-button login__button hide', actions, '', { type: 'button' })
        this.bioImage = createElement('img', 'login__button__icon', this.biometric, null, { 'src': CheckIcon });
        this.bioText = createElement('div', 'login__button__text', this.biometric, '');

        this.autoContainer = createElement('div', 'login__auto hide', actions);
        this.autoText = createElement('div', 'login__auto__text', this.autoContainer, '');
        this.autoSwitch = new Switch(this.autoContainer, false, 'var(--color-primary)', () => { })
        this.biometric.onclick = this.biometric.touchstart = () => {
            if (owner.fApp)	// If we're running in an ios app, tell the app we need to initialize two factor
                webkit.messageHandlers.callback.postMessage({ message: 'biometricLogin' });
        }
        this.status = createElement('div', 'login__status', actions);

        // helper messages for creating strong passwords
        this.pwdHelp = createElement('div', 'login__pwd-help hide', actions);
        createElement('div', 'login__pwd-help__title', this.pwdHelp, 'Password Must Be:')

        let helpChar = createElement('div', 'login__pwd-help__line', this.pwdHelp);
        helpChar.icon = createElement('img', 'login__pwd-help__icon', helpChar, undefined, { 'src': XIcon });
        helpChar.text = createElement('span', 'login__pwd-help__text', helpChar, 'At Least 8 Characters');
        helpChar.check = () => { return this.passwordSecurityChecker.isLongEnough };

        var haveIBeenPwnedExplanation = 'Passwords are securely checked for existence in the extensive "have i been pwned?" database of known weak and compromised passwords.';

        let hibpContainer = createElement('div', 'login__hibp__container login__clickable', this.pwdHelp);
        this.hibpStatus = createElement('div', 'login__pwd-help__line login__clickable', hibpContainer);

        this.hibpStatus.loaderContainer = createElement('div', 'login__pwd-help__icon', this.hibpStatus);
        this.hibpStatus.icon = createElement('img', 'login__pwd-help__icon', this.hibpStatus.loaderContainer, undefined, { 'src': XIcon });
        this.hibpStatus.loaderHolder = createElement('div', 'hide', this.hibpStatus.loaderContainer);
        this.hibpStatus.loader = createLoader(this.hibpStatus.loaderHolder, 24, 'login__pwd-help__hibp__loader');

        this.hibpStatus.text = createElement('span', 'login__pwd-help__text', this.hibpStatus);
        this.hibpStatus.textValue = createElement('span', '', this.hibpStatus.text, "Not compromised");
        this.hibpStatus.help = createElement('img', 'login__pwd-info__icon', hibpContainer, undefined, { 'src': help, 'title': haveIBeenPwnedExplanation });

        hibpContainer.onclick = () => {
            var dialog = new Dialog(document.body, {
                title: "Password Compromise Check",
                body: haveIBeenPwnedExplanation,
                titleColor: 'var(--color-inverseOnSurface)',
                titleBackground: 'var(--color-primary)',
                minWidth: 400
            })

            let linkParagraph = createElement('p', 'login__hibp__link___paragraph', dialog.bodyDiv);
            createElement('a', '', linkParagraph, 'Learn more at haveibeenpwned.com', { 'href': 'https://haveibeenpwned.com/Passwords', 'target': '_blank' });
        };

        this.hibpStatus.check = () => { return this.passwordSecurityChecker.hasNotBeenPwned };

        this.pwdHelp.checks = [helpChar, this.hibpStatus];

        this.forgotPasswordLink = createElement('a', 'login__forgot-password-link', actions, 'Forgot my password', { 'href': '#' });
        this.forgotPasswordLink.onclick = () => {
            this.processLoginStatus(LoginStatus.FORGOT_PASSWORD, "");
        };

        let reCAPTCHAdisclosure = createElement('p', 'login__recaptcha-disclosure', actions);
        createElement('span', undefined, reCAPTCHAdisclosure, 'This site is protected by reCAPTCHA and the ');
        createElement('a', undefined, reCAPTCHAdisclosure, 'Google Privacy Policy', { 'href': 'https://policies.google.com/privacy' });
        createElement('span', undefined, reCAPTCHAdisclosure, ' and ');
        createElement('a', undefined, reCAPTCHAdisclosure, 'Terms of Service', { 'href': 'https://policies.google.com/terms' });
        createElement('span', undefined, reCAPTCHAdisclosure, ' apply.');

        //this.classicLink            = createElement('a', 'login__classic-link',      actions, 'Go back to Classic Specific Energy', { 'href': 'https://specificenergy.com/Classic', 'target': '_blank' });
        this.twofalink = createElement('a', 'login__two-factor__link hide', actions, 'Need help?', { 'href': 'https://specificenergy.com/twoFactorSetup.html', 'target': '_blank' });

        this.pwdReset.hide();
        this.pwdDuplicate.hide();
        this.twofatoken.hide();
        this.copytwofa.onclick = () => navigator.clipboard.writeText(this.copytwofatext.value);

        this.pwdReset.oninput = () => {
            this.updateHIBPloaderIndication(1); // There's at least one pending
            this.passwordSecurityChecker.evaluatePassword(this.pwdReset.value).then(this.updateSecurityHelpMessages.bind(this));
        }

        // iOS touch does not work on login submit button, possible fixes
        //this.submit.addEventListener('touchstart', (e) => { this.onLoginSubmit(); e.preventDefault(); });
        this.submit.addEventListener('click', (e) => { this.onLoginSubmit(); });
        document.onkeyup = (e) => this.pressSubmit(e);

        if (process.env.TARGET == 'local') {
            this.user.value = localSettings.autologin[0];
            this.pwd.value = localSettings.autologin[1];
        }

        if (this.props.reset_token != null) {    // We got here via a reset password link, show them the password reset inputs
            this.processLoginStatus(LoginStatus.FORGOT_PASSWORD_RESET, "");
        }
    }

    updateHIBPloaderIndication(pendingAPIrequests) {
        if (pendingAPIrequests > 0) {  // API is waiting on stuff, show the loader
            this.hibpStatus.icon.classList.add('hide');
            this.hibpStatus.loaderHolder.classList.remove('hide');
        }
        else {  // Remove the loader, display the result

            // Let them know if the result is due to an inability to communicate with HIBP
            if(this.passwordSecurityChecker.communicationError) {
                this.hibpStatus.textValue.textContent = "Failed to communicate with password security API.";
                this.hibpStatus.classList.remove('login__acceptable');
                this.hibpStatus.icon.setAttribute('src', DisconnectIcon);
            }
            else
                this.hibpStatus.textValue.textContent = "Not compromised.";


            this.hibpStatus.loaderHolder.classList.add('hide');
            this.hibpStatus.icon.classList.remove('hide');
        }
    }

    updateSecurityHelpMessages() {
        for (let i = 0; i < this.pwdHelp.checks.length; i++) {
            let check = this.pwdHelp.checks[i].check();
            if (check) {
                this.pwdHelp.checks[i].classList.add('login__acceptable');
                this.pwdHelp.checks[i].icon.setAttribute('src', CheckIcon);
            }
            else {
                this.pwdHelp.checks[i].classList.remove('login__acceptable');
                this.pwdHelp.checks[i].icon.setAttribute('src', XIcon);
            }
        }

        this.updateHIBPloaderIndication(this.passwordSecurityChecker.pendingAPIrequests);

        if (this.passwordSecurityChecker.isSecure) {
            this.pwdReset.color = 'var(--color-primary)';
            this.pwdReset.prebar.style.background = this.pwdReset.postbar.style.background = 'var(--color-primary)';
            this.pwdReset.label.style.color = 'var(--color-primary)';
        }
        else {
            this.pwdReset.color = 'var(--color-error)';
            this.pwdReset.prebar.style.background = this.pwdReset.postbar.style.background = 'var(--color-error)';
            this.pwdReset.label.style.color = 'var(--color-error)';
        }
    }

    // handleLogin:
    // 1) receives result of owner.ldc.logIn().
    // 2) receives connection status change at any time.
    // Possible status values:
    //  - User.TWOFACTOR_VERIFY	user has entered a valid username/password and is prompted to enter a 6 digit 2fa code
    //  - User.TWOFACTOR_INIT	user has entered a valid username/password and has two factor enabled but requires setup
    //  - User.PASSWORD_RESET	user has entered a valid username/password but password has expired, reset
    //  - User.LOGGED_IN			user logged in and connection is ready to communicate
    //  - User.LOGGED_OUT		user logged out or server logged user out
    //  - User.INVALID_PASSWORD	invalid username/password pair
    //	- User.CONNECT_FAILED	failed to connect to server
    //  - User.CONNECTION_LOST	connection lost to server
    // LiveDataClient will not automatically reconnect, so the web page should tell the user what
    // happened, and prompt them to log in again.

    onConnectionStatusChanged(status, freshTwoFaKey, freshTwoFaCookie) {
        this.requiresPasswordChangeRedirect(status).then(requiresPasswordChangeRedirect => {   // Proceed with login flow after async check of password strength has completed
            if (requiresPasswordChangeRedirect)
                this.submitButtonFunction = this.SubmitFunction.CHANGE_PASSWORD;
            this.passwordChangeRedirect = requiresPasswordChangeRedirect && owner.ldc.fUsedPasswordLogin; // Keep track of this requirement so we can handle form submission behavior
            this.processLoginStatus(status, freshTwoFaKey);                                             // Do all the things for the given status
        });
    }

    setDefaultLoginFormState() {
        this.loader.hide();
        this.user.show();
        this.pwd.show();
        this.user.enable();
        this.pwd.enable();
        this.pwdReset.hide();
        this.pwdDuplicate.hide();
        this.submit.classList.remove('hide');
        this.pwdHelp.classList.add('hide');
        this.submit.value = "LOGIN";
        this.forgotPasswordLink.classList.remove('hide');
        this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox
        this.displaySubmittingMode(false);  // Stop showing the loader and stuff if we are
    }

    processLoginStatus(status, freshTwoFaKey) {
        switch (status) {
            case LoginStatus.LOGGED_IN:	// Success! Now change the styles and request the devices:
                if (this.passwordChangeRedirect && !this.hasBeenPromptedToUpdatePassword && (process.env.TARGET !== 'local')) {
                    // We're in the midst password change redirect and have not already been prompted to change it
                    this.displayWeakPasswordModal();
                    return;
                }
                //this.user.value = this.pwd.value = this.twofatoken.value = this.copytwofatext.value = this.pwdReset.value = this.pwdDuplicate.value = this.qrcode.src = '';// Clear sensitive info
                document.onkeyup = null;
                if (owner.fApp)	// If we're running in an ios app, tell the app we need to initialize two factor
                    webkit.messageHandlers.callback.postMessage({ message: 'loggedIn', remember: `${this.autoSwitch.state}`, username: this.user.value, password: this.pwd.value });
                owner.handleLogin(); // Let the owner change the get groups, devices, and change navigation
                break;

            case LoginStatus.TWOFACTOR_INIT:
                this.setDefaultLoginFormState();
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                this.forgotPasswordLink.classList.add('hide');

                if (owner.fApp)	// If we're running in an ios app, tell the app we need to initialize two factor
                    webkit.messageHandlers.callback.postMessage({ message: 'twoFactorInitialize' });

                if (this.pwdReset.value == this.pwdDuplicate.value && this.pwdDuplicate.value != null && this.pwdDuplicate.value != "") // User just finished resetting their password, hand the new one off for two-factor
                {
                    this.pwd.value = this.pwdReset.value;
                    this.pwdReset.value = this.pwdDuplicate.value = "";
                }
                this.user.hide();					    // Hide username input
                this.pwd.hide();						// Hide passsword input
                this.qrcode.classList.remove('hide');   // Display QR code
                this.twofatoken.show();			        // Display 2fa token input
                this.copytwofa.classList.remove('hide');	// Display 2fa copy option
                this.copytwofatext.classList.remove('hide')		// Display 2fa key
                this.twofalink.classList.remove('hide');	// Display help link
                //this.loginLabel.textContent = Localization.getLocalText("Two-Factor Setup:");

                var qrcodeSource = `otpauth://totp/Specific Energy:${this.user.value}?secret=${freshTwoFaKey}&issuer=Specific Energy`;

                const options = {
                    width: 300,
                    height: 300,
                    type: 'svg',
                    data: qrcodeSource,
                    margin: 10,
                    qrOptions: {
                        typeNumber: 0,
                        mode: 'Byte',
                        errorCorrectionLevel: 'Q'
                    },
                    imageOptions: {
                        hideBackgroundDots: true,
                        imageSize: 0.4,
                        margin: 20,
                        crossOrigin: 'anonymous',
                    },
                    dotsOptions: {
                        color: '#222222',
                        type: 'rounded'
                    },
                    backgroundOptions: {
                        color: '#FFFFFF',
                    },
                    cornersSquareOptions: {
                        color: '#222222',
                        type: 'extra-rounded'
                    },
                    cornersDotOptions: {
                        color: '#222222',
                        type: 'dot'
                    }
                };

                new QRCodeStyling(options).getRawData().then((pngData)=>{ this.qrcode.src = URL.createObjectURL(pngData); })

                this.copytwofatext.value = freshTwoFaKey; 			// Allow user to alternatively copy the key manually (most likely used when setting up on mobile)

                this.twofatoken.focus();			//put the user's cursor on the token input
                this.twofatoken.select();
                break;

            case LoginStatus.TWOFACTOR_VERIFY:
                this.setDefaultLoginFormState();
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                this.forgotPasswordLink.classList.add('hide');

                if (owner.fApp)	// If we're running in an ios app, tell the app we need to verify two factor
                    webkit.messageHandlers.callback.postMessage({ message: 'twoFactorVerify' });

                if (this.pwdReset.value == this.pwdDuplicate.value && this.pwdDuplicate.value != null && this.pwdDuplicate.value != "") // User just finished resetting their password, hand the new one off for two-factor
                {
                    this.pwd.value = this.pwdReset.value;
                    this.pwdReset.value = this.pwdDuplicate.value = "";
                }
                this.twofatoken.value = '';
                this.user.hide();					// Hide username input
                this.pwd.hide();						// Hide passsword input
                this.twofatoken.show();			// Display 2fa token input
                this.twofalink.classList.remove('hide');	// Display help link
                this.rememberDiv.classList.remove('hide');		// Display remember comp checkbox

                this.twofatoken.focus();							//put the user's cursor on the token input
                this.twofatoken.select();
                break;

            case LoginStatus.PASSWORD_RESET:
                this.setDefaultLoginFormState();
                this.forgotPasswordLink.classList.add('hide');
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                this.forgotPasswordLink.classList.add('hide');
                this.rememberDiv.classList.add('hide');		// Display remember comp checkbox

                if (owner.fApp)	// If we're running in an ios app, tell the app we need to reset password
                    webkit.messageHandlers.callback.postMessage({ message: 'passwordReset' });
                this.user.hide();					// Hide username input
                this.pwdHelp.classList.remove('hide');
                this.pwd.hide();						// Hide old password input
                this.pwdReset.show();
                this.pwdDuplicate.show();

                this.pwdReset.focus();								//put the user's cursor on first password input
                this.pwdReset.select();
                this.hasBeenPromptedToUpdatePassword = true; // Update the state for the login handler
                break;

            case LoginStatus.LOGGED_IN_WITH_WEAK_PASSWORD:
                this.setDefaultLoginFormState();
                // User is logged in but we need to hold them captive until they set a more secure password
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                this.forgotPasswordLink.classList.add('hide');

                // if (owner.fApp)	// If we're running in an ios app, tell the app we need to reset password   // Is this needed here???
                //     webkit.messageHandlers.callback.postMessage({message:'passwordReset'});

                this.submit.value = "CHANGE PASSWORD";
                this.user.hide();					        // Hide username input
                this.pwdHelp.classList.remove('hide');
                this.pwd.hide();						    // Hide old password input
                this.pwdReset.show();
                this.pwdDuplicate.show();

                this.qrcode.classList.add('hide');          // Hide QR code
                this.twofatoken.hide();			            // Hide 2fa token input
                this.copytwofa.classList.add('hide');	    // Hide 2fa copy option
                this.copytwofatext.classList.add('hide')	// Hide 2fa key
                this.twofalink.classList.add('hide');	    // Hide help link

                this.pwdReset.focus();						// put the user's cursor on first password input
                this.pwdReset.select();
                this.hasBeenPromptedToUpdatePassword = true; // Update the state for the login handler
                break;

            case LoginStatus.INVALID_PASSWORD: /*|| status == LoginStatus.LOGGED_OUT*/
                this.setDefaultLoginFormState();
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                if (owner.fApp)	// If we're running in an ios app, tell the app we failed to login
                    webkit.messageHandlers.callback.postMessage({ message: 'failed' });
                this.user.value = this.pwd.value = this.twofatoken.value = this.copytwofatext.value = this.pwdReset.value = this.pwdDuplicate.value = this.qrcode.src = '';	//if the password/username was entered incorrectly, clear sensitive info
                this.user.focus();
                this.twofatoken.hide();			            // Hide 2fa token input
                this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox
                this.remember.checked = false;				// Uncheck remember computer checkbox
                break;

            case LoginStatus.FORGOT_PASSWORD:
                this.setDefaultLoginFormState();
                this.submit.value = "REQUEST RESET";
                this.forgotPasswordLink.classList.add('hide');
                this.submitButtonFunction = this.SubmitFunction.FORGOT_PASSWORD;
                this.user.value = this.pwd.value = this.twofatoken.value = this.copytwofatext.value = this.pwdReset.value = this.pwdDuplicate.value = this.qrcode.src = '';	//if the password/username was entered incorrectly, clear sensitive info
                this.user.focus();
                this.twofatoken.hide();			            // Hide 2fa token input
                this.pwd.hide();                            // Hide password input
                this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox
                this.remember.checked = false;				// Uncheck remember computer checkbox
                break;

            case LoginStatus.FORGOT_PASSWORD_RESET:
                this.setDefaultLoginFormState();
                this.forgotPasswordLink.classList.add('hide');
                this.submit.value = "SET PASSWORD";
                this.submitButtonFunction = this.SubmitFunction.FORGOT_PASSWORD_RESET;
                this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox

                this.user.hide();					// Hide username input
                this.pwdHelp.classList.remove('hide');
                this.pwd.hide();						// Hide old password input
                this.pwdReset.show();
                this.pwdDuplicate.show();

                this.pwdReset.focus();								//put the user's cursor on first password input
                this.pwdReset.select();
                break;

            case LoginStatus.FORGOT_PASSWORD_RESET_SUCCESS:
                this.setDefaultLoginFormState();
                this.user.value = this.pwd.value = this.twofatoken.value = this.copytwofatext.value = this.pwdReset.value = this.pwdDuplicate.value = this.qrcode.src = '';	//if the password/username was entered incorrectly, clear sensitive info
                this.user.focus();
                this.twofatoken.hide();			            // Hide 2fa token input
                this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox
                this.remember.checked = false;				// Uncheck remember computer checkbox
                break;
            case LoginStatus.CONNECT_FAILED:
                this.setDefaultLoginFormState();
                this.status.textContent = Localization.getLocalText(LoginStatusText[status]);
                if (owner.fApp)	// If we're running in an ios app, tell the app we failed to login
                    webkit.messageHandlers.callback.postMessage({ message: 'failed' });
                this.user.value = this.pwd.value = this.twofatoken.value = this.copytwofatext.value = this.pwdReset.value = this.pwdDuplicate.value = this.qrcode.src = '';	//if the password/username was entered incorrectly, clear sensitive info
                this.user.focus();
                this.twofatoken.hide();			            // Hide 2fa token input
                this.rememberDiv.classList.add('hide');		// Hide remember comp checkbox
                this.remember.checked = false;				// Uncheck remember computer checkbox
                owner.ldc.user.passKeys.loginButton.getNewChallenge();
                break;
        }
    }

    async requiresPasswordChangeRedirect(status) {
        if (status == LoginStatus.LOGGED_IN && !this.hasBeenPromptedToUpdatePassword) {    // Logged in and have not been prompted to change a weak password already
            // Check their existing password for security and send them to password reset if it's weak
            await this.passwordSecurityChecker.evaluatePassword(this.pwd.value);

            return !this.passwordSecurityChecker.isSecure;
        }
        return false;
    }

    displayWeakPasswordModal() {
        var dialog = new Dialog(document.body, {
            title: "WEAK PASSWORD IDENTIFIED!",
            body: 'Your password failed a check against a database of known weak/compromised passwords. Set a new password now by pressing the button below and change your password for any other accounts that use this password.',
            titleColor: 'var(--color-inverseOnSurface)',
            titleBackground: 'var(--color-error)',
            minWidth: 400,
            fImportant: true,
            fButtons: false,
            buttons: [
                {
                    title: 'CHANGE PASSWORD NOW',
                    color: 'var(--color-primary)',
                    callback: () => {
                        this.hasBeenPromptedToUpdatePassword = true;    // Let the status handler know we don't need to prompt them again
                        this.processLoginStatus(LoginStatus.LOGGED_IN_WITH_WEAK_PASSWORD, this.copytwofatext.value);
                    }
                }
            ]
        })

        let unsafeLinkParagraph = createElement('p', 'login__hibp__unsafe__link', dialog.content);
        var unsafeLink = createElement('a', '', unsafeLinkParagraph, 'Unsafely continue with current password');
        unsafeLink.onclick = () => {
            this.hasBeenPromptedToUpdatePassword = true;
            this.passwordChangeRedirect = false;    // Don't trigger another password change redirect (they decided to unsafely continue)
            this.submitButtonFunction = this.SubmitFunction.LOGIN;
            this.processLoginStatus(LoginStatus.LOGGED_IN, this.copytwofatext.value);
        };
    }

    displayPasswordChangeErrorModal() {
        new Dialog(document.body, { title: 'Error', body: 'An error occurred while attempting to submit this change. Please try again.' })
    }

    pressSubmit(e) { if (e.keyCode === 13) { this.onLoginSubmit(); } } // Document listener for enter key use on the "Submit Button", removed once user is authenticated

    displaySubmittingMode(submitting = true) {
        if (submitting) {
            this.submit.classList.add('hide');
            this.pwd.blur();
            this.user.blur();
            this.pwd.disable();
            this.pwdReset.disable();
            this.user.disable();
            this.loader.show();
            this.status.textContent = 'Verifying...';
        }
        else {
            this.submit.classList.remove('hide');
            this.pwd.enable();
            this.pwdReset.enable();
            this.user.enable();
            this.loader.hide();
            this.status.textContent = '';
        }
    }

    inputsAreValid() {
        if (((this.user.value.length == 0) || (this.pwd.value.length == 0)) && this.twofatoken.hidden()) { //user is entering username and password
            this.status.textContent = Localization.getLocalText("Both a user name and password are required!");
            return false;
        } else if (((this.twofatoken.value > 1000000) || this.twofatoken.value.toString().length != 6 || isNaN(this.twofatoken.value)) && this.pwd.hidden() && this.pwdReset.hidden()) { // User is entering a 2 factor token
            this.status.textContent = Localization.getLocalText("Enter a valid 6 digit token");
            return false;
        }
        else if (!this.pwdReset.hidden()) {	// Changing the password
            if (this.pwdReset.value != this.pwdDuplicate.value) {
                this.status.textContent = Localization.getLocalText("Passwords do not match");
                return false;
            } else if (this.pwdReset.value == this.pwd.value) {
                this.status.textContent = Localization.getLocalText("Previous password cannot be used");
                return false;
            } else if (!this.passwordSecurityChecker.isSecure) {    // passwordSecurityChecker values should be up to date due to being called onInput
                this.status.textContent = Localization.getLocalText("Password must meet all security requirements");
                return false;
            }
        }
        return true;
    }

    displayForgotModal() {
        new Dialog(document.body, {
            title: "Reset request sent",
            body: "If the username you submitted exists, an email with further instructions will be sent to the address associated with the account.",
            minWidth: 400
        });
        this.setDefaultLoginFormState();    // Back to main login
        this.user.value = '';               // Clear the username
        document.onkeyup = (e) => this.pressSubmit(e); // Add the keyup listener back
    }

    attemptLogin(twoFactorCookie) {
        owner.ldc.logIn(new LoginMessage(this.user.value, this.pwd.value, this.twofatoken.value, this.copytwofatext.value, this.pwdReset.value, twoFactorCookie, this.remember.checked));
        this.displaySubmittingMode();
    }

    onLoginSubmit() {    // User submitted user/password via web form
        // PasskeyLoginButton.savePasskeyPreference(false);
        //this.loginbox.classList.add('hide'); // Hide the login box with the form in it.
        // Submit login request to LiveData client:
        switch (this.submitButtonFunction) {

            case this.SubmitFunction.LOGIN:
                if (!this.inputsAreValid())
                    return;
                owner.ldc.pathStorage.getItem("__twofa" + this.user.value.hashCode()).then((twoFactorCookie) => this.attemptLogin(twoFactorCookie));
                break;

            case this.SubmitFunction.CHANGE_PASSWORD:
                if (!this.inputsAreValid())
                    return;

                this.displaySubmittingMode();   // Disable inputs to ensure that password security checks are accurate through submission

                if (!this.passwordSecurityChecker.isSecure || this.passwordSecurityChecker.pendingAPIrequests > 0) { // If password is not secure or they hit enter while HIBP api requests are pending...don't submit
                    this.displaySubmittingMode(false);   // Stop submitting - reenable inputs
                    this.status.textContent = Localization.getLocalText("Password must meet all security requirements");
                    return;
                }

                //owner.ldc = new LiveDataClient(owner, owner.ldc);	            // Create a new client and migrate over to it
                owner.ldc.changePassword(this.pwd.value, this.pwdReset.value);  // Transmit the password change, success will

                this.passwordChangeRedirect = false;

                break;

            case this.SubmitFunction.FORGOT_PASSWORD:
                if (this.user.value.length == 0) { //user is entering username
                    this.status.textContent = Localization.getLocalText("Username is required");
                    return;
                }
                this.displaySubmittingMode();
                document.onkeyup = null;    // Disable keyup in the meantime
                owner.ldc.forgotPassword(this.user.value);
                this.submitButtonFunction = this.SubmitFunction.LOGIN;  // Back to the default function we go
                setTimeout(function () { this.displayForgotModal() }.bind(this), 1000);
                break;

            case this.SubmitFunction.FORGOT_PASSWORD_RESET:
                if (this.pwdReset.value != this.pwdDuplicate.value) {
                    this.status.textContent = Localization.getLocalText("Passwords do not match");
                    return;
                }

                this.displaySubmittingMode();   // Disable inputs to ensure that password security checks are accurate through submission

                if (!this.passwordSecurityChecker.isSecure || this.passwordSecurityChecker.pendingAPIrequests > 0) { // If password is not secure or they hit enter while HIBP api requests are pending...don't submit
                    this.displaySubmittingMode(false);   // Stop submitting - reenable inputs
                    this.status.textContent = Localization.getLocalText("Password must meet all security requirements");
                    return;
                }

                this.displaySubmittingMode();   // Can't type anymore after this

                owner.ldc.forgotPasswordReset(this.props.reset_token, this.pwdReset.value);
                this.submitButtonFunction = this.SubmitFunction.LOGIN;  // Back to the default function we go

                break;
        }
    }

    getCookie(cname, value) {
        var name = cname + value.hashCode();
        return window.localStorage.getItem(name);
    }

    setCookie(cname, cvalue, exdays) {
        cname += this.ldc.user.value.hashCode();
        window.localStorage.setItem(cname, cvalue);
    }

    copyTwoFactorKey(copytwofaText) {
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {				// The Apple way of selecting and copying text
            var oldContentEditable = copytwofaText.contentEditable,
                oldReadOnly = this.copytwofaText.readOnly,
                range = document.createRange();

            copytwofaText.contenteditable = true;
            copytwofaText.readonly = false;
            range.selectNodeContents(copytwofaText);

            var s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            copytwofaText.select();
            copytwofaText.setSelectionRange(0, 16); 					// Select all 16 chars of the key

            copytwofaText.contentEditable = oldContentEditable;
            copytwofaText.readOnly = oldReadOnly;
        } else															// The everyone else way of selecting and copying text
            copytwofaText.select();

        document.execCommand("copy");
    }

    destroy() {
        document.onkeyup = null;
        this.parent.removeChildren();
    }


};
