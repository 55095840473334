import { createElement } from "../elements";
import { RadioSelector, RadioButton } from './radio';
import './tabmanager.css';

export class TabManager {
    parent: HTMLElement;
    title: string;
    tabs: RadioButton[] = [];
    sectionMap: Map<string, HTMLElement> = new Map();
    radioSelector: RadioSelector;
    wrapper: HTMLElement;
    changeCallback: ((setting: string)=>void) | undefined;
    constructor(parent: HTMLElement, title: string, tabs: RadioButton[], selection?: string, changeCallback?: (setting: string)=>void) {
        this.parent = parent;
        this.title = title;
        this.changeCallback = changeCallback;
        this.wrapper            = createElement('div', 'tab-manager__wrapper', this.parent);
        let radioContainer      = createElement('div', 'radio__row', this.wrapper);
        let sectionContainer    = createElement('div', 'radio__sections', this.wrapper);
        for (let i=0;i<tabs.length;++i) {
            let section = createElement('div', 'radio__section ' + (i==0 ? '' : ' hide'), sectionContainer);
            this.sectionMap.set(tabs[i].name, section);
            this.tabs.push(
                {...tabs[i], selectCallback: 
                    (name: string) => {            
                        for (let j=0;j<tabs.length;++j) {
                            if (tabs[j].name == name) {
                                this.sectionMap.get(tabs[j].name)!.classList.remove('hide');
                            }
                            else {
                                this.sectionMap.get(tabs[j].name)!.classList.add('hide');
                            }
                        }
                        if (tabs[i].selectCallback != undefined)
                            tabs[i].selectCallback!(tabs[i].name)
                    }
                }
            )
        }
        this.radioSelector = new RadioSelector(radioContainer, {
            title: title,
            buttons: this.tabs, 
            defaultSelection: selection, 
            changeCallback: this.changeCallback
        });
    }

    selectDefault() {
        this.radioSelector.selectDefault();
    }

    getSectionByName(name: string): HTMLElement {
        return this.sectionMap.get(name)!;
    }

    setTabEnabled(name: string, fEnabled: boolean) {
        this.radioSelector.setButtonEnabled(name, fEnabled);
    }

    clearSections() {
        for (let section of this.sectionMap.values()) {
            section.removeChildren();
        }
    };

    showSection(name: string) {
        this.sectionMap.get(name)?.classList.remove('hide');
        this.radioSelector.setButtonVisibility(name, true);
        this.radioSelector.getButtonByName(name)?.click();
    }

    hideSection(name: string) {
        this.sectionMap.get(name)?.classList.add('hide');
        this.radioSelector.setButtonVisibility(name, false);
        this.radioSelector.options.buttons.every(button => {
            if (button.name != name) {
                this.radioSelector.getButtonByName(button.name)!.click();
                return false;
            }
            return true;
        })
    }

    update(setting: string) {
        for (let [name, section] of this.sectionMap) {
            if (name == setting)
                section.classList.remove('hide');
            else 
                section.classList.add('hide');
        }
        this.radioSelector.update(setting);
    }
}