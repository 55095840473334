import { createElement } from "../elements";
import { TagAttribute } from './lib/tag';
import { RegisterWidget, Widget } from "./lib/widget";
import type { TagDefinition } from "./lib/tag";
import { Role } from "../role";
import { Device } from "../device";
import owner from "../../owner";

import HomeIcon             from '../images/icons/home.svg';
import ChartIcon            from '../images/icons/chart.svg';
import BuildIcon            from '../images/icons/build.svg';
import PumpIcon             from '../images/icons/pump.svg';
import DashboardIcon        from '../images/icons/dashboard.svg';
import ReportIcon           from '../images/icons/report.svg';
import DollarIcon           from '../images/icons/dollar.svg';
import DeviceIcon           from '../images/icons/device.svg';
import OperatorIcon         from '../images/icons/operator.svg';
import SECIcon              from '../images/icons/SEC.svg';
import ListIcon             from '../images/icons/list.svg';
import DisconnectedIcon     from '../images/icons/disconnected.svg';

import defaultView          from '../views/defaultview';
import SpecificEnergyView   from '../views/specificenergyview';
import SimplifiedSpecificEnergyView from '../views/simplifiedsemapview';
import ChartView            from '../views/chartview_classic';
import Overview             from '../views/pumpstation';
import PumpCurveView        from '../views/pumpcurveview';
import CustomTabView        from '../views/customtabview';
import AssetManagementView  from '../views/assetmanagementview';
import ResultsView          from '../views/resultsview';
import IFSOverview          from '../views/ifsoverview';
import ReportView           from '../views/reportview';
import LiftStationOverview  from '../views/liftstationoverview';
import Dialog               from '../dialog';
import WellOverview         from '../views/welloverview';
import FormView             from '../views/formview';
import ConstraintView       from '../views/constraintview';
import EventFeedView        from '../views/eventfeedview';
import OvivoReport          from '../views/ovivoreport';
import AlarmLogView         from '../views/alarmlogview';
import ConfigTabViewClassic from '../views/configtabview_classic';
import MaintenanceView      from '../views/maintenanceview';
import { TagUnit }          from './lib/tagunits';
import TaggerView           from '../views/taggerview';
import { TabBar }           from './layout/tabbar/tabbar';
import View                 from "../views/view";
import { DeviceConfigurationView } from "../views/devicesettingsview";
import DashboardView from "../views/dashboardview";
import WidgetDashboardView from '../views/widgetdashboardview';

interface TabBarInfo {
    name: string;
    icon: string;
    content: View;
    container?: HTMLElement;
}

const template = /*html*/`
<style>
    :host {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
.device__container {
    container: device-page / inline-size;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#title-bar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.5em;
    padding-left: 10px;
    display: none;
}

.device__tab-bar {
    flex: 1;
    width: 100%;
}

.device-page__title-bar {
    height: 36px;
    font-size: 1.5em;
    padding: 5px;
}

.device__title-row {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.device__title-row__title {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.25em;
    padding-left: 10px;
}

.device__title-row__title__icon {
    height: 24px;
    width: auto;
}

.device__title-row__title__title {
    padding-left: 10px;
}

.device__disconnect-overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.device__disconnect-overlay__text {
    font-size: 2em;
    color: var(--color-onSurface);
    opacity: 0.3;
}

.device__disconnect-overlay__icon {
    height: 72px;
    width: 72px;
    opacity: 0.3;
}

@media(max-width: 620px) {
    .device__title-row {
        display: none;
    }
}

</style>
<div id="title-bar"></div>
<slot name="device"></slot>
`.trim();

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'device-widget', displayName: 'Device', template: template})
export class DeviceWidget extends Widget {
    device: Device;
    container: HTMLElement;
    graphID: number;
    disconnectDialog: Dialog;
    fileNames: string[];
    titleBar: HTMLElement;
    tabs: TabBarInfo[] = [];
    disconnectOverlay: HTMLElement;
    banner: HTMLElement;
    tabBar: TabBar;
    @TagAttribute({
        displayName: 'Root Tag',
    }) rootTag: TagDefinition;

    protected connectedCallback(): void {
        this.titleBar = this.shadowRoot?.getElementById('title-bar')!;
    }

    enliven() {
        this.container?.remove()
        this.container      = createElement('div', 'device__container', this, '', {'slot': 'device'});
        this.device         = this.rootTag.tag.device;
        this.graphID        = owner.ldc.registerGraph(this);

        owner.navBar.setTitle(`${this.device.siteName}${this.device.connected ? '' : ' (Disconnected)'}`);

        this.disconnectOverlay = createElement('div', 'device__disconnect-overlay', this);
        createElement('img', 'device__disconnect-overlay__icon', this.disconnectOverlay, undefined, {'src':DisconnectedIcon});
        createElement('div', 'device__disconnect-overlay__text', this.disconnectOverlay, 'Device Disconnected');
        if (this.device.connected) {
            for (let tab of this.tabs) {
                if (tab.content.fInitialized)
                    tab.content.destroy();
            }

            this.disconnectOverlay.classList.add('hide');
            this.device.requestNodeTree(() => this.onNodeTreeComplete(this.device));
        }
        else {
            this.disconnectOverlay.classList.remove('hide')
            if (this.device.cachedTree && !this.tabBar)
                this.device.requestNodeTree(() => this.onNodeTreeComplete(this.device));
        }
    }

    onTabChanged(tab: string) {
        //this.tabBar.selectTab(tab);
    }

    onNodeTreeComplete(device: Device) { // called by device onnodetreecomplete
        this.tabs = [];
        this.banner       = createElement('div', 'device__banner', this.container);
        let titleRow        = createElement('div', 'device__title-row', this.container);
        let titleContainer  = createElement('div', 'device__title-row__title', titleRow);
        createElement('device-status', '', titleContainer, '', {rootNode: this.rootTag});
        createElement('tag-name', '', titleContainer, '', {nameTag: this.rootTag});
        this.tabBar       = createElement('tab-bar', 'device__tab-bar', this.container);
        this.tabBar.onchange = () => {
            for (let tabInfo of this.tabs) {
                if (tabInfo.name == this.tabBar.value)
                if (!tabInfo.content.fInitialized)
                    tabInfo.content.initialize(tabInfo.container!);
                else
                    tabInfo.content.resize();
            }
        }
        let rootNode      = device.tree.nodes[0]!;
        let pumpSystem    = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);
        let flare         = rootNode.findChildByRole(Role.ROLE_FLARE_FOLDER);
        let IFSTag        = rootNode.findChildByRole(Role.ROLE_IFS_PUMP);
        let dpoFolder     = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
        let alternators   = rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
        if (alternators.length == 0)
		    alternators   = rootNode.findByRole(Role.ROLE_TOP_LEVEL);
        let hasConfig     = rootNode.findChildByRole(Role.ROLE_CONFIG_ERRORS);
        let fTagger       = rootNode.findChildByRole(Role.ROLE_VERSION);
        let assetMode     = dpoFolder ? dpoFolder.findChild('AssetManagementMode') : null;

        device.fAssetOnly	= assetMode && assetMode.getValue() && !owner.ldc.isPowerUser();

        this.fileNames		= device.customFiles;

        if(alternators.length > 1) {		// Simple Mustang Well
            this.tabs.push(
                {name: "Overview", 		icon: HomeIcon,		content: new WellOverview(this.device, owner.ldc)},
            );
        } else if (alternators.length == 1 && pumpSystem) {		// Mustang well with opt boosters
            alternators.push(pumpSystem);
            this.tabs.push(
                {name: "Overview", 		        icon: HomeIcon,		content: new WellOverview(this.device, owner.ldc)},
                {name: "Specific Energy Map",   icon: SECIcon,      content: new SpecificEnergyView(this.device, owner.ldc)},
                {name: "Pumps", 		        icon: PumpIcon,		content: new PumpCurveView(this.device)},
                {name: "Controls",              icon: BuildIcon,    content: new ConstraintView(this.device) },
                {name: "Asset Management",      icon: DollarIcon,	content: new AssetManagementView(this.device, owner.ldc)},
            );
        } else if (pumpSystem) { //It's got an uber controller
            let minBEP = pumpSystem.findByRole(Role.ROLE_PUMP_MIN_BEP_RATIO);
            let maxBEP = pumpSystem.findByRole(Role.ROLE_PUMP_MAX_BEP_RATIO);
            let minAOR = pumpSystem.findByRole(Role.ROLE_PUMP_MIN_AOR);
            let maxAOR = pumpSystem.findByRole(Role.ROLE_PUMP_MAX_AOR);
            if (minBEP)
                for (const ratio of minBEP)
                    ratio.units = TagUnit.TU_RATIO;
            if (maxBEP)
                for (const ratio of maxBEP)
                    ratio.units = TagUnit.TU_RATIO;
            if (minAOR)
                for (const ratio of minAOR)
                    ratio.units = TagUnit.TU_RATIO;
            if (maxAOR)
                for (const ratio of maxAOR)
                    ratio.units = TagUnit.TU_RATIO;

            var floatNode = pumpSystem.findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR);	// Display default view differently if it's a lift station
            this.tabs.push(
                {name: "Overview", 		    icon: HomeIcon,		content: floatNode ? new LiftStationOverview(this.device) : new Overview(this.device, owner.ldc)},
            );

            let maintenanceFolder = rootNode.findChildByRole(Role.ROLE_MAINTENANCE_FOLDER);
            if (maintenanceFolder) {
                let activeTask = maintenanceFolder.findChildByRole(Role.ROLE_MAINTENANCE_TASK_ACTIVE);
                if (activeTask)
                    createElement('lsg-maintenance-banner', '', this.banner, '', {statusTag: {tag:activeTask}})
                this.tabs.push(
                    {name: "Maintenance", 		icon: HomeIcon,		content: new MaintenanceView(this.device)},
                );
            }

            if (floatNode)
                this.tabs.push(
                    {name: "Form",          icon: BuildIcon,    content: new FormView(this.device, owner.ldc) }, //TODO: need configuration for lift stations
                    );
            if (!device.fAssetOnly && dpoFolder) {// No Solver tab in asset management mode
                if (!floatNode)
                    this.tabs.push({ name: "Operation", icon: OperatorIcon, content: new SimplifiedSpecificEnergyView(this.device, owner.ldc) });
                this.tabs.push(
                    { name: "Specific Energy Map",   icon: SECIcon,      content: new SpecificEnergyView(this.device, owner.ldc) },
                    { name: "Controls",              icon: BuildIcon,    content: new ConstraintView(this.device) },
                    { name: "Results",               icon: ReportIcon,   content: new ResultsView(this.device, owner.ldc) },
                );
            }
            this.tabs.push({name: "Pumps", 	            icon: PumpIcon,		content: new PumpCurveView(this.device)})
            this.tabs.push({name: "Asset Management",   icon: DollarIcon,	content: new AssetManagementView(this.device, owner.ldc)});

        } else if (alternators.length == 1) {
            if(alternators[0].findChildByRole(Role.ROLE_LOW_FLOAT_INDICATOR)) { //It must be a lift station.
                this.tabs.push(
                    {name: "Overview", 		icon: HomeIcon,	    content: new LiftStationOverview(this.device)},
                    {name: "Configuration", icon: BuildIcon,    content: new FormView(this.device, owner.ldc) }, //TODO: need configuration for lift stations
                );
            } else {	// We assume this is a well
                this.tabs.push(
                    {name: "Overview", icon: HomeIcon, content: new WellOverview(this.device, owner.ldc)},
                );
            }

        } else if (flare) {
            this.tabs.push(
                {name: "Overview", icon: HomeIcon, content: new defaultView()},
            );
        }
        else if (IFSTag) {
            this.tabs.push({name: "Overview", icon: HomeIcon, content: new IFSOverview(this.device, owner.ldc)});
        }
        else if (fTagger)
            this.tabs.push({name: 'Overview', icon: HomeIcon, content: new TaggerView(this.device.tree.nodes[0]!)});

        if (device.customFiles.length > 0) {	// It has at least one custom file
            if (device.key == 'US.TX.MUSTANGSUD.SANDBROCK.WWTP' || device.key == 'US.OVIVO.OH.CANTON')
                this.tabs.push({name: "Dashboards", icon: DashboardIcon, content: new CustomTabView(this.device, owner.ldc)});
            else
                for (let fileName of device.customFiles) {
                    this.tabs.push({name: fileName, icon: DashboardIcon, content: new CustomTabView(this.device, owner.ldc, fileName)});
                }
        }
        if (device.key.match("US.OVIVO.OH.CANTON")) {				// Can we make this less ugly?
            this.tabs.push({name: "Analysis", icon: DashboardIcon, content: new OvivoReport(this.device, owner.ldc)});
        }

        this.tabs.splice(4,0,{name: "Chart", icon: ChartIcon,    content: new ChartView(this.device, owner.ldc)});
        this.tabs.push({name: "Alarm Settings",  icon: ListIcon,     content: new EventFeedView(this.device, owner.ldc)});
        this.tabs.push({name: "Alarm Log", icon: ListIcon, content: new AlarmLogView(this.device, owner.ldc)});
        //this.tabs.push({name: "Tag Finder", icon: DashboardIcon, content: new TagFinderView(owner.ldc, this.device)});
        this.tabs.push({name: "Reports",     icon: ReportIcon,	content: new ReportView(this.device, owner.ldc)});

        if (hasConfig && owner.ldc.user.fTagConfig) {
            if (fTagger)
                this.tabs.push({name: 'Settings', icon: HomeIcon, content: new DeviceConfigurationView(owner.ldc, this.device.key)});
            else {
                this.tabs.push({name: "Tag Config", icon: DashboardIcon, content: new ConfigTabViewClassic(this.device)});
            }
        }

        for (let [name, details] of this.device.dashboards) {
            this.tabs.unshift({name: name,     icon: DashboardIcon,	content: details.version > 3 ? new WidgetDashboardView(owner.ldc, details.id) : new DashboardView(owner.ldc, details.id)});
        }

        this.tabs.forEach(tab => {
            this.createTab(tab);
        });

        this.tabBar.value = this.tabs[0].name;
    };

    createTab(tabInfo: TabBarInfo) {
        let container = createElement('div', 'full__width full__height', this.tabBar, '', );
        container.setAttribute('name', tabInfo.name);
        container.setAttribute('icon-src', tabInfo.icon)
        tabInfo.container = container;
    }

    protected onResize(): void {
        requestAnimationFrame(()=> {
            this.tabs.forEach(tab => {
                if (tab.name == this.tabBar.value) {
                    tab.content.resize();
                    return;
                }
            })
        })
    }

    protected onDisconnect(): void {
        for (let tab of this.tabs) {
            if (tab.content.fInitialized)
                tab.content.destroy();
        }
    }
}