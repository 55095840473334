import owner from "../../owner";
import assert from "../debug";
import { createElement } from "../elements";
import './avatar.css';

export default function avatar(parent: HTMLElement, first: string, last: string, image?: string, color?: string) {
    assert(parent);
    let container = createElement('div', 'avatar__container', parent);
    if (image) {
        createElement('img', 'avatar__image', container, undefined, {'src':image});
    }
    else {
        let firstName = '';
        let lastName = '';
        if (first && first.length > 0)
            firstName   = first[0].toUpperCase();
        if (last && last.length > 0)
            lastName    = last[0].toUpperCase();
        container.style.backgroundColor = color ? color : 'var(--color-gray-6)';
        container.textContent = firstName + lastName;
        //container.onclick = () => owner.wrapper.setAttribute("classic", owner.wrapper.getAttribute("classic") == "true" ? "false" : "true")
    }
}
