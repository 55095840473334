import editorpage           from '../../pages/editorpage';
import { StyleCategories }  from '../../views/dashboardstyleview';
import { Gizmo }            from './gizmo';
import owner, { Routes }    from '../../../owner';
import { createElement }    from '../../elements';
import assert               from '../../debug';
import FrameParser          from '../../frameparser';
import { getHash } from '../../router/router';

export class LinkGizmo extends Gizmo {
    linkSelect: HTMLSelectElement;
    typeSelect: HTMLSelectElement;
    graphID: number;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.POSITION, StyleCategories.DIMENSIONS, StyleCategories.TYPOGRAPHY, StyleCategories.LAYOUT, StyleCategories.SPACING]
        this.graphID            = owner.ldc.registerGraph(this);
        this.wrapper = createElement('div', '', this, '', {tabIndex:0});
        this.buildLink();
    }

    populateSettings(editor: editorpage): void {
        super.populateSettings(editor);
        this.createSettingTextInput('text', 'Text: ');
        let parent = editor.toolTabs.getSectionByName('Settings')
        let typeWrapper = createElement('div', 'gizmo__setting__row', parent);
        createElement('div', undefined, typeWrapper, 'Link Type: ');
        this.typeSelect = createElement('select', 'editor__container__settings__select', typeWrapper);		// Give the user a drop down selector to change what type of link they want
        this.typeSelect.onchange = () => {
            if (this.recipe.settings['type'] != this.typeSelect.selectedOptions[0].value)
                delete this.recipe.settings['id']
            this.onTypeSelectorChange();
        }

        createElement('option', 'link-view__option', this.typeSelect, 'Dashboard', {value: '0'});	// Put an option in the selector
        createElement('option', 'link-view__option', this.typeSelect, 'Device', {value: '1'});	// Put an option in the selector
        this.typeSelect.value = this.recipe.settings['type'] ?? '0';
        let linkWrapper = createElement('div', 'gizmo__setting__row', parent);
        createElement('div', undefined, linkWrapper, 'Link Page: ');
        this.linkSelect = createElement('select', 'editor__container__settings__select', linkWrapper);		// Give the user a drop down selector to change companies
        this.linkSelect.onchange = () => {
            this.recipe.settings['id'] = this.linkSelect.selectedOptions[0].value
            this.rebuild();
        }
        this.onTypeSelectorChange();
    }

    onTypeSelectorChange() {
        this.linkSelect.removeChildren();
        this.recipe.settings['type'] = this.typeSelect.selectedOptions[0].value;
		switch(this.typeSelect.selectedOptions[0].text) {
            case 'Dashboard':
                owner.ldc.getDashboards(this.graphID, owner.menuPanel.getCompanyKey());
            break;
            case 'Device':
                for (let i=0;i<owner.sortedDevices.length;i++) {
                    let device  = owner.sortedDevices[i];
                    createElement('option', 'link-view__option', this.linkSelect, device.siteName, {value: device.key});	// Put an option in the selector
                }
                this.linkSelect.value = this.recipe.settings['id'] ?? this.linkSelect.options[0].value;
                this.recipe.settings['id'] = this.linkSelect.value;
            break;
            default:
                assert(false, 'Type Select returned a bad link type');
        }
    }



    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '96px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '36px');
        }
    }

    onDashboardsResponse(fp: FrameParser) {
        let count = fp.pop_u32();
        for (let i=0;i<count;i++) {
            let id          = fp.pop_u32();
            let name        = fp.pop_string();
            let version     = fp.pop_u16();
            let creator     = fp.pop_string();
            let deviceCount = fp.pop_u32();
            for (let j=0;j<deviceCount;++j) {
                let key     = fp.pop_string();
                let device  = owner.ldc.devices.getByKey(key);
                if (device)
                    device.dashboards.set(name, {id: id, version: version});
            }
            let link    = createElement('option', 'link-view__option', this.linkSelect, name);	// Put an option in the selector
            link.value  = id.toString();
        }
        this.linkSelect.value = this.recipe.settings['id'] ?? this.linkSelect.options[0].value;
        this.recipe.settings['id'] = this.linkSelect.value;
    }

    buildLink() {
        this.wrapper.textContent    = this.recipe.settings['text'] ?? '';
        this.onclick        =  () => {
            let href = '';
            if ('type' in this.recipe.settings) {
                if (this.recipe.settings['type'] == '0')
                    href = getHash(Routes.Viewer, {'id':this.recipe.settings['id']});
                else
                    href = getHash(Routes.Device, {'key':this.recipe.settings['id']});
            }
            else if ('href' in this.recipe.settings)
                href   = this.recipe.settings['href'];
            location.hash = href;
        }
    }

    rebuild() {
        this.buildLink();
    }
}