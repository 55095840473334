import {createElement} from '../elements';
import assert from '../debug';
import MoreIcon from '../images/icons/more.svg';
import ExtraTabView from '../views/extratabview';
import './tabbar.css';
import owner from '../../owner';
import View from '../views/view';
import AlarmLogView from '../views/alarmlogview';
import { getHash, getRouteAndProperties } from '../router/router';

export interface TabBarInfo {
    name: string;
    icon: string;
    content: View;
}

export default class TabBar {
    parent: HTMLElement;
    tabs: Map<string, HTMLDivElement> = new Map();
    views: Map<string, View> = new Map();
    tabList: TabBarInfo[] = [];
    currentView: View | null = null;
    tabBar: HTMLElement;
    content: HTMLElement;
    tabContainer: HTMLDivElement;
    constructor(parent: HTMLElement) {
        assert(parent, 'a parent element is required for tab bar');
        this.parent         = parent;       // the element to build our tab bar up inside

        var container       = createElement('div', 'tab-bar__container',   this.parent);
        this.tabBar         = createElement('div', 'tab-bar__bar',         container);
        this.content        = createElement('div', 'tab-bar__content',     container);
        this.tabContainer   = createElement('div', 'tab-bar__bar__container', this.tabBar)
    }

    addTab(view: View) {

    }

    addTabs(tabs: TabBarInfo[], fPrepend: boolean = false) {
        if (fPrepend)
            for (let tab of tabs)
                this.tabList.unshift(tab);
        else
            this.tabList.push(...tabs);
        for (let i=0; i<tabs.length; ++i) {
            if (this.tabs.size==4) { // make sure the fifth tab is always the "more" tab
                const moreName = "More";
                let moreTab: TabBarInfo = {name: moreName, icon: MoreIcon, content: new ExtraTabView()};    // more tab shows links to additional tabs
                let tab = this.createTab(moreTab, this.tabContainer);                   //
                tab.classList.add('tab-bar__tab__more');
                tab.onclick = () => {
                    location.href    = getHash(...getRouteAndProperties(location.href, {'tab':moreName,'index':'0'}));
                };
                this.tabs.set(moreName, tab)
                this.views.set(moreName, moreTab.content);
            }
            let tab         = this.createTab(tabs[i], this.tabContainer, fPrepend);
            if (this.tabs.size>4) tab.classList.add('tab-bar__tab__extra');
            tab.onclick = () => {
                location.href    = getHash(...getRouteAndProperties(location.href, {'tab': tabs[i].name, 'index': "0"}));
            };
            this.tabs.set(tabs[i].name, tab);
            this.views.set(tabs[i].name,tabs[i].content);
        };
    };

    createTab(tab: TabBarInfo, parent: HTMLDivElement, fPrepend: boolean = false): HTMLDivElement {
        let newTab      = createElement('div', 'tab-bar__tab');
        if (fPrepend)
            parent.prepend(newTab);
        else
            parent.append(newTab);
        let container   = createElement('div', 'tab-bar__tab__container', newTab);
        createElement('div', 'tab-bar__tab__selector', newTab);
        createElement('img', 'tab-bar__tab__icon', container, '', {'src':tab.icon});
        createElement('div', 'tab-bar__tab__name', container, tab.name);

        return newTab;
    }

    selectTab(tabName: string) {
        if (this.currentView && !this.currentView.fInitialized) return;
        if (tabName == 'default' || !tabName || !this.tabs.get(tabName)) {
            tabName = this.tabList[0].name;
            owner.router.navigate(getHash(...getRouteAndProperties(location.href, {'tab': tabName})));
        }
        if (this.views[tabName] === this.currentView) return
        for (let [tabName, tab] of this.tabs) { // unselect all tabs
            tab.classList.remove('selected')
        };
        for (let [name, view] of this.views) { // unselect all tabs
            if (view.fInitialized && view.fShown) {
                view.wrapper.classList.add('hide');
                view.fShown = false;
                view.onViewHidden();
            }
        }
        this.tabs.get(tabName)?.classList.add('selected');

        this.currentView = this.views.get(tabName)!;
        if (this.currentView && this.currentView.fInitialized) {
            this.currentView.wrapper.classList.remove('hide');
            this.currentView.onViewShown();
        }
        else if (this.currentView)
            this.currentView.initialize(this.content);
        this.currentView.fShown = true;
    }

    destroy() {
        for (let [name, view] of this.views) {
            if (view.fInitialized) {
                view.destroy();
            }
        }
    }
}