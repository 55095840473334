import { Attribute, ColorAttribute } from "../../lib/attributes";
import { TagAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './colorstatus.html'

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'color-status', displayName: 'Color Status', template: template})
export class ColorStatus extends Widget {
    @Attribute({displayName: 'On Color', typeModifier: 'color'}) onColor: ColorAttribute = new ColorAttribute('#00FF00');
    @Attribute({displayName: 'Off Color', typeModifier: 'color'}) offColor: ColorAttribute = new ColorAttribute('#FF0000');

    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric', 'boolean'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    update(tag: Tag): void {
        if (tag !== this.valueTag.tag)
            throw(new Error('Invalid subscribed tag'));
        if (tag.getValue())
            this.style.backgroundColor = this.onColor.color;
        else
            this.style.backgroundColor = this.offColor.color;
    }
}
