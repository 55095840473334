import './library/styles/main.css';
import './library/styles/button.css';
import * as Sentry from "@sentry/browser";
import { Integration } from '@sentry/types';
import { BrowserTracing } from "@sentry/tracing";

import owner 				from './owner'

window.onload = function () {
	owner.initialize();
	//@ts-ignore
	window.owner = owner;
	if (process.env.ERROR_TRACKING === 'true') {
		Sentry.init({
			beforeSend(event, hint) { // Have to catch an old recaptchaV3 bug here so we don't get bombarded with issues from google's bug
				if (hint.originalException === "Timeout") return null;
				return event;
			},
			dsn: "https://af606cd473764730a9edf9074144c8ad@o1261089.ingest.sentry.io/6438098",
			//@ts-ignore
			integrations: [new BrowserTracing() as Integration],
			release: `fortnite@${process.env.VERSION}`,
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
	}

	console.log('%cStop!', "Color: red; font-size: 20px;");
	console.log(`This is a browser feature intended for developers. Specific Energy employees will never direct you to enter anything into the browser's console.`)

	// helper viewheight and viewwidth css variables
	let vh = window.innerHeight * 0.01;
	let vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	document.documentElement.style.setProperty('--vw', `${vw}px`);

	window.addEventListener('resize', () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		let vw = window.innerWidth * 0.01;
		document.documentElement.style.setProperty('--vw', `${vw}px`);
	});
}
