import assert from './debug'
import { RadioButton, TagRadioButton } from './widgets/input/radio/radiobutton';
import { TagName } from './widgets/taginfo/tagname/tagname';
import { Widget } from './widgets/lib/widget';
import { LineGraph } from './widgets/linegraph';
import { DigitalGauge } from './widgets/digitalgauge';
import { RootElement } from './widgets/hmiroot';
import { TagBadge } from './widgets/taginfo/tagbadge/tagbadge';
import { PushButton, TagPushButton } from './widgets/pushbutton';
import { Container } from './widgets/layout/container/container';
import { TagDescription } from './widgets/taginfo/tagdescription/tagdescription';
import { TagToggleSwitch, ToggleSwitch } from './widgets/input/toggle/toggleswitch';
import { LinearGauge, TagLinearGauge } from './widgets/status/lineargauge/lineargauge';
import { Tank } from './widgets/tank';
import { RadialGauge } from './widgets/radialgauge';
import { LineChart } from './widgets/linechart';
import { Checkbox, TagCheckbox } from './widgets/input/checkbox/checkbox';
import { ColorStatus } from './widgets/status/colorstatus/colorstatus';
import PumpSystemGraph from './widgets/pumpsystemgraph';
import { TabBar } from './widgets/layout/tabbar/tabbar';
import { DeviceWidget } from './widgets/device';
import { InputSetpoint } from './widgets/input/setpoint/inputsetpoint';
import { DateTimeSetpoint } from './widgets/input/datetimesetpoint';
import { SelectWidget, TagSelect } from './widgets/input/select/select';
import { WeeklySchedule } from './widgets/weeklyschedule';
import { FloatTimeout } from './widgets/lsg/floattimeout/floattimeout';
import { DeviceStatus } from './widgets/status/devicestatusicon/devicestatus';
import { ControlTicker } from './widgets/dpo/controls/ticker/ticker';
import { RangeSlider } from './widgets/input/rangeslider/rangeslider';
import { Accordion } from './widgets/layout/accordion/accordion';
import { PumpAuto } from './widgets/dpo/pumps/pumpauto/pumpauto';
import { PumpCard } from './widgets/dpo/pumps/pumpcard/pumpcard';
import { Repeater } from './widgets/layout/repeater/repeater';
import { PumpBank } from './widgets/dpo/pumps/pumpbank/pumpbank';
import { PumpBadge } from './widgets/dpo/pumps/pumpbadge/pumpbadge';
import { Carousel } from './widgets/layout/carousel/carousel';
import { Modal } from './widgets/layout/modal/modal';
import { PumpTest } from './widgets/dpo/pumps/pumptest/pumptest';
import { PumpCurve } from './widgets/dpo/pumps/pumpcurve/pumpcurve';
import { TagUnits } from './widgets/taginfo/tagunits/tagunits';
import { TagConfiguration } from './widgets/taginfo/tagconfiguration/tagconfiguratoin';
import { DPOOverview } from './widgets/dpo/overview/overview';
import { ResponsiveWidget } from './widgets/responsive/responsive';
import { Slider, TagSlider } from './widgets/input/slider/slider';
import { FolderMultiSelect, MultiSelect } from './widgets/input/multiselect/multiselect';
import { TagPath } from './widgets/taginfo/tagpath/tagpath';
import { LSGMaintenanceBanner } from './widgets/status/banner/banner';
import { PumpSelect } from './widgets/dpo/pumps/pumpselector/pumpselector';
import { PumpWearCost } from './widgets/dpo/pumps/pumpwearcost/pumpwearcost';
import { Loader } from './widgets/basic/loader';
// Array methods:

// Binary search for a sorted array.
// Return index of item, or -1 if not found.
// If you want something other than direct element comparison, then provide a comparator method.
// User-supplied comparator method that takes two elements and returns negative, 0 or positive if e1<e2, e1=e2, or e1>e2.
// Taken from wikipedia binary search, iterative method:
declare global {
	interface Array<T> {
		bsearch(element: any, comparator?: (a: any, b: any)=>number);
		binsert(element: any, comparator?: (a: any, b: any)=>number);
		comparator(a: any, b: any);
		front();
		back();
		evaluatePolynomial(x: number);
		evaluateFirstDerivative(x: number);
		solvePolynomial(xMin: number, xMax: number, y: number, tolerance: number);

	}
}

Array.prototype.bsearch = function(element, comparator) {
	var min = 0;
	var max = this.length - 1;

	// If comparator method is passed in, use it.
	// Else if element has a comparator method, use it.
	// Else use the default (polymorphic) array comparator method.

	comparator = comparator || (element.comparator ? element.comparator : this.comparator);
	if (comparator)
		while (min <= max) {
			var mid		= Math.floor((min + max) / 2);
			var result	= comparator(this[mid], element);

			if (result < 0)
				min = mid + 1;
			else if (result > 0)
				max = mid - 1;
			else // match:
				return mid;
		}
	return -1;	// no match
};

// Insert element into sorted array
// Uses a binary search algorithm for Olog(n) performance.
// Does not guarantee uniqueness, and matching elements are not sorted w/r to each other.
// Returns index where item was inserted.
// If you want something other than direct element comparison, then provide a comparator method.
// User-supplied comparator method that takes two elements and returns negative, 0 or positive if e1<e2, e1=e2, or e1>e2.
// Taken from wikipedia binary search, iterative method:
Array.prototype.binsert = function(element, comparator) {
	var min = 0;
	var max = this.length;

	comparator = comparator || (element.comparator ? element.comparator : this.comparator);
	if (comparator)
		while (min < max) {
			var mid		= Math.floor((min + max) / 2);
			var result	= comparator(this[mid], element);

			if (result < 0)
				min = mid + 1;
			else if (result > 0)
				max = mid;
			else {
				min = mid;
				break;
			}
		}
	// insert at 'min':
	this.splice(min, 0, element);	// Ouch. This is expensive, but not sure how to have a cheap, ordered set in Javascript. Object properties have no order.

	return min;
};

// Default sorted array comparator, to compare two elements:
Array.prototype.comparator = function(el1, el2) {	// Written to be polymorphic:
	if (el1 < el2)
		return -1;
	else if (el1 > el2)
		return 1;
	else
		return 0;
};

Array.prototype.front = function() {
	return this[0];
};

Array.prototype.back = function() {
	return this[this.length - 1];
};

Array.prototype.evaluatePolynomial = function(x) {	// Quick way to evalute a polynomial in an array
	var poly = this;
	var answer = poly[poly.length - 1];
	for(var i = poly.length - 2; i >= 0; --i)
		answer = poly[i] + x * answer;
	return answer;
};

Array.prototype.evaluateFirstDerivative = function(x) {
	// Horner's method for polynomial evaluation, modified to return first derivative:
	let poly     = this;
	let exponent = poly.length - 1;            // exponent value of highest order coefficient
	let answer   = poly[exponent] * exponent;  // start with highest order coefficient
	for(--exponent; exponent >= 1; --exponent)
		answer = (poly[exponent] * exponent) + (x * answer);
	return answer;
};

Array.prototype.solvePolynomial = function(xmin, xmax, y, tolerance) {	// Quick way to solve a polynomial in an array
	if (isNaN(y))
		return undefined;
	var minError = y - this.evaluatePolynomial(xmin);	// Start off by finding the min and max errors for our guesses
	var maxError = y - this.evaluatePolynomial(xmax);

	var i = 0;
	while (++i < 200) {	// This while loop is a false position (regula falsi) root finding algorithm. We will return when the error < tolerance
		// Compute our new guess. xnew is the point where the line from (xmin, minError) to (xmax, maxError) intersects the x axis.
		var xnew		= (minError * xmax - maxError * xmin)/ (minError - maxError);
		var newError	=  y - this.evaluatePolynomial(xnew);

		if (Math.abs(newError) < tolerance)     // Have to get newError down low. If low, leave the loop
			return xmin <= xnew && xnew <= xmax ? xnew : undefined;

		if ((newError < 0) == (maxError < 0)) {	// Check if sign of newError is equal to the sign of highError. If so, it's the new high side
			xmax		= xnew;		// Store the new guess
			maxError	= newError;	// Store the new error
		} else {	// If not, it's our low result
			xmin		= xnew;		// Store the new guess
			minError	= newError;	// Store the new error
		}
	}
	return undefined;
};

declare global {
	interface String {
		hashCode() : number
	}
}

String.prototype.hashCode = function(): number {	// Used for creating hashed username and token combo that gets stored in local storage - "remember this device for 30 days?"
	var hash = 0, i, chr;
	if (this.length === 0)
		return hash;
	for (i = 0; i < this.length; i++)
	{
		chr   = this.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
};

// Create a bind method for function if there isn't one
Function.prototype.bind = Object.bind || function bind() {
    var fn = this,
    args = [].slice.call( arguments ),
    object = args.shift();
    return function() {
        return fn.apply(object, args.concat( [].slice.call(arguments) ) );
    };
};

export interface ExtendedTagNameMap extends HTMLElementTagNameMap {
	'base-widget': Widget;
	'radio-button': RadioButton;
	'tag-name': TagName;
	'line-graph': LineGraph;
	'hmi-root': RootElement;
	'lsg-maintenance-banner': LSGMaintenanceBanner;
	'tag-badge': TagBadge;
	'digital-gauge': DigitalGauge;
	'push-button': PushButton;
	'hmi-container': Container;
	'tag-push-button': TagPushButton;
	'tag-description': TagDescription;
	'se-tag-toggle-switch': TagToggleSwitch;
	'linear-gauge': LinearGauge;
	'tag-tank': Tank;
	'radial-gauge': RadialGauge;
	'line-chart': LineChart;
	'se-checkbox': Checkbox;
	'se-tag-checkbox': TagCheckbox;
	'color-status': ColorStatus;
	'pump-system-graph': PumpSystemGraph;
	'tab-bar': TabBar;
	'device-widget': DeviceWidget;
	'input-setpoint': InputSetpoint;
	'date-time-setpoint': DateTimeSetpoint;
	'select-widget': SelectWidget;
	'weekly-schedule': WeeklySchedule;
	'float-timeout': FloatTimeout;
	'device-status': DeviceStatus;
	'control-ticker': ControlTicker;
	'range-slider': RangeSlider;
	'tag-select': TagSelect;
	'se-accordion': Accordion;
	'pump-auto': PumpAuto;
	'pump-card': PumpCard;
	'se-repeater': Repeater;
	'pump-bank': PumpBank;
	'pump-badge': PumpBadge;
	'hmi-carousel': Carousel;
	'hmi-modal': Modal;
	'pump-test': PumpTest;
	'pump-curve': PumpCurve;
	'tag-units': TagUnits;
	'tag-linear-gauge': TagLinearGauge;
	'tag-config': TagConfiguration;
	'dpo-overview': DPOOverview;
	'responsive-widget': ResponsiveWidget;
	'se-slider': Slider;
	'se-tag-slider': TagSlider;
	'tag-radio-button': TagRadioButton;
	'multi-select': MultiSelect;
	'folder-multi-select': FolderMultiSelect;
	'tag-path': TagPath;
	'pump-select': PumpSelect;
	'pump-wear-cost': PumpWearCost;
	'toggle-switch': ToggleSwitch;
	'widget-loader': Loader;
}

export const WidgetMap = new Map([
	['base-widget', Widget],
	['radio-button', RadioButton],
	['tag-name', TagName],
	['line-graph', LineGraph],
	['hmi-root', RootElement],
	['lsg-maintenance-banner', LSGMaintenanceBanner],
	['tag-badge', TagBadge],
	['digital-gauge', DigitalGauge],
	['push-button', PushButton],
	['hmi-container', Container],
	['tag-push-button', TagPushButton],
	['tag-description', TagDescription],
	['se-tag-toggle-switch', TagToggleSwitch],
	['linear-gauge', LinearGauge],
	['tag-tank', Tank],
	['radial-gauge', RadialGauge],
	['line-chart', LineChart],
	['se-checkbox', Checkbox],
	['se-tag-checkbox', TagCheckbox],
	['color-status', ColorStatus],
	['pump-system-graph', PumpSystemGraph],
	['tab-bar', TabBar],
	['device-widget', DeviceWidget],
	['input-setpoint', InputSetpoint],
	['date-time-setpoint', DateTimeSetpoint],
	['select-widget', SelectWidget],
	['weekly-schedule', WeeklySchedule],
	['float-timeout', FloatTimeout],
	['device-status', DeviceStatus],
	['control-ticker', ControlTicker],
	['range-slider', RangeSlider],
	['tag-select', TagSelect],
	['se-accordion', Accordion],
	['pump-auto', PumpAuto],
	['pump-card', PumpCard],
	['se-repeater', Repeater],
	['pump-bank', PumpBank],
	['pump-badge', PumpBadge],
	['hmi-carousel', Carousel],
	['hmi-modal', Modal],
	['pump-test', PumpTest],
	['pump-curve', PumpCurve],
	['tag-units', TagUnits],
	['tag-linear-gauge', TagLinearGauge],
	['tag-config', TagConfiguration],
	['dpo-overview', DPOOverview],
	['responsive-widget', ResponsiveWidget],
	['se-slider', Slider],
	['se-tag-slider', TagSlider],
	['tag-radio-button', TagRadioButton],
	['folder-multi-select', FolderMultiSelect],
	['multi-select', MultiSelect],
	['tag-path', TagPath],
	['pump-select', PumpSelect],
	['pump-wear-cost', PumpWearCost],
	['toggle-switch', ToggleSwitch],
	['widget-loader', Loader]
]);

type CreatedElement<T extends string> = T extends keyof ExtendedTagNameMap ? ExtendedTagNameMap[T] : HTMLElement;

/**
 * Create a DOM element
 *
 * @param {string} 		tag			DOM element type (div, span, etc)
 * @param {string} 		className	One or more class names separated by spaces
 * @param {DOMElement}	parent		append new element to end of parent
 * @param {string} 		content		text content
 * @return {DOMElement}
 */
export function createElement<T extends keyof ExtendedTagNameMap>(tag: T, className?: string, parent?: HTMLElement | SVGElement | DocumentFragment, content?: string, options?: Partial<CreatedElement<T>>) : CreatedElement<T> {
	var element = document.createElement(tag) as CreatedElement<T>

	if (className)		// optional
		element.className = className;
	if (content)		// optional
		element.textContent = content;
	Object.assign(element, options);
	if (parent)			// optional
		parent.appendChild(element);
	return element;
};

export function createComponent(tag, className, parent, options = {}) {
	var element = document.createElement(tag);	// tag is required

	const attributes = Object.entries(options);
	for (const [key, value] of attributes) {
		element[key] = value;
	}

	if (className)		// optional
	element.className = className;
	if (parent)			// optional
		parent.appendChild(element);
	return element;
}

declare global {
	interface Element {
		removeChildren();
		removeChildElements();
		destroyWidgets(fRecursive?: boolean);
		insertChildAt(childElement: HTMLElement, index: number);
		removeChildAt(index: number);
		getCSS(property: string);
		getCSSInt(property: string);
		getWidth();
		setWidth(width: number, units: string);
		getHeight();
		setHeight(height: number, units: string);
		getScrollLeft();
		getScrollTop();
		stretchHeight(minimum: number, margin: number);
		collapse();
		expand();
		formatTimeSpan(milliseconds: number, days: number, hours: number, minutes: number, seconds: number);
		_redX: SVGElement;
	}
}

// Remove all children, including text nodes, comments, etc:
Element.prototype.removeChildren = function () {
	while (this.lastChild)
		this.removeChild(this.lastChild);
};

// Remove only child elements
Element.prototype.removeChildElements = function () {
	while (this.lastElementChild)
		this.removeChild(this.lastElementChild);
};

// element.destroyWidgets() must be called before removing elements from the DOM tree.
// If it is not called, then the LiveData job will continue to receive node values and deliver them to
// the DOM elements' widget update methods, even though the elements are no longer in the tree.
// Add method to all DOM elements:
Element.prototype.destroyWidgets = function (fRecursively) {	// default for fRecursively is 'false'

	if (this._widget) {
		this._widget.destroy();
		delete this._widget;	// Prevent 'destroy' method from being called more than once
	}

	if (fRecursively)
		for (var child = this.firstElementChild; child; child = child.nextElementSibling)
			child.destroyWidgets(true);
};

// Insert childElement into this parent element at specified index.
// this: parent element
// childElement: element to insert
// index values:.hashcode
// 0: insert as first child
// negative value or null: insert as last child
// #: insert before existing child at index #
Element.prototype.insertChildAt = function (childElement, index) {
	if (index < 0 || index === null || index === undefined || index > this.children.length)
		this.appendChild(childElement);
	else 	// insert the node before index'th child:
		this.insertBefore(childElement, this.children[index]);
};

// Remove child element of parent at specified index:
Element.prototype.removeChildAt = function (index) {
	assert (index >= 0 && index < this.children.length);
	this.removeChild(this.children[index]);
};

//Get CSS property value as text:
Element.prototype.getCSS = function(property) {
	// Return computed value for specified CSS property, or empty string if property is not defined:
	var style	= window.getComputedStyle(this, null);
	var result	= style.getPropertyValue(property);

	if (result != '')
		return result;
	return style.getPropertyValue('-webkit-' + property);
};

// Get CSS property value integer:
Element.prototype.getCSSInt = function(property) {
	return parseInt(this.getCSS(property), 10);
};

// The width and height of an element can include the size of content, padding, borders and margins.
// When we are modifying width and height of an object, we will almost always want to modify the
// width and height of the content only, and then let padding, border and margins add to the overall
// size of the element.
// There are two interesting sets of read-only values for a DOM element:
// clientWidth and clientHeight -- content size + padding
// offsetWidth and offsetHeight -- element size including content, padding borders and margins
// Neither of these give you just the width and height of just the content.
// Content width and height are determined by the browser using the following algorithm:
// 1. If an inline CSS width/height is specified (in em, px, %, etc), then this governs
// 2. If there is no inline style, then the computed CSS width/height is used (em, px, %, etc)
// 3. If there is no CSS width/height specified, then the size is determined by the layout.
//
// If we are going to alter the width or height of an element, then we are almost always going to
// alter the inline width/height. Otherwise, we are altering the width/height of all elements
// that use the CSS style that we are modifying. Therefore, the following methods may retrieve the
// CSS styles if no inline style is present, but they will always alter the inline styles, which
// overrides CSS style:

// Methods to get and set content size:

/**
 * Get element content width in pixels
 * @returns {number} pixels
 */
Element.prototype.getWidth = function () {
	var w = this.getCSSInt('width');
	return isNaN(w) ? 0 : w;
};

/**
 * Set element content width
 * @param {number|string} width
 * @param {string|omitted} units
 * @returns nothing
 */
Element.prototype.setWidth = function (width, units) {
	// If width is numeric and no units string is specified, assume units is 'px'.
	// If width is string and no units string is specified, assume width string includes units.
	this.style.width = width + (units ? units : (typeof width === 'string' ? '' : 'px'));
};

/**
 * Get element content height in pixels
 * @returns {number} pixels
 */
Element.prototype.getHeight = function () {
	var h = this.getCSSInt('height');
	return isNaN(h) ? 0 : h;
};

/**
 * Set element content height
 * @param {number|string} height
 * @param {string|omitted} units
 * @returns nothing
 */
Element.prototype.setHeight = function (height, units) {
	// If height is numeric and no units string is specified, assume units is 'px'.
	// If height is string and no units string is specified, assume height string includes units.
	this.style.height = height + (units ? units : (typeof height === 'string' ? '' : 'px'));
};

/**
 * Get the total scrollLeft of an element.
 * @returns {Number} scrollLeft
 */
Element.prototype.getScrollLeft = function () {
	var element = this;
	var scrollLeft = 0;
	while (element) {
		scrollLeft += element.scrollLeft;
		element = element.offsetParent; // 'SVGElement.offsetParent' is deprecated and will be removed in M50, around April 2016. See https://www.chromestatus.com/features/5724912467574784 for more details.
	}
	return scrollLeft;
};

/**
 * Get the total scrollTop of an element.
 * @returns {Number} offsetTop
 */
Element.prototype.getScrollTop = function () {
	var element = this;
	var scrollTop = 0;
	while (element) {
		scrollTop += element.scrollTop;
		element = element.offsetParent;
	}
	return scrollTop;
};

/*
 * Set element height to be within 'margin' pixels of the bottom of the browser window.
 * You will have to add an 'onresize' method to the element, and call this method to
 * respond to the user adjusting the size of the browser window:
 */
Element.prototype.stretchHeight = function(minimum, margin) {
  var height = window.innerHeight - this.offsetTop - margin;

  if (height < minimum)		// You can specify a minimum reasonable height, which might create a scrollbar in the window.
	  height = minimum;
  if (height < 0)
	  height = 0;

  this.style.height = height + "px";

  return height;
};

/**
 * Collapse an element with a smooth transition from an auto dimension.
 * Transitions must still be set in the element's style definition.
 * The browser can't cache dimensions that are sized automatically based on content
 * so we use this function to create transitions for collapsing the element
 */
Element.prototype.collapse = function() {
	if (this.getAttribute('collapsing') == 'true') return
	this.setAttribute('collapsing', 'true');
    var sectionHeight = this.scrollHeight; // get the height of the element's inner content, regardless of its actual size

	// when the next css transition finishes (which should be the one we just triggered)
	this.addEventListener('transitionend', function foo(e) {
		// remove this event listener so it only gets triggered once
		this.removeEventListener('transitionend', foo);
		this.setAttribute('collapsing', 'false');
	})
	// on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
	// aren't transitioning out of 'auto'
    requestAnimationFrame(() => {
      	this.style.height = sectionHeight + 'px';

		// on the next frame (as soon as the previous style change has taken effect),
		// have the element transition to height: 0
		requestAnimationFrame(() => {
			this.style.height = '0px';
		});
    });

    // mark the section as "currently collapsed"
    this.setAttribute('is-collapsed', 'true');
}

Element.prototype.expand = function() {
	if (this.getAttribute('collapsing') == 'true') return
	this.setAttribute('collapsing', 'true');
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = this.scrollHeight;

    // have the element transition to the height of its inner content
    this.style.height = sectionHeight + 'px';

    // when the next css transition finishes (which should be the one we just triggered)
    this.addEventListener('transitionend', function foo(e) {
		// remove this event listener so it only gets triggered once
		this.removeEventListener('transitionend', foo);
		// remove "height" from the element's inline styles, so it can return to its initial value
		this.style.height = null;
		this.setAttribute('collapsing', 'false');
    });

    // mark the section as "currently not collapsed"
    this.setAttribute('is-collapsed', 'false');
  }

/**
 * Create a unique element id
 * @returns {string} unique id
 */
export function createUniqueId() {
	return 'se_id_' + uniqueIdCounter++;
};

var uniqueIdCounter = 0;

export function formatTimeSpan(milliseconds: number, days: boolean, hours: boolean, minutes: boolean, seconds: boolean) {
	// Result is: ddd:hh:mm:ss,
	// with the final requested units rounded to the nearest whole number:
	// days, hours, minutes, seconds are all booleans.
	var result = '';
	if (days) {
		if (hours) {
			let dayCount = Math.floor(milliseconds / 86400000);
			milliseconds -= (dayCount * 86400000);
			result += dayCount + ':';
		} else {
			let dayCount = Math.round(milliseconds / 86400000);
			result += dayCount;
		}
	}
	if (hours) {
		if (minutes){
			let hourCount = Math.floor(milliseconds / 3600000);
			milliseconds -= (hourCount * 3600000);
			result += ('0'+hourCount).slice(-2) + ':';
		} else {
			let hourCount = Math.round(milliseconds / 3600000);
			result += ('0'+hourCount).slice(-2);
		}
	}
	if (minutes) {
		if (seconds){
			let minuteCount = Math.floor(milliseconds / 60000);
			milliseconds -= (minuteCount * 60000);
			result += ('0'+minuteCount).slice(-2) + ':';
		} else {
			let minuteCount = Math.floor(milliseconds / 60000);
			result += ('0'+minuteCount).slice(-2);
		}
	}
	if (seconds) {
		let secondCount = Math.round(milliseconds / 1000);
		if (secondCount < 10)
			result += '0';
		result += secondCount;
	}
	return result;
};

export function getHumanReadableTime(timeStamp: number, preText: string, postText: string) {
	// Input timestamp in microseconds, returns relative human readable time
	var now 			= (new Date()).getTime();
	var secondsElapsed 	= (now - timeStamp/1000) / 1000;	// Convert timestamp to milliseconds, take the diff from current time, then convert to seconds
	var count;
	if(secondsElapsed < 2*60){
        return "Just now";
	} else if(secondsElapsed < 2*3600){
		count 	= Math.floor(secondsElapsed / 60).toFixed(0);
		preText += count + " minute";
	} else if(secondsElapsed < 2*3600*24){
		count	= Math.floor(secondsElapsed / 3600).toFixed(0);
		preText += count + " hour";
	} else if (secondsElapsed < 2*3600*24*7){
		count 	= Math.floor(secondsElapsed / (3600*24)).toFixed(0);
		preText += count + " day";
    } else {
		count	= Math.floor(secondsElapsed / (3600*24*7)).toFixed(0);
		preText += count + " week";
	}
	if (count > 1)
		preText += 's';
	return preText + postText;
};

declare global {
	interface Number {
		toCurrency(label: string, digits: number);
	}
}

Number.prototype.toCurrency = function(label: string, digits: number) {
	return label + ' ' +this.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function getMimeType(filename: string) {
	switch(filename.substring(filename.lastIndexOf('.'))) {	// Extract the last period (if it exists) and any text after it. If no period, this returns the whole filename
		case '.3gp':	return 'video/3gpp';				// 3GP
		case '.avi':	return 'video/x-msvideo';			// Audio Video Interleave (AVI)
		case '.bmp':	return 'image/bmp';					// Bitmap Image File
		case '.flv':	return 'video/x-flv';				// Flash Video
		case '.gif':	return 'image/gif';					// Graphics Interchange Format
		case '.ico':	return 'image/x-icon';				// Icon Image
		case '.jpeg':	// Fallthrough
		case '.jpg':	return 'image/jpeg';				// JPEG Image
		case '.m4v':	return 'video/x-m4v';				// M4v
		case '.mp4':	return 'video/mp4';					// MPEG-4 Video
		case '.pdf':	return 'application/pdf';			// Adobe Portable Document Format
		case '.png':	return 'image/png';					// Portable Network Graphics (PNG)
		case '.qt':		return 'video/quicktime';			// Quicktime Video
		case '.svg':	return 'image/svg+xml';				// Scalable Vector Graphics (SVG)
		case '.tiff':	return 'image/tiff';				// Tagged Image File Format
		case '.wmv':	return 'video/x-ms-wmv';			// Microsoft Windows Media Video
	}
};

export default function createLoader(parent: HTMLElement, size: number, className = ''): HTMLDivElement {
	let container 					= createElement('div', '', parent);
	let loader 						= createElement('div', `lds-spinner ${className}`, container);
	let scale 						= size / 80
	container.style.transform 		= 'scale(' + scale + ',' + scale + ')';
	container.style.transformOrigin = "0 0"

    loader.innerHTML = `<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>`;
	return loader;
}
