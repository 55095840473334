import { TagAttribute } from "../../../lib/tag";
import { RegisterWidget, Widget } from "../../../lib/widget";
import type { Tag, TagDefinition } from "../../../lib/tag";
import template from './pumpwearcost.html';
import { Role } from "../../../../role";
import { type TagUnits } from "../../../taginfo/tagunits/tagunits";
import { Attribute } from "../../../lib/attributes";
import { TagUnitQuantity, UnitsMap } from "../../../lib/tagunits";

@RegisterWidget({tag: 'pump-wear-cost', template: template, displayName: 'Radio Button'})
export class PumpWearCost extends Widget {
    private wornPumpCost: Tag;
    private wornPumpTime: Tag;
    private energyCost: Tag;
    private costElement: HTMLElement;
    private currency: string;
    @Attribute({displayName: 'Digits'}) currencyDigits: number = 0;
    @TagAttribute({displayName: 'Pump'}) pumpFolder: TagDefinition;

    protected connectedCallback(): void {
        this.costElement = this.shadowRoot?.getElementById('cost') as HTMLElement;
    }

    protected enliven(): void {
        this.wornPumpCost = this.pumpFolder.tag.findChild('WearCost') ?? this.pumpFolder.tag.parent.findChildByRole(Role.ROLE_WORN_PUMP_COST)!;
        this.subscribeToTag(this.wornPumpCost);
        this.wornPumpTime = this.pumpFolder.tag.parent.findChildByRole(Role.ROLE_WORN_PUMP_SECONDS)!;
        this.subscribeToTag(this.wornPumpTime);
        this.energyCost = this.pumpFolder.tag.parent.findChildByRole(Role.ROLE_ENERGY_COST)!;
        this.subscribeToTag(this.energyCost);
        this.currency = UnitsMap.get((this.energyCost.units - TagUnitQuantity.TUQ_ENERGYCOST) + TagUnitQuantity.TUQ_CURRENCY)!.abbrev;
    }

    update(tag: Tag): void {
        let kWhLost = this.wornPumpCost.getValue()*365*24*3600/this.wornPumpTime.getValue()*this.energyCost.getValue();
        if (isNaN(kWhLost))	// Clamp NaNs to 0
            kWhLost = 0;
        this.costElement.textContent = Math.abs(kWhLost).toCurrency(this.currency ,this.currencyDigits) + "/yr";	// Energy cost is tangible
    }
}