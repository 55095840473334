import Icon from '../../../images/icons/name.svg';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './tagdescription.html'

@RegisterWidget({tag: 'tag-description', displayName: 'Tag Description', template: template, icon: Icon, section: 'Tag Information'})
export class TagDescription extends Widget {
    descriptionElement: HTMLElement;
    @TagAttribute({displayName: 'Tag'}) descriptionTag: TagDefinition;

    protected connectedCallback(): void {
        this.descriptionElement = this.shadowRoot?.getElementById('text')!;
    }

    protected enliven(): void {
        this.descriptionElement.textContent = this.descriptionTag.tag.description;
    }
}