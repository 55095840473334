import { createElement } from '../elements';
import owner from '../../owner';
import './configtabview.css';
import View from './view';
import LiveDataClient from '../livedataclient';
import { Device, Driver, PDSType, PotentialDataSource } from '../device';
import FrameParser from '../frameparser';
import assert from '../debug';
import { NodeFlags, Node, UserNodeFlags, VType, serializeRolesToCSV } from '../node';
import { TagFilter } from '../tagfilter';
import AddIcon from '../images/icons/add.svg';
import writeXlsxFile from 'write-excel-file'
import readXlsxFile, { readSheetNames, Row } from 'read-excel-file'
import LiveData from '../livedata';
import DownloadIcon from '../images/icons/download.svg';
import UploadIcon from '../images/icons/upload.svg';
import RefreshIcon from '../images/icons/refresh.svg';
import { WritesEnabler } from '../dialog';
import { UnitsMap } from '../widgets/lib/tagunits';

enum ColumnHeaders {
	PATH,
	NAME,
	DRIVER,
	DATA_TYPE,
	REGISTER,
	UNITS,
	WRITES,
	LOG,
	RAW_MIN,
	RAW_MAX,
	SCALED_MIN,
	SCALED_MAX,
	RESOLUTION,
	ROLE,
	CONFIG
}

interface ColumnMap {
	row: number;
	errors: string[];
}

interface CellCallback {
	sheetName: string;
	rowNumber: number;
}

// YAML property ids - These match up with backend values
const PathID = "Path"
const NameID = "Name"
const DriverNameID = "Driver Name";
const UnitsID = "Units";
const DataTypeID = "Data Type";
const RegisterNameID = "Register";
const FolderID = "Folder";
const EngMinID = "Eng Min";
const EngMaxID = "Eng Max";
const RawMinID = "Raw Min";
const RawMaxID = "Raw Max";
const ResolutionID = "Resolution";
const WriteableID = "Writeable";
const LogID = "Logged";
const RoleID = "Role";
const DescriptionID = "Description";
const RemotePathID = "Remote Path";

const defaultHeaders = [PathID, NameID, RegisterNameID, UnitsID, EngMinID, EngMaxID, RawMinID, RawMaxID, ResolutionID, WriteableID, LogID, RoleID, DescriptionID, RemotePathID];
const aliasHeaders = [PathID, NameID, RegisterNameID, RoleID];

const builtInHeaders = [PathID, NameID, UnitsID, EngMinID, EngMaxID, RawMinID, RawMaxID, ResolutionID, WriteableID, LogID, RoleID]

const StandardColor = 'var(--color-onSurface)';
const ErrorColor = 'var(--color-red-8)';
const WarningColor = 'var(--color-orange-5)';
const StatusColor = 'var(--color-blue-8)';

interface ColumnInfo {
	type: typeof Number | typeof Boolean | typeof String;
	valueParser: (node: Node) => Number | Boolean | String;
}

export interface ConfigTabViewProps {
	deviceKey: string;
}

class DeviceRefCount {
	count: number = 0;
}

// This tab display currently configured alarm on a system and allows alarms to be added/modified
export default class ConfigTabView extends View {
	ldc: LiveDataClient;
	device: Device;
	log: HTMLElement;
	getButton: HTMLInputElement;
	setButton: HTMLInputElement;
	tableContainer: HTMLElement;
	addButton: HTMLElement;
	drivers: Driver[];
	sourceMap: Map<string, PotentialDataSource[]> = new Map();
	columnMap: Map<string, ColumnInfo>;
	graphID: number;
	pendingConfiguration: string;
	pendingWriteButton: HTMLButtonElement;
	callbackMap: Map<string, CellCallback> = new Map();
	constructor(device: Device, ldc: LiveDataClient, props: ConfigTabViewProps) {
		super();
		this.device = device;
		assert(this.device);
		this.ldc = ldc;
		this.graphID = this.ldc.registerGraph(this);
		this.columnMap = new Map([
			[PathID, { type: String, valueParser: (node: Node) => this.getPathName(node) }],
			[NameID, { type: String, valueParser: (node: Node) => this.getNodeName(node) }],
			[DriverNameID, { type: String, valueParser: (node: Node) => node.driver }],
			[DataTypeID, { type: String, valueParser: (node: Node) => node.dataType }],
			[RegisterNameID, { type: String, valueParser: (node: Node) => node.registerName }],
			[UnitsID, { type: String, valueParser: (node: Node) => node.cacheUnits ? UnitsMap.get(node.cacheUnits)!.abbrev : ''}],
			[WriteableID, { type: String, valueParser: (node: Node) => (node.flags & NodeFlags.NF_WRITE) != 0 ? 'TRUE' : '' }],
			[LogID, { type: String, valueParser: (node: Node) => (node.flags & NodeFlags.NF_LOG) != 0 ? 'TRUE' : '' }],
			[RawMinID, { type: Number, valueParser: (node: Node) => node.rawMin }],
			[RawMaxID, { type: Number, valueParser: (node: Node) => node.rawMax }],
			[EngMinID, { type: Number, valueParser: (node: Node) => node.engMin }],
			[EngMaxID, { type: Number, valueParser: (node: Node) => node.engMax }],
			[ResolutionID, { type: Number, valueParser: (node: Node) => (node.flags & NodeFlags.NF_RESOLUTION) ? node.resolution : '' }],
			[RoleID, { type: String, valueParser: (node: Node) => (node.flags & NodeFlags.NF_ROLE) ? serializeRolesToCSV(node.roles) : ''}],
			[RemotePathID, { type: String, valueParser: (node: Node) => (node.userFlags & UserNodeFlags.UNF_LINKED) ? node.remotePath : '' }],
			[DescriptionID, { type: String, valueParser: (node: Node) => (node.userFlags & UserNodeFlags.UNF_DESCRIPTION) ? node.description : '' }],
		]);
	};

	initialize(parent: HTMLElement) {	// Called when the tab is brought up for the first time
		super.initialize(parent);
		this.wrapper = createElement('div', 'config__wrapper', this.parent);
		this.tableContainer = createElement('div', 'config__table', this.wrapper);
		let logContainer = createElement('div', 'config__container', this.wrapper);
		let logTitle = createElement('div', 'config__log__title', logContainer, 'System Status');
		this.log = createElement('div', 'config__log', logContainer);

		//TODO: Need to do something when our device isn't connects
		this.ldc.getStartupLog(this.graphID, this.device.id);					// Get the startup log so we can display it
		this.device.requestDrivers((drivers: Driver[]) => this.onDriversReceived(drivers))
		this.fInitialized = true;
		return this;
	}

	yamlParser(type: (typeof String | typeof Number | typeof Boolean), value: string): string {
		if (type === String)
			return `'${value}'`
		else if (type === Number)
			return value.toString();
		else
			return value.toString().toUpperCase() == 'TRUE' ? 'y' : 'n';
	}

	getPathName(node: Node): string {
		if (node.vtype == VType.VT_UNKNOWN)
			return node.getDeviceRelativePath();
		let pathArray = node.getDeviceRelativePath().split('/');
		pathArray.pop();
		return pathArray.join('/') + '/';
	}

	getNodeName(node: Node): string {
		if (node.vtype == VType.VT_UNKNOWN)
			return "";
		let pathArray = node.getDeviceRelativePath().split('/');
		return pathArray.pop()!;
	}

	onDriversReceived(drivers: Driver[]) {
		this.drivers = drivers;
		let pullButton = this.createButton(this.tableContainer, 'Pull Tag Map', DownloadIcon, 'Download an Excel spreadsheet of the existing tag map to edit on your local machine');
		let pushButton = this.createButton(this.tableContainer, 'Push Tag Map', UploadIcon, 'Upload and validate an Excel spreadsheet or CSV file from your local machine');
		this.pendingWriteButton = this.createButton(this.tableContainer, 'Confirm Configuration and Restart', RefreshIcon, `Write your validated config file to ${this.device.siteName}. This will remove the previous tag configuration.`);
		this.pendingWriteButton.classList.add('hide', 'config__button__pulse');
		pushButton.onclick = () => {
			let fileInput = createElement('input', undefined, undefined, undefined, { 'type': 'file', 'accept': 'text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fileInput.onchange = () => {
				let rows: Row[][] = [];
				let drivers: string[] = []
				readSheetNames(fileInput.files![0]).then((sheetNames) => {
					for (let i = 0; i < sheetNames.length; ++i) {
						readXlsxFile(fileInput.files![0], { sheet: sheetNames[i] }).then((parsedRows) => {
							drivers.push(sheetNames[i]);
							rows.push(parsedRows);
							if (drivers.length == sheetNames.length) // If this is the last one
							{
								new WritesEnabler(() => {
									this.pendingConfiguration = this.createConfig(drivers, rows, fileInput.files![0].name);
									this.ldc.fm.buildFrame(LiveData.LDC_SUBMIT_TAG_CONFIG, this.device.id, this.graphID);
									this.ldc.fm.push_u8(0);
									this.ldc.fm.push_bytes(this.pendingConfiguration, this.pendingConfiguration.length);
									this.ldc.send();
								});
							}
						})
					}
				})

			}
			fileInput.click();
		}
		pullButton.onclick = () => this.serializeTagTree();
		this.pendingWriteButton.onclick = () => this._assignTagMap();
	}

	createButton(parent: HTMLElement, title: string, icon: string, helpText: string) {
		let button = createElement('button', 'se-button config__button', parent);
		let titleRow = createElement('div', 'config__button__title-row', button);
		createElement('div', 'config__button__title-row__title', titleRow, title);
		createElement('img', 'config__button__title-row__icon', titleRow, '', { 'src': icon });
		createElement('div', 'config__button__help-text', button, helpText);
		return button;
	}

	onSubmitTagConfigResponse(fp: FrameParser) {
		let fWrite = fp.pop_u8() == 1;
		let errorCount = fp.pop_u32();
		if (fWrite) {
			this.createTextRow(this.log, false, `✓ Configuration successfully applied`, StatusColor);
			this.createTextRow(this.log, false, `Restarting ${this.device.siteName}...`, StatusColor);
			return;
		}
		for (let i = 0; i < errorCount; ++i) {
			let deviceRelativePath = fp.pop_string();
			let tagWarningCount = fp.pop_u16();
			let tagErrorCount = fp.pop_u16();
			let callback = this.callbackMap.get(deviceRelativePath);
			let warningIntro = tagWarningCount > 0 ? `${tagWarningCount} WARNING${tagWarningCount > 1 ? 'S' : ''}` : '';
			let errorIntro = tagErrorCount > 0 ? `${tagWarningCount > 0 ? ' and ' : ''}${tagErrorCount} ERROR${tagErrorCount > 1 ? 'S' : ''}` : '';
			this.createTextRow(this.log, false, warningIntro + errorIntro + ` found in row ${callback?.rowNumber} on sheet '${callback?.sheetName}'`, tagErrorCount > 0 ? ErrorColor : WarningColor);
			for (let j = 0; j < tagWarningCount; ++j)
				this.createTextRow(this.log, true, `WARNING: ${fp.pop_string()}`, WarningColor);
			for (let j = 0; j < tagErrorCount; ++j)
				this.createTextRow(this.log, true, `ERROR: ${fp.pop_string()}`, ErrorColor);
		}

		let modifyCount = fp.pop_u32();
		let addCount = fp.pop_u32();
		let removedCount = fp.pop_u32();
		this.log.scrollTop = this.log.scrollHeight;
		if (errorCount == 0) {
			this.createTextRow(this.log, false, `✓ Configuration successfully verified`, StatusColor);
			this.createTextRow(this.log, true, `${modifyCount} Pending Tag changes`, StatusColor);
			this.createTextRow(this.log, true, `${addCount} Pending Tag additions`, StatusColor);
			this.createTextRow(this.log, true, `${removedCount} Pending Tag deletions`, StatusColor);
			this.pendingWriteButton.classList.remove('hide');
			this.pendingWriteButton.focus();
		}
	}

	private _assignTagMap(): void {
		this.log.removeChildren();
		this.ldc.fm.buildFrame(LiveData.LDC_SUBMIT_TAG_CONFIG, this.device.id, this.graphID);
		this.ldc.fm.push_u8(1);
		this.ldc.fm.push_bytes(this.pendingConfiguration, this.pendingConfiguration.length);
		this.ldc.send();
	}

	createConfig(driverNames: string[], driverRows: Row[][], name: string): string {
		assert(driverNames.length == driverRows.length);
		this.callbackMap.clear();
		this.log.removeChildren();
		this.pendingWriteButton.classList.add('hide');
		let yaml = 'Tags:';

		this.createTextRow(this.log, false, `Reading Tag Map from '${name}'`, StandardColor);

		for (let i = 0; i < driverNames.length; ++i) {
			let driver = driverNames[i];
			let driverRow = driverRows[i];
			this.createTextRow(this.log, false, `Read ${driverRows[i].length} rows for Driver '${driver}'`, StandardColor);
			for (let j = 0; j < driverRow.length; ++j) {
				let row = driverRow[j];
				if (j == 0) {	// Validate the headers
					for (let k = 0; k < row.length; ++k) {
						if (!this.columnMap.has(row[k].toString()))
							this.createTextRow(this.log, false, `Error for Driver '${driver}': Invalid column header '${row[k]}`, ErrorColor);
					}
				} else {
					let path = row[0].toString();	// FIXME: What if Path and Node aren't first?
					path = path.endsWith('/') ? path : path + '/';
					let name = row[1]?.toString() ?? '';
					let deviceRelativePath = `${path}${name}`;
					if (this.callbackMap.has(deviceRelativePath)) {
						let duplicateCallback = this.callbackMap.get(deviceRelativePath);
						this.createTextRow(this.log, false, `Duplicate tag names found in sheet '${duplicateCallback?.sheetName}' row ${duplicateCallback?.rowNumber} and sheet '${driver}' row ${j}. Combination of tag path and name must be unique for each tag.`, ErrorColor);
						continue;
					}
					this.callbackMap.set(deviceRelativePath, {
						sheetName: driver,
						rowNumber: j
					})
					yaml += `\n  ${deviceRelativePath}:\n    ${DriverNameID}: '${driver}'`;
					for (let k = 2; k < row.length; ++k) {
						if (row[k] != null)
							yaml += `\n    ${driverRow[0][k]}: ${this.serializeCell(row[k])}`;
					}
					if (path != null && path !== "" && name === "")	// If there's a folder with a path and no name, it's a folder
						yaml += `\n    ${FolderID}: y`;
				}
			}
		}
		return yaml + '\n';
	}

	serializeCell(cell: (string | number | boolean | typeof Date)) {
		if (typeof cell === 'string') {
			return `'${cell.toString()}'`;
		}
		else if (typeof cell === 'number') {
			return cell.toString();
		}
		else if (typeof cell === 'boolean') {
			return cell.toString().toUpperCase() == 'TRUE' ? 'y' : 'n'
		}
	}

	createTextRow(parent: HTMLElement, fBullet: boolean, text: string, color: string = StandardColor) {
		let row = createElement('div', 'config__log__row', parent);
		row.style.color = color;
		if (fBullet)
			createElement('p', 'config__log__text', row, `◦`);
		createElement('p', 'config__log__text', row, text);
	}

	serializeTagTree() {
		//let deviceRefCount = new Map<string, DeviceRefCount>(); This gave me all sorts of compiler warnings
		let deviceRefCount = new Map();
		this.device.drivers.forEach(driver => {
			deviceRefCount.set(driver.name, new DeviceRefCount);
		});
		this.device.tree.nodes.forEach(node => {
			if (node?.driver !== undefined)
				deviceRefCount.get(node?.driver).count++;
		});
		let biggestCount = -1;
		let mostUsed;
		this.device.drivers.forEach(driver => {
			let count = deviceRefCount.get(driver.name).count;
			if (count <= biggestCount)
				return;
			biggestCount = count;
			mostUsed = driver;
		});

		let nodes: (Node | undefined)[][] = [];
		let tabs: { sheetName: string, schema: any[] }[] = [];
		this.device.drivers.forEach(driver => {
			if (driver.sources.size == 0)	// If there's no available sources (looking at you, VFDs)
				return;						// They can't create nodes on it. Don't add a tab

			let columns: any[] = [];
			let headers = new Array(...(driver.name !== 'Alias' ? defaultHeaders : aliasHeaders));
			if (driver.fRequiresDataType)
				headers.splice(3, 0, DataTypeID);

			headers.forEach(header => {
				let info = this.columnMap.get(header);
				columns.push({
					column: header,
					type: info?.type,
					value: info?.valueParser
				});
			});
			tabs.push({
				sheetName: driver.name,
				schema: columns
			});
			nodes.push(this.device.tree.nodes.filter((node) => ((node?.driver == driver.name) || (node && Node.isFolder(node) && driver === mostUsed)) && (node?.flags & NodeFlags.NF_USER) != 0));
		});

		//let columns: any[] = [];
		//let headers = builtInHeaders;

		//headers.forEach(header => {
		//	let info = this.columnMap.get(header);
		//	columns.push({
		//		column: header,
		//		type: info?.type,
		//		value: info?.valueParser
		//	});
		//});

		//tabs.push({
		//	sheetName: "BuiltIn Tags",
		//	schema: columns
		//})
		//nodes.push(this.device.tree.nodes.filter(node => node && (node?.flags & NodeFlags.NF_USER) == 0));

		writeXlsxFile(nodes, {
			schema: tabs.map(tab => tab.schema),
			sheets: tabs.map(tab => tab.sheetName),
			headerStyle: {},
			fileName: `${this.device.siteName.split(' ').join('_')}_Tag_Map_${new Date().toLocaleDateString()}.xlsx`
		})
	}

	onLogReceived(log) {	// Got back the startup log
		//this.log.removeChildren();	// Remove any existing children
		for (var i = 0; i < log.length; ++i)	// For each log entry
			this.createTextRow(this.log, false, log[i].text, log[i].type ? ErrorColor : StatusColor);
	}

	destroy() {
		if (this.graphID)						// If we were initialized
			this.ldc.unregisterGraph(this.graphID);		// Unregister ourselves as a callback
		this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}
};
