import createSVGElement from '../svgelements';
import { NodeQuality } from '../node';
import GenericFlower from './genericflower';
import { TagUnitQuantity } from '../widgets/lib/tagunits';

// The Blower driver is designed specifically for blowers and nothing else. You might want to check out other things
// inheriting from GenericFlower like the Pump driver or the Valve driver
// Necessary attributes:
//      data-se-node="BLOWER'S/NODE/PATH"
//      data-se-lens="blower"
//
// Other assumptions: Assumes that the element that has data-se-lens="blower" is an SVG rect element.
// Otherwise, this will likely break.
//
// Supplemental attributes:
//		data-se-direction="left|right|up|down"			defines what direction the blower should be pointing in
//
//		data-se-isomer="cw|ccw"							defines the isomer (i.e. should the blower's spout be
//															just clockwise or just counter clockwise of the
//															direction mentioned
//
//		data-se-text-offset="0|1|2|3"					defines how many quarter turn offsets the text on the
//															blower should be offset to look proper (not upside down)
//
//		data-se-node-mode="BLOWER'S/STATUS/PATH"		defines what mode the blower is in (Auto or Manual)
//
//		data-se-status_[SC]="(n|a)?(Error|Off|On|OPOR)"	defines what status code SC means (whether an error, blower is
//															off, blower is on, or blower is outside of POR) and what mode
//															the blower is running in if needed (n: not in auto, a:
//															in auto). Do not need to specify the mode of the blower
//
//		data-se-status-exp_[SC]="A string of you're very own creation"
//														defines what status code SC should show to the user via a Tippy

export default class Blower extends GenericFlower {
    static attach(nodeRoot, element) {
        return new this(nodeRoot, element);
    }

    constructor(nodeRoot, element) {
        super(nodeRoot, element);
	}

	getViewBox(isVertical) {
		return  "0 0 100 100";
	}

	setTextLocation(direction) {
		switch (direction) {
		case "right":							// Nothing to do: already have 0 degrees set
			this.textLocation = {x:50, y:50};
			break;
		case "up":								// Up is -90 degrees or +270 degrees clockwise from positive x-axis
			this.textLocation = {x:40, y:40};	// Only for text location because of SVG text weirdness (i.e. if x and y of a rect and a text element are the same, the text's base line will be through the y and will look like it is sitting on the rect, instead of the top-most portion of the text being aligned with the y-coordinate)
			break;
		case "left":							// Left is 180 degs from right
	        this.textLocation = {x:50, y:70};
			break;
		case "down":							// Down is 90 from right
	        this.textLocation = {x:60, y:40};
			break;
		default:
			assert(false, "Invalid attribute assignment to data-se-direction for a blower SVG Widget.");
			break;
		}
	}

	// Creates the blower widget and corresponding text elements in the parent SVG element
	createStuff() {
		if (this.blowerPath)
			return;
		if (this.rotation !== undefined) {
			// Maybe all of this style should be in a CSS document, but no other SVG widget has a CSS document, and there isn't THAT much style here.
			this.textLocation["text-anchor"] = "middle";		// Anchor in the middle so that single digits through triple digits look normal
			let transformText = "rotate(" + this.rotation + ", 50, 50)";
			this.blowerPath = createSVGElement("path", "error-state", this.parentElem, {d:"M 0 100 l 25 -25 a 40 40 0 1 1 50 0 l 25 25 Z",transfomr:transformText});
			if (this.modeNode) {
				this.textLocation.y -= 5;
			}
			this.textLocation.transform = "rotate(" + this.textRotation + ", " + this.textLocation.x + ", " + (this.textLocation.y - 10) + ")";
			this.blowerText = createSVGElement("text", null, this.parentElem, this.textLocation);
			if (this.modeNode) {
				this.textLocation.y += 5;
				delete this.textLocation.transform;
				this.modeText = createSVGElement("tspan", "small", this.blowerText, this.textLocation);
			}
		}
		if (this.node == undefined) {
			this.postError("Node not found: " + this.nodePaths[""], this.parentElem);
		}
		this.setNodeStatus();
	}

	get path() {
		return this.blowerPath;
	}

	get text() {
		return this.blowerText;
	}

	setModeStuff() {
		let node = this.modeNode;
		let type = node.units & 0xFF00;
		if (node.quality == NodeQuality.NQ_GOOD && type == TagUnitQuantity.TUQ_UNDEFINED) {
			let val = node.getValue();
			let text = "off";
			if (this.modeStatusLookup && this.modeStatusLookup[val.toString()]) {	// Use the mode lookup if one is provided
				text = this.modeStatusLookup[val.toString()];
			}
			else if (this.modeText) {
				if (node.getValue()) {			// In Auto or manual... not sure which, assume in auto
					text = "auto";
				}
				else if (this.status === 1){	// Check if the blower is running according to the status.
					text = "hand";
				}
				else if (this.status === 2) {	// Check if some error
					text = "error";
				}
				else if (this.status === 3) {	// Check if running out of POR
					text = "out of POR";
				}
				else if (this.status === 4) {	// Check if node wasn't found... Odd since we found the mode's node...
					text = "";					// maybe mis-intered node path?
				}
				// else: text remains 'off' since blower is determined not to be running and assumed to not be in auto,
				// otherwise, a value for the status code would be provided.
			}
			this.modeText.innerHTML = text;
		}
	}
};
