import {Node, NodeQuality }           from '../node';
import NodeManager      from '../nodemanager';
import Localization     from '../localization';
import {createElement, createUniqueId}  from '../elements';
import createSVGElement from '../svgelements';
import {Widget}         from '../widget';
import MouseCapture     from '../mousecapture';
import LiveData         from '../livedata';
import {StaticGraph}  	from '../graph';
import {Color}          from '../color';
import owner            from '../../owner';
import assert           from '../debug';
import {PumpBank}       from '../pumpbank';
import View from './view';
import './specificenergyview.css';
import SquishedCurve from '../squishedcurve';
import { TagUnit, TagUnitQuantity, UnitsMap, convert } from '../widgets/lib/tagunits';
import { Role } from '../role';
import { getHash, getRouteAndProperties } from '../router/router';

// Make a graph of all the Configuration points the uber controller remembers.
export default class SpecificEnergyView extends View {
    constructor(device, ldc) {
		super();
		assert (device.isTreeComplete(), 'Device must already have a complete node tree');
        this.ldc		= ldc;			// The LiveDataClient
        this.device 	= device;
	};

	initialize(parent) {
		super.initialize(parent);
		this.rootNode  		= this.device.tree.nodes[0];
        this.pumpSystem		= this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
        this.dpoFolder		= this.rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);
        this.periods		= this.dpoFolder.findByRole(Role.ROLE_TLC_PERIOD);	// Parts of Day folders
        this.index			= -1;
		this.flow			= -1;	// IIR filter storage values for the current point
		this.sec			= -1;
		this.filterID		= setInterval(this.updateCurrentPoint.bind(this), 200, this);	// Update five times a second
		this.selectedAuto	= false;

		this.flowLimits		= [0, 0, 0, 0];	// This is used to draw the min and max flow limits (x axis). Will be [minFlow, minFlow, maxFlow, maxFlow]
		this.SECMaxes		= [0, 0, 0, 0];	// This is used to draw the min and max flow limits (y axis). Will be [0, maxSEC, maxSEC, 0]
		this.limitedFlows	= [];
		this.limitedSECs	= [];
		this.globalEnables	= [];	// Find the enabled nodes for all the global constraints. Then we can show the user we a constraint is overwritten

		this.labels			= ['FlowLimits', 'FlowDeadband', 'SecDeadband'];
		this.colors			= [owner.colors.hex('--color-orange-3'), owner.colors.hex('--color-orange-2'), owner.colors.hex('--color-onSurface')]; // colors for sandbox
		this.visible		= [true, true, true];

		this.flowBandXs		= [0,0,0,0];
		this.secBandXs		= [];
		this.secBandYs		= [];
		this.secBandMaxes	= [];

		this.selectedRegime	= 1;			// Start off on the 1st regime selected
		this.selectedPoint	= 0;			// Start off on the first point in the 0 regime

		this.pumps			= this.pumpSystem.findByRole(Role.ROLE_PUMP);	// Find all the pump folders
        var modelPumps		= this.pumpSystem.findByRole(Role.ROLE_MODEL_PUMP);
        var maintenance		= this.pumpSystem.findChild('Maintenance');
		this.nodeManager	= new NodeManager(this);
		this.lastCurveCheck = this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SYSTEM_MODEL_TIMESTAMP);
		this.targetSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_TARGET_SPEED);
		this.idealSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_IDEAL_SPEED);
		this.flowNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_TOTAL_FLOW);
		this.secNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SEC);
		this.pressureNode	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_DISCHARGE_PRESSURE);
		this.energyMetric	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_STATION_EFFICIENCY);
		this.qMin			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMIN);
		this.qMax			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMAX);
		this.nPumps			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_MAX_PUMPS);
		this.dpoInControl	= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_DPO_IN_CONTROL);
		this.offHull		= this.nodeManager.addNodeByName(this.dpoFolder, 'OffConvexHull');
		this.noValidPoints	= this.nodeManager.addNodeByName(this.dpoFolder, 'NoValidPoints');
		this.fFlowDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, 'UsingFlowDeadband');
		this.fSecDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, 'UsingSecDeadband');
		this.powerLimited	= this.nodeManager.addNodeByName(this.dpoFolder, 'PowerLimited');
		this.nPumpsLimited	= this.nodeManager.addNodeByName(this.dpoFolder, 'MaxPumpsLimited');
		this.odorContolled	= this.nodeManager.addNodeByName(this.pumpSystem, 'OdorControlTriggered');
		this.floatOverride	= this.nodeManager.addNodeByName(this.pumpSystem, 'Override');
		if (maintenance)
			this.maintenance	= this.nodeManager.addNodeByName(maintenance, 'MaintenanceCycleState');

		var pressureUnits	= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_PRESSURE) || TagUnit.TU_PSI;
		this.convertPSI		= convert(1, TagUnit.TU_PSI, pressureUnits);

		var powerUnits		= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_POWER) || TagUnit.TU_KW;
		this.convertKW		= convert(1, TagUnit.TU_KW, powerUnits);

		let maxSpeedNode	= this.pumpSystem.findChildByRole(Role.ROLE_MAX_SPEED_HZ); // Final max line frequency node

		var speedUnits		= this.pumpSystem.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) || TagUnit.TU_HZ;
		this.convertHz		= convert(1, TagUnit.TU_HZ, speedUnits, maxSpeedNode.getValue());

		this.convertSEC		= convert(1, TagUnit.TU_KW_HR_PER_MG, this.secNode.units);

		this.lineOptions	= {drawPoints: false, connectSeparatedPoints: true, nomouseover: true};
		var fillOptions		= {connectSeparatedPoints: false, nomouseover: true};	// For fills, no hover and don't draw lines
		this.options		= {
			colors:						this.colors,		// Colors
			drawY2Line:					true,
			visibility:					this.visible,		// Which lines to show and hide
			connectSeparatedPoints:		false,				// Lines between points
			drawPoints: 				true,				// Put an indicator at each point
			fillAlpha: 					1,
			pointSize: 					2,					// How big to make the circle
			xAxisAsNumber: 				true, 				// Interpret the x-axis as numbers, not time stamps
			pointClickCallback:			this.pointClicked.bind(this),
			drawCallback:				this.onDraw.bind(this),
			AutoHull: 					fillOptions,
			AutoLine:					this.lineOptions,
			ActHull: 					fillOptions,
			ActLine: 					this.lineOptions,
			SecDeadband: {
				fillAlpha: 				0.5,
				drawPoints:				false,
				connectSeparatedPoints: false,
				nomouseover:			true
			},
			FlowLimits: {									// Options specific to the shaded area representing qMin and qMax
				strokeWidth:			0, 					// Don't draw lines for these guys
				fillAlpha: 				0.6,					// All the way opaque (as we have selected colors appropriately)
				drawBehind:				true,				// Draw behind the axis
				nomouseover:			true				// This flags tells Dygraph to ignore this line for mouse events
			},
			FlowDeadband: {									// Options specific to the shaded area representing qMin and qMax
				strokeWidth:			0, 					// Don't draw lines for these guys
				fillAlpha: 				0.6,					// All the way opaque (as we have selected colors appropriately)
				drawBehind:				true,				// Draw behind the axis
				nomouseover:			true				// This flags tells Dygraph to ignore this line for mouse events
			},
			Limited:					fillOptions,
			xlabel: 					Localization.toLocal("Flow (" + this.flowNode.getUnitsText() + ")"),			// X-axis title
			ylabel: 					Localization.toLocal("Specific Energy (" + this.secNode.getUnitsText() + ")"),	// Y-axis title
			yRangePad:					200,
		};

		assert(this.pumps.length == this.targetSpeeds.length, 'Should have a next speed for each pump!');
		assert(this.idealSpeeds.length == this.targetSpeeds.length, 'Should have a next speed for each pump!');

		this.totalBase = 0;
		var basePowers = [];
		this.hoas = [], this.startFails = [], this.stopFails = [], this.faulted = [], this.preferred = [];
		for (var i = 0; i < this.targetSpeeds.length; ++i) {
			basePowers.push(this.pumps[i].findChild('BaseLoad').getValue());	// This is a final node
			this.totalBase += basePowers.back();
			this.hoas.push(this.nodeManager.addNodeByRole(this.pumps[i], Role.ROLE_PUMP_OVERALL_HOA));
			this.startFails.push(this.nodeManager.addNodeByName(this.pumps[i], "StartFail"));
			this.stopFails.push(this.nodeManager.addNodeByName(this.pumps[i], "StopFail"));
			this.faulted.push(this.nodeManager.addNodeByRole(this.pumps[i], Role.ROLE_BOOL_FAULTED));
			this.preferred.push(this.nodeManager.addNodeByName(this.pumps[i], "fPreferred"));
		}
		this.limiting				= new PowerLimiting(this, this.dpoFolder, basePowers);	// This guy will color points differently that are over metered power
		this.graphID				= this.ldc.registerGraph(this);	// Register for graph data
		this.fRedraw				= false;		// We will set this to true when it is time to replot the graph
		this.fLandscape 			= window.innerHeight < 420;
		this.wrapper 				= createElement('div', 'specific-energy-view__page-wrapper',this.parent);
		this.parameterWrapper		= createElement('div', 'overview__parameter-wrapper', this.wrapper)
		var overviewWrapper 		= createElement('div', 'overview__overview-graph-wrapper', this.wrapper); // center div that holds the gauges and chart
		var dataWrapper				= createElement('div', 'specific-energy-view__data-wrapper hide', this.wrapper);
		let parameterContainer		= createElement('div', 'overview__parameter-wrapper__value-container',this.parameterWrapper);
		this.parameterTable 		= createElement('div', 'overview__parameter-table', parameterContainer);
		this.pumpBankWrapper 		= createElement('div', 'overview__pump-bank-wrapper', overviewWrapper);
		this.pumpTable 				= createElement('div', 'overview__parameter-table', parameterContainer)
		var overviewGraphContainer 	= createElement('div', 'specific-energy-view__graph-container', overviewWrapper); // graph flex wrapper
		this.graphRow				= createElement('div', 'overview__overview-graph-wrapper__graph-row', overviewGraphContainer);
		this.constraintContainer 	= createElement('div', 'specific-energy-view__constraint-container',dataWrapper);
		var SEWrapper 				= createElement('div', 'specific-energy-view__se-wrapper', this.graphRow);
		var SEGraphContainer 		= createElement('div', 'specific-energy-view__se-graph-container', SEWrapper);
		this.SEGraph 				= createElement('div', 'specific-energy-view__se-graph', SEGraphContainer);
		this.graphRow				= createElement('div', 'specific-energy-view__se-graph-wrapper__graph-row', this.SEGraph);
		this.graphDiv				= createElement('div', 'secMapGraphDiv', this.graphRow);
		this.selectionSmall 		= createElement('div', 'specific-energy-view__selection-small', this.pumpBankWrapper);

		// Find constraint folder
		var constraintFolder	= this.dpoFolder.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FOLDER);
		this.subscribeToEnableNodes(constraintFolder);
		this.subscribeToEnableNodes(this.dpoFolder.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ROC_FOLDER));
		this.subscribeToEnableNodes(this.dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER));
		let targetFolder = this.dpoFolder.findChildByRole(Role.ROLE_TLC_TARGET_TANK_FOLDER);
		let sourceFolder = this.dpoFolder.findChildByRole(Role.ROLE_TLC_SOURCE_TANK_FOLDER);

		this.subscribeToEnableNodes(targetFolder);
		this.subscribeToEnableNodes(sourceFolder)

		// Find constraint folder
		this.flowNodes = [], this.dischargeNodes = [], this.suctionNodes = [];	// Fresh arrays to hold the nodes for each pv
		for (var i = 0; constraintFolder && i < constraintFolder.children.length; ++i)
			this.checkRole(constraintFolder.children[i], Role.ROLE_TLC_CONSTRAINT_PV, false);		// Sort the nodes by process variable

		var folder	= this.dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER);	// Find the folder, if it exists
		this.powerNodes	= folder ? folder.children : [];		// Store the nodes

		this.flowDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, "FlowDeadband");
		this.secDeadband	= this.nodeManager.addNodeByName(this.dpoFolder, "SEC_Deadband");

		var flow = this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);
		createElement('tag-badge', 'overview__gauge PumpStationFlow', this.parameterTable, '', {statusTag: {tag: flow}, showUnits: true});

		var sec = this.pumpSystem.findChildByRole(Role.ROLE_SEC);
		createElement('tag-badge', 'overview__gauge PumpStationSEC', this.parameterTable, '', {statusTag: {tag: sec}, showUnits: true, overrideName: 'Specific Energy'});

		var suction 	= this.pumpSystem.findChildByRole(Role.ROLE_SUCTION_PRESSURE);
		if (suction)
			createElement('tag-badge', 'overview__gauge PumpStationSuction', this.parameterTable, '', {statusTag: {tag: suction}, showUnits: true});

		var discharge 	= this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		if (discharge)
			createElement('tag-badge', 'overview__gauge PumpStationDP', this.parameterTable, '', {statusTag: {tag: discharge}, showUnits: true});

		var chlorine	= this.dpoFolder.findChildByRole(Role.ROLE_CHLORINE_RESIDUAL);
		if (chlorine)
			createElement('tag-badge', 'overview__gauge PumpStationChlorine', this.parameterTable, '', {statusTag: {tag: discharge}, showUnits: true});

		this.description 		= createElement('div', 'specific-energy-view__description', this.parameterWrapper);

		this.pumpBank 			= new PumpBank(this.pumpSystem, this.fLandscape? this.pumpTable : this.pumpBankWrapper, this.dpoFolder);

		this.currentPulse	= createGraphPulse(this.graphDiv, 'green');
		this.selectPulse	= createGraphPulse(this.graphDiv, '#17FFE8');

		this.constraintLines = [];
		this.constraints = [...this.powerNodes, ...this.flowNodes, ...this.dischargeNodes, ...this.suctionNodes];
		if (targetFolder) {
			this.constraints.push(targetFolder)
		}
		if (sourceFolder) {
			this.constraints.push(sourceFolder)
		}

		this.lineWrapper = createSVGElement('svg', 'secMapSVGline', this.graphDiv, {height:500});
		this.createGraphLines(this.powerNodes);	// We put power nodes first becasue they are the hardest to position the names of
		this.createGraphLines(this.flowNodes);
		this.createGraphLines(this.dischargeNodes);
		this.createGraphLines(this.suctionNodes);
		var wetWellFolder = this.dpoFolder.findChild('SourceTankMinFlow');
		this.fWetWell = wetWellFolder !== undefined;
		this.createGraphLine(wetWellFolder, false, Role.ROLE_TLC_SOURCE_TANK_FOLDER)
		this.createGraphLine(this.dpoFolder.findChild('SourceTankMaxFlow'), true, Role.ROLE_TLC_SOURCE_TANK_FOLDER);
		this.createGraphLine(this.dpoFolder.findChild('TargetTankMinFlow'), false, Role.ROLE_TLC_TARGET_TANK_FOLDER);

		this.spinnerDiv		= createElement('div', 'secMapSpinnerWrapper', this.graphDiv);

		// Create a division to hold the graph we are going to create onJobComplete

		var advisoryNode	= this.dpoFolder.findChild('AdvisoryMode');
		this.fAdvisoryMode	= advisoryNode && advisoryNode.getValue();
		this.captionRow		= createElement('div', 'specific-energy-view__se-graph-wrapper__caption-row', this.SEGraph)
		this.captionWrapper	= createElement('div', 'secMapCaptionWrapper', this.captionRow);

		if (!this.fAdvisoryMode)
			createCaption(this.captionWrapper, 'Available Points:', 'green', this.hideValidOperationPoints.bind(this), true);
		if (!this.fAdvisoryMode)
			createCaption(this.captionWrapper, 'Available Solution:', 'black', this.hideCurrentSolution.bind(this), true);
		createCaption(this.captionWrapper, 'Valid Points:', this.fAdvisoryMode ? 'green' : '#70a970', this.hideDPOOperationPoints.bind(this), true);
		createCaption(this.captionWrapper, 'Current Solution:', this.fAdvisoryMode ? 'black' : 'gray', this.hideDPOSolution.bind(this), true);
		createCaption(this.captionWrapper, 'Invalid Points:', '#ff2828', this.hideInvalid.bind(this));
		if(this.limiting.meters.length > 0)
			createCaption(this.captionWrapper, 'Over Power Points:', '#C12727');
		createCaption(this.captionWrapper, 'Over Pump Limit:', '#10664e');
		createCaption(this.captionWrapper, 'Measured Operating Point:', 'green', this.hideCurrentOperationPoint.bind(this), false, true);
		createCaption(this.captionWrapper, 'Selected Point:', '#17FFE8', this.hideSelectedPoint.bind(this), false, true);
		createCaption(this.captionWrapper, 'Constraints:', 'black', this.hideConstraintLines.bind(this), true, false, true);

		var messageWrapper	= createElement('div', 'secMapCaptionWrapper', this.description);
		this.message 		= createElement('div', null, messageWrapper);

		this.selection		= createElement('div', 'secMapSelectedPointWrapper', this.graphRow);
		this.fSmall			= this.graphDiv.clientWidth < 920;
		this.innerWrapper	= createElement('div', 'secMapSelectedPointSubWrapper', this.fSmall? this.selectionSmall : this.selection);

		var stationData		= createElement('div', 'secMapSelectedPointRow', this.innerWrapper);
		var dataLabel 		= createElement('div', 'secMapSelectedPointColumn', stationData);
		var dataValue		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', stationData);
		var dataUnits		= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', stationData);

		createElement('div', 'secMapSelectedPointCell', dataLabel, 'Flow:');
		this.selectedFlow	= createElement('div', 'secMapSelectedPointCell', dataValue);
		createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = this.flowNode.getUnitsText();

		createElement('div', 'secMapSelectedPointCell', dataLabel, 'Specific Energy:');
		this.selectedSEC	= createElement('div', 'secMapSelectedPointCell', dataValue);
		createElement('div', 'secMapSelectedPointCell', dataUnits).innerHTML = this.secNode.getUnitsText();

		this.sucLabel		= createElement('div', 'secMapSelectedPointCell', dataLabel, 'Suction Pressure:');
		this.selectedSuc	= createElement('div', 'secMapSelectedPointCell', dataValue);
		this.sucUnits		= createElement('div', 'secMapSelectedPointCell', dataUnits, UnitsMap.get(pressureUnits).abbrev);

		var discharge		= this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		this.disLabel		= createElement('div', 'secMapSelectedPointCell', dataLabel, 'Discharge Pressure:');
		this.selectedDis	= createElement('div', 'secMapSelectedPointCell', dataValue);
		this.disUnits		= createElement('div', 'secMapSelectedPointCell', dataUnits, discharge ? discharge.getUnitsText() : '');

		// We create columns for the per-pump section
		var pumpData 		= createElement('div', 'secMapSelectedPointRow', this.innerWrapper);
		var pumpName 		= createElement('div', 'secMapSelectedPointColumn', pumpData);
		//var pumpFlow 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
		//var pumpFlowUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
		var pumpPower 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
		var pumpPowerUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
		var pumpSpeed 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedValue', pumpData);
		var pumpSpeedUnits	= createElement('div', 'secMapSelectedPointColumn secMapSelectedUnits', pumpData);
		var pumpFlags 		= createElement('div', 'secMapSelectedPointColumn secMapSelectedRemarks', pumpData);

		// Create a header row above the pump info
		createElement('div', 'secMapSelectedPointCell', pumpName);
		//createElement('div', 'secMapSelectedPointCell', pumpFlow, 'Flow');
		//createElement('div', 'secMapSelectedPointCell', pumpFlowUnits);
		createElement('div', 'secMapSelectedPointCell', pumpPower, 'Power');
		createElement('div', 'secMapSelectedPointCell', pumpPowerUnits);
		createElement('div', 'secMapSelectedPointCell', pumpSpeed, 'Freq.');
		createElement('div', 'secMapSelectedPointCell', pumpSpeedUnits);
		createElement('div', 'secMapSelectedPointCell', pumpFlags, 'Flow (' + this.qMin.getUnitsText() + ')');

		// Create and store the individual divs for pump data
		this.nameDivs = [], this.flowDivs = [], this.powerDivs = [], this.speedDivs = [], this.flagDivs = [];
		for (var i = 0; i < this.targetSpeeds.length; ++i) {
			this.nameDivs.push(createElement('div', 'secMapSelectedPointCell', pumpName, this.pumps[i].getDisplayName()));
			this.nameDivs[i].onclick = this.togglePump.bind(this, i);
			//this.flowDivs.push(createElement('div', 'secMapSelectedPointCell', pumpFlow));
			//createElement('div', 'secMapSelectedPointCell', pumpFlowUnits).innerHTML = this.flowNode.getUnitsText();
			this.powerDivs.push(createElement('div', 'secMapSelectedPointCell', pumpPower));
			createElement('div', 'secMapSelectedPointCell', pumpPowerUnits, UnitsMap.get(powerUnits).abbrev);
			this.speedDivs.push(createElement('div', 'secMapSelectedPointCell', pumpSpeed));
			createElement('div', 'secMapSelectedPointCell', pumpSpeedUnits, UnitsMap.get(speedUnits).abbrev);
			var porGraph = createElement('div', 'secMapSelectedPointCell', pumpFlags);
			this.flagDivs.push(new SquishedCurve(porGraph, this.pumps[i], modelPumps[i], {fUpdate:false}).initialize());
			//this.flagDivs.push(new PorGraph(porGraph, this.pumps[i], modelPumps[i], {width: porGraph.clientWidth, fUseNode: false, normalText: 'black', label: '%'}).initialize());
		}

		var isCurrentRow	= createElement('div', 'secMapSelectedPointRow', this.innerWrapper);
		var currentLabel 	= createElement('div', 'secMapSelectedPointColumn', isCurrentRow);
		var currentCheckBox	= createElement('div', 'se-checkbox', isCurrentRow);
		let currentId 		= createUniqueId()
		this.selectCurrent	= createElement('input', 'secMapSelectedPointCell', currentCheckBox, null, {'type':'checkbox', 'id':currentId});
		createElement('label', null, currentCheckBox, 'Current Operating Point', {'htmlFor':currentId})

		let idealCheckBox 	= createElement('div', 'se-checkbox', isCurrentRow);
		this.selectCurrent.onchange	= this.goToCurrentTarget.bind(this);
		let idealId = createUniqueId()
		this.selectIdeal			= createElement('input', 'secMapSelectedPointCell', idealCheckBox, null, {'type':'checkbox', 'id':idealId});
		createElement('label', null, idealCheckBox, 'Recommended Operating Point', {'htmlFor':idealId})
		this.selectIdeal.onchange	= this.goToIdealTarget.bind(this);

		this.selection.onmousedown	= (e) => this.processMouseEvent(e);
		this.selection.addEventListener('touchstart', this.selection.onmousedown);

		this.keyPressFunction	= this.onKeyPress.bind(this);	// We want to listen for the left and right arrow keys
		window.addEventListener('keydown', this.keyPressFunction);
		this.nodeManager.subscribe();

		this.jobCallback	= this.onJobCompleted.bind(this);
		owner.addJobCompletedCallback(this.jobCallback);	// Register for the callback

		this.fInitialized = true;
		return this;
	}

	findAllPumpNodes(name) {
		var nodes = [];
		for (var i = 0; i < this.pumps.length; ++i)
			nodes.push(this.nodeManager.addNodeByName(this.pumps[i], name));
		return nodes;
	}

	getConfigFromSpeeds(speeds) {
		var config = 0;
		for (var i = 0; i < speeds.length; ++i)
			if (speeds[i].getValue() > 0)
				config += (1 << i);
		return config;
	}

	updateCurrentPoint() {	// Called five times a second
		// SKUN-57, node.getValue() throws assert if ReadPermission is false
		if (this.graph == undefined || this.flowNode.quality != NodeQuality.NQ_GOOD || this.secNode.quality != NodeQuality.NQ_GOOD || !this.flowNode.hasReadPermission() || !this.secNode.hasReadPermission())
			return;

		if (this.flow == -1) {	// Firt time we've been through here, just take the initial value
			this.flow = this.flowNode.getValue();
			this.sec = this.secNode.getValue();
		} else {				// Every other time, 95% of the old value and 5% of the new
			this.flow = this.flow * 0.95 + this.flowNode.getValue()*0.05;
			this.sec = this.sec * 0.95 + this.secNode.getValue()*0.05;
		}

		var energyValue = this.energyMetric.getValue();

		this.updatePulsePoint(this.currentPulse, this.flow, this.sec);
	}

	hideValidOperationPoints(e) {
		for (var i = 0; i < this.actualRegimes.length; ++i) {
			var index = 3 + 4 * this.autoRegimes.length + 4*i;
			this.visible[index] = this.visible[index + 1] = this.visible[index + 2] = !this.visible[index];
		}
		this.changeVisibility(e);
	}

	hideDPOOperationPoints(e) {
		for (var i = 0; i < this.autoRegimes.length; ++i) {
			var index = 3 + 4*i;
			this.visible[index] = this.visible[index + 1] = this.visible[index + 2] = !this.visible[index];
		}
		this.changeVisibility(e);
	}

	hideCurrentSolution(e) {
		for (var i = 0; i < 2; ++i) {
			var index = this.labels.length - 2 + i;
			this.visible[index] = !this.visible[index];
		}
		this.changeVisibility(e);
	}

	hideDPOSolution(e) {
		for (var i = 0; i < 2; ++i) {
			var index = this.labels.length - 4 + i;
			this.visible[index] = !this.visible[index];
		}
		this.changeVisibility(e);
	}

	hideInvalid(e) {
		for (var i = 0; i < this.actualRegimes.length; ++i) {
			var index = 6 + 4 * this.autoRegimes.length + 4*i;
			this.visible[index] = !this.visible[index];
		}
		for (var i = 0; i < this.autoRegimes.length; ++i) {
			var index = 6 + 4*i;
			this.visible[index] = !this.visible[index];
		}
		this.changeVisibility(e);
	}

	changeVisibility(e) {
		this.fRedraw = true;
		this.onJobCompleted();
		e.currentTarget.classList.toggle('secMapCaptionClicked');
	}

	hideCurrentOperationPoint(e) {
		this.changePulseVisibility(e, this.currentPulse);
	}

	hideSelectedPoint(e) {
		this.changePulseVisibility(e, this.selectPulse);
	}

	hideConstraintLines(e) {
		this.lineWrapper.classList.toggle('collapse');
		e.currentTarget.classList.toggle('secMapCaptionClicked');
	}

	changePulseVisibility(e, pulse) {
		pulse.classList.toggle('hiddenSecMapSVGpulse');
		e.currentTarget.classList.toggle('secMapCaptionClicked');
	}

	updatePulsePoint(pulse, flow, sec) {
		if(this.graph.dygraph.axes_ == undefined) {return;} // SKUN-38, axes are null if the user is logged out
		var xAxisRange = this.graph.dygraph.xAxisRange();
		var yAxisRange = this.graph.dygraph.yAxisRange();
		if (flow < xAxisRange[0] || xAxisRange[1] < flow || sec < yAxisRange[0] || yAxisRange[1] < sec)	// Flow or SEC isn't in the graph area
			pulse.classList.add('collapse');
		else {
			pulse.classList.remove('collapse');	// Update the position of our pulser, which is a 14 by 14 circle
			pulse.style.left	= (this.graph.dygraph.toDomXCoord(flow) - 7) + 'px';
			pulse.style.top		= (this.graph.dygraph.toDomYCoord(sec) - 7) + 'px';
		}
	}

	onDraw(g, fInitial) {
		if (fInitial)
			return;

		this.updateCurrentPoint();
		this.highlightSelected();
	}

	pointClicked(e, point) {
		var regimeIndex = parseInt(point.name.substr(1));	// Get the index of the regime
		if (isNaN(regimeIndex))
			return;
		var letter = point.name.charAt(0);
		var regime = (letter === SpecificEnergyView.autoLetter) || (letter === SpecificEnergyView.autoOkayLetter) || (letter === SpecificEnergyView.autoGoodLetter) || letter === SpecificEnergyView.autoBadLetter ? this.autoRegimes[regimeIndex] : this.actualRegimes[regimeIndex];
		for (var i = 0; i < regime.length; ++i) {
			if (point.yval == regime[i].sec) {
				this.selectedAuto	= regime === this.autoRegimes[regimeIndex];
				this.selectedRegime	= regimeIndex;	// Update our selected indices
				this.selectedPoint	= i;
				this.highlightSelected();	// Highlight the clicked point
				if (this.fHiddenSelection)	// If we are hidden
					this.hideSelection();	// Unhide
				return;						// Done what we came to do
			}
		}
	}

	increaseSelectedPoint() {
		++this.selectedPoint;
		this.highlightSelected();	// Highlight the correct point
	}

	decreaseSelectedPoint() {
		--this.selectedPoint;
		this.highlightSelected();	// Highlight the correct point
	}

	togglePump(i) {
		if (this.selectedRegime & (1 << i))		// If the pump is on
			this.selectedRegime &= ~(1 << i);	// Clear the bit to turn it off
		else									// Pump is off
			this.selectedRegime |= (1 << i);	// Set the bit to turn it on
		this.highlightSelected();	// Highlight the correct point
	}

	onKeyPress(e) {
		if (this.graphDiv.clientHeight == 0)	// If we aren't on the displayed tab
			return;								// Leave. This way, graph doesn't change sizes if they go back to our tab

		var keyCode = typeof e.keyCode === 'number' ? e.keyCode : parseInt(e.keyCode);	// Depending on browser, key code can be a string or a number
		if (e.keyCode == 37) {			// Left arrow
			--this.selectedPoint;		// Go down a point within the regime
			this.highlightSelected();	// Highlight the correct line/point
		} else if (e.keyCode == 38) {	// Up arrow
			++this.selectedRegime;		// Go to a higher regime
			this.highlightSelected();	// Highlight the correct line/point
		} else if (e.keyCode == 39) {	// Right arrow
			++this.selectedPoint;		// Go up a point within the regime
			this.highlightSelected();	// Highlight the correct line/point
		} else if (e.keyCode == 40) {	// Down arrow
			--this.selectedRegime;		// Go to a lower regime
			this.highlightSelected();	// Highlight the correct line/point
		}
	}

	hideSelection() {
		this.fHiddenSelection = !this.fHiddenSelection;
		this.arrow.innerHTML = this.fHiddenSelection ? '&#9660' : '&#9650';
		this.selection.setAttribute('hide', this.fHiddenSelection);
	}

	processMouseEvent(evt) {		// 'this' is the selection wrapper
		if (!this.graph || !this.graph.dygraph) return;
		switch (evt.type) {
			case 'mousedown':
			case 'touchstart':
				this.selection.top		= parseFloat(this.selection.getCSS('top').slice(0, -2));
				this.selection.left		= parseFloat(this.selection.getCSS('left').slice(0, -2));
				this.selection.capture	= new MouseCapture(this.selection, evt);	// Capture the mouse
				this.selection.setAttribute('grabbed', true);
			break;

			case 'mousemove':
			case 'touchmove':
				this.selection.style.top	= Math.max(0, Math.min(this.selection.top + this.selection.capture.deltaY, this.selection.parentElement.clientHeight - this.selection.clientHeight)) + 'px';
				this.selection.style.left	= Math.max(0, Math.min(this.selection.left + this.selection.capture.deltaX, this.selection.parentElement.clientWidth - this.selection.clientWidth)) + 'px';
			break;

			case 'mouseup':
			case 'touchend':
				delete this.selection.capture;
				this.selection.setAttribute('grabbed', false);
			break;
		}
	}

	queryIdealPoint() {
		// The ideal point might not be in the full auto system if we are alternating. This is because DPO doesn't send points twice
		// (once for the auto system and actual system). So, check to see if the regime is in the auto regimes instead of assuming.
		var idealConfig = this.getConfigFromSpeeds(this.idealSpeeds);	// Get our config
		var fConfigInAuto = false;										// Assume config is in the actual regimes until found otherwise
		for (var i = 0; i < this.autoRegimes.length; ++i) {		// Check all fully auto configs
			if (this.autoRegimes[i].config == idealConfig) {	// If the config matches
				fConfigInAuto = true;							// The config really is in the auto system
				break;											// No need to look further
			}
		}
		this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, idealConfig, fConfigInAuto, this.idealSpeeds, this.convertHz, null);
	}

	updateMessagefunction() {	// Brain dump of the UberController
		if (this.nPumpsLimited === undefined)		// If we don't have the newest nodes, this method won't work right
			return;
		if (this.lastCurveCheck.getValue() == 0)	// No solved system yet
			return this.message.textContent = '';	// No message allowed
		if (!this.dpoInControl.getValue() && !this.fAdvisoryMode)	// If we aren't in advisory mode and not in control
			return this.message.textContent = 'The DPO is not in control of this pump system.'	// Say so

		var minCon, maxCon;
		for (var i = 0; i < this.constraintLines.length; ++i) {	// Check all the lines
			var line = this.constraintLines[i];		// Convenience reference to the line
			if (line.isPowerMeter)					// If the line is for power constraints
				continue;							// Skip it
			if (line.active && line.active.getValue()) {			// If the line if in control
				if (line.fMax)						// Save it as the min or max constraint
					maxCon = line;
				else
					minCon = line;
			}
			if (minCon !== undefined && maxCon !== undefined)	// If we have both
				break;											// Leave the loop
		}
		if (this.maintenance) {							// If we are an LSG with a maintenance cycle
			var state = this.maintenance.getValue();	// Get the state value
			// If we are in a maintenance state, we will set the message and leave
			if (state == 1) 							// Initialized FIXME: Factor out the state names
				return this.message.textContent = "Float test initialized."
			else if (state == 2)						// Filling
				return this.message.textContent = "Float test underway. Filling the wet well."
			else if (state == 3) 						// Draining
				return this.message.textContent = "Float test underway. Draining the wet well."
		}

		var message = '';								// Start the message off as blank
		if (!this.offHull.getValue())					// Not off the hull
			message += " Operating at minumum Specific Energy. ";	// We're on the convex hull
		else if (!this.noValidPoints.getValue())		// Not no valid points
			message += " Meeting all constraints. ";	// We're meeting constraints, but not on the hull
		else											// Operating outside constraints
			message += " Unable to meet all constraints. ";			// We're trying our best

		if (minCon) {									// Found a minimum constraint
			if (minCon.tankRole != null) {				// If the min constraint is the target tanks
				if (minCon.spNode.getValue() == 0)		// No flow requested
					message += this.fWetWell ? "Monitoring wet well level" : 'Target tanks are full';	// Say so
				else									// Flow requested
					message += this.fWetWell ? 'Draining wet well' : 'Filling target tanks';			// Say so
			} else {									// Not a tank as a min constraint
				message += 'Maintaining min ' + (minCon.pvNode.roles.has(Role.ROLE_TOTAL_FLOW) ? 'flow' : 'pressure');	// Say we are maintaining a flow/pressure constraint
				if (minCon.dbNode)						// If it is deadbanded
					message += ' setpoint';				// Call it a setpoint
			}
			if (maxCon && minCon.clamped.getValue()) {	// If we have a maximum and it's clamping the min constraint
				message += ', but limited by ';			// Say we are limited
				if (maxCon.tankRole != null)			// If it's the source tank
					message += 'source tanks. ';		// Say so
				else if (maxCon.pvNode.roles.has(Role.ROLE_TOTAL_FLOW))	// If it is flow based
					message += 'max flow. ';
				else
					message += 'max pressure. ';		// It must be pressure based
			} else										// Not being clamped
				message += '. ';						// Just let it breathe
		} else											// No minimum constraint
			message += 'No minimum constraints enabled. ';	// Say so

		if (this.odorContolled && this.odorContolled.getValue())	// If the odor control logic is running the show
			message += "Running to satisfy odor control logic. ";

		var hoas = [], startFails = [], stopFails = [], faults = [], prefs = [];	// To hold all the status bits
		this.fIssues = 0;	// Make of issues we need to talk about. Starts out empty
		for (var i = 0; i < this.targetSpeeds.length; ++i) {		// For each pump
			startFails.push(this.startFails[i] && this.startFails[i].getValue());			// Is any pump start failed
			stopFails.push(this.stopFails[i] && this.stopFails[i].getValue());			// Is any pump stop failed
			faults.push(this.faulted[i] && this.faulted[i].getValue());	// Is any pump faulted
			prefs.push(this.preferred[i].getValue());				// Is any pump being alternated
			// Here, we treat pumps that are being alternated as being in hand so they don't get swooped up in the autoStub below
			hoas.push(this.hoas[i].quality !== NodeQuality.NQ_GOOD ? undefined : prefs[i] ? 1 : this.hoas[i].getValue());	// Get the HOA, taking note if it is not communicating.
			var fRunning = this.targetSpeeds[i].getValue() > 0;		// Are we running it?
			var fWantToRun = this.idealSpeeds[i].getValue() > 0;	// Do we really want to run it?
			if (fRunning != fWantToRun)								// If the pump running doesn't match the ideal
				this.fIssues += (1 << i);							// Make a note of it
		}

		// TODO: Say if auto speeds are affecting us
		var wrapper = {message: message, autoStub: this.buildMessageStub(3, hoas)};			// Have to build this wrapper to pass the message and autoStub by reference
		this.printStub(wrapper, undefined, hoas, ' not commmunicating. ', false, true);		// First, check for pumps that are communicating
		this.printStub(wrapper, 1, stopFails, ' failed to stop. ', true, false, true);		// Second, check for stop fails
		this.printStub(wrapper, 1, startFails, ' failed to start. ', false, true, true);	// Third, check for start fails
		this.printStub(wrapper, 1, faults, ' faulted. ', false, true, true);				// Fourth, check for faults
		this.printStub(wrapper, 1, prefs, ' being exercised.', true, true);					// Fifth, check for alternation
		if (this.floatOverride && this.floatOverride.getValue())							// For LSGs, special case the float override logic
			wrapper.message += 'Float logic is in control of the station. ';
		else {	// Display HOA limiting us if the float isn't active
			this.printStub(wrapper, 1, hoas, ' in HAND. ', true);							// Sixth, check for pumps in hand. We gotta check the HOA stuff last
			this.printStub(wrapper, 2, hoas, ' in OFF. ', false, true);						// Seventh, check for pumps in off
		}
		message = wrapper.message;			// Pull the new message out of the wrapper

		if (this.fSecDeadband.getValue())			// If the SEC deadband is active. In the uber controller, SEC deadband trumps flow deadband
			message += "Currently in the Specific Energy deadband. ";
		else if (this.fFlowDeadband.getValue())		// If the flow deadband is active
			message += "Currently in the flow deadband. ";

		if (this.powerLimited.getValue())			// Power limit is taking hold
			message += "Limited by power constraints.";
		if (this.nPumpsLimited.getValue())			// Max pumps limit is changing the operating point
			message += "Limited by max pumps constraint. ";
		this.message.textContent = message;			// Set the message
	}

	printStub(wrapper, value, array, tailString, fRunning, fUseAuto, fSkipVerb) {	// Build up a message based on the array passed in.
		var stub = this.buildMessageStub(value, array);		// Find the names of pumps in this state
		if (stub.length > 0) {								// Found any pumps
			if (fUseAuto && wrapper.autoStub.length > 0) {	// If we want to add a clause about pumps running that are in HOA = AUTO
				wrapper.message += (fRunning ? "Not running" : "Running ") + wrapper.autoStub + ' because ' + stub;	// Tell which pumps we are running instead
				wrapper.autoStub = Role.ROLE_UNDEFINED;						// Clear out the auto stub so it isn't used again
			} else 											// Just wanna talk about these pumps OR we aren't running other pumps isntead
				wrapper.message += (fRunning ? "Running" : "Not running ") + stub + ' because ' + (stub.search(',') > 0 ? 'they' : 'it');	// Just say so
			if (!fSkipVerb)									// If they want a verb
				wrapper.message += (stub.search(',') > 0 ? ' are' : ' is');	// Add it in
			wrapper.message += tailString;					// Add the tail
		}
	}

	buildMessageStub(value, array) {	// List the names of pumps whose value in array matches the passed in value
		var stub = Role.ROLE_UNDEFINED;							// Start out with an empty string
		for (var j = 0; this.fIssues != 0 && j < this.targetSpeeds.length; ++j) {	// While there's discrepancies, check pumps
			if (this.fIssues & (1 << j) && array[j] === value) {	// If this pump has an issue and is in the target state
				if (stub.length > 0)			// If we already have a pump listed
					stub += ", ";				// Add a comma
				stub += this.pumps[j].name;		// Add in the pump name
				this.fIssues -= (1 << j);		// Mark this issue as resolved
			}
		}
		return stub;							// Return whatever string we ended up with
	}

	update(node) {
		switch(node) {
			case this.offHull:
			case this.noValidPoints:
			case this.fFlowDeadband:
			case this.fSecDeadband:
			case this.powerLimited:
			case this.nPumpsLimited:
			case this.dpoInControl:
				this.fUpdateMessage = true;	// Update our text dump
			break;

			case this.flowNode:
			case this.secNode:
			case this.energyMetric:
			break;	// Handled elsewhere

			case this.qMin:
			case this.qMax:
			case this.flowDeadband:
				var qMin = this.qMin.getValue();	// Get the control min and max flow
				var qMax = this.qMax.getValue();
				var qDb  = this.flowDeadband.getValue();
				/*
				if (qMax > this.flowNode.engMax)	// Clamp the max just increase because it can be something like 1.8e+308
					qMax = this.flowNode.engMax;
				this.rangeLabel.innerHTML = qMin.toFixed(this.flowNode.digits) + "-" + qMax.toFixed(this.flowNode.digits) + ' ' + node.getUnitsText();
				*/
				if (qDb < (qMax - qMin)) {
					this.flowBandXs[0] = this.flowBandXs[1] = qMin;
					this.flowBandXs[2] = this.flowBandXs[3] = qMin + qDb;
					qMin += qDb;
				} else
					this.flowBandXs[0] = this.flowBandXs[1] = this.flowBandXs[2] = this.flowBandXs[3] = 0;

				this.flowLimits[0] = this.flowLimits[1] = qMin;	// Store this min flow as both start points so we get a vertical line
				this.flowLimits[2] = this.flowLimits[3] = qMax;	// Store this max flow as both end points so we get a vertical line
				this.fRedraw = this.graph != undefined;
			break;

			case this.lastCurveCheck:	// If the last time the curves updated changed
				this.fModelReq = true;
				this.ldc.getRegimeCurves(this.graphID, node.tree.device.id);	// Time to query those new curves
			break;

			case this.secDeadband:
				this.updateSecDeadband();
			break;
			/*
			case this.tankOnline:
				this.modalBox.name.textContent = this.tankOnline.parent.getDisplayName() + (this.tankOnline.getValue() ? '' : ' -- Valved Off');
			break;
			*/

			default:
				if (node.row) {			// If this guys has a row displayed
					this.setOverWritten(node);
					this.fRedraw = true;		// Changed a line's enabled status
				} else if (this.targetSpeeds.indexOf(node) != -1)  {
					if (this.autoRegimes && this.actualRegimes)
						this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, this.getConfigFromSpeeds(this.targetSpeeds), false, this.targetSpeeds, this.convertHz, null);
					this.fUpdateMessage = true;
				} else if (this.idealSpeeds.indexOf(node) != -1) {
					if (this.autoRegimes && this.actualRegimes)
						this.queryIdealPoint();
					this.fUpdateMessage = true;
				} else if (this.hoas.indexOf(node) != -1 || this.startFails.indexOf(node) != -1 || this.stopFails.indexOf(node) != -1 || this.faulted.indexOf(node) != -1) {
					this.fUpdateMessage = true;
				} else if (!this.fRedraw && this.graph) {	// If we are drawing the graph, we have to move all the indicators anyway
					for (var i = 0; i < this.constraintLines.length; ++i) {	// Check each constraint line
						var line = this.constraintLines[i];					// Convenience reference
						if (node === line.spNode || node === line.fbNode || node === line.active || node === line.clamped) {	// If the enable or the setpoint changed
							this.fRedraw = true;		// Must redraw all lines in case we get into overlap issues
							break;
						}
					}
				}
			break;
		}
	}

	updateSecDeadband() {
		if (!this.actualRegimes)	// If we haven't gotten data yet
			return;				// No reason to update yet

		var targetConfig = this.getConfigFromSpeeds(this.targetSpeeds);
		var secDeadband = this.secDeadband.getValue();
		this.secBandXs.length = this.secBandYs.length = this.secBandMaxes.length = 0;
		var regimes = this.fAdvisoryMode ? this.autoRegimes : this.actualRegimes;
		for (var i = 0; i < regimes.length; ++i) {	// Check all the regimes we have
			var regime = regimes[i];
			if (regime.config == targetConfig) {			// Found the correct regime
				for (var j = 0; j < regime.length; ++j) {	// Extend a shaded region below each point
					if (regime[j].flag != 0)
						continue;
					this.secBandXs.push(regime[j].flow);
					this.secBandYs.push(regime[j].sec - secDeadband);
					this.secBandMaxes.push(regime[j].sec);
				}
				if (this.secBandXs.length == 1)
					this.secBandXs.length = this.secBandYs.length = this.secBandMaxes.length = 0;

				this.fRedraw = true;	// Need to redraw the graph
				return;					// Found the correct regime, no need to look farther
			}
		}
	}

	onRegimeCurvesResponse(fp) {
		this.fModelReq		= false;
		var oldVis = this.visible.length > 3 ? this.visible.slice(-4) : [true, true, true, true]; // If we have old visibilities, get the last five elements of the array
		var fShowAuto 			= this.visible.length > 3 ? this.visible[3] : true;
		var fShowAutoInvalid	= this.visible.length > 3 ? this.visible[6] : true;
		var fShowActual 		= this.visible.length > 3 ? this.visible[3 + 4 * this.autoRegimes.length] : true;
		var fShowActualInvalid	= this.visible.length > 3 ? this.visible[6 + 4 * this.autoRegimes.length] : true;
		this.labels.length	= this.colors.length = this.visible.length = 3;
		this.limitedFlows.length = this.limitedSECs.length = 0;
		this.data			= [	this.labels, this.flowLimits, null, [0, 0, 0, 0], this.SECMaxes,	// Background of min and max flow
		         			  	this.flowBandXs, null, [0, 0, 0, 0], this.SECMaxes,
								this.secBandXs, null, this.secBandYs, this.secBandMaxes];

		// We use this trick because it is impossible to pass a javascript number by reference
		this.limits = {minFlow: Number.MAX_VALUE, maxFlow: 0, minSEC: Number.MAX_VALUE, maxSEC: 0};	// To hold overall statistics on data ranges

		this.actualRegimes	= [];	// This will hold our big build of of data for each regime we have
		this.autoRegimes	= [];
		var actHullFlows	= [], actHullSECs	= [];
		var autoHullFlows 	= [], autoHullSECs	= [];

		this.extractModelSystem(fp, this.autoRegimes, autoHullFlows, autoHullSECs, this.fAdvisoryMode ? 'green' : 'lightgreen', false, fShowAuto, fShowAutoInvalid);
		this.extractModelSystem(fp, this.actualRegimes, actHullFlows, actHullSECs, 'green', true, fShowActual, fShowActualInvalid);

		this.labels.push('Limited');
		this.colors.push('#C12727');
		this.visible.push(true);
		this.data.push(this.limitedFlows, null, this.limitedSECs, null);

		var actLineFlows = [], actLineSECs = [], autoLineFlows = [], autoLineSECs = [];
		if (this.actualRegimes.length > 0) {	// If the model was solving, it is possible we got nothing back
			// Interpolate lines for all of the convex hulls (which aren't straight)
			if (this.limits.minFlow == Number.MAX_VALUE && this.limits.minSEC == Number.MAX_VALUE) {
				this.limits.minFlow = this.limits.minSEC = 0;
				this.limits.maxFlow = this.limits.maxSEC = 5;
			}

			if (this.limits.maxFlow == this.limits.minFlow) {
				if (!isNaN(this.flow)) {
					this.limits.minFlow = Math.min(this.flow * 0.95, this.limits.minFlow * 0.95);
					this.limits.maxFlow = Math.max(this.flow * 1.05, this.limits.maxFlow * 1.05);
				}
				else  {
					this.limits.minFlow *= 0.95
					this.limits.maxFlow *= 1.05
				}
			}

			if (this.limits.maxSEC == this.limits.minSEC) {
				if (!isNaN(this.sec)) {
					this.limits.minSEC = Math.min(this.sec * 0.95, this.limits.minSEC * 0.95);
					this.limits.maxSEC = Math.max(this.sec * 1.05, this.limits.maxSEC * 1.05);
				}
				else  {
					this.limits.minSEC *= 0.95
					this.limits.maxSEC *= 1.05
				}
			}

			var flowPerPixel		= 5*(this.limits.maxFlow-this.limits.minFlow)/this.graphDiv.clientWidth;	// Calculate this so we can put a point each five pixels
			interpolateSECs(flowPerPixel, actHullFlows, actHullSECs, actLineFlows, actLineSECs);
			interpolateSECs(flowPerPixel, autoHullFlows, autoHullSECs, autoLineFlows, autoLineSECs);

			var xAxisBuffer			= (this.limits.maxFlow - this.limits.minFlow) * .05;						// Calculate 5% of the plotted flow range
			this.options.dateWindow	= [this.limits.minFlow - xAxisBuffer, this.limits.maxFlow + xAxisBuffer];	// Give a 5% buffer on each side

			var yAxisBuffer 		= (this.limits.maxSEC - this.limits.minSEC) * .1;							// Calculate 10% of the plotted SEC range
			this.options.valueRange	= [this.limits.minSEC - yAxisBuffer, this.limits.maxSEC + yAxisBuffer];		// Give a 10% buffer on each side

			this.SECMaxes[1]		= this.SECMaxes[2] = this.options.valueRange[1];	// Give the fill curve the maximum value so it fills the whole area

			this.labels.push('AutoHull', 'AutoLine', 'ActHull', 'ActLine');	// Add this way so the actual hull on top of the auto hull
			var dpoHullColor = this.fAdvisoryMode ? 'black' : 'gray';
			this.colors.push(dpoHullColor, dpoHullColor, 'black', 'black');
			this.visible.push(oldVis[0], oldVis[1], oldVis[2], oldVis[3]);	// If the hid the line, don't reshow it all of the sudden
			this.data.push(	autoHullFlows,	null, autoHullSECs,		null,
				autoLineFlows,	null, autoLineSECs,		null,
				actHullFlows,	null, actHullSECs,		null,
				actLineFlows,	null, actLineSECs,		null);
			this.updateSecDeadband();

			this.fRedraw = true;		// Need to redraw
			this.onJobCompleted();		// Redraw the graph
			this.highlightSelected();	// Make sure our SVG pulse point is in a good place
			this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, true, this.getConfigFromSpeeds(this.targetSpeeds), false, this.targetSpeeds, this.convertHz, null);
			this.queryIdealPoint();
		}
	}

	extractModelSystem(fp, regimes, secFlows, secSECs, color, fActual, fVisible, fShowInvalid) {
		var secConversion = convert(16666.67, TagUnit.TU_GPM, this.flowNode.units)/this.convertKW*this.convertSEC;	// Term to calculate SEC in kWh/MG
		var lineLetter = fActual ? SpecificEnergyView.actualLineLetter	: SpecificEnergyView.autoLineLetter;
		var goodletter = fActual ? SpecificEnergyView.actualLetter		: SpecificEnergyView.autoLetter;
		var okayletter = fActual ? SpecificEnergyView.actualOkayLetter	: SpecificEnergyView.autoOkayLetter;
		var badletter  = fActual ? SpecificEnergyView.actualBadLetter	: SpecificEnergyView.autoBadLetter;

		var regimeCount = fp.pop_u16();				// Count of regimes attached
		for (var i = 0; i < regimeCount; ++i) {		// For each regime they attached
			var regFlows = [], regSECs = [], goodSECs = [], okaySECs = [], badSECs = [];		// These arrays will hold the graph data for this regimes

			var regime = [];						// This will hold all the points for one regime
			regimes.push(regime);					// Add it to the big glut of regimes

			var config = fp.pop_u16();				// Pumps that are on in this regime set
			regime.config = config;					// Store the config on the regime for convenience

			var nPumps = config - ((config >> 1) & 0x55555555);	// Very fast bit count (for up to 32 bits)
			nPumps = (nPumps & 0x33333333) + ((nPumps >> 2) & 0x33333333)
			nPumps = ((nPumps + (nPumps >> 4) & 0xF0F0F0F) * 0x1010101) >> 24;

			this.labels.push(lineLetter + i, goodletter + i, okayletter + i, badletter + i);		// Add a label for this guy
			var c = fp.pop_u8() ? color : 'purple';
			if (this.nPumps && nPumps > this.nPumps.getValue())
				c = '#128a68';
			this.colors.push(c, c, '#ecc900', '#ff2828');	// Allowed or not allowed in the convex hull
			this.visible.push(fVisible, fVisible, fVisible, fShowInvalid);		// Add a visibility indicator for this regime
			this.data.push(regFlows, null, regSECs, null, regFlows, null, goodSECs, null, regFlows, null, okaySECs, null, regFlows, null, badSECs, null);
			this.options[lineLetter + i] = this.lineOptions;

			var pointCount = fp.pop_u16();			// Points for this regime
			for (var j = 0; j < pointCount; ++j) {	// For each regime point
				var point = {flow: this.flowNode.convertFromCacheToDisplay(fp.pop_f32())}; // To sum the point data
				var power = fp.pop_f32() * this.convertKW;	// Point total power
				point.flag = fp.pop_u8();
				point.sec = point.flow > 0 ? secConversion * power / point.flow : 0;
				regime.push(point);				// Add the point to this regime
				checkLimits(this.limits, point.flow, point.sec);

				regFlows.push(point.flow);			// Add the totals to the arrays
				regSECs.push(point.sec);
				if (point.flag == 2) {			// If it was just in AOR
					goodSECs.push(null);
					okaySECs.push(point.sec);	// Add yellow to this point
					badSECs.push(null);
				} else if (point.flag > 0) {	// If any of the pumps had some problem
					goodSECs.push(null);
					okaySECs.push(null);
					badSECs.push(point.sec);	// Add red to this point
				} else {
					goodSECs.push(point.sec);
					okaySECs.push(null);
					badSECs.push(null);
				}
			}
		}

		this.limiting.findPowerLimits(regimes, this.limitedFlows, this.limitedSECs);

		var points = fp.pop_u16();			// Count of points in the actual convex hull
		for (var i = 0; i < points; ++i) {	// For each point in the actual convex hull
			var flow	= this.flowNode.convertFromCacheToDisplay(fp.pop_f32());		// Get the flow
			var power	= fp.pop_f32();
			secFlows.push(flow);		// Store the values
			secSECs.push(flow > 0 ? secConversion * power/ flow : 0);	// Turn the power into an SEC in kWh
		}
	}

	highlightSelected() {	// Make the selected regime and the selected point different colors
		var regimes = this.selectedAuto	? this.autoRegimes : this.actualRegimes;

		if (!regimes || regimes.length == 0)	// Might not have data yet
			return;

		this.selectedRegime	= Math.max(0,Math.min(this.selectedRegime, regimes.length - 1));	// Limit to valid indicies
		var regime = regimes[this.selectedRegime];
		this.selectedPoint	= Math.max(0,Math.min(this.selectedPoint, regime.length - 1));		// Limit to valid indicies

		var point = regime[this.selectedPoint];
		if (this.graph)		// If we have drawn a graph
			this.updatePulsePoint(this.selectPulse, point.flow, point.sec);	// Update the pulse point

		if (point.fReq)							// If we already have the data on this point
			this.updatePointDetails(point);		// Update the point display
		else if(!this.fModelReq){				// If there's not a model request outstanding, request the data
			point.fReq = true;					// Make a note we are requesting this data
			this.ldc.getPointData(this.graphID, this.qMin.tree.device.id, false, regime.config, this.selectedAuto, null, null, this.selectedPoint);	// Ask for it
		}
	}

	onPointResponse(fp) {
		if (!this.autoRegimes && !this.actualRegimes) {	// If don't have data
			fp.skip(fp.size);							// Don't respond to data
			return;
		}

		var fAuto	= fp.pop_u8();	// If the point they want is in the auto system
		var regime	= fp.pop_u16();	// Regime for this point
		var index	= fp.pop_u16();	// Index of the point they want

		var regimes = fAuto	? this.autoRegimes : this.actualRegimes;	// Get the right set of curves based on flag
		for (var i = 0; i < regimes.length; ++i) {	// Check each regime
			if (regimes[i].config != regime)	// If this is the right regime (they are in order, but potentially sparse)
				continue;
			if (index >= regimes[i].length)
				break;

			var point = regimes[i][index];		// Get a reference to the point
			point.flows = [], point.powers = [], point.speeds = [], point.flags = [];	// Build up data arrays for it
			for (var j = 0; j < this.targetSpeeds.length; ++j) {	// For each pump
				if (regime & (1 << j)) {			// If the pump was on
					point.flags.push(fp.pop_u16());	// Get flags
					point.flows.push(this.flowNode.convertFromCacheToDisplay(fp.pop_f32()));	// Get flow and convert to correct units
					point.powers.push(fp.pop_f32()*this.convertKW);					// Get power and convert to correct units
					point.speeds.push(fp.pop_f32()*this.convertHz);					// Get speed and convert to correct units
				} else {							// The pump is off
					point.flags.push(0);			// And its data is uninteresting
					point.flows.push(0);
					point.powers.push(0);
					point.speeds.push(0);
				}
			}
			if (fp.pop_u8())	// If there's a suction pressure
				point.suction = fp.pop_f32();	// Grab it
			if (fp.pop_u8())	// If there's a discharge pressure
				point.discharge = fp.pop_f32();	// Grab it
			if (fAuto == this.selectedAuto && i == this.selectedRegime && this.selectedPoint == index)	// If they are still selecting this point
				this.updatePointDetails(point);	// Display the data
			return;	// Found our point
		}
		fp.skip(fp.size());	// Skip the data
	}

	updatePointDetails(point) {
		this.selectedFlow.textContent	= point.flow.toFixed(this.flowNode.digits);
		this.selectedSEC.textContent	= point.sec.toFixed(this.secNode.digits);

		if (point.suction != undefined)
			this.selectedSuc.textContent = point.suction.toFixed(1) ;
		else {
			this.sucLabel.setAttribute('hide', true);
			this.selectedSuc.setAttribute('hide', true);
			this.sucUnits.setAttribute('hide', true);
		}

		if (point.discharge != undefined)
			this.selectedDis.textContent = point.discharge.toFixed(1);
		else {
			this.disLabel.setAttribute('hide', true);
			this.selectedDis.setAttribute('hide', true);
			this.disUnits.setAttribute('hide', true);
		}

		if (point.speeds != undefined) {
			for (var i = 0; i < this.targetSpeeds.length; ++i) {
				this.nameDivs[i].setAttribute('running', point.speeds[i] > 0);
				//this.flowDivs[i].textContent = point.flows[i].toFixed(this.flowNode.digits);
				this.powerDivs[i].textContent = (this.limiting.basePowers[i] + point.powers[i]).toFixed(1);
				this.speedDivs[i].textContent = point.speeds[i].toFixed(1);
                this.flagDivs[i].setFlow(point.flows[i], point.speeds[i]);
				this.powerDivs[i].classList.toggle('pumpOverPower', point.flags[i] & LiveData.MSS_OVER_POWER_LIMIT);
				//this.flowDivs[i].classList.toggle('pumpOverPower', point.flags[i] & LiveData.MSS_UNDER_NPSHR);	// FIXME: Gotta be a better way to show this
				this.speedDivs[i].classList.toggle('pumpOverPower', point.flags[i] & LiveData.MSS_LOCKED_OUT);
			}
		}
		this.checkSelection();
	}

	checkSelection() {
		if (!this.graph)	// If we haven't drawn a graph
			return;			// Nothing to check

		// We are at the current point if the config is right and all the speeds match
		var regime = this.selectedAuto ? this.autoRegimes[this.selectedRegime] : this.actualRegimes[this.selectedRegime];
		this.selectCurrent.checked	= (this.getConfigFromSpeeds(this.targetSpeeds) == regime.config) && this.pointSpeedsMatchReference(regime.config, regime[this.selectedPoint], this.targetSpeeds);
		this.selectIdeal.checked	= (this.getConfigFromSpeeds(this.idealSpeeds)  == regime.config) && this.pointSpeedsMatchReference(regime.config, regime[this.selectedPoint], this.idealSpeeds);
	}

	goToCurrentTarget() {
		this.goToTarget(this.selectCurrent, this.actualRegimes, this.targetSpeeds);
	}

	goToIdealTarget() {
		if (!this.goToTarget(this.selectIdeal, this.autoRegimes, this.idealSpeeds))
			this.goToTarget(this.selectIdeal, this.actualRegimes, this.idealSpeeds);
	}

	goToTarget(checkbox, regimes, speeds) {
		if (!this.graph)	// If we haven't drawn a graph
			return;			// Nothing to check

		var config = this.getConfigFromSpeeds(speeds);
		var closestRegime = -1;
		var closestPoint = -1;
		var totalError = 100E6;
		for (var i = 0; i < regimes.length; ++i) {	// Check all the actual regimes we have
			var regime = regimes[i];
			if (regime.config == config) {			// Found the correct regime
				for (var j = 0; j < regime.length; ++j) {
					if (regime[j].speeds) {	// If this point's speeds match the reference speeds
						var error = this.pointSpeedsClose(regime.config, regime[j], speeds);
						if (error < totalError) {
							closestRegime = i;
							closestPoint = j;
							totalError = error;
							if (error < 0.005)
								break;
						}
					}
				}
				break;	// Found the correct regime, no need to look farther
			}
		}
		if (closestRegime >= 0) {
			this.selectedAuto       = regimes === this.autoRegimes;
			this.selectedRegime     = closestRegime;    // Update our selected point variables
			this.selectedPoint      = closestPoint;
			this.highlightSelected();      			// Update the display division
			checkbox.checked = true;
			return true;                            // Found our point and updated. Now we just leave
		}
		checkbox.checked = false;	// If we made it this far, we didn't find our current point. Uncheck the box
		return false;
	}

	pointSpeedsClose(config, point, speeds) {
		if (!point.speeds)
		    return;
		var totalError = 0;
		for (var i = 0; i < speeds.length; ++i) {
		    if (speeds[i])
		        totalError += Math.abs(speeds[i].getValue() - point.speeds[i]); // This pump's speed doesn't match. Don't check the rest of the speeds.
		}
		return totalError;                      // All the speeds match
    }

	pointSpeedsMatchReference(config, point, speeds) {
		if (!point.speeds)
			return;
		for (var i = 0; i < speeds.length; ++i) {
			if (speeds[i] && Math.abs(speeds[i].getValue() - point.speeds[i]) > 0.05)
				return false;	// This pump's speed doesn't match. Don't check the rest of the speeds.
		}
		return true;			// All the speeds match
	}

	onTimer() {	// This is called one a one-second timer to update the solution age
		if(this.lastCurveCheck.hasReadPermission())	// SKUN-57, added a check to prevent asserts when already logged out but timer still running
			this.ageLabel.textContent = ((Date.now() - this.lastCurveCheck.getValue()/1000)/60000).toFixed(1) + " min";
	}

	static activeClassname      = 'hide';
	static clampedClassname     = 'secMapSVGlineClamped';
	static disabledClassname    = 'hide';

	createGraphLines(nodes) {
		for (var i = 0; i < nodes.length; ++i)
			this.createGraphLine(nodes[i], nodes[i].roles.has(Role.ROLE_TLC_CONSTRAINT_MAX), null);
	}

	createGraphLine(constraint, fMax, tankRole) {
		if (!constraint)
			return;

		var line = createSVGElement('g', null, this.lineWrapper);					// Start out collapsed in case there's not an SEC map yet
		line.isPowerMeter = constraint.roles.has(Role.ROLE_TLC_POWER_METER);
		line.child 		= createSVGElement(line.isPowerMeter ? 'path' : 'line', 'secMapSVGPowerLine', line);
		line.spNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);		// Set point node
		line.fbNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK);	// Feedback node (if it exists)
		line.enable		= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);	// Enabled node
		line.dbNode 	= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_DB);		// deadband node
		var pvNode		= constraint.findChildByRole(Role.ROLE_TLC_CONSTRAINT_PV);		// Process variable
		line.pvNode 	= pvNode && pvNode.sourceID != undefined ? pvNode.tree.getNode(pvNode.sourceID) : pvNode;	// Get the source node if there is one
		line.start 		= constraint.findChild('Start');
		line.stop 		= constraint.findChild('Stop');
		line.active 	= constraint.findChild('Active');
		line.clamped 	= constraint.findChild('Clamped');
		line.tankRole 	= tankRole;
		line.name = Localization.toLocal(constraint.getDisplayName());
		line.label = createSVGElement('text', 'secMapSVGlabel', line, {'text-anchor': 'end', 'dominant-baseline' : 'hanging', 'font-size':'0.75em'});
		line.label.onclick = line.label.ontouch = () => location.hash = getHash(...getRouteAndProperties(location.hash, {'tab':'Controls'}));

		line.fMax = fMax;
		line.label.updateLabel(0, 0, line.name);
		line.conversion = line.isPowerMeter ? 1 : convert(1, (line.fbNode || line.spNode).units, this.qMin.units);

		this.nodeManager.addNode(line.spNode);
		this.nodeManager.addNode(line.fbNode);
//		this.nodeManager.addNode(line.enabled); We're already subscribed to this node
		this.nodeManager.addNode(line.active);
		this.nodeManager.addNode(line.clamped);
		this.constraintLines.push(line);
	}

	updateGraphLines() {
		var xAxisRange 	= this.graph.dygraph.xAxisRange();	// Plotted x axis range
		var y1 			= this.graph.dygraph.toDomYCoord(this.graph.dygraph.yAxisRange()[0])+3;	// Bottom of the graph in pixels
		var minX = this.graph.dygraph.toDomXCoord(xAxisRange[0]);	// Left edge of the graph in pixels
		var maxX = this.graph.dygraph.toDomXCoord(xAxisRange[1]);	// Right edge of the graph in pixels
		for (var i = 0; i < this.constraintLines.length; ++i) {	// For each line
			var line = this.constraintLines[i];
			var sp = (line.fbNode || line.spNode).getValue()*line.conversion;	// Get the setpoint node

			var text = line.name + ': ' + (line.fbNode? line.spNode.getFormattedText(true)  + ', ' + line.fbNode.getFormattedText(true) : line.spNode.getFormattedText(true)); // Make a copy of the name so we can modify it
			line.label.updateLabel(0,0, text);
			var width = line.label.textLength.baseVal.value;	// Get the width of the label

			if (line.isPowerMeter && this.options.dateWindow) {	// We put power nodes first becasue they are the hardest to position the names of (moing down would need to shift it laterally)
				var limit  = sp - this.totalBase;	// Get the current limit -- including base load which is taken off the top
				var deltaQ = (this.options.dateWindow[1] - this.options.dateWindow[0]) / 50;	// Our unit for flow change for this calculation
				var toMGD = convert(1, this.flowNode.units, TagUnit.TU_MGD);			// Calculate change from flow units to MGD once
				var deltaX = this.graph.dygraph.toDomXCoord(this.options.dateWindow[0] + deltaQ) - this.graph.dygraph.toDomXCoord(this.options.dateWindow[0]);	// Delta x between point
				sp = 24 * limit / this.options.valueRange[1] / toMGD;	// Calculate where our label goes (and where our line starts) at the top of the y axis
				var path = 'M 0 0 ';	// Start our path
				var fExit = false;		// True if we need to bail out
				for (var q = sp + deltaQ, offset = 0; q - this.options.dateWindow[1] < deltaQ; q += deltaQ) {	// q is in flowNode.units
					var sec = 24 * limit / q / toMGD;	// This is kWh/MG
					if (sec <= this.options.valueRange[0]) {
						sec = this.options.valueRange[0];
						var newQ = 24 * limit / sec / toMGD;
						deltaX *= (deltaQ - (q - newQ)) / deltaQ;
						fExit = true;
					}
					offset += deltaX;
					path += 'L ' + offset + " " + this.graph.dygraph.toDomYCoord(sec * this.convertSEC);	// Convert SEC to target units and make a line
					if (fExit)
						break;
				}
				line.child.setAttribute('d', path);		// Set the path on the element
			}
			var x = this.graph.dygraph.toDomXCoord(sp);			// Get the sp position in pixels
			line.child.classList.toggle('collapse',  sp < xAxisRange[0] || xAxisRange[1] < sp);	// Hide the line if it's not on the graph
			var xShift = line.fMax ? 0 : -20;	// If we're flipped, translate the line so the arrow is pushing on the line
			var yShift = line.fMax ? -(this.graphDiv.clientHeight) + width + 40 : -20;
			line.label.updateLabel(yShift, xShift, text);					// Update the label
			x = Math.max(minX, Math.min(maxX, x));	// Calculate clamped position
			if (!isNaN(x))
				line.translate(x, 0);										// Move to clamped position
			if (line.active) {
				if (line.active.getValue())
					line.classList.remove('hide')	// Show red if we're active
				else line.classList.add('hide')
			}
			line.child.updateLine(0, y1, 0, 4);										// Rescale line so we stretch the full graph height
			line.left = (line.fMax ^ line.fFlipped ? x : x - width) + xShift;		// Calculate the left most edge of text for positioning
			line.right = (line.fMax ^ line.fFlipped ? x + width : x) + xShift;		// Calculate the right most edge of text for positioning
		}
	}

	onJobCompleted() {
		if (!this.fRedraw || !this.autoRegimes || !this.fInitialized)	// If we don't need to redraw the graph
			return;								// Just leave
		this.graphHeight 	= this.graphDiv.clientHeight;
		this.graphWidth 	= this.graphDiv.clientWidth;

		if (this.fUpdatePower) {				// If the power stuff changed
			this.limitedFlows.length = this.limitedSECs.length = 0;	// Remove any old plotted points
			this.limiting.findPowerLimits(this.autoRegimes, this.limitedFlows, this.limitedSECs);	// Find the new limited points
			this.limiting.findPowerLimits(this.actualRegimes, this.limitedFlows, this.limitedSECs);
			this.fUpdatePower = false;			// Done updating the power stuff
		}
		if (this.fUpdateMessage) {				// If we need to update our human readable message
			this.updateMessagefunction();		// Do it
			this.fUpdateMessage = false;		// And say we done did it
		}
		if (this.graph)							// If we've drawn a graph before
			this.graph.destroy();				// Delete it first

		// Create a new graph to plot our points
		this.graph = new StaticGraph(this.ldc, this.graphDiv, this.graphWidth, this.graphHeight, this.data, this.options, false);
		//this.lineWrapper.setAttribute('width', this.graphDiv.clientWidth);
		this.updateGraphLines();

		this.fRedraw = false;		// Don't need to redraw the graph anymore
	}

	resize() {			// This is called when the page is reloaded
		this.pumpBank.resize();
		this.graphHeight 	= this.graphDiv.clientHeight;
		this.graphWidth 	= this.graphDiv.clientWidth;
		if (this.fSmall != this.graphDiv.clientWidth < 920) {
			if (this.fSmall) {
				this.selection.appendChild(this.innerWrapper);
				this.selectionSmall.removeChildren();
			}
			else {
				this.selectionSmall.appendChild(this.innerWrapper);
				this.selection.removeChildren();
			}
			this.fSmall = !this.fSmall;
			if (this.fLandscape != window.innerHeight < 420) {
				this.fLandscape = !this.fLandscape;
				this.pumpBank.destroy();
				this.pumpBank = new PumpBank(this.pumpSystem, this.fLandscape? this.pumpTable : this.pumpBankWrapper, this.dpoFolder);
			}
		}
		this.fRedraw = true;
		this.onJobCompleted();		// Tell the graph to quickly realign itself
		this.highlightSelected();	// Make sure our SVG pulse point is in a good place
	}

	destroy() {
		window.removeEventListener('keydown', this.keyPressFunction);
		if (this.nodeManager) {	// Initialize might not have been called and this wouldn't exist
			this.nodeManager.destroy();		// Unsubscribe to all the nodes we subscribed to
			for (var i = 0; i < this.globalEnables.length; ++i)		// Unsubscribe to all global enabled nodes
				this.globalEnables[i].unsubscribe(this);
			if(this.tankOnline)
				this.tankOnline.unsubscribe(this);
			this.ldc.unregisterGraph(this.graphID);
			owner.removeJobCompletedCallback(this.jobCallback);
			clearInterval(this.timerId);
			clearInterval(this.filterID);
			delete this.selection.capture;
			this.limiting.destroy();
		}
		this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over

		for(var key in this)	// Delete our created members
			delete this[key];	// Remove all of our constraint member variables

	}

	powerLimitsUpdated() {	// Called by the power limiting helper when something changes
		this.fUpdatePower	= true;		// Time to update the power onJobComplete
		this.fRedraw		= true;		// And time to redraw the graph
	}

	subscribeToEnableNodes(folder) {
		if (!folder)	// No folder?
			return;		// no problem
		for (var i = 0; i < folder.children.length; ++i) {				// For each constraint folder
			var enabledNode = folder.children[i].findChild('Enabled') || folder.children[i].findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);	// Find the enabled node
			enabledNode.subscribe(this);								// Subscribe to the node
			this.globalEnables.push(enabledNode);						// Add it to our vector of enabled nodes
		}
	}

	fillByFolder(div, period, role) {
		var folder	= period.findChildByRole(role);		// Find the folder, if it exists
		div.nodes	= folder ? folder.children : [];	// Store the nodes
		this.fillSection(div);							// Fill with life
	}

	checkRole(node, pvRole) {
		var pv = node.findChildByRole(pvRole);				// The process variable alias node
		var roles = node.tree.nodes[pv.sourceID].roles;		// Find the role of the source node, which will tell us what this constrains

		if (roles.has(Role.ROLE_TOTAL_FLOW))				// Flow constraint
			this.flowNodes.push(node);
		else if (roles.has(Role.ROLE_DISCHARGE_PRESSURE))	// Discharge pressure constraint
			this.dischargeNodes.push(node);
		else
			this.suctionNodes.push(node);				// Suction pressure constraint
	}

	setOverWritten(node) {
		node.row.setAttribute('overwritten', this.index != -1 && node.getValue() > 0);	// True if active, but overwritten by the global
		node.row.setAttribute('global', this.index == -1 && node.getValue() > 0);		// True if active and the global
	}

	static actualLineLetter = 'Q';
	static actualLetter     = 'R';
	static actualOkayLetter = 'S';
	static actualBadLetter  = 'T';
	static autoLineLetter   = 'C';
	static autoLetter       = 'D';
	static autoOkayLetter   = 'E';
	static autoBadLetter    = 'F';
};

// Helper class that keeps track any power limiting in place
class PowerLimiting {
    constructor(graph, dpoFolder, basePowers) {
        this.meters			= [];
        this.basePowers		= basePowers;
        var powerMeters 	= dpoFolder.findChild('MeterDemands');
        if (powerMeters) {		// If we have any power meters
            var meters		= dpoFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER);

            for (var j = 0; j < meters.children.length; ++j) {
                this.meters.push({	pumps:			meters.children[j].findChild("Pumps").getValue(),	// This is a final value
                                    enabledNode:	powerMeters.children[j].findChild("LimitEnabled"),	// Find the enabled node for the power meter
                                    limitNode:		powerMeters.children[j].findChild("DemandLimit")});	// Find the limit node for the power meter
                this.meters[j].enabledNode.subscribe(this);	// Subscribe to the meter's nodes
                this.meters[j].limitNode.subscribe(this);
            }
        }
        this.graph = graph;	// Save a reference to the graph that created us. We do this last to block calls in the update method
    };

	update() {
		if (!this.graph)	// No graph when we subscribe to the nodes
			return;
		this.graph.powerLimitsUpdated();	// Tell the graph something changed
	}

	findPowerLimits(regimes, limitedFlows, limitedSECs) {	// Parse regimes and add flows and SECs to the two arrays
		for (var j = 0; j < this.meters.length; ++j) {
			if (this.meters[j].enabledNode.getValue())				// This meter is active. Use it for power limiting
				this.checkPower(this.meters[j], regimes, limitedFlows, limitedSECs);
		}
	}

	checkPower(meter, regimes, limitedFlows, limitedSECs) {
		var pumps = meter.pumps;				// Get the pumps this meter cares about
		var limit = meter.limitNode.getValue();	// Get the limited value of the ndoe
		for (var k = 0; k < regimes.length; ++k) {	// For each regime
			var points = regimes[k];
			for (var l = 0; l < points.length; ++l) {	// For each point in the regime
				var meterPower = this.graph.flowNode.convertFromCacheToDisplay(points[l].flow) * points[l].sec * 60 / 1000000;	// FIXME: This doesn't work for multiple meters
				for (var m = 0; m < this.basePowers.length; ++m) {	// For each power
					if ((pumps & (1 << m)) > 0)				// If the meter cares about this pump
						meterPower += this.basePowers[m];	// Add in its power and its base load power
				}
				if (meterPower > limit) {				// If the total power is over the limit
					limitedFlows.push(points[l].flow);	// Add the flow and SEC to the array
					limitedSECs.push(points[l].sec);
				}
			}
		}
	}

	destroy() {
		for (var j = 0; j < this.meters.length; ++j) {
			this.meters[j].enabledNode.unsubscribe(this);
			this.meters[j].limitNode.unsubscribe(this);
		}
	}
};

//Conviencience helper class that lets us show when the tank is online or not
export class TankOutput extends Widget {
    constructor(element, outputNode, onlineNode) {
        super();
        this.registerAsWidget(element);		// Register like a good little widget
        this.element	= element;			// Store a pointer to our element
        this.outputNode	= outputNode;		// Save the output node
        this.outputNode.subscribe(this);	// Subscribe to the ouput node
        this.onlineNode	= onlineNode;		// Save the online node
        this.onlineNode.subscribe(this);	// Subscribe to the online node
    };

    update() {
        if(this.onlineNode)	// If we have all the elements we need
            this.element.textContent = this.onlineNode.getValue() ?  this.outputNode.getFormattedText() : 'Valved Off';	// Check if we are online or not
    };

    destroy() {
        this.outputNode.unsubscribe(this);	// Unsubscribe from everything
        this.onlineNode.unsubscribe(this);
        this.unregisterAsWidget();			// Unregister like a good little widget
    };
};

// Global methods so WhatIf can use it by importing this file
export function createGraphPulse(wrapper, stroke) {
	var svgPulse		= createSVGElement('svg', 'secMapSVGpulse collapse', wrapper, {width:14, height:14});
	createPulseInnards(svgPulse, stroke, 7, 2, 7);
	return svgPulse;
};

export function createPulseInnards(svgPulse, stroke, circleCenter, circleSize, pulseSize) {
	var svgOuterCircle	= createSVGElement('circle', null, svgPulse, {stroke:stroke, cx: circleCenter, cy: circleCenter, r: 1, 'stroke-width': 3, fill: 'none'});
	var svgInnerCircle	= createSVGElement('circle', null, svgPulse, {cx: circleCenter, cy: circleCenter, r: circleSize, 'stroke-width': 0, fill: stroke});
	createSVGElement('animate', null, svgOuterCircle, {attributeType:'XML', attributeName: 'r', from: 0, to: pulseSize, dur:'2s', repeatCount: 'indefinite'});
	createSVGElement('animate', null, svgOuterCircle, {attributeType:'XML', attributeName: 'opacity', from: 0.75, to: 0, dur:'2s', repeatCount: 'indefinite'});
};

export function createCaption(wrapper, label, color, clickCallback, fLine, fSVG, fNoDot) {
	var caption	= createElement('div', 'secMapCaption', wrapper);			// Create a wrapper for the caption
	var text = createElement('label', 'secMapCaptionText', caption, label);	// Create the text label for the caption
	text.style.backgroundColor = new Color(color);

	if (clickCallback) {
		caption.onclick = clickCallback;	// Add an click callback to hide lines and such
		caption.addEventListener('touchstart', clickCallback);
		caption.classList.add('secMapClickableCaption');
	}

	if (fSVG)
		createPulseInnards(createSVGElement('svg', 'secMapCaptionCanvas', caption, {width:20, height:20}), color, 10, 3, 8);
	else {
		// Now, create a very small canvas are recreate a dot and such. Each canvas is 20 x 20
		var canvas = createElement('canvas', 'secMapCaptionCanvas', caption);
		canvas.width = canvas.clientWidth;	// This is necessary or the canvas pixels are scaled
		canvas.height = canvas.clientHeight;

		var ctx = canvas.getContext("2d");	// Get the drawing context
		if(fNoDot !== true) {
			ctx.fillStyle = color;			// Set the fill color
			ctx.beginPath();				// Start a path
			ctx.arc(10,10,3,0, 2*Math.PI);	// Make a full circle
			ctx.fill();						// Fill in the circle
		}
        if (fLine) {
        	ctx.strokeStyle = color;		// Set the stroke color
        	ctx.lineWidth = 2;				// Set the line width
        	ctx.beginPath();				// Start a path
        	ctx.moveTo(0, 10);				// Move to the left edge of the graph (aligned on a half pixel cause it makes lines sharper)
        	ctx.lineTo(20, 10);				// Make the horizontal line
        	ctx.stroke();					// Draw the line
        }
	}
};

export function interpolateSECs(flowPerPixel, flowArray, secArray, outputFlowArray, outputSECArray) {
	if (flowPerPixel <= 0)
		return;
	for (var i = 1; i < flowArray.length; ++i) {
		var lowFlow		= flowArray[i-1];
		var lowSEC		= secArray[i-1];
		var highFlow	= flowArray[i];
		var highSEC		= secArray[i];

		var q = lowFlow;
		while ((highFlow - q) > flowPerPixel) {	// Put a point every pixel
			outputFlowArray.push(q);			// Store this intermediate flow
			outputSECArray.push(q==0?0:(lowSEC*(highFlow-q)*lowFlow + highSEC*(q-lowFlow)*highFlow)/(q*(highFlow-lowFlow)));
			q += flowPerPixel;
		}
		outputFlowArray.push(highFlow);			// Store this intermediate flow
		outputSECArray.push(highSEC);
	}
};

export function checkLimits(limits, flow, sec) {
	if (flow == 0)	// We don't want zero flow points to be shown
		return;		// Just leave

	if (flow < limits.minFlow)	// If this is the smallest flow we have seen
		limits.minFlow = flow;	// Store this as the smallest flow we have seen
	if (flow > limits.maxFlow)	// If this is the biggest flow we have seen
		limits.maxFlow = flow;	// Store this as the biggest flow we have seen

	if (sec < limits.minSEC)	// If this is the smallest SEC we have seen
		limits.minSEC = sec;	// Store this as the smallest SEC we have seen
	if (sec > limits.maxSEC)	// If this is the biggest SEC we have seen
		limits.maxSEC = sec;	// Store this as the biggest SEC we have seen
};
