import View from './view';
import { createElement } from '../elements';
import owner from '../../owner';
import LiveData from '../livedata';
import FrameParser from '../frameparser';
import './imagebrowserview.css';

interface Thumbnail {
    uuid:   string;
    name:   string;
    url:    string;
}

export default class ImageBrowserView extends View {
    graphID       : number;
    thumbs        : Thumbnail[] = [];
    selectedThumb : Thumbnail;
    containerMap  : Map<string, HTMLElement> = new Map();
    thumbContainer: HTMLElement;
    selectButton  : HTMLButtonElement;
    backButton    : HTMLElement;
    folderTitle   : HTMLElement;
    uploadButton  : HTMLButtonElement;
    callback      : (uuid: string)=>void;
    pendingFile   : File;
    path          : string;
    constructor(path: string, callback: (uuid: string)=>void) {
        super();
        this.callback   = (uuid: string) => callback(uuid);
        this.graphID    = owner.ldc.registerGraph(this);
        this.path       = path;
    }

    initialize(): View {
        this.wrapper        = createElement('div', 'image-browser__wrapper', this.parent);
        let navContainer    = createElement('div', 'image-browser__nav', this.wrapper);
        this.backButton     = createElement('div', 'image-browser__nav__back', navContainer);
        this.folderTitle    = createElement('div', 'image-browser__nav__title', navContainer);
        let srcInput        = createElement('input', '', undefined, '', {'type':'file', 'accept':'image/*'});
        srcInput.onchange   = () => {
            if (!srcInput.files)
                return
            this.pendingFile = srcInput.files[0]
            owner.ldc.uploadFile(this.pendingFile, this.path, (url: string, uuid: string) => this.onFileUploaded(url, uuid));
        }
        this.uploadButton   = createElement('button', 'se-button', navContainer, 'Upload');
        this.uploadButton.onclick = () => srcInput.click();
        this.thumbContainer = createElement('div', 'image-browser__container', this.wrapper);
        let buttonContainer = createElement('div', 'image-browser__buttons', this.wrapper);

        this.selectButton    = createElement('button', 'se-button image-browser__buttons__select', buttonContainer, 'Select', {'disabled': true});
        this.selectButton.onclick = (e) => {
            e.stopPropagation();
            if (!this.selectedThumb)
                return;
            this.callback(this.selectedThumb.uuid);
            if (this.modal)
                this.modal.destroy();
        }
        let cancelButton    = createElement('button', 'se-button image-browser__buttons__cancel', buttonContainer, 'Cancel');
        cancelButton.onclick = () => {
            if (this.modal)
                this.modal.destroy();
        }
        this.wrapper.onclick = () => this.clearSelection();
        owner.ldc.fm.buildFrame(LiveData.WVC_GET_IMAGES, undefined, this.graphID);
        owner.ldc.fm.push_string(this.path);
        owner.ldc.fm.push_string(owner.ldc.user.companyKey);
        owner.ldc.send();										// Send the frame
        this.fInitialized = true;
        return this;
    }

    getUploadURL(files: FileList | null) {
        this.pendingFile = files![0];
        owner.ldc.pendingFileUpload = files![0];
        owner.ldc.fm.buildFrame(LiveData.WVC_GET_UPLOAD_URL, undefined, this.graphID);
        owner.ldc.fm.push_string(this.pendingFile.name);
        owner.ldc.fm.push_string(this.pendingFile.type);
        owner.ldc.fm.push_string(this.path);
        owner.ldc.fm.push_string(owner.ldc.user.companyKey);
        owner.ldc.fm.push_u64(this.pendingFile.size);
        owner.ldc.send();										// Send the frame
    }

    onFileUploaded(url: string, uuid: string) {
        let thumb: Thumbnail = {
            uuid:   uuid,
            name:   this.pendingFile.name,
            url:    URL.createObjectURL(this.pendingFile)
        }
        this.thumbs.push(thumb);
        this.createThumbNail(thumb);
    }

    onGetImagesResponse(fp: FrameParser) {
        let success = fp.pop_u8();
        if (success) {
            let imageCount  = fp.pop_u32();
            this.thumbs     = [];
            for (let i=0;i<imageCount;++i) {
                let thumb: Thumbnail = {
                    uuid:   fp.pop_string(),
                    name:   fp.pop_string(),
                    url:    fp.pop_string()
                }
                this.thumbs.push(thumb);
            }
            for (let i=0; i<this.thumbs.length;++i) {
                this.createThumbNail(this.thumbs[i]);
            }
        }
        for (let i=0; i<this.thumbs.length;++i) {
            this.createThumbNail(this.thumbs[i]);
        }
    }

    createThumbNail(thumb: Thumbnail) {
        let thumbnail  = createElement('div', 'image-browser__thumbnail', this.thumbContainer);
        let imageWrapper    = createElement('div', 'image-browser__thumbnail__wrapper', thumbnail);
        let imageContainer  = createElement('div', 'image-browser__thumbnail__container', imageWrapper);
        createElement('img', 'image-browser__thumbnail__image', imageContainer, '', {'src':thumb.url});
        createElement('div', 'image-browser__thumbnail__title', thumbnail, thumb.name);
        this.containerMap.set(thumb.uuid, thumbnail);
        thumbnail.onclick = (e) => {
            e.stopPropagation();
            this.clearSelection();
            thumbnail.setAttribute('selected', 'true');
            this.selectedThumb = thumb;
            this.selectButton.disabled = false;
        }
    }

    clearSelection() {
        for (let [uuid, container] of this.containerMap) {
            container.setAttribute('selected', 'false');
        }

    }

    destroy() {
        owner.ldc.unregisterGraph(this.graphID);
        super.destroy();
    }
}
